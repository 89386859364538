/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CampaignStatus } from './CampaignStatus';
import {
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
} from './CampaignStatus';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 *
 * @export
 * @interface CampaignBasicInfoResponseDto
 */
export interface CampaignBasicInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    organisationId?: string;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    createdByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    managedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    adClientId?: string | null;
    /**
     *
     * @type {CampaignStatus}
     * @memberof CampaignBasicInfoResponseDto
     */
    campaignStatus?: CampaignStatus;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    campaignName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    referenceNumber?: string | null;
    /**
     *
     * @type {number}
     * @memberof CampaignBasicInfoResponseDto
     */
    totalBudget?: number | null;
    /**
     *
     * @type {CurrencyCode}
     * @memberof CampaignBasicInfoResponseDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {Date}
     * @memberof CampaignBasicInfoResponseDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof CampaignBasicInfoResponseDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {boolean}
     * @memberof CampaignBasicInfoResponseDto
     */
    isAccessibleToEveryone?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof CampaignBasicInfoResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof CampaignBasicInfoResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof CampaignBasicInfoResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the CampaignBasicInfoResponseDto interface.
 */
export function instanceOfCampaignBasicInfoResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CampaignBasicInfoResponseDtoFromJSON(json: any): CampaignBasicInfoResponseDto {
    return CampaignBasicInfoResponseDtoFromJSONTyped(json, false);
}

export function CampaignBasicInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CampaignBasicInfoResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationId: !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
        managedByUserId: !exists(json, 'managed_by_user_id')
            ? undefined
            : json['managed_by_user_id'],
        adClientId: !exists(json, 'ad_client_id') ? undefined : json['ad_client_id'],
        campaignStatus: !exists(json, 'campaign_status')
            ? undefined
            : CampaignStatusFromJSON(json['campaign_status']),
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
        referenceNumber: !exists(json, 'reference_number') ? undefined : json['reference_number'],
        totalBudget: !exists(json, 'total_budget') ? undefined : json['total_budget'],
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        isAccessibleToEveryone: !exists(json, 'is_accessible_to_everyone')
            ? undefined
            : json['is_accessible_to_everyone'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function CampaignBasicInfoResponseDtoToJSON(
    value?: CampaignBasicInfoResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_id: value.organisationId,
        campaign_id: value.campaignId,
        created_by_user_id: value.createdByUserId,
        managed_by_user_id: value.managedByUserId,
        ad_client_id: value.adClientId,
        campaign_status: CampaignStatusToJSON(value.campaignStatus),
        campaign_name: value.campaignName,
        reference_number: value.referenceNumber,
        total_budget: value.totalBudget,
        currency: CurrencyCodeToJSON(value.currency),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        is_accessible_to_everyone: value.isAccessibleToEveryone,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
