import { DeleteAScenarioRequest, ScenarioApi } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface DeleteScenarioHook {
    deleteScenario: (campaignId: string, scenarioId: string) => Promise<number>;
}

type useDeleteScenarioProps = () => DeleteScenarioHook;

export const useDeleteScenario: useDeleteScenarioProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteScenario(campaignId: string, scenarioId: string): Promise<number> {
        try {
            const config = await getMediaPlanConfig();
            const request: DeleteAScenarioRequest = {
                campaignId,
                scenarioId,
            };

            const api = new ScenarioApi(config);
            return await api.deleteAScenario(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        deleteScenario,
    };
};
