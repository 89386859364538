import { FC } from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Container,
    Heading,
    Icon,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { externalUrls } from '@configs/externalUrls';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { clearLocalStorage } from '@shared/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContextHelper } from '@hooks/_contexts';

export const UnexpectedErrorRequiresLogin: FC = () => {
    const { logout } = useAuth0();
    const { resetAppContext } = useAppContextHelper();
    const onLogout = () => {
        clearLocalStorage();
        resetAppContext();
        logout();
    };
    return (
        <Box minH="100vh" minW="100vw" bg="gray.50">
            <Container paddingTop="10vh">
                <Card>
                    <CardBody>
                        <VStack>
                            <Center>
                                <Heading>An error has occurred</Heading>
                            </Center>
                            <Center>
                                <Text textAlign="center" flexWrap="wrap" width="60%">
                                    Whoops! Something went wrong there, please try logging in again.
                                    If you continue to see this error, please reach out to{' '}
                                    <Link
                                        textDecoration="underline"
                                        href={externalUrls.customerSupportEmailUrl}
                                    >
                                        plan-support@amplifiedintelligence.com.au
                                    </Link>{' '}
                                    for assistance.
                                </Text>
                            </Center>
                            <Button onClick={() => onLogout()}>Sign in</Button>
                        </VStack>
                    </CardBody>
                </Card>
            </Container>
        </Box>
    );
};
