import {
    GetScenarioResponseDto,
    OptimisationStrategy,
    ScenarioStatus,
} from '@api-clients/media-plan';
import {
    ScenarioParameterView,
    UpgradeMediaSpendingHeader,
} from '@apps/attentionADJUST/components/atoms';
import { ScenarioDeleteCell } from '@apps/attentionADJUST/components/atoms/scenarioLinkCell/ScenarioDeleteCell';
import { LockIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Skeleton,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { fade } from '@components/atoms/loading/Loading';
import { StatisticCell } from '@components/atoms/statisticCell/StatisticCell';
import { Column, DataTable, DynamicUpgradeModal, Row } from '@components/molecules';
import { routing } from '@configs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { isAlmostEqual } from '@shared/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ScenarioComparisonTableProps {
    scenarios: Array<GetScenarioResponseDto>;
    onScenarioDelete: (scenarioId: string) => Promise<void>;
    canUserSeeScenarioDetails?: boolean;
    setScenarios: (countries: Array<GetScenarioResponseDto>) => void;
}

const isCellUnlocked = (
    optimisationStrategy: OptimisationStrategy,
    column: string,
    canUserSeeScenarioDetails?: boolean,
): boolean => {
    if (optimisationStrategy === OptimisationStrategy.Base || canUserSeeScenarioDetails) {
        return true;
    }

    switch (optimisationStrategy) {
        case OptimisationStrategy.AApR:
            return column === 'activeAttentionSecPerReach';
        case OptimisationStrategy.AApI:
            return column === 'activeAttentionSecPerImpression';
        case OptimisationStrategy.Stl:
            return column === 'shortTermLift';
        default:
            return false;
    }
};

const getCellBackgroundColor = (
    cellUnlocked: boolean,
    isHighestCell: boolean,
    isOptimising: boolean,
): string => {
    if (isOptimising) {
        return '';
    }
    if (!cellUnlocked) {
        return 'gray.50';
    }
    if (isHighestCell) {
        return 'green.50';
    }
    return '';
};

interface DataRow {
    optimisationStrategy: OptimisationStrategy;
    scenarioName: string;
    activeAttentionSecPerReach: number;
    activeAttentionSecPerImpression: number;
    activeAttentionVolumeSeconds: number;
    passiveAttentionSecPerReach: number;
    passiveAttentionSecPerImpression: number;
    passiveAttentionVolumeSeconds: number;
    shortTermLift: number;
    status: ScenarioStatus;
    dateCreated: Date;
    hoverContent?: ReactJSXElement;
}

export const ScenarioComparisonTable: FC<ScenarioComparisonTableProps> = ({
    scenarios,
    onScenarioDelete,
    canUserSeeScenarioDetails,
    setScenarios,
}) => {
    const emitClickOnBaseViewButtonEvent = usePosthogEvent('Viewed baseline scenario');
    const emitClickOnOptimisedViewButtonEvent = usePosthogEvent('Viewed optimised scenario');

    const handleScenarioDelete = async (scenarioId: string) => {
        await onScenarioDelete(scenarioId);
    };
    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const { t } = useTranslation('mediaPlans');

    const navigate = useNavigate();
    const columnsText = t('mediaPlanning.tables.scenarioComparison.columns', {
        returnObjects: true,
    });

    const tooltipText = t('mediaPlanning.tables.scenarioComparison.tooltips', {
        returnObjects: true,
    });

    const onRowClick = (row: Row<DataRow>) => {
        if (canUserSeeScenarioDetails || row.optimisationStrategy === OptimisationStrategy.Base) {
            navigate(`${routing.plan.scenario.path}/${row.key}`);
        } else {
            onUpgradeModalOpen();
        }
    };

    const dataSource: Array<Row<DataRow>> = scenarios
        .map((scenario): Row<DataRow> => {
            return {
                key: scenario.id ?? '',
                optimisationStrategy: scenario.optimisationStrategy!,
                scenarioName: scenario.scenarioName ?? '',
                activeAttentionSecPerReach: scenario?.activeAttentionPerReach ?? 0,
                activeAttentionSecPerImpression: scenario?.activeAttentionPerImpression ?? 0,
                activeAttentionVolumeSeconds: scenario?.activeAttentionVolumeSeconds ?? 0,
                passiveAttentionSecPerReach: scenario?.passiveAttentionPerReach ?? 0,
                passiveAttentionSecPerImpression: scenario?.passiveAttentionPerImpression ?? 0,
                passiveAttentionVolumeSeconds: scenario?.passiveAttentionVolumeSeconds ?? 0,
                shortTermLift: (scenario?.shortTermLift ?? 0) * 100,
                status: scenario.scenarioStatus ?? ScenarioStatus.Active,
                dateCreated: scenario?.createdAt ?? new Date(),
                hoverContent: (
                    <ScenarioParameterView scenario={scenario} setScenarios={setScenarios} />
                ),
            };
        })
        .sort((a, b) => {
            if (b.dateCreated > a.dateCreated) {
                return 1;
            }
            if (b.dateCreated < a.dateCreated) {
                return -1;
            }
            return 0;
        });

    const comparisonFunction = (
        rowA: Row<DataRow>,
        rowB: Row<DataRow>,
        property: keyof DataRow,
    ) => {
        return (rowA[property]! > rowB[property]! ? rowA[property] : rowB[property]) as number;
    };

    const maxRow = dataSource?.reduce(
        (rowA, rowB): Row<DataRow> => ({
            key: 'max',
            optimisationStrategy: OptimisationStrategy.Base,
            scenarioName: 'max',
            activeAttentionSecPerReach: comparisonFunction(
                rowA,
                rowB,
                'activeAttentionSecPerReach',
            ),
            activeAttentionSecPerImpression: comparisonFunction(
                rowA,
                rowB,
                'activeAttentionSecPerImpression',
            ),
            activeAttentionVolumeSeconds: comparisonFunction(
                rowA,
                rowB,
                'activeAttentionVolumeSeconds',
            ),
            passiveAttentionSecPerReach: comparisonFunction(
                rowA,
                rowB,
                'passiveAttentionSecPerReach',
            ),
            passiveAttentionSecPerImpression: comparisonFunction(
                rowA,
                rowB,
                'passiveAttentionSecPerImpression',
            ),
            passiveAttentionVolumeSeconds: comparisonFunction(
                rowA,
                rowB,
                'passiveAttentionVolumeSeconds',
            ),
            shortTermLift: comparisonFunction(rowA, rowB, 'shortTermLift'),
            status: ScenarioStatus.Archived,
            dateCreated: new Date(),
        }),
    );

    const baselineScenario = dataSource.find(
        (scenario) =>
            scenario?.optimisationStrategy &&
            scenario.optimisationStrategy === OptimisationStrategy.Base,
    );

    const getStatisticColumn = (title: string, dataIndex: keyof DataRow) => ({
        title,
        dataIndex,
        key: dataIndex,
        isNumeric: true,
        sorter: (a: Row<DataRow>, b: Row<DataRow>) =>
            (b[dataIndex] as number) - (a[dataIndex] as number),
        render: (row: Row<DataRow>) => {
            if (row.status === ScenarioStatus.Optimising) {
                return <Skeleton height="41px" />;
            }
            if (isCellUnlocked(row.optimisationStrategy, dataIndex, canUserSeeScenarioDetails)) {
                return (
                    <StatisticCell
                        value={row[dataIndex] as number}
                        comparedWithValue={
                            baselineScenario && (baselineScenario[dataIndex] as number)
                        }
                        isHighlighted={isAlmostEqual(
                            row[dataIndex] as number,
                            maxRow[dataIndex] as number,
                            0.01,
                        )}
                        isPercentage={dataIndex === 'shortTermLift'}
                        toDecimalPlace={
                            dataIndex === 'activeAttentionVolumeSeconds' ||
                            dataIndex === 'passiveAttentionVolumeSeconds'
                                ? 0
                                : 2
                        }
                    />
                );
            }
            return (
                <Center>
                    <LockIcon color="gray.300" />
                </Center>
            );
        },
        onCell: (row: Row<DataRow>) => {
            return {
                bgColor: getCellBackgroundColor(
                    isCellUnlocked(row.optimisationStrategy, dataIndex, canUserSeeScenarioDetails),
                    isAlmostEqual(row[dataIndex] as number, maxRow[dataIndex] as number, 0.01),
                    row.status === ScenarioStatus.Optimising,
                ),
                _groupHover: row.status !== ScenarioStatus.Optimising ? { bgColor: 'gray.50' } : {},
            };
        },
    });

    const columns: Array<Column<DataRow>> = [
        {
            title: columnsText.scenario,
            dataIndex: 'scenarioName',
            key: 'scenarioName',
            onCell: (row) => ({
                minWidth: '8rem',
            }),
            render: (row) => {
                if (row.status === ScenarioStatus.Optimising) {
                    return <Text animation={`${fade} infinite 3s linear`}>{row.scenarioName}</Text>;
                }
                return (
                    <Popover placement="bottom-start" trigger="hover">
                        <PopoverTrigger>
                            <Button
                                onClick={() => {
                                    if (row.optimisationStrategy === 'base')
                                        emitClickOnBaseViewButtonEvent();
                                    else emitClickOnOptimisedViewButtonEvent();
                                    onRowClick(row);
                                }}
                                _groupHover={{ textDecoration: 'underline' }}
                                variant="link"
                                textAlign="start"
                            >
                                {row.scenarioName}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent width="100%" maxWidth="100%">
                            <PopoverArrow />
                            <PopoverBody>
                                <Box
                                    onClick={(e) => e.stopPropagation()}
                                    _hover={{ cursor: 'default' }}
                                >
                                    {row.hoverContent}
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                );
            },
        },
        {
            title: columnsText.activeAttentionSeconds,
            key: 'activeAttention',
            textAlign: 'center',
            subColumns: [
                {
                    ...getStatisticColumn(columnsText.perReach, 'activeAttentionSecPerReach'),
                },
                {
                    ...getStatisticColumn(
                        columnsText.perImpression,
                        'activeAttentionSecPerImpression',
                    ),
                },
                {
                    tooltip: tooltipText.volume,
                    ...getStatisticColumn(columnsText.volume, 'activeAttentionVolumeSeconds'),
                },
            ],
        },
        {
            title: columnsText.passiveAttentionSeconds,
            key: 'passiveAttention',
            textAlign: 'center',
            subColumns: [
                {
                    ...getStatisticColumn(columnsText.perReach, 'passiveAttentionSecPerReach'),
                },
                {
                    ...getStatisticColumn(
                        columnsText.perImpression,
                        'passiveAttentionSecPerImpression',
                    ),
                },
                {
                    tooltip: tooltipText.volume,
                    ...getStatisticColumn(columnsText.volume, 'passiveAttentionVolumeSeconds'),
                },
            ],
        },
        {
            tooltip: tooltipText.shortTermLift,
            ...getStatisticColumn('Brand uplift', 'shortTermLift'),
        },
        {
            key: 'action',
            title: 'Action',
            textAlign: 'center',
            onCell: () => ({ width: 0, p: 0 }),
            render: (row) =>
                (row.optimisationStrategy as OptimisationStrategy) === OptimisationStrategy.Base ||
                row.status === ScenarioStatus.Optimising ? (
                    <></>
                ) : (
                    <ScenarioDeleteCell onScenarioDelete={handleScenarioDelete} row={row} />
                ),
        },
    ];

    return (
        <>
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_ScenarioComparisonTable"
                isOpen={isUpgradeModalOpen}
                onClose={onUpgradeModalClose}
                header={<UpgradeMediaSpendingHeader />}
            />
            <DataTable
                dataSource={dataSource.sort(
                    // sort fn makes sure baseline is the top scenario
                    (a, b) =>
                        (b.optimisationStrategy === OptimisationStrategy.Base ? 1 : 0) -
                        (a.optimisationStrategy === OptimisationStrategy.Base ? 1 : 0),
                )}
                columns={columns}
                onRow={(row) => {
                    const cellProps =
                        row.status !== ScenarioStatus.Optimising
                            ? {
                                  onClick: () => onRowClick(row),
                                  _hover: {
                                      cursor: 'pointer',
                                      bgColor: 'gray.50',
                                  },
                              }
                            : {};
                    return { height: '4.625rem', ...cellProps };
                }}
            />
        </>
    );
};
