import {
    CampaignApi,
    DeleteCampaignRequest,
    DeleteDraftCampaignRequest,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface DeleteDraftCampaignHook {
    deleteDraftCampaign: (campaignId: string) => Promise<void>;
}

type useDeleteDraftCampaignProps = () => DeleteDraftCampaignHook;

export const useDeleteDraftCampaign: useDeleteDraftCampaignProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteDraftCampaign(campaignId: string): Promise<void> {
        try {
            const config = await getMediaPlanConfig();
            const request: DeleteDraftCampaignRequest = {
                campaignId,
            };

            const api = new CampaignApi(config);
            return await api.deleteDraftCampaign(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        deleteDraftCampaign,
    };
};
