import {
    GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { useLoaderData } from 'react-router-dom';
import { useGetScenarioComparison } from '@hooks/scenarios/useGetScenarioComparison';
import { useEffect, useRef, useState } from 'react';

type useCurrentScenarioComparisonHook = () => {
    scenarioWithComparison: ScenarioWithComparisonResponseDto | undefined;
    setScenarioWithComparison: (data: ScenarioWithComparisonResponseDto | undefined) => void;
};

export const useCurrentScenarioComparison: useCurrentScenarioComparisonHook = () => {
    const requestParams =
        useLoaderData() as GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest;
    const { getScenarioComparison } = useGetScenarioComparison();
    const [scenarioWithComparison, setScenarioWithComparison] =
        useState<ScenarioWithComparisonResponseDto>();

    useEffect(() => {
        getScenarioComparison(requestParams).then((response) => {
            setScenarioWithComparison(response);
        });
    }, []);

    return {
        scenarioWithComparison,
        setScenarioWithComparison,
    };
};
