import {
    AspectRatio,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    UseDisclosureProps,
} from '@chakra-ui/react';
import { externalUrls } from '@configs/externalUrls';
import { FC, useState } from 'react';

export const FeedbackModal: FC<UseDisclosureProps> = (disclosureProps) => {
    const { isOpen, onClose } = disclosureProps;

    const [isFrameLoaded, setIsFrameLoaded] = useState(false);

    const handleClose = () => {
        if (onClose) {
            setIsFrameLoaded(false);
            onClose();
        }
    };

    return (
        <>
            <Modal
                size="4xl"
                blockScrollOnMount={false}
                isOpen={isOpen ?? false}
                onClose={handleClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader />
                    <ModalCloseButton />
                    <ModalBody>
                        {!isFrameLoaded && (
                            <Center
                                position="absolute"
                                left={0}
                                width="100%"
                                top={0}
                                height="100%"
                                zIndex="modal"
                            >
                                <Spinner size="2xl" />
                            </Center>
                        )}
                        <AspectRatio>
                            <iframe
                                id="feedback_form"
                                title="Feedback form"
                                src={externalUrls.feedbackUrl}
                                onLoad={() => setIsFrameLoaded(true)}
                            />
                        </AspectRatio>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
