/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';
import type { GetChannelLevelResultComparisonResponseDto } from './GetChannelLevelResultComparisonResponseDto';
import {
    GetChannelLevelResultComparisonResponseDtoFromJSON,
    GetChannelLevelResultComparisonResponseDtoFromJSONTyped,
    GetChannelLevelResultComparisonResponseDtoToJSON,
} from './GetChannelLevelResultComparisonResponseDto';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';

/**
 *
 * @export
 * @interface GetScenarioLevelResultComparisonResponseDto
 */
export interface GetScenarioLevelResultComparisonResponseDto {
    /**
     *
     * @type {Array<GetChannelLevelResultComparisonResponseDto>}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    channelLevelResults?: Array<GetChannelLevelResultComparisonResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    comparedWithId?: string;
    /**
     *
     * @type {string}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    scenarioName?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    comparedWithScenarioName?: string | null;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    budget?: DecimalComparisonValue;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    comparedWithOptimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    activeAttentionSecondsPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    activeAttentionSecondsPerImpression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    brandUplift?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    impressions?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    reach?: DecimalComparisonValue;
    /**
     *
     * @type {string}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioLevelResultComparisonResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the GetScenarioLevelResultComparisonResponseDto interface.
 */
export function instanceOfGetScenarioLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetScenarioLevelResultComparisonResponseDtoFromJSON(
    json: any,
): GetScenarioLevelResultComparisonResponseDto {
    return GetScenarioLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function GetScenarioLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetScenarioLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        channelLevelResults: !exists(json, 'channel_level_results')
            ? undefined
            : json['channel_level_results'] === null
            ? null
            : (json['channel_level_results'] as Array<any>).map(
                  GetChannelLevelResultComparisonResponseDtoFromJSON,
              ),
        comparedWithId: !exists(json, 'compared_with_id') ? undefined : json['compared_with_id'],
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        comparedWithScenarioName: !exists(json, 'compared_with_scenario_name')
            ? undefined
            : json['compared_with_scenario_name'],
        budget: !exists(json, 'budget')
            ? undefined
            : DecimalComparisonValueFromJSON(json['budget']),
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        comparedWithOptimisationStrategy: !exists(json, 'compared_with_optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['compared_with_optimisation_strategy']),
        activeAttentionSecondsPerReach: !exists(json, 'active_attention_seconds_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_seconds_per_reach']),
        activeAttentionSecondsPerImpression: !exists(
            json,
            'active_attention_seconds_per_impression',
        )
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_seconds_per_impression']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        brandUplift: !exists(json, 'brand_uplift')
            ? undefined
            : DecimalComparisonValueFromJSON(json['brand_uplift']),
        impressions: !exists(json, 'impressions')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impressions']),
        reach: !exists(json, 'reach') ? undefined : DecimalComparisonValueFromJSON(json['reach']),
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function GetScenarioLevelResultComparisonResponseDtoToJSON(
    value?: GetScenarioLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        channel_level_results:
            value.channelLevelResults === undefined
                ? undefined
                : value.channelLevelResults === null
                ? null
                : (value.channelLevelResults as Array<any>).map(
                      GetChannelLevelResultComparisonResponseDtoToJSON,
                  ),
        compared_with_id: value.comparedWithId,
        scenario_name: value.scenarioName,
        compared_with_scenario_name: value.comparedWithScenarioName,
        budget: DecimalComparisonValueToJSON(value.budget),
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        compared_with_optimisation_strategy: OptimisationStrategyToJSON(
            value.comparedWithOptimisationStrategy,
        ),
        active_attention_seconds_per_reach: DecimalComparisonValueToJSON(
            value.activeAttentionSecondsPerReach,
        ),
        active_attention_seconds_per_impression: DecimalComparisonValueToJSON(
            value.activeAttentionSecondsPerImpression,
        ),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        brand_uplift: DecimalComparisonValueToJSON(value.brandUplift),
        impressions: DecimalComparisonValueToJSON(value.impressions),
        reach: DecimalComparisonValueToJSON(value.reach),
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
