import { FC, ReactElement } from 'react';
import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';

interface NoFeatureBalancePopoverProps extends PopoverProps {
    shouldTriggerPopover: boolean;
    triggerElement: ReactElement;
    header: string;
    body: ReactElement;
}

export const NoFeatureBalancePopover: FC<NoFeatureBalancePopoverProps> = ({
    shouldTriggerPopover,
    triggerElement,
    header,
    body,
    ...popoverProps
}) => {
    return shouldTriggerPopover ? (
        <Popover {...popoverProps}>
            <PopoverTrigger>{triggerElement}</PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader fontWeight="semibold">{header}</PopoverHeader>
                    <PopoverBody>{body}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    ) : (
        <>{triggerElement}</>
    );
};
