export const authMessages = {
    changePassword: {
        success: {
            title: 'Change password',
            description: 'A change password request has been sent to your email.',
        },
        error: {
            title: 'Change password',
            description: 'An error has occurred when changing password.Please try again.',
        },
    },
    forgotPassword: {
        success: {
            title: 'Forgot password',
            description: 'Your forgot password request has been sent successfully.',
        },
        error: {
            title: 'Forgot password',
            description: 'An error has occurred when sending request.Please try again.',
        },
    },
};
