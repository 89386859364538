export const freemium = {
    features: {
        '0': 'Increase your ROI of media spend by helping you invest in attention-effective inventory',
        '1': 'Tailor your media strategy towards targeted demographic, brand type and campaign objective',
        '2': 'Features include format recommendation, creative optimisation, media plan fine-tuning',
        '3': 'Utilise market leading attention metrics: Attention-adjusted® reach curve, attentive reach, attention retention, etc.',
        '4': 'Iterate media plan performance across 16 platforms and 62 formats',
        '5': 'And more!',
    },
    plans: {
        payg: {
            label: 'PAYG',
            pricing: '$1000 PAYG',
            subscriptionLabel: '',
        },
        standard: {
            label: 'Standard',
            pricing: '$1000/month',
            subscriptionLabel: '12 month subscription',
        },
        professional: {
            label: 'Professional',
            pricing: '$5000/month',
            subscriptionLabel: '3 month subscription',
        },
        advanced: {
            label: 'Advanced',
            pricing: '$4000/month',
            subscriptionLabel: '12 month subscription',
        },
    },
} as const;
