/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface SelectedAdFormatRequestDto
 */
export interface SelectedAdFormatRequestDto {
    /**
     *
     * @type {string}
     * @memberof SelectedAdFormatRequestDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SelectedAdFormatRequestDto
     */
    isChecked?: boolean;
    /**
     *
     * @type {number}
     * @memberof SelectedAdFormatRequestDto
     */
    cpm?: number;
}

/**
 * Check if a given object implements the SelectedAdFormatRequestDto interface.
 */
export function instanceOfSelectedAdFormatRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SelectedAdFormatRequestDtoFromJSON(json: any): SelectedAdFormatRequestDto {
    return SelectedAdFormatRequestDtoFromJSONTyped(json, false);
}

export function SelectedAdFormatRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SelectedAdFormatRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        isChecked: !exists(json, 'is_checked') ? undefined : json['is_checked'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
    };
}

export function SelectedAdFormatRequestDtoToJSON(value?: SelectedAdFormatRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_code: value.adFormatCode,
        is_checked: value.isChecked,
        cpm: value.cpm,
    };
}
