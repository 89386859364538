import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resourceNamespaces, globalResources } from './global-resources';

export const resources = globalResources;

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).init({
    resources,
    defaultNS: resourceNamespaces,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
