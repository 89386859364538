import { GetUserByIdRequest, UserApi, UserResponseDto } from '@api-clients/account-manager';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';
import { useAuth0 } from '@auth0/auth0-react';

interface useGetUserProps {
    getUser: () => Promise<UserResponseDto>;
}

type useGetUserHook = () => useGetUserProps;

export const useGetUser: useGetUserHook = () => {
    const { getAccountManagerConfig } = useApiConfiguration();
    const { user } = useAuth0();
    const { getDisplayedErrorMessage, getUserProfile } = useHelper();

    async function getUser(): Promise<UserResponseDto> {
        try {
            const profile = getUserProfile(user!);
            const request: GetUserByIdRequest = { userId: profile.userId as string };
            const configuration = await getAccountManagerConfig();

            const api = new UserApi(configuration);
            return await api.getUserById(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        getUser,
    };
};
