import { Badge, Card, CardBody, CardProps, SkeletonText } from '@chakra-ui/react';
import { FC } from 'react';

export interface AsyncCardProps extends CardProps {
    isLoading?: boolean;
}

/**
 * Card with built in loading behaviour
 */
export const AsyncCard: FC<AsyncCardProps> = ({ isLoading, children, ...props }) => {
    return (
        <Card {...props}>
            <CardBody>
                {isLoading ? (
                    <SkeletonText noOfLines={4} spacing="1rem" skeletonHeight="1rem" />
                ) : (
                    children
                )}
            </CardBody>
        </Card>
    );
};
