/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ConstraintResponseDto } from './ConstraintResponseDto';
import {
    ConstraintResponseDtoFromJSON,
    ConstraintResponseDtoFromJSONTyped,
    ConstraintResponseDtoToJSON,
} from './ConstraintResponseDto';
import type { ImportedFileResponseDto } from './ImportedFileResponseDto';
import {
    ImportedFileResponseDtoFromJSON,
    ImportedFileResponseDtoFromJSONTyped,
    ImportedFileResponseDtoToJSON,
} from './ImportedFileResponseDto';

/**
 *
 * @export
 * @interface GetCampaignResponseDto
 */
export interface GetCampaignResponseDto {
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    campaignId?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    campaignName?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    campaignObjective?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    clientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    clientName?: string | null;
    /**
     *
     * @type {ConstraintResponseDto}
     * @memberof GetCampaignResponseDto
     */
    constraints?: ConstraintResponseDto;
    /**
     *
     * @type {ImportedFileResponseDto}
     * @memberof GetCampaignResponseDto
     */
    importedFile?: ImportedFileResponseDto;
    /**
     *
     * @type {string}
     * @memberof GetCampaignResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof GetCampaignResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof GetCampaignResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the GetCampaignResponseDto interface.
 */
export function instanceOfGetCampaignResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCampaignResponseDtoFromJSON(json: any): GetCampaignResponseDto {
    return GetCampaignResponseDtoFromJSONTyped(json, false);
}

export function GetCampaignResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetCampaignResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
        campaignObjective: !exists(json, 'campaign_objective')
            ? undefined
            : json['campaign_objective'],
        clientId: !exists(json, 'client_id') ? undefined : json['client_id'],
        clientName: !exists(json, 'client_name') ? undefined : json['client_name'],
        constraints: !exists(json, 'constraints')
            ? undefined
            : ConstraintResponseDtoFromJSON(json['constraints']),
        importedFile: !exists(json, 'imported_file')
            ? undefined
            : ImportedFileResponseDtoFromJSON(json['imported_file']),
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function GetCampaignResponseDtoToJSON(value?: GetCampaignResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        campaign_name: value.campaignName,
        campaign_objective: value.campaignObjective,
        client_id: value.clientId,
        client_name: value.clientName,
        constraints: ConstraintResponseDtoToJSON(value.constraints),
        imported_file: ImportedFileResponseDtoToJSON(value.importedFile),
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
