/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { RawImportedDataRequestDto } from './RawImportedDataRequestDto';
import {
    RawImportedDataRequestDtoFromJSON,
    RawImportedDataRequestDtoFromJSONTyped,
    RawImportedDataRequestDtoToJSON,
} from './RawImportedDataRequestDto';

/**
 *
 * @export
 * @interface ImportedFileRequestDto
 */
export interface ImportedFileRequestDto {
    /**
     *
     * @type {string}
     * @memberof ImportedFileRequestDto
     */
    campaignId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileRequestDto
     */
    uploadedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileRequestDto
     */
    templateName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileRequestDto
     */
    fileName?: string | null;
    /**
     *
     * @type {Array<RawImportedDataRequestDto>}
     * @memberof ImportedFileRequestDto
     */
    rawImportedDatas?: Array<RawImportedDataRequestDto> | null;
}

/**
 * Check if a given object implements the ImportedFileRequestDto interface.
 */
export function instanceOfImportedFileRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportedFileRequestDtoFromJSON(json: any): ImportedFileRequestDto {
    return ImportedFileRequestDtoFromJSONTyped(json, false);
}

export function ImportedFileRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ImportedFileRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        uploadedByUserId: !exists(json, 'uploaded_by_user_id')
            ? undefined
            : json['uploaded_by_user_id'],
        templateName: !exists(json, 'template_name') ? undefined : json['template_name'],
        fileName: !exists(json, 'file_name') ? undefined : json['file_name'],
        rawImportedDatas: !exists(json, 'raw_imported_datas')
            ? undefined
            : json['raw_imported_datas'] === null
            ? null
            : (json['raw_imported_datas'] as Array<any>).map(RawImportedDataRequestDtoFromJSON),
    };
}

export function ImportedFileRequestDtoToJSON(value?: ImportedFileRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        uploaded_by_user_id: value.uploadedByUserId,
        template_name: value.templateName,
        file_name: value.fileName,
        raw_imported_datas:
            value.rawImportedDatas === undefined
                ? undefined
                : value.rawImportedDatas === null
                ? null
                : (value.rawImportedDatas as Array<any>).map(RawImportedDataRequestDtoToJSON),
    };
}
