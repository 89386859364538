import { FC } from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

type StrategyReportNavigationProps = {
    prev?: string;
    next?: string;
};
export const StrategyReportNavigation: FC<StrategyReportNavigationProps> = ({ next, prev }) => {
    const navigate = useNavigate();
    return (
        <Flex justifyContent={prev ? 'space-between' : 'flex-end'} alignItems="center">
            {prev && (
                <Button
                    variant="outline"
                    type="button"
                    onClick={() => navigate(prev)}
                    display="flex"
                    alignItems="center"
                    gap=".5rem"
                >
                    <Icon as={ArrowLeftIcon} boxSize="1.2rem" />
                    Back
                </Button>
            )}
            {next && (
                <Button
                    type="button"
                    onClick={() => navigate(next)}
                    display="flex"
                    alignItems="center"
                    gap=".5rem"
                >
                    Next
                    <Icon as={ArrowRightIcon} boxSize="1.2rem" />
                </Button>
            )}
        </Flex>
    );
};
