import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveBreakdownPublisherReportDemoData: ProveReportData = {
    "breakdown": [
        {
            "value": "www.publisher1.com",
            "metrics": {
                "avgActiveAttention": 0.86,
                "impressionsActive5s": 5196,
                "impressionsActive4s": 8860,
                "avgNonAttention": 0.54,
                "impressions": 181808,
                "avgPassiveAttention": 3.15,
                "impressionsActive3s": 14844,
                "impressionsActive2s": 29682,
                "impressionsActive1s": 54862
            }
        },
        {
            "value": "www.publisher2.com",
            "metrics": {
                "avgActiveAttention": 1.17,
                "impressionsActive5s": 4689,
                "impressionsActive4s": 8011,
                "avgNonAttention": 0.71,
                "impressions": 152527,
                "avgPassiveAttention": 4.17,
                "impressionsActive3s": 12904,
                "impressionsActive2s": 27265,
                "impressionsActive1s": 61539
            }
        },
        {
            "value": "www.publisher3.com",
            "metrics": {
                "avgActiveAttention": 1.67,
                "impressionsActive5s": 6362,
                "impressionsActive4s": 10015,
                "avgNonAttention": 1.53,
                "impressions": 82382,
                "avgPassiveAttention": 6.84,
                "impressionsActive3s": 16039,
                "impressionsActive2s": 27328,
                "impressionsActive1s": 44717
            }
        },
        {
            "value": "www.publisher4.com",
            "metrics": {
                "avgActiveAttention": 1.39,
                "impressionsActive5s": 163,
                "impressionsActive4s": 942,
                "avgNonAttention": 1.41,
                "impressions": 58134,
                "avgPassiveAttention": 9.39,
                "impressionsActive3s": 5892,
                "impressionsActive2s": 16918,
                "impressionsActive1s": 33293
            }
        },
        {
            "value": "www.publisher5.com",
            "metrics": {
                "avgActiveAttention": 1.25,
                "impressionsActive5s": 56,
                "impressionsActive4s": 640,
                "avgNonAttention": 1.29,
                "impressions": 43279,
                "avgPassiveAttention": 8.91,
                "impressionsActive3s": 4353,
                "impressionsActive2s": 11414,
                "impressionsActive1s": 22207
            }
        },
        {
            "value": "www.publisher6.com",
            "metrics": {
                "avgActiveAttention": 2.08,
                "impressionsActive5s": 4660,
                "impressionsActive4s": 8163,
                "avgNonAttention": 2.04,
                "impressions": 36191,
                "avgPassiveAttention": 9.79,
                "impressionsActive3s": 11706,
                "impressionsActive2s": 16350,
                "impressionsActive1s": 20696
            }
        },
        {
            "value": "www.publisher7.com",
            "metrics": {
                "avgActiveAttention": 1.97,
                "impressionsActive5s": 4584,
                "impressionsActive4s": 7640,
                "avgNonAttention": 2.56,
                "impressions": 32911,
                "avgPassiveAttention": 9.96,
                "impressionsActive3s": 10168,
                "impressionsActive2s": 13516,
                "impressionsActive1s": 17007
            }
        },
        {
            "value": "www.publisher8.com",
            "metrics": {
                "avgActiveAttention": 1.07,
                "impressionsActive5s": 844,
                "impressionsActive4s": 1564,
                "avgNonAttention": 1.57,
                "impressions": 27576,
                "avgPassiveAttention": 5.51,
                "impressionsActive3s": 2685,
                "impressionsActive2s": 5253,
                "impressionsActive1s": 10702
            }
        },
        {
            "value": "www.publisher9.com",
            "metrics": {
                "avgActiveAttention": 0.45,
                "impressionsActive5s": 415,
                "impressionsActive4s": 699,
                "avgNonAttention": 0.91,
                "impressions": 26893,
                "avgPassiveAttention": 2.27,
                "impressionsActive3s": 1098,
                "impressionsActive2s": 2035,
                "impressionsActive1s": 4017
            }
        },
        {
            "value": "www.publisher10.com",
            "metrics": {
                "avgActiveAttention": 0.85,
                "impressionsActive5s": 456,
                "impressionsActive4s": 773,
                "avgNonAttention": 0.52,
                "impressions": 25277,
                "avgPassiveAttention": 2.85,
                "impressionsActive3s": 1261,
                "impressionsActive2s": 2786,
                "impressionsActive1s": 7245
            }
        },
        {
            "value": "www.publisher11.com",
            "metrics": {
                "avgActiveAttention": 1.3,
                "impressionsActive5s": 882,
                "impressionsActive4s": 1508,
                "avgNonAttention": 0.64,
                "impressions": 19582,
                "avgPassiveAttention": 4.51,
                "impressionsActive3s": 2371,
                "impressionsActive2s": 4451,
                "impressionsActive1s": 7981
            }
        },
        {
            "value": "www.publisher12.com",
            "metrics": {
                "avgActiveAttention": 1.05,
                "impressionsActive5s": 492,
                "impressionsActive4s": 1060,
                "avgNonAttention": 3.82,
                "impressions": 15811,
                "avgPassiveAttention": 8.36,
                "impressionsActive3s": 2153,
                "impressionsActive2s": 3481,
                "impressionsActive1s": 5035
            }
        },
        {
            "value": "www.publisher13.com",
            "metrics": {
                "avgActiveAttention": 1.02,
                "impressionsActive5s": 552,
                "impressionsActive4s": 862,
                "avgNonAttention": 2.62,
                "impressions": 13908,
                "avgPassiveAttention": 6.73,
                "impressionsActive3s": 1150,
                "impressionsActive2s": 2348,
                "impressionsActive1s": 5380
            }
        },
        {
            "value": "www.publisher14.com",
            "metrics": {
                "avgActiveAttention": 0.81,
                "impressionsActive5s": 280,
                "impressionsActive4s": 423,
                "avgNonAttention": 1.99,
                "impressions": 11641,
                "avgPassiveAttention": 4.99,
                "impressionsActive3s": 614,
                "impressionsActive2s": 1495,
                "impressionsActive1s": 3745
            }
        },
        {
            "value": "www.publisher15.com",
            "metrics": {
                "avgActiveAttention": 2.04,
                "impressionsActive5s": 1200,
                "impressionsActive4s": 2070,
                "avgNonAttention": 2.33,
                "impressions": 9162,
                "avgPassiveAttention": 10.16,
                "impressionsActive3s": 2852,
                "impressionsActive2s": 3879,
                "impressionsActive1s": 4988
            }
        },
        {
            "value": "www.publisher16.com",
            "metrics": {
                "avgActiveAttention": 1.59,
                "impressionsActive5s": 34,
                "impressionsActive4s": 308,
                "avgNonAttention": 2.09,
                "impressions": 8972,
                "avgPassiveAttention": 12.69,
                "impressionsActive3s": 1719,
                "impressionsActive2s": 3521,
                "impressionsActive1s": 5269
            }
        },
        {
            "value": "www.publisher17.com",
            "metrics": {
                "avgActiveAttention": 1.33,
                "impressionsActive5s": 5,
                "impressionsActive4s": 84,
                "avgNonAttention": 1.29,
                "impressions": 8016,
                "avgPassiveAttention": 9.37,
                "impressionsActive3s": 657,
                "impressionsActive2s": 2135,
                "impressionsActive1s": 4553
            }
        },
        {
            "value": "www.publisher18.com",
            "metrics": {
                "avgActiveAttention": 0.91,
                "impressionsActive5s": 197,
                "impressionsActive4s": 314,
                "avgNonAttention": 2.24,
                "impressions": 7374,
                "avgPassiveAttention": 5.83,
                "impressionsActive3s": 454,
                "impressionsActive2s": 1079,
                "impressionsActive1s": 2566
            }
        },
        {
            "value": "www.publisher19.com",
            "metrics": {
                "avgActiveAttention": 1.85,
                "impressionsActive5s": 729,
                "impressionsActive4s": 1189,
                "avgNonAttention": 1.99,
                "impressions": 6681,
                "avgPassiveAttention": 9.07,
                "impressionsActive3s": 1678,
                "impressionsActive2s": 2481,
                "impressionsActive1s": 3574
            }
        },
        {
            "value": "www.publisher20.com",
            "metrics": {
                "avgActiveAttention": 1.52,
                "impressionsActive5s": 395,
                "impressionsActive4s": 697,
                "avgNonAttention": 2.38,
                "impressions": 5616,
                "avgPassiveAttention": 6.96,
                "impressionsActive3s": 967,
                "impressionsActive2s": 1588,
                "impressionsActive1s": 2712
            }
        },
        {
            "value": "www.publisher21.com",
            "metrics": {
                "avgActiveAttention": 1.62,
                "impressionsActive5s": 20,
                "impressionsActive4s": 94,
                "avgNonAttention": 1.37,
                "impressions": 5375,
                "avgPassiveAttention": 11.17,
                "impressionsActive3s": 622,
                "impressionsActive2s": 1776,
                "impressionsActive1s": 3708
            }
        },
        {
            "value": "www.publisher22.com",
            "metrics": {
                "avgActiveAttention": 0.88,
                "impressionsActive5s": 129,
                "impressionsActive4s": 212,
                "avgNonAttention": 1.85,
                "impressions": 5216,
                "avgPassiveAttention": 5.14,
                "impressionsActive3s": 314,
                "impressionsActive2s": 708,
                "impressionsActive1s": 1688
            }
        }
    ]
};





