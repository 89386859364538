import {
    CampaignResponseDto,
    ChannelLevelCurvesResponseDto,
    GetCampaignByIdRequest,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    OptimisationStrategy,
} from '@api-clients/media-plan';
import {
    Button,
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { InputWrapper } from '@components/atoms';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentScenarioComparison } from '@hooks/scenarios';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ShareScenarioButton } from '@apps/attentionADJUST/components/molecules/manageScenarioMenu/ShareScenarioButton';
import { useGetScenarioLevelResultsComparison } from '@hooks/scenarios/useGetScenarioLevelResultsComparison';
import { useLoaderData } from 'react-router-dom';
import { useGetCampaign } from '@hooks/campaigns';
import { ScenarioBannerProps } from '../../organisms/scenarioBanner/ScenarioBanner';
import { ExportToPdfButton } from './ExportToPdfButton';

export interface ManageScenarioMenuProps extends Pick<ScenarioBannerProps, 'onDelete' | 'onEdit'> {
    scenarioBasicInfo?: GetScenarioBasicInfoResponseDto;
    reachCurveData: Array<ChannelLevelCurvesResponseDto>;
    isFreePlan: boolean;
}

export const ManageScenarioMenu: FC<ManageScenarioMenuProps> = ({
    scenarioBasicInfo,
    onDelete,
    onEdit,
    reachCurveData,
    isFreePlan,
}) => {
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

    const [campaign, setCampaign] = useState<CampaignResponseDto>();

    const { t } = useTranslation('mediaPlans');
    const editText = t('mediaPlanning.scenarioCreation.comparison.edit', {
        returnObjects: true,
    });
    const deleteText = t('mediaPlanning.scenarioCreation.comparison.delete', {
        returnObjects: true,
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid },
    } = useForm<{ name: string }>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                name: yup.string().required(editText.dialog.form.name.errorMessage),
            }),
        ),
    });

    const { campaignId, scenarioId } = useLoaderData() as {
        campaignId: string;
        scenarioId: string;
    };

    useEffect(() => {
        reset();
    }, [isEditOpen]);

    // for use by the pdf
    const { scenarioWithComparison } = useCurrentScenarioComparison();
    const { getScenarioLevelResultsComparison } = useGetScenarioLevelResultsComparison();
    const [scenarioLevelResultsComparison, setScenarioLevelResultsComparison] =
        useState<GetScenarioLevelResultComparisonResponseDto>();

    const { getCampaign } = useGetCampaign();

    useEffect(() => {
        getScenarioLevelResultsComparison(campaignId, scenarioId).then((r) =>
            setScenarioLevelResultsComparison(r),
        );
        const request: GetCampaignByIdRequest = { mediaPlanId: campaignId ?? '' };
        getCampaign(request).then((c) => setCampaign(c));
    }, [campaignId, scenarioId]);

    return (
        <HStack gap="1rem">
            {campaign && scenarioWithComparison && scenarioLevelResultsComparison ? (
                <HStack spacing={3}>
                    <ShareScenarioButton
                        scenario={scenarioWithComparison}
                        reachCurveData={reachCurveData}
                        scenarioLevelResultsComparison={scenarioLevelResultsComparison}
                        campaign={campaign}
                        scenarioBasicInfo={scenarioBasicInfo}
                    />
                    <ExportToPdfButton
                        scenario={scenarioWithComparison}
                        reachCurveData={reachCurveData}
                        scenarioLevelResultsComparison={scenarioLevelResultsComparison}
                        isFreePlan={isFreePlan}
                    />
                </HStack>
            ) : (
                <Button variant="link" colorScheme="orange" isLoading />
            )}
            <Menu>
                <Tooltip label="More">
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        fontSize="1.5rem"
                        icon={<Icon as={EllipsisVerticalIcon} />}
                        variant="ghost"
                    />
                </Tooltip>
                <MenuList>
                    <MenuGroup title="Manage">
                        <MenuItem
                            onClick={onEditOpen}
                            icon={<Icon fontSize="1rem" as={PencilIcon} />}
                        >
                            Edit
                        </MenuItem>
                        {scenarioBasicInfo?.optimisationStrategy !== OptimisationStrategy.Base && (
                            <MenuItem
                                onClick={onDeleteOpen}
                                icon={<Icon fontSize="1rem" as={TrashIcon} />}
                            >
                                Delete
                            </MenuItem>
                        )}
                    </MenuGroup>
                </MenuList>
            </Menu>

            <ConfirmDialog
                isDestructive
                headerText={deleteText.dialog.title}
                onClose={(isConfirmation: boolean) => {
                    if (isConfirmation) {
                        onDelete();
                    }
                    onDeleteClose();
                }}
                confirmButtonText={deleteText.dialog.confirm}
                cancelButtonText={deleteText.dialog.cancel}
                isOpen={isDeleteOpen}
            >
                <Trans
                    i18nKey="mediaPlanning.scenarioCreation.comparison.delete.dialog.body"
                    values={{ scenario: scenarioBasicInfo?.scenarioName }}
                />
            </ConfirmDialog>
            <ConfirmDialog
                headerText="Edit Scenario"
                onClose={(isConfirmation: boolean) => {
                    if (isConfirmation) {
                        handleSubmit((form) => onEdit({ scenarioName: form.name }))();
                    }
                    onEditClose();
                }}
                confirmButtonText={editText.dialog.confirm}
                cancelButtonText={editText.dialog.cancel}
                isOpen={isEditOpen}
                isConfirmDisabled={!isValid}
            >
                <form onSubmit={handleSubmit((form) => onEdit({ scenarioName: form.name }))}>
                    <InputWrapper
                        isRequired
                        formLabel={editText.dialog.form.name.label}
                        formErrorText={errors.name?.message}
                        isInvalid={!!errors.name}
                    >
                        <Input
                            {...register('name', {
                                value: scenarioBasicInfo?.scenarioName ?? '',
                            })}
                        />
                    </InputWrapper>
                </form>
            </ConfirmDialog>
        </HStack>
    );
};
