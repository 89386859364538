const fileUrl = '/attentionPLAN_Template.xlsx' as const;
const fileName = 'attentionPLAN_Template.xlsx' as const;
const sheetName = 'Sheet1' as const;
const templateName = 'V0.1' as const;

// all values of the channel column in the template, must be unique
export const channels = [
    'TV',
    'BVOD',
    'Youtube',
    'Facebook',
    'Instagram',
    'TikTok',
    'Twitter',
    'General Web',
    'Twitch',
    'Snapchat',
] as const;

// The headers of the excel file
// cells under the channel header must contain a channel
// cells under any of the input headers must contain a number
const channelHeader = 'Channel' as const;
const inputHeaders = [
    'Budget',
    'CPM',
    'Average frequency',
    'Potential target audience size',
    'Impressions (M)',
    'Target audience reached',
    'Reach (%)',
    'TRP',
    'CPP',
] as const;

export const importTemplate = {
    fileUrl,
    fileName,
    sheetName,
    templateName,
    headers: { channelHeader, inputHeaders },
    channels,
};
