import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveGeneratedTag, ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";

export class GenerateProveJsTagApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign,
        lineItem: ProveLineItem
    ): Promise<ProveGeneratedTag> {
        const response = await fetch(`${configuration.basePath}/prove/v1/tag/js`, {
            method: 'POST',
            headers: configuration.headers,
            body: JSON.stringify({
                campaign: campaign.externalId,
                insertionOrder: campaign.insertionOrder!.externalId,
                lineItem: lineItem.externalId,
                tcf: true,
                adFormat: lineItem.adFormat
            })
        });

        if (response.status !== 200) {
            throw new Error("Error thrown attempting to generate PROVE JS tag.");
        }

        return response.json();
    }

}
