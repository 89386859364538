import { CampaignApi, CampaignBasicInfoResponseDto } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetCampaignsHook = () => {
    getCampaigns: () => Promise<Array<CampaignBasicInfoResponseDto>>;
};

export const useGetCampaigns: useGetCampaignsHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getCampaigns() {
        try {
            const config = await getMediaPlanConfig();

            const api = new CampaignApi(config);
            return await api.listAllCampaignsForTheCurrentLoggedInUser();
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getCampaigns };
};
