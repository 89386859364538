/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { ChannelRequestDto } from './ChannelRequestDto';
import {
    ChannelRequestDtoFromJSON,
    ChannelRequestDtoFromJSONTyped,
    ChannelRequestDtoToJSON,
} from './ChannelRequestDto';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';

/**
 *
 * @export
 * @interface GetAttentionDataSaasRequestDto
 */
export interface GetAttentionDataSaasRequestDto {
    /**
     *
     * @type {boolean}
     * @memberof GetAttentionDataSaasRequestDto
     */
    isOnlyMrcCompliant?: boolean;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof GetAttentionDataSaasRequestDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof GetAttentionDataSaasRequestDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof GetAttentionDataSaasRequestDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {Array<ChannelRequestDto>}
     * @memberof GetAttentionDataSaasRequestDto
     */
    channels?: Array<ChannelRequestDto> | null;
}

/**
 * Check if a given object implements the GetAttentionDataSaasRequestDto interface.
 */
export function instanceOfGetAttentionDataSaasRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAttentionDataSaasRequestDtoFromJSON(json: any): GetAttentionDataSaasRequestDto {
    return GetAttentionDataSaasRequestDtoFromJSONTyped(json, false);
}

export function GetAttentionDataSaasRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetAttentionDataSaasRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        isOnlyMrcCompliant: !exists(json, 'is_only_mrc_compliant')
            ? undefined
            : json['is_only_mrc_compliant'],
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        channels: !exists(json, 'channels')
            ? undefined
            : json['channels'] === null
            ? null
            : (json['channels'] as Array<any>).map(ChannelRequestDtoFromJSON),
    };
}

export function GetAttentionDataSaasRequestDtoToJSON(
    value?: GetAttentionDataSaasRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        is_only_mrc_compliant: value.isOnlyMrcCompliant,
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        channels:
            value.channels === undefined
                ? undefined
                : value.channels === null
                ? null
                : (value.channels as Array<any>).map(ChannelRequestDtoToJSON),
    };
}
