/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AttentionCriteria } from './AttentionCriteria';
import {
    AttentionCriteriaFromJSON,
    AttentionCriteriaFromJSONTyped,
    AttentionCriteriaToJSON,
} from './AttentionCriteria';
import type { ReachCurvePointResponseDto } from './ReachCurvePointResponseDto';
import {
    ReachCurvePointResponseDtoFromJSON,
    ReachCurvePointResponseDtoFromJSONTyped,
    ReachCurvePointResponseDtoToJSON,
} from './ReachCurvePointResponseDto';
import type { ReachCurveType } from './ReachCurveType';
import {
    ReachCurveTypeFromJSON,
    ReachCurveTypeFromJSONTyped,
    ReachCurveTypeToJSON,
} from './ReachCurveType';

/**
 *
 * @export
 * @interface ReachCurveResponseDto
 */
export interface ReachCurveResponseDto {
    /**
     *
     * @type {ReachCurveType}
     * @memberof ReachCurveResponseDto
     */
    reachCurveType?: ReachCurveType;
    /**
     *
     * @type {ReachCurvePointResponseDto}
     * @memberof ReachCurveResponseDto
     */
    scenarioPoint?: ReachCurvePointResponseDto;
    /**
     *
     * @type {Array<ReachCurvePointResponseDto>}
     * @memberof ReachCurveResponseDto
     */
    curvePoints?: Array<ReachCurvePointResponseDto> | null;
    /**
     *
     * @type {number}
     * @memberof ReachCurveResponseDto
     */
    attentionThreshold?: number;
    /**
     *
     * @type {AttentionCriteria}
     * @memberof ReachCurveResponseDto
     */
    attentionCriteria?: AttentionCriteria;
}

/**
 * Check if a given object implements the ReachCurveResponseDto interface.
 */
export function instanceOfReachCurveResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReachCurveResponseDtoFromJSON(json: any): ReachCurveResponseDto {
    return ReachCurveResponseDtoFromJSONTyped(json, false);
}

export function ReachCurveResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ReachCurveResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        reachCurveType: !exists(json, 'reach_curve_type')
            ? undefined
            : ReachCurveTypeFromJSON(json['reach_curve_type']),
        scenarioPoint: !exists(json, 'scenario_point')
            ? undefined
            : ReachCurvePointResponseDtoFromJSON(json['scenario_point']),
        curvePoints: !exists(json, 'curve_points')
            ? undefined
            : json['curve_points'] === null
            ? null
            : (json['curve_points'] as Array<any>).map(ReachCurvePointResponseDtoFromJSON),
        attentionThreshold: !exists(json, 'attention_threshold')
            ? undefined
            : json['attention_threshold'],
        attentionCriteria: !exists(json, 'attention_criteria')
            ? undefined
            : AttentionCriteriaFromJSON(json['attention_criteria']),
    };
}

export function ReachCurveResponseDtoToJSON(value?: ReachCurveResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        reach_curve_type: ReachCurveTypeToJSON(value.reachCurveType),
        scenario_point: ReachCurvePointResponseDtoToJSON(value.scenarioPoint),
        curve_points:
            value.curvePoints === undefined
                ? undefined
                : value.curvePoints === null
                ? null
                : (value.curvePoints as Array<any>).map(ReachCurvePointResponseDtoToJSON),
        attention_threshold: value.attentionThreshold,
        attention_criteria: AttentionCriteriaToJSON(value.attentionCriteria),
    };
}
