import {
    ChannelSegmentResponseDto
} from '@api-clients/attention-data';
import {
    GetAllSegmentsRequest,
    StrategyReportsApi
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAllSegmentsProps {
    getAllSegments: (request: GetAllSegmentsRequest) => Promise<Array<ChannelSegmentResponseDto>>;
}

type useGetAllSegmentsHook = () => useGetAllSegmentsProps;

export const useGetAllSegments: useGetAllSegmentsHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllSegments(
        request: GetAllSegmentsRequest,
    ): Promise<Array<ChannelSegmentResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getAllSegments(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAllSegments,
    };
};
