import {
    CampaignApi,
    GetCampaignResponseDto,
    GetDraftCampaignByIdRequest,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetDraftCampaignHook = () => {
    getDraftCampaign: (request: GetDraftCampaignByIdRequest) => Promise<GetCampaignResponseDto>;
};

export const useGetDraftCampaign: useGetDraftCampaignHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getDraftCampaign(request: GetDraftCampaignByIdRequest) {
        try {
            const config = await getMediaPlanConfig();
            const api = new CampaignApi(config);
            return await api.getDraftCampaignById(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getDraftCampaign };
};
