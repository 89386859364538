import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { outline } from '../radio/Radio';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
    control: {
        bgColor: 'white',
    },
});

export const Checkbox = defineMultiStyleConfig({ baseStyle, variants: { outline } });
