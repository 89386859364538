import {
    Center,
    Stack,
    Table,
    TableCaption,
    TableCellProps,
    TableContainer,
    TableContainerProps,
    TableHeadProps,
    TableProps,
    TableRowProps,
    Tbody,
    Td,
    Tfoot,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { SortDirection } from '@shared/cores';
import React, { useEffect, useMemo, useState } from 'react';
import { DataTableBodySection } from './DataTableBodySection';
import { DataTableHeaderSection } from './DataTableHeaderSection';
import { TablePaginator } from './TablePaginator';

export type Row<T> = T & {
    key: string;
    expandedContent?: ReactJSXElement;
};

// gets only columns with no child columns)
const getChildColumns = <T,>(columns: Array<Column<T>>): Array<Column<T>> =>
    columns.flatMap((column) => {
        if (column.subColumns) {
            return getChildColumns(column.subColumns);
        }
        return column;
    });

// many of these props are based on functionality from ant design's implementation of tables
// see https://ant.design/components/table/
export interface Column<T> {
    title?: string | ReactJSXElement;
    dataIndex?: keyof T;
    key: string;
    defaultValue?: string;
    sorter?: (a: Row<T>, b: Row<T>) => number;
    subColumns?: Array<Column<T>>;
    render?: (row: Row<T>, rowIndex: number) => ReactJSXElement; // used to render a component within the cell itself
    textAlign?: 'left' | 'center' | 'right'; // alignment of header text
    isNumeric?: boolean; // isNumeric overrides textAlign and applies alignment to cells too
    onCell?: (row: Row<T>, rowIndex: number) => TableCellProps; // used to pass table cell props to specific cells
    tooltip?: React.ReactNode;
}

export interface SimpleTableProps<T> extends TableProps {
    dataSource: Array<Row<T>>;
    columns: Array<Column<T>>;
    isPaginated?: boolean;
    paginationOptions?: Array<number>;
    defaultPageSize?: number;
    settings?: { caption?: string; foot?: Array<string> };
    noEntriesComponent?: ReactJSXElement;
    tableContainerProps?: TableContainerProps;
    isSelectable?: boolean;
    filter?: string;
    isHeaderSticky?: boolean;
    paginatorPosition?: 'center' | 'left' | 'right';
    onRowSelect?: (keys: Array<string>) => void;
    onRow?: (row: Row<T>) => TableRowProps;
}

export const DataTable = <T,>({
    dataSource,
    columns,
    settings,
    isPaginated,
    paginationOptions = [5, 10, 20, 50],
    defaultPageSize,
    variant,
    noEntriesComponent,
    tableContainerProps,
    isSelectable,
    filter = '',
    isHeaderSticky,
    paginatorPosition = 'center',
    onRowSelect,
    onRow,
    ...tableProps
}: SimpleTableProps<T>) => {
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        // if default is provided and it matches provided pagination options sets initial rows per page
        defaultPageSize && paginationOptions.includes(defaultPageSize)
            ? defaultPageSize
            : paginationOptions[0],
    );
    const [selectedPage, setSelectedPage] = useState<number>(1);

    // reset pagination
    // apply filter
    const filteredRows = useMemo(() => {
        setSelectedPage(1);
        return dataSource.filter((r) =>
            getChildColumns(columns).some(
                (c) =>
                    c.dataIndex &&
                    c.dataIndex in r &&
                    (r as any)[c.dataIndex]
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filter.toLocaleLowerCase()),
            ),
        );
    }, [filter, dataSource]);

    const numberOfPages = Math.ceil(filteredRows.length / rowsPerPage);
    const displayedRowsRange = isPaginated
        ? {
              start: (selectedPage - 1) * rowsPerPage,
              end: selectedPage * rowsPerPage,
          }
        : undefined; // if range is undefined the dataset will display in its entirety

    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Unsorted);
    const [sortedColumn, setSortedColumn] = useState<Column<T>>();
    const handleDirectionChange = (selectedColumn: Column<T>) => {
        setSelectedPage(1);
        setSortDirection((prevDir) => {
            if (
                sortedColumn?.dataIndex !== selectedColumn.dataIndex ||
                prevDir === SortDirection.Unsorted
            ) {
                setSortedColumn(selectedColumn);
                return SortDirection.Ascending;
            }
            if (prevDir === SortDirection.Ascending) {
                setSortedColumn(selectedColumn);
                return SortDirection.Descending;
            }
            setSortedColumn(undefined);
            return SortDirection.Unsorted;
        });
    };

    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

    useEffect(() => {
        if (onRowSelect) {
            onRowSelect([...selectedRows]);
        }
    }, [selectedRows]);

    const toggleSelectedRow = (key: string) => {
        setSelectedRows((oldRows) => {
            const newRows = new Set(oldRows);
            if (newRows.has(key)) {
                newRows.delete(key);
            } else {
                newRows.add(key);
            }
            return newRows;
        });
    };

    const allChecked = dataSource.every((r) => selectedRows?.has(r.key));
    const isIndeterminate = dataSource.some((r) => selectedRows?.has(r.key)) && !allChecked;

    const toggleAllRows = () => {
        setSelectedRows((oldRows) => {
            const newRows = new Set(oldRows);
            if (allChecked) {
                newRows.clear();
            } else {
                dataSource.forEach((r) => newRows.add(r.key));
            }
            return newRows;
        });
    };

    const headerProps: TableHeadProps = isHeaderSticky
        ? { position: 'sticky', top: 0, zIndex: 'docked' }
        : {};

    const isExpandable = useMemo(() => dataSource.some((d) => d.expandedContent), [dataSource]);

    return (
        <Stack spacing="1rem">
            <TableContainer overflowY="auto" {...tableContainerProps}>
                <Table variant={variant} {...tableProps}>
                    {settings?.caption && <TableCaption>{settings.caption}</TableCaption>}
                    <Thead {...headerProps}>
                        <DataTableHeaderSection
                            columns={columns}
                            sortDirection={sortDirection}
                            onSort={handleDirectionChange}
                            sortedColumn={sortedColumn}
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            onToggleAllRows={toggleAllRows}
                            isSelectable={isSelectable}
                            isExpandable={isExpandable}
                        />
                    </Thead>
                    <Tbody>
                        <DataTableBodySection
                            filter={filter}
                            dataSource={filteredRows}
                            dataColumns={getChildColumns(columns)}
                            sortDirection={sortDirection}
                            sortedColumn={sortedColumn}
                            displayedRowsRange={displayedRowsRange}
                            toggleSelectedRow={toggleSelectedRow}
                            isSelectable={isSelectable}
                            selectedRows={selectedRows}
                            onRow={onRow}
                        />
                    </Tbody>
                    {settings?.foot && (
                        <Tfoot>
                            <Tr>
                                {settings.foot.map((value, index) => (
                                    <Td key={`footer-${index}`}>{value}</Td>
                                ))}
                            </Tr>
                        </Tfoot>
                    )}
                </Table>
                {dataSource.length === 0 && noEntriesComponent && (
                    <Center padding="4.5rem">{noEntriesComponent}</Center>
                )}
            </TableContainer>

            {isPaginated && numberOfPages > 1 && (
                <TablePaginator
                    selectedPage={selectedPage}
                    numberOfPages={numberOfPages}
                    paginationOptions={paginationOptions}
                    setSelectedPage={setSelectedPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(value) => {
                        setSelectedPage(1);
                        setRowsPerPage(value);
                    }}
                    paginatorPosition={paginatorPosition}
                />
            )}
        </Stack>
    );
};
