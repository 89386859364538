import {routing} from '@configs';
import {RouteObject} from 'react-router-dom';
import {GlobalCampaignsPage} from "@apps/attention360/pages/campaigns/GlobalCampaignsPage";
import {GlobalCampaignDetailsPage} from "@apps/attention360/pages/campaigns/GlobalCampaignDetailsPage";

export const CampaignRoutes: Array<RouteObject> = [
    {
        index: true,
        path: routing.campaign.root.path,
        element: <GlobalCampaignsPage/>
    },
    {
        path: `${routing.campaign.root.path}/${routing.campaign.create.path}`,
        element: <GlobalCampaignDetailsPage/>,
    },
    {
        path: `${routing.campaign.root.path}/:campaignId/${routing.campaign.edit.path}`,
        children: [
            {
                index: true,
                element: <GlobalCampaignDetailsPage/>
            }
        ]
    }
];
