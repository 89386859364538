/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ChannelRequestDto
 */
export interface ChannelRequestDto {
    /**
     *
     * @type {string}
     * @memberof ChannelRequestDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ChannelRequestDto
     */
    adFormatCodes?: Array<string> | null;
}

/**
 * Check if a given object implements the ChannelRequestDto interface.
 */
export function instanceOfChannelRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelRequestDtoFromJSON(json: any): ChannelRequestDto {
    return ChannelRequestDtoFromJSONTyped(json, false);
}

export function ChannelRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adFormatCodes: !exists(json, 'ad_format_codes') ? undefined : json['ad_format_codes'],
    };
}

export function ChannelRequestDtoToJSON(value?: ChannelRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_channel_code: value.adChannelCode,
        ad_format_codes: value.adFormatCodes,
    };
}
