/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    ProblemDetails,
    ScenarioResponseDto,
    ScenarioShareLinkResponseDto,
    ScenarioWithComparisonResponseDto,
} from '../models';
import {
    GetScenarioBasicInfoResponseDtoFromJSON,
    GetScenarioBasicInfoResponseDtoToJSON,
    GetScenarioLevelResultComparisonResponseDtoFromJSON,
    GetScenarioLevelResultComparisonResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ScenarioResponseDtoFromJSON,
    ScenarioResponseDtoToJSON,
    ScenarioShareLinkResponseDtoFromJSON,
    ScenarioShareLinkResponseDtoToJSON,
    ScenarioWithComparisonResponseDtoFromJSON,
    ScenarioWithComparisonResponseDtoToJSON,
} from '../models';

export interface CreateNewScenarioSharedLinkRequest {
    scenarioId?: string;
}

export interface DeleteScenarioShareLinkRequest {
    scenarioId?: string;
}

export interface GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest {
    scenarioShareLinkId: string;
    compareWith?: string;
}

export interface GetSharedScenarioBasicInfoByShareLinkIdRequest {
    scenarioShareLinkId: string;
}

export interface GetSharedScenarioByShareLinkIdRequest {
    scenarioShareLinkId: string;
}

export interface GetSharedScenarioLevelComparisonOfScenariosRequest {
    scenarioShareLinkId: string;
    compareWith?: string;
}

/**
 * ShareScenarioApi - interface
 *
 * @export
 * @interface ShareScenarioApiInterface
 */
export interface ShareScenarioApiInterface {
    /**
     *
     * @param {string} [scenarioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    createNewScenarioSharedLinkRaw(
        requestParameters: CreateNewScenarioSharedLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioShareLinkResponseDto>>;

    /**
     */
    createNewScenarioSharedLink(
        requestParameters: CreateNewScenarioSharedLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioShareLinkResponseDto>;

    /**
     *
     * @param {string} [scenarioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    deleteScenarioShareLinkRaw(
        requestParameters: DeleteScenarioShareLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteScenarioShareLink(
        requestParameters: DeleteScenarioShareLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;

    /**
     *
     * @param {string} scenarioShareLinkId
     * @param {string} [compareWith]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
        requestParameters: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioWithComparisonResponseDto>>;

    /**
     */
    getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
        requestParameters: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioWithComparisonResponseDto>;

    /**
     *
     * @param {string} scenarioShareLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    getSharedScenarioBasicInfoByShareLinkIdRaw(
        requestParameters: GetSharedScenarioBasicInfoByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioBasicInfoResponseDto>>;

    /**
     */
    getSharedScenarioBasicInfoByShareLinkId(
        requestParameters: GetSharedScenarioBasicInfoByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioBasicInfoResponseDto>;

    /**
     *
     * @param {string} scenarioShareLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    getSharedScenarioByShareLinkIdRaw(
        requestParameters: GetSharedScenarioByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>>;

    /**
     */
    getSharedScenarioByShareLinkId(
        requestParameters: GetSharedScenarioByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto>;

    /**
     *
     * @param {string} scenarioShareLinkId
     * @param {string} [compareWith]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareScenarioApiInterface
     */
    getSharedScenarioLevelComparisonOfScenariosRaw(
        requestParameters: GetSharedScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioLevelResultComparisonResponseDto>>;

    /**
     */
    getSharedScenarioLevelComparisonOfScenarios(
        requestParameters: GetSharedScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioLevelResultComparisonResponseDto>;
}

/**
 *
 */
export class ShareScenarioApi extends runtime.BaseAPI implements ShareScenarioApiInterface {
    /**
     */
    async createNewScenarioSharedLinkRaw(
        requestParameters: CreateNewScenarioSharedLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioShareLinkResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.scenarioId !== undefined) {
            queryParameters['scenarioId'] = requestParameters.scenarioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioShareLinkResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createNewScenarioSharedLink(
        requestParameters: CreateNewScenarioSharedLinkRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioShareLinkResponseDto> {
        const response = await this.createNewScenarioSharedLinkRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async deleteScenarioShareLinkRaw(
        requestParameters: DeleteScenarioShareLinkRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.scenarioId !== undefined) {
            queryParameters['scenarioId'] = requestParameters.scenarioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteScenarioShareLink(
        requestParameters: DeleteScenarioShareLinkRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.deleteScenarioShareLinkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
        requestParameters: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioWithComparisonResponseDto>> {
        if (
            requestParameters.scenarioShareLinkId === null ||
            requestParameters.scenarioShareLinkId === undefined
        ) {
            throw new runtime.RequiredError(
                'scenarioShareLinkId',
                'Required parameter requestParameters.scenarioShareLinkId was null or undefined when calling getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenario.',
            );
        }

        const queryParameters: any = {};

        if (requestParameters.compareWith !== undefined) {
            queryParameters['compareWith'] = requestParameters.compareWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios/{scenarioShareLinkId}/compare`.replace(
                    `{${'scenarioShareLinkId'}}`,
                    encodeURIComponent(String(requestParameters.scenarioShareLinkId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioWithComparisonResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
        requestParameters: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioWithComparisonResponseDto> {
        const response =
            await this.getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }

    /**
     */
    async getSharedScenarioBasicInfoByShareLinkIdRaw(
        requestParameters: GetSharedScenarioBasicInfoByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioBasicInfoResponseDto>> {
        if (
            requestParameters.scenarioShareLinkId === null ||
            requestParameters.scenarioShareLinkId === undefined
        ) {
            throw new runtime.RequiredError(
                'scenarioShareLinkId',
                'Required parameter requestParameters.scenarioShareLinkId was null or undefined when calling getSharedScenarioBasicInfoByShareLinkId.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios/{scenarioShareLinkId}/basic-info`.replace(
                    `{${'scenarioShareLinkId'}}`,
                    encodeURIComponent(String(requestParameters.scenarioShareLinkId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetScenarioBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getSharedScenarioBasicInfoByShareLinkId(
        requestParameters: GetSharedScenarioBasicInfoByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioBasicInfoResponseDto> {
        const response = await this.getSharedScenarioBasicInfoByShareLinkIdRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getSharedScenarioByShareLinkIdRaw(
        requestParameters: GetSharedScenarioByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>> {
        if (
            requestParameters.scenarioShareLinkId === null ||
            requestParameters.scenarioShareLinkId === undefined
        ) {
            throw new runtime.RequiredError(
                'scenarioShareLinkId',
                'Required parameter requestParameters.scenarioShareLinkId was null or undefined when calling getSharedScenarioByShareLinkId.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios/{scenarioShareLinkId}`.replace(
                    `{${'scenarioShareLinkId'}}`,
                    encodeURIComponent(String(requestParameters.scenarioShareLinkId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getSharedScenarioByShareLinkId(
        requestParameters: GetSharedScenarioByShareLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto> {
        const response = await this.getSharedScenarioByShareLinkIdRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getSharedScenarioLevelComparisonOfScenariosRaw(
        requestParameters: GetSharedScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioLevelResultComparisonResponseDto>> {
        if (
            requestParameters.scenarioShareLinkId === null ||
            requestParameters.scenarioShareLinkId === undefined
        ) {
            throw new runtime.RequiredError(
                'scenarioShareLinkId',
                'Required parameter requestParameters.scenarioShareLinkId was null or undefined when calling getSharedScenarioLevelComparisonOfScenarios.',
            );
        }

        const queryParameters: any = {};

        if (requestParameters.compareWith !== undefined) {
            queryParameters['compareWith'] = requestParameters.compareWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/scenarios/{scenarioShareLinkId}/channels/compare`.replace(
                    `{${'scenarioShareLinkId'}}`,
                    encodeURIComponent(String(requestParameters.scenarioShareLinkId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetScenarioLevelResultComparisonResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getSharedScenarioLevelComparisonOfScenarios(
        requestParameters: GetSharedScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioLevelResultComparisonResponseDto> {
        const response = await this.getSharedScenarioLevelComparisonOfScenariosRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
