import ExcelJS from 'exceljs';

export const headerCellStyle: Partial<ExcelJS.Style> = {
    font: { color: { argb: 'ffffffff' }, size: 14, bold: true, name: 'Calibri' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '#ff2e6e45' },
    },
    alignment: { vertical: 'middle', horizontal: 'center' },
};

const solidBorder: Partial<ExcelJS.Borders> = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
};

export const tableHeaderCellStyle: Partial<ExcelJS.Style> = {
    font: { size: 14, bold: true, name: 'Calibri' },
    alignment: { vertical: 'middle', horizontal: 'center' },
    border: solidBorder,
};

export const tableValueCellStyle: Partial<ExcelJS.Style> = {
    font: { size: 14, name: 'Calibri' },
    border: solidBorder,
    numFmt: '#,##0.0', // 1 digit after the decimal point
};

export const valueCellStyle: Partial<ExcelJS.Style> = {
    ...tableValueCellStyle,
    numFmt: '#,##0.00', // 2 digits after the decimal point
};
