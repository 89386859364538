import React, { FC, ReactNode } from 'react';
import { routing } from '@configs';
import { Navigate } from 'react-router-dom';
import { useSubscriptionHelper } from '@hooks/subscriptions';

export const CanAccessMediaPlanning: FC<{ children: ReactNode }> = ({ children }) => {
    const { isAttentionDataOnly } = useSubscriptionHelper();

    return (
        <>{isAttentionDataOnly() ? <Navigate to={routing.manageApplications.url} /> : children}</>
    );
};
