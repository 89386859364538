/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BrandedMomentsRequestDto
 */
export interface BrandedMomentsRequestDto {
    /**
     *
     * @type {number}
     * @memberof BrandedMomentsRequestDto
     */
    start?: number;
    /**
     *
     * @type {number}
     * @memberof BrandedMomentsRequestDto
     */
    end?: number;
}

/**
 * Check if a given object implements the BrandedMomentsRequestDto interface.
 */
export function instanceOfBrandedMomentsRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BrandedMomentsRequestDtoFromJSON(json: any): BrandedMomentsRequestDto {
    return BrandedMomentsRequestDtoFromJSONTyped(json, false);
}

export function BrandedMomentsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): BrandedMomentsRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        start: !exists(json, 'start') ? undefined : json['start'],
        end: !exists(json, 'end') ? undefined : json['end'],
    };
}

export function BrandedMomentsRequestDtoToJSON(value?: BrandedMomentsRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        start: value.start,
        end: value.end,
    };
}
