import { Tag } from '@chakra-ui/react';
import { MultiValueGenericProps } from 'chakra-react-select';
import { FC } from 'react';

export interface CountrySelectMultiValueContainerProps extends MultiValueGenericProps<any> {
    isUsingGlobal: boolean;
}

export const CountrySelectMultiValueContainer: FC<CountrySelectMultiValueContainerProps> = ({
    isUsingGlobal,
    ...props
}) => {
    const selectedValues = props.selectProps?.value;
    return (
        // Hide tag unless its the first one selected
        <Tag hidden={props.data.value !== selectedValues.at(0).value}>
            {/* replace tag text from the selected option to static text displaying amount of values selected */}
            {isUsingGlobal ? 'Global' : <>{`${selectedValues.length ?? 0} selected`}</>}
        </Tag>
    );
};
