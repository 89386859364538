export const directMediaInput = {
    table: {
        row: {
            budget: {
                errorMessage: 'Budget must be greater than 0',
            },
            cpm: {
                errorMessage: 'CPM must be greater than 0',
            },
            frequency: {
                errorMessage: 'Frequency must be greater than 1',
            },
            totalAudience: {
                errorMessage: 'Potential audience size must be greater than or equal to 1',
            },
            reachPercentage: {
                errorMessage:
                    'Total audience must be greater than the target audience, please adjust parameters',
            },
        },
    },
} as const;
