import { fonts, fontSizes, fontWeights, letterSpacings, lineHeights } from './typography';
import { colors } from './colors';
import { sizes } from './sizes';
import { spacing } from './spacing';

export const foundation = {
    ...colors,
    ...sizes,
    ...spacing,
    ...fonts,
    ...fontSizes,
    ...fontWeights,
    ...letterSpacings,
    ...lineHeights,
};
