import { useTranslation } from 'react-i18next';
import type {
    SubTableDataType,
    TableDataType,
} from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import { Input, InputGroup, InputLeftElement, Td } from '@chakra-ui/react';
import { isNumeric, useHelper } from '@shared/utils';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import { recalculateCpm, recalculateRow } from './tableHelper';

type SubTableEditableCellProps = {
    value: number;
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
    validate?: (val: number) => boolean;
    rowIndex: number;
    accessorKey: keyof SubTableDataType;
    adFormat: SubTableDataType;
    disabled?: boolean;
    setSubTableHasError: Dispatch<SetStateAction<Array<TableError>>>;
    removeInvalidTableError?: () => void;
};
export const SubTableEditableCell: FC<SubTableEditableCellProps> = ({
    value,
    tableData,
    rowIndex,
    setTableData,
    accessorKey,
    adFormat,
    disabled,
    setSubTableHasError,
    validate = (_: number) => true,
    removeInvalidTableError = () => {},
}) => {
    const [internalState, setInternalState] = useState('0');
    const hasTouched = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('directMediaInput');

    const isValid = (str: string) => {
        if (!hasTouched.current) {
            return true;
        }
        return isNumeric(str) && validate(parseFloat(str));
    };
    const handleChangeOnBudget = () => {
        const newTableData = tableData.map((tableRow, tableRowIndex) => {
            if (tableRowIndex !== rowIndex) {
                return tableRow;
            }
            const newTableRow: TableDataType = {
                ...tableRow,
                adFormats: tableRow.adFormats?.map((format) => {
                    if (
                        format.adFormatName?.toLowerCase() !== adFormat.adFormatName?.toLowerCase()
                    ) {
                        return format;
                    }
                    const newFormat: SubTableDataType = {
                        ...format,
                        budget: parseFloat(internalState),
                    };
                    return newFormat;
                }),
            };
            newTableRow.budget = newTableRow.adFormats?.reduce((partialSum, format) => {
                if (format.isChecked) {
                    return format.budget + partialSum;
                }
                return partialSum;
            }, 0);
            recalculateRow(newTableRow);
            return newTableRow;
        });
        setTableData(newTableData);
    };
    const handleChangeOnCpm = () => {
        const newTableData = tableData.map((tableRow, tableRowIndex) => {
            if (tableRowIndex !== rowIndex) {
                return tableRow;
            }
            const newTableRow: TableDataType = {
                ...tableRow,
                adFormats: tableRow.adFormats?.map((format) => {
                    if (
                        format.adFormatName?.toLowerCase() !== adFormat.adFormatName?.toLowerCase()
                    ) {
                        return format;
                    }
                    const newFormat: SubTableDataType = {
                        ...format,
                        cpm: parseFloat(internalState),
                    };
                    return newFormat;
                }),
            };
            recalculateCpm(newTableRow);
            recalculateRow(newTableRow);
            return newTableRow;
        });
        setTableData(newTableData);
    };
    const removeCurrentTableError = () => {
        setSubTableHasError((old) =>
            old.filter(
                (tableError) =>
                    !(
                        tableError.channelName === (tableData[rowIndex].channelName ?? '') &&
                        tableError.adFormatName !== null &&
                        tableError.adFormatName !== undefined &&
                        tableError.adFormatName === (adFormat.adFormatName ?? '') &&
                        tableError.accessorKey === accessorKey
                    ),
            ),
        );
    };
    const addCurrentTableError = () => {
        let message = '';
        switch (accessorKey) {
            case 'cpm':
                message = t('table.row.cpm.errorMessage');
                break;
            case 'budget':
                message = t('table.row.budget.errorMessage');
                break;
            default:
                break;
        }
        setSubTableHasError((old) => [
            ...old,
            {
                channelName: tableData[rowIndex].channelName ?? '',
                adFormatName: adFormat.adFormatName ?? '',
                accessorKey,
                message,
            },
        ]);
    };

    const handleOnBlur = () => {
        // save the value
        removeInvalidTableError();
        removeCurrentTableError();
        if (!isValid(internalState)) {
            addCurrentTableError();
            return;
        }
        switch (accessorKey) {
            case 'budget':
                handleChangeOnBudget();
                break;
            case 'cpm':
                handleChangeOnCpm();
                break;
            default:
                break;
        }
    };

    const { roundNumber } = useHelper();
    useEffect(() => {
        const rounded = roundNumber(value);
        setInternalState(`${rounded}`);
        return () => {
            removeCurrentTableError();
        };
    }, [value]);

    return (
        <Td padding="0.5rem">
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children="$"
                    fontSize="1.2em"
                    color="gray.500"
                />
                <Input
                    ref={inputRef}
                    textAlign="right"
                    backgroundColor="white"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="0.375rem"
                    value={internalState}
                    onBlur={() => handleOnBlur()}
                    onChange={(e) => {
                        hasTouched.current = true;
                        setInternalState(e.target.value);
                    }}
                    isInvalid={!isValid(internalState)}
                    disabled={disabled}
                    aria-disabled={disabled}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            // block 'Enter' key from submitting the form
                            e.preventDefault();
                        }
                    }}
                    onFocus={() => {
                        inputRef.current?.select();
                    }}
                />
            </InputGroup>
        </Td>
    );
};
