import {
    GetOrganisationByIdRequest,
    OrganisationApi,
    OrganisationResponseDto,
} from '@api-clients/account-manager';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';
import { useAuth0 } from '@auth0/auth0-react';

interface GetOrganisationProps {
    getOrganisation: () => Promise<OrganisationResponseDto>;
}

type useGetOrganisationHook = () => GetOrganisationProps;

export const useGetOrganisation: useGetOrganisationHook = () => {
    const { getAccountManagerConfig } = useApiConfiguration();

    const { getDisplayedErrorMessage } = useHelper();
    const { user } = useAuth0();
    const { getUserProfile } = useHelper();

    async function getOrganisation(): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: GetOrganisationByIdRequest = {
                organisationId: profile.orgId as string,
            };

            const api = new OrganisationApi(configuration);
            return await api.getOrganisationById(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        getOrganisation,
    };
};
