import { Configuration, ConfigurationParameters } from '@api-clients/runtime';
import { useAuth0 } from '@auth0/auth0-react';

interface ApiConfiguration {
    getAccountManagerConfig: () => Promise<Configuration>;
    getMediaPlanConfig: () => Promise<Configuration>;
    getMediaPlanConfigWithoutCredentials: () => Promise<Configuration>;
    getSubscriptionApiConfig: () => Promise<Configuration>;
    getAttentionDataApiConfig: () => Promise<Configuration>;
    getGlobalCampaignServerConfig: () => Promise<Configuration>;
    getProveManagementServiceConfig: () => Promise<Configuration>;
    getProveTagServiceConfig: () => Promise<Configuration>;
    getProveReportServiceConfig: () => Promise<Configuration>;
}

type useApiConfigurationHook = () => ApiConfiguration;

export const useApiConfiguration: useApiConfigurationHook = () => {
    const { getAccessTokenSilently } = useAuth0();

    async function getConfiguration(basePath: string | undefined): Promise<Configuration> {
        const accessToken = await getAccessTokenSilently();

        if (basePath === undefined) {
            throw new Error('Cannot read base path of the API');
        }

        const cfgParams: ConfigurationParameters = {
            basePath,
            credentials: 'include',
        };

        if (accessToken !== null) {
            cfgParams.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            };
        }

        return new Configuration(cfgParams);
    }

    async function getAccountManagerConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_ACCOUNT_MANAGER_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get AccountManager API configuration');
        }
    }

    async function getMediaPlanConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_MEDIA_PLAN_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get MediaPlans API configuration');
        }
    }

    async function getMediaPlanConfigWithoutCredentials(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_MEDIA_PLAN_API_URL as string;
            if (!basePath) {
                throw new Error('Cannot read base path of the API');
            }
            const cfgParams: ConfigurationParameters = {
                basePath,
                credentials: 'include',
            };
            return new Configuration(cfgParams);
        } catch (error) {
            throw new Error('Unable to get MediaPlans API configuration without credentials');
        }
    }

    async function getSubscriptionApiConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_SUBSCRIPTIONS_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get Subscriptions API configuration');
        }
    }

    async function getAttentionDataApiConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_ATTENTION_DATA_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get Attention Data API configuration');
        }
    }

    async function getGlobalCampaignServerConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_GLOBAL_CAMPAIGN_SERVER_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get campaign server configuration');
        }
    }

    async function getProveManagementServiceConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_PROVE_MANAGEMENT_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get PROVE management API configuration');
        }
    }

    async function getProveTagServiceConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_PROVE_TAG_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get PROVE tag API configuration');
        }
    }

    async function getProveReportServiceConfig(): Promise<Configuration> {
        try {
            const basePath = import.meta.env.VITE_PROVE_REPORT_API_URL as string;
            return await getConfiguration(basePath);
        } catch (e) {
            throw new Error('Unable to get PROVE report API configuration');
        }
    }

    return {
        getAccountManagerConfig,
        getMediaPlanConfig,
        getMediaPlanConfigWithoutCredentials,
        getSubscriptionApiConfig,
        getAttentionDataApiConfig,
        getGlobalCampaignServerConfig,
        getProveManagementServiceConfig,
        getProveTagServiceConfig,
        getProveReportServiceConfig
    };
};
