/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    CreateOptimisedScenariosRequestDto,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    GetScenarioResponseDto,
    ProblemDetails,
    ScenarioResponseDto,
    ScenarioWithComparisonResponseDto,
    ShareUserAccessDto,
    UpdateScenarioRequestDto,
} from '../models';
import {
    CreateOptimisedScenariosRequestDtoFromJSON,
    CreateOptimisedScenariosRequestDtoToJSON,
    GetScenarioBasicInfoResponseDtoFromJSON,
    GetScenarioBasicInfoResponseDtoToJSON,
    GetScenarioLevelResultComparisonResponseDtoFromJSON,
    GetScenarioLevelResultComparisonResponseDtoToJSON,
    GetScenarioResponseDtoFromJSON,
    GetScenarioResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ScenarioResponseDtoFromJSON,
    ScenarioResponseDtoToJSON,
    ScenarioWithComparisonResponseDtoFromJSON,
    ScenarioWithComparisonResponseDtoToJSON,
    ShareUserAccessDtoFromJSON,
    ShareUserAccessDtoToJSON,
    UpdateScenarioRequestDtoFromJSON,
    UpdateScenarioRequestDtoToJSON,
} from '../models';

export interface CreateNewOptimisedScenariosRequest {
    campaignId: string;
    createOptimisedScenariosRequestDto?: CreateOptimisedScenariosRequestDto;
}

export interface CreateScenarioUserAccessRequest {
    scenarioId: string;
    userId: string;
    campaignId: string;
}

export interface DeleteAScenarioRequest {
    scenarioId: string;
    campaignId: string;
}

export interface DeleteScenarioUserAccessRequest {
    scenarioId: string;
    userId: string;
    campaignId: string;
}

export interface GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest {
    scenarioId: string;
    campaignId: string;
    compareWith?: string;
}

export interface GetScenarioBasicInfoByIdRequest {
    scenarioId: string;
    campaignId: string;
}

export interface GetScenarioByIdRequest {
    scenarioId: string;
    campaignId: string;
}

export interface GetScenarioLevelComparisonOfScenariosRequest {
    scenarioId: string;
    campaignId: string;
    compareWith?: string;
}

export interface GetScenarioUserAccessRequest {
    scenarioId: string;
    campaignId: string;
}

export interface ListAllScenariosOfACampaignRequest {
    campaignId: string;
}

export interface UpdateAScenarioRequest {
    scenarioId: string;
    campaignId: string;
    updateScenarioRequestDto?: UpdateScenarioRequestDto;
}

export interface UpdateScenarioUserAccessVisibilityRequest {
    scenarioId: string;
    campaignId: string;
    body?: boolean;
}

/**
 * ScenarioApi - interface
 *
 * @export
 * @interface ScenarioApiInterface
 */
export interface ScenarioApiInterface {
    /**
     *
     * @param {string} campaignId
     * @param {CreateOptimisedScenariosRequestDto} [createOptimisedScenariosRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    createNewOptimisedScenariosRaw(
        requestParameters: CreateNewOptimisedScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScenarioResponseDto>>>;

    /**
     */
    createNewOptimisedScenarios(
        requestParameters: CreateNewOptimisedScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScenarioResponseDto>>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} userId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    createScenarioUserAccessRaw(
        requestParameters: CreateScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ShareUserAccessDto>>;

    /**
     */
    createScenarioUserAccess(
        requestParameters: CreateScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ShareUserAccessDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    deleteAScenarioRaw(
        requestParameters: DeleteAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>>;

    /**
     */
    deleteAScenario(
        requestParameters: DeleteAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} userId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    deleteScenarioUserAccessRaw(
        requestParameters: DeleteScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteScenarioUserAccess(
        requestParameters: DeleteScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {string} [compareWith]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    getAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
        requestParameters: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioWithComparisonResponseDto>>;

    /**
     */
    getAScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
        requestParameters: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioWithComparisonResponseDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    getScenarioBasicInfoByIdRaw(
        requestParameters: GetScenarioBasicInfoByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioBasicInfoResponseDto>>;

    /**
     */
    getScenarioBasicInfoById(
        requestParameters: GetScenarioBasicInfoByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioBasicInfoResponseDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    getScenarioByIdRaw(
        requestParameters: GetScenarioByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>>;

    /**
     */
    getScenarioById(
        requestParameters: GetScenarioByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {string} [compareWith]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    getScenarioLevelComparisonOfScenariosRaw(
        requestParameters: GetScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioLevelResultComparisonResponseDto>>;

    /**
     */
    getScenarioLevelComparisonOfScenarios(
        requestParameters: GetScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioLevelResultComparisonResponseDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    getScenarioUserAccessRaw(
        requestParameters: GetScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ShareUserAccessDto>>>;

    /**
     */
    getScenarioUserAccess(
        requestParameters: GetScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ShareUserAccessDto>>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    listAllScenariosOfACampaignRaw(
        requestParameters: ListAllScenariosOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GetScenarioResponseDto>>>;

    /**
     */
    listAllScenariosOfACampaign(
        requestParameters: ListAllScenariosOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GetScenarioResponseDto>>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {UpdateScenarioRequestDto} [updateScenarioRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    updateAScenarioRaw(
        requestParameters: UpdateAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>>;

    /**
     */
    updateAScenario(
        requestParameters: UpdateAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto>;

    /**
     *
     * @param {string} scenarioId
     * @param {string} campaignId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApiInterface
     */
    updateScenarioUserAccessVisibilityRaw(
        requestParameters: UpdateScenarioUserAccessVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    updateScenarioUserAccessVisibility(
        requestParameters: UpdateScenarioUserAccessVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;
}

/**
 *
 */
export class ScenarioApi extends runtime.BaseAPI implements ScenarioApiInterface {
    /**
     */
    async createNewOptimisedScenariosRaw(
        requestParameters: CreateNewOptimisedScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScenarioResponseDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling createNewOptimisedScenarios.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOptimisedScenariosRequestDtoToJSON(
                    requestParameters.createOptimisedScenariosRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ScenarioResponseDtoFromJSON),
        );
    }

    /**
     */
    async createNewOptimisedScenarios(
        requestParameters: CreateNewOptimisedScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScenarioResponseDto>> {
        const response = await this.createNewOptimisedScenariosRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async createScenarioUserAccessRaw(
        requestParameters: CreateScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ShareUserAccessDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling createScenarioUserAccess.',
            );
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling createScenarioUserAccess.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling createScenarioUserAccess.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/access/{userId}`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId)))
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ShareUserAccessDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createScenarioUserAccess(
        requestParameters: CreateScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ShareUserAccessDto> {
        const response = await this.createScenarioUserAccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAScenarioRaw(
        requestParameters: DeleteAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling deleteAScenario.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling deleteAScenario.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/${encodeURIComponent(String(requestParameters.campaignId))}/scenarios/${encodeURIComponent(String(requestParameters.scenarioId))}`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<number>(response);
    }

    /**
     */
    async deleteAScenario(
        requestParameters: DeleteAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number> {
        const response = await this.deleteAScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteScenarioUserAccessRaw(
        requestParameters: DeleteScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling deleteScenarioUserAccess.',
            );
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling deleteScenarioUserAccess.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling deleteScenarioUserAccess.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/access/{userId}`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId)))
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteScenarioUserAccess(
        requestParameters: DeleteScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.deleteScenarioUserAccessRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
        requestParameters: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioWithComparisonResponseDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling getAScenarioComparedWithAGivenScenarioOrTheBaselineScenario.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getAScenarioComparedWithAGivenScenarioOrTheBaselineScenario.',
            );
        }

        const queryParameters: any = {};

        if (requestParameters.compareWith !== undefined) {
            queryParameters['compareWith'] = requestParameters.compareWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/compare`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioWithComparisonResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getAScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
        requestParameters: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioWithComparisonResponseDto> {
        const response = await this.getAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getScenarioBasicInfoByIdRaw(
        requestParameters: GetScenarioBasicInfoByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioBasicInfoResponseDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling getScenarioBasicInfoById.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getScenarioBasicInfoById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/basic-info`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetScenarioBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getScenarioBasicInfoById(
        requestParameters: GetScenarioBasicInfoByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioBasicInfoResponseDto> {
        const response = await this.getScenarioBasicInfoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getScenarioByIdRaw(
        requestParameters: GetScenarioByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling getScenarioById.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getScenarioById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getScenarioById(
        requestParameters: GetScenarioByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto> {
        const response = await this.getScenarioByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getScenarioLevelComparisonOfScenariosRaw(
        requestParameters: GetScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetScenarioLevelResultComparisonResponseDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling getScenarioLevelComparisonOfScenarios.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getScenarioLevelComparisonOfScenarios.',
            );
        }

        const queryParameters: any = {};

        if (requestParameters.compareWith !== undefined) {
            queryParameters['compareWith'] = requestParameters.compareWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/${encodeURIComponent(String(requestParameters.campaignId))}/scenarios/${encodeURIComponent(String(requestParameters.scenarioId))}/channels/compare`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetScenarioLevelResultComparisonResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getScenarioLevelComparisonOfScenarios(
        requestParameters: GetScenarioLevelComparisonOfScenariosRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetScenarioLevelResultComparisonResponseDto> {
        const response = await this.getScenarioLevelComparisonOfScenariosRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getScenarioUserAccessRaw(
        requestParameters: GetScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ShareUserAccessDto>>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling getScenarioUserAccess.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getScenarioUserAccess.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/access`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ShareUserAccessDtoFromJSON),
        );
    }

    /**
     */
    async getScenarioUserAccess(
        requestParameters: GetScenarioUserAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ShareUserAccessDto>> {
        const response = await this.getScenarioUserAccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listAllScenariosOfACampaignRaw(
        requestParameters: ListAllScenariosOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GetScenarioResponseDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling listAllScenariosOfACampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(GetScenarioResponseDtoFromJSON),
        );
    }

    /**
     */
    async listAllScenariosOfACampaign(
        requestParameters: ListAllScenariosOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GetScenarioResponseDto>> {
        const response = await this.listAllScenariosOfACampaignRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updateAScenarioRaw(
        requestParameters: UpdateAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScenarioResponseDto>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling updateAScenario.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updateAScenario.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateScenarioRequestDtoToJSON(requestParameters.updateScenarioRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ScenarioResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateAScenario(
        requestParameters: UpdateAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScenarioResponseDto> {
        const response = await this.updateAScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateScenarioUserAccessVisibilityRaw(
        requestParameters: UpdateScenarioUserAccessVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling updateScenarioUserAccessVisibility.',
            );
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updateScenarioUserAccessVisibility.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/scenarios/{scenarioId}/access/visibility`
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    )
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body as any,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateScenarioUserAccessVisibility(
        requestParameters: UpdateScenarioUserAccessVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.updateScenarioUserAccessVisibilityRaw(requestParameters, initOverrides);
    }
}
