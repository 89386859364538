import { ComponentStyleConfig } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/styled-system';

export const Accordion: ComponentStyleConfig = {
    parts: ['container', 'button', 'panel', 'icon'],
    variants: {
        navContentParent: (props: StyleFunctionProps) => {
            const c = props.colorScheme;
            return {
                button: {
                    paddingX: '16px',
                    paddingY: 0,
                    color: c === 'gray' ? 'gray.600' : `${c}.500`,
                    bg: c !== 'gray' && `${c}.50`,
                    borderRadius: 'md',
                    fontWeight: 'bold',
                    height: '40px',
                    textAlign: 'left',
                    _hover: {
                        bg: c === 'gray' && 'gray.50',
                    },
                },
                panel: {
                    paddingY: 2,
                    paddingRight: 2,
                    marginY: 0,
                },
                container: {
                    padding: 0,
                    margin: 0,
                    borderWidth: 0,
                    _last: {
                        borderBottomWidth: 0,
                    },
                },
            };
        },
        expandableCard: {
            button: {
                bgColor: 'gray.50',
                borderRadius: 'lg',
                _expanded: {
                    borderTopRadius: 0,
                },
            },
            panel: {},
            container: {
                borderRadius: 'lg',
                borderWidth: '1px',
            },
        },
    },
};
