import { useApiConfiguration } from '@hooks/configuration';
import { ResourceSharingApi, ShareMediaPlanRequest } from '@api-clients/account-manager';
import { useHelper } from '@shared/utils';

interface ShareMediaPlanHookProps {
    shareMediaPlan: (request: ShareMediaPlanRequest) => Promise<void>;
}

type ShareMediaPlanHook = () => ShareMediaPlanHookProps;

export const useShareMediaPlan: ShareMediaPlanHook = () => {
    const { getAccountManagerConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function shareMediaPlan(request: ShareMediaPlanRequest) {
        try {
            const config = await getAccountManagerConfig();
            const api = new ResourceSharingApi(config);
            return await api.shareMediaPlan(request);
        } catch (e: any) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        shareMediaPlan,
    };
};
