import { FC } from 'react';
import { Box, Divider, Flex, Skeleton } from '@chakra-ui/react';

type CampaignCreationDetailsTabSkeletonProps = {};

export const CampaignCreationDetailsTabSkeleton: FC<
    CampaignCreationDetailsTabSkeletonProps
> = () => {
    return (
        <Box px="3rem" py="2rem">
            <Flex flexDir="column" gap="1rem" maxW="42rem" mx="auto">
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Divider my="1.5rem" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
                <Skeleton h="2.25rem" borderRadius="0.375rem" shadow="sm" />
            </Flex>
        </Box>
    );
};
