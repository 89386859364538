import {
    Button,
    Card,
    CardBody,
    Center,
    Container,
    Heading,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useLogout } from '@hooks/_contexts';

export const SignInErrorCard: FC = () => {
    const { logout } = useLogout();
    const handleClick = () => {
        logout({ force: true });
    };
    return (
        <Container paddingTop="10vh">
            <Card width="45rem" mx="auto">
                <CardBody>
                    <VStack>
                        <Center>
                            <Heading>An error has occurred</Heading>
                        </Center>
                        <Center>
                            <Text>Whoops! Something went wrong there, please try again.</Text>
                        </Center>
                        <Center>
                            <Button
                                variant="link"
                                type="button"
                                textDecoration="underline"
                                onClick={() => {
                                    handleClick();
                                }}
                            >
                                Redirect back to login page
                            </Button>
                        </Center>
                    </VStack>
                </CardBody>
            </Card>
        </Container>
    );
};
