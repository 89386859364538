const AccountManagerRuntime = 'AccountManager.Runtime';
const SubscriptionRuntime = 'Subscription.Runtime';
const AttentionPlan = 'AttentionPlan';

export const CacheKey = {
    User: `${AccountManagerRuntime}.User`,
    Organisation: `${AccountManagerRuntime}.Organisation`,
    Subscription: `${SubscriptionRuntime}.Subscriptions`,
    FeatureBalances: `${SubscriptionRuntime}.FeatureBalances`,
    StrictlyNecessaryCookies: 'strictlyNecessaryCookies',
    PerformanceCookies: 'performanceCookies',
    UsageReminderCookies: 'usageReminderCookies',
    DismissedUserPrompts: 'dismissedUserPrompts',
    ChangelogCookies: 'changelogCookies',
    GuidelineCookies: 'guidelineCookies',
    ProductTours: `${AttentionPlan}.ProductTours`,
};
