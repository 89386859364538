import {
    ScenarioApi,
    GetScenarioUserAccessRequest,
    ShareUserAccessDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';

import { useHelper } from '@shared/utils';

interface GetUserScenarioAccessHook {
    getUserScenarioAcess: (campaignId: string, scenarioId: string) => Promise<ShareUserAccessDto[]>;
}

type useGetUserScenarioAccessProps = () => GetUserScenarioAccessHook;

export const useGetUserScenarioAccess: useGetUserScenarioAccessProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getUserScenarioAcess(
        campaignId: string,
        scenarioId: string,
    ): Promise<ShareUserAccessDto[]> {
        try {
            const config = await getMediaPlanConfig();
            const request: GetScenarioUserAccessRequest = {
                scenarioId,
                campaignId,
            };
            const api = new ScenarioApi(config);
            return await api.getScenarioUserAccess(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        getUserScenarioAcess,
    };
};
