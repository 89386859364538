/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { CampaignStatus } from './CampaignStatus';
import {
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
} from './CampaignStatus';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { ImportedFileRequestDto } from './ImportedFileRequestDto';
import {
    ImportedFileRequestDtoFromJSON,
    ImportedFileRequestDtoFromJSONTyped,
    ImportedFileRequestDtoToJSON,
} from './ImportedFileRequestDto';
import type { ScenarioAdFormatRequestDto } from './ScenarioAdFormatRequestDto';
import {
    ScenarioAdFormatRequestDtoFromJSON,
    ScenarioAdFormatRequestDtoFromJSONTyped,
    ScenarioAdFormatRequestDtoToJSON,
} from './ScenarioAdFormatRequestDto';

/**
 *
 * @export
 * @interface UpdateDraftCampaignRequestDto
 */
export interface UpdateDraftCampaignRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    organisationId?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    createdByUserId?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    managedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    adClientId?: string | null;
    /**
     *
     * @type {CampaignStatus}
     * @memberof UpdateDraftCampaignRequestDto
     */
    campaignStatus?: CampaignStatus;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    campaignName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    referenceNumber?: string | null;
    /**
     *
     * @type {number}
     * @memberof UpdateDraftCampaignRequestDto
     */
    totalBudget?: number | null;
    /**
     *
     * @type {CurrencyCode}
     * @memberof UpdateDraftCampaignRequestDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {Date}
     * @memberof UpdateDraftCampaignRequestDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateDraftCampaignRequestDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {ImportedFileRequestDto}
     * @memberof UpdateDraftCampaignRequestDto
     */
    importedFile?: ImportedFileRequestDto;
    /**
     *
     * @type {Array<ScenarioAdFormatRequestDto>}
     * @memberof UpdateDraftCampaignRequestDto
     */
    adFormats?: Array<ScenarioAdFormatRequestDto> | null;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof UpdateDraftCampaignRequestDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof UpdateDraftCampaignRequestDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof UpdateDraftCampaignRequestDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdateDraftCampaignRequestDto
     */
    toCreateBaselineScenario?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof UpdateDraftCampaignRequestDto
     */
    campaignObjective?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdateDraftCampaignRequestDto
     */
    isUsingGlobalCountry?: boolean;
}

/**
 * Check if a given object implements the UpdateDraftCampaignRequestDto interface.
 */
export function instanceOfUpdateDraftCampaignRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDraftCampaignRequestDtoFromJSON(json: any): UpdateDraftCampaignRequestDto {
    return UpdateDraftCampaignRequestDtoFromJSONTyped(json, false);
}

export function UpdateDraftCampaignRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateDraftCampaignRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationId: !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
        managedByUserId: !exists(json, 'managed_by_user_id')
            ? undefined
            : json['managed_by_user_id'],
        adClientId: !exists(json, 'ad_client_id') ? undefined : json['ad_client_id'],
        campaignStatus: !exists(json, 'campaign_status')
            ? undefined
            : CampaignStatusFromJSON(json['campaign_status']),
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
        referenceNumber: !exists(json, 'reference_number') ? undefined : json['reference_number'],
        totalBudget: !exists(json, 'total_budget') ? undefined : json['total_budget'],
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        importedFile: !exists(json, 'imported_file')
            ? undefined
            : ImportedFileRequestDtoFromJSON(json['imported_file']),
        adFormats: !exists(json, 'ad_formats')
            ? undefined
            : json['ad_formats'] === null
            ? null
            : (json['ad_formats'] as Array<any>).map(ScenarioAdFormatRequestDtoFromJSON),
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        toCreateBaselineScenario: !exists(json, 'to_create_baseline_scenario')
            ? undefined
            : json['to_create_baseline_scenario'],
        campaignObjective: !exists(json, 'campaign_objective')
            ? undefined
            : json['campaign_objective'],
        isUsingGlobalCountry: !exists(json, 'is_using_global_country')
            ? undefined
            : json['is_using_global_country'],
    };
}

export function UpdateDraftCampaignRequestDtoToJSON(
    value?: UpdateDraftCampaignRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_id: value.organisationId,
        created_by_user_id: value.createdByUserId,
        managed_by_user_id: value.managedByUserId,
        ad_client_id: value.adClientId,
        campaign_status: CampaignStatusToJSON(value.campaignStatus),
        campaign_name: value.campaignName,
        reference_number: value.referenceNumber,
        total_budget: value.totalBudget,
        currency: CurrencyCodeToJSON(value.currency),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        imported_file: ImportedFileRequestDtoToJSON(value.importedFile),
        ad_formats:
            value.adFormats === undefined
                ? undefined
                : value.adFormats === null
                ? null
                : (value.adFormats as Array<any>).map(ScenarioAdFormatRequestDtoToJSON),
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        to_create_baseline_scenario: value.toCreateBaselineScenario,
        campaign_objective: value.campaignObjective,
        is_using_global_country: value.isUsingGlobalCountry,
    };
}
