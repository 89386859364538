import { Center, keyframes, Spinner, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingProps {
    loadingText?: string;
}

export const fade = keyframes`
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
`;

export const Loading: FC<LoadingProps> = ({ loadingText }) => {
    const { t } = useTranslation('generic');
    const text = loadingText ?? t('spinner.default');
    return (
        <Center
            pointerEvents="none"
            pos="fixed"
            left={0}
            top={0}
            w="100vw"
            h="100vh"
            display="flex"
            zIndex="modal"
            justifyContent="center"
            alignItems="center"
        >
            <VStack>
                <Spinner size="2xl" thickness="3px" speed="0.6s" />
                <Text animation={`${fade} infinite 3s linear`}>{text}</Text>
            </VStack>
        </Center>
    );
};
