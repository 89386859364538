import { Button, Center, Flex, Heading, Icon, Link as ChakraLink, Spacer, Text, VStack } from '@chakra-ui/react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { AmplifiedColorScheme } from '@shared/cores';
import { FC } from 'react';
import { useAppContextHelper } from '@hooks/_contexts';
import posthog from 'posthog-js';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export interface ImportTabBannerProps {
    colorScheme?: AmplifiedColorScheme;
    templateUrl: string;
    headingText: string;
    subheadingText?: string;
    bodyText: string;
}

export const ImportTabBanner: FC<ImportTabBannerProps> = ({ colorScheme = 'green', templateUrl, headingText, subheadingText, bodyText }) => {
    const {
        currentContextValues: { organisation, user },
    } = useAppContextHelper();

    function sendEventToPostHog() {
        posthog.capture('Download template (2/5)', {
            organisation_id: organisation!.id!,
            organisation_name: organisation!.organisationName!,
            user_id: user!.id!,
            user_name: `${user!.firstName!} ${user!.lastName!}`,
            timestamp: Date.now().toLocaleString(),
        });
    }

    return (
        <Flex paddingX="2.5rem" paddingY="1.5rem" bgColor={`${colorScheme}.50`}>
            <VStack align="left">
                <Heading
                    color="gray.800"
                    size="sm"
                    fontSize="1.125rem"
                    fontWeight={700}
                    lineHeight="1.75rem"
                >
                    {headingText}
                </Heading>
                {subheadingText && (
                    <Text
                        color="gray.600"
                        fontSize="0.875rem"
                        fontWeight={600}
                        lineHeight="1.25rem"
                    >
                        {subheadingText}
                    </Text>
                )}
                <Flex alignItems="center" gap="0.375rem">
                    <Icon as={ExclamationCircleIcon} color="yellow.500" strokeWidth="2px" />
                    <Text
                        color="gray.600"
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.25rem"
                    >
                        {bodyText}
                    </Text>
                </Flex>
            </VStack>
            <Spacer />
                <VStack>
                    <Button
                        onClick={() => sendEventToPostHog()}
                        variant="outline"
                        colorScheme={colorScheme}
                        leftIcon={<Icon as={DocumentArrowDownIcon} />}
                        as={ChakraLink}
                        download
                        href={templateUrl}
                        isExternal={false}
                    >
                        Download Template
                    </Button>
                    <Spacer/>
                </VStack>
        </Flex>
    );
};
