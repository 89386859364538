/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';

/**
 *
 * @export
 * @interface GetFormatLevelResultComparisonResponseDto
 */
export interface GetFormatLevelResultComparisonResponseDto {
    /**
     *
     * @type {string}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    comparedWithId?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    formatName?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    formatCode?: string | null;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    budget?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    mix?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    impressions?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    passiveAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {Date}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    createdAt?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof GetFormatLevelResultComparisonResponseDto
     */
    updatedAt?: Date | null;
}

/**
 * Check if a given object implements the GetFormatLevelResultComparisonResponseDto interface.
 */
export function instanceOfGetFormatLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetFormatLevelResultComparisonResponseDtoFromJSON(
    json: any,
): GetFormatLevelResultComparisonResponseDto {
    return GetFormatLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function GetFormatLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetFormatLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        comparedWithId: !exists(json, 'compared_with_id') ? undefined : json['compared_with_id'],
        formatName: !exists(json, 'format_name') ? undefined : json['format_name'],
        formatCode: !exists(json, 'format_code') ? undefined : json['format_code'],
        budget: !exists(json, 'budget')
            ? undefined
            : DecimalComparisonValueFromJSON(json['budget']),
        mix: !exists(json, 'mix') ? undefined : DecimalComparisonValueFromJSON(json['mix']),
        impressions: !exists(json, 'impressions')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impressions']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_volume_seconds']),
        createdAt: !exists(json, 'created_at')
            ? undefined
            : json['created_at'] === null
            ? null
            : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at')
            ? undefined
            : json['updated_at'] === null
            ? null
            : new Date(json['updated_at']),
    };
}

export function GetFormatLevelResultComparisonResponseDtoToJSON(
    value?: GetFormatLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        compared_with_id: value.comparedWithId,
        format_name: value.formatName,
        format_code: value.formatCode,
        budget: DecimalComparisonValueToJSON(value.budget),
        mix: DecimalComparisonValueToJSON(value.mix),
        impressions: DecimalComparisonValueToJSON(value.impressions),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        passive_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.passiveAttentionVolumeSeconds,
        ),
        created_at:
            value.createdAt === undefined
                ? undefined
                : value.createdAt === null
                ? null
                : value.createdAt.toISOString(),
        updated_at:
            value.updatedAt === undefined
                ? undefined
                : value.updatedAt === null
                ? null
                : value.updatedAt.toISOString(),
    };
}
