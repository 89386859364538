import {
    CampaignApi,
    CampaignResponseDto,
    GetCampaignResponseDto,
    GetCampaignWithConstraintByCampaignIdRequest,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetCampaignConstraintsHook = () => {
    getCampaignConstraints: (campaignId: string) => Promise<GetCampaignResponseDto>;
};

export const useGetCampaignConstraints: useGetCampaignConstraintsHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getCampaignConstraints(campaignId: string) {
        try {
            const config = await getMediaPlanConfig();
            const api = new CampaignApi(config);

            return await api.getCampaignWithConstraintByCampaignId({ campaignId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getCampaignConstraints };
};
