import React, {FC, useEffect, useState} from 'react'
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {useDispatch} from "@redux";
import {useApiConfiguration} from "@hooks/configuration";
import {useForm} from "react-hook-form";
import {saveProveLineItemVastAsync} from "@redux/slices/prove/lineItem/thunks";
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Icon,
    Input,
    Text,
    Textarea,
    Wrap
} from "@chakra-ui/react";
import {CloseIcon} from "@chakra-ui/icons";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";

export interface LineItemVastModalBodyProps {
    selectedCampaign: ProveCampaign;
    selectedLineItem: ProveLineItem;
}

interface FormData {
    url: string;
    macros: string;
}

export const ProveLineItemVastModalBody: FC<LineItemVastModalBodyProps> = ({
                                                                               selectedCampaign,
                                                                               selectedLineItem
                                                                           }) => {

    const dispatch = useDispatch();
    const {getProveManagementServiceConfig, getProveTagServiceConfig} = useApiConfiguration();
    const [macros, setMacros] = useState([] as Array<string>);
    const [customMacro, setCustomMacro] = useState(undefined as string | undefined);
    const {t} = useTranslation('prove');
    const content = t('prove.lineItems.existing.vast', {
        returnObjects: true,
    });

    useEffect(() => {
        if (!selectedLineItem.vast || selectedLineItem.vast.macros === "") {
            setMacros([] as Array<string>);

            return;
        }

        setMacros(selectedLineItem.vast!.macros.split(','));
    }, [selectedLineItem]);

    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm<FormData>({
        defaultValues: {
            url: selectedLineItem!.vast?.url
        },
        mode: 'onChange'
    });

    const addCustomMacro = () => {
        if (!customMacro) {
            return;
        }

        const updatedMacros = [...macros];
        updatedMacros.push(customMacro);

        (document.getElementById("customMacro")! as HTMLInputElement).value = "";

        for (let i = 0; i < macros.length; i++) {
            if (macros[i] === customMacro) {
                return;
            }
        }

        setMacros(updatedMacros);
    }

    const onFormSave = async (data: FormData) => {
        const managementConfiguration = await getProveManagementServiceConfig();
        const tagConfiguration = await getProveTagServiceConfig();

        dispatch(saveProveLineItemVastAsync({
            managementConfiguration,
            tagConfiguration,
            campaign: selectedCampaign,
            lineItem: selectedLineItem!,
            lineItemVast: {
                url: data.url,
                macros: macros.join(",")
            }
        }));
    };

    return (
        <Box paddingX="3rem" paddingY="2rem">
            <form onSubmit={handleSubmit(onFormSave)}>
                <Flex
                    flexDirection="column"
                    gap="1rem"
                    maxW="42rem"
                    mx="auto"
                >
                    <Text>{content.body.introContent}</Text>
                    <Box>
                        <FormControl
                            isRequired
                            isInvalid={
                                errors.url !== undefined
                            }
                        >
                            <FormLabel color={"green.500"}>{content.form.vastUrl.label}</FormLabel>
                            <Textarea rows={5}
                                      {...register('url', {
                                          required: true, onBlur: (event) => {
                                              const url = event.target?.value;

                                              if (!url) {
                                                  return;
                                              }

                                              const curlyBrackets = "\\$?\\{[^\\}]*\\}";
                                              const squareBrackets = "\\[[^\\]]*]";
                                              const doublePercent = "%%[^\\%%]*%%";

                                              const pattern = `(${curlyBrackets}|${squareBrackets}|${doublePercent})`;
                                              const regex = new RegExp(pattern, "g");

                                              const macroCandidates = url.match(regex) || [];
                                              const updatedMacros = [...macros];

                                              for (let i = 0; i < macroCandidates.length; i++) {
                                                  const candidate = macroCandidates[i];

                                                  if (!updatedMacros.includes(candidate)) {
                                                      updatedMacros.push(candidate);
                                                  }
                                              }

                                              setMacros(updatedMacros);
                                          }
                                      })}
                            />
                            <FormErrorMessage>
                                {errors.url &&
                                    (errors.url.message as string)}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Heading size={"xs"} mt={4}>{content.body.detectedTitle}</Heading>
                    <Text>{content.body.detectedContent}</Text>
                    <Box>
                        <Wrap>
                            {macros.map((entry) => {
                                return (
                                    <Button
                                        key={entry}
                                        size={"sm"}
                                        rightIcon={
                                            <Icon as={CloseIcon} boxSize="0.5rem"/>
                                        }
                                        type="button"
                                        colorScheme="gray"
                                        display="flex"
                                        alignItems="center"
                                        onClick={() => {
                                            const updatedMacros = [];

                                            for (let i = 0; i < macros.length; i++) {
                                                if (macros[i] !== entry) {
                                                    updatedMacros.push(macros[i]);
                                                }
                                            }

                                            setMacros(updatedMacros);
                                        }}
                                    >
                                        {entry}
                                    </Button>);
                            })
                            }
                        </Wrap>
                    </Box>
                    <Heading size={"xs"} mt={4}>{content.body.manualTitle}</Heading>
                    <Text>{content.body.manualContent}</Text>
                    <HStack spacing="0.5rem">
                        <Input id="customMacro"
                               placeholder={content.form.macros.placeholder}
                               type="text"
                               width={400}
                               onChange={(event) => {
                                   setCustomMacro(event.target.value)
                               }}/>
                        <Button
                            rightIcon={
                                <Icon as={PlusIcon}/>
                            }
                            type="button"
                            colorScheme="orange"
                            alignItems="center"
                            onClick={addCustomMacro}
                        >
                            {content.form.addButton}
                        </Button>
                    </HStack>
                    <Divider my="0.5rem"/>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button
                            type="submit"
                            display="flex"
                            alignItems="center"
                        >
                            {content.buttons.save}
                        </Button>
                    </Box>
                </Flex>
            </form>
        </Box>
    )
};
