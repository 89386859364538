/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AttentionCriteria } from './AttentionCriteria';
import {
    AttentionCriteriaFromJSON,
    AttentionCriteriaFromJSONTyped,
    AttentionCriteriaToJSON,
} from './AttentionCriteria';

/**
 *
 * @export
 * @interface UpdateReachCurveRequestDto
 */
export interface UpdateReachCurveRequestDto {
    /**
     *
     * @type {AttentionCriteria}
     * @memberof UpdateReachCurveRequestDto
     */
    attentionCriteria?: AttentionCriteria;
    /**
     *
     * @type {number}
     * @memberof UpdateReachCurveRequestDto
     */
    attentionThreshold?: number;
}

/**
 * Check if a given object implements the UpdateReachCurveRequestDto interface.
 */
export function instanceOfUpdateReachCurveRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateReachCurveRequestDtoFromJSON(json: any): UpdateReachCurveRequestDto {
    return UpdateReachCurveRequestDtoFromJSONTyped(json, false);
}

export function UpdateReachCurveRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateReachCurveRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        attentionCriteria: !exists(json, 'attention_criteria')
            ? undefined
            : AttentionCriteriaFromJSON(json['attention_criteria']),
        attentionThreshold: !exists(json, 'attention_threshold')
            ? undefined
            : json['attention_threshold'],
    };
}

export function UpdateReachCurveRequestDtoToJSON(value?: UpdateReachCurveRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        attention_criteria: AttentionCriteriaToJSON(value.attentionCriteria),
        attention_threshold: value.attentionThreshold,
    };
}
