/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FeatureCode } from './FeatureCode';
import { FeatureCodeFromJSON, FeatureCodeFromJSONTyped, FeatureCodeToJSON } from './FeatureCode';

/**
 *
 * @export
 * @interface UpdateUsedFeatureBalanceDto
 */
export interface UpdateUsedFeatureBalanceDto {
    /**
     *
     * @type {string}
     * @memberof UpdateUsedFeatureBalanceDto
     */
    organisationId: string;
    /**
     *
     * @type {FeatureCode}
     * @memberof UpdateUsedFeatureBalanceDto
     */
    featureCode: FeatureCode;
}

/**
 * Check if a given object implements the UpdateUsedFeatureBalanceDto interface.
 */
export function instanceOfUpdateUsedFeatureBalanceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'organisationId' in value;
    isInstance = isInstance && 'featureCode' in value;

    return isInstance;
}

export function UpdateUsedFeatureBalanceDtoFromJSON(json: any): UpdateUsedFeatureBalanceDto {
    return UpdateUsedFeatureBalanceDtoFromJSONTyped(json, false);
}

export function UpdateUsedFeatureBalanceDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateUsedFeatureBalanceDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationId: json['organisation_id'],
        featureCode: FeatureCodeFromJSON(json['feature_code']),
    };
}

export function UpdateUsedFeatureBalanceDtoToJSON(value?: UpdateUsedFeatureBalanceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_id: value.organisationId,
        feature_code: FeatureCodeToJSON(value.featureCode),
    };
}
