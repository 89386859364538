import {createAppAsyncThunk} from "@/redux/createAppAsyncThunk";
import {FindProveInsertionOrdersApi} from "@api-clients/prove/api/FindProveInsertionOrdersApi";
import {CreateProveCampaignApi} from "@api-clients/prove/api/CreateProveCampaignApi";
import {CreateProveInsertionOrderApi} from "@api-clients/prove/api/CreateProveInsertionOrderApi";
import {Configuration} from "@api-clients/runtime";
import {FindProveCampaignApi} from "@api-clients/prove/api/FindProveCampaignApi";
import {FindAllProveCampaignsApi} from "@api-clients/prove/api/FindAllProveCampaignsApi";
import {proveCampaignDemoData} from "@redux/slices/prove/campaign/demo/proveCampaign";

export const findAllProveCampaignsAsync = createAppAsyncThunk(
    'prove/campaign/findAll',
    async (
        payload: {
            configuration: Configuration
        },
        {getState}
    ) => {
        return FindAllProveCampaignsApi.execute(payload.configuration);
    }
)

export const createProveCampaignAsync = createAppAsyncThunk(
    'prove/campaign/create',
    async (
        payload: {
            configuration: Configuration,
            campaignId: string
        }
    ) => {
        const campaign = await CreateProveCampaignApi.execute(payload.configuration, payload.campaignId);

        campaign.insertionOrder = await CreateProveInsertionOrderApi.execute(
            payload.configuration,
            campaign,
            {
                name: "default"
            }
        );

        return campaign;
    }
)

export const selectProveCampaignAsync = createAppAsyncThunk(
    'prove/campaign/select',
    async (
        payload: {
            configuration: Configuration,
            campaignId: string
        },
        {getState}
    ) => {
        if (payload.campaignId === 'demo') {
            return proveCampaignDemoData;
        }

        const state = getState();

        const retrieveCampaign = async () => {
            if (state.proveCampaign.selected?.id === payload.campaignId) {
                return state.proveCampaign.selected;
            }

            return FindProveCampaignApi.execute(payload.configuration, payload.campaignId);
        }

        const campaign = await retrieveCampaign();
        if (campaign.id && !campaign.insertionOrder) {
            const insertionOrders = await FindProveInsertionOrdersApi.execute(payload.configuration, campaign);

            campaign.insertionOrder = (insertionOrders.length > 0) ?
                insertionOrders[0] :
                await CreateProveInsertionOrderApi.execute(
                    payload.configuration,
                    campaign,
                    {
                        name: "default"
                    }
                );
        }

        return campaign;
    }
)
