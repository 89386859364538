/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FromAuth0 } from './FromAuth0';
import { FromAuth0FromJSON, FromAuth0FromJSONTyped, FromAuth0ToJSON } from './FromAuth0';

/**
 *
 * @export
 * @interface ChangePasswordRequestDto
 */
export interface ChangePasswordRequestDto {
    /**
     *
     * @type {FromAuth0}
     * @memberof ChangePasswordRequestDto
     */
    auth0: FromAuth0;
}

/**
 * Check if a given object implements the ChangePasswordRequestDto interface.
 */
export function instanceOfChangePasswordRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'auth0' in value;

    return isInstance;
}

export function ChangePasswordRequestDtoFromJSON(json: any): ChangePasswordRequestDto {
    return ChangePasswordRequestDtoFromJSONTyped(json, false);
}

export function ChangePasswordRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChangePasswordRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        auth0: FromAuth0FromJSON(json['auth0']),
    };
}

export function ChangePasswordRequestDtoToJSON(value?: ChangePasswordRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        auth0: FromAuth0ToJSON(value.auth0),
    };
}
