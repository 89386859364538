import {routing} from '@configs';
import {RouteObject} from 'react-router-dom';
import {ProveDashboardPage} from "@apps/attentionPROVE/ProveDashboardPage";
import {ProveManagementPage} from "@apps/attentionPROVE/ProveManagementPage";
import {prove} from "@configs/i18n/languages/en/translation-modules/prove";

export const ProveRoutes: Array<RouteObject> = [
    {
        path: `${routing.prove.root.path}/:campaignId/${routing.prove.manage.path}`,
        children: [
            {
                index: true,
                element: <ProveManagementPage/>
            }
        ]
    },
    {
        path: `${routing.prove.root.path}/:campaignId/${routing.prove.dashboard.path}`,
        children: [
            {
                index: true,
                element: <ProveDashboardPage/>
            }
        ]
    }
];
