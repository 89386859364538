import { ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { Box, ModalProps, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { useAppContextHelper } from '@hooks/_contexts';
import { useHelper } from '@shared/utils';
import { FC, useEffect, useMemo, useState } from 'react';
import { DynamicUpgradeModal } from '@components/molecules';
import { UpgradeAdFormatHeader } from '@apps/attentionADJUST/components/atoms';
import { ChannelCpmCheckboxes } from './ChannelCpmCheckboxes';

export interface AdFormatsConstraintModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
    scenarioAdFormats: Array<ScenarioAdFormatResponseDto>;
    onConfirm: (channelData: Array<ScenarioAdFormatResponseDto>) => void;
}

export const AdFormatsConstraintModal: FC<AdFormatsConstraintModalProps> = ({
    scenarioAdFormats,
    onConfirm,
    isOpen,
    onClose,
}) => {
    const toast = useToast();
    const { getChannelSectionOfAdFormatCode } = useHelper();

    const channels = [...new Set(scenarioAdFormats.map(getChannelSectionOfAdFormatCode))];

    const getInitialAdFormats = () => {
        return new Map<string, ScenarioAdFormatResponseDto>(
            scenarioAdFormats.map((f) => [
                f.adFormatCode!,
                {
                    adFormatName: f.adFormatName,
                    adFormatCode: f.adFormatCode,
                    isChecked: f.isChecked,
                    cpm: f.cpm!,
                },
            ]),
        );
    };

    const [adFormats, setAdFormats] = useState<Map<string, ScenarioAdFormatResponseDto>>(
        getInitialAdFormats(),
    );

    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const canCustomise = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseFormatCpms,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    useEffect(() => {
        setAdFormats(getInitialAdFormats());
    }, [scenarioAdFormats]);

    const onAdFormatUpdate = (adFormatCode: string, cpm?: number, isChecked?: boolean) => {
        const adFormat = adFormats.get(adFormatCode);
        setAdFormats((map) => {
            return new Map(
                map.set(adFormatCode, {
                    ...adFormat,
                    cpm: cpm ?? adFormat?.cpm,
                    isChecked: isChecked ?? adFormat?.isChecked,
                }),
            );
        });
    };

    const handleChannelToggle = (channelCode: string) => {
        const channelAdFormats = [...adFormats.values()].filter((f) =>
            getChannelSectionOfAdFormatCode(f).includes(channelCode),
        );
        const uncheckedChannelAdFormats = channelAdFormats.filter((f) => !f.isChecked);

        setAdFormats((map) => {
            // check all if some amount of unchecked boxes in channel
            // otherwise uncheck all
            if (uncheckedChannelAdFormats.length > 0) {
                uncheckedChannelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: true });
                });
            } else {
                channelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: false });
                });
            }
            return new Map<string, ScenarioAdFormatResponseDto>(map);
        });
    };

    const isConfirmDisabled = useMemo(
        () => ![...adFormats.values()].some((f) => f.isChecked),
        [adFormats],
    );

    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    return (
        <>
            <ConfirmDialog
                headerText="Configure ad formats"
                confirmButtonText="Confirm"
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        onConfirm([...adFormats.values()]);
                        toast({
                            title: `Ad formats changed`,
                            description: `Ad formats constraints modified`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                    } else {
                        setAdFormats(getInitialAdFormats());
                    }
                    onClose();
                }}
                isOpen={isOpen}
                size="3xl"
                isConfirmDisabled={!canCustomise || isConfirmDisabled}
                modalCaption={isConfirmDisabled ? 'At least one format must be selected' : ''}
            >
                <Stack>
                    <Text color="gray.600">
                        Select the channels and formats available, and configure CPMs
                    </Text>
                    <Box>
                        {channels
                            .sort((a, b) => a.localeCompare(b))
                            .map((channelCode) => {
                                const channelFormats = [...adFormats.values()].filter((f) =>
                                    getChannelSectionOfAdFormatCode(f).includes(channelCode),
                                );
                                return (
                                    <ChannelCpmCheckboxes
                                        key={channelCode}
                                        channelCode={channelCode}
                                        formats={channelFormats}
                                        onCheckChannel={handleChannelToggle}
                                        onAdFormatUpdate={onAdFormatUpdate}
                                        isFreeTier={!canCustomise}
                                        openUpgradeModal={onUpgradeModalOpen}
                                    />
                                );
                            })}
                    </Box>
                </Stack>
            </ConfirmDialog>
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_AdFormatModal"
                isOpen={isUpgradeModalOpen}
                onClose={onUpgradeModalClose}
                header={<UpgradeAdFormatHeader />}
            />
        </>
    );
};
