import { OrganisationResponseDto, UserResponseDto } from '@api-clients/account-manager';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { Card, Flex, Spacer, StackDivider, Text, VStack } from '@chakra-ui/react';
import { DetailSection } from '@components/molecules';
import { useManageMember } from '@hooks/organisations';
import { FC } from 'react';
import { EditOrganisationDialog } from './EditOrganisationDialog';
import { getCountryName } from './OrganisationInformationViewMode';

export interface OrganisationInfoSectionProps {
    organisation: OrganisationResponseDto;
    user: UserResponseDto;
}

export const OrganisationInformationSection: FC<OrganisationInfoSectionProps> = (
    props: OrganisationInfoSectionProps,
) => {
    const { organisation, user } = props;

    const { organisationName, region } = organisation;
    const { hasAdministrativePermission } = useManageMember();

    return (
        <VStack align="left" spacing="1rem">
            <Flex>
                <ContainerTitle headingText="Manage organisation" size="xl" />
                <Spacer />
                {hasAdministrativePermission(organisation, user) && (
                    <EditOrganisationDialog organisation={organisation} />
                )}
            </Flex>
            <Card>
                <VStack divider={<StackDivider />} align="stretch" spacing="0">
                    <DetailSection
                        label="Name"
                        renderItem={<Text fontWeight="semibold">{organisationName}</Text>}
                    />
                    <DetailSection
                        label="Region"
                        renderItem={<Text fontWeight="semibold">{getCountryName(region)}</Text>}
                    />
                </VStack>
            </Card>
        </VStack>
    );
};
