import { createContext, createRef, useContext } from 'react';

const LayoutContainerContext = createContext<any>(null);
const LayoutContainerRef = createRef<any>();

export const LayoutContainerContextProvider = (props: any) => {
    return (
        <LayoutContainerContext.Provider value={LayoutContainerRef}>
            {props.children}
        </LayoutContainerContext.Provider>
    );
};

export const useLayoutContainerContext = () => useContext(LayoutContainerContext);
