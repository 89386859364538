import { FC } from 'react';
import {
    Box,
    Flex,
    Stat,
    StatArrow,
    StatHelpText,
    StatLabel,
    Text,
    GridItem,
    GridItemProps,
    Grid,
    Icon,
    Tooltip,
} from '@chakra-ui/react';
import { isAlmostEqual, toAppropriateDecimalPlace, toLocaleFixed } from '@shared/utils';
import { InfoOutlineIcon } from '@chakra-ui/icons';

type KeyOptimisedMetricCellProps = {
    value: number;
    comparedWithValue?: number;
    isPercentage?: boolean;
    title: string;
    label: string;
    toDecimalPlace?: number;
    isBaseScenario: boolean;
    tooltip?: string;
} & GridItemProps;
export const KeyOptimisedMetricCell: FC<KeyOptimisedMetricCellProps> = ({
    value,
    comparedWithValue,
    isPercentage,
    title,
    label,
    isBaseScenario,
    toDecimalPlace = 2,
    tooltip = '',
    ...props
}) => {
    let difference = 0;
    if (comparedWithValue && value) {
        if (isPercentage) {
            difference = value - comparedWithValue;
        } else {
            difference = ((value - comparedWithValue) / comparedWithValue) * 100;
        }
    }
    const formattedComparedWithValue = toLocaleFixed(comparedWithValue ?? 0, toDecimalPlace);
    const formattedValue = toLocaleFixed(value ?? 0, toDecimalPlace);
    return (
        <GridItem
            bg="white"
            borderRadius="0.5rem"
            border="1px solid"
            py="1rem"
            borderColor="gray.200"
            {...props}
            display="flex"
            flexDir="column"
            gap="1rem"
            position="relative"
        >
            {tooltip && tooltip.length > 0 && (
                <Tooltip label={tooltip} placement="left-start">
                    <Icon
                        position="absolute"
                        as={InfoOutlineIcon}
                        top="0.5rem"
                        right="0.5rem"
                        boxSize=".875rem"
                    />
                </Tooltip>
            )}
            <Text textAlign="center" fontWeight={700} color="gray.700" fontSize="0.875rem">
                {title}
            </Text>

            <Flex justifyContent="space-evenly" gap="0.5rem" alignItems="center">
                <Box>
                    <Stat
                        display="flex"
                        flexDir="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Flex gap="0.125rem" justifyContent="center">
                            <Text fontWeight={700} color="gray.900">
                                <Text as="span">{formattedComparedWithValue}</Text>
                                {isPercentage && <Text as="span">%</Text>}
                            </Text>
                        </Flex>
                        {!isBaseScenario && (
                            <StatLabel
                                fontSize="0.875rem"
                                fontWeight={600}
                                color="gray.500"
                                textAlign="center"
                            >
                                Base
                            </StatLabel>
                        )}
                    </Stat>
                </Box>
                {!isBaseScenario && (
                    <>
                        <Box w="2px" h="3rem" bg="gray.200" />
                        <Box>
                            <Stat
                                display="flex"
                                flexDir="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Flex gap="0.125rem" justifyContent="center">
                                    <Text
                                        fontWeight={700}
                                        color={difference >= 0 ? 'green.500' : 'red.500'}
                                    >
                                        <Text as="span">{formattedValue}</Text>
                                        {isPercentage && <Text as="span">%</Text>}
                                    </Text>
                                </Flex>
                                <StatLabel
                                    fontSize="0.875rem"
                                    fontWeight={600}
                                    color="gray.500"
                                    textAlign="center"
                                >
                                    {label}
                                </StatLabel>
                                {!isAlmostEqual(difference, 0) && (
                                    <StatHelpText marginBottom={0} textAlign="center">
                                        <StatArrow
                                            type={difference > 0 ? 'increase' : 'decrease'}
                                            color={difference >= 0 ? 'green.500' : 'red.500'}
                                        />
                                        {toAppropriateDecimalPlace(Math.abs(difference))}%
                                    </StatHelpText>
                                )}
                            </Stat>
                        </Box>
                    </>
                )}
            </Flex>
        </GridItem>
    );
};
