import { Center, Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useRemoveAdClient } from '@hooks/adClients/useRemoveAdClient';

import { useRemoveClientToast } from '@hooks/toast/useRemoveClientToast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export interface RemoveClientProps {
    adClientId: string;
    adClientName: string;
    onSuccessfulRemove: () => void;
}

export const RemoveClient: FC<RemoveClientProps> = ({
    adClientId,
    adClientName,
    onSuccessfulRemove,
}) => {
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();
    const { t } = useTranslation();
    const { removeAdClient } = useRemoveAdClient();
    const { successRemoveClientToast, errorRemoveClientToast } = useRemoveClientToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onRemove = async () => {
        try {
            setIsLoading(true);
            await removeAdClient(adClientId);
            successRemoveClientToast(organisation?.organisationName);
            onClose();
            onSuccessfulRemove();
        } catch (e: any) {
            errorRemoveClientToast();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Center>
                <IconButton
                    variant="ghost"
                    colorScheme="red"
                    aria-label={`delete-${adClientName}`}
                    icon={<Icon boxSize="1rem" as={TrashIcon} />}
                    onClick={onOpen}
                    size="sm"
                />
            </Center>
            <ConfirmDialog
                headerText={t('labels.dialogs.removeClient.header')}
                confirmButtonText={t('labels.dialogs.removeClient.confirmButtonText')}
                cancelButtonText={t('labels.dialogs.removeClient.cancelButtonText')}
                isOpen={isOpen}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        onRemove();
                    } else {
                        onClose();
                    }
                }}
                isDestructive
                isLoading={isLoading}
            >
                <Trans
                    i18nKey="labels.dialogs.removeClient.body"
                    values={{
                        clientName: adClientName,
                        companyName: organisation?.organisationName,
                    }}
                />
            </ConfirmDialog>
        </>
    );
};
