import { Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FC } from 'react';

const SUBTABLE_HEADERS = [
    {
        id: 1,
        label: 'Format',
    },
    {
        id: 2,
        label: 'Budget',
    },
    {
        id: 3,
        label: 'CPM',
    },
];
const subTableHeaders = SUBTABLE_HEADERS.sort((a, b) => a.id - b.id);
type SubTableHeaderProps = {};
const SubTableHeader: FC<SubTableHeaderProps> = () => {
    return (
        <>
            <Thead bg="gray.50">
                <Tr>
                    <Th>&nbsp;</Th>
                    {subTableHeaders.map((subHeader) => (
                        <Th key={subHeader.id}>
                            <Text>{subHeader.label}</Text>
                        </Th>
                    ))}
                </Tr>
            </Thead>
        </>
    );
};
export default SubTableHeader;
