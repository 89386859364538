import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/toast/useCustomToast';

interface useUpdatedToastHookProps {
    successUpdateUserToast: () => void;
    errorUpdateUserToast: () => void;
    successUpdateOrganisationToast: () => void;
    errorUpdateOrganisationToast: () => void;
}

type useUpdatedToastHook = () => useUpdatedToastHookProps;

export const useUpdatedToast: useUpdatedToastHook = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successUpdateUserToast() {
        const title = t('messages.toast.update.user.success.title');
        const description = t('messages.toast.update.user.success.description');

        successToast(title, description);
    }

    function errorUpdateUserToast() {
        const title = t('messages.toast.invite.user.error.title');
        const description = t('messages.toast.update.user.error.description');

        errorToast(title, description);
    }

    function successUpdateOrganisationToast() {
        const title = t('messages.toast.update.organisation.success.title');
        const description = t('messages.toast.update.organisation.success.description');

        successToast(title, description);
    }

    function errorUpdateOrganisationToast() {
        const title = t('messages.toast.update.organisation.error.title');
        const description = t('messages.toast.update.organisation.error.description');

        errorToast(title, description);
    }

    return {
        successUpdateUserToast,
        errorUpdateUserToast,
        successUpdateOrganisationToast,
        errorUpdateOrganisationToast,
    };
};
