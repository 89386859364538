import { ChangeEvent, CSSProperties } from 'react';
import {
    useCheckbox,
    chakra,
    Flex,
    Box,
    Text,
    useBoolean,
    Tooltip,
    BoxProps,
} from '@chakra-ui/react';

const shouldDisplayLabel = (second: number, totalSeconds: number): boolean => {
    if (totalSeconds <= 15) return true;
    if (totalSeconds <= 30) {
        return second === 1 || second % 5 === 0 || second === totalSeconds;
    }
    return second === 1 || second % 10 === 0 || second === totalSeconds;
};
const computeDashWidth = (totalSeconds: number): string => {
    if (totalSeconds <= 15) {
        return '2.25rem';
    }
    return '1.25rem';
};
type DashProps = {
    isChecked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    second: number;
    children?: React.ReactNode;
    totalSeconds: number;
};

export const Dash = ({
    second,
    isChecked,
    onChange,
    totalSeconds,
    children,
    ...props
}: DashProps) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox({
        ...props,
        isChecked,
        onChange,
    });
    const displayLabel = shouldDisplayLabel(second, totalSeconds);
    const dashWidth = computeDashWidth(totalSeconds);

    return (
        <chakra.label
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap=".375rem"
            cursor="pointer"
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Box>
                <Text
                    color="gray.500"
                    fontSize=".875rem"
                    lineHeight="1rem"
                    opacity={displayLabel ? 1 : 0}
                >{`${second}`}</Text>
            </Box>
            <Tooltip label={`Second ${second}`} hasArrow placement="bottom">
                <Box
                    border="2px solid"
                    borderColor="orange.500"
                    borderRadius=".625rem"
                    width={dashWidth}
                    h=".875rem"
                    {...getCheckboxProps()}
                    bg={state.isChecked ? 'orange.500' : 'white'}
                />
            </Tooltip>
        </chakra.label>
    );
};
