import { StrategyReportBasicInfoResponseDto } from '@api-clients/attention-data';
import { StrategyReportsApi } from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAllStrategyReportsHook {
    getAllStrategyReports: () => Promise<Array<StrategyReportBasicInfoResponseDto>>;
}

type useGetAllStrategyReportsProps = () => useGetAllStrategyReportsHook;

export const useGetAllStrategyReports: useGetAllStrategyReportsProps = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllStrategyReports(): Promise<Array<StrategyReportBasicInfoResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getAllStrategyReports();
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAllStrategyReports,
    };
};
