import { ChannelBenchmarkResponseDto } from '@api-clients/attention-data';
import {
    GetAllBenchmarkResultsForAllChannelsRequest, StrategyReportsApi
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAllChannelBenchmarksProps {
    getAllChannelBenchmarks: (
        request: GetAllBenchmarkResultsForAllChannelsRequest,
    ) => Promise<Array<ChannelBenchmarkResponseDto>>;
}

type useGetAllChannelBenchmarksHook = () => useGetAllChannelBenchmarksProps;

export const useGetAllChannelBenchmarks: useGetAllChannelBenchmarksHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllChannelBenchmarks(
        request: GetAllBenchmarkResultsForAllChannelsRequest,
    ): Promise<Array<ChannelBenchmarkResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getAllBenchmarkResultsForAllChannels(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAllChannelBenchmarks,
    };
};
