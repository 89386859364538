/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    FeatureBalanceResponseDto,
    GetFeatureBalanceRequestDto,
    ProblemDetails,
    UpdateUsedFeatureBalanceDto,
} from '../models';
import {
    FeatureBalanceResponseDtoFromJSON,
    FeatureBalanceResponseDtoToJSON,
    GetFeatureBalanceRequestDtoFromJSON,
    GetFeatureBalanceRequestDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateUsedFeatureBalanceDtoFromJSON,
    UpdateUsedFeatureBalanceDtoToJSON,
} from '../models';

export interface AddFeatureBalanceRequest {
    updateUsedFeatureBalanceDto?: UpdateUsedFeatureBalanceDto;
}

export interface GetFeatureBalanceOfLoggedInUserRequest {
    getFeatureBalanceRequestDto?: GetFeatureBalanceRequestDto;
}

export interface UseFeatureBalanceRequest {
    updateUsedFeatureBalanceDto?: UpdateUsedFeatureBalanceDto;
}

/**
 * FeatureBalanceApi - interface
 *
 * @export
 * @interface FeatureBalanceApiInterface
 */
export interface FeatureBalanceApiInterface {
    /**
     *
     * @param {UpdateUsedFeatureBalanceDto} [updateUsedFeatureBalanceDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureBalanceApiInterface
     */
    addFeatureBalanceRaw(
        requestParameters: AddFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>>;

    /**
     */
    addFeatureBalance(
        requestParameters: AddFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureBalanceApiInterface
     */
    getAllFeatureBalancesOfLoggedInUserRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<FeatureBalanceResponseDto>>>;

    /**
     */
    getAllFeatureBalancesOfLoggedInUser(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<FeatureBalanceResponseDto>>;

    /**
     *
     * @param {GetFeatureBalanceRequestDto} [getFeatureBalanceRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureBalanceApiInterface
     */
    getFeatureBalanceOfLoggedInUserRaw(
        requestParameters: GetFeatureBalanceOfLoggedInUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>>;

    /**
     */
    getFeatureBalanceOfLoggedInUser(
        requestParameters: GetFeatureBalanceOfLoggedInUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto>;

    /**
     *
     * @param {UpdateUsedFeatureBalanceDto} [updateUsedFeatureBalanceDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureBalanceApiInterface
     */
    useFeatureBalanceRaw(
        requestParameters: UseFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>>;

    /**
     */
    useFeatureBalance(
        requestParameters: UseFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto>;
}

/**
 *
 */
export class FeatureBalanceApi extends runtime.BaseAPI implements FeatureBalanceApiInterface {
    /**
     */
    async addFeatureBalanceRaw(
        requestParameters: AddFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/feature-balances/add`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateUsedFeatureBalanceDtoToJSON(
                    requestParameters.updateUsedFeatureBalanceDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            FeatureBalanceResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async addFeatureBalance(
        requestParameters: AddFeatureBalanceRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto> {
        const response = await this.addFeatureBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllFeatureBalancesOfLoggedInUserRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<FeatureBalanceResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/feature-balances/all`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(FeatureBalanceResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllFeatureBalancesOfLoggedInUser(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<FeatureBalanceResponseDto>> {
        const response = await this.getAllFeatureBalancesOfLoggedInUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFeatureBalanceOfLoggedInUserRaw(
        requestParameters: GetFeatureBalanceOfLoggedInUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/feature-balances`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetFeatureBalanceRequestDtoToJSON(
                    requestParameters.getFeatureBalanceRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            FeatureBalanceResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getFeatureBalanceOfLoggedInUser(
        requestParameters: GetFeatureBalanceOfLoggedInUserRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto> {
        const response = await this.getFeatureBalanceOfLoggedInUserRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async useFeatureBalanceRaw(
        requestParameters: UseFeatureBalanceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FeatureBalanceResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/feature-balances/use`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateUsedFeatureBalanceDtoToJSON(
                    requestParameters.updateUsedFeatureBalanceDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            FeatureBalanceResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async useFeatureBalance(
        requestParameters: UseFeatureBalanceRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FeatureBalanceResponseDto> {
        const response = await this.useFeatureBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
