/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    UpdateUserRequestDto,
    UserResponseDto
} from '../models';
import {
    UpdateUserRequestDtoToJSON,
    UserResponseDtoFromJSON
} from '../models';

export interface GetUserByIdRequest {
    userId: string;
}

export interface UpdateUserRequest {
    userId: string;
    updateUserRequestDto?: UpdateUserRequestDto;
}

export interface UpdateUserAccessLevelRequest {
    userId: string;
    body?: string;
}

/**
 * UserApi - interface
 *
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserByIdRaw(
        requestParameters: GetUserByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>>;

    /**
     */
    getUserById(
        requestParameters: GetUserByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto>;

    /**
     *
     * @param {string} userId
     * @param {UpdateUserRequestDto} [updateUserRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserRaw(
        requestParameters: UpdateUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>>;

    /**
     */
    updateUser(
        requestParameters: UpdateUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto>;

    /**
     *
     * @param {string} userId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserAccessLevelRaw(
        requestParameters: UpdateUserAccessLevelRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>>;

    /**
     */
    updateUserAccessLevel(
        requestParameters: UpdateUserAccessLevelRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto>;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {
    /**
     */
    async getUserByIdRaw(
        requestParameters: GetUserByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling getUserById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/user/{userId}`.replace(
                    `{${'userId'}}`,
                    encodeURIComponent(String(requestParameters.userId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getUserById(
        requestParameters: GetUserByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto> {
        const response = await this.getUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserRaw(
        requestParameters: UpdateUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling updateUser.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/user/{userId}`.replace(
                    `{${'userId'}}`,
                    encodeURIComponent(String(requestParameters.userId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateUserRequestDtoToJSON(requestParameters.updateUserRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateUser(
        requestParameters: UpdateUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserAccessLevelRaw(
        requestParameters: UpdateUserAccessLevelRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserResponseDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling updateUserAccessLevel.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/user/{userId}/access`.replace(
                    `{${'userId'}}`,
                    encodeURIComponent(String(requestParameters.userId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body as any,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateUserAccessLevel(
        requestParameters: UpdateUserAccessLevelRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserResponseDto> {
        const response = await this.updateUserAccessLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
