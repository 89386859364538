import _ from 'lodash';

/**
 * Convert a snake case string to a capitalised string
 * @param value - a string to be formatted
 * @return a new formatted string
 */
export const snakeCaseToCapitalised = (value: string): string => {
    let result = value.split('_');
    result.forEach((item, index) => {
        result[index] = _.capitalize(item);
    });

    return result.join(' ');
};

/**
 * Capitalise a string value of a key in an object
 * @param obj - A object of type T extends Record<string, any> type
 * @param args - An array of object keys need to be capitalised
 * @return object of type T
 */
export function toCapitalised<T extends Record<string, any>>(obj: T, args: Array<keyof T>): T {
    args.forEach((item) => {
        (obj[item] as string) = _.capitalize(obj[item] as string);
    });
    return obj;
}
