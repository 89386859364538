/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
import {
    Center,
    Checkbox,
    Collapse,
    Highlight,
    IconButton,
    TableRowProps,
    Td,
    Tr,
} from '@chakra-ui/react';
import { ExpandingIcon } from '@components/atoms';
import { SortDirection } from '@shared/cores';
import { useState } from 'react';
import { Column, Row } from './DataTable';

export interface DataTableRowProps<T> {
    row: Row<T>;
    rowIndex: number;
    columns: Array<Column<T>>;
    toggleSelectedRow?: (key: string) => void;
    isSelectable?: boolean;
    isChecked?: boolean;
    sortDirection?: SortDirection;
    sortedColumn?: Column<T>;
    filter?: string;
    onRow?: (row: Row<T>) => TableRowProps;
}

export const DataTableRow = <T,>({
    row,
    rowIndex,
    columns,
    toggleSelectedRow,
    isSelectable,
    isChecked,
    sortDirection,
    sortedColumn,
    filter,
    onRow,
}: DataTableRowProps<T>) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const getAriaSortValue = (column: Column<T>) => {
        if (sortedColumn?.dataIndex === column.dataIndex) {
            if (sortDirection === SortDirection.Ascending) {
                return 'ascending';
            }
            if (sortDirection === SortDirection.Descending) {
                return 'descending';
            }
        }
        return 'none';
    };

    const rowProps = onRow ? onRow(row) : undefined;
    const isTourOpen = () => {
        const overlay = document.getElementsByClassName('shepherd-modal-overlay-container');
        return !!overlay;
    };

    return (
        <>
            <Tr
                role="row"
                aria-selected={isChecked}
                key={`row-${row.key}`}
                style={{
                    overflowY: isTourOpen() ? 'hidden' : 'auto',
                }}
                {...rowProps}
                onClick={() => setIsExpanded(!isExpanded)}
                cursor={row.expandedContent ? "pointer" : "default"}
            >
                {isSelectable && toggleSelectedRow && (
                    <Td aria-sort="none" w={0}>
                        <Center>
                            <Checkbox
                                isChecked={isChecked}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    toggleSelectedRow(row.key);
                                }}
                            />
                        </Center>
                    </Td>
                )}
                {row.expandedContent && (
                    <Td aria-sort="none" w={0} paddingRight={0}>
                        <Center>
                            {row.expandedContent && (
                                <IconButton
                                    variant="ghost"
                                    size="xs"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsExpanded(!isExpanded);
                                    }}
                                    icon={
                                        <ExpandingIcon
                                            direction="right"
                                            rotation="90"
                                            fontSize="1.2rem"
                                            isOpen={isExpanded}
                                        />
                                    }
                                    aria-label="expand"
                                />
                            )}
                        </Center>
                    </Td>
                )}
                {columns.map((column, index) => {
                    const cellProps = column.onCell ? column.onCell(row, rowIndex) : undefined;
                    return (
                        cellProps?.rowSpan !== 0 && (
                            <Td
                                aria-sort={getAriaSortValue(column)}
                                key={`cell-${row.key}-${column.key}`}
                                isNumeric={column.isNumeric}
                                {...cellProps}
                            >
                                {column.render
                                    ? column.render(row, rowIndex)
                                    : (
                                        <Highlight
                                            query={filter ?? ''}
                                            styles={{ bg: 'green.100' }}
                                        >
                                            {`${column.dataIndex && column.dataIndex in row
                                                ? (row as any)[column.dataIndex]
                                                : ''
                                                }`}
                                        </Highlight>
                                    ) ?? column.defaultValue}
                            </Td>
                        )
                    );
                })}
            </Tr>
            {row.expandedContent && (
                <Tr bgColor="gray.50" borderWidth={isExpanded ? undefined : 0}>
                    <Td
                        borderWidth={isExpanded ? undefined : 0}
                        colSpan={columns.length + 1}
                        padding={0}
                    >
                        <Collapse in={isExpanded}>{row.expandedContent}</Collapse>
                    </Td>
                </Tr>
            )}
        </>
    );
};
