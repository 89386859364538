/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type { GetAttentionDataResponseDto, GetAttentionDataSaasRequestDto } from '../models';
import {
    GetAttentionDataResponseDtoFromJSON,
    GetAttentionDataSaasRequestDtoToJSON,
} from '../models';

export interface GetAttentionDataForSaasRequest {
    getAttentionDataSaasRequestDto?: GetAttentionDataSaasRequestDto;
}

/**
 * AttentionDataSaasApi - interface
 *
 * @export
 * @interface AttentionDataSaasApiInterface
 */
export interface AttentionDataSaasApiInterface {
    /**
     *
     * @param {GetAttentionDataSaasRequestDto} [getAttentionDataSaasRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttentionDataSaasApiInterface
     */
    getAttentionDataForSaasRaw(
        requestParameters: GetAttentionDataForSaasRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GetAttentionDataResponseDto>>>;

    /**
     */
    getAttentionDataForSaas(
        requestParameters: GetAttentionDataForSaasRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GetAttentionDataResponseDto>>;
}

/**
 *
 */
export class AttentionDataSaasApi extends runtime.BaseAPI implements AttentionDataSaasApiInterface {
    /**
     */
    async getAttentionDataForSaasRaw(
        requestParameters: GetAttentionDataForSaasRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GetAttentionDataResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/plan/api/v2/attention-data-saas`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetAttentionDataSaasRequestDtoToJSON(
                    requestParameters.getAttentionDataSaasRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(GetAttentionDataResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAttentionDataForSaas(
        requestParameters: GetAttentionDataForSaasRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GetAttentionDataResponseDto>> {
        const response = await this.getAttentionDataForSaasRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
