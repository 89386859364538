import {
    GetStrategyReportRequest,
    StrategyReportsApi,
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { StrategyReportResponseDto } from '@api-clients/attention-data/models/StrategyReportResponseDto';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetStrategyReportHook = () => {
    getStrategyReport: (request: GetStrategyReportRequest) => Promise<StrategyReportResponseDto>;
};

export const useGetStrategyReport: useGetStrategyReportHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getStrategyReport(
        request: GetStrategyReportRequest,
    ): Promise<StrategyReportResponseDto> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);
            return await api.getStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return { getStrategyReport };
};
