export const campaign = {
    main: {
        heading: "Campaigns",
        subtitle: "Click on campaign for more details",
        createButton: "Create campaign",
        table: {
            headers: {
                name: "Name",
                status: "Status",
                dateCreated: "Date created",
                lastModified: "Last modified",
                startDate: "Start date",
                endDate: "End date",
                owner: "Owner",
                products: "Products",
                action: "Action"
            },
            noEntries: {
                heading: "No campaigns found",
                subtitle: "Click on the create campaign button to get started"
            }
        }
    },
    details: {
        createHeading: "Create campaign",
        manageHeading: "Manage campaign",
        lastModifiedAt: 'Last modified at',
        saveButton: "Save",
        form: {
            name: {
                label: "Name",
                errorMessage: "The name must not exceed 100 characters",
                requiredMessage: "The name is required.",
                exceedsMaxLengthMessage: "The name must not exceed 100 characters.",
                duplicateMessage: "A campaign with the same name already exists. Please enter a different name."
            }
        }
    }
};
