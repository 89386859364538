/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import { RecipientKeys, type Recipient } from '../models/Recipient';

export interface ShareMediaPlanRequest {
    sender?: string;
    recipients?: Array<Recipient>;
    campaignName?: string;
    scenarioName?: string;
    scenarioLink?: string;
    attachments?: Array<Blob>;
}

/**
 * ResourceSharingApi - interface
 *
 * @export
 * @interface ResourceSharingApiInterface
 */
export interface ResourceSharingApiInterface {
    /**
     *
     * @param {string} [sender]
     * @param {Array<Recipient>} [recipients]
     * @param {string} [campaignName]
     * @param {string} [scenarioName]
     * @param {string} [scenarioLink]
     * @param {Array<Blob>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceSharingApiInterface
     */
    shareMediaPlanRaw(
        requestParameters: ShareMediaPlanRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    shareMediaPlan(
        requestParameters: ShareMediaPlanRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;
}

/**
 *
 */
export class ResourceSharingApi extends runtime.BaseAPI implements ResourceSharingApiInterface {
    /**
     */
    async shareMediaPlanRaw(
        requestParameters: ShareMediaPlanRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.sender !== undefined) {
            formParams.append('sender', requestParameters.sender as any);
        }

        if (requestParameters.recipients) {
            requestParameters.recipients.forEach((element, index) => {
                RecipientKeys.forEach((key) => {
                    formParams.append(`recipients[${index}][${key}]`, element[key] as any);
                });
            });
        }

        if (requestParameters.campaignName !== undefined) {
            formParams.append('campaignName', requestParameters.campaignName as any);
        }

        if (requestParameters.scenarioName !== undefined) {
            formParams.append('scenarioName', requestParameters.scenarioName as any);
        }

        if (requestParameters.scenarioLink !== undefined) {
            formParams.append('scenarioLink', requestParameters.scenarioLink as any);
        }

        if (requestParameters.attachments) {
            requestParameters.attachments.forEach((element) => {
                formParams.append('attachments', element as any);
            });
        }

        const response = await this.request(
            {
                path: `/api/v1/resource/share-plan`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shareMediaPlan(
        requestParameters: ShareMediaPlanRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.shareMediaPlanRaw(requestParameters, initOverrides);
    }
}
