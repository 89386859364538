/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface AdChannelResponseDto
 */
export interface AdChannelResponseDto {
    /**
     *
     * @type {string}
     * @memberof AdChannelResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdChannelResponseDto
     */
    adChannelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdChannelResponseDto
     */
    id?: string;
}

/**
 * Check if a given object implements the AdChannelResponseDto interface.
 */
export function instanceOfAdChannelResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdChannelResponseDtoFromJSON(json: any): AdChannelResponseDto {
    return AdChannelResponseDtoFromJSONTyped(json, false);
}

export function AdChannelResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AdChannelResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
        id: !exists(json, 'id') ? undefined : json['id'],
    };
}

export function AdChannelResponseDtoToJSON(value?: AdChannelResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_channel_code: value.adChannelCode,
        ad_channel_name: value.adChannelName,
        id: value.id,
    };
}
