import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface ManageMemberContextProps {
    errorAddNewMember: boolean;
    setErrorAddNewMember: (isError: boolean) => void;
    errorInviteMessage: string | null;
    setErrorInviteMessage: (message: string) => void;
}

const ManageMemberFormContext = createContext<ManageMemberContextProps>({
    errorAddNewMember: false,
    setErrorAddNewMember: (isError: boolean) => {},
    errorInviteMessage: null,
    setErrorInviteMessage: (message: string) => {},
});

export const ManageMemberFormContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [errorInvite, setErrorInvite] = useState(false);
    const [failedInviteMessage, setFailedInviteMessage] = useState<string | null>(null);

    const errorAddNewMember = useMemo(() => errorInvite, [errorInvite]);
    const setErrorAddNewMember = useCallback(
        (value: boolean) => setErrorInvite(value),
        [setErrorInvite],
    );

    const errorInviteMessage = useMemo(() => failedInviteMessage, [failedInviteMessage]);
    const setErrorInviteMessage = useCallback(
        (value: string) => setFailedInviteMessage(value),
        [setFailedInviteMessage],
    );

    const value = useMemo(
        (): ManageMemberContextProps => ({
            errorAddNewMember,
            setErrorAddNewMember,
            errorInviteMessage,
            setErrorInviteMessage,
        }),
        [errorAddNewMember, setErrorAddNewMember, errorInviteMessage, setErrorInviteMessage],
    );

    return (
        <ManageMemberFormContext.Provider value={value}>
            {children}
        </ManageMemberFormContext.Provider>
    );
};

export const useManageMemberFormContext = (): ManageMemberContextProps =>
    useContext(ManageMemberFormContext);
