import {
    GetScenarioResponseDto,
    ListAllScenariosOfACampaignRequest,
    ScenarioApi,
    ScenarioResponseDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetScenariosHook = () => {
    getScenarios: (campaignId: string) => Promise<Array<GetScenarioResponseDto>>;
};

export const useGetScenarios: useGetScenariosHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    const getScenarios = async (campaignId: string) => {
        try {
            const config = await getMediaPlanConfig();
            const api = new ScenarioApi(config);
            return await api.listAllScenariosOfACampaign({ campaignId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getScenarios };
};
