import {
    ChannelLevelCurvesResponseDto,
    ListAllReachCurvesOfACampaignRequest,
    ReachCurveApi,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetReachCurvesHook = () => {
    getReachCurves: (
        request: ListAllReachCurvesOfACampaignRequest,
    ) => Promise<Array<ChannelLevelCurvesResponseDto>>;
};

export const useGetReachCurves: useGetReachCurvesHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getReachCurves(request: ListAllReachCurvesOfACampaignRequest) {
        try {
            const config = await getMediaPlanConfig();
            const api = new ReachCurveApi(config);
            return await api.listAllReachCurvesOfACampaign(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getReachCurves };
};
