import {
    OauthApplicationResponseDto,
    UpdateOauthApplicationRequestDto,
} from '@api-clients/account-manager';
import { Input, useConst, useDisclosure, VStack } from '@chakra-ui/react';
import { InputWrapper } from '@components/atoms';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { NavBarMenuListItem } from '@components/atoms/navBarItem';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOauthApplications } from '@hooks/accountManager';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { OAuthApplicationFormData } from './CreateNewOAuthApplicationDialogButton';

interface InternalProps {
    application: OauthApplicationResponseDto;
    onUpdate: () => void;
}

export const UpdateOAuthApplicationDialogButton: FC<InternalProps> = ({
    application,
    onUpdate,
}) => {
    const { t } = useTranslation();
    const { validationSchema, updateOauthApplication } = useOauthApplications();
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();
    const { errorToast, successToast } = useCustomToast();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, touchedFields, isSubmitting, isValid },
    } = useForm<OAuthApplicationFormData>({
        resolver: yupResolver(
            yup.object().shape({
                applicationName: yup
                    .string()
                    .trim()
                    .required(
                        t('messages.forms.validation.oauthApplications.applicationName.isRequired'),
                    ),
            }),
        ),
        mode: 'onChange',
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const translation = useConst(() =>
        t('pages.applicationManagement', {
            returnObjects: true,
        }),
    );

    const {
        editDialog,
        toastText: {
            edit: { success, error },
        },
    } = useConst(() => ({
        editDialog: translation.dialogs.edit,
        toastText: translation.toast,
    }));

    const onFormSubmit = async (data: OAuthApplicationFormData) => {
        try {
            const request: UpdateOauthApplicationRequestDto = {
                applicationName: data.applicationName,
            };
            await updateOauthApplication(organisation!.id!, application.clientId!, request);
            onUpdate();
            successToast(success.heading, success.subtitle);
            onClose();
        } catch (e) {
            errorToast(error.heading, error.subtitle);
        }
    };

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen]);

    return (
        <>
            <NavBarMenuListItem children="Rename" icon={PencilSquareIcon} onClick={onOpen} />
            <ConfirmDialog
                headerText={editDialog.headerText}
                confirmButtonText={editDialog.confirmButton}
                cancelButtonText={editDialog.cancelButton}
                isOpen={isOpen}
                isLoading={isSubmitting}
                isConfirmDisabled={!isValid}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        handleSubmit(onFormSubmit)();
                    } else {
                        onClose();
                    }
                }}
            >
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <VStack spacing="1rem" align="left">
                        <InputWrapper
                            isRequired
                            formLabel={editDialog.form.fields.applicationName}
                            formErrorText={errors.applicationName?.message}
                            isInvalid={touchedFields.applicationName && !!errors.applicationName}
                        >
                            <Input
                                defaultValue={application.name ?? ''}
                                {...register('applicationName')}
                            />
                        </InputWrapper>
                    </VStack>
                </form>
            </ConfirmDialog>
        </>
    );
};
