import ExcelJS from 'exceljs';

export const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Amplified Intelligence';
    workbook.lastModifiedBy = 'Amplified Intelligence';

    const now = new Date();
    workbook.created = now;
    workbook.modified = now;
    workbook.calcProperties.fullCalcOnLoad = true;
    return workbook;
};
