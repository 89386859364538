import { useTranslation } from 'react-i18next';
import { ErrorDictionary, useErrorDictionary } from '@shared/cores';
import { errors } from '@configs/i18n/languages/en/translation-modules/errors';
import { User } from '@auth0/auth0-react';
import { OrganisationResponseDto } from '@api-clients/account-manager';
import {
    AgeGroup,
    BudgetConstraintType,
    DecimalComparisonValue,
    OptimisationStrategy,
    ScenarioAdFormatResponseDto,
    ScenarioLevelResultComparisonResponseDto,
} from '@api-clients/media-plan';
import { channels } from '@configs/importTemplate';

type AuthErrorKeys = keyof typeof errors.auth | undefined;
type OrganisationErrorKeys = keyof typeof errors.organisation | undefined;
type LimitKeys = keyof typeof errors.usageLimit | undefined;

interface Profile {
    userId?: string;
    orgId?: string;
}

function mapErrorMessage(message: string): string | null {
    const dictValues = Object.values(ErrorDictionary);
    for (let i = 0; i < dictValues.length; i++) {
        const val = dictValues[i];
        if (message === val) return message;
    }
    return null;
}

function getAuthErrorTranslationKey(message: string): AuthErrorKeys {
    const obj = errors.auth as Record<string, any>;
    return Object.keys(obj).find((key) => obj[key] === message) as AuthErrorKeys;
}

function getOrganisationErrorTranslationKey(message: string): OrganisationErrorKeys {
    const obj = errors.organisation as Record<string, any>;
    return Object.keys(obj).find((key) => obj[key] === message) as OrganisationErrorKeys;
}

function removeUniqueNameInText(message: string): string {
    return message.replace(/'[\w\s.]*'/, '{0}');
}

function getUsageLimitErrorTranslationKey(message: string): LimitKeys {
    const obj = errors.auth as Record<string, any>;
    return Object.keys(obj).find((key) => obj[key] === message) as LimitKeys;
}

async function getErrorObject(ex: any): Promise<Error> {
    try {
        const response = await ex.response.text();
        const json = JSON.parse(response);
        const errorObj = new Error(json.message);
        errorObj.name = json.trace_id;
        return errorObj;
    } catch (e) {
        throw new Error('Something is wrong when sending the request.');
    }
}

export const useHelper = () => {
    const { t } = useTranslation('errors');

    function getUserProfile(user: User): Profile {
        const namespace = 'https://amplified.co/';
        return {
            userId: user[`${namespace}account_manager_user_id`],
            orgId: user[`${namespace}account_manager_org_id`],
        };
    }

    const { errorDictionary } = useErrorDictionary();

    async function getDisplayedErrorMessage(ex: any): Promise<string> {
        const error = await getErrorObject(ex);
        const message = mapErrorMessage(removeUniqueNameInText(error.message));
        if (message === null) {
            return `${t('displayTrace')}${error.name}`;
        }
        return message;
    }

    // This is an update function to display meaningful error message to customer.
    async function mapDisplayedErrorMessage(er: any) {
        const error = await getErrorObject(er);
        let result = `${t('displayTrace')}${error.name}`;

        for (let i = 0; i < errorDictionary.length; i++) {
            if (error.message.includes(errorDictionary[i].checkTerm)) {
                result = errorDictionary[i].displayedMessage ?? error.message;
                break;
            }
        }

        return result;
    }

    function mapAuthTranslation(message: string): string {
        if (message.toLowerCase().includes('temporary password has expired')) {
            return t('auth.temporaryPasswordExpired');
        }

        if (message.toLowerCase().includes('session is expired')) {
            return t('auth.sessionExpired');
        }

        if (message.toLowerCase().includes('user password cannot be reset in the current state')) {
            return t('auth.requiredNewPasswordBeforeRequestForgotPassword');
        }

        const tKey = getAuthErrorTranslationKey(message);
        return tKey !== undefined ? t(`auth.${tKey}`) : message;
    }

    function mapOrganisationTranslation(message: string): string {
        const tKey = getOrganisationErrorTranslationKey(removeUniqueNameInText(message));
        return tKey !== undefined ? t(`organisation.${tKey}`) : message;
    }

    function displayUniqueNameInMessage(message: string, name?: string | null): string {
        return message.replace('{0}', `'${name}'` ?? '');
    }

    function mapUsageLimitTranslation(message: string): string {
        const tKey = getUsageLimitErrorTranslationKey(removeUniqueNameInText(message));
        return tKey !== undefined ? t(`usageLimit.${tKey}`) : message;
    }

    function isAuth0Flag(): boolean {
        // Temporary set default value, should remove later
        const auth0Flag = import.meta.env.VITE_APP_AUTH0_FLAG ?? 'true';
        return auth0Flag.toLowerCase() === 'true';
    }

    function getUserNameFromId(organisation: OrganisationResponseDto, id?: string | null) {
        const user = organisation?.users?.find((u) => u.id === id);
        return `${user?.firstName} ${user?.lastName}`;
    }

    function getOptimisationObjectiveText(
        optimisationStrategy: string,
        get: 'label' | 'tooltip',
        optimisationObjectivesText: any,
    ): string {
        switch (optimisationStrategy) {
            case OptimisationStrategy.Base:
                return get === 'label'
                    ? optimisationObjectivesText.base.label
                    : optimisationObjectivesText.base.tooltip;
            case OptimisationStrategy.Stl:
                return get === 'label'
                    ? optimisationObjectivesText.stl.label
                    : optimisationObjectivesText.stl.tooltip;
            case OptimisationStrategy.Ltl:
                return get === 'label'
                    ? optimisationObjectivesText.ltl.label
                    : optimisationObjectivesText.ltl.tooltip;
            case OptimisationStrategy.AApR:
                return get === 'label'
                    ? optimisationObjectivesText.aasr.label
                    : optimisationObjectivesText.aasr.tooltip;
            case OptimisationStrategy.AApI:
                return get === 'label'
                    ? optimisationObjectivesText.aasi.label
                    : optimisationObjectivesText.aasi.tooltip;
            default:
                return '';
        }
    }

    function getBudgetConstraintLabel(budgetConstraintType: string): string {
        switch (budgetConstraintType) {
            case BudgetConstraintType.AdjustmentToleranceLevel:
                return 'Plan tolerance';
            case BudgetConstraintType.MinSpendPerChannel:
                return 'Channel minimum spend';
            default:
                return '';
        }
    }

    function convertChannelCodeToChannelName(channelCode: string): string {
        const normaliseText = (text: string) =>
            text.toLowerCase().replace(' ', '').replace('-', '');

        return (
            channels.find((channel) => normaliseText(channel) === normaliseText(channelCode)) ??
            channelCode
        );
    }

    function getChannelSectionOfAdFormatCode(format: ScenarioAdFormatResponseDto): string {
        return format.adFormatCode?.split('.').at(0) ?? '';
    }

    function formatOptionsForSelect(
        items: Array<string>,
        labelFormat?: (value: string) => string,
    ): Array<{ label: string; value: string }> {
        return items.map((item) => ({
            label: labelFormat ? labelFormat(item) : item,
            value: item,
        }));
    }

    function formatAgeGroup(group: string, remove?: string, minimize = true): string {
        let formattedGroup = group.replace(
            /group(\d+)(to)(\d+)/,
            `${minimize ? '' : 'Group '}$1 ${minimize ? '-' : '$2'} $3`,
        );
        formattedGroup = formattedGroup.replace(
            /group(\d+)(\s*)(plus)/,
            `${minimize ? '' : 'Group'}$1${minimize ? '+' : ' plus'}`,
        );
        if (remove) {
            formattedGroup = formattedGroup.replace(remove, '');
        }
        return formattedGroup.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    function sortAgeGroups(
        ageGroups: readonly typeof AgeGroup[keyof typeof AgeGroup][] | null | undefined,
    ): typeof AgeGroup[keyof typeof AgeGroup][] {
        if (ageGroups == null) {
            return [];
        }
        const mutableAgeGroups = [...ageGroups];
        return mutableAgeGroups.sort();
    }

    function formatStringToCapitalized(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    function formatNumber(
        value?: number | undefined | null,
        isPercentage = false,
        fractionDigits = 2,
        currencySymbol = '',
    ): string {
        if (value) {
            if (isPercentage) {
                value *= 100;
            }
        } else {
            value = 0;
        }
        let valueWithCommas = value
            .toFixed(fractionDigits)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        return isPercentage ? `${valueWithCommas}%` : currencySymbol + valueWithCommas;
    }

    function getReachValue(
        scenarioLevelResult?: ScenarioLevelResultComparisonResponseDto,
    ): DecimalComparisonValue {
        if (!scenarioLevelResult) {
            return { value: 0, comparedWithValue: 0, percentageDifference: 0, valueDifference: 0 };
        }

        let comparedWithValue =
            scenarioLevelResult?.channelLevelResults?.reduce(
                (prev, channel) => (channel.targetAudience?.comparedWithValue ?? 0) + prev,
                0,
            ) ?? 0;

        let value =
            scenarioLevelResult?.channelLevelResults?.reduce(
                (prev, channel) => (channel.targetAudience?.value ?? 0) + prev,
                0,
            ) ?? 0;

        return {
            value,
            comparedWithValue,
            percentageDifference: (value - comparedWithValue) / comparedWithValue,
            valueDifference: value - comparedWithValue,
        };
    }

    function roundNumber(num: number, digits = 2) {
        const mul = 10 ** digits;
        return Math.round((num + Number.EPSILON) * mul) / mul;
    }

    return {
        getDisplayedErrorMessage,
        mapAuthTranslation,
        mapOrganisationTranslation,
        displayUniqueNameInMessage,
        mapUsageLimitTranslation,
        isAuth0Flag,
        getUserProfile,
        getUserNameFromId,
        getOptimisationObjectiveText,
        getBudgetConstraintLabel,
        convertChannelCodeToChannelName,
        getChannelSectionOfAdFormatCode,
        mapDisplayedErrorMessage,
        formatOptionsForSelect,
        formatAgeGroup,
        formatStringToCapitalized,
        sortAgeGroups,
        formatNumber,
        getReachValue,
        roundNumber,
    };
};
