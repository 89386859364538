import {ChakraProvider} from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '@App';
import { AMPLIFIED_THEMES } from '@assets/design-system';
import * as Sentry from '@sentry/react';
import {reduxStore} from '@/redux'
import {Provider} from 'react-redux'

Sentry.init({
    dsn: 'https://17f5947e451e2e0a65cd8676314e2a0e@o225955.ingest.us.sentry.io/4507139559063552',
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/a360daily\.amplifiedintelligence\.io/,
        /^https:\/\/a360\.dev\.amplifiedintelligence\.io/,
        /^https:\/\/plan\.amplified\.co/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={reduxStore}>
            <ChakraProvider theme={AMPLIFIED_THEMES}>
                <App/>
            </ChakraProvider>
        </Provider>
    </React.StrictMode>,
);
