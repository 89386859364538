import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class CreateProveCampaignApi {

    static async execute(
        configuration: Configuration,
        campaignId: string
    ): Promise<ProveCampaign> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaignId}`, {
            method: 'POST',
            headers: configuration.headers,
        });

        return response.json();
    }

}
