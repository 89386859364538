import {Configuration} from "@api-clients/runtime";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";

export class UpdateGlobalCampaignApi {

    static async execute(
        configuration: Configuration,
        campaign: GlobalCampaign
    ): Promise<GlobalCampaign> {
        const response = await fetch(`${configuration.basePath}/a360/v1/campaigns/${campaign.id}`, {
            method: 'PUT',
            headers: configuration.headers,
            body: JSON.stringify({
                name: campaign.name,
                startDate: campaign.startDate,
                endDate: campaign.endDate
            })
        });

        return response.json();
    }

}
