import {
    CampaignApi,
    ScenarioResponseDto,
    UpdateCampaignRequest,
    UpdateCampaignRequestDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface UpdateCampaignHook {
    updateCampaign: (
        campaignId: string,
        updateCampaignRequestDto: UpdateCampaignRequestDto,
    ) => Promise<UpdateCampaignRequestDto>;
}

type useUpdateCampaignProps = () => UpdateCampaignHook;

export const useUpdateCampaign: useUpdateCampaignProps = () => {
    // const { validateJWT } = useValidateJWT();
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateCampaign(
        campaignId: string,
        updateCampaignRequestDto: UpdateCampaignRequestDto,
    ): Promise<ScenarioResponseDto> {
        try {
            // await validateJWT();
            const config = await getMediaPlanConfig();
            const request: UpdateCampaignRequest = {
                campaignId,
                updateCampaignRequestDto,
            };

            const api = new CampaignApi(config);
            return await api.updateCampaign(request);
        } catch (error: any) {
            throw new Error(await getDisplayedErrorMessage(error));
        }
    }

    return {
        updateCampaign,
    };
};
