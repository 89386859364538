import {
    UpdateUserRequest,
    UpdateUserRequestDto,
    UserApi,
    UserResponseDto,
} from '@api-clients/account-manager';
import * as yup from 'yup';
import { useApiConfiguration } from '@hooks/configuration';
import { toCapitalised, useHelper } from '@shared/utils';
import { useTranslation } from 'react-i18next';

interface UpdateUserHook {
    validationSchema: yup.ObjectSchema<any>;
    updateUser: (
        userId: string,
        updateUserRequestDto: UpdateUserRequestDto,
    ) => Promise<UserResponseDto>;
}

type useUpdateUserProps = () => UpdateUserHook;

const phoneRegEx = /^\d{0,15}$/;

export const useUpdateUser: useUpdateUserProps = () => {
    const { t } = useTranslation();
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.user.firstName.isRequired')),
        lastName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.user.firstName.isRequired')),
        phoneNumber: yup.string().matches(phoneRegEx, {
            message: t('messages.forms.validation.user.phoneNumber.isInvalid'),
            excludeEmptyString: true,
        }),
    });

    async function updateUser(
        userId: string,
        updateUserRequestDto: UpdateUserRequestDto,
    ): Promise<UserResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: UpdateUserRequest = {
                userId,
                updateUserRequestDto: toCapitalised(updateUserRequestDto, [
                    'firstName',
                    'lastName',
                ]),
            };

            const api = new UserApi(configuration);
            return await api.updateUser(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        validationSchema,
        updateUser,
    };
};
