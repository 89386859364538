import { ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { Box, ModalProps, Stack, Text, useToast } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { useAppContextHelper } from '@hooks/_contexts';
import { useHelper } from '@shared/utils';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormatResponseDto } from '@api-clients/attention-data';
import { ChannelCheckboxes } from './ChannelCheckboxes';

export interface AdFormatsBasicModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
    scenarioAdFormats: ScenarioAdFormatResponseDto[];
    channels: FormatResponseDto[];
    onConfirm: (channelData: ScenarioAdFormatResponseDto[]) => void;
    isPaidPlan?: boolean;
}

export const AdFormatsBasicModal: FC<AdFormatsBasicModalProps> = ({
    scenarioAdFormats,
    channels,
    onConfirm,
    isPaidPlan = true,
    isOpen,
    onClose,
}) => {
    const toast = useToast();
    const { getChannelSectionOfAdFormatCode } = useHelper();

    const uniqueChannels = [...new Set(channels.map((c) => c?.code))];

    const getInitialAdFormats = () => {
        return new Map<string, ScenarioAdFormatResponseDto>(
            scenarioAdFormats.map((f) => [
                f.adFormatCode!,
                {
                    adFormatName: f.adFormatName,
                    adFormatCode: f.adFormatCode,
                    isChecked: f.isChecked,
                    cpm: f.cpm!,
                },
            ]),
        );
    };

    const [adFormats, setAdFormats] = useState<Map<string, ScenarioAdFormatResponseDto>>(
        getInitialAdFormats(),
    );

    useEffect(() => {
        setAdFormats(getInitialAdFormats());
    }, [scenarioAdFormats]);

    const onAdFormatUpdate = (adFormatCode: string, cpm?: number, isChecked?: boolean) => {
        const adFormat = adFormats.get(adFormatCode);
        setAdFormats((map) => {
            return new Map(
                map.set(adFormatCode, {
                    ...adFormat,
                    cpm: cpm ?? adFormat?.cpm,
                    isChecked: isChecked ?? adFormat?.isChecked,
                }),
            );
        });
    };

    const handleChannelToggle = (channelCode: string) => {
        const channelAdFormats = [...adFormats.values()].filter((f) =>
            getChannelSectionOfAdFormatCode(f).includes(channelCode),
        );
        const uncheckedChannelAdFormats = channelAdFormats.filter((f) => !f.isChecked);

        setAdFormats((map) => {
            // check all if some amount of unchecked boxes in channel
            // otherwise uncheck all
            if (uncheckedChannelAdFormats.length > 0) {
                uncheckedChannelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: true });
                });
            } else {
                channelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: false });
                });
            }
            return new Map<string, ScenarioAdFormatResponseDto>(map);
        });
    };

    const isConfirmDisabled = useMemo(
        () => ![...adFormats.values()].some((f) => f.isChecked),
        [adFormats],
    );

    return (
        <ConfirmDialog
            headerText="Filter ad formats"
            confirmButtonText="Confirm"
            onClose={(isConfirmation) => {
                if (isConfirmation) {
                    onConfirm([...adFormats.values()]);
                    toast({
                        title: `Ad formats selected`,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    setAdFormats(getInitialAdFormats());
                }
                onClose();
            }}
            isOpen={isOpen}
            size="3xl"
            isConfirmDisabled={isConfirmDisabled}
            modalCaption={isConfirmDisabled ? 'At least one format must be selected' : ''}
        >
            <Stack>
                <Text color="gray.600">
                    Select the channels and formats available. If the ad format data is
                    insufficient, the result will not be presented.
                </Text>
                <Box>
                    {uniqueChannels
                        ?.sort((a, b) => a?.localeCompare(b ?? '') ?? 0)
                        .map((channelCode) => {
                            const channelFormats = [...adFormats.values()].filter((f) =>
                                getChannelSectionOfAdFormatCode(f)?.includes(channelCode ?? ''),
                            );
                            return (
                                <ChannelCheckboxes
                                    key={`${channelCode}` ?? ''}
                                    channelCode={channelCode ?? ''}
                                    channelName={
                                        channels
                                            .filter((c) => c?.code === channelCode)
                                            .map((c) => c?.name)
                                            .at(0) ?? ''
                                    }
                                    formats={channelFormats ?? ''}
                                    onCheckChannel={handleChannelToggle}
                                    onAdFormatUpdate={onAdFormatUpdate}
                                    isFreeTier={!isPaidPlan}
                                />
                            );
                        })}
                </Box>
            </Stack>
        </ConfirmDialog>
    );
};
