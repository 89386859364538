import { FC, useState } from 'react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Flex,
} from '@chakra-ui/react';
import { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';

const MaxNumberOfErrorsDisplayed = 2;
type TableErrorSectionProps = {
    subTableHasError: TableError[];
};

const TableErrorSection: FC<TableErrorSectionProps> = ({ subTableHasError }) => {
    const [maxNumberOfErrorsDisplayed, setMaxNumberOfErrorsDisplayed] = useState(
        MaxNumberOfErrorsDisplayed,
    );
    if (subTableHasError.length === 0) {
        return <></>;
    }
    const hideErrors = () => {
        setMaxNumberOfErrorsDisplayed(0);
    };
    const showErrors = () => {
        setMaxNumberOfErrorsDisplayed(Number.MAX_SAFE_INTEGER);
    };
    const numberOfErrors = subTableHasError.length;
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="0.5rem"
            borderRadius="0.375rem"
            fontSize="0.875rem"
        >
            {numberOfErrors > 0 && (
                <Alert status="error" boxShadow="sm" borderRadius="0.375rem" height="3rem">
                    <AlertTitle color="red.900" fontWeight={700}>{`${
                        numberOfErrors > 2 ? '2+' : numberOfErrors
                    } error${numberOfErrors > 1 ? 's' : ''} detected`}</AlertTitle>
                    <AlertDescription
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap="0.75rem"
                        textAlign="right"
                        flexGrow={1}
                    >
                        <Button
                            size="sm"
                            type="button"
                            fontSize="0.875rem"
                            variant="ghost"
                            colorScheme="red"
                            onClick={() => {
                                showErrors();
                            }}
                        >
                            Show all {`(${subTableHasError.length})`}
                        </Button>
                        <Box color="red.900">|</Box>
                        <Button
                            size="sm"
                            type="button"
                            fontSize="0.875rem"
                            variant="ghost"
                            colorScheme="red"
                            onClick={() => {
                                hideErrors();
                            }}
                        >
                            Hide all
                        </Button>
                    </AlertDescription>
                </Alert>
            )}
            {subTableHasError.slice(0, maxNumberOfErrorsDisplayed).map((tableError) => (
                <Alert
                    borderRadius="0.375rem"
                    status="error"
                    boxShadow="sm"
                    key={`${tableError.channelName}-${tableError.accessorKey}-${
                        tableError.adFormatName && tableError.adFormatName.length > 0
                            ? tableError.adFormatName
                            : ''
                    }`}
                >
                    <AlertIcon />
                    <>
                        <AlertTitle>
                            {tableError.channelName}
                            {tableError.adFormatName &&
                                tableError.adFormatName.length > 0 &&
                                ` - ${tableError.adFormatName}`}
                            :
                        </AlertTitle>
                        <AlertDescription>{tableError.message}</AlertDescription>
                    </>
                </Alert>
            ))}
        </Box>
    );
};
export default TableErrorSection;
