import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { LockIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Center, Divider, Stack, Tooltip } from '@chakra-ui/react';
import { useAppContextHelper } from '@hooks/_contexts';
import { Option } from '@shared/cores/types';
import { chakraComponents, MenuListProps } from 'chakra-react-select';
import { FC } from 'react';

export interface CountrySelectMenuListProps extends MenuListProps<any> {
    canSelectGlobal: boolean;
    canSelectCountries: boolean;
    isUsingGlobal: boolean;
    setIsUsingGlobal: (isUsingGlobal: boolean) => void;
    countryOptions: {
        value:
            | 'mx'
            | 'fr'
            | 'au'
            | 'be'
            | 'ca'
            | 'de'
            | 'ie'
            | 'nz'
            | 'sa'
            | 'ae'
            | 'gb'
            | 'us'
            | 'pl'
            | 'in';
        label: string;
        isDisabled: boolean;
    }[];
    selectedOptions: Option[];
    setSelectedOptions: (value: Array<Option>) => void;
    openUpgradeModal?: () => void;
}

export const CountrySelectMenuList: FC<CountrySelectMenuListProps> = ({
    canSelectGlobal,
    canSelectCountries,
    isUsingGlobal,
    setIsUsingGlobal,
    countryOptions,
    selectedOptions,
    setSelectedOptions,
    openUpgradeModal,
    ...props
}) => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const isPaidTier = featureBalances?.some(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    const handleSelectAllCountries = () => {
        setSelectedOptions(
            countryOptions
                .filter((c) => !c.isDisabled)
                .map((c) => ({ value: c.value, label: c.label })),
        );
    };
    const handleDeselectAllCountries = () => {
        setSelectedOptions([]);
    };

    return (
        <chakraComponents.MenuList {...props}>
            <Stack>
                <Center>
                    <ButtonGroup size="sm" isAttached maxWidth="13rem">
                        <Tooltip
                            isDisabled={canSelectGlobal}
                            label="Upgrade your plan to unlock global"
                        >
                            <Button
                                onClick={() => setIsUsingGlobal(true)}
                                variant={isUsingGlobal ? 'solid' : 'outline'}
                                isDisabled={!canSelectGlobal}
                                rightIcon={canSelectGlobal ? undefined : <LockIcon />}
                            >
                                Global
                            </Button>
                        </Tooltip>
                        <Tooltip
                            isDisabled={canSelectCountries || isPaidTier}
                            label="Upgrade your plan to unlock countries"
                        >
                            <Button
                                onClick={() => {
                                    if (!canSelectCountries && !isPaidTier && openUpgradeModal)
                                        openUpgradeModal();
                                    else setIsUsingGlobal(false);
                                }}
                                variant={isUsingGlobal ? 'outline' : 'solid'}
                            >
                                Select countries
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Center>
                {!isUsingGlobal && (
                    <Box display="flex" alignItems="center" justifyContent="center" gap="1.5rem">
                        <Box
                            maxWidth="12.7rem"
                            flexGrow={1}
                            paddingX="1px"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Button
                                size="sm"
                                variant="link"
                                textDecoration="underline"
                                paddingY={0}
                                colorScheme="gray"
                                marginY={0}
                                onClick={() => handleSelectAllCountries()}
                                fontWeight={400}
                            >
                                Select all
                            </Button>
                            <Box color="gray.400">|</Box>
                            <Button
                                size="sm"
                                variant="link"
                                textDecoration="underline"
                                paddingY={0}
                                colorScheme="gray"
                                marginY={0}
                                onClick={() => handleDeselectAllCountries()}
                                fontWeight={400}
                            >
                                Deselect all
                            </Button>
                        </Box>
                    </Box>
                )}
                {!isUsingGlobal && (
                    <>
                        <Divider />
                        <Box>{props.children}</Box>
                    </>
                )}
            </Stack>
        </chakraComponents.MenuList>
    );
};
