/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface SearchedCampaignResponseDto
 */
export interface SearchedCampaignResponseDto {
    /**
     *
     * @type {string}
     * @memberof SearchedCampaignResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof SearchedCampaignResponseDto
     */
    campaignName?: string | null;
}

/**
 * Check if a given object implements the SearchedCampaignResponseDto interface.
 */
export function instanceOfSearchedCampaignResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchedCampaignResponseDtoFromJSON(json: any): SearchedCampaignResponseDto {
    return SearchedCampaignResponseDtoFromJSONTyped(json, false);
}

export function SearchedCampaignResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SearchedCampaignResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
    };
}

export function SearchedCampaignResponseDtoToJSON(value?: SearchedCampaignResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        campaign_name: value.campaignName,
    };
}
