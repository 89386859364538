import React from 'react'
import {Container, Heading, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export const ProveDashboardNoData = () => {
    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    return (
        <Container>
            <VStack>
                <Heading variant="amplifiedHeading">
                    {content.breakdown.noDataFoundSection.heading}
                </Heading>
                <Text fontSize="sm">{content.breakdown.noDataFoundSection.subtitle}</Text>
            </VStack>
        </Container>
    )

}
