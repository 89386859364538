import { OrganisationResponseDto, UserResponseDto } from '@api-clients/account-manager';
import { FeatureBalanceResponseDto, FeatureCode } from '@api-clients/subscriptions';
import { useGetOrganisation } from '@hooks/organisations';
import { useGetFeatureBalances } from '@hooks/subscriptions/useGetFeatureBalances';
import { useGetUser } from '@hooks/users';
import { useAppContext } from '@hooks/_contexts/app/AppContextProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@configs';
import { selectGlobalCampaignAsync } from '@redux/slices/global/campaign/thunks';
import { selectProveCampaignAsync } from '@redux/slices/prove/campaign/thunks';
import { useDispatch } from '@redux';
import { useApiConfiguration } from '@hooks/configuration';
import { findProveAdFormatsAsync } from '@redux/slices/prove/adFormat/thunks';
import { findProveLineItemsAsync } from '@redux/slices/prove/lineItem/thunks';
import { ProveCampaign } from '@api-clients/prove/schema/ProveCampaign';
import { ProveAdFormat } from '@api-clients/prove/schema/ProveAdFormat';

export const useAppContextHelper = () => {
    const dispatch = useDispatch();
    const { getGlobalCampaignServerConfig, getProveManagementServiceConfig } = useApiConfiguration();
    const { getUser } = useGetUser();
    const { getOrganisation } = useGetOrganisation();
    const { getFeatureBalances } = useGetFeatureBalances();
    const { appUser, appOrganisation, appFeatureBalances, errorFetching, productTours } =
        useAppContext();

    const navigate = useNavigate();

    function resetAppContext() {
        appUser.setCurrentValue(null);
        appOrganisation.setCurrentValue(null);
        appFeatureBalances.setCurrentValue(null);
    }

    const handleFetchContextError = (e: any) => {
        errorFetching.setCurrentValue({ isError: true });
    };

    async function getUserContext(): Promise<UserResponseDto> {
        try {
            errorFetching.setCurrentValue({ isError: false });
            const user = await getUser();
            appUser.setCurrentValue(user);
            return user;
        } catch (e: any) {
            handleFetchContextError(e);
            throw new Error(e);
        }
    }

    async function getOrganisationContext(): Promise<OrganisationResponseDto> {
        try {
            errorFetching.setCurrentValue({ isError: false });
            const organisation = await getOrganisation();
            appOrganisation.setCurrentValue(organisation);
            return organisation;
        } catch (e: any) {
            handleFetchContextError(e);
            throw new Error(e);
        }
    }

    async function getFeatureBalanceContext(): Promise<
        Array<FeatureBalanceResponseDto> | undefined
    > {
        try {
            errorFetching.setCurrentValue({ isError: false });
            const currentActive = await getFeatureBalances();
            if (!currentActive) {
                return [];
            }
            appFeatureBalances.setCurrentValue(currentActive);
            return currentActive;
        } catch (e: any) {
            handleFetchContextError(e);
            throw new Error(e);
        }
    }

    function shouldRedirectToProgressiveSignUpPage(user: UserResponseDto) {
        return (
            !user.organisations ||
            user.organisations.length === 0 ||
            !user.firstName ||
            user.firstName.length === 0 ||
            !user.lastName ||
            user.lastName.length === 0
        );
    }

    function fetchContext() {
        // if the user info is already fetched, check if redirection to sign up page is needed
        if (appUser.currentValue !== null) {
            if (shouldRedirectToProgressiveSignUpPage(appUser.currentValue)) {
                // TODO: is this URL still correct - e.g. using "../"?
                navigate(`../${routing.progressiveSignUp.url}`);
                return;
            }
            // appFeatureBalances should be an array
            // if not, re-fetch
            if (Array.isArray(appFeatureBalances.currentValue)) {
                return;
            }
        }
        // need to fetch user first then fetch organisation then fetch subscription
        // because if both are fetching in parallel, the backend will throw exception
        // when create the same instances of user and organisation (same user id and org id) from self sign up.
        // request user first, see if the organisation field is an empty array
        // if it is, redirect user to the progressive signup page
        // otherwise, request info on organisation as usual
        getUserContext()
            .then((user) => {
                if (!user) {
                    throw new Error('Unable to fetch user');
                }
                if (shouldRedirectToProgressiveSignUpPage(user)) {
                    // TODO: is this URL still correct - e.g. using "../"?
                    navigate(`../${routing.progressiveSignUp.url}`);
                    return;
                }
                getOrganisationContext()
                    .then(() => {
                        getFeatureBalanceContext().catch((e) => {
                            throw new Error(e);
                        });
                    })
                    .catch((e) => {
                        throw new Error(e);
                    });
            })
            .catch((e) => {
                errorFetching.setCurrentValue({ isError: true });
                throw new Error(e);
            });
    }

    function getAppContext() {
        errorFetching.setCurrentValue({ isError: false });
        fetchContext();
    }

    function getCreateMediaPlanFeatureBalance(featureBalances: Array<FeatureBalanceResponseDto>) {
        return featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
        );
    }

    const selectGlobalCampaign = async (campaignId: string) => {
        dispatch(selectGlobalCampaignAsync({
            configuration: await getGlobalCampaignServerConfig(),
            campaignId,
        }));
    };

    const selectProveCampaign = async (campaignId: string) => {
        dispatch(selectProveCampaignAsync({
            configuration: await getProveManagementServiceConfig(),
            campaignId,
        }));
    };

    const retrieveProveAdFormats = async () => {
        dispatch(findProveAdFormatsAsync({
            configuration: await getProveManagementServiceConfig(),
        }));
    };

    const retrieveProveLineItems = async (campaign: ProveCampaign) => {
        dispatch(findProveLineItemsAsync({
            configuration: await getProveManagementServiceConfig(),
            campaign,
        }));
    };

    const resolveProveAdFormatInstance = (adFormats: Array<ProveAdFormat>, code: string) => {
        for (let i = 0; i < adFormats.length; i++) {
            if (adFormats[i].code === code) {
                return adFormats[i];
            }
        }

        throw new Error(`Ad format code [${code}] is not supported.`);
    };

    const resolveProveAdFormatName = (adFormats: Array<ProveAdFormat>, code: string) => {
        for (let i = 0; i < adFormats.length; i++) {
            if (adFormats[i].code === code) {
                return adFormats[i].name;
            }
        }

        return code;
    };

    useEffect(() => {
        getAppContext();
    }, []);

    return {
        resetAppContext,
        currentContextValues: {
            user: appUser.currentValue,
            organisation: appOrganisation.currentValue,
            error: errorFetching.currentValue,
            featureBalances: appFeatureBalances.currentValue,
            productTours: productTours.currentValue,
        },
        setCurrentContextValues: {
            setUser: appUser.setCurrentValue,
            setOrganisation: appOrganisation.setCurrentValue,
            setError: errorFetching.setCurrentValue,
            setFeatureBalances: appFeatureBalances.setCurrentValue,
            setProductTours: productTours.setCurrentValue,
        },
        helper: {
            getCreateMediaPlanFeatureBalance,
            selectGlobalCampaign,
            selectProveCampaign,
            retrieveProveAdFormats,
            retrieveProveLineItems,
            resolveProveAdFormatInstance,
            resolveProveAdFormatName,
        },
    };
};
