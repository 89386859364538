import { FC } from 'react';
import { CreativeResponseDto } from '@api-clients/attention-data';
import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Tooltip,
    Text,
    MenuButton,
    MenuList,
    MenuItem,
    Menu,
    useDisclosure,
    Skeleton,
    Image,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon, PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';
import { DeleteCreativeModal } from '@apps/attention360/pages/strategyReports/DeleteCreativeModal';
import { selectedCreativeQueryKey } from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { CustomAddIcon } from '@assets/svgs';

type SecondBarProps = {
    selected: boolean;
    totalSeconds: number;
    second: number;
};
const SecondBar: FC<SecondBarProps> = ({ selected, totalSeconds, second }) => {
    return (
        <Box
            flexGrow={1}
            minWidth=".5rem"
            h={selected ? '0.75rem' : '0.25rem'}
            bg={selected ? 'orange.300' : 'gray.300'}
            opacity={second > totalSeconds ? 0 : 1}
        />
    );
};

type CreativeSummaryCardProps = {
    creative: CreativeResponseDto;
    onActionSuccess: () => void;
};

const CreativeSummaryCard: FC<CreativeSummaryCardProps> = ({ creative, onActionSuccess }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const secondsSelected = creative.brandedMoments?.map((b) => b.end) ?? [];

    const selectedCreativeId = searchParams.get(selectedCreativeQueryKey) ?? '';
    const isCreativeSummaryCardSelected = creative.id === selectedCreativeId;

    const {
        onOpen: onDeleteCreativeModalOpen,
        isOpen: isDeleteCreativeModalOpen,
        onClose: onDeleteCreativeModalClose,
    } = useDisclosure();

    const {
        onOpen: onEditCreativeModalOpen,
        isOpen: isEditCreativeModalOpen,
        onClose: onEditCreativeModalClose,
    } = useDisclosure();

    return (
        <Tooltip
            label="Select a creative to see the formats that it performs the best on"
            placement="top"
            hasArrow
        >
            <Flex
                flexShrink={0}
                display="flex"
                flexDir="column"
                h="6rem"
                p=".625rem"
                bg={isCreativeSummaryCardSelected ? 'green.50' : 'white'}
                borderRadius=".375rem"
                w="12rem"
                role="group"
                border="1px solid"
                borderColor={isCreativeSummaryCardSelected ? 'green.500' : 'gray.200'}
                cursor="pointer"
                onClick={() => {
                    setSearchParams((prev) => {
                        prev.set(selectedCreativeQueryKey, creative.id ?? '');
                        return prev;
                    });
                }}
            >
                <Flex alignItems="flex-start" justifyContent="space-between">
                    <Heading
                        flexGrow={1}
                        textAlign="left"
                        color="gray.700"
                        textOverflow="ellipsis"
                        fontSize=".875rem"
                        fontWeight={600}
                        overflow="hidden"
                        css={{ textWrap: 'nowrap' }}
                        lineHeight="1rem"
                    >
                        {creative.name}
                    </Heading>
                    <Box flexGrow={0}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                size="xs"
                                color="black"
                                p={0}
                                w="1rem"
                                h="1.5rem"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                colorScheme="gray"
                                variant="ghost"
                                rounded="xl"
                            >
                                <Icon as={EllipsisVerticalIcon} boxSize="1rem" strokeWidth="2px" />
                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    onClick={() => onDeleteCreativeModalOpen()}
                                    display="flex"
                                    alignItems="center"
                                    gap=".5rem"
                                >
                                    <Icon as={TrashIcon} boxSize="1rem" />
                                    <Text>Delete</Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>
                <Text
                    textAlign="left"
                    lineHeight="1rem"
                    color="gray.500"
                    fontSize=".75rem"
                    fontWeight={400}
                >
                    {`${creative.duration}s`}
                </Text>
                <Flex mt="auto" gap=".25rem" alignItems="flex-end">
                    {Array.from({ length: 10 }, (_, index) => index + 1).map((second) => {
                        return (
                            <SecondBar
                                selected={secondsSelected.includes(second)}
                                key={second}
                                second={second}
                                totalSeconds={creative.duration ?? 0}
                            />
                        );
                    })}
                </Flex>
                <DeleteCreativeModal
                    isOpen={isDeleteCreativeModalOpen}
                    onClose={onDeleteCreativeModalClose}
                    onSuccess={onActionSuccess}
                    creative={creative}
                />
            </Flex>
        </Tooltip>
    );
};

type CreativeSummaryCardListProps = {
    creatives: CreativeResponseDto[];
    onActionSuccess: () => void;
};
const CreativeSummaryCardList: FC<CreativeSummaryCardListProps> = ({
    creatives,
    onActionSuccess,
}) => {
    return (
        <>
            {creatives.map((creative) => (
                <CreativeSummaryCard
                    creative={creative}
                    key={creative.id}
                    onActionSuccess={onActionSuccess}
                />
            ))}
        </>
    );
};

type NoCreativesProps = {
    onClick: () => void;
    maxNumberOfCreatives: number;
};
const NoCreativesButton: FC<NoCreativesProps> = ({ onClick, maxNumberOfCreatives }) => {
    return (
        <Flex alignItems="center" gap="4rem">
            <Box flexGrow={0} flexShrink={1}>
                <Image src={CustomAddIcon} maxWidth="20rem" />
            </Box>
            <Flex flexDir="column" gap=".5rem" maxW="20rem">
                <Heading color="gray.800" fontSize="1rem" fontWeight={500} textAlign="left">
                    {`Add a creative (up to ${maxNumberOfCreatives})`}
                </Heading>
                <Text textAlign="left" color="gray.500" fontSize="0.875rem">
                    See how creative length and branded moments affect the performance across
                    different formats.
                </Text>
                <Button
                    justifyContent="center"
                    alignItems="center"
                    borderRadius=".375rem"
                    bg="orange.500"
                    type="button"
                    onClick={() => onClick()}
                    display="flex"
                    colorScheme="orange"
                    width="fit-content"
                    size="sm"
                    gap=".25rem"
                >
                    <Icon as={PlusIcon} boxSize="1rem" strokeWidth="2px" />
                    <Text>Add</Text>
                </Button>
            </Flex>
        </Flex>
    );
};
const CreativeSummaryCardLoadingSkeleton: FC = () => {
    return (
        <>
            {[1, 2, 3].map((_, i) => (
                <Skeleton
                    key={i}
                    speed={1}
                    w="12rem"
                    h="6rem"
                    borderRadius=".375rem"
                    css={{ animationDelay: `${i * 100}ms` }}
                />
            ))}
        </>
    );
};
type AddCreativeButtonProps = { onClick: () => void; isDisabled: boolean };
const AddCreativeButton: FC<AddCreativeButtonProps> = ({ onClick, isDisabled }) => {
    return (
        <Tooltip
            label={isDisabled ? 'Maximum number of creatives reached' : 'Add a creative'}
            hasArrow
            placement="top"
        >
            <Button
                type="button"
                onClick={() => onClick()}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius=".375rem"
                bg="orange.500"
                h="6rem"
                w="2rem"
                colorScheme="orange"
                isDisabled={isDisabled}
            >
                <Icon as={PlusIcon} boxSize="1rem" strokeWidth="3px" />
            </Button>
        </Tooltip>
    );
};
type CreativeSummarySectionProps = {
    onAddCreative: () => void;
    creatives: CreativeResponseDto[];
    onActionSuccess: () => void;
    isLoadingAllCreatives: boolean;
    maxNumberOfCreatives: number;
};
export const CreativeSummarySection: FC<CreativeSummarySectionProps> = ({
    onAddCreative,
    creatives,
    onActionSuccess,
    isLoadingAllCreatives,
    maxNumberOfCreatives,
}) => {
    return (
        <Flex justifyContent="flex-start" alignItems="stretch" gap=".75rem" overflowX="auto">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoadingAllCreatives ? (
                <CreativeSummaryCardLoadingSkeleton />
            ) : creatives.length === 0 ? (
                <NoCreativesButton
                    onClick={onAddCreative}
                    maxNumberOfCreatives={maxNumberOfCreatives}
                />
            ) : (
                <>
                    <AddCreativeButton
                        onClick={() => {
                            onAddCreative();
                        }}
                        isDisabled={creatives.length >= maxNumberOfCreatives}
                    />
                    <CreativeSummaryCardList
                        creatives={creatives}
                        onActionSuccess={onActionSuccess}
                    />
                </>
            )}
        </Flex>
    );
};
