import { AdClientApi, AdClientResponseDto } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAdClientsProps {
    getAdClients: () => Promise<Array<AdClientResponseDto>>;
}

type useGetAdClientsHook = () => useGetAdClientsProps;

export const useGetAdClients: useGetAdClientsHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();

    async function getAdClients(): Promise<Array<AdClientResponseDto>> {
        try {
            const config = await getMediaPlanConfig();
            const api = new AdClientApi(config);

            return await api.getAllAdClients();
        } catch (e: any) {
            throw new Error(e);
        }
    }

    return {
        getAdClients,
    };
};
