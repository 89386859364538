import { FC } from 'react';
import {
    LayoutContainerContextProvider,
    useLayoutContainerContext,
} from '@hooks/_contexts/layoutContainer/LayoutContainerContextProvider';
import { layoutPadding, topbarHeight } from '@configs/layoutConfig';
import { Box, Flex } from '@chakra-ui/react';
import { HorizontalNavBarForSharing } from '@components/molecules/navBars/horizontalNavBar/HorizontalNavBarForSharing';

type ShareScenarioInnerLayoutProps = {
    children: React.ReactNode;
};

export const ShareScenarioInnerLayout: FC<ShareScenarioInnerLayoutProps> = ({ children }) => {
    const scrollRef = useLayoutContainerContext();
    return (
        <Flex height="100vh" width="100vw" direction="column">
            <HorizontalNavBarForSharing height={topbarHeight} />
            <Box ref={scrollRef} flexGrow={1} overflow="auto">
                <Box padding={layoutPadding}>{children}</Box>
            </Box>
        </Flex>
    );
};

type ShareScenarioLayoutProps = { children: React.ReactNode };

export const ShareScenarioLayout: FC<ShareScenarioLayoutProps> = ({ children }) => {
    return (
        <LayoutContainerContextProvider>
            <ShareScenarioInnerLayout>{children}</ShareScenarioInnerLayout>
        </LayoutContainerContextProvider>
    );
};
