import {
    ChannelLevelCurvesResponseDto,
    ListAllReachCurvesRelatingToASpecificScenarioRequest,
    ReachCurveApi,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetScenarioReachCurvesHook = () => {
    getScenarioReachCurves: (
        request: ListAllReachCurvesRelatingToASpecificScenarioRequest,
    ) => Promise<Array<ChannelLevelCurvesResponseDto>>;
};

export const useGetScenarioReachCurves: useGetScenarioReachCurvesHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getScenarioReachCurves(
        request: ListAllReachCurvesRelatingToASpecificScenarioRequest,
    ) {
        try {
            const config = await getMediaPlanConfig();
            const api = new ReachCurveApi(config);
            return await api.listAllReachCurvesRelatingToASpecificScenario(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getScenarioReachCurves };
};
