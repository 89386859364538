import { Tour } from 'react-shepherd';
import { exists } from '@api-clients/runtime';

export const TourOptions: Tour.TourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true,
        },
    },
    exitOnEsc: false,
    useModalOverlay: true,
};

export interface AppTours {
    scenarioGeneration?: ProductTour;
    scenarioDetails?: ProductTour;
}

export type TourKey = Exclude<keyof AppTours, 'isSkipped'>;

export interface ProductTour {
    skipped: boolean;
    completed: boolean;
}

export function TourFromJSON(json: any): ProductTour {
    if (json === undefined || json === null) {
        return json;
    }

    return {
        skipped: !exists(json, 'skipped') ? undefined : json['skipped'],
        completed: !exists(json, 'completed') ? undefined : json['completed'],
    };
}

export const TourToJSON = (value?: ProductTour | null): any => {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    return {
        completed: value.completed,
    };
};

export function ProductToursFromJSON(json: any): AppTours {
    if (json === undefined || json === null) {
        return json;
    }

    return {
        scenarioGeneration: !exists(json, 'scenario_generation')
            ? undefined
            : TourFromJSON(json['scenario_generation']),
        scenarioDetails: !exists(json, 'scenario_details')
            ? undefined
            : TourFromJSON(json['scenario_details']),
    };
}

export const ProductToursToJSON = (value?: AppTours | null): any => {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    return {
        scenario_generation: value.scenarioGeneration,
        scenario_details: value.scenarioDetails,
    };
};
