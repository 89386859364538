/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { OrganisationStatus } from './OrganisationStatus';
import {
    OrganisationStatusFromJSON,
    OrganisationStatusFromJSONTyped,
    OrganisationStatusToJSON,
} from './OrganisationStatus';

/**
 *
 * @export
 * @interface UpdateOrganisationRequestDto
 */
export interface UpdateOrganisationRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateOrganisationRequestDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {CountryCodeIso2}
     * @memberof UpdateOrganisationRequestDto
     */
    region?: CountryCodeIso2;
    /**
     *
     * @type {OrganisationStatus}
     * @memberof UpdateOrganisationRequestDto
     */
    status?: OrganisationStatus;
    /**
     *
     * @type {boolean}
     * @memberof UpdateOrganisationRequestDto
     */
    isArchived?: boolean;
}

/**
 * Check if a given object implements the UpdateOrganisationRequestDto interface.
 */
export function instanceOfUpdateOrganisationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOrganisationRequestDtoFromJSON(json: any): UpdateOrganisationRequestDto {
    return UpdateOrganisationRequestDtoFromJSONTyped(json, false);
}

export function UpdateOrganisationRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateOrganisationRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        region: !exists(json, 'region') ? undefined : CountryCodeIso2FromJSON(json['region']),
        status: !exists(json, 'status') ? undefined : OrganisationStatusFromJSON(json['status']),
        isArchived: !exists(json, 'is_archived') ? undefined : json['is_archived'],
    };
}

export function UpdateOrganisationRequestDtoToJSON(
    value?: UpdateOrganisationRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_name: value.organisationName,
        region: CountryCodeIso2ToJSON(value.region),
        status: OrganisationStatusToJSON(value.status),
        is_archived: value.isArchived,
    };
}
