import { useTranslation } from 'react-i18next';

export const useErrorDictionary = () => {
    const { t } = useTranslation('errors');

    const errorDictionary = [
        {
            checkTerm: 'is not registered yet',
            displayedMessage: t('auth.userIsNotRegistered'),
        },
        {
            checkTerm: 'No data of the user',
            displayedMessage: t('auth.userIsNotRegistered'),
        },
        {
            // this is from Cognito
            checkTerm: 'Incorrect username or password',
            displayedMessage: t('auth.incorrectUsernamePassword'),
        },
        {
            // this is from Auth0
            checkTerm: 'Wrong email or password',
            displayedMessage: t('auth.wrongEmailOrPassword'),
        },
        {
            checkTerm: 'Email address is not found',
            displayedMessage: t('auth.emailAddressIsNotFound'),
        },
        {
            checkTerm: 'Invalid verification code provided, please try again',
            displayedMessage: t('auth.invalidConfirmForgotPasswordCode'),
        },
        {
            checkTerm: 'Attempt limit exceeded, please try after some time',
            displayedMessage: t('auth.attemptExceedLimit'),
        },
        {
            checkTerm: 'temporary password has expired',
            displayedMessage: t('auth.temporaryPasswordExpired'),
        },
        {
            checkTerm: 'session is expired',
            displayedMessage: t('auth.sessionExpired'),
        },
        {
            checkTerm: 'user password cannot be reset in the current state',
            displayedMessage: t('auth.requiredNewPasswordBeforeRequestForgotPassword'),
        },
        {
            checkTerm: 'already joined the organisation',
            displayedMessage: t('organisation.userHasJoinedOtherOrg'),
        },
        {
            checkTerm: 'already been part of the organisation',
            displayedMessage: t('organisation.userIsPartOfOrganisation'),
        },
    ];

    return {
        errorDictionary,
    };
};
