/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ChannelLevelResultResponseDto } from './ChannelLevelResultResponseDto';
import {
    ChannelLevelResultResponseDtoFromJSON,
    ChannelLevelResultResponseDtoFromJSONTyped,
    ChannelLevelResultResponseDtoToJSON,
} from './ChannelLevelResultResponseDto';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';

/**
 *
 * @export
 * @interface ScenarioLevelResultResponseDto
 */
export interface ScenarioLevelResultResponseDto {
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    outcomeMetric?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    shortTermLift?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    longTermLift?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    totalAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    targetAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    reach?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    impression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    costPerActiveAttentionDay?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    costPerReach?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    activeAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    activeAttentionSecPerImpression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    activeAttentionSecPerReach?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    passiveAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    passiveAttentionSecPerImpression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    passiveAttentionSecPerReach?: number | null;
    /**
     *
     * @type {Array<ChannelLevelResultResponseDto>}
     * @memberof ScenarioLevelResultResponseDto
     */
    channelLevelResults?: Array<ChannelLevelResultResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultResponseDto
     */
    scenarioId?: string;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof ScenarioLevelResultResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultResponseDto
     */
    optimisationIteration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultResponseDto
     */
    codedCommentary?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultResponseDto
     */
    modelVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ScenarioLevelResultResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ScenarioLevelResultResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ScenarioLevelResultResponseDto interface.
 */
export function instanceOfScenarioLevelResultResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioLevelResultResponseDtoFromJSON(json: any): ScenarioLevelResultResponseDto {
    return ScenarioLevelResultResponseDtoFromJSONTyped(json, false);
}

export function ScenarioLevelResultResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioLevelResultResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        outcomeMetric: !exists(json, 'outcome_metric') ? undefined : json['outcome_metric'],
        shortTermLift: !exists(json, 'short_term_lift') ? undefined : json['short_term_lift'],
        longTermLift: !exists(json, 'long_term_lift') ? undefined : json['long_term_lift'],
        totalAudience: !exists(json, 'total_audience') ? undefined : json['total_audience'],
        targetAudience: !exists(json, 'target_audience') ? undefined : json['target_audience'],
        reach: !exists(json, 'reach') ? undefined : json['reach'],
        impression: !exists(json, 'impression') ? undefined : json['impression'],
        costPerActiveAttentionDay: !exists(json, 'cost_per_active_attention_day')
            ? undefined
            : json['cost_per_active_attention_day'],
        costPerReach: !exists(json, 'cost_per_reach') ? undefined : json['cost_per_reach'],
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : json['active_attention_volume_seconds'],
        activeAttentionSecPerImpression: !exists(json, 'active_attention_sec_per_impression')
            ? undefined
            : json['active_attention_sec_per_impression'],
        activeAttentionSecPerReach: !exists(json, 'active_attention_sec_per_reach')
            ? undefined
            : json['active_attention_sec_per_reach'],
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : json['passive_attention_volume_seconds'],
        passiveAttentionSecPerImpression: !exists(json, 'passive_attention_sec_per_impression')
            ? undefined
            : json['passive_attention_sec_per_impression'],
        passiveAttentionSecPerReach: !exists(json, 'passive_attention_sec_per_reach')
            ? undefined
            : json['passive_attention_sec_per_reach'],
        channelLevelResults: !exists(json, 'channel_level_results')
            ? undefined
            : json['channel_level_results'] === null
            ? null
            : (json['channel_level_results'] as Array<any>).map(
                  ChannelLevelResultResponseDtoFromJSON,
              ),
        scenarioId: !exists(json, 'scenario_id') ? undefined : json['scenario_id'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        optimisationIteration: !exists(json, 'optimisation_iteration')
            ? undefined
            : json['optimisation_iteration'],
        codedCommentary: !exists(json, 'coded_commentary') ? undefined : json['coded_commentary'],
        modelVersion: !exists(json, 'model_version') ? undefined : json['model_version'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ScenarioLevelResultResponseDtoToJSON(
    value?: ScenarioLevelResultResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        outcome_metric: value.outcomeMetric,
        short_term_lift: value.shortTermLift,
        long_term_lift: value.longTermLift,
        total_audience: value.totalAudience,
        target_audience: value.targetAudience,
        reach: value.reach,
        impression: value.impression,
        cost_per_active_attention_day: value.costPerActiveAttentionDay,
        cost_per_reach: value.costPerReach,
        active_attention_volume_seconds: value.activeAttentionVolumeSeconds,
        active_attention_sec_per_impression: value.activeAttentionSecPerImpression,
        active_attention_sec_per_reach: value.activeAttentionSecPerReach,
        passive_attention_volume_seconds: value.passiveAttentionVolumeSeconds,
        passive_attention_sec_per_impression: value.passiveAttentionSecPerImpression,
        passive_attention_sec_per_reach: value.passiveAttentionSecPerReach,
        channel_level_results:
            value.channelLevelResults === undefined
                ? undefined
                : value.channelLevelResults === null
                ? null
                : (value.channelLevelResults as Array<any>).map(
                      ChannelLevelResultResponseDtoToJSON,
                  ),
        scenario_id: value.scenarioId,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        optimisation_iteration: value.optimisationIteration,
        coded_commentary: value.codedCommentary,
        model_version: value.modelVersion,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
