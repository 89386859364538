import { Flex, Stat, StatArrow, StatHelpText, Text } from '@chakra-ui/react';
import { isAlmostEqual, toAppropriateDecimalPlace, toLocaleFixed } from '@shared/utils';
import { FC } from 'react';

export interface StatisticCellProps {
    value: number;
    comparedWithValue?: number;
    isPercentage?: boolean;
    isHighlighted?: boolean;
    isCurrency?: boolean;
    toDecimalPlace?: number;
}

export const StatisticCell: FC<StatisticCellProps> = ({
    value,
    comparedWithValue,
    isPercentage,
    isHighlighted,
    isCurrency,
    toDecimalPlace = 2,
}) => {
    let difference = 0;
    if (comparedWithValue && value) {
        if (isPercentage) {
            difference = value - comparedWithValue;
        } else {
            difference = ((value - comparedWithValue) / comparedWithValue) * 100;
        }
    }

    const formattedValue = toLocaleFixed(value ?? 0, toDecimalPlace);

    return (
        <Stat fontWeight={isHighlighted ? 'semibold' : 'normal'}>
            <Flex gap="0.125rem" justifyContent="end">
                {isCurrency && (
                    <Text fontWeight="normal" color="gray.500">
                        $
                    </Text>
                )}
                <Text>{formattedValue}</Text>
                {isPercentage && (
                    <Text fontWeight="normal" color="gray.500">
                        %
                    </Text>
                )}
            </Flex>
            {!isAlmostEqual(difference, 0) && (
                <StatHelpText marginBottom={0}>
                    <StatArrow type={difference > 0 ? 'increase' : 'decrease'} />
                    {toAppropriateDecimalPlace(Math.abs(difference))}%
                </StatHelpText>
            )}
        </Stat>
    );
};
