import { Button, Icon, Link, Tooltip } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface NavBarButtonProps {
    href?: string;
    colorScheme?: string;
    label: string | ReactJSXElement;
    leftIcon: any;
    rightIcon?: any;
    isExternal?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    tooltip?: string;
}

interface ButtonWithoutLinkProps extends NavBarButtonProps {
    isActive?: boolean;
}

const ButtonWithoutLink: FC<ButtonWithoutLinkProps> = (props) => {
    return (
        <>
            {props.tooltip ? (
                <Tooltip label={props.tooltip} margin={-2} hasArrow>
                    <Button
                        isDisabled={props.disabled}
                        isActive={props.isActive}
                        variant="ghost"
                        justifyContent="start"
                        width="100%"
                        onClick={props.onClick}
                        transition="background-color 200ms linear"
                        colorScheme={props.isActive ? props.colorScheme : 'gray'}
                        leftIcon={<Icon boxSize="1.5em" as={props.leftIcon} />}
                        rightIcon={props.rightIcon && <Icon boxSize="1.2em" as={props.rightIcon} />}
                    >
                        {props.label}
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    isDisabled={props.disabled}
                    isActive={props.isActive}
                    variant="ghost"
                    justifyContent="start"
                    width="100%"
                    onClick={props.onClick}
                    transition="background-color 200ms linear"
                    colorScheme={props.isActive ? props.colorScheme : 'gray'}
                    leftIcon={<Icon boxSize="1.5em" as={props.leftIcon} />}
                    rightIcon={props.rightIcon && <Icon boxSize="1.2em" as={props.rightIcon} />}
                >
                    {props.label}
                </Button>
            )}
        </>
    );
};

const ButtonWithLink: FC<NavBarButtonProps> = (props) => {
    return (
        <>
            {props.disabled ? (
                <ButtonWithoutLink {...props} />
            ) : (
                <NavLink to={`/${props.href}`}>
                    {({ isActive }) => <ButtonWithoutLink isActive={isActive} {...props} />}
                </NavLink>
            )}
        </>
    );
};

export const NavBarButton: FC<NavBarButtonProps> = (props) => {
    if (props.isExternal && props.href) {
        // wrap button in external link
        return (
            <Link key={props.href} isExternal href={props.href}>
                <ButtonWithoutLink {...props} />
            </Link>
        );
    }

    if (props.href) {
        return <ButtonWithLink {...props} />;
    }

    return <ButtonWithoutLink {...props} />;
};
