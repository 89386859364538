import { pdf } from '@react-pdf/renderer';
import {
    GetScenarioLevelResultComparisonResponseDto,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { ScenarioDetailPdf } from '@apps/attentionADJUST/components/organisms';
import ExcelJS from 'exceljs';
import { attentionPlanExcelLogo } from '@assets/images';
import { findCountryName } from '@shared/cores/types/Country';
import { getOptimisationObjective } from '@shared/cores/types';
import { useHelper } from '@shared/utils';

export const useExport = (scenario: ScenarioWithComparisonResponseDto) => {
    const { getReachValue, formatAgeGroup, formatStringToCapitalized } = useHelper();

    async function exportToPdf(
        images: string[],
        scenarioLevelResultsComparison: GetScenarioLevelResultComparisonResponseDto,
    ): Promise<Blob> {
        const blob = await pdf(
            <ScenarioDetailPdf
                scenario={scenario as ScenarioWithComparisonResponseDto}
                images={images}
                scenarioLevelResultsComparison={scenarioLevelResultsComparison}
            />,
        ).toBlob();

        return blob;
    }

    async function exportToExcel(images: string[], isFreePlan = false): Promise<Blob> {
        const percentageNumFmt = '0.00%';
        const numberWithTwoDecimalFmt = '#,##0.00';
        const solidBorder: Partial<ExcelJS.Borders> = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
        const headerCellStyle: Partial<ExcelJS.Style> = {
            font: { color: { argb: 'ffffffff' }, size: 14, bold: true, name: 'Calibri' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '#ff2e6e45' },
            },
            alignment: { vertical: 'middle', horizontal: 'center' },
        };
        const tableHeaderCellStyle: Partial<ExcelJS.Style> = {
            font: { size: 14, bold: true, name: 'Calibri' },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: solidBorder,
        };
        const tableValueCellStyle: Partial<ExcelJS.Style> = {
            font: { size: 14, name: 'Calibri' },
            border: solidBorder,
            numFmt: numberWithTwoDecimalFmt,
        };
        const tableValueCellPercentageStyle: Partial<ExcelJS.Style> = {
            font: { size: 14, name: 'Calibri' },
            border: solidBorder,
            numFmt: percentageNumFmt,
        };

        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Amplified Intelligence';
        workbook.lastModifiedBy = 'Amplified Intelligence';
        const now = new Date();
        workbook.created = now;
        workbook.modified = now;
        workbook.calcProperties.fullCalcOnLoad = true;
        const sheet = workbook.addWorksheet('data');
        sheet.properties.defaultColWidth = 45;

        const firstRow = sheet.getRow(1);
        firstRow.height = 60;
        const res = await fetch(attentionPlanExcelLogo);
        const imageBuffer = await res.arrayBuffer();
        const logoId = workbook.addImage({ buffer: imageBuffer, extension: 'png' });
        sheet.addImage(logoId, 'A1:A1');

        // Cell A3: campaign name
        const campaignNameCell = sheet.getCell('A3');
        campaignNameCell.style = { font: { size: 20, bold: true, name: 'Calibri' } };
        campaignNameCell.value =
            scenario.campaignBasicInfo?.campaignName ?? 'Unknwon Campaign Name';

        // Cell A5: timestamp
        const timestampCell = sheet.getCell('A5');
        timestampCell.style = {
            font: { size: 14, name: 'Calibri' },
        };
        timestampCell.value = `Exported at: ${new Date().toLocaleString()}`;

        const scenarioParamCell = sheet.getCell('A7');
        scenarioParamCell.style = headerCellStyle;
        scenarioParamCell.value = 'Scenario Parameters';

        sheet.getCell('A9').value = 'Name';
        sheet.getCell('B9').value = scenario.scenarioName;
        sheet.getCell('A10').value = 'Last modified at';
        sheet.getCell('B10').value = scenario.updatedAt?.toLocaleString() ?? '';
        sheet.getCell('A11').value = 'Start date';
        sheet.getCell('B11').value = scenario.startDate?.toLocaleDateString() ?? '';
        sheet.getCell('A12').value = 'End date';
        sheet.getCell('B12').value = scenario.endDate?.toLocaleDateString() ?? '';
        sheet.getCell('A13').value = 'Countries';
        sheet.getCell('B13').value =
            scenario.countries?.map((c) => findCountryName(c)).join(', ') ?? '';
        sheet.getCell('A14').value = 'Age groups';
        sheet.getCell('B14').value =
            scenario.ageGroups?.map((ageGroup) => formatAgeGroup(ageGroup)).join(', ') ?? '';
        sheet.getCell('A15').value = 'Genders';
        sheet.getCell('B15').value =
            scenario.genders?.map((gender) => formatStringToCapitalized(gender)).join(', ') ?? '';
        sheet.getCell('A16').value = 'Budget constraints';
        sheet.getCell('B16').value =
            scenario.budgetConstraintType === 'adjustment_tolerance_level'
                ? 'Plan Tolerance'
                : 'Minimum Spend';
        sheet.getCell('A17').value = 'Optimisation objective';
        sheet.getCell('B17').value = scenario.optimisationStrategy
            ? getOptimisationObjective(scenario.optimisationStrategy)
            : '';
        sheet.getCell('A18').value = 'Formats';
        sheet.getCell('B18').value =
            scenario.scenarioLevelResults
                ?.at(0)
                ?.channelLevelResults?.map(
                    (ch) =>
                        `${ch.adChannel?.adChannelName} (${ch.formatLevelResults?.length ?? 0})`,
                )
                .join(', ') ?? '';

        for (let row = 9; row <= 18; row++) {
            sheet.getCell(`A${row}`).style = tableHeaderCellStyle;
            sheet.getCell(`B${row}`).style = tableValueCellStyle;
        }

        const scenarioMixChannelViewCell = sheet.getCell('A20');
        scenarioMixChannelViewCell.style = headerCellStyle;
        scenarioMixChannelViewCell.value = 'Scenario mix detailed channel view';

        sheet.getCell('A22').value = 'Channel';
        sheet.getCell('B22').value = 'Budget($)';
        sheet.getCell('C22').value = 'Mix';
        sheet.getCell('D22').value = 'Impression';
        sheet.getCell('E22').value = 'Reach';
        sheet.getCell('F22').value = 'Target Audience';
        sheet.getCell('G22').value = 'Active attention volume (s)';
        sheet.getCell('H22').value = 'Passive attention volume (s)';
        const COLS = 'ABCDEFGH';
        for (let i = 0; i < COLS.length; i++) {
            const col = COLS[i];
            sheet.getCell(`${col}22`).style = tableHeaderCellStyle;
        }
        let rowNumber = 23;
        // output data
        if (
            scenario.scenarioLevelResults &&
            scenario.scenarioLevelResults[0] &&
            scenario.scenarioLevelResults[0].channelLevelResults
        ) {
            for (
                let i = 0;
                i < scenario.scenarioLevelResults[0].channelLevelResults.length;
                i++, rowNumber++
            ) {
                const result = scenario.scenarioLevelResults[0].channelLevelResults[i];
                sheet.getCell(`A${rowNumber}`).value = result.adChannel?.adChannelName;
                sheet.getCell(`B${rowNumber}`).value = result.budget?.value;
                sheet.getCell(`C${rowNumber}`).value = result.mix?.value;
                sheet.getCell(`D${rowNumber}`).value = result.impression?.value;
                sheet.getCell(`E${rowNumber}`).value = result.reachPercentage?.value;
                sheet.getCell(`F${rowNumber}`).value = result.targetAudience?.value;
                sheet.getCell(`G${rowNumber}`).value = result.activeAttentionVolumeSeconds?.value;
                sheet.getCell(`H${rowNumber}`).value = result.passiveAttentionVolumeSeconds?.value;

                for (let j = 0; j < COLS.length; j++) {
                    const col = COLS[j];
                    if (col === 'C' || col === 'E') {
                        sheet.getCell(`${col}${rowNumber}`).style = tableValueCellPercentageStyle;
                    } else {
                        sheet.getCell(`${col}${rowNumber}`).style = tableValueCellStyle;
                    }
                }
            }
        }

        if (!isFreePlan) {
            rowNumber += 2;
            const scenarioMixFormatViewCell = sheet.getCell(`A${rowNumber}`);
            scenarioMixFormatViewCell.value = 'Scenario mix detailed format view';
            scenarioMixFormatViewCell.style = headerCellStyle;
            rowNumber += 2;
            sheet.getCell(`A${rowNumber}`).value = 'Channel';
            sheet.getCell(`B${rowNumber}`).value = 'Format';
            sheet.getCell(`C${rowNumber}`).value = 'Budget($)';
            sheet.getCell(`D${rowNumber}`).value = 'Mix';
            sheet.getCell(`E${rowNumber}`).value = 'Impression';
            sheet.getCell(`F${rowNumber}`).value = 'Active attention volume (s)';
            sheet.getCell(`G${rowNumber}`).value = 'Passive attention volume (s)';
            const shortCOLS = 'ABCDEFG';
            for (let i = 0; i < shortCOLS.length; i++) {
                const col = shortCOLS[i];
                sheet.getCell(`${col}${rowNumber}`).style = tableHeaderCellStyle;
            }
            rowNumber += 1;
            const scenarioMixDetailedFormatBaseRowNumber = rowNumber;
            // scenario mix detailed format view
            if (
                scenario.scenarioLevelResults &&
                scenario.scenarioLevelResults[0] &&
                scenario.scenarioLevelResults[0].channelLevelResults
            ) {
                for (
                    let i = 0;
                    i < scenario.scenarioLevelResults[0].channelLevelResults.length;
                    i++
                ) {
                    const channelLevelResult =
                        scenario.scenarioLevelResults[0].channelLevelResults[i];
                    sheet.getCell(`A${rowNumber}`).value =
                        channelLevelResult.adChannel?.adChannelName;
                    const startRowNumber = rowNumber;
                    if (channelLevelResult.formatLevelResults) {
                        for (
                            let j = 0;
                            j < channelLevelResult.formatLevelResults?.length;
                            j++, rowNumber++
                        ) {
                            const formatLevelResult = channelLevelResult.formatLevelResults[j];
                            //
                            sheet.getCell(`B${rowNumber}`).value =
                                formatLevelResult.adFormat?.adFormatName;
                            sheet.getCell(`C${rowNumber}`).value = formatLevelResult.budget?.value;
                            sheet.getCell(`D${rowNumber}`).value = formatLevelResult.mix?.value;
                            sheet.getCell(`E${rowNumber}`).value =
                                formatLevelResult.impression?.value;
                            sheet.getCell(`F${rowNumber}`).value =
                                formatLevelResult.activeAttentionVolumeSeconds?.value;
                            sheet.getCell(`G${rowNumber}`).value =
                                formatLevelResult.passiveAttentionVolumeSeconds?.value;
                        }
                    }
                    const endRowNumber = rowNumber - 1;
                    // merge cell A${startRowNumber}:A${endRowNumber}
                    try {
                        sheet.mergeCells(`A${startRowNumber}:A${endRowNumber}`);
                    } catch (e) {
                        /** Try merge cell, if merging fails, do nothing */
                    }
                }
                // apply styling
                for (let row = scenarioMixDetailedFormatBaseRowNumber; row < rowNumber; row++) {
                    for (let k = 0; k < shortCOLS.length; k++) {
                        const col = shortCOLS[k];
                        if (col === 'D') {
                            sheet.getCell(`${col}${row}`).style = tableValueCellPercentageStyle;
                        } else {
                            sheet.getCell(`${col}${row}`).style = tableValueCellStyle;
                        }
                    }
                }
            }
        }

        // key metrics section
        rowNumber += 2;
        const keyOptimisedMetricCell = sheet.getCell(`A${rowNumber}`);
        keyOptimisedMetricCell.style = headerCellStyle;
        keyOptimisedMetricCell.value = 'Key optimised metrics';
        rowNumber += 2;
        const metricSectionBaseRowNumber = rowNumber;
        // output title
        sheet.getCell(`A${metricSectionBaseRowNumber}`).value = 'Drivers';
        sheet.getCell(`A${metricSectionBaseRowNumber + 1}`).value = 'Attention seconds per reach';
        sheet.getCell(`A${metricSectionBaseRowNumber + 2}`).value =
            'Attention seconds per impression';
        sheet.getCell(`A${metricSectionBaseRowNumber + 3}`).value = 'Brand Uplift';
        sheet.getCell(`A${metricSectionBaseRowNumber + 4}`).value = 'Impressions';
        sheet.getCell(`A${metricSectionBaseRowNumber + 5}`).value = 'Reach';
        sheet.getCell(`A${metricSectionBaseRowNumber + 6}`).value =
            'Active attention volume seconds';
        // metrics summary
        sheet.getCell(`B${metricSectionBaseRowNumber}`).value = scenario.scenarioName;
        sheet.getCell(`B${metricSectionBaseRowNumber + 1}`).value =
            scenario.scenarioLevelResults?.at(0)?.activeAttentionSecPerReach?.value;
        sheet.getCell(`B${metricSectionBaseRowNumber + 2}`).value =
            scenario.scenarioLevelResults?.at(0)?.activeAttentionSecPerImpression?.value;
        sheet.getCell(`B${metricSectionBaseRowNumber + 3}`).value =
            scenario.scenarioLevelResults?.at(0)?.shortTermLift?.value;
        sheet.getCell(`B${metricSectionBaseRowNumber + 4}`).value =
            scenario.scenarioLevelResults?.at(0)?.impression?.value;
        sheet.getCell(`B${metricSectionBaseRowNumber + 5}`).value = getReachValue(
            scenario.scenarioLevelResults?.at(0),
        ).value;
        sheet.getCell(`B${metricSectionBaseRowNumber + 6}`).value =
            scenario.scenarioLevelResults?.at(0)?.activeAttentionVolumeSeconds?.value;
        sheet.getCell(`C${metricSectionBaseRowNumber}`).value = 'Base';
        sheet.getCell(`C${metricSectionBaseRowNumber + 1}`).value =
            scenario.scenarioLevelResults?.at(0)?.activeAttentionSecPerReach?.comparedWithValue;
        sheet.getCell(`C${metricSectionBaseRowNumber + 2}`).value =
            scenario.scenarioLevelResults?.at(
                0,
            )?.activeAttentionSecPerImpression?.comparedWithValue;
        sheet.getCell(`C${metricSectionBaseRowNumber + 3}`).value =
            scenario.scenarioLevelResults?.at(0)?.shortTermLift?.comparedWithValue;
        sheet.getCell(`C${metricSectionBaseRowNumber + 4}`).value =
            scenario.scenarioLevelResults?.at(0)?.impression?.comparedWithValue;
        sheet.getCell(`C${metricSectionBaseRowNumber + 5}`).value = getReachValue(
            scenario.scenarioLevelResults?.at(0),
        ).comparedWithValue;
        sheet.getCell(`C${metricSectionBaseRowNumber + 6}`).value =
            scenario.scenarioLevelResults?.at(0)?.activeAttentionVolumeSeconds?.comparedWithValue;

        // Apply style
        for (let row = metricSectionBaseRowNumber; row <= metricSectionBaseRowNumber + 6; row++) {
            sheet.getCell(`A${row}`).style = {
                font: { size: 14, bold: true, name: 'Calibri' },
                alignment: { vertical: 'middle' },
                border: solidBorder,
            };
            if (row === metricSectionBaseRowNumber + 3) {
                sheet.getCell(`B${row}`).style = tableValueCellPercentageStyle;
                sheet.getCell(`C${row}`).style = tableValueCellPercentageStyle;
            } else {
                sheet.getCell(`B${row}`).style = tableValueCellStyle;
                sheet.getCell(`C${row}`).style = tableValueCellStyle;
            }
        }
        rowNumber += 10; // so that the chart does not cover up the metric section
        const aarcCell = sheet.getCell(`A${rowNumber}`);
        aarcCell.value = 'Attention-adjusted® reach curves';
        aarcCell.style = headerCellStyle;
        rowNumber += 2;
        const imageVerticalOffset = 25;
        for (let i = 0; i < images.length; i++, rowNumber += imageVerticalOffset) {
            const dataURL = images[i];
            const imageId = workbook.addImage({ base64: dataURL, extension: 'png' });
            // determine image dimension
            /* eslint-disable-next-line */
            const imageDimension = await new Promise<{ width: number; height: number }>(
                (resolve, _) => {
                    const image = new Image();
                    image.src = dataURL ?? '';
                    image.onload = () => {
                        resolve({ width: image.naturalWidth, height: image.naturalHeight });
                    };
                },
            );
            const { width, height } = imageDimension;
            const scaleFactor = 0.5;
            sheet.addImage(imageId, {
                tl: { col: 0, row: rowNumber },
                ext: { width: width * scaleFactor, height: height * scaleFactor },
            });
        }

        const buf = await workbook.xlsx.writeBuffer();
        return new Blob([buf], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'",
        });
    }

    return {
        exportToPdf,
        exportToExcel,
    };
};
