/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AccessLevelType } from './AccessLevelType';
import {
    AccessLevelTypeFromJSON,
    AccessLevelTypeFromJSONTyped,
    AccessLevelTypeToJSON,
} from './AccessLevelType';
import type { RoleTitle } from './RoleTitle';
import { RoleTitleFromJSON, RoleTitleFromJSONTyped, RoleTitleToJSON } from './RoleTitle';

/**
 *
 * @export
 * @interface InviteNewUserDto
 */
export interface InviteNewUserDto {
    /**
     *
     * @type {AccessLevelType}
     * @memberof InviteNewUserDto
     */
    accessLevel?: AccessLevelType;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    emailAddress?: string | null;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    countryPhoneCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {RoleTitle}
     * @memberof InviteNewUserDto
     */
    roleTitle?: RoleTitle;
    /**
     *
     * @type {string}
     * @memberof InviteNewUserDto
     */
    otherRole?: string | null;
}

/**
 * Check if a given object implements the InviteNewUserDto interface.
 */
export function instanceOfInviteNewUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InviteNewUserDtoFromJSON(json: any): InviteNewUserDto {
    return InviteNewUserDtoFromJSONTyped(json, false);
}

export function InviteNewUserDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InviteNewUserDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        accessLevel: !exists(json, 'access_level')
            ? undefined
            : AccessLevelTypeFromJSON(json['access_level']),
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
        countryPhoneCode: !exists(json, 'country_phone_code')
            ? undefined
            : json['country_phone_code'],
        phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
        roleTitle: !exists(json, 'role_title') ? undefined : RoleTitleFromJSON(json['role_title']),
        otherRole: !exists(json, 'other_role') ? undefined : json['other_role'],
    };
}

export function InviteNewUserDtoToJSON(value?: InviteNewUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        access_level: AccessLevelTypeToJSON(value.accessLevel),
        first_name: value.firstName,
        last_name: value.lastName,
        email_address: value.emailAddress,
        country_phone_code: value.countryPhoneCode,
        phone_number: value.phoneNumber,
        role_title: RoleTitleToJSON(value.roleTitle),
        other_role: value.otherRole,
    };
}
