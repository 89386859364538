import {
    Box,
    Flex,
    Icon,
    TableCellProps,
    Text,
    Th,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import { SortingIcon } from '@components/atoms';
import { SortDirection } from '@shared/cores';
import { TableHeaderType } from './CampaignCreationTable';

type TableHeaderCellProps = {
    header: TableHeaderType;
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
};

const TableHeaderCell: FC<TableHeaderCellProps> = ({ header, setTableData, tableData }) => {
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Unsorted);

    const toggleSortDirection = () => {
        if (!header.sortable) {
            return;
        }
        const newTableData = structuredClone(tableData);
        switch (sortDirection) {
            case SortDirection.Unsorted:
                setSortDirection(SortDirection.Ascending);
                newTableData.sort(
                    (a, b) => (a[header.accessorKey] as number) - (b[header.accessorKey] as number),
                );
                setTableData(newTableData);
                break;
            case SortDirection.Ascending:
                setSortDirection(SortDirection.Descending);
                newTableData.sort(
                    (a, b) => (b[header.accessorKey] as number) - (a[header.accessorKey] as number),
                );
                setTableData(newTableData);
                break;
            case SortDirection.Descending:
                setSortDirection(SortDirection.Unsorted);
                newTableData.sort((a, b) =>
                    (a.channelName ?? '').localeCompare(b.channelName ?? ''),
                );
                setTableData(newTableData);
                break;
            default:
                break;
        }
    };
    const getTooltipText = () => {
        switch (sortDirection) {
            case SortDirection.Unsorted:
                return 'Click to sort ascending';
            case SortDirection.Ascending:
                return 'Click to sort descending';
            case SortDirection.Descending:
            default:
                return 'Click to cancel sorting';
        }
    };

    if (header.tooltip && header.tooltip.length > 0) {
        return (
            <Tooltip label={header.tooltip} hasArrow placement="top">
                <Th
                    role="group"
                    onClick={() => {
                        toggleSortDirection();
                    }}
                    cursor={header.sortable ? 'pointer' : 'auto'}
                    minWidth={header.minWidth ? header.minWidth : 'auto'}
                >
                    <Flex
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        gap="0.25rem"
                    >
                        {header.sortable && (
                            <Tooltip label={getTooltipText()}>
                                <Box>
                                    <SortingIcon
                                        transition="opacity 200ms linear"
                                        opacity={sortDirection === SortDirection.Unsorted ? 0 : 1}
                                        _groupHover={{ opacity: 1 }}
                                        boxSize="1rem"
                                        sortDirection={sortDirection}
                                    />
                                </Box>
                            </Tooltip>
                        )}
                        <Text>{header.label}</Text>
                    </Flex>
                </Th>
            </Tooltip>
        );
    }
    return (
        <Th
            role="group"
            onClick={() => {
                toggleSortDirection();
            }}
            cursor={header.sortable ? 'pointer' : 'auto'}
            minWidth={header.minWidth ? header.minWidth : 'auto'}
        >
            <Flex alignItems="center" direction="row" justifyContent="space-between" gap="0.25rem">
                {header.sortable && (
                    <Tooltip label={getTooltipText()}>
                        <Box>
                            <SortingIcon
                                transition="opacity 200ms linear"
                                opacity={sortDirection === SortDirection.Unsorted ? 0 : 1}
                                _groupHover={{ opacity: 1 }}
                                boxSize="1rem"
                                sortDirection={sortDirection}
                            />
                        </Box>
                    </Tooltip>
                )}
                <Text>{header.label}</Text>
            </Flex>
        </Th>
    );
};

export default TableHeaderCell;
