/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface CreateAdClientRequestDto
 */
export interface CreateAdClientRequestDto {
    /**
     *
     * @type {string}
     * @memberof CreateAdClientRequestDto
     */
    adClientName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateAdClientRequestDto
     */
    primaryContactName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateAdClientRequestDto
     */
    phone?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateAdClientRequestDto
     */
    email?: string | null;
}

/**
 * Check if a given object implements the CreateAdClientRequestDto interface.
 */
export function instanceOfCreateAdClientRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAdClientRequestDtoFromJSON(json: any): CreateAdClientRequestDto {
    return CreateAdClientRequestDtoFromJSONTyped(json, false);
}

export function CreateAdClientRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateAdClientRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adClientName: !exists(json, 'ad_client_name') ? undefined : json['ad_client_name'],
        primaryContactName: !exists(json, 'primary_contact_name')
            ? undefined
            : json['primary_contact_name'],
        phone: !exists(json, 'phone') ? undefined : json['phone'],
        email: !exists(json, 'email') ? undefined : json['email'],
    };
}

export function CreateAdClientRequestDtoToJSON(value?: CreateAdClientRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_client_name: value.adClientName,
        primary_contact_name: value.primaryContactName,
        phone: value.phone,
        email: value.email,
    };
}
