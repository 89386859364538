import { ChannelApi, ChannelResponseDto } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetChannelsHook = () => {
    getChannels: () => Promise<Array<ChannelResponseDto>>;
};

export const useGetChannels: useGetChannelsHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getChannels() {
        try {
            const config = await getMediaPlanConfig();

            const api = new ChannelApi(config);
            return await api.listAllAdChannelsAndTheirAdFormats();
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getChannels };
};
