export const removeMessages = {
    user: {
        success: {
            title: 'Member has been removed.',
            description: 'Member has been removed from',
        },
        error: {
            title: 'User has not been removed.',
            description: 'An error has occurred.',
        },
    },
    client: {
        success: {
            title: 'Client has been removed.',
            description: 'Client has been removed from',
        },
        error: {
            title: 'Client has not been removed.',
            description: 'An error has occurred.',
        },
    }
};
