import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveLineItem, ProveLineItemVast} from "@api-clients/prove/schema/ProveLineItem";

export class SaveProveLineItemVastApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign,
        lineItem: ProveLineItem,
        lineItemVast: ProveLineItemVast
    ): Promise<ProveLineItem> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaign.id}/insertion-orders/${campaign.insertionOrder!.id}/line-items/${lineItem.id}/vast`, {
            method: 'PUT',
            headers: configuration.headers,
            body: JSON.stringify(lineItemVast)
        });

        return response.json();
    }

}
