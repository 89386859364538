export interface SelectOption {
    value: any;
    label: string;
}

export const campaignObjectiveOptions: SelectOption[] = [
    { value: 'Increase website traffic', label: 'Increase website traffic' },
    { value: 'Increase brand awareness', label: 'Increase brand awareness' },
    { value: 'Increase product awareness', label: 'Increase product awareness' },
    { value: 'Launch new product', label: 'Launch new product' },
    { value: 'Optimise product positioning', label: 'Optimise product positioning' },
    { value: 'Enter new markets', label: 'Enter new markets' },
    { value: 'Retain existing customers', label: 'Retain existing customers' },
    { value: 'Increase revenue', label: 'Increase revenue' },
    { value: 'Increase brand authority', label: 'Increase brand authority' },
];
