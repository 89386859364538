import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Image,
    ModalHeader,
    Text,
    Box,
    Card,
    CardHeader,
    CardBody,
    Badge,
    Flex,
    Spacer,
    Icon,
    Button,
    ModalFooter,
    Link,
    useDisclosure,
} from '@chakra-ui/react';
import { urls } from '@configs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
    DocumentPlusIcon,
    GlobeAsiaAustraliaIcon,
    TableCellsIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { AvailableCountries } from '@configs/availableCountries';
import { GetFullVersionModal } from '../getFullVersionModal/GetFullVersionModal';

export interface DynamicUpgradeModalProps {
    header: ReactJSXElement;
    isOpen: boolean;
    onClose: () => void;
}

const featureList = [
    'Increase your ROI of media spend by helping you invest in attention-effective inventory',
    'Tailor your media strategy towards targeted demographic, brand type and campaign objective',
    'Features include format recommendation, creative optimisation, media plan fine-tuning',
    'Utilise market leading attention metrics: Attention-adjusted® reach curve, attentive reach, attention retention, etc.',
    'Iterate media plan performance across 16 platforms and 62 formats',
    'And more!',
];

export const DynamicUpgradeModal: FC<DynamicUpgradeModalProps> = ({ header, isOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const {
        isOpen: isFullVersionModalOpen,
        onClose: onFullVersionModalClose,
        onOpen: onFullVersionModalOpen,
    } = useDisclosure();

    const handleOpenAllPlansModal = () => {
        onFullVersionModalOpen();
        onClose();
    };
    return (
        <>
            <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader margin="1rem">{header}</ModalHeader>
                    <ModalBody>
                        <Box marginRight="1rem" marginLeft="1rem">
                            <Flex gap="3rem">
                                <Flex flexDirection="column" gap="1rem">
                                    <Card
                                        width="14.25"
                                        height="18rem"
                                        borderColor="green.500"
                                        border="solid green.500"
                                    >
                                        <CardHeader marginBottom="-0.5rem">
                                            <Flex>
                                                <Text fontWeight="bold" fontSize="0.875rem">
                                                    Advanced
                                                </Text>
                                                <Spacer />
                                                <Badge
                                                    colorScheme="orange"
                                                    variant="solid"
                                                    textTransform="inherit"
                                                    fontSize="0.75rem"
                                                    rounded="0.3rem"
                                                >
                                                    Most popular
                                                </Badge>
                                            </Flex>
                                        </CardHeader>
                                        <CardBody>
                                            <Text fontWeight="bold" fontSize="0.875rem">
                                                $4000/month
                                            </Text>
                                            <Text
                                                color="gray.600"
                                                fontWeight="bold"
                                                marginTop="0.75rem"
                                                fontSize="0.75rem"
                                            >
                                                12 month subscription
                                            </Text>
                                            <Flex
                                                flexDirection="column"
                                                marginTop="2rem"
                                                gap="0.3rem"
                                                fontSize="0.75rem"
                                            >
                                                <Flex gap="0.5rem">
                                                    <Icon as={DocumentPlusIcon} boxSize="1.2rem" />
                                                    <Text fontSize="xs">
                                                        <strong>Unlimited</strong> campaigns
                                                    </Text>
                                                </Flex>
                                                <Flex gap="0.5rem">
                                                    <Icon as={UsersIcon} boxSize="1.2rem" />
                                                    <Text fontSize="xs">10 users</Text>
                                                </Flex>
                                                <Flex gap="0.5rem">
                                                    <Icon
                                                        as={GlobeAsiaAustraliaIcon}
                                                        boxSize="1.2rem"
                                                    />
                                                    <Text fontSize="xs">1 market</Text>
                                                </Flex>
                                                <Flex gap="0.5rem" position="relative">
                                                    <Icon as={TableCellsIcon} boxSize="1.2rem" />
                                                    <Text>Attention benchmarks</Text>
                                                    <Text
                                                        color="green"
                                                        position="absolute"
                                                        right="-1rem"
                                                        top="-.25rem"
                                                        fontSize=".625rem"
                                                    >
                                                        New
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </CardBody>
                                    </Card>
                                    <Button
                                        as={Link}
                                        href={urls.advancedPackageCheckoutPage}
                                        isExternal
                                        fontSize="0.75rem"
                                    >
                                        Upgrade to Advanced
                                    </Button>
                                </Flex>
                                <Box>
                                    <Text fontWeight="bold" fontSize="0.875rem">
                                        Also included in full-featured attentionPLAN®
                                    </Text>
                                    <Flex
                                        flexDirection="column"
                                        gap="0.5rem"
                                        marginTop="1rem"
                                        fontSize="0.75rem"
                                    >
                                        {featureList.map((feature, index) => (
                                            <Flex
                                                key={`flex_feature_${index}`}
                                                gap="0.5rem"
                                                alignItems="center"
                                            >
                                                <Icon
                                                    as={CheckCircleIcon}
                                                    fontSize="1.5rem"
                                                    color="green.600"
                                                />
                                                <Text>{feature}</Text>
                                            </Flex>
                                        ))}
                                        <Text
                                            fontSize="0.75rem"
                                            fontWeight="semibold"
                                            color="green.500"
                                            cursor="pointer"
                                            onClick={() => handleOpenAllPlansModal()}
                                            decoration="underline"
                                        >
                                            Compare all plans →
                                        </Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
            <GetFullVersionModal
                isOpen={isFullVersionModalOpen}
                onClose={onFullVersionModalClose}
            />
        </>
    );
};
