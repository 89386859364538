import { FC } from 'react';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Icon,
    Input,
    InputLeftAddon,
    Select,
    Spacer,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import {
    allRoleTitles,
    OrganisationResponseDto,
    UpdateUserRequestDto,
    UserResponseDto,
} from '@api-clients/account-manager';
import { getListRenderKey, isValidValueString, snakeCaseToCapitalised } from '@shared/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { allCountriesInfo, CountryInfo, findCountryPhoneCode } from '@shared/cores/types/Country';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputRenderListItem } from '@shared/cores/types';
import { useAppContext } from '@hooks/_contexts';
import { useUpdateUser } from '@hooks/users';
import { useUserFormContext } from '@components/organisms/userInformation';
import { useUpdatedToast } from '@hooks/toast';
import { EditableFormInput, ViewOnlyFormInput } from '@components/molecules';
import { TextRender } from '@components/molecules/detailSection/DetailSection';
import { ErrorAlert } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { useGetOrganisation } from '@hooks/organisations';

type UpdateUserRequestKeys = keyof UpdateUserRequestDto;

interface UserInfoInputListItem extends InputRenderListItem {
    inputName?: UpdateUserRequestKeys;
}

interface UserInfoEditModeProps {
    user: UserResponseDto;
    organisation: OrganisationResponseDto;
}

const getPhoneCode = (user: UserResponseDto, organisation: OrganisationResponseDto): string => {
    if (isValidValueString(user.countryPhoneCode)) {
        return user.countryPhoneCode!;
    }

    if (isValidValueString(user.phoneNumber) && isValidValueString(organisation.region)) {
        return findCountryPhoneCode(organisation.region!);
    }

    return '';
};

export const UserInformationEditMode: FC<UserInfoEditModeProps> = ({ user, organisation }) => {
    // Place these inside the component due to Storybook does not recognise the EditInput and ViewInput
    const EditInput = EditableFormInput<UpdateUserRequestDto, UserResponseDto>();
    const ViewInput = ViewOnlyFormInput<UserResponseDto>();
    const { t } = useTranslation();
    const { getOrganisation } = useGetOrganisation();
    const { appUser, appOrganisation } = useAppContext();
    const {
        setEditMode,
        errorUpdateUser,
        setErrorUpdateUser,
        updateErrorMessage,
        setUpdateErrorMessage,
    } = useUserFormContext();
    const { validationSchema, updateUser } = useUpdateUser();
    const { successUpdateUserToast } = useUpdatedToast();

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting, isValid },
    } = useForm<UpdateUserRequestDto>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur',
    });

    const onFormSave = async (data: UpdateUserRequestDto) => {
        if (!isValidValueString(data.countryPhoneCode) || !isValidValueString(data.phoneNumber)) {
            data.phoneNumber = null;
            data.countryPhoneCode = null;
        }

        try {
            const response = await updateUser(user.id!, data);
            appUser.setCurrentValue(response);

            // synchronise user full name to organisation's user list.
            const updatedOrg = await getOrganisation();
            appOrganisation.setCurrentValue(updatedOrg);

            successUpdateUserToast();
            setEditMode(false);
        } catch (e: any) {
            setErrorUpdateUser(true);
            setUpdateErrorMessage(e.message);
        }
    };

    const GroupInputLeftAddon = (
        <>
            <InputLeftAddon w="14rem">
                <Select
                    variant="unstyled"
                    defaultValue={getPhoneCode(user, organisation)}
                    {...register('countryPhoneCode')}
                >
                    {allCountriesInfo.map((option: CountryInfo, index) => (
                        <option
                            key={getListRenderKey(index, option.countryCode)}
                            value={option.phoneCode}
                            label={`${option.country} (+${option.phoneCode})`}
                        />
                    ))}
                </Select>
            </InputLeftAddon>
        </>
    );

    const inputRenderList: UserInfoInputListItem[] = [
        {
            componentType: 'text',
            mode: 'edit',
            label: t('labels.forms.accountDetails.firstName.field'),
            defaultValue: user.firstName,
            inputName: 'firstName',
            formRegister: register,
            errors,
        },
        {
            componentType: 'text',
            mode: 'edit',
            label: t('labels.forms.accountDetails.lastName.field'),
            defaultValue: user.lastName,
            inputName: 'lastName',
            formRegister: register,
            errors,
        },
        {
            componentType: 'select',
            mode: 'edit',
            label: t('labels.forms.accountDetails.roleTitle.field'),
            defaultValue: user.roleTitle,
            inputName: 'roleTitle',
            formRegister: register,
            errors,
            selectOptions: {
                optionList: allRoleTitles,
                optionLabelFormat: snakeCaseToCapitalised,
            },
        },
        {
            componentType: 'text',
            mode: 'view',
            label: t('labels.forms.accountDetails.email.field'),
            defaultValue: user.emailAddress,
            inputOptions: (
                <Center>
                    <Icon
                        as={CheckCircleIcon}
                        marginLeft="1rem"
                        w="1.25rem"
                        h="1.25rem"
                        color="green.600"
                        flex="1"
                    />
                </Center>
            ),
        },
        {
            componentType: 'group',
            mode: 'edit',
            label: t('labels.forms.accountDetails.phoneNumber.field'),
            defaultValue: user.phoneNumber,
            inputName: 'phoneNumber',
            formRegister: register,
            errors,
            groupOptions: {
                leftAddon: GroupInputLeftAddon,
                input: (
                    <Input
                        flex="1"
                        type="tel"
                        defaultValue={user.phoneNumber!}
                        {...register('phoneNumber')}
                    />
                ),
            },
        },
    ];

    return (
        <>
            {errorUpdateUser && <ErrorAlert errorMessage={updateErrorMessage} />}
            <Flex marginBottom="4">
                <Center>
                    <Heading size="md" color="gray.600">
                        {t('labels.sections.accountDetails.heading')}
                    </Heading>
                </Center>
                <Spacer />
                <Box>
                    <Button
                        size="sm"
                        onClick={handleSubmit(onFormSave)}
                        isLoading={isSubmitting}
                        disabled={!isValid}
                        type="submit"
                    >
                        {t('labels.sections.accountDetails.buttonName.editMode')}
                    </Button>
                </Box>
            </Flex>
            {user && organisation && (
                <Box bg="white" borderRadius="lg">
                    <form onSubmit={handleSubmit(onFormSave)}>
                        <VStack
                            divider={<StackDivider />}
                            align="stretch"
                            borderWidth="thin"
                            borderRadius="lg"
                            spacing="0"
                        >
                            {inputRenderList.map((item, index) => (
                                <>
                                    {item.mode === 'edit' && (
                                        <EditInput
                                            key={getListRenderKey(index, item.inputName)}
                                            componentType={item.componentType}
                                            formRegister={item.formRegister}
                                            formErrors={item.errors}
                                            inputName={item.inputName!}
                                            defaultValue={item.defaultValue}
                                            label={item.label}
                                            selectOptions={item.selectOptions}
                                            groupOptions={item.groupOptions}
                                        />
                                    )}
                                    {item.mode === 'view' && (
                                        <ViewInput
                                            key={getListRenderKey(index, item.inputName)}
                                            label={item.label}
                                            renderItem={
                                                <TextRender
                                                    text={item.defaultValue}
                                                    option={item.inputOptions}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            ))}
                        </VStack>
                    </form>
                </Box>
            )}
        </>
    );
};
