import { TableRowProps } from '@chakra-ui/react';
import { SortDirection } from '@shared/cores';
import { Column, Row } from './DataTable';
import { DataTableRow } from './DataTableRow';

export interface DataTableBodySectionProps<T> {
    dataSource: Array<Row<T>>;
    dataColumns: Array<Column<T>>;
    sortDirection?: SortDirection;
    sortedColumn?: Column<T>;
    displayedRowsRange?: { start: number; end: number };
    toggleSelectedRow?: (key: string) => void;
    isSelectable?: boolean;
    selectedRows: Set<string>;
    filter: string;
    onRow?: (row: Row<T>) => TableRowProps;
}

export const DataTableBodySection = <T,>({
    dataSource,
    dataColumns,
    sortDirection = SortDirection.Unsorted,
    sortedColumn,
    displayedRowsRange,
    toggleSelectedRow,
    isSelectable,
    selectedRows,
    filter,
    onRow,
}: DataTableBodySectionProps<T>) => {
    return (
        <>
            {dataSource
                .slice() // make a temp copy
                .sort(
                    // sort
                    (a, b) =>
                        sortDirection !== SortDirection.Unsorted && sortedColumn?.sorter
                            ? sortedColumn.sorter(a, b) * sortDirection
                            : 0,
                )
                .slice(
                    // paginate
                    displayedRowsRange?.start,
                    displayedRowsRange?.end,
                )
                .map(
                    // render rows
                    (row, i) => {
                        return (
                            <DataTableRow
                                filter={filter}
                                key={row.key}
                                row={row}
                                rowIndex={i}
                                columns={dataColumns}
                                sortDirection={sortDirection}
                                sortedColumn={sortedColumn}
                                toggleSelectedRow={toggleSelectedRow}
                                isSelectable={isSelectable}
                                isChecked={selectedRows.has(row.key)}
                                onRow={onRow}
                            />
                        );
                    },
                )}
        </>
    );
};
