import {
    Box,
    Button,
    Flex, HStack,
    Icon,
    Link,
    Radio,
    Spacer,
    Stack,
    Text
} from '@chakra-ui/react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

export interface CampaignTypeRadioProps<T> {
    value: T;
    heading: string;
    productName: string;
    description?: string;
    isChecked?: boolean;
    onClick?: (value: T) => void;
    onLearnMore?: () => void;
    onViewDemo?: () => void;
    colorScheme?: string;
    isDisabled?: boolean;
}

export const CampaignTypeRadio = <T,>({
    value,
    isChecked = false,
    onClick,
    heading,
    productName,
    description,
    colorScheme = 'orange',
    onLearnMore,
    onViewDemo,
    isDisabled,
}: CampaignTypeRadioProps<T>) => {
    let backgroundColor = 'white';
    if (isChecked) {
        backgroundColor = `${colorScheme}.50`;
    }
    if (isDisabled) {
        backgroundColor = 'gray.50';
    }

    return (
        <Box as="label">
            <Box
                cursor={!isDisabled ? 'pointer' : undefined}
                borderWidth="1px"
                borderRadius="md"
                px="1rem"
                py=".5rem"
                borderColor={isChecked ? `${colorScheme}.200` : 'gray.200'}
                backgroundColor={backgroundColor}
                onClick={(e) => {
                    if (!isDisabled && onClick) {
                        onClick(value);
                    }
                }}
            >
                <HStack>
                    <Radio
                        isDisabled={isDisabled}
                        colorScheme={colorScheme}
                        isChecked={isChecked}
                    />
                    <Stack width="100%">
                        <Flex direction="column">
                            <HStack>
                                <Text fontWeight="light" fontSize="xs">
                                    {productName}
                                </Text>
                                <Spacer />
                                {isDisabled && <Icon color="gray.400" as={LockClosedIcon} />}
                            </HStack>
                            <Text fontWeight="medium">{heading}</Text>
                            <Text fontWeight="normal" fontSize="sm" userSelect="none">
                                {description}
                            </Text>
                        </Flex>
                        {isDisabled && (
                            <HStack>
                                <Spacer />
                                {onLearnMore && (
                                    <Button onClick={onLearnMore} size="sm" variant="link">
                                        Learn more
                                    </Button>
                                )}
                                {onViewDemo && (
                                    <Button
                                        as={Link}
                                        onClick={onViewDemo}
                                        size="sm"
                                        variant="outline"
                                    >
                                        View demo
                                    </Button>
                                )}
                            </HStack>
                        )}
                    </Stack>
                </HStack>
            </Box>
        </Box>
    );
};
