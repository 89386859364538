/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdFormatResponseDto } from './AdFormatResponseDto';
import {
    AdFormatResponseDtoFromJSON,
    AdFormatResponseDtoFromJSONTyped,
    AdFormatResponseDtoToJSON,
} from './AdFormatResponseDto';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';

/**
 *
 * @export
 * @interface FormatLevelResultComparisonResponseDto
 */
export interface FormatLevelResultComparisonResponseDto {
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    mix?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    budget?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    cpm?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    impression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    reach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    passiveAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {string}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    channelLevelResultId?: string;
    /**
     *
     * @type {AdFormatResponseDto}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    adFormat?: AdFormatResponseDto;
    /**
     *
     * @type {string}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof FormatLevelResultComparisonResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the FormatLevelResultComparisonResponseDto interface.
 */
export function instanceOfFormatLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatLevelResultComparisonResponseDtoFromJSON(
    json: any,
): FormatLevelResultComparisonResponseDto {
    return FormatLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function FormatLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mix: !exists(json, 'mix') ? undefined : DecimalComparisonValueFromJSON(json['mix']),
        budget: !exists(json, 'budget')
            ? undefined
            : DecimalComparisonValueFromJSON(json['budget']),
        cpm: !exists(json, 'cpm') ? undefined : DecimalComparisonValueFromJSON(json['cpm']),
        impression: !exists(json, 'impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impression']),
        reach: !exists(json, 'reach') ? undefined : DecimalComparisonValueFromJSON(json['reach']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_volume_seconds']),
        channelLevelResultId: !exists(json, 'channel_level_result_id')
            ? undefined
            : json['channel_level_result_id'],
        adFormat: !exists(json, 'ad_format')
            ? undefined
            : AdFormatResponseDtoFromJSON(json['ad_format']),
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function FormatLevelResultComparisonResponseDtoToJSON(
    value?: FormatLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mix: DecimalComparisonValueToJSON(value.mix),
        budget: DecimalComparisonValueToJSON(value.budget),
        cpm: DecimalComparisonValueToJSON(value.cpm),
        impression: DecimalComparisonValueToJSON(value.impression),
        reach: DecimalComparisonValueToJSON(value.reach),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        passive_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.passiveAttentionVolumeSeconds,
        ),
        channel_level_result_id: value.channelLevelResultId,
        ad_format: AdFormatResponseDtoToJSON(value.adFormat),
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
