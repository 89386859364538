import { FC, ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

export const Auth0ContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN;
    const clientId = import.meta.env.VITE_APP_AUTH0_SPA_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_APP_AUTH0_SPA_CALLBACK_URL;
    const audience = import.meta.env.VITE_APP_AUTH0_A360_API_AUDIENCE;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience,
            }}
            cacheLocation="localstorage" // enable when debugging
            useRefreshTokens
        >
            {children}
        </Auth0Provider>
    );
};
