import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/toast/useCustomToast';

interface useAuthToastHookProps {
    successChangePasswordToast: () => void;
    errorChangePasswordToast: () => void;
}

type useAuthToastHook = () => useAuthToastHookProps;

export const useAuthToast: useAuthToastHook = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successChangePasswordToast() {
        const title = t('messages.toast.auth.changePassword.success.title');
        const description = t('messages.toast.auth.changePassword.success.description');

        successToast(title, description);
    }

    function errorChangePasswordToast() {
        const title = t('messages.toast.auth.changePassword.error.title');
        const description = t('messages.toast.auth.changePassword.error.description');

        errorToast(title, description);
    }

    return {
        successChangePasswordToast,
        errorChangePasswordToast,
    };
};
