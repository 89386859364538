import {
    AccessLevelType,
    UserResponseDto,
    UserWithAccessLevelDto,
    allAccessLevelTypes,
} from '@api-clients/account-manager';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    ModalFooter,
    Button,
    HStack,
    Link,
    Select,
    useBoolean,
} from '@chakra-ui/react';
import { urls } from '@configs';
import { useAppContextHelper } from '@hooks/_contexts';
import { useUpdateUserRole } from '@hooks/accountManager/useUpdateUserRole';
import { useCustomToast } from '@hooks/toast';
import { toCapitalised, useHelper } from '@shared/utils';
import { FC, useEffect, useState } from 'react';

export interface UpdateUserRoleModalProps {
    selectedUser: UserWithAccessLevelDto;
    currentUser: UserResponseDto;
    isOpen: boolean;
    onClose: () => void;
    getOrganisation?: () => void;
}

export const UpdateUserRoleModal: FC<UpdateUserRoleModalProps> = ({
    selectedUser,
    currentUser,
    isOpen,
    onClose,
    getOrganisation,
}) => {
    const { formatStringToCapitalized } = useHelper();
    const { successToast, errorToast } = useCustomToast();

    const { updateUserRole } = useUpdateUserRole();
    const [userRole, setUserRole] = useState<AccessLevelType>();
    const [isLoading, setIsLoading] = useBoolean();

    useEffect(() => {
        setUserRole(selectedUser.accessLevel);
    }, [selectedUser.accessLevel]);

    const handleUpdateUserRole = () => {
        setIsLoading.on();
        updateUserRole(selectedUser.id!, userRole as AccessLevelType)
            .then(() => {
                successToast('Success', 'User role successfully updated');
            })
            .catch(() => {
                errorToast('Error', 'Something went wrong updating user role');
            })
            .finally(() => {
                if (getOrganisation) getOrganisation();
                onClose();
                setIsLoading.off();
            });
    };

    const administratorOptions: AccessLevelType[] = ['administrator', 'member'];

    return (
        <Modal size="lg" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Update member</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text size="1.5rem" fontWeight={500}>
                        Full name
                    </Text>
                    <Text size="1.5rem" my="0.5rem">
                        {`${selectedUser.firstName} ${selectedUser.lastName}`}
                    </Text>
                    <Text size="1.5rem" fontWeight={500}>
                        Email
                    </Text>
                    <Text size="1.5rem" my="0.5rem">
                        {selectedUser.emailAddress}
                    </Text>
                    <Text size="1.5rem" fontWeight={500}>
                        Access level
                    </Text>
                    <Select
                        value={userRole}
                        onChange={(e) => setUserRole(e.target.value as AccessLevelType)}
                        mt={2}
                    >
                        {currentUser.organisations?.at(0)?.accessLevel === 'owner'
                            ? allAccessLevelTypes.map((access) => (
                                  <option value={access}>
                                      {formatStringToCapitalized(access)}
                                  </option>
                              ))
                            : administratorOptions.map((access) => (
                                  <option value={access}>
                                      {formatStringToCapitalized(access)}
                                  </option>
                              ))}
                    </Select>
                </ModalBody>
                <ModalFooter>
                    <HStack gap="0.5rem">
                        <Button
                            variant="unstyled"
                            onClick={onClose}
                            backgroundColor="gray.100"
                            paddingLeft="1rem"
                            paddingRight="1rem"
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={isLoading}
                            variant="solid"
                            as={Button}
                            onClick={handleUpdateUserRole}
                        >
                            Save
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
