import type { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Checkbox,
    Flex,
    FormLabel,
    Icon,
    Switch,
    Table,
    Tbody,
    Td,
    Tr,
    Tooltip,
} from '@chakra-ui/react';
import { useHelper } from '@shared/utils';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import type { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import EditableTableCell from './EditableTableCell';
import SubTableHeader from './SubTableHeader';
import SubTableRow from './SubTableRow';
import TableDisplayCell from './TableDisplayCell';

type TableRowProps = {
    row: TableDataType;
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
    rowIndex: number;
    setSubTableHasError?: Dispatch<SetStateAction<TableError[]>>;
    subTableHasError?: TableError[];
    hasSubmitted: boolean;
    removeInvalidTableError?: () => void;
};
const TableRow: FC<TableRowProps> = ({
    row,
    tableData,
    setTableData,
    rowIndex,
    setSubTableHasError = () => {},
    subTableHasError = [],
    hasSubmitted,
    removeInvalidTableError = () => {},
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDefaultFormatMix, setIsDefaultFormatMix] = useState(true);
    const { formatNumber } = useHelper();
    const toggleCheckbox = () => {
        removeInvalidTableError();
        setTableData((old) =>
            old.map((r, index) => {
                if (index !== rowIndex) {
                    return r;
                }
                if (r.isChecked) {
                    // remove all errors from this channel
                    setSubTableHasError((errors) =>
                        errors.filter(
                            (err) => err.channelName.toLowerCase() !== r.channelName?.toLowerCase(),
                        ),
                    );
                    return {
                        ...r,
                        adFormats: r.adFormats?.map((f) => ({ ...f, isChecked: false })),
                        isChecked: false,
                    };
                }
                const numberOfAdFormats = r.adFormats?.length ?? 0;
                if (numberOfAdFormats) {
                    return {
                        ...r,
                        isChecked: true,
                        adFormats: r.adFormats?.map((f) => ({
                            ...f,
                            isChecked: true,
                            budget: (r.budget ?? 0) / numberOfAdFormats,
                        })),
                    };
                }
                // if number of ad formats is zero, then something went wrong
                return {
                    ...r,
                    adFormats: r.adFormats?.map((f) => ({ ...f, isChecked: true })),
                    isChecked: true,
                };
            }),
        );
    };
    // if all the ad formats are checked for this ad channel, then display a check
    const isRowChecked = row.adFormats?.every((format) => format.isChecked);
    // if some of the ad formats are checked, but not all of them, then display an indeterminate check
    const isRowPartiallyChecked =
        row.adFormats?.some((format) => format.isChecked) && !isRowChecked;
    const tableErrorForThisRow = subTableHasError.filter(
        (error) => error.channelName === row.channelName,
    );
    return (
        <>
            <Tr
                lineHeight="1.25rem"
                key={row.channelName}
                background={row.isChecked ? undefined : 'gray.50'}
                opacity={row.isChecked ? '1' : '0.8'}
                color={row.isChecked ? undefined : 'gray.600'}
                borderBottom="1px solid"
                borderColor="gray.100"
            >
                <Td borderBottom="0px">
                    <Checkbox
                        isChecked={isRowChecked}
                        isIndeterminate={isRowPartiallyChecked}
                        onChange={() => toggleCheckbox()}
                    />
                </Td>
                <Td
                    borderBottom="0px"
                    onClick={() => {
                        setIsExpanded((old) => !old);
                    }}
                    minWidth="160px"
                    verticalAlign="center"
                    fontWeight={600}
                    fontSize="0.875rem"
                    _hover={{ cursor: 'pointer' }}
                    transition="background-color 300ms ease"
                >
                    <Flex alignItems="center" gap="0.125rem">
                        {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                        {row.channelName}
                        {tableErrorForThisRow.length > 0 && (
                            <Tooltip label="Invalid input in formats">
                                <Icon boxSize="1.5em" marginLeft="auto" color="red.600">
                                    <ExclamationCircleIcon strokeWidth="2px" />
                                </Icon>
                            </Tooltip>
                        )}
                    </Flex>
                </Td>
                <EditableTableCell
                    setSubTableHasError={setSubTableHasError}
                    setTableData={setTableData}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="budget"
                    value={row.budget ?? 0}
                    validate={(val: number) => val > 0}
                    disabled={!isDefaultFormatMix}
                    currencyInput
                    hasSubmitted={hasSubmitted}
                    removeInvalidTableError={removeInvalidTableError}
                />
                <EditableTableCell
                    setSubTableHasError={setSubTableHasError}
                    setTableData={setTableData}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="cpm"
                    value={row.cpm ?? 0}
                    validate={(val: number) => val > 0}
                    currencyInput
                    disabled={!isDefaultFormatMix}
                    hasSubmitted={hasSubmitted}
                    removeInvalidTableError={removeInvalidTableError}
                />
                <EditableTableCell
                    setSubTableHasError={setSubTableHasError}
                    setTableData={setTableData}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="frequency"
                    value={row.frequency ?? 0}
                    validate={(val: number) => val > 1}
                    hasSubmitted={hasSubmitted}
                    removeInvalidTableError={removeInvalidTableError}
                />
                <EditableTableCell
                    setSubTableHasError={setSubTableHasError}
                    setTableData={setTableData}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="totalAudience"
                    value={row.totalAudience ?? 0}
                    validate={(val: number) => val > 0}
                    hasSubmitted={hasSubmitted}
                    removeInvalidTableError={removeInvalidTableError}
                />
                <TableDisplayCell
                    value={row.impressions ?? 0}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="impressions"
                />
                <TableDisplayCell
                    value={row.targetAudience ?? 0}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="targetAudience"
                />
                <TableDisplayCell
                    value={row.reachPercentage ?? 0}
                    isPercentage
                    validate={(val) => val <= 1 && val >= 0}
                    setSubTableHasError={setSubTableHasError}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="reachPercentage"
                />
                <TableDisplayCell
                    value={row.trp ?? 0}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="trp"
                />
                <TableDisplayCell
                    value={row.cpp ?? 0}
                    tableData={tableData}
                    rowIndex={rowIndex}
                    accessorKey="cpp"
                />
            </Tr>
            {isExpanded && (
                <Tr borderBottom="1px solid" borderColor="gray.100">
                    <Td colSpan={11} padding="2rem" borderBottom="0px">
                        <Flex direction="column" gap="1rem">
                            <Box>
                                <Flex gap="1rem" alignItems="center">
                                    <Tooltip
                                        label="Enable to edit the budget and cpm of individual formats"
                                        placement="top"
                                        hasArrow
                                    >
                                        <FormLabel
                                            marginBottom={0}
                                            fontSize="0.875rem"
                                            color="gray.600"
                                            lineHeight="1.25rem"
                                        >
                                            Use custom format mix
                                        </FormLabel>
                                    </Tooltip>
                                    <Switch
                                        isChecked={!isDefaultFormatMix}
                                        onChange={() => {
                                            setIsDefaultFormatMix((old) => !old);
                                        }}
                                    />
                                </Flex>
                            </Box>
                            <Table display="block">
                                <SubTableHeader />
                                <Tbody>
                                    {row.adFormats?.map((f) => (
                                        <SubTableRow
                                            key={f.adFormatCode}
                                            setTableData={setTableData}
                                            tableData={tableData}
                                            rowIndex={rowIndex}
                                            adFormat={f}
                                            isDefaultFormatMix={isDefaultFormatMix}
                                            setSubTableHasError={setSubTableHasError}
                                            removeInvalidTableError={removeInvalidTableError}
                                        />
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                    </Td>
                </Tr>
            )}
        </>
    );
};
export default TableRow;
