import {Configuration} from "@api-clients/runtime";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";

export class FindGlobalCampaignsApi {

    static async execute(
        configuration: Configuration
    ): Promise<Array<GlobalCampaign>> {
        const response = await fetch(`${configuration.basePath}/a360/v1/campaigns`, {
            method: 'GET',
            headers: configuration.headers,
        });

        return response.json();
    }

}
