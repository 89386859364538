/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdChannelResponseDto } from './AdChannelResponseDto';
import {
    AdChannelResponseDtoFromJSON,
    AdChannelResponseDtoFromJSONTyped,
    AdChannelResponseDtoToJSON,
} from './AdChannelResponseDto';
import type { FormatLevelResultResponseDto } from './FormatLevelResultResponseDto';
import {
    FormatLevelResultResponseDtoFromJSON,
    FormatLevelResultResponseDtoFromJSONTyped,
    FormatLevelResultResponseDtoToJSON,
} from './FormatLevelResultResponseDto';

/**
 *
 * @export
 * @interface ChannelLevelResultResponseDto
 */
export interface ChannelLevelResultResponseDto {
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    mix?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    budget?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    cpm?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    reachPercentage?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    impression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    frequency?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    totalAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    targetAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    reach?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    trp?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    cpp?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    activeAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    activeAttentionPerImpression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    activeAttentionPerReach?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    passiveAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    passiveAttentionPerImpression?: number | null;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    passiveAttentionPerReach?: number | null;
    /**
     *
     * @type {Array<FormatLevelResultResponseDto>}
     * @memberof ChannelLevelResultResponseDto
     */
    formatLevelResults?: Array<FormatLevelResultResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ChannelLevelResultResponseDto
     */
    scenarioLevelResultId?: string;
    /**
     *
     * @type {AdChannelResponseDto}
     * @memberof ChannelLevelResultResponseDto
     */
    adChannel?: AdChannelResponseDto;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultResponseDto
     */
    iota?: number | null;
    /**
     *
     * @type {string}
     * @memberof ChannelLevelResultResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ChannelLevelResultResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ChannelLevelResultResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ChannelLevelResultResponseDto interface.
 */
export function instanceOfChannelLevelResultResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelLevelResultResponseDtoFromJSON(json: any): ChannelLevelResultResponseDto {
    return ChannelLevelResultResponseDtoFromJSONTyped(json, false);
}

export function ChannelLevelResultResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelLevelResultResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mix: !exists(json, 'mix') ? undefined : json['mix'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        reachPercentage: !exists(json, 'reach_percentage') ? undefined : json['reach_percentage'],
        impression: !exists(json, 'impression') ? undefined : json['impression'],
        frequency: !exists(json, 'frequency') ? undefined : json['frequency'],
        totalAudience: !exists(json, 'total_audience') ? undefined : json['total_audience'],
        targetAudience: !exists(json, 'target_audience') ? undefined : json['target_audience'],
        reach: !exists(json, 'reach') ? undefined : json['reach'],
        trp: !exists(json, 'trp') ? undefined : json['trp'],
        cpp: !exists(json, 'cpp') ? undefined : json['cpp'],
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : json['active_attention_volume_seconds'],
        activeAttentionPerImpression: !exists(json, 'active_attention_per_impression')
            ? undefined
            : json['active_attention_per_impression'],
        activeAttentionPerReach: !exists(json, 'active_attention_per_reach')
            ? undefined
            : json['active_attention_per_reach'],
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : json['passive_attention_volume_seconds'],
        passiveAttentionPerImpression: !exists(json, 'passive_attention_per_impression')
            ? undefined
            : json['passive_attention_per_impression'],
        passiveAttentionPerReach: !exists(json, 'passive_attention_per_reach')
            ? undefined
            : json['passive_attention_per_reach'],
        formatLevelResults: !exists(json, 'format_level_results')
            ? undefined
            : json['format_level_results'] === null
            ? null
            : (json['format_level_results'] as Array<any>).map(
                  FormatLevelResultResponseDtoFromJSON,
              ),
        scenarioLevelResultId: !exists(json, 'scenario_level_result_id')
            ? undefined
            : json['scenario_level_result_id'],
        adChannel: !exists(json, 'ad_channel')
            ? undefined
            : AdChannelResponseDtoFromJSON(json['ad_channel']),
        iota: !exists(json, 'iota') ? undefined : json['iota'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ChannelLevelResultResponseDtoToJSON(
    value?: ChannelLevelResultResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mix: value.mix,
        budget: value.budget,
        cpm: value.cpm,
        reach_percentage: value.reachPercentage,
        impression: value.impression,
        frequency: value.frequency,
        total_audience: value.totalAudience,
        target_audience: value.targetAudience,
        reach: value.reach,
        trp: value.trp,
        cpp: value.cpp,
        active_attention_volume_seconds: value.activeAttentionVolumeSeconds,
        active_attention_per_impression: value.activeAttentionPerImpression,
        active_attention_per_reach: value.activeAttentionPerReach,
        passive_attention_volume_seconds: value.passiveAttentionVolumeSeconds,
        passive_attention_per_impression: value.passiveAttentionPerImpression,
        passive_attention_per_reach: value.passiveAttentionPerReach,
        format_level_results:
            value.formatLevelResults === undefined
                ? undefined
                : value.formatLevelResults === null
                ? null
                : (value.formatLevelResults as Array<any>).map(FormatLevelResultResponseDtoToJSON),
        scenario_level_result_id: value.scenarioLevelResultId,
        ad_channel: AdChannelResponseDtoToJSON(value.adChannel),
        iota: value.iota,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
