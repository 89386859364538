import {
    AccountDetailsPage,
    AttentionBenchmarksPage,
    ManageApplicationPage,
    ManageClientPage,
    OrganisationDetailsPage,
    PageNotFoundPage,
    ProgressiveSignUpPage,
    ShareScenarioPage,
    StrategyReportPage,
} from '@apps/attention360/pages';
import { BaseLayout, NavBarLayout } from '@components/templates/appsLayout';
import { routing } from '@configs';
import { ProtectedRoutes } from '@routes/protectedRoutes';
import { Outlet, RouteObject } from 'react-router-dom';
import { ErrorBoundary } from '@components/errorHandler/ErrorBoundary';
import {
    AttentionPlanAppProvider,
    Auth0ContextProvider,
    AuthenticatedWrapper,
} from '@hooks/_contexts';
import { ForbiddenPage } from '@components/pages';
import { CanAccessMediaPlanning } from '@hooks/_contexts/app/CanAccessMediaPlanning';
import { SubscriptionValidationRedirect } from '@hooks/_contexts/app/SubscriptionValidationRedirect';
import { ShareScenarioLayout } from '@components/templates/shareScenario';
import { SignInErrorCard } from '@components/molecules/errorCard/SignInErrorCard';
import { StrategyReportCreationPage } from '@apps/attention360/pages/strategyReports/StrategyReportCreationPage';
import {
    CampaignRoutes,
    MediaPlanningRoutes,
    ProveRoutes,
    StrategyReportRoutes,
} from './subRoutes';

export const AppRoutes: Array<RouteObject> = [
    {
        path: routing.root.url,
        element: (
            <Auth0ContextProvider>
                <BaseLayout />
            </Auth0ContextProvider>
        ),
        errorElement: (
            <Auth0ContextProvider>
                <ErrorBoundary />
            </Auth0ContextProvider>
        ),
        children: [
            {
                // Note: app context is wrapped in 'AttentionPlanAppProvider'
                path: routing.progressiveSignUp.url,
                element: (
                    <AttentionPlanAppProvider>
                        <ProgressiveSignUpPage />
                    </AttentionPlanAppProvider>
                ),
            },
            {
                path: routing.share.url,
                children: [
                    {
                        index: true,
                        path: routing.share.scenario.url,
                    },
                    {
                        path: `${routing.share.scenario.url}/:scenarioShareLinkId`,
                        element: (
                            <ShareScenarioLayout>
                                <ShareScenarioPage />
                            </ShareScenarioLayout>
                        ),
                    },
                ],
            },
            {
                element: <ProtectedRoutes />,
                children: [
                    {
                        element: (
                            <AuthenticatedWrapper>
                                <NavBarLayout />
                            </AuthenticatedWrapper>
                        ),
                        children: [
                            {
                                index: true,
                                element: <SubscriptionValidationRedirect />,
                            },
                            {
                                element: <Outlet />,
                                children: CampaignRoutes,
                            },
                            {
                                element: <Outlet />,
                                children: StrategyReportRoutes,
                            },
                            {
                                element: (
                                    <CanAccessMediaPlanning>
                                        <Outlet />
                                    </CanAccessMediaPlanning>
                                ),
                                children: MediaPlanningRoutes,
                            },
                            {
                                element: <Outlet />,
                                children: ProveRoutes,
                            },
                            {
                                path: routing.benchmarks.url,
                                element: <AttentionBenchmarksPage />,
                            },
                            {
                                path: routing.accountDetails.url,
                                children: [
                                    {
                                        index: true,
                                        element: <AccountDetailsPage />,
                                    },
                                ],
                            },
                            {
                                path: routing.manageOrganisation.url,
                                element: <OrganisationDetailsPage />,
                            },
                            {
                                path: routing.manageClients.url,
                                element: <ManageClientPage />,
                            },
                            {
                                path: routing.manageApplications.url,
                                element: <ManageApplicationPage />,
                            },
                            {
                                path: routing.root.forbidden.url,
                                element: <ForbiddenPage />,
                            },
                            {
                                path: '*',
                                element: <PageNotFoundPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: routing.root.signInError.url,
                element: <SignInErrorCard />,
            },
            {
                path: routing.root.error.url,
                element: <ErrorBoundary />,
            },
        ],
    },
];
