/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';
import type { ReachCurveResponseDto } from './ReachCurveResponseDto';
import {
    ReachCurveResponseDtoFromJSON,
    ReachCurveResponseDtoFromJSONTyped,
    ReachCurveResponseDtoToJSON,
} from './ReachCurveResponseDto';

/**
 *
 * @export
 * @interface ScenarioLevelCurvesResponseDto
 */
export interface ScenarioLevelCurvesResponseDto {
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelCurvesResponseDto
     */
    scenarioId?: string;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof ScenarioLevelCurvesResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelCurvesResponseDto
     */
    scenarioName?: string | null;
    /**
     *
     * @type {Array<ReachCurveResponseDto>}
     * @memberof ScenarioLevelCurvesResponseDto
     */
    reachCurves?: Array<ReachCurveResponseDto> | null;
}

/**
 * Check if a given object implements the ScenarioLevelCurvesResponseDto interface.
 */
export function instanceOfScenarioLevelCurvesResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioLevelCurvesResponseDtoFromJSON(json: any): ScenarioLevelCurvesResponseDto {
    return ScenarioLevelCurvesResponseDtoFromJSONTyped(json, false);
}

export function ScenarioLevelCurvesResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioLevelCurvesResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        scenarioId: !exists(json, 'scenario_id') ? undefined : json['scenario_id'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        reachCurves: !exists(json, 'reach_curves')
            ? undefined
            : json['reach_curves'] === null
            ? null
            : (json['reach_curves'] as Array<any>).map(ReachCurveResponseDtoFromJSON),
    };
}

export function ScenarioLevelCurvesResponseDtoToJSON(
    value?: ScenarioLevelCurvesResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        scenario_id: value.scenarioId,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        scenario_name: value.scenarioName,
        reach_curves:
            value.reachCurves === undefined
                ? undefined
                : value.reachCurves === null
                ? null
                : (value.reachCurves as Array<any>).map(ReachCurveResponseDtoToJSON),
    };
}
