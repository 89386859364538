import { FormatDecayResponseDto } from '@api-clients/attention-data';
import {
    GetFormatDecayForAllFormatsRequest,
    StrategyReportsApi
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetFormatDecayForAllFormatsProps {
    getFormatDecayForAllFormats: (
        request: GetFormatDecayForAllFormatsRequest,
    ) => Promise<Array<FormatDecayResponseDto>>;
}

type useGetFormatDecayForAllFormatsHook = () => useGetFormatDecayForAllFormatsProps;

export const useGetFormatDecayForAllFormats: useGetFormatDecayForAllFormatsHook  = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getFormatDecayForAllFormats(
        request: GetFormatDecayForAllFormatsRequest,
    ): Promise<Array<FormatDecayResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getFormatDecayForAllFormats(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getFormatDecayForAllFormats,
    };
};
