/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FormatSegmentResponseDto } from './FormatSegmentResponseDto';
import {
    FormatSegmentResponseDtoFromJSON,
    FormatSegmentResponseDtoFromJSONTyped,
    FormatSegmentResponseDtoToJSON,
} from './FormatSegmentResponseDto';

/**
 *
 * @export
 * @interface ChannelSegmentResponseDto
 */
export interface ChannelSegmentResponseDto {
    /**
     *
     * @type {Array<FormatSegmentResponseDto>}
     * @memberof ChannelSegmentResponseDto
     */
    formatSegmentResponseDto?: Array<FormatSegmentResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ChannelSegmentResponseDto
     */
    adChannelId?: string;
    /**
     *
     * @type {string}
     * @memberof ChannelSegmentResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof ChannelSegmentResponseDto
     */
    adChannelName?: string | null;
}

/**
 * Check if a given object implements the ChannelSegmentResponseDto interface.
 */
export function instanceOfChannelSegmentResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelSegmentResponseDtoFromJSON(json: any): ChannelSegmentResponseDto {
    return ChannelSegmentResponseDtoFromJSONTyped(json, false);
}

export function ChannelSegmentResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelSegmentResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        formatSegmentResponseDto: !exists(json, 'format_segment_response_dto')
            ? undefined
            : json['format_segment_response_dto'] === null
            ? null
            : (json['format_segment_response_dto'] as Array<any>).map(
                  FormatSegmentResponseDtoFromJSON,
              ),
        adChannelId: !exists(json, 'ad_channel_id') ? undefined : json['ad_channel_id'],
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
    };
}

export function ChannelSegmentResponseDtoToJSON(value?: ChannelSegmentResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        format_segment_response_dto:
            value.formatSegmentResponseDto === undefined
                ? undefined
                : value.formatSegmentResponseDto === null
                ? null
                : (value.formatSegmentResponseDto as Array<any>).map(
                      FormatSegmentResponseDtoToJSON,
                  ),
        ad_channel_id: value.adChannelId,
        ad_channel_code: value.adChannelCode,
        ad_channel_name: value.adChannelName,
    };
}
