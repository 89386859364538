import {
    StrategyReportResponseDto,
    StrategyReportsApi,
    UpdateTheAdFormatsForStrategyReportRequest,
} from '@api-clients/attention-data';
import { UpdateAdFormatsRequestDto } from '@api-clients/attention-data/models/UpdateAdFormatsRequestDto';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useUpdateAdFormatsProps {
    updateAdFormats: (
        strategyReportId: string,
        updateAdFormatsRequestDto: UpdateAdFormatsRequestDto,
    ) => Promise<StrategyReportResponseDto>;
}

type useUpdateAdFormatsHook = () => useUpdateAdFormatsProps;

export const useUpdateAdFormats: useUpdateAdFormatsHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function updateAdFormats(
        strategyReportId: string,
        updateAdFormatsRequestDto: UpdateAdFormatsRequestDto,
    ): Promise<StrategyReportResponseDto> {
        try {
            const config = await getAttentionDataApiConfig();
            const request: UpdateTheAdFormatsForStrategyReportRequest = {
                strategyReportId,
                updateAdFormatsRequestDto,
            };

            const api = new StrategyReportsApi(config);

            return await api.updateTheAdFormatsForStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        updateAdFormats,
    };
};
