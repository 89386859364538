import {
    CampaignApi,
    CampaignResponseDto,
    CreateADraftCampaignRequest,
    CreateDraftCampaignRequestDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';

import { useHelper } from '@shared/utils';

interface CreateDraftCampaignHook {
    createDraftCampaign: (
        createDraftCampaignRequestDto: CreateDraftCampaignRequestDto,
    ) => Promise<CampaignResponseDto>;
}

type useCreateDraftCampaignProps = () => CreateDraftCampaignHook;

export const useCreateDraftCampaign: useCreateDraftCampaignProps = () => {
    const { t } = useTranslation();

    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function createDraftCampaign(
        createDraftCampaignRequestDto: CreateDraftCampaignRequestDto,
    ): Promise<CampaignResponseDto> {
        try {
            const config = await getMediaPlanConfig();
            const request: CreateADraftCampaignRequest = {
                createDraftCampaignRequestDto,
            };

            const api = new CampaignApi(config);
            return await api.createADraftCampaign(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        createDraftCampaign,
    };
};
