import {
    Card,
    CardBody,
    Center,
    Container,
    Heading,
    Icon,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { externalUrls } from '@configs/externalUrls';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export const ErrorCard: FC = () => (
    <Container paddingTop="10vh">
        <Card width="45rem">
            <CardBody>
                <VStack>
                    <Center>
                        <Heading>An error has occurred</Heading>
                    </Center>
                    <Center>
                        <Text>
                            Whoops! Something went wrong there, please try again. If you continue to
                            see this error reach out at{' '}
                            <Link
                                textDecoration="underline"
                                href={externalUrls.customerSupportEmailUrl}
                            >
                                {externalUrls.customerSupportEmail}
                            </Link>
                            .
                        </Text>
                    </Center>
                </VStack>
            </CardBody>
        </Card>
    </Container>
);
