import { ComponentStyleConfig } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';

export const Button: ComponentStyleConfig = {
    baseStyle: {
        whiteSpace: 'normal',
    },
    variants: {
        navContentLink: (props: StyleFunctionProps) => {
            const c = props.colorScheme;
            return {
                color: 'gray.600',
                borderRadius: 'md',
                fontWeight: 'bold',
                overflow: 'hidden',
                width: '100%',
                justifyContent: 'flex-start',
                _activeLink: {
                    color: `${c}.500`,
                    bg: `${c}.50`,
                },
                _hover: {
                    bg: 'gray.50',
                },
            };
        },
    },
};
