import { Icon } from '@chakra-ui/icons';
import { Center, MenuItem, MenuItemProps } from '@chakra-ui/react';

import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface NavBarMenuListItemProps extends Omit<MenuItemProps, 'icon'> {
    href?: string;
    path?: string;
    icon: any;
    onClick?: () => void;
    shouldDisplay?: boolean;
}

export const NavBarMenuListItem: FC<NavBarMenuListItemProps> = ({
    shouldDisplay = true,
    ...props
}) => (
    <>
        {shouldDisplay && (
            <MenuItem
                {...props}
                as={Link}
                to={props.href ?? ''}
                icon={
                    <Center>
                        <Icon as={props.icon} boxSize="1.5rem" />
                    </Center>
                }
            >
                {props.children}
            </MenuItem>
        )}
    </>
);
