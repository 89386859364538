import { FC, useCallback, useMemo } from 'react';
import { CreateOauthApplicationResponseDto } from '@api-clients/account-manager';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Card,
    CardBody,
    Center,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    VStack,
} from '@chakra-ui/react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface InternalProps {
    oauthApplication: CreateOauthApplicationResponseDto;
}

export const DisplayClientSecret: FC<InternalProps> = ({ oauthApplication }) => {
    const { t } = useTranslation();
    const secretText = useMemo(
        () => t('pages.applicationManagement.secrets', { returnObjects: true }),
        [],
    );

    const handleCopy = useCallback(async (text: string) => {
        await navigator.clipboard.writeText(text);
    }, []);

    const FILE_NAME = useMemo(
        () => `oauth-application-${oauthApplication.applicationName}`,
        [oauthApplication],
    );

    const textFileContent = useMemo(
        () =>
            `[client_id]\n${oauthApplication.clientId}\n\n[client_secret]\n${oauthApplication.clientSecret}`,
        [oauthApplication],
    );

    const downloadTxtFile = useCallback(() => {
        const blob = new Blob([textFileContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = FILE_NAME;
        a.click();
    }, [textFileContent]);

    return (
        <Center>
            <Card width="50rem">
                <CardBody>
                    <VStack spacing="2rem" align="flex-start">
                        <Alert
                            status="info"
                            bgColor="blue.50"
                            flexDirection={{ base: 'column', md: 'row' }}
                        >
                            <VStack align="left">
                                <Flex>
                                    <AlertIcon />
                                    <AlertTitle>{secretText.alert.title}</AlertTitle>
                                </Flex>
                                <AlertDescription>{secretText.alert.description}</AlertDescription>
                            </VStack>
                        </Alert>
                        <FormControl>
                            <FormLabel>{secretText.clientId}</FormLabel>
                            <HStack>
                                <Input isDisabled defaultValue={oauthApplication.clientId!} />
                                <Button
                                    leftIcon={<Icon as={DocumentDuplicateIcon} boxSize="1rem" />}
                                    colorScheme="gray"
                                    variant="ghost"
                                    onClick={() => handleCopy(oauthApplication.clientId!)}
                                >
                                    {secretText.buttons.copy}
                                </Button>
                            </HStack>
                        </FormControl>
                        <FormControl>
                            <FormLabel>{secretText.clientSecret}</FormLabel>
                            <HStack>
                                <Input isDisabled defaultValue={oauthApplication.clientSecret!} />
                                <Button
                                    leftIcon={<Icon as={DocumentDuplicateIcon} boxSize="1rem" />}
                                    colorScheme="gray"
                                    variant="ghost"
                                    onClick={() => handleCopy(oauthApplication.clientSecret!)}
                                >
                                    {secretText.buttons.copy}
                                </Button>
                            </HStack>
                        </FormControl>
                        <Button onClick={() => downloadTxtFile()}>
                            {secretText.buttons.download}
                        </Button>
                    </VStack>
                </CardBody>
            </Card>
        </Center>
    );
};
