/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type { AdFormatResponseDto, ProblemDetails } from '../models';
import {
    AdFormatResponseDtoFromJSON,
    AdFormatResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

/**
 * AdFormatsApi - interface
 *
 * @export
 * @interface AdFormatsApiInterface
 */
export interface AdFormatsApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdFormatsApiInterface
     */
    getAllAdFormatsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdFormatResponseDto>>>;

    /**
     */
    getAllAdFormats(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdFormatResponseDto>>;
}

/**
 *
 */
export class AdFormatsApi extends runtime.BaseAPI implements AdFormatsApiInterface {
    /**
     */
    async getAllAdFormatsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdFormatResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/ad-formats`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(AdFormatResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllAdFormats(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdFormatResponseDto>> {
        const response = await this.getAllAdFormatsRaw(initOverrides);
        return await response.value();
    }
}
