import {
    Button,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Text,
} from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { colors, CurrentAmplifiedColorScheme } from '@assets/design-system/theming/colors';

export interface ConfirmDialogProps extends Omit<ModalProps, 'onClose'> {
    headerText: string;
    confirmButtonText?: string;
    confirmButtonStyles?: string;
    cancelButtonText?: string;
    spaceBetween?: boolean;
    isDestructive?: boolean;
    onClose: (isConfirmation: boolean) => void; // isConfirmation is true if user clicks confirm and false if they cancel
    showCloseButton?: boolean;
    isConfirmDisabled?: boolean;
    modalCaption?: string;
    isLoading?: boolean;
    titleColor?: CurrentAmplifiedColorScheme;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
    headerText,
    confirmButtonText = 'Confirm',
    confirmButtonStyles,
    cancelButtonText,
    spaceBetween,
    isDestructive = false, // changes confirm button color to red and prevents it from being the initial focus
    children,
    onClose,
    isOpen,
    showCloseButton = true,
    isConfirmDisabled = false,
    colorScheme,
    scrollBehavior = 'inside',
    modalCaption,
    isLoading,
    titleColor = 'green',
    ...props
}) => {
    const cancelRef = useRef(null);
    const confirmRef = useRef(null);

    const footerButtonWidth = '7rem';

    return (
        <Modal
            initialFocusRef={isDestructive ? cancelRef : confirmRef}
            onClose={() => onClose(false)}
            isOpen={isOpen}
            scrollBehavior={scrollBehavior}
            {...props}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    style={titleColor ? { color: colors.colors[titleColor].DEFAULT } : undefined}
                >
                    {headerText}
                </ModalHeader>
                {showCloseButton && <ModalCloseButton isDisabled={isLoading} />}
                <ModalBody>{children}</ModalBody>

                {modalCaption && (
                    <Center p="1rem">
                        <Text color="red.500">{modalCaption}</Text>
                    </Center>
                )}
                <ModalFooter style={spaceBetween ? { justifyContent: 'space-between' } : undefined}>
                    {cancelButtonText && (
                        <Button
                            ref={cancelRef}
                            colorScheme="gray"
                            marginRight="0.75rem"
                            onClick={() => onClose(false)}
                            minWidth={footerButtonWidth}
                            isDisabled={isLoading}
                        >
                            {cancelButtonText}
                        </Button>
                    )}

                    <Button
                        ref={confirmRef}
                        onClick={() => onClose(true)}
                        colorScheme={isDestructive ? 'red' : colorScheme}
                        minWidth={footerButtonWidth}
                        isDisabled={isConfirmDisabled}
                        isLoading={isLoading}
                        className={confirmButtonStyles || ''}
                    >
                        {confirmButtonText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
