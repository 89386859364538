import { useTranslation } from 'react-i18next';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Input, InputGroup, InputLeftElement, Td } from '@chakra-ui/react';
import { isNumeric, useHelper } from '@shared/utils';
import { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import type { RawImportedDataRequestDto } from '@api-clients/media-plan';
import defaultTableData from './data';
import type { TableDataType } from '../../../pages/campaignCreationPage/MediaPlanCreationPage';
import { recalculateRow } from './tableHelper';

type EditableTableCellProps = {
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
    rowIndex: number;
    accessorKey: keyof RawImportedDataRequestDto;
    value: number;
    validate?: (val: number) => boolean;
    disabled?: boolean;
    currencyInput?: boolean;
    setSubTableHasError?: Dispatch<SetStateAction<TableError[]>>;
    hasSubmitted: boolean;
    removeInvalidTableError?: () => void;
};

const EditableTableCell: FC<EditableTableCellProps> = ({
    value,
    tableData,
    setTableData,
    accessorKey,
    rowIndex,
    disabled,
    currencyInput,
    validate = (_: number) => true,
    setSubTableHasError = () => {},
    hasSubmitted,
    removeInvalidTableError = () => {},
}) => {
    // keep internal state as string
    const [internalState, setInternalState] = useState('0');
    const { roundNumber } = useHelper();
    const inputRef = useRef<HTMLInputElement>(null);
    const hasTouched = useRef(false);
    const { t } = useTranslation('directMediaInput');

    const addCurrentError = () => {
        let message = '';
        switch (accessorKey) {
            case 'budget':
                message = t('table.row.budget.errorMessage');
                break;
            case 'cpm':
                message = t('table.row.cpm.errorMessage');
                break;
            case 'frequency':
                message = t('table.row.frequency.errorMessage');
                break;
            case 'totalAudience':
                message = t('table.row.totalAudience.errorMessage');
                break;
            default:
                break;
        }
        setSubTableHasError((old) => [
            ...old,
            { channelName: tableData[rowIndex].channelName ?? '', accessorKey, message },
        ]);
    };
    const removeCurrentError = () => {
        setSubTableHasError((old) =>
            old.filter(
                (tableError) =>
                    !(
                        tableError.channelName === (tableData[rowIndex].channelName ?? '') &&
                        tableError.accessorKey === accessorKey &&
                        (tableError.adFormatName === null || tableError.adFormatName === undefined)
                    ),
            ),
        );
    };

    const isValid = (str: string) => {
        if (!tableData[rowIndex].isChecked) return true;

        // if the user has tried to submit the form, display error message right away
        if (hasSubmitted) {
            return isNumeric(str) && validate(parseFloat(str));
        }
        // if the user has not submitted the form, only display the error message if they have touched the input

        // remove ad format then compare
        const newTableData = tableData.map((d) => {
            const newData = { ...d };
            newData.adFormats = [];
            return newData;
        });
        const newDefaultTableData = defaultTableData.map((d) => {
            const newData = { ...d };
            newData.adFormats = [];
            return newData;
        });

        if (!(isNumeric(str) && validate(parseFloat(str)))) {
            if (JSON.stringify(newTableData) === JSON.stringify(newDefaultTableData)) {
                return true;
            }
            return false;
        }
        return true;
    };

    useEffect(() => {
        const rounded = roundNumber(value);
        setInternalState(`${rounded}`);
        if (!isValid(`${rounded}`)) {
            addCurrentError();
        }
        return () => {
            removeCurrentError();
        };
    }, [value]);

    useEffect(() => {
        if (!tableData[rowIndex].isChecked) {
            // toggled to false
            removeCurrentError();
            setInternalState(`${value}`);
        }
    }, [tableData[rowIndex].isChecked]);

    // eslint-disable-next-line
    const handleChangeOnBudget = (rowIndex: number, newValue: string) => {
        setTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    const newRow = { ...row };
                    newRow.budget = parseFloat(newValue);
                    recalculateRow(newRow);
                    // update format budget
                    const numberOfEnabledAdFormats =
                        newRow.adFormats?.filter((f) => f.isChecked).length ?? 0;
                    if (numberOfEnabledAdFormats !== 0) {
                        newRow.adFormats = newRow.adFormats?.map((f) => {
                            if (!f.isChecked) {
                                return { ...f, budget: 0 };
                            }
                            return {
                                ...f,
                                budget: (newRow.budget ?? 0) / numberOfEnabledAdFormats,
                                cpm: newRow.cpm,
                            };
                        });
                    }
                    return newRow;
                }
                return row;
            }),
        );
    };

    // eslint-disable-next-line
    const handleChangeOnCpm = (rowIndex: number, newValue: string) => {
        setTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    const newRow = { ...row };
                    newRow.cpm = parseFloat(newValue);
                    recalculateRow(newRow);
                    // update formats cpm
                    newRow.adFormats = newRow.adFormats?.map((f) => ({ ...f, cpm: newRow.cpm }));
                    return newRow;
                }
                return row;
            }),
        );
    };

    // eslint-disable-next-line
    const handleChangeOnFrequency = (rowIndex: number, newValue: string) => {
        setTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    const newRow = { ...row };
                    newRow.frequency = parseFloat(newValue);
                    recalculateRow(newRow);
                    return newRow;
                }
                return row;
            }),
        );
    };

    // eslint-disable-next-line
    const handleChangeOnTotalAudience = (rowIndex: number, newValue: string) => {
        setTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    const newRow = { ...row };
                    newRow.totalAudience = parseFloat(newValue);
                    recalculateRow(newRow);
                    return newRow;
                }
                return row;
            }),
        );
    };

    const handleOnBlur = () => {
        removeInvalidTableError();
        removeCurrentError();
        if (!isValid(internalState)) {
            addCurrentError();
            return;
        }
        // save it
        switch (accessorKey) {
            case 'budget':
                handleChangeOnBudget(rowIndex, internalState);
                break;
            case 'cpm':
                handleChangeOnCpm(rowIndex, internalState);
                break;
            case 'frequency':
                handleChangeOnFrequency(rowIndex, internalState);
                break;
            case 'totalAudience':
                handleChangeOnTotalAudience(rowIndex, internalState);
                break;
            default:
                break;
        }
    };

    return (
        <Td padding="0.5rem" borderBottom="0px">
            <InputGroup>
                {currencyInput && (
                    <InputLeftElement children="$" fontSize="1.2em" color="gray.500" />
                )}
                <Input
                    ref={inputRef}
                    textAlign="right"
                    borderColor="gray.200"
                    display="block"
                    value={internalState}
                    onBlur={() => {
                        handleOnBlur();
                    }}
                    onChange={(e) => {
                        hasTouched.current = true;
                        const newValue = e.target.value;
                        setInternalState(newValue);
                    }}
                    isInvalid={tableData[rowIndex].isChecked ? !isValid(internalState) : false}
                    disabled={disabled}
                    isReadOnly={hasSubmitted}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            // block 'Enter' key from submitting the form
                            e.preventDefault();
                        }
                    }}
                    onFocus={() => {
                        inputRef.current?.select();
                    }}
                />
            </InputGroup>
        </Td>
    );
};

export default EditableTableCell;
