import React, {FC, useState} from 'react'
import {Container, Spacer} from "@chakra-ui/react";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveDashboardSearchCriteriaProps} from "@components/organisms/prove/ProveDashboardHeader";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import {ProveDashboardOverview} from "@components/organisms/prove/ProveDashboardOverview";
import {ProveDashboardBreakdown} from "@components/organisms/prove/ProveDashboardBreakdown";
import {ProveFilter} from "@api-clients/prove/schema/ProveFilter";
import {ProveDashboardSelectedFilters} from "@components/organisms/prove/ProveDashboardSelectedFilters";

interface ProveDashboardBreakdownField {
    id: string;
    label: string;
    maxRecords?: number
}

export interface ProveDashboardBreakdownConfig {
    id: string;
    label: string;
    fields: Array<ProveDashboardBreakdownField>;
    chartType: string;
    chartLimit?: number;
}

export interface ProveDashboardBreakdownProps {
    globalCampaign: GlobalCampaign;
    proveCampaign: ProveCampaign;
    searchCriteria: ProveDashboardSearchCriteriaProps;
}

export const ProveDashboardBody: FC<ProveDashboardBreakdownProps> = ({
                                                                         globalCampaign,
                                                                         proveCampaign,
                                                                         searchCriteria
                                                                     }) => {

    const [filters, setFilters] = useState<Array<ProveFilter>>([]);

    return (
        <Container mt={4}>
            <ProveDashboardSelectedFilters searchCriteria={searchCriteria} filters={filters} setFilters={setFilters}/>
            <ProveDashboardOverview proveCampaign={proveCampaign} searchCriteria={searchCriteria} filters={filters}/>
            <Spacer height={4}/>
            <ProveDashboardBreakdown globalCampaign={globalCampaign}
                                     proveCampaign={proveCampaign}
                                     searchCriteria={searchCriteria}
                                     filters={filters}
                                     setFilters={setFilters}/>
        </Container>
    )

}
