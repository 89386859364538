import { LockIcon } from '@chakra-ui/icons';
import { Box, Spacer, Tooltip } from '@chakra-ui/react';
import { chakraComponents, OptionProps } from 'chakra-react-select';
import { FC } from 'react';

export const CountrySelectOption: FC<OptionProps<any>> = (props) => {
    return (
        <Tooltip
            isDisabled={!props.isDisabled}
            label="Upgrade your plan to unlock this country"
            placement="left"
        >
            <Box>
                <chakraComponents.Option {...props}>
                    {props.children}
                    {props.isDisabled && (
                        <>
                            <Spacer />
                            <LockIcon />
                        </>
                    )}
                </chakraComponents.Option>
            </Box>
        </Tooltip>
    );
};
