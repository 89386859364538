import posthog from 'posthog-js';
import { useAppContextHelper } from './useAppContextHelper';

export const usePosthogEvent = (eventName: string, includeUserDetails = true) => {
    const {
        currentContextValues: { organisation, user },
    } = useAppContextHelper();
    if (!includeUserDetails) {
        return () => {
            return posthog.capture(eventName);
        };
    }
    return () => {
        return posthog.capture(eventName, {
            organisation_id: organisation!.id!,
            organisation_name: organisation!.organisationName!,
            user_id: user!.id!,
            user_name: `${user!.firstName!} ${user!.lastName!}`,
            user_email: user?.emailAddress ?? '',
            timestamp: Date.now(),
        });
    };
};
