import exploreFreeImage01 from './exploreFreeVersion/free_01.png';
import exploreFreeImage02 from './exploreFreeVersion/free_02.png';
import exploreFreeImage03 from './exploreFreeVersion/free_03.png';
import exploreFreeImage04 from './exploreFreeVersion/free_04.png';
import getFullVersionImage from './exploreFreeVersion/upgrade.png';
import customerLogo1 from './customerLogos/Dentsu.png';
import customerLogo2 from './customerLogos/GroupM.png';
import customerLogo3 from './customerLogos/IPG.png';
import customerLogo4 from './customerLogos/Publicis.png';
import customerLogo5 from './customerLogos/omd.png';
import attentionPlanExcelLogo from './logos/attentionPLAN-logo-xlsx.png';

const customerLogos = [
    { src: customerLogo1, alt: 'Dentsu' },
    {
        src: customerLogo2,
        alt: 'GroupM',
    },
    {
        src: customerLogo3,
        alt: 'IPG',
    },
    {
        src: customerLogo4,
        alt: 'Publicis',
    },
    {
        src: customerLogo5,
        alt: 'omd',
    },
] as const;
export {
    exploreFreeImage01,
    exploreFreeImage02,
    exploreFreeImage03,
    exploreFreeImage04,
    getFullVersionImage,
    customerLogos,
    attentionPlanExcelLogo,
};
