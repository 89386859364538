import {
    ScenarioApi,
    UpdateScenarioUserAccessVisibilityRequest,
    ShareUserAccessDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';

import { useHelper } from '@shared/utils';

interface SetScenarioVisibilityHook {
    setScenarioVisibility: (scenarioId: string, campaignId: string, body: boolean) => Promise<void>;
}

type useSetScenarioVisibilityProps = () => SetScenarioVisibilityHook;

export const useSetScenarioVisibility: useSetScenarioVisibilityProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function setScenarioVisibility(scenarioId: string, campaignId: string, body: boolean) {
        try {
            const config = await getMediaPlanConfig();
            const request: UpdateScenarioUserAccessVisibilityRequest = {
                scenarioId,
                campaignId,
                body,
            };
            const api = new ScenarioApi(config);
            await api.updateScenarioUserAccessVisibility(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        setScenarioVisibility,
    };
};
