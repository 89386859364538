import {
    Avatar,
    Box,
    IconButton,
    Menu,
    MenuButton,
    MenuGroup,
    MenuList,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { clearLocalStorage } from '@shared/utils';
import { routing } from '@configs';
import { useTranslation } from 'react-i18next';
import { NavBarMenuListItem } from '@components/atoms/navBarItem';
import { NavBarMenuListItemProps } from '@components/atoms/navBarItem/NavBarMenuListItem';
import { ArrowLeftOnRectangleIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContextHelper, useLogout } from '@hooks/_contexts';

export interface ProfileDropdownProps {
    profileName: string;
    setIsLoggingOut: (isLoggingOut: boolean) => void;
}

export const ProfileDropdown: FC<ProfileDropdownProps> = ({ profileName, setIsLoggingOut }) => {
    const { t } = useTranslation('navigation');
    const buttonText = t('topbar', { returnObjects: true });
    const { logout } = useLogout();

    const onLogout = (): void => {
        setIsLoggingOut(true);
        logout();
        setIsLoggingOut(true);
    };

    const profileItems: Array<NavBarMenuListItemProps> = [
        {
            key: buttonText.profile.accountDetails,
            children: buttonText.profile.accountDetails,
            href: routing.accountDetails.url,
            icon: UserCircleIcon,
        },
        {
            key: buttonText.profile.logout,
            children: buttonText.profile.logout,
            icon: ArrowLeftOnRectangleIcon,
            onClick: onLogout,
        },
    ];

    const {
        isOpen: isTooltipOpen,
        onOpen: onTooltipOpen,
        onClose: onTooltipClose,
    } = useDisclosure();

    return (
        <Box>
            <Menu>
                <Tooltip isOpen={isTooltipOpen} label="Account">
                    <MenuButton
                        onMouseEnter={onTooltipOpen}
                        onMouseLeave={onTooltipClose}
                        icon={<Avatar size="sm" name={profileName} />}
                        aria-label="Account Management"
                        variant="ghost"
                        as={IconButton}
                        onClick={onTooltipClose}
                        isRound
                    />
                </Tooltip>
                <MenuList minH="48px">
                    <MenuGroup title={profileName}>
                        {profileItems.map((item) => (
                            <NavBarMenuListItem {...item} />
                        ))}
                    </MenuGroup>
                </MenuList>
            </Menu>
        </Box>
    );
};
