import { GlobalCountriesApi } from '@api-clients/attention-data';
import { GlobalCountriesResponseDto } from '@api-clients/attention-data/models/GlobalCountriesResponseDto';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetGlobalCountriesHook = () => {
    getGlobalCountries: () => Promise<GlobalCountriesResponseDto[]>;
};

export const useGetGlobalCountries: useGetGlobalCountriesHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getGlobalCountries(): Promise<GlobalCountriesResponseDto[]> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new GlobalCountriesApi(config);
            return await api.getGlobalCountries();
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    };

    return { getGlobalCountries };
};