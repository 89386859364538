/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ChannelLevelResultComparisonResponseDto } from './ChannelLevelResultComparisonResponseDto';
import {
    ChannelLevelResultComparisonResponseDtoFromJSON,
    ChannelLevelResultComparisonResponseDtoFromJSONTyped,
    ChannelLevelResultComparisonResponseDtoToJSON,
} from './ChannelLevelResultComparisonResponseDto';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';

/**
 *
 * @export
 * @interface ScenarioLevelResultComparisonResponseDto
 */
export interface ScenarioLevelResultComparisonResponseDto {
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    outcomeMetric?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    shortTermLift?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    longTermLift?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    totalAudience?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    targetAudience?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    reach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    impression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    costPerActiveAttentionDay?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    costPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    activeAttentionSecPerImpression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    activeAttentionSecPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    passiveAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    passiveAttentionSecPerImpression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    passiveAttentionSecPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {Array<ChannelLevelResultComparisonResponseDto>}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    channelLevelResults?: Array<ChannelLevelResultComparisonResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    scenarioId?: string;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {number}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    optimisationIteration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    codedCommentary?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    modelVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ScenarioLevelResultComparisonResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ScenarioLevelResultComparisonResponseDto interface.
 */
export function instanceOfScenarioLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioLevelResultComparisonResponseDtoFromJSON(
    json: any,
): ScenarioLevelResultComparisonResponseDto {
    return ScenarioLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function ScenarioLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        outcomeMetric: !exists(json, 'outcome_metric')
            ? undefined
            : DecimalComparisonValueFromJSON(json['outcome_metric']),
        shortTermLift: !exists(json, 'short_term_lift')
            ? undefined
            : DecimalComparisonValueFromJSON(json['short_term_lift']),
        longTermLift: !exists(json, 'long_term_lift')
            ? undefined
            : DecimalComparisonValueFromJSON(json['long_term_lift']),
        totalAudience: !exists(json, 'total_audience')
            ? undefined
            : DecimalComparisonValueFromJSON(json['total_audience']),
        targetAudience: !exists(json, 'target_audience')
            ? undefined
            : DecimalComparisonValueFromJSON(json['target_audience']),
        reach: !exists(json, 'reach') ? undefined : DecimalComparisonValueFromJSON(json['reach']),
        impression: !exists(json, 'impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impression']),
        costPerActiveAttentionDay: !exists(json, 'cost_per_active_attention_day')
            ? undefined
            : DecimalComparisonValueFromJSON(json['cost_per_active_attention_day']),
        costPerReach: !exists(json, 'cost_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['cost_per_reach']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        activeAttentionSecPerImpression: !exists(json, 'active_attention_sec_per_impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_sec_per_impression']),
        activeAttentionSecPerReach: !exists(json, 'active_attention_sec_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_sec_per_reach']),
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_volume_seconds']),
        passiveAttentionSecPerImpression: !exists(json, 'passive_attention_sec_per_impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_sec_per_impression']),
        passiveAttentionSecPerReach: !exists(json, 'passive_attention_sec_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_sec_per_reach']),
        channelLevelResults: !exists(json, 'channel_level_results')
            ? undefined
            : json['channel_level_results'] === null
            ? null
            : (json['channel_level_results'] as Array<any>).map(
                  ChannelLevelResultComparisonResponseDtoFromJSON,
              ),
        scenarioId: !exists(json, 'scenario_id') ? undefined : json['scenario_id'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        optimisationIteration: !exists(json, 'optimisation_iteration')
            ? undefined
            : json['optimisation_iteration'],
        codedCommentary: !exists(json, 'coded_commentary') ? undefined : json['coded_commentary'],
        modelVersion: !exists(json, 'model_version') ? undefined : json['model_version'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ScenarioLevelResultComparisonResponseDtoToJSON(
    value?: ScenarioLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        outcome_metric: DecimalComparisonValueToJSON(value.outcomeMetric),
        short_term_lift: DecimalComparisonValueToJSON(value.shortTermLift),
        long_term_lift: DecimalComparisonValueToJSON(value.longTermLift),
        total_audience: DecimalComparisonValueToJSON(value.totalAudience),
        target_audience: DecimalComparisonValueToJSON(value.targetAudience),
        reach: DecimalComparisonValueToJSON(value.reach),
        impression: DecimalComparisonValueToJSON(value.impression),
        cost_per_active_attention_day: DecimalComparisonValueToJSON(
            value.costPerActiveAttentionDay,
        ),
        cost_per_reach: DecimalComparisonValueToJSON(value.costPerReach),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        active_attention_sec_per_impression: DecimalComparisonValueToJSON(
            value.activeAttentionSecPerImpression,
        ),
        active_attention_sec_per_reach: DecimalComparisonValueToJSON(
            value.activeAttentionSecPerReach,
        ),
        passive_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.passiveAttentionVolumeSeconds,
        ),
        passive_attention_sec_per_impression: DecimalComparisonValueToJSON(
            value.passiveAttentionSecPerImpression,
        ),
        passive_attention_sec_per_reach: DecimalComparisonValueToJSON(
            value.passiveAttentionSecPerReach,
        ),
        channel_level_results:
            value.channelLevelResults === undefined
                ? undefined
                : value.channelLevelResults === null
                ? null
                : (value.channelLevelResults as Array<any>).map(
                      ChannelLevelResultComparisonResponseDtoToJSON,
                  ),
        scenario_id: value.scenarioId,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        optimisation_iteration: value.optimisationIteration,
        coded_commentary: value.codedCommentary,
        model_version: value.modelVersion,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
