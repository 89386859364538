import {
    ChannelLevelCurvesResponseDto,
    GetCampaignByIdRequest,
    GetScenarioResponseDto,
    ScenarioLevelResultResponseDto,
    ScenarioResponseDto,
} from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { Box, Center, Container, Spinner, Stack } from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { CollapsibleComponent } from '@components/molecules';
import { useGetReachCurves } from '@hooks/reachCurves/useGetReachCurves';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { ContainerTitle } from '../../atoms';
import { MediaMixChannelViewChart, ScenarioComparisonTable } from '../../molecules';
import { MediaMixChartContainer } from '../mediaMixChartContainer/MediaMixChartContainer';
import { ReachCurveContainer } from '../reachCurveContainer/ReachCurveContainer';

export interface ComparisonSectionProps {
    scenarioPlaceholders: GetScenarioResponseDto[];
    scenarios?: GetScenarioResponseDto[];
    onScenarioDelete: (scenarioId: string) => Promise<void>;
    setScenarios: (countries: Array<GetScenarioResponseDto>) => void;
}

/**
 * interface used to flatten the properties of scenarioDTO and scenarioLevelResultDto
 * for ease of use in child components
 */
export interface Scenario
    extends ScenarioLevelResultResponseDto,
        Pick<ScenarioResponseDto, 'scenarioName' | 'budget'> {}

export const ComparisonSection: FC<ComparisonSectionProps> = ({
    scenarioPlaceholders,
    scenarios,
    onScenarioDelete,
    setScenarios,
}) => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const canUserSeeScenarioDetails =
        featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    const { t } = useTranslation('mediaPlans');
    const comparisonText = t('mediaPlanning.scenarioCreation.comparison', {
        returnObjects: true,
    });
    const { scenarioComparison, reachCurve, scenarioMediaMix } = comparisonText;

    const requestParams = useLoaderData() as GetCampaignByIdRequest;
    const { getReachCurves } = useGetReachCurves();

    const [reachCurveData, setReachCurveData] = useState<Array<ChannelLevelCurvesResponseDto>>();

    const handleReachCurve = async () => {
        setReachCurveData(await getReachCurves({ campaignId: requestParams.mediaPlanId }));
    };

    useEffect(() => {
        handleReachCurve();
    }, [scenarios]);

    return (
        <Container>
            <Stack spacing="2.25rem">
                <Box display="flex" flexDirection="column" gap="1rem">
                    <CollapsibleComponent
                        headingText={scenarioComparison.header}
                        subtitleText={scenarioComparison.subHeader}
                    >
                        <AsyncCard isLoading={!scenarios}>
                            {scenarios && (
                                <ScenarioComparisonTable
                                    canUserSeeScenarioDetails={canUserSeeScenarioDetails}
                                    onScenarioDelete={onScenarioDelete}
                                    scenarios={scenarios.concat(scenarioPlaceholders)}
                                    setScenarios={setScenarios}
                                />
                            )}
                        </AsyncCard>
                    </CollapsibleComponent>
                </Box>
                <Box display="flex" flexDirection="column" gap="1rem">
                    <CollapsibleComponent
                        headingText={reachCurve.header}
                        subtitleText={reachCurve.subHeader}
                    >
                        <ReachCurveContainer
                            canUserSeeScenarioDetails={canUserSeeScenarioDetails}
                            reachCurveData={reachCurveData}
                        />
                    </CollapsibleComponent>
                </Box>
                <Box display="flex" flexDirection="column" gap="1rem">
                    <CollapsibleComponent
                        headingText={scenarioMediaMix.header}
                        subtitleText={scenarioMediaMix.subHeader}
                    >
                        <MediaMixChartContainer>
                            {scenarios ? (
                                <MediaMixChannelViewChart
                                    canUserSeeScenarioDetails={canUserSeeScenarioDetails}
                                    scenarios={scenarios.concat(scenarioPlaceholders)}
                                />
                            ) : (
                                <Center height="100%">
                                    <Spinner />
                                </Center>
                            )}
                        </MediaMixChartContainer>
                    </CollapsibleComponent>
                </Box>
            </Stack>
        </Container>
    );
};
