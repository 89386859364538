import { useTranslation } from 'react-i18next';
import { Td, TableCellProps, Text, Tooltip } from '@chakra-ui/react';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useHelper } from '@shared/utils';
import type { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import type { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import { RawImportedDataRequestDto } from '@api-clients/media-plan';

const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
type TableDisplayCellProps = {
    value: number;
    isPercentage?: boolean;
    validate?: (val: number) => boolean;
    setSubTableHasError?: Dispatch<SetStateAction<TableError[]>>;
    tableData: TableDataType[];
    rowIndex: number;
    accessorKey: keyof RawImportedDataRequestDto;
};
const TableDisplayCell: FC<TableDisplayCellProps> = ({
    value,
    isPercentage = false,
    validate = (_) => true,
    setSubTableHasError = () => {},
    tableData,
    rowIndex,
    accessorKey,
}) => {
    const { formatNumber } = useHelper();
    const { t } = useTranslation('directMediaInput');
    const isValid = validate(value);
    const addCurrentError = () => {
        let message = '';
        switch (accessorKey) {
            case 'reachPercentage':
                message = t('table.row.reachPercentage.errorMessage');
                break;
            default:
                break;
        }
        setSubTableHasError((old) => [
            ...old,
            { channelName: tableData[rowIndex].channelName ?? '', accessorKey, message },
        ]);
    };

    const removeCurrentError = () => {
        setSubTableHasError((old) =>
            old.filter(
                (tableError) =>
                    !(
                        tableError.channelName === (tableData[rowIndex].channelName ?? '') &&
                        tableError.accessorKey === accessorKey &&
                        (tableError.adFormatName === null || tableError.adFormatName === undefined)
                    ),
            ),
        );
    };
    useEffect(() => {
        return () => {
            removeCurrentError();
        };
    }, []);
    useEffect(() => {
        removeCurrentError();
        if (!validate(value)) {
            addCurrentError();
        }
    }, [value]);
    return (
        <Td
            borderBottom="0px"
            backgroundColor={isValid ? 'inherit' : 'red.200'}
            color={isValid ? 'inherit' : 'red.900'}
            fontWeight={isValid ? 'inherit' : 'bold'}
        >
            <Tooltip
                label={`${
                    isPercentage ? formatNumber(value, true, 3) : formatNumber(value, false, 2)
                }`}
                placement="right-end"
            >
                <Text textAlign="right" w="100%" height="100%">
                    {isPercentage ? formatNumber(value, true, 1) : formatter.format(value)}
                </Text>
            </Tooltip>
        </Td>
    );
};

export default TableDisplayCell;
