import { useTranslation } from 'react-i18next';
import { useApiConfiguration } from '@hooks/configuration';
import { toCapitalised, useHelper } from '@shared/utils';
import {
    CreateSelfSignUpOrganisationRequest,
    FinaliseUserDataFromSelfSignUpRequest,
    OrganisationBasicInfoResponseDto,
    UpdateUserRequestDto,
    SignUpApi,
} from '@api-clients/account-manager';

export const useFinaliseUserDataFromSelfSignup = () => {
    const { t } = useTranslation();
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function finaliseUserDataFromSelfSignup(
        userId: string,
        updateUserRequestDto: UpdateUserRequestDto,
    ): Promise<OrganisationBasicInfoResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: FinaliseUserDataFromSelfSignUpRequest = {
                userId,
                updateUserRequestDto,
            };
            const api = new SignUpApi(configuration);
            return await api.finaliseUserDataFromSelfSignUp(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return {
        finaliseUserDataFromSelfSignup,
    };
};
