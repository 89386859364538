import { GetScenarioLevelResultComparisonResponseDto } from '@api-clients/media-plan';
import { ShareScenarioApi } from '@api-clients/media-plan/apis/ShareScenarioApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetSharedScenarioLevelResultsComparisonHook = () => {
    getSharedScenarioLevelResultsComparison: (
        scenarioShareLinkId: string,
    ) => Promise<GetScenarioLevelResultComparisonResponseDto>;
};

export const useGetSharedScenarioLevelResultsComparison: useGetSharedScenarioLevelResultsComparisonHook =
    () => {
        const { getMediaPlanConfigWithoutCredentials } = useApiConfiguration()
        const { getDisplayedErrorMessage } = useHelper();
        const getSharedScenarioLevelResultsComparison = async (scenarioShareLinkId: string) => {
            try {
                const config = await getMediaPlanConfigWithoutCredentials()
                const api = new ShareScenarioApi(config);
                return await api.getSharedScenarioLevelComparisonOfScenarios({
                    scenarioShareLinkId,
                });
            } catch (e: any) {
                throw new Error(await getDisplayedErrorMessage(e));
            }
        };
        return { getSharedScenarioLevelResultsComparison };
    };
