import { Center, Checkbox, Th, Tr } from '@chakra-ui/react';
import { SortDirection } from '@shared/cores';
import { FC } from 'react';
import { Column } from './DataTable';
import { DataTableHeaderCell } from './DataTableHeaderCell';

export interface DataTableHeadSectionProps<T> {
    columns: Array<Column<T>>;
    sortedColumn?: Column<T>;
    sortDirection?: SortDirection;
    onSort?: (sortedColumn: Column<T>) => void;
    isChecked: boolean;
    isIndeterminate: boolean;
    onToggleAllRows: () => void;
    isSelectable?: boolean;
    isExpandable?: boolean;
}

export interface HeaderCell<T> extends Column<T> {
    depth: number;
    rowSpan: number;
    colSpan: number;
}

const getColumnMaxDepth = <T,>(columns: Array<Column<T>>, depth: number): number => {
    return Math.max(
        ...columns.flatMap((column) => {
            if (column.subColumns) {
                return getColumnMaxDepth(column.subColumns, depth + 1);
            }
            return depth;
        }),
    );
};

// get number of childless children of a column to determine its colspan
const getColspanOfColumn = <T,>(column: Column<T>): number => {
    if (column.subColumns) {
        return column.subColumns
            .map((subColumn) => getColspanOfColumn(subColumn))
            .reduce((prev, current) => prev + current);
    }

    return 1;
};

const getColumnsAsHeaderCell = <T,>(
    columns: Array<Column<T>>,
    depth: number,
    maxDepth: number,
): Array<HeaderCell<T>> => {
    return columns.flatMap((column) => {
        let subCols: Array<HeaderCell<T>> = [];
        if (column.subColumns) {
            subCols = getColumnsAsHeaderCell(column.subColumns, depth + 1, maxDepth);
        }
        return [
            {
                rowSpan: column.subColumns ? 1 : maxDepth - depth,
                colSpan: getColspanOfColumn(column),
                depth,
                ...column,
            },
            ...subCols,
        ];
    });
};

const getColumnRows = <T,>(columns: Array<Column<T>>) => {
    const maxDepth = getColumnMaxDepth(columns, 1);

    return [...Array(maxDepth)].map((_, i) =>
        getColumnsAsHeaderCell(columns, 0, maxDepth).filter((column) => column.depth === i),
    );
};

export const DataTableHeaderSection = <T,>({
    columns,
    sortedColumn,
    sortDirection,
    onSort,
    isChecked,
    isIndeterminate,
    onToggleAllRows,
    isSelectable,
    isExpandable,
}: DataTableHeadSectionProps<T>) => {
    const headers = getColumnRows(columns);
    return (
        <>
            {headers.map((header, i) => (
                <Tr key={`header-row-${i}`}>
                    {isSelectable && i === 0 && (
                        <Th rowSpan={headers.length}>
                            <Center>
                                <Checkbox
                                    onChange={onToggleAllRows}
                                    isChecked={isChecked}
                                    isIndeterminate={isIndeterminate}
                                />
                            </Center>
                        </Th>
                    )}
                    {isExpandable && i === 0 && <Th w={0} rowSpan={headers.length} />}
                    {header
                        .filter((column) => column.depth === i)
                        .map((column, index) => (
                            <DataTableHeaderCell
                                key={`header-${index}`}
                                column={column}
                                sortDirection={
                                    sortedColumn?.dataIndex === column.dataIndex
                                        ? sortDirection
                                        : SortDirection.Unsorted
                                }
                                onSort={onSort}
                            />
                        ))}
                </Tr>
            ))}
        </>
    );
};
