import { Box, Container, Stack } from '@chakra-ui/react';
import { ErrorCard } from '@components/molecules/errorCard/ErrorCard';
import { UserBadgeSection } from '@components/molecules/userBadgeSection/UserBadgeSection';
import { SecuritySettings } from '@components/organisms/securitySettings/SecuritySettings';
import { UserInformationSection } from '@components/organisms/userInformation/UserInformationSection';
import { FC } from 'react';
import { Loading } from '@components/atoms';
import { UserFormContextProvider } from '@components/organisms/userInformation';
import { useAppContextHelper } from '@hooks/_contexts';

export const AccountDetailsPage: FC = () => {
    const {
        currentContextValues: { user, organisation, error },
    } = useAppContextHelper();

    return (
        <UserFormContextProvider>
            {!error?.isError ? (
                <>
                    {user && organisation ? (
                        <Container>
                            <Stack spacing="3rem">
                                <Box>
                                    <UserBadgeSection
                                        user={user}
                                        organisationId={organisation.id!}
                                    />
                                </Box>
                                <Box>
                                    <UserInformationSection
                                        user={user}
                                        organisation={organisation}
                                    />
                                </Box>
                                <Box>
                                    <SecuritySettings />
                                </Box>
                            </Stack>
                        </Container>
                    ) : (
                        <Loading />
                    )}
                </>
            ) : (
                <ErrorCard />
            )}
        </UserFormContextProvider>
    );
};
