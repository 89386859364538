/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdjustmentToleranceLevel = {
    High: 'high',
    Medium: 'medium',
    Low: 'low',
    Custom: 'custom'
} as const;
export type AdjustmentToleranceLevel = typeof AdjustmentToleranceLevel[keyof typeof AdjustmentToleranceLevel];


export function AdjustmentToleranceLevelFromJSON(json: any): AdjustmentToleranceLevel {
    return AdjustmentToleranceLevelFromJSONTyped(json, false);
}

export function AdjustmentToleranceLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdjustmentToleranceLevel {
    return json as AdjustmentToleranceLevel;
}

export function AdjustmentToleranceLevelToJSON(value?: AdjustmentToleranceLevel | null): any {
    return value as any;
}

