export const errors = {
    displayTrace:
        'Something went wrong! Please email us at plan-support@amplifiedintelligence.com.au with the following error code: ',
    auth: {
        userIsNotRegistered: 'Your email address is in correct or has not been registered.',
        incorrectUsernamePassword: 'Incorrect username or password.',
        wrongEmailOrPassword: 'The username and/or password is incorrect.',
        emailAddressIsNotFound: 'Email address is not found.',
        invalidConfirmForgotPasswordCode: 'Invalid verification code provided, please try again.',
        attemptExceedLimit: 'Attempt limit exceeded, please try after some time.',
        temporaryPasswordExpired:
            'Your temporary password has expired. Please contact Amplified Intelligence to reset the password.',
        sessionExpired:
            'Reset password session has expired. Please try re-login and reset the password again.',
        requiredNewPasswordBeforeRequestForgotPassword:
            "You cannot request a password reset at this stage. Try logging in with the temporary password sent to your email when this account was created. If you haven't received a temporary password, please contact plan-support@amplifiedintelligence.com.au for assistance",
    },
    organisation: {
        existedOrganisationName:
            'Organisation name {0} is already existed. Please change to other name.',
        existedAdClient: 'Ad client {0} already exists.',
        userHasJoinedOtherOrg: 'The invited user has already joined the organisation.',
        userIsPartOfOrganisation: 'The invited user has already been part of the organisation.',
    },
    usageLimit: {
        exhaustedCampaignCreation:
            'You have exhausted your usage. Please reach out to us at plan-support@amplifiedintelligence.com.au for assistance.',
    },
};
