export const prove = {
    lineItems: {
        new: {
            title: 'Create new line item',
            subtitle: 'Enter details below to create your new line item.',
            form: {
                name: {
                    label: 'Name',
                    requiredMessage: 'The name is required.',
                    exceedsMaxLengthMessage: 'The name must not exceed 100 characters.',
                    duplicateMessage: 'A line item with the same name already exists. Please enter a different name.',
                },
                mediaType: {
                    label: 'Media type',
                    placeholder: 'Select media type',
                    display: 'Display',
                    video: 'Video',
                },
                adFormat: {
                    label: 'Ad format',
                    placeholder: 'Select ad format',
                },
                addButton: 'Add line item',
            },
        },
        import: {
            errorMessages: {
                noLineItems: 'No line items detected.',
            }
        },
        existing: {
            title: 'Line items',
            subtitle: 'Below is a list of existing line items.',
            buttons: {
                downloadTags: 'Download tags',
                viewDashboard: 'View dashboard',
            },
            csv: {
                fileSuffix: 'Tag sheet',
                headers: {
                    name: 'Name',
                    adFormat: 'Ad format',
                    tag: 'Tag',
                },
            },
            table: {
                headers: {
                    name: 'Name',
                    adFormat: 'Ad format',
                    dateCreated: 'Date created',
                    owner: 'Owner',
                    status: 'Status',
                    action: 'Action',
                },
                unknown: 'Unknown',
                status: {
                    vastRequired: 'VAST required',
                    tagRequired: 'Tag required',
                    ready: 'Ready',
                },
                tooltip: {
                    modifyVast: 'Modify VAST',
                    generateTag: 'Generate tag',
                    copyTag: 'Copy tag',
                },
                noEntries: {
                    heading: 'No line items found',
                    subtitle: 'Start adding line items using the form above',
                },
            },
            vast: {
                title: 'Enter VAST details',
                body: {
                    introContent: 'Please provide details of the creative VAST that should be used when generating the wrapped VAST tag.',
                    detectedTitle: 'DETECTED MACROS',
                    detectedContent: 'When generating wrapped VAST tags, we will ensure the declared macros are preserved in the tag so these remain visible to the ad server.',
                    manualTitle: 'MANUALLY ADD MACROS',
                    manualContent: 'Manually add any macros you wish to preserve that were not automatically detected above.',
                },
                form: {
                    vastUrl: {
                        label: 'VAST URL',
                    },
                    macros: {
                        placeholder: 'Enter new macro',
                    },
                    addButton: 'Add macro',
                },
                buttons: {
                    save: 'Save',
                },
            },
        },
    },
    dashboard: {
        buttons: {
            editLineItems: 'Edit line items',
            download: 'Download',
        },
        search: {
            dateRange: {
                label: 'Date Range',
                last24Hours: 'Last 24 hours',
                last48Hours: 'Last 48 hours',
                last7Days: 'Last 7 days',
                last30Days: 'Last 30 days',
            },
            lineItems: {
                label: 'Line items',
            },
            button: 'Search',
        },
        tabs: {
            highlights: 'Highlights',
            hierarchy: 'Hierarchy',
            device: 'Device',
            publisher: 'Publisher',
            adFormat: 'Ad format',
            lineItem: 'Line item',
        },
        overview: {
            active: 'Active',
            passive: 'Passive',
            impressions: {
                label: 'IMPRESSIONS',
                tooltip: 'Total attention rated impressions for the selected search criteria.',
            },
            totalAttention: {
                label: 'TOTAL ATTENTION',
                tooltip: 'Total active / passive attention for the selected search criteria.',
            },
            averageAttention: {
                label: 'AVERAGE ATTENTION',
                tooltip: 'Average active / passive attention for the selected search criteria.',
            },
            attentionVsTiv: {
                label: 'ATTENTION vs TiV',
                noAttention: 'No attention',
                totalAttention: 'Total attention',
                tooltip: 'Comparing attention against time in view (TiV).',
            },
        },
        breakdown: {
            titles: {
                all: 'All',
                bestPerforming: 'Best performing',
                lowPerforming: 'Low performing',
                highActive: 'High active attention',
                highPassive: 'High passive attention',
            },
            infoBanner: {
                bestPerforming: 'Higher active and passive attention than the campaign\'s average. Recommended for all campaign objectives and all brand sizes.',
                lowPerforming: 'Lower active and passive attention than the campaign\'s average. Items with less attention requires more frequency to work.',
                highActive: 'Higher active attention than the campaign\'s average. Recommended for all campaign objectives (especially sales activation) and brand sizes. Essential for brand growth in small brands.',
                highPassive: 'Higher passive attention than the campaign\'s average. Recommended for brand maintenance, message reinforcement and big brands.',
            },
            tooltip: {
                bestPerforming: 'Higher active and passive attention than the campaign\'s average. Sorted by highest number of impressions.',
                lowPerforming: 'Lower active and passive attention than the campaign\'s average. Sorted by highest number of impressions.',
                highActive: 'Higher active attention than the campaign\'s average. Sorted by highest average active attention.',
                highPassive: 'Higher passive attention than the campaign\'s average. Sorted by highest average passive attention.',
            },
            descriptions: {
                bestPerforming: 'Recommended for all campaign objectives and all brand sizes.',
                lowPerforming: 'Items with less attention requires more frequency to work.',
                highActive: 'Recommended for all campaign objectives (especially sales activation) and brand sizes. Essential for brand growth in small brands.',
                highPassive: 'Recommended for brand maintenance, message reinforcement and big brands.',
            },
            labels: {
                device: 'Device',
                publisher: 'Publisher',
                adFormat: 'Ad format',
                lineItem: 'Line item',
                impressions: 'Impressions',
                averageAttention: 'Average attention',
                avgActive: 'Avg active',
                avgPassive: 'Avg passive',
                averageActiveAttention: 'Average active attention',
                averagePassiveAttention: 'Average passive attention',
                averageNonAttention: 'Average non attention',
                activeAttentionDecay: 'Active attention decay',
                activeAttention: 'Active attention',
            },
            noDataFoundSection: {
                heading: 'No data found',
                subtitle: 'Please ensure your tags have been implemented in your DSP and update the search criteria to refresh the dashboard.',
            },
        },
    }
};
