/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganisationStatus = {
    Active: 'active',
    Pending: 'pending',
    Archived: 'archived'
} as const;
export type OrganisationStatus = typeof OrganisationStatus[keyof typeof OrganisationStatus];


export function OrganisationStatusFromJSON(json: any): OrganisationStatus {
    return OrganisationStatusFromJSONTyped(json, false);
}

export function OrganisationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationStatus {
    return json as OrganisationStatus;
}

export function OrganisationStatusToJSON(value?: OrganisationStatus | null): any {
    return value as any;
}

