import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { ContextProperty } from '@hooks/_contexts';

interface TableContextProps {
    tableName: string | null;
    hasProductTour: ContextProperty<boolean>;
    targetIndex: ContextProperty<number>;
    targetTourClass: ContextProperty<string[]>;
    targetId: ContextProperty<string>;
}

const initialState: TableContextProps = {
    tableName: null,
    hasProductTour: {
        currentValue: null,
        setCurrentValue: (value: boolean | null) => {},
    },
    targetIndex: {
        currentValue: null,
        setCurrentValue: (value: number | null) => {},
    },
    targetTourClass: {
        currentValue: null,
        setCurrentValue: (value: string[] | null) => {},
    },
    targetId: {
        currentValue: null,
        setCurrentValue: (value: string | null) => {},
    },
};

export const TableContext = createContext<TableContextProps>(initialState);

export const TableContextProvider: FC<{ children: ReactNode; tableName: string }> = ({
    children,
    tableName,
}) => {
    const [hasProductTour, setHasProductTour] = useState(initialState.hasProductTour.currentValue);
    const [targetIndex, setTargetIndex] = useState(initialState.targetIndex.currentValue);
    const [targetTourClass, setTargetTourClass] = useState(
        initialState.targetTourClass.currentValue,
    );
    const [targetId, setTargetId] = useState(initialState.targetId.currentValue);

    const ctxHasProductTour: ContextProperty<boolean> = useMemo(
        () => ({
            currentValue: hasProductTour,
            setCurrentValue: (value: boolean | null) => {
                setHasProductTour(value);
            },
        }),
        [hasProductTour],
    );

    const ctxTargetIndex: ContextProperty<number> = useMemo(
        () => ({
            currentValue: targetIndex,
            setCurrentValue: (value: number | null) => {
                setTargetIndex(value);
            },
        }),
        [hasProductTour],
    );

    const ctxTargetTourClass: ContextProperty<string[]> = useMemo(
        () => ({
            currentValue: targetTourClass,
            setCurrentValue: (value: string[] | null) => {
                setTargetTourClass(value);
            },
        }),
        [hasProductTour],
    );

    const ctxTargetId: ContextProperty<string> = useMemo(
        () => ({
            currentValue: targetId,
            setCurrentValue: (value: string | null) => {
                setTargetId(value);
            },
        }),
        [targetId],
    );

    const value = useMemo(
        (): TableContextProps => ({
            tableName,
            hasProductTour: ctxHasProductTour,
            targetIndex: ctxTargetIndex,
            targetTourClass: ctxTargetTourClass,
            targetId: ctxTargetId,
        }),
        [ctxTargetTourClass, ctxTargetIndex, ctxHasProductTour, ctxTargetId],
    );

    return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export const useTableContext = (): TableContextProps => useContext(TableContext);
