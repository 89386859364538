import { Box, Center, Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { ExpandingIcon } from '@components/atoms';
import { layoutPadding } from '@configs/layoutConfig';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useLayoutContainerContext } from '@hooks/_contexts/layoutContainer/LayoutContainerContextProvider';
import { FC, useEffect, useRef } from 'react';
import { useTableContext } from '@components/molecules/tables';

export interface ExpandableBannerProps {
    children: ReactJSXElement | Array<ReactJSXElement>;
}

export const ExpandableBanner: FC<ExpandableBannerProps> = ({ children }) => {
    return (
        <Box
            zIndex={1}
            position="sticky"
            top={0}
            marginTop={`-${layoutPadding}`}
            marginX={`-${layoutPadding}`}
            backgroundColor="green.50"
        >
            {children}
        </Box>
    );
};

export const ExpandableBannerHeader: FC<{ children: ReactJSXElement | Array<ReactJSXElement> }> = ({
    children,
}) => (
    <Box paddingY="1rem" paddingX={layoutPadding}>
        {children}
    </Box>
);

type ExpandableBannerBodyProps = {
    children: ReactJSXElement | Array<ReactJSXElement>;
    defaultIsOpen: boolean;
    autoOpen: boolean;
};
export const ExpandableBannerBody: FC<ExpandableBannerBodyProps> = ({
    children,
    defaultIsOpen,
    autoOpen,
}) => {
    const { tableName } = useTableContext();
    const {
        isOpen: isSectionOpen,
        onToggle: onSectionToggle,
        onOpen,
        onClose,
    } = useDisclosure({
        defaultIsOpen,
    });

    const shouldAutoToggle = useRef(true);

    const handleToggle = () => {
        shouldAutoToggle.current = false;
        onSectionToggle();
    };

    const scrollRef = useLayoutContainerContext();

    const handleScroll = () => {
        if (!autoOpen) {
            return;
        }
        const currentScrollPosition = scrollRef?.current?.scrollTop;

        if (currentScrollPosition === 0) {
            onOpen();
            shouldAutoToggle.current = true;
        } else if (currentScrollPosition > 0 && shouldAutoToggle.current) {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, []);

    return (
        <>
            <Collapse in={isSectionOpen}>
                <Box paddingX={layoutPadding} paddingBottom="1rem">
                    {children}
                </Box>
            </Collapse>
            <Flex
                id={tableName ? `${tableName}-expand-section` : undefined}
                backgroundColor="green.100"
                onClick={handleToggle}
                cursor="pointer"
                padding="0.625rem"
                aria-expanded={isSectionOpen}
                bgColor="green.100"
            >
                <Center flexGrow={1}>
                    <Text color="green.500">{isSectionOpen ? 'Collapse' : 'Expand'}</Text>
                    <ExpandingIcon
                        color="green.500"
                        marginLeft=".5rem"
                        boxSize="1.5rem"
                        isOpen={isSectionOpen}
                    />
                </Center>
            </Flex>
        </>
    );
};
