import { OauthApplicationResponseDto, OrganisationResponseDto } from '@api-clients/account-manager';
import { Icon } from '@chakra-ui/icons';
import {
    Card,
    CardBody,
    Center,
    Heading,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    SkeletonText,
    Text,
    useConst,
    VStack,
} from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { Column, DataTable, Row } from '@components/molecules';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useHelper } from '@shared/utils';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DeleteOAuthApplicationMenuListItem,
    UpdateOAuthApplicationDialogButton,
    UpdateOAuthApplicationPermissionsDialogButton,
} from './dialogs';

interface DataRow {
    appName: string;
    clientId: string;
    createdAt: string;
    createdByUser: string;
}

interface InternalProps {
    organisation: OrganisationResponseDto;
    oAuthApplications?: OauthApplicationResponseDto[];
    onFetch: () => void;
}
export const OAuthApplicationListSection: FC<InternalProps> = ({
    organisation,
    oAuthApplications,
    onFetch,
}) => {
    const { getUserNameFromId } = useHelper();
    const { t } = useTranslation();

    const oauthApplicationPage = useConst(() =>
        t('pages.applicationManagement', { returnObjects: true }),
    );
    const { table } = useConst(() => ({
        table: oauthApplicationPage.table,
        dialogs: oauthApplicationPage.dialogs,
    }));

    const columns: Array<Column<DataRow>> = [
        {
            title: table.headers.appName,
            dataIndex: 'appName',
            key: 'appName',
            render: (row) => {
                return (
                    <Text noOfLines={1} wordBreak="break-all" whiteSpace="normal">
                        {row.appName}
                    </Text>
                );
            },
        },
        {
            title: table.headers.clientId,
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            title: table.headers.creationDate,
            dataIndex: 'createdAt',
            key: 'creationDate',
        },
        {
            title: table.headers.owner,
            dataIndex: 'createdByUser',
            key: 'owner',
        },
        {
            title: table.headers.action,
            key: 'action',
            render: (row) => {
                const application = oAuthApplications?.find((app) => app.clientId === row.key);

                return (
                    <Center>
                        {application && (
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    icon={<Icon boxSize="1.7rem" as={EllipsisVerticalIcon} />}
                                    variant="ghost"
                                />
                                <MenuList>
                                    <UpdateOAuthApplicationDialogButton
                                        onUpdate={onFetch}
                                        application={application}
                                    />
                                    <UpdateOAuthApplicationPermissionsDialogButton
                                        onUpdate={onFetch}
                                        application={application}
                                    />
                                    <DeleteOAuthApplicationMenuListItem
                                        onDelete={onFetch}
                                        application={application}
                                    />
                                </MenuList>
                            </Menu>
                        )}
                    </Center>
                );
            },
        },
    ];

    const dataSource: Array<Row<DataRow>> =
        oAuthApplications?.map((app) => {
            return {
                key: app.clientId!,
                appName: app.name!,
                clientId: app.clientId!,
                createdAt: app.createdAt!.toLocaleDateString()!,
                createdByUser: getUserNameFromId(organisation, app.createdByUserId),
            };
        }) ?? [];

    const renderNoOAuthApplicationsFound = useCallback(
        (): ReactJSXElement => (
            <VStack>
                <Heading>{table.noApplicationsFoundSection.heading}</Heading>
                <Text fontSize="sm">{table.noApplicationsFoundSection.subtitle}</Text>
            </VStack>
        ),
        [],
    );

    return (
        <>
            <AsyncCard isLoading={!oAuthApplications}>
                <DataTable
                    dataSource={dataSource}
                    columns={columns}
                    noEntriesComponent={renderNoOAuthApplicationsFound()}
                />
            </AsyncCard>
        </>
    );
};
