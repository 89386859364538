import React, {FC, useEffect, useState} from 'react'
import {
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tooltip,
    Tr
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useSelector} from "@redux";
import {proveDashboardBreakdownData, proveDashboardOverviewData} from "@redux/slices/prove/dashboard/selectors";
import {ProveFlattenedReportBreakdownEntry} from "@api-clients/prove/schema/ProveDashboard";
import {AsyncCard} from "@components/atoms";
import {ProveDashboardBreakdownConfig} from "@components/organisms/prove/ProveDashboardBody";
import {InformationCircleIcon} from "@heroicons/react/24/outline";

interface ProveDashboardHighlightsProps {
    breakdownConfig: ProveDashboardBreakdownConfig;

    valueFormatter(type: string, value: string): string;
}

export const ProveDashboardHighlights: FC<ProveDashboardHighlightsProps> = ({
                                                                                breakdownConfig,
                                                                                valueFormatter
                                                                            }) => {

    const dashboardOverviewDataSelector = useSelector(proveDashboardOverviewData);
    const dashboardBreakdownDataSelector = useSelector(proveDashboardBreakdownData);

    const [bestPerformers, setBestPerformers] = useState<undefined | Array<ProveFlattenedReportBreakdownEntry>>(undefined);
    const [lowPerformers, setLowPerformers] = useState<undefined | Array<ProveFlattenedReportBreakdownEntry>>(undefined);
    const [highActive, setHighActive] = useState<undefined | Array<ProveFlattenedReportBreakdownEntry>>(undefined);
    const [highPassive, setHighPassive] = useState<undefined | Array<ProveFlattenedReportBreakdownEntry>>(undefined);

    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    useEffect(() => {
        if (!dashboardOverviewDataSelector || !dashboardBreakdownDataSelector) {
            return;
        }

        setBestPerformers(dashboardBreakdownDataSelector
            .filter((breakdownEntry) => breakdownEntry.metrics.avgActiveAttention > dashboardOverviewDataSelector.avgActiveAttention && breakdownEntry.metrics.avgPassiveAttention > dashboardOverviewDataSelector.avgPassiveAttention)
            .sort((a, b) => b.metrics.impressions - a.metrics.impressions)
            .slice(0, 5));

        setLowPerformers(dashboardBreakdownDataSelector
            .filter((breakdownEntry) => breakdownEntry.metrics.avgActiveAttention < dashboardOverviewDataSelector.avgActiveAttention && breakdownEntry.metrics.avgPassiveAttention < dashboardOverviewDataSelector.avgPassiveAttention)
            .sort((a, b) => b.metrics.impressions - a.metrics.impressions)
            .slice(0, 5));

        setHighActive(dashboardBreakdownDataSelector
            .filter((breakdownEntry) => breakdownEntry.metrics.avgActiveAttention > dashboardOverviewDataSelector.avgActiveAttention)
            .sort((a, b) => b.metrics.avgActiveAttention - a.metrics.avgActiveAttention)
            .slice(0, 5));

        setHighPassive(dashboardBreakdownDataSelector
            .filter((breakdownEntry) => breakdownEntry.metrics.avgPassiveAttention > dashboardOverviewDataSelector.avgPassiveAttention)
            .sort((a, b) => b.metrics.avgPassiveAttention - a.metrics.avgPassiveAttention)
            .slice(0, 5));
    }, [dashboardBreakdownDataSelector])

    const renderComponent = (title: string, description: string, tooltip: string, results: undefined | Array<ProveFlattenedReportBreakdownEntry>, color: string) => {
        return <AsyncCard isLoading={!results} backgroundColor={color}>
            {results && (
                <>
                    <HStack ml={4} mt={4}>
                        <Heading size="sm">{title.toUpperCase()}</Heading>
                        <Tooltip
                            label={tooltip}
                        >
                            <Icon as={InformationCircleIcon} boxSize="1.2rem"/>
                        </Tooltip>
                    </HStack>
                    <Text fontSize="sm" m={4} h={8}>{description}</Text>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    {breakdownConfig.fields.map((item, index) => {
                                        return (<Th key={index} border={0}>{item.label}</Th>);
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {results.map((entry, indexA) => {
                                    return (
                                        <Tr key={indexA}>
                                            {entry.value.map((item, indexB) => {
                                                return <Td key={indexB}
                                                           border={0}>{valueFormatter(breakdownConfig.fields[indexB].id, item)}</Td>
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </AsyncCard>
    }

    return (
        <>
            <Grid templateColumns="repeat(2, 1fr)" gap="1rem">
                <GridItem>
                    {renderComponent(content.breakdown.titles.bestPerforming, content.breakdown.descriptions.bestPerforming, content.breakdown.tooltip.bestPerforming, bestPerformers, "green.100")}
                </GridItem>
                <GridItem>
                    {renderComponent(content.breakdown.titles.lowPerforming, content.breakdown.descriptions.lowPerforming, content.breakdown.tooltip.lowPerforming, lowPerformers, "red.100")}
                </GridItem>
                <GridItem>
                    {renderComponent(content.breakdown.titles.highActive, content.breakdown.descriptions.highActive, content.breakdown.tooltip.highActive, highActive, "blue.100")}
                </GridItem>
                <GridItem>
                    {renderComponent(content.breakdown.titles.highPassive, content.breakdown.descriptions.highPassive, content.breakdown.tooltip.highPassive, highPassive, "gray.100")}
                </GridItem>
            </Grid>
        </>
    )
}
