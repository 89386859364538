import { OauthApplicationResponseDto } from '@api-clients/account-manager';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Flex,
    Input,
    useConst,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { InputWrapper } from '@components/atoms';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { NavBarMenuListItem } from '@components/atoms/navBarItem';
import { TrashIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOauthApplications } from '@hooks/accountManager';
import { useGetOrganisation } from '@hooks/organisations';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface InternalProps {
    application: OauthApplicationResponseDto;
    onDelete: () => void;
}

interface FormData {
    typeDelete: string;
}

export const DeleteOAuthApplicationMenuListItem: FC<InternalProps> = ({
    application,
    onDelete,
}) => {
    const { t } = useTranslation();
    const { deleteOauthApplication } = useOauthApplications();
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();
    const { errorToast, successToast } = useCustomToast();

    const translation = useConst(() =>
        t('pages.applicationManagement', {
            returnObjects: true,
        }),
    );

    const {
        deleteDialog,
        toastText: {
            delete: { success, error },
        },
    } = useConst(() => ({
        deleteDialog: translation.dialogs.delete,
        toastText: translation.toast,
    }));

    const validationSchema = yup.object().shape({
        typeDelete: yup
            .string()
            .lowercase()
            .trim()
            .oneOf(['delete'], deleteDialog.form.fields.typeDelete.validation),
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, touchedFields, isSubmitting, isValid },
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const deleteAlertDescription = useMemo(
        () => deleteDialog.alertText.description.replace('{applicationName}', application.name!),
        [application],
    );

    const { onOpen, isOpen, onClose } = useDisclosure();

    const onDeleteOAuthApplication = async (data: FormData) => {
        try {
            await deleteOauthApplication(organisation!.id!, application.clientId!);
            onDelete();
            successToast(success.heading, success.subtitle);
            onClose();
        } catch (e) {
            errorToast(error.heading, error.subtitle);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    return (
        <>
            <NavBarMenuListItem children="Delete" icon={TrashIcon} onClick={onOpen} />
            <ConfirmDialog
                isDestructive
                headerText={deleteDialog.headerText}
                confirmButtonText={deleteDialog.confirmButton}
                cancelButtonText={deleteDialog.cancelButton}
                isOpen={isOpen}
                isLoading={isSubmitting}
                isConfirmDisabled={!isValid}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        handleSubmit(onDeleteOAuthApplication)();
                    } else {
                        onClose();
                    }
                }}
            >
                <VStack spacing="1rem" align="left">
                    <Alert status="error">
                        <VStack align="left">
                            <Flex>
                                <AlertIcon />
                                <AlertTitle>{deleteDialog.alertText.heading}</AlertTitle>
                            </Flex>
                            <AlertDescription>
                                <Trans>{deleteAlertDescription}</Trans>
                            </AlertDescription>
                        </VStack>
                    </Alert>
                    <form onSubmit={handleSubmit(onDeleteOAuthApplication)}>
                        <InputWrapper
                            isRequired
                            formLabel={deleteDialog.form.fields.typeDelete.label}
                            formErrorText={errors.typeDelete?.message}
                            isInvalid={touchedFields.typeDelete && !!errors.typeDelete}
                        >
                            <Input {...register('typeDelete')} />
                        </InputWrapper>
                    </form>
                </VStack>
            </ConfirmDialog>
        </>
    );
};
