import {FeatureCode, LimitType} from '@api-clients/subscriptions';
import {Box, Center, Flex, HStack, Icon, IconButton, Spacer, Tooltip, useDisclosure,} from '@chakra-ui/react';
import {ProfileDropdown, TechnicalSupportDropdown, VerticalNavbar,} from '@components/molecules';
import {Bars3Icon} from '@heroicons/react/24/outline';
import {useAppContextHelper} from '@hooks/_contexts';
import {FC} from 'react';

export interface HorizontalNavBarProps {
    height: string | number;
    profileName: string;
    iconSize?: string | number;
    hasBorder?: boolean;
    setIsLoggingOut: (isLoggingOut: boolean) => void;
}

export const HorizontalNavBar: FC<HorizontalNavBarProps> = ({
                                                                height,
                                                                profileName,
                                                                iconSize = '1.5rem',
                                                                hasBorder = true,
                                                                setIsLoggingOut,
                                                            }) => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    const {
        currentContextValues: {featureBalances},
    } = useAppContextHelper();

    const isPaidPlan =
        featureBalances?.find(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    const {
        isOpen: isTooltipOpen,
        onOpen: onTooltipOpen,
        onClose: onTooltipClose,
    } = useDisclosure();

    return (
        <Box position="sticky" top={0} zIndex={2}>
            <Flex
                bgColor="white"
                boxShadow={hasBorder ? 'xs' : ''}
                paddingX="1rem"
                paddingY=".5rem"
                h={height}
            >
                <HStack spacing="1.5rem">
                    <Center>
                        <Tooltip isOpen={isTooltipOpen} label="Main menu">
                            <IconButton
                                isRound
                                onMouseEnter={onTooltipOpen}
                                onMouseLeave={onTooltipClose}
                                onClick={() => {
                                    onOpen();
                                    onTooltipClose();
                                }}
                                variant="ghost"
                                icon={<Icon boxSize={iconSize} as={Bars3Icon}/>}
                                aria-label="menu"
                            />
                        </Tooltip>
                    </Center>
                </HStack>
                <Spacer/>
                <HStack>
                    <TechnicalSupportDropdown/>
                    <ProfileDropdown setIsLoggingOut={setIsLoggingOut} profileName={profileName}/>
                </HStack>
                <VerticalNavbar onClose={onClose} isOpen={isOpen}/>
            </Flex>
        </Box>
    );
};
