/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { InviteExistingUserDto } from './InviteExistingUserDto';
import {
    InviteExistingUserDtoFromJSON,
    InviteExistingUserDtoFromJSONTyped,
    InviteExistingUserDtoToJSON,
} from './InviteExistingUserDto';
import type { InviteNewUserDto } from './InviteNewUserDto';
import {
    InviteNewUserDtoFromJSON,
    InviteNewUserDtoFromJSONTyped,
    InviteNewUserDtoToJSON,
} from './InviteNewUserDto';

/**
 *
 * @export
 * @interface InviteUserToOrganisationRequestDto
 */
export interface InviteUserToOrganisationRequestDto {
    /**
     *
     * @type {InviteNewUserDto}
     * @memberof InviteUserToOrganisationRequestDto
     */
    newUser?: InviteNewUserDto;
    /**
     *
     * @type {InviteExistingUserDto}
     * @memberof InviteUserToOrganisationRequestDto
     */
    existingUser?: InviteExistingUserDto;
}

/**
 * Check if a given object implements the InviteUserToOrganisationRequestDto interface.
 */
export function instanceOfInviteUserToOrganisationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InviteUserToOrganisationRequestDtoFromJSON(
    json: any,
): InviteUserToOrganisationRequestDto {
    return InviteUserToOrganisationRequestDtoFromJSONTyped(json, false);
}

export function InviteUserToOrganisationRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InviteUserToOrganisationRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        newUser: !exists(json, 'new_user') ? undefined : InviteNewUserDtoFromJSON(json['new_user']),
        existingUser: !exists(json, 'existing_user')
            ? undefined
            : InviteExistingUserDtoFromJSON(json['existing_user']),
    };
}

export function InviteUserToOrganisationRequestDtoToJSON(
    value?: InviteUserToOrganisationRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        new_user: InviteNewUserDtoToJSON(value.newUser),
        existing_user: InviteExistingUserDtoToJSON(value.existingUser),
    };
}
