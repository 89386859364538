import {
    AdClientResponseDto,
    AgeGroup,
    CampaignResponseDto,
    CampaignStatus,
    ChannelLevelCurvesResponseDto,
    ChannelResponseDto,
    CreateCampaignRequestDto,
    Gender,
    GetScenarioLevelResultComparisonResponseDto,
    RawImportedDataRequestDto,
    ScenarioAdFormatRequestDto,
    ScenarioAdFormatResponseDto,
    UpdateDraftCampaignRequestDto,
} from '@api-clients/media-plan';
import { CountryCodeIso2, CurrencyCode } from '@api-clients/shared';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { BackButtonOnCampaignCreationPage } from '@apps/attention360/atoms';
import { AddIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Collapse,
    Container,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Input,
    Select,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip,
    useBoolean,
    useDisclosure,
    useRadioGroup,
} from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { mimeType } from '@components/atoms/fileUploadContainer/FileUploadContainer';
import { convertTemplateToDTO } from '@components/atoms/fileUploadContainer/ValidateImportTemplate';
import {
    AddNewClient,
    CollapsibleComponent,
    DynamicUpgradeModal,
    ImportFileModal,
} from '@components/molecules';
import { DateRange, DateRangeFormParam } from '@components/molecules/dateRange/DateRange';
import { campaignObjectiveOptions, importTemplate, routing } from '@configs';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetAdClients } from '@hooks/adClients/useGetAdClients';
import { useCreateCampaign } from '@hooks/campaigns/useCreateCampaign';
import { useDeleteDraftCampaign, useGetDraftCampaign } from '@hooks/campaigns';
import { useGetChannels } from '@hooks/channels/useGetChannels';
import { useGetFeatureBalances } from '@hooks/subscriptions/useGetFeatureBalances';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { findCountryName } from '@shared/cores/types/Country';
import { useHelper } from '@shared/utils';
import {
    CountryFeatureCode,
    GetCountryCodeFromCountryFeature,
    isMediaPlanCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FileDrop } from 'react-file-drop';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import type { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import { useUpdateDraftCampaign } from '@hooks/campaigns/useUpdateDraftCampaign';
import { useDebouncedFunction } from '@hooks/debounce';
import {
    recalculateCpm,
    recalculateRow,
} from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/tableHelper';
import { useGetScenarioLevelResultsComparison } from '@hooks/scenarios/useGetScenarioLevelResultsComparison';
import {
    MediaPlanDetailsBanner,
    MultiSelect,
    SingleSelect,
    TableEditingSectionBanner,
} from '../../molecules';
import { CountrySelect } from '../../molecules/countrySelect/CountrySelect';
import {
    CampaignCreationDetailsTabSkeleton,
    UpgradeAgeGroupHeader,
    UpgradeCountryHeader,
    UpgradeGenderHeader,
} from '../../atoms';
import { CampaignCreationTable } from '../../molecules/table';

import defaultTableData from '../../molecules/table/campaignCreationTable/data';
import { NoCampaignsLeftCard } from '../../atoms/noCampaignsLeftPopover/NoCampaignsLeftCard';
import '../../molecules/table/campaignCreationTable/filedrop.css';
import { ScenarioViewSection } from '../../organisms/scenarioEditSection/ScenarioViewSection';

interface FormData {
    campaignName: string;
    adClientId: string;
    campaignObjective: string;
    campaignObjectiveOther: string;
}

const filterOutEmptyRows = (row: RawImportedDataRequestDto) =>
    row.budget &&
    row.budget > 0 &&
    row.cpm &&
    row.cpm > 0 &&
    row.frequency &&
    row.frequency > 0 &&
    row.totalAudience &&
    row.totalAudience > 0;

export type SubTableDataType = ScenarioAdFormatResponseDto & { budget: number };
export type TableDataType = RawImportedDataRequestDto & {
    isChecked: boolean;
    adFormats?: SubTableDataType[];
};

type TabContentType = {
    tabIndex: number;
    label: string;
    description: string;
    saved: boolean;
};
const defaultTabContent: Array<TabContentType> = [
    { tabIndex: 0, label: 'STEP 1', description: 'Media plan details', saved: false },
    { tabIndex: 1, label: 'STEP 2', description: 'Build media mix', saved: false },
    { tabIndex: 2, label: 'STEP 3', description: 'Review base media plan', saved: false },
];

// Delay before saving user input
const AUTOSAVE_TIMER = 3000;
export const MediaPlanCreationPage: FC = () => {
    const [tabContent, setTabContent] = useState(defaultTabContent);
    const { mediaPlanId } = useParams();
    const [subTableHasError, setSubTableHasError] = useState<Array<TableError>>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [globalCampaignId, setGlobalCampaignId] = useState<string>();
    const {
        isOpen: isFileDropModalOpen,
        onClose: onFileDropModalClose,
        onOpen: onFileDropModalOpen,
    } = useDisclosure();
    const { t } = useTranslation('mediaPlans');

    const { mapUsageLimitTranslation } = useHelper();
    const { getFeatureBalances } = useGetFeatureBalances();
    const { getAdClients } = useGetAdClients();
    const {
        currentContextValues: { organisation, user, featureBalances, error },
        setCurrentContextValues: { setFeatureBalances },
    } = useAppContextHelper();
    const navigate = useNavigate();

    const demographic = t('mediaPlanning.scenarioCreation.generateScenarios.buttons.demographic', {
        returnObjects: true,
    });

    const { getChannels } = useGetChannels();

    const [allChannels, setAllChannels] = useState<Array<ChannelResponseDto>>();

    const [allAdClients, setAllAdClients] = useState<Array<AdClientResponseDto>>();

    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFormParam>({
        startDate: null,
        endDate: null,
        isValid: false,
    });

    const acceptedFileTypes = [mimeType.xls, mimeType.xlsx];

    const [tableData, setTableData] = useState<TableDataType[]>(defaultTableData);
    const [validTemplate, setValidTemplate] = useState<RawImportedDataRequestDto[]>();
    const [formatCpms, setFormatCpms] = useState<ScenarioAdFormatResponseDto[]>();
    const [selectedCountries, setSelectedCountries] = useState<CountryCodeIso2[]>([]);
    const [selectedAgeGroups, setSelectedAgeGroups] = useState<AgeGroup[]>(Object.values(AgeGroup));
    const [selectedGender, setSelectedGender] = useState<Gender[]>(Object.values(Gender));
    const [selectedAdClientId, setSelectedAdClientId] = useState<string>();
    const [baseScenarioId, setBaseScenarioId] = useState<string>('');
    const [createdMediaPlanId, setCreatedMediaPlanId] = useState<string>('');

    const [createdCampaignConstraint, setCreatedCampaignConstraint] =
        useState<CreateCampaignRequestDto>();

    const {
        isOpen: isCreateCampaignDialogOpen,
        onOpen: onCreateCampaignDialogOpen,
        onClose: onCreateCampaignDialogClose,
    } = useDisclosure();
    const cancelCreateCampaignRef = useRef<HTMLButtonElement>(null);
    const refSubmitButton = useRef<HTMLButtonElement>(null);

    const { formatOptionsForSelect, formatAgeGroup, formatStringToCapitalized } = useHelper();
    const emitPosthogEvent = usePosthogEvent('Successfully upload a valid template file (3/5)');
    const emitFinishCreatingMediaPlan = usePosthogEvent('Finish create media plan (4/5)');

    const getAdFormats = (adChannelName: string): SubTableDataType[] => {
        // Convert FormatResponseDto to ScenarioAdFormatResponseDto & { budget: number }
        const adFormats =
            allChannels?.find(
                (ch) => ch.adChannelName?.toLowerCase() === adChannelName.toLowerCase(),
            )?.adFormats ?? [];
        return adFormats.map((f) => ({
            adFormatCode: f.adFormatCode,
            adFormatName: f.adFormatName,
            cpm: 0,
            budget: 0,
            isChecked: true,
        }));
    };

    const emitPosthogEventOnClickNext = usePosthogEvent('Fill in media plan details (2.1/5)');
    const sortAdFormatsWithAdFormatName = (adFormats: SubTableDataType[]): SubTableDataType[] => {
        adFormats.sort((a, b) => {
            if (!a.adFormatName) {
                return 1;
            }
            if (!b.adFormatName) {
                return 1;
            }
            return a.adFormatName?.localeCompare(b.adFormatName);
        });
        return [...adFormats];
    };
    useEffect(() => {
        const newTableData: TableDataType[] = tableData.map((tableRow) => ({
            ...tableRow,
            adFormats: sortAdFormatsWithAdFormatName(getAdFormats(tableRow.channelName ?? '')),
        }));
        // sort the adFormats
        setTableData(newTableData);
    }, [allChannels]);

    const convertTemplateDataToTableData = (template?: Array<RawImportedDataRequestDto>) => {
        if (!template) {
            return;
        }
        // only overwrite default table data if the uploaded template is valid
        setTableData(
            template.map((r) => {
                // for each table row, find the corresponding adformats
                const subFormats =
                    allChannels?.find(
                        (ch) => ch.adChannelName?.toLowerCase() === r.channelName?.toLowerCase(),
                    )?.adFormats ?? [];
                const numberOfAdFormats = subFormats.length;
                const adFormats: SubTableDataType[] = sortAdFormatsWithAdFormatName(
                    subFormats.map((f) => ({
                        adFormatCode: f.adFormatCode,
                        adFormatName: f.adFormatName,
                        cpm: r.cpm,
                        isChecked: true,
                        // by default, default format mix is set to true
                        // channel budget will be evenly distributed towards among all formats that are ticked
                        budget:
                            numberOfAdFormats > 0
                                ? (r.budget ?? 0) / numberOfAdFormats
                                : r.budget ?? 0,
                    })),
                );
                const newTableRow: TableDataType = {
                    ...r,
                    isChecked: true,
                    adFormats,
                };
                return newTableRow;
            }),
        );

        // if the channel budget is zero, uncheck
        setTableData((data) =>
            data.map((row) => {
                if ((row?.budget ?? 0) > 0) {
                    return row;
                }
                // uncheck all sub formats
                row.adFormats = row.adFormats?.map((subRow) => ({ ...subRow, isChecked: false }));
                return {
                    ...row,
                    isChecked: false,
                };
            }),
        );
    };
    const onFileDropModalConfirm = () => {
        convertTemplateDataToTableData(validTemplate);
    };
    const onFileUpload = (files: Array<File>): void => {
        if (files.length > 0) {
            convertTemplateToDTO(files[0]).then((response) => {
                emitPosthogEvent();
                setValidTemplate(response);
            });
        } else {
            setValidTemplate(undefined);
        }
    };

    const { validationSchema, createCampaign } = useCreateCampaign();
    const { deleteDraftCampaign } = useDeleteDraftCampaign();

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors, isSubmitting, isValid },
        getValues,
        setValue,
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const campaignSelectedOption = watch('campaignObjective');
    const { errorToast, successToast } = useCustomToast();

    const { getDraftCampaign } = useGetDraftCampaign();
    const fetchAdClients = () => {
        setAllAdClients(undefined);
        setSelectedAdClientId(undefined);
        getAdClients().then((response) => {
            setAllAdClients(
                response.sort(
                    (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0),
                ),
            );
            setSelectedAdClientId(response.at(0)?.id);
        });
    };

    useEffect(() => {
        getChannels().then((response) => setAllChannels(response));
        fetchAdClients();
    }, []);

    useEffect(() => {
        const filteredTemplate = validTemplate?.filter(filterOutEmptyRows);

        if (filteredTemplate && allChannels) {
            const newFormatCpms = filteredTemplate.flatMap((ch) => {
                const formats = allChannels.find(
                    (channel) =>
                        channel.adChannelName?.toLowerCase() === ch.channelName?.toLowerCase(),
                )?.adFormats;

                return (
                    formats?.map(
                        (f): ScenarioAdFormatResponseDto => ({
                            adFormatName: f.adFormatName ?? '',
                            isChecked: true,
                            adFormatCode: f.adFormatCode ?? '',
                            cpm: ch.cpm ?? 0,
                        }),
                    ) ?? []
                );
            });
            setFormatCpms(newFormatCpms);
        } else {
            setFormatCpms(undefined);
        }
    }, [validTemplate, allChannels]);

    const {
        isOpen: isAdFormatsModalOpen,
        onOpen: onAdFormatsModalOpen,
        onClose: onAdFormatsModalClose,
    } = useDisclosure();

    const {
        getRootProps,
        getRadioProps,
        value: radioGroupValue,
    } = useRadioGroup({ name: 'method', defaultValue: 'create' });
    const group = getRootProps();

    const feature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
    );
    const balance = feature && feature.limit! - feature.used!;
    const [isUsingGlobal, setIsUsingGlobal] = useState<boolean>(false);
    const { canUseGlobal, subscribedCountries } = useMemo(() => {
        const isGlobalFeatureBalance = featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );
        setIsUsingGlobal(!!isGlobalFeatureBalance);

        return {
            canUseGlobal: isGlobalFeatureBalance,
            subscribedCountries:
                featureBalances
                    ?.filter(
                        (f) =>
                            isMediaPlanCountryFeature(f.featureCode!) &&
                            f.limitType === LimitType.Unlimited,
                    )
                    .map(
                        (f) =>
                            GetCountryCodeFromCountryFeature(
                                f.featureCode as CountryFeatureCode,
                            ) as CountryCodeIso2,
                    ) ?? [],
        };
    }, [featureBalances]);

    const getSelectedTableRows = () => {
        return tableData.filter((row) => row.adFormats !== null && row.adFormats !== undefined);
    };
    const getAllSelectedAdFormats = (selectedTableRows: TableDataType[]) => {
        const allSelectedAdFormats: (SubTableDataType & { channelName: string })[] = [];
        for (let i = 0; i < selectedTableRows.length; i++) {
            const row = selectedTableRows[i];
            const channelName = row.channelName ?? '';
            // No need to filter out unchecked ad formats
            // const selectedAdFormats = row.adFormats?.filter((f) => f.isChecked) ?? [];
            const selectedAdFormats = row.adFormats ?? [];
            selectedAdFormats.forEach((f) => {
                if (!row.isChecked) {
                    allSelectedAdFormats.push({
                        isChecked: false,
                        budget: 0,
                        adFormatCode: f.adFormatCode,
                        cpm: f.cpm,
                        channelName,
                    });
                } else {
                    // row is checked
                    allSelectedAdFormats.push({ ...f, channelName });
                }
            });
        }
        return allSelectedAdFormats;
    };
    const getAdFormatsPayload = (
        allSelectedAdFormats: (ScenarioAdFormatResponseDto & { budget: number } & {
            channelName: string;
        })[],
    ): ScenarioAdFormatRequestDto[] => {
        return allSelectedAdFormats.map((f) => {
            if (f.isChecked)
                return {
                    adFormatCode: f.adFormatCode,
                    cpm: f.cpm,
                    isChecked: f.isChecked,
                    budget: f.budget,
                };
            return {
                adFormatCode: f.adFormatCode,
                cpm: f.cpm,
                isChecked: f.isChecked,
                budget: 0,
            };
        });
    };
    const getRawImportedData = () => {
        return tableData
            .filter((row) => row.isChecked)
            .map((row) => ({
                channelName: row.channelName,
                budget: row.budget,
                cpm: row.cpm,
                reachPercentage: row.reachPercentage,
                totalAudience: row.totalAudience,
                impressions: row.impressions,
                targetAudience: row.targetAudience,
                frequency: row.frequency,
                trp: row.trp,
                cpp: row.cpp,
            }));
    };

    const handlePostCampaignCreation = (response?: CampaignResponseDto) => {
        if (response) {
            setBaseScenarioId(response.scenarios?.at(0)?.id ?? '');
            setCreatedMediaPlanId(response.id ?? '');
            setTabIndex(2);
        }
    };

    const onFormSave = async (data: FormData) => {
        emitFinishCreatingMediaPlan();
        let totalBudget = 0;

        tableData.forEach((value) => {
            // if user does not select the item, ignore it in budget calculation
            if (value.isChecked && value.budget) {
                totalBudget += value.budget;
            }
        });

        const campaignObjectiveInput =
            data.campaignObjective !== 'Other'
                ? data.campaignObjective
                : data.campaignObjectiveOther;

        const selectedTableRows = getSelectedTableRows();
        const allSelectedAdFormats = getAllSelectedAdFormats(selectedTableRows);
        const payload: CreateCampaignRequestDto = {
            organisationId: organisation?.id,
            campaignId: globalCampaignId,
            createdByUserId: user?.id,
            managedByUserId: user?.id,
            adClientId: selectedAdClientId,
            campaignStatus: CampaignStatus.Completed,
            campaignName: data.campaignName,
            totalBudget,
            currency: CurrencyCode.Usd,
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            importedFile: {
                uploadedByUserId: user?.id,
                templateName: importTemplate.templateName,
                fileName: importTemplate.fileName,
                rawImportedDatas: getRawImportedData(),
            },
            toCreateBaselineScenario: true,
            campaignObjective: campaignObjectiveInput,
            adFormats: getAdFormatsPayload(allSelectedAdFormats),
            countries: [...selectedCountries],
            ageGroups: selectedAgeGroups,
            genders: selectedGender,
            isUsingGlobalCountry: isUsingGlobal,
        };

        try {
            const response = await createCampaign(payload);
            if (mediaPlanId) {
                await deleteDraftCampaign(mediaPlanId);
            }
            // update context + localStorage
            const currentFeatureBalances = await getFeatureBalances();
            setFeatureBalances(currentFeatureBalances!);
            setCreatedCampaignConstraint(payload);
            handlePostCampaignCreation(response);
            setHasSubmitted(true);
        } catch (e: any) {
            errorToast(
                t('mediaPlanning.campaignCreation.errorMessages.importFailed.title'),
                mapUsageLimitTranslation(e.message),
            );
        }
    };

    const canUseAgeGroups = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseAgeGroups,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const canUseGenders = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseGenders,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const canUseAdFormat = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseFormatCpms,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const adClientOptions = useMemo(
        () =>
            allAdClients?.map((a) => ({
                value: a.id!,
                label: a.adClientName!,
            })) ?? [],
        [allAdClients],
    );

    const {
        onOpen: onCreateAdClientOpen,
        onClose: onCreateAdClientClose,
        isOpen: isCreateAdClientOpen,
    } = useDisclosure();

    const { formatNumber } = useHelper();

    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const [upgradeHeader, setUpgradeHeader] = useState<ReactJSXElement>(<></>);
    const handleOpenUpgradeModal = (header: ReactJSXElement) => {
        setUpgradeHeader(header);
        onUpgradeModalOpen();
    };
    const isFormInvalid =
        !selectedDateRange.isValid ||
        // isValid from react-hook-form is buggy, `isValid` will be true even though there is no errors
        // For now this will do
        errors.campaignObjective !== undefined ||
        errors.campaignObjectiveOther !== undefined ||
        errors.campaignName !== undefined ||
        errors.adClientId !== undefined ||
        (selectedCountries.length === 0 && !isUsingGlobal) ||
        selectedAgeGroups.length === 0 ||
        selectedGender.length === 0 ||
        !selectedAdClientId;

    const isTableValid = (table: TableDataType[]) => {
        for (let i = 0; i < table.length; i++) {
            const row = table[i];
            if (!row.isChecked) {
                // eslint-disable-next-line
                continue;
            }
            if (
                !row.cpm ||
                row.cpm < 0 ||
                !row.budget ||
                row.budget < 0 ||
                !row.totalAudience ||
                row.totalAudience < 0
            ) {
                return false;
            }
        }
        return true;
    };
    const invalidTableErrorChannelName = 'Table';
    const addInvalidTableError = () => {
        setSubTableHasError((old) => [
            ...old,
            {
                channelName: invalidTableErrorChannelName,
                message: 'Table contains invalid inputs',
            },
        ]);
    };
    const removeInvalidTableError = () => {
        setSubTableHasError((old) =>
            old.filter((err) => err.channelName !== invalidTableErrorChannelName),
        );
    };
    useEffect(() => {
        removeInvalidTableError();
        return () => {
            removeInvalidTableError();
        };
    }, []);

    const markDirectMediaInputAsSaved = () => {
        setTabContent((old) =>
            old.map((tab) => {
                // second tab is the direct media plan input
                if (tab.tabIndex !== 1) {
                    return tab;
                }
                return { ...tab, saved: true };
            }),
        );
    };
    const markCampaignBasicInfoAsSaved = () => {
        setTabContent((old) =>
            old.map((tab) => {
                // first tab contains the basic info
                if (tab.tabIndex !== 0) {
                    return tab;
                }
                return { ...tab, saved: true };
            }),
        );
    };
    const [isLoadingDraftCampaignInfo, setIsLoadingDraftCampaignInfo] = useBoolean(false);
    const fetchDraftCampaignInfo = async () => {
        try {
            setIsLoadingDraftCampaignInfo.on();
            if (!mediaPlanId) {
                throw new Error('Unable to get media plan');
            }
            const res = await getDraftCampaign({ campaignId: mediaPlanId });

            if (res.campaignId) {
                setGlobalCampaignId(res.campaignId);
            }

            if (res.campaignName) {
                setValue('campaignName', res.campaignName);
            }
            if (res.clientId) {
                // if clientId is null, then the user has never uploaded basic campaign info to backend
                markCampaignBasicInfoAsSaved();
                const adClientOption = adClientOptions.find((opt) => opt.value === res.clientId);
                if (adClientOption) {
                    setSelectedAdClientId(adClientOption.value);
                }
            }
            if (res.campaignObjective) {
                const objective = res.campaignObjective;
                const found = campaignObjectiveOptions.find((obj) => obj.label === objective);
                if (found) {
                    setValue('campaignObjective', found.value);
                } else {
                    setValue('campaignObjective', 'Other');
                    setValue('campaignObjectiveOther', objective);
                }
            }
            const constraints = res?.constraints;
            if (!constraints) {
                return;
            }
            const { startDate, endDate, ageGroups, genders, countries } = constraints;
            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);
                const isDateRangeValid = start.getTime() <= end.getTime();
                setSelectedDateRange((old) => ({
                    startDate: constraints.startDate,
                    endDate: constraints.endDate,
                    isValid: isDateRangeValid,
                }));
            }
            if (constraints.isUsingGlobalCountry) {
                setIsUsingGlobal(true);
            } else {
                setIsUsingGlobal(false);
            }
            const userSelectedCountries = countries && countries.length > 0 ? countries : [];
            setSelectedCountries(userSelectedCountries);
            // if the response does not contain any options for ageGroups and gender, defaults to select all
            const userSelectedAgeGroups =
                ageGroups && ageGroups.length > 0 ? ageGroups : Object.values(AgeGroup);
            setSelectedAgeGroups(userSelectedAgeGroups);
            const userSelectedGenders =
                genders && genders.length > 0 ? genders : Object.values(Gender);
            setSelectedGender(userSelectedGenders);
            const hasAdFormats = constraints.adFormats && constraints.adFormats.length > 0;
            if (hasAdFormats) {
                markDirectMediaInputAsSaved();
                const { adFormats: savedAdFormats } = constraints;
                setTableData((old) =>
                    old.map((row) => {
                        const foundInSavedAdChannel = (
                            res.importedFile?.rawImportedData ?? []
                        ).find(
                            (r) => r.channelName?.toLowerCase() === row.channelName?.toLowerCase(),
                        );
                        if (foundInSavedAdChannel) {
                            row.frequency = foundInSavedAdChannel.frequency ?? 0;
                            row.totalAudience = foundInSavedAdChannel.totalAudience ?? 0;
                            row.isChecked = true;
                        } else {
                            row.isChecked = false;
                            row.adFormats = row.adFormats?.map((f) => ({ ...f, isChecked: false }));
                        }
                        row.adFormats = (row.adFormats ?? []).map((subRow) => {
                            const foundInSavedAdFormat = savedAdFormats?.find(
                                (s) =>
                                    s.adFormatCode?.toLowerCase() ===
                                    subRow.adFormatCode?.toLowerCase(),
                            );
                            if (foundInSavedAdFormat) {
                                return {
                                    ...subRow,
                                    isChecked: foundInSavedAdFormat.isChecked,
                                    budget: foundInSavedAdFormat.budget ?? 0,
                                    cpm: foundInSavedAdFormat.cpm ?? 0,
                                };
                            }
                            return { ...subRow, isChecked: false };
                        });
                        row.budget = row.adFormats?.reduce((partialSum, format) => {
                            if (format.isChecked) {
                                return format.budget + partialSum;
                            }
                            return partialSum;
                        }, 0);
                        recalculateCpm(row);
                        recalculateRow(row);
                        return row;
                    }),
                );
            }
        } catch (e) {
            errorToast(
                'Something went wrong',
                'An error has occurred when fetching draft campaign info',
            );
        } finally {
            setIsLoadingDraftCampaignInfo.off();
        }
    };
    useEffect(() => {
        fetchDraftCampaignInfo();
    }, [allAdClients]);

    const containerRef = useRef<HTMLDivElement>(null);
    const [isCreatingDraftCampaign, setIsCreatingDraftCampaign] = useBoolean(false);
    const { updateDraftCampaign } = useUpdateDraftCampaign();
    const handleClickOnNext = async () => {
        emitPosthogEventOnClickNext();
        const formValues = getValues();
        const campaignObjectiveInput =
            formValues.campaignObjective !== 'Other'
                ? formValues.campaignObjective
                : formValues.campaignObjectiveOther;
        const payload: UpdateDraftCampaignRequestDto = {
            organisationId: organisation?.id,
            createdByUserId: user?.id,
            managedByUserId: user?.id,
            adClientId: selectedAdClientId,
            campaignName: formValues.campaignName,
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            currency: CurrencyCode.Usd,
            campaignObjective: campaignObjectiveInput,
            countries: [...selectedCountries],
            ageGroups: selectedAgeGroups,
            genders: selectedGender,
            isUsingGlobalCountry: isUsingGlobal,
            toCreateBaselineScenario: true,
        };
        try {
            setIsCreatingDraftCampaign.on();
            // send request here
            if (!mediaPlanId) {
                throw new Error('Unable to get media plan');
            }
            const response = await updateDraftCampaign(mediaPlanId, payload);
            // update the campaign instead
        } catch (e) {
            console.error('Unable to create draft campaign', e instanceof Error ? e.stack : e);
        } finally {
            setIsCreatingDraftCampaign.off();
        }
        // send the request here
        setTabIndex((old) => old + 1);
        containerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const [isSavingUserInput, setIsSavingUserInput] = useBoolean(false);
    const savingUserInput = async () => {
        // Don't save invalid table data
        if (!isTableValid(tableData)) {
            return;
        }
        const selectedTableRows = getSelectedTableRows();
        const allSelectedAdFormats = getAllSelectedAdFormats(selectedTableRows);
        const formValues = getValues();

        const payload: UpdateDraftCampaignRequestDto = {
            organisationId: organisation?.id,
            createdByUserId: user?.id,
            managedByUserId: user?.id,
            adClientId: selectedAdClientId,
            campaignName: formValues.campaignName,
            campaignObjective:
                formValues.campaignObjective !== 'Other'
                    ? formValues.campaignObjective
                    : formValues.campaignObjectiveOther,
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            currency: CurrencyCode.Usd,
            countries: [...selectedCountries],
            ageGroups: selectedAgeGroups,
            genders: selectedGender,
            isUsingGlobalCountry: isUsingGlobal,
            toCreateBaselineScenario: true,
            importedFile: {
                uploadedByUserId: user?.id,
                templateName: importTemplate.templateName,
                fileName: importTemplate.fileName,
                rawImportedDatas: getRawImportedData(),
            },
            adFormats: getAdFormatsPayload(allSelectedAdFormats),
        };
        try {
            setIsSavingUserInput.on();
            if (!mediaPlanId) {
                throw new Error('Unable to get media plan');
            }
            const response = await updateDraftCampaign(mediaPlanId, payload);
            markDirectMediaInputAsSaved();
        } catch (e) {
            console.error('Unable to save user input');
        } finally {
            setIsSavingUserInput.off();
        }
    };
    const debouncedSavingUserInput = useDebouncedFunction(savingUserInput, AUTOSAVE_TIMER);
    useEffect(() => {
        debouncedSavingUserInput();
    }, [tableData]);
    const handleUnload = (e: Event) => {
        e.preventDefault();
        savingUserInput();
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const [scenarioLevelResultsComparison, setScenarioLevelResultsComparison] =
        useState<GetScenarioLevelResultComparisonResponseDto>();
    const [reachCurveData, setReachCurveData] = useState<Array<ChannelLevelCurvesResponseDto>>([]);

    const { getScenarioLevelResultsComparison } = useGetScenarioLevelResultsComparison();

    useEffect(() => {
        if (!createdMediaPlanId || !baseScenarioId) {
            return;
        }

        getScenarioLevelResultsComparison(createdMediaPlanId, baseScenarioId).then((r) => {
            r.channelLevelResults?.sort(
                (a, b) => a.channelName?.localeCompare(b.channelName ?? '') ?? 0,
            );
            setScenarioLevelResultsComparison(r);
        });
    }, [createdMediaPlanId, baseScenarioId]);

    return !error?.isError ? (
        <>
            {(balance && balance > 0) || hasSubmitted ? (
                <Container ref={containerRef}>
                    <AlertDialog
                        isOpen={isCreateCampaignDialogOpen}
                        onClose={onCreateCampaignDialogClose}
                        leastDestructiveRef={cancelCreateCampaignRef}
                    >
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                    Confirm media plan details
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                    By clicking the Confirm button, a media plan will be created and
                                    subtracted from your media plan limit. Please make sure all
                                    details are correct before proceeding.
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button variant="outline" onClick={onCreateCampaignDialogClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            refSubmitButton?.current?.click();
                                            onCreateCampaignDialogClose();
                                        }}
                                        ml={3}
                                    >
                                        Confirm
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                    <DynamicUpgradeModal
                        key="DynamicUpgradeModal_CampaignCreationPage"
                        isOpen={isUpgradeModalOpen}
                        onClose={onUpgradeModalClose}
                        header={upgradeHeader}
                    />
                    <HStack paddingBottom="1rem">
                        <BackButtonOnCampaignCreationPage
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            handleUnload={savingUserInput}
                        />
                        <Heading>Create media plan</Heading>
                    </HStack>

                    <Box overflow="clip" padding={0} boxShadow="xs" borderRadius="lg" bg="white">
                        {user && organisation ? (
                            <Flex h="100%" direction="column">
                                <form onSubmit={handleSubmit(onFormSave)}>
                                    <Tabs
                                        isFitted
                                        index={tabIndex}
                                        onChange={(index) => {
                                            if (isFormInvalid) {
                                                return;
                                            }
                                            if (index === 2 && !hasSubmitted) return;
                                            setTabIndex(index);
                                        }}
                                    >
                                        <TabList>
                                            {tabContent.map((tab) => (
                                                <Tab
                                                    key={tab.tabIndex}
                                                    display="block"
                                                    paddingX="2.5rem"
                                                    paddingY="1.5rem"
                                                    textAlign="left"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Box flexGrow={1}>
                                                            <Heading
                                                                fontSize="0.75rem"
                                                                color="green.500"
                                                                fontWeight={600}
                                                                lineHeight="1rem"
                                                                letterSpacing="0.01875rem"
                                                                alignSelf="stretch"
                                                            >
                                                                {tab.label}
                                                            </Heading>
                                                            <Text
                                                                fontSize="0.875rem"
                                                                color="gray.700"
                                                                fontWeight={500}
                                                                lineHeight="1.25rem"
                                                                textTransform="none"
                                                                textAlign="left"
                                                                display="block"
                                                                width="100%"
                                                            >
                                                                {tab.description}
                                                            </Text>
                                                        </Box>
                                                        <Box flexGrow={0}>
                                                            {isLoadingDraftCampaignInfo && (
                                                                <Spinner size="sm" />
                                                            )}
                                                            {!isLoadingDraftCampaignInfo &&
                                                                tab.saved && (
                                                                    <Icon
                                                                        as={CheckCircleIcon}
                                                                        boxSize="1.5rem"
                                                                    />
                                                                )}
                                                        </Box>
                                                    </Flex>
                                                </Tab>
                                            ))}
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel p={0}>
                                                {/** Step 1: media plan details */}
                                                <MediaPlanDetailsBanner
                                                    firstName={user.firstName ?? ''}
                                                />
                                                {isLoadingDraftCampaignInfo ? (
                                                    <CampaignCreationDetailsTabSkeleton />
                                                ) : (
                                                    <Box paddingX="3rem" paddingY="2rem">
                                                        <Flex
                                                            flexDirection="column"
                                                            gap="1rem"
                                                            maxW="42rem"
                                                            mx="auto"
                                                        >
                                                            <Box>
                                                                <FormControl
                                                                    isRequired
                                                                    isInvalid={
                                                                        errors.campaignName !==
                                                                        undefined
                                                                    }
                                                                >
                                                                    <FormLabel>Name</FormLabel>
                                                                    <Input
                                                                        {...register(
                                                                            'campaignName',
                                                                        )}
                                                                        isReadOnly={true}
                                                                    />
                                                                    <FormErrorMessage>
                                                                        {errors.campaignName &&
                                                                            (errors.campaignName
                                                                                .message as string)}
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </Box>
                                                            <Box>
                                                                <FormControl
                                                                    isRequired
                                                                    isInvalid={
                                                                        errors.adClientId !==
                                                                        undefined
                                                                    }
                                                                    isReadOnly={hasSubmitted}
                                                                >
                                                                    <HStack>
                                                                        <SingleSelect
                                                                            isReadOnly={
                                                                                hasSubmitted
                                                                            }
                                                                            isLoaded={
                                                                                !!allAdClients
                                                                            }
                                                                            label="Select client"
                                                                            options={
                                                                                adClientOptions
                                                                            }
                                                                            selectedOption={adClientOptions.find(
                                                                                (o) =>
                                                                                    o.value ===
                                                                                    selectedAdClientId,
                                                                            )}
                                                                            setSelectedOption={(
                                                                                o,
                                                                            ) => {
                                                                                setSelectedAdClientId(
                                                                                    o?.value,
                                                                                );
                                                                            }}
                                                                            noOptionsMessage="No clients found, create a new client to get started"
                                                                            isRequired
                                                                            useSkeletonLoading={
                                                                                false
                                                                            }
                                                                            rightContent={
                                                                                <Tooltip label="Add a new client">
                                                                                    <IconButton
                                                                                        colorScheme="gray"
                                                                                        icon={
                                                                                            <AddIcon />
                                                                                        }
                                                                                        verticalAlign="end"
                                                                                        aria-label="add client"
                                                                                        variant="outline"
                                                                                        onClick={
                                                                                            onCreateAdClientOpen
                                                                                        }
                                                                                        isDisabled={
                                                                                            hasSubmitted
                                                                                        }
                                                                                    >
                                                                                        Add
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <AddNewClient
                                                                            isOpen={
                                                                                isCreateAdClientOpen
                                                                            }
                                                                            onClose={
                                                                                onCreateAdClientClose
                                                                            }
                                                                            onSuccessfulCreate={
                                                                                fetchAdClients
                                                                            }
                                                                        />
                                                                    </HStack>
                                                                    <FormErrorMessage height="1rem">
                                                                        {errors.adClientId !==
                                                                            undefined &&
                                                                            (errors.adClientId
                                                                                .message as string)}
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </Box>
                                                            <Box>
                                                                <FormControl>
                                                                    <FormLabel>
                                                                        Campaign Objective
                                                                    </FormLabel>
                                                                    <Select
                                                                        placeholder="Select option"
                                                                        {...register(
                                                                            'campaignObjective',
                                                                        )}
                                                                        isDisabled={hasSubmitted}
                                                                    >
                                                                        {campaignObjectiveOptions.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <option
                                                                                        key={
                                                                                            item.value
                                                                                        }
                                                                                        value={
                                                                                            item.value
                                                                                        }
                                                                                    >
                                                                                        {item.label}
                                                                                    </option>
                                                                                );
                                                                            },
                                                                        )}
                                                                        <option value="Other">
                                                                            Other
                                                                        </option>
                                                                    </Select>
                                                                    <Collapse
                                                                        in={
                                                                            campaignSelectedOption ===
                                                                            'Other'
                                                                        }
                                                                        style={{
                                                                            overflow: 'visible',
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            {...register(
                                                                                'campaignObjectiveOther',
                                                                            )}
                                                                            placeholder="Enter campaign objective"
                                                                            marginTop="0.5rem"
                                                                        />
                                                                    </Collapse>
                                                                </FormControl>
                                                            </Box>
                                                            <DateRange
                                                                dateRangeForm={selectedDateRange}
                                                                onChange={setSelectedDateRange}
                                                                startDateRequired
                                                                endDateRequired
                                                                disabled={hasSubmitted}
                                                            />
                                                            <Divider my="1.5rem" />

                                                            <Box>
                                                                <CountrySelect
                                                                    label="Countries"
                                                                    availableCountryOptions={
                                                                        subscribedCountries
                                                                    }
                                                                    setSelectedOptions={(
                                                                        selectedOptions,
                                                                    ) =>
                                                                        setSelectedCountries(
                                                                            selectedOptions.map(
                                                                                (o) =>
                                                                                    o.value as CountryCodeIso2,
                                                                            ),
                                                                        )
                                                                    }
                                                                    selectedOptions={formatOptionsForSelect(
                                                                        selectedCountries,
                                                                        findCountryName,
                                                                    )}
                                                                    tooltip={demographic.tooltip}
                                                                    isRequired
                                                                    canSelectGlobal={!!canUseGlobal}
                                                                    isUsingGlobal={isUsingGlobal}
                                                                    setIsUsingGlobal={
                                                                        setIsUsingGlobal
                                                                    }
                                                                    isDisabled={
                                                                        !featureBalances ||
                                                                        hasSubmitted
                                                                    }
                                                                    openUpgradeModal={() =>
                                                                        handleOpenUpgradeModal(
                                                                            <UpgradeCountryHeader />,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <MultiSelect
                                                                    label="Age group"
                                                                    options={formatOptionsForSelect(
                                                                        Object.values(AgeGroup),
                                                                        formatAgeGroup,
                                                                    ).map((o) => ({
                                                                        isDisabled: false,
                                                                        tooltip: !canUseAgeGroups
                                                                            ? 'Upgrade your plan to customise age group constraints'
                                                                            : '',
                                                                        showLockIcon: false,
                                                                        ...o,
                                                                    }))}
                                                                    setSelectedOptions={(
                                                                        selectedOptions,
                                                                    ) =>
                                                                        setSelectedAgeGroups(
                                                                            selectedOptions.map(
                                                                                (o) =>
                                                                                    o.value as AgeGroup,
                                                                            ),
                                                                        )
                                                                    }
                                                                    selectedOptions={formatOptionsForSelect(
                                                                        selectedAgeGroups,
                                                                        formatAgeGroup,
                                                                    )}
                                                                    tooltip={demographic.tooltip}
                                                                    isRequired
                                                                    isClearable
                                                                    selectAll
                                                                    allowSelectAll
                                                                    openUpgradeModal={() =>
                                                                        handleOpenUpgradeModal(
                                                                            <UpgradeAgeGroupHeader />,
                                                                        )
                                                                    }
                                                                    isPaidPlan={!!canUseAgeGroups}
                                                                    isDisabled={hasSubmitted}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <MultiSelect
                                                                    label="Gender"
                                                                    options={formatOptionsForSelect(
                                                                        Object.values(Gender),
                                                                        formatStringToCapitalized,
                                                                    ).map((o) => ({
                                                                        isDisabled: false,
                                                                        tooltip: !canUseGenders
                                                                            ? 'Upgrade your plan customise gender constraints'
                                                                            : '',
                                                                        showLockIcon: false,
                                                                        ...o,
                                                                    }))}
                                                                    setSelectedOptions={(
                                                                        selectedOptions,
                                                                    ) =>
                                                                        setSelectedGender(
                                                                            selectedOptions.map(
                                                                                (o) =>
                                                                                    o.value as Gender,
                                                                            ),
                                                                        )
                                                                    }
                                                                    selectedOptions={formatOptionsForSelect(
                                                                        selectedGender,
                                                                        formatStringToCapitalized,
                                                                    )}
                                                                    tooltip={demographic.tooltip}
                                                                    isRequired
                                                                    isClearable
                                                                    selectAll
                                                                    allowSelectAll
                                                                    openUpgradeModal={() =>
                                                                        handleOpenUpgradeModal(
                                                                            <UpgradeGenderHeader />,
                                                                        )
                                                                    }
                                                                    isPaidPlan={!!canUseAgeGroups}
                                                                    isDisabled={hasSubmitted}
                                                                />
                                                            </Box>
                                                            <Box
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        handleClickOnNext();
                                                                    }}
                                                                    rightIcon={
                                                                        <Icon as={ArrowRightIcon} />
                                                                    }
                                                                    isDisabled={isFormInvalid}
                                                                    type="button"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    isLoading={
                                                                        isCreatingDraftCampaign
                                                                    }
                                                                >
                                                                    Next
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                )}
                                            </TabPanel>
                                            <TabPanel p={0}>
                                                <TableEditingSectionBanner
                                                    onClick={() => onFileDropModalOpen()}
                                                />
                                                <Box
                                                    p="3rem"
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap="2rem"
                                                >
                                                    <Box position="relative">
                                                        <CampaignCreationTable
                                                            removeInvalidTableError={
                                                                removeInvalidTableError
                                                            }
                                                            tableData={tableData}
                                                            setTableData={setTableData}
                                                            subTableHasError={subTableHasError}
                                                            setSubTableHasError={
                                                                setSubTableHasError
                                                            }
                                                            hasSubmitted={hasSubmitted}
                                                        />
                                                        <FileDrop
                                                            onDrop={(files, events) => {
                                                                if (files && files.length > 0) {
                                                                    convertTemplateToDTO(files[0])
                                                                        .then((response) => {
                                                                            emitPosthogEvent();
                                                                            setValidTemplate(
                                                                                response,
                                                                            );
                                                                            if (!response) {
                                                                                errorToast(
                                                                                    t(
                                                                                        'mediaPlanning.campaignCreation.errorMessages.fileDropFailed.title',
                                                                                    ),
                                                                                    t(
                                                                                        'mediaPlanning.campaignCreation.errorMessages.fileDropFailed.description',
                                                                                    ),
                                                                                );
                                                                                return;
                                                                            }
                                                                            convertTemplateDataToTableData(
                                                                                response,
                                                                            );
                                                                            successToast(
                                                                                t(
                                                                                    'mediaPlanning.campaignCreation.successMessages.fileDropSucceeded.title',
                                                                                ),
                                                                                t(
                                                                                    'mediaPlanning.campaignCreation.successMessages.fileDropSucceeded.description',
                                                                                ),
                                                                            );
                                                                        })
                                                                        .catch((err) =>
                                                                            errorToast(
                                                                                t(
                                                                                    'mediaPlanning.campaignCreation.errorMessages.fileDropFailed.title',
                                                                                ),
                                                                                t(
                                                                                    'mediaPlanning.campaignCreation.errorMessages.fileDropFailed.description',
                                                                                ),
                                                                            ),
                                                                        );
                                                                }
                                                            }}
                                                        >
                                                            <Box
                                                                border="0.5rem dashed #d1d5db"
                                                                height="100%"
                                                                display="grid"
                                                                placeItems="center"
                                                                borderRadius="1.5rem"
                                                            >
                                                                <Text
                                                                    fontSize={48}
                                                                    color="#d1d5db"
                                                                    fontWeight={500}
                                                                >
                                                                    Drop file here
                                                                </Text>
                                                            </Box>
                                                        </FileDrop>
                                                    </Box>
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setTabIndex((old) => old - 1);
                                                            }}
                                                            variant="outline"
                                                            leftIcon={<Icon as={ArrowLeftIcon} />}
                                                            type="button"
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            rightIcon={<Icon as={ArrowRightIcon} />}
                                                            isDisabled={
                                                                isFormInvalid ||
                                                                subTableHasError.length > 0 ||
                                                                hasSubmitted
                                                            }
                                                            isLoading={isSubmitting}
                                                            onClick={(e) => {
                                                                if (!isTableValid(tableData)) {
                                                                    addInvalidTableError();
                                                                    e.preventDefault();
                                                                } else {
                                                                    onCreateCampaignDialogOpen();
                                                                }
                                                            }}
                                                        >
                                                            Create media plan
                                                        </Button>
                                                        <Button
                                                            ref={refSubmitButton}
                                                            hidden
                                                            type="submit"
                                                        >
                                                            submit
                                                        </Button>
                                                    </Flex>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel p={0}>
                                                <Box
                                                    p="3rem"
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap="2rem"
                                                >
                                                    <Box marginLeft="1rem">
                                                        <CollapsibleComponent headingText="Overview">
                                                            <Grid
                                                                templateColumns="repeat(2, 1fr)"
                                                                gap={1}
                                                                mt={3}
                                                            >
                                                                <GridItem>
                                                                    <strong>Client:</strong>{' '}
                                                                    {organisation.organisationName}
                                                                </GridItem>
                                                                <GridItem>
                                                                    <strong>Start date:</strong>{' '}
                                                                    {createdCampaignConstraint?.startDate?.toLocaleDateString()}
                                                                </GridItem>
                                                                <GridItem>
                                                                    <strong>Total budget:</strong>{' '}
                                                                    {`$${createdCampaignConstraint?.totalBudget?.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        },
                                                                    )}`}
                                                                </GridItem>
                                                                <GridItem>
                                                                    <strong>End date:</strong>{' '}
                                                                    {createdCampaignConstraint?.endDate?.toLocaleDateString()}
                                                                </GridItem>
                                                            </Grid>
                                                        </CollapsibleComponent>
                                                    </Box>
                                                    {createdMediaPlanId &&
                                                        baseScenarioId &&
                                                        scenarioLevelResultsComparison && (
                                                            <ScenarioViewSection
                                                                scenarioLevelResultsComparison={
                                                                    scenarioLevelResultsComparison
                                                                }
                                                                reachCurveData={reachCurveData}
                                                                setReachCurveData={
                                                                    setReachCurveData
                                                                }
                                                                campaignId={createdMediaPlanId}
                                                                scenarioId={baseScenarioId}
                                                            />
                                                        )}
                                                    <Flex justifyContent="space-between">
                                                        <Button
                                                            variant="outline"
                                                            onClick={() => setTabIndex(1)}
                                                            leftIcon={<Icon as={ArrowLeftIcon} />}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                navigate(
                                                                    `/${routing.plan.root.path}/${createdMediaPlanId}`,
                                                                );
                                                            }}
                                                            rightIcon={<Icon as={ArrowRightIcon} />}
                                                        >
                                                            Generate optimisation
                                                        </Button>
                                                    </Flex>
                                                </Box>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </form>
                                <ImportFileModal
                                    onClose={onFileDropModalClose}
                                    onConfirm={onFileDropModalConfirm}
                                    isOpen={isFileDropModalOpen}
                                    acceptedFileTypes={acceptedFileTypes}
                                    onFileUpload={onFileUpload}
                                    templateUrl={importTemplate.fileUrl}
                                    templateType="attentionPLAN"
                                    tabBannerHeadingText="Download our template and upload it here"
                                    tabBannerBodyText="Importing data from a file will override your current media plan. Please review carefully before clicking confirm."
                                />
                            </Flex>
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Container>
            ) : (
                <Container>
                    <NoCampaignsLeftCard />
                </Container>
            )}
        </>
    ) : (
        <Loading />
    );
};
