import { digitalOnboarding } from '@configs/i18n/languages/en/translation-modules/digital-onboarding';
import {
    authMessages,
    dialogsLabels,
    formLabels,
    formValidationMessages,
    inviteMessages,
    pages,
    removeMessages,
    sectionLabels,
    tableLabels,
    updateMessages,
} from './translation-modules/account-manager';
import { navigation } from './translation-modules/navigation';
import { mediaPlanning } from './translation-modules/mediaPlanning';
import { campaign } from './translation-modules/campaign';
import { prove } from './translation-modules/prove';
import { freemium } from './translation-modules/freemium';
import { generic } from './translation-modules/generic/generic-messages';
import { errors } from './translation-modules/errors';
import { attentionBenchmarks } from './translation-modules/attention-benchmarks/attentionBenchmarks';
import { directMediaInput } from './translation-modules/direct-media-input';

export const resources_EN = {
    accountManager: {
        welcome: 'Hello', // example
        labels: {
            forms: formLabels,
            dialogs: dialogsLabels,
            sections: sectionLabels,
            tables: tableLabels,
        },
        messages: {
            forms: {
                validation: formValidationMessages,
            },
            toast: {
                auth: authMessages,
                invite: inviteMessages,
                update: updateMessages,
                remove: removeMessages,
            },
        },
        pages,
    },
    mediaPlans: {
        mediaPlanning,
    },
    campaign: {
        campaign,
    },
    prove: {
        prove,
    },
    benchmark: {
        attentionBenchmarks,
    },
    digitalOnboarding,
    navigation,
    generic,
    errors,
    freemium,
    directMediaInput,
};
