import {
    ChannelLevelCurvesResponseDto,
    ListAllReachCurvesRelatingToASpecificScenarioRequest,
} from '@api-clients/media-plan';
import { useHelper } from '@shared/utils';
import {
    ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
    PublicReachCurveApi,
} from '@api-clients/media-plan/apis/PublicReachCurveApi';
import { useApiConfiguration } from '@hooks/configuration';

type useGetSharedScenarioReachCurvesHook = () => {
    getSharedScenarioReachCurves: (
        request: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
    ) => Promise<Array<ChannelLevelCurvesResponseDto>>;
};

export const useGetSharedScenarioReachCurves: useGetSharedScenarioReachCurvesHook = () => {
    const { getDisplayedErrorMessage } = useHelper();
    const { getMediaPlanConfigWithoutCredentials } = useApiConfiguration();

    async function getSharedScenarioReachCurves(
        request: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
    ) {
        try {
            const config = await getMediaPlanConfigWithoutCredentials();
            const api = new PublicReachCurveApi(config);
            return await api.listAllReachCurvesRelatingToASharedScenarioBySharedLinkId(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getSharedScenarioReachCurves };
};
