/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const CountryCodeIso2 = {
    Af: 'af',
    Al: 'al',
    Dz: 'dz',
    As: 'as',
    Ad: 'ad',
    Ao: 'ao',
    Ai: 'ai',
    Aq: 'aq',
    Ag: 'ag',
    Ar: 'ar',
    Am: 'am',
    Aw: 'aw',
    Au: 'au',
    At: 'at',
    Ax: 'ax',
    Az: 'az',
    Bs: 'bs',
    Bh: 'bh',
    Bd: 'bd',
    Bb: 'bb',
    By: 'by',
    Be: 'be',
    Bq: 'bq',
    Bv: 'bv',
    Bz: 'bz',
    Bj: 'bj',
    Bm: 'bm',
    Bt: 'bt',
    Bo: 'bo',
    Ba: 'ba',
    Bw: 'bw',
    Br: 'br',
    Io: 'io',
    Vg: 'vg',
    Bn: 'bn',
    Bg: 'bg',
    Bf: 'bf',
    Bi: 'bi',
    Kh: 'kh',
    Cm: 'cm',
    Ca: 'ca',
    Cv: 'cv',
    Ky: 'ky',
    Cf: 'cf',
    Td: 'td',
    Tf: 'tf',
    Cl: 'cl',
    Cn: 'cn',
    Cx: 'cx',
    Cc: 'cc',
    Co: 'co',
    Km: 'km',
    Ck: 'ck',
    Cr: 'cr',
    Hr: 'hr',
    Cu: 'cu',
    Cw: 'cw',
    Cy: 'cy',
    Cz: 'cz',
    Cd: 'cd',
    Dk: 'dk',
    Dj: 'dj',
    Dm: 'dm',
    Do: 'do',
    Tl: 'tl',
    Ec: 'ec',
    Eg: 'eg',
    Sv: 'sv',
    Gq: 'gq',
    Er: 'er',
    Ee: 'ee',
    Et: 'et',
    Fk: 'fk',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Pf: 'pf',
    Ga: 'ga',
    Gm: 'gm',
    Ge: 'ge',
    Gf: 'gf',
    Gp: 'gp',
    Gs: 'gs',
    De: 'de',
    Gh: 'gh',
    Gi: 'gi',
    Gr: 'gr',
    Gl: 'gl',
    Gd: 'gd',
    Gu: 'gu',
    Gt: 'gt',
    Gg: 'gg',
    Gn: 'gn',
    Gw: 'gw',
    Gy: 'gy',
    Ht: 'ht',
    Hn: 'hn',
    Hk: 'hk',
    Hm: 'hm',
    Hu: 'hu',
    Is: 'is',
    In: 'in',
    Id: 'id',
    Ir: 'ir',
    Iq: 'iq',
    Ie: 'ie',
    Im: 'im',
    Il: 'il',
    It: 'it',
    Ci: 'ci',
    Jm: 'jm',
    Jp: 'jp',
    Je: 'je',
    Jo: 'jo',
    Kz: 'kz',
    Ke: 'ke',
    Ki: 'ki',
    Xk: 'xk',
    Kw: 'kw',
    Kg: 'kg',
    La: 'la',
    Lv: 'lv',
    Lb: 'lb',
    Ls: 'ls',
    Lr: 'lr',
    Ly: 'ly',
    Li: 'li',
    Lt: 'lt',
    Lu: 'lu',
    Mo: 'mo',
    Mk: 'mk',
    Mg: 'mg',
    Mw: 'mw',
    My: 'my',
    Mv: 'mv',
    Ml: 'ml',
    Mt: 'mt',
    Mh: 'mh',
    Mr: 'mr',
    Mu: 'mu',
    Yt: 'yt',
    Mx: 'mx',
    Fm: 'fm',
    Md: 'md',
    Mc: 'mc',
    Mn: 'mn',
    Me: 'me',
    Ms: 'ms',
    Ma: 'ma',
    Mz: 'mz',
    Mm: 'mm',
    Na: 'na',
    Nf: 'nf',
    Nr: 'nr',
    Np: 'np',
    Nl: 'nl',
    An: 'an',
    Nc: 'nc',
    Nz: 'nz',
    Ni: 'ni',
    Ne: 'ne',
    Ng: 'ng',
    Nu: 'nu',
    Kp: 'kp',
    Mp: 'mp',
    Mq: 'mq',
    No: 'no',
    Om: 'om',
    Pk: 'pk',
    Pw: 'pw',
    Ps: 'ps',
    Pa: 'pa',
    Pg: 'pg',
    Py: 'py',
    Pe: 'pe',
    Ph: 'ph',
    Pn: 'pn',
    Pl: 'pl',
    Pt: 'pt',
    Pr: 'pr',
    Qa: 'qa',
    Cg: 'cg',
    Re: 're',
    Ro: 'ro',
    Ru: 'ru',
    Rw: 'rw',
    Bl: 'bl',
    Sh: 'sh',
    Kn: 'kn',
    Lc: 'lc',
    Mf: 'mf',
    Pm: 'pm',
    Vc: 'vc',
    Ws: 'ws',
    Sm: 'sm',
    St: 'st',
    Sa: 'sa',
    Sn: 'sn',
    Rs: 'rs',
    Sc: 'sc',
    Sl: 'sl',
    Sg: 'sg',
    Sx: 'sx',
    Sk: 'sk',
    Si: 'si',
    Sb: 'sb',
    So: 'so',
    Za: 'za',
    Kr: 'kr',
    Ss: 'ss',
    Es: 'es',
    Lk: 'lk',
    Sd: 'sd',
    Sr: 'sr',
    Sj: 'sj',
    Sz: 'sz',
    Se: 'se',
    Ch: 'ch',
    Sy: 'sy',
    Tw: 'tw',
    Tj: 'tj',
    Tz: 'tz',
    Th: 'th',
    Tg: 'tg',
    Tk: 'tk',
    To: 'to',
    Tt: 'tt',
    Tn: 'tn',
    Tr: 'tr',
    Tm: 'tm',
    Tc: 'tc',
    Tv: 'tv',
    Vi: 'vi',
    Ug: 'ug',
    Um: 'um',
    Ua: 'ua',
    Ae: 'ae',
    Gb: 'gb',
    Us: 'us',
    Uy: 'uy',
    Uz: 'uz',
    Vu: 'vu',
    Va: 'va',
    Ve: 've',
    Vn: 'vn',
    Wf: 'wf',
    Eh: 'eh',
    Ye: 'ye',
    Zm: 'zm',
    Zw: 'zw',
} as const;
export type CountryCodeIso2 = typeof CountryCodeIso2[keyof typeof CountryCodeIso2];

export function CountryCodeIso2FromJSON(json: any): CountryCodeIso2 {
    return CountryCodeIso2FromJSONTyped(json, false);
}

export function CountryCodeIso2FromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CountryCodeIso2 {
    return json as CountryCodeIso2;
}

export function CountryCodeIso2ToJSON(value?: CountryCodeIso2 | null): any {
    return value as any;
}
