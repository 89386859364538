/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrencyCode = {
    Usd: 'usd',
    Aud: 'aud',
    Gbp: 'gbp',
    Eur: 'eur',
    Aed: 'aed',
    Afn: 'afn',
    All: 'all',
    Amd: 'amd',
    Ang: 'ang',
    Aoa: 'aoa',
    Ars: 'ars',
    Awg: 'awg',
    Azn: 'azn',
    Bam: 'bam',
    Bbd: 'bbd',
    Bdt: 'bdt',
    Bgn: 'bgn',
    Bhd: 'bhd',
    Bif: 'bif',
    Bmd: 'bmd',
    Bnd: 'bnd',
    Bob: 'bob',
    Bov: 'bov',
    Brl: 'brl',
    Bsd: 'bsd',
    Btn: 'btn',
    Bwp: 'bwp',
    Byn: 'byn',
    Bzd: 'bzd',
    Cad: 'cad',
    Cdf: 'cdf',
    Che: 'che',
    Chf: 'chf',
    Chw: 'chw',
    Clf: 'clf',
    Clp: 'clp',
    Cny: 'cny',
    Cop: 'cop',
    Cou: 'cou',
    Crc: 'crc',
    Cuc: 'cuc',
    Cup: 'cup',
    Cve: 'cve',
    Czk: 'czk',
    Djf: 'djf',
    Dkk: 'dkk',
    Dop: 'dop',
    Dzd: 'dzd',
    Egp: 'egp',
    Ern: 'ern',
    Etb: 'etb',
    Fjd: 'fjd',
    Fkp: 'fkp',
    Gel: 'gel',
    Ghs: 'ghs',
    Gip: 'gip',
    Gmd: 'gmd',
    Gnf: 'gnf',
    Gtq: 'gtq',
    Gyd: 'gyd',
    Hkd: 'hkd',
    Hnl: 'hnl',
    Hrk: 'hrk',
    Htg: 'htg',
    Huf: 'huf',
    Idr: 'idr',
    Ils: 'ils',
    Inr: 'inr',
    Iqd: 'iqd',
    Irr: 'irr',
    Isk: 'isk',
    Jmd: 'jmd',
    Jod: 'jod',
    Jpy: 'jpy',
    Kes: 'kes',
    Kgs: 'kgs',
    Khr: 'khr',
    Kmf: 'kmf',
    Kpw: 'kpw',
    Krw: 'krw',
    Kwd: 'kwd',
    Kyd: 'kyd',
    Kzt: 'kzt',
    Lak: 'lak',
    Lbp: 'lbp',
    Lkr: 'lkr',
    Lrd: 'lrd',
    Lsl: 'lsl',
    Lyd: 'lyd',
    Mad: 'mad',
    Mdl: 'mdl',
    Mga: 'mga',
    Mkd: 'mkd',
    Mmk: 'mmk',
    Mnt: 'mnt',
    Mop: 'mop',
    Mru: 'mru',
    Mur: 'mur',
    Mvr: 'mvr',
    Mwk: 'mwk',
    Mxn: 'mxn',
    Mxv: 'mxv',
    Myr: 'myr',
    Mzn: 'mzn',
    Nad: 'nad',
    Ngn: 'ngn',
    Nio: 'nio',
    Nok: 'nok',
    Npr: 'npr',
    Nzd: 'nzd',
    Omr: 'omr',
    Pab: 'pab',
    Pen: 'pen',
    Pgk: 'pgk',
    Php: 'php',
    Pkr: 'pkr',
    Pln: 'pln',
    Pyg: 'pyg',
    Qar: 'qar',
    Ron: 'ron',
    Rsd: 'rsd',
    Rub: 'rub',
    Rwf: 'rwf',
    Sar: 'sar',
    Sbd: 'sbd',
    Scr: 'scr',
    Sdg: 'sdg',
    Sek: 'sek',
    Sgd: 'sgd',
    Shp: 'shp',
    Sle: 'sle',
    Sos: 'sos',
    Srd: 'srd',
    Ssp: 'ssp',
    Stn: 'stn',
    Svc: 'svc',
    Syp: 'syp',
    Szl: 'szl',
    Thb: 'thb',
    Tjs: 'tjs',
    Tmt: 'tmt',
    Tnd: 'tnd',
    Top: 'top',
    Try: 'try',
    Ttd: 'ttd',
    Twd: 'twd',
    Tzs: 'tzs',
    Uah: 'uah',
    Ugx: 'ugx',
    Usn: 'usn',
    Uyi: 'uyi',
    Uyu: 'uyu',
    Uzs: 'uzs',
    Ved: 'ved',
    Vef: 'vef',
    Vnd: 'vnd',
    Vuv: 'vuv',
    Wst: 'wst',
    Xaf: 'xaf',
    Xcd: 'xcd',
    Xdr: 'xdr',
    Xof: 'xof',
    Xpf: 'xpf',
    Xsu: 'xsu',
    Xua: 'xua',
    Yer: 'yer',
    Zar: 'zar',
    Zmw: 'zmw',
    Zwl: 'zwl'
} as const;
export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


export function CurrencyCodeFromJSON(json: any): CurrencyCode {
    return CurrencyCodeFromJSONTyped(json, false);
}

export function CurrencyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyCode {
    return json as CurrencyCode;
}

export function CurrencyCodeToJSON(value?: CurrencyCode | null): any {
    return value as any;
}

