const AllCountries = [
    { value: 'AU', label: 'Australia', code: '61' },
    { value: 'AF', label: 'Afghanistan', code: '93' },
    { value: 'AX', label: 'Åland Islands', code: '358-18' },
    { value: 'AL', label: 'Albania', code: '355' },
    { value: 'DZ', label: 'Algeria', code: '213' },
    { value: 'AS', label: 'American Samoa', code: '1-684' },
    { value: 'AD', label: 'Andorra', code: '376' },
    { value: 'AO', label: 'Angola', code: '244' },
    { value: 'AI', label: 'Anguilla', code: '1-264' },
    { value: 'AG', label: 'Antigua and Barbuda', code: '1-268' },
    { value: 'AR', label: 'Argentina', code: '54' },
    { value: 'AM', label: 'Armenia', code: '374' },
    { value: 'AW', label: 'Aruba', code: '297' },
    { value: 'AT', label: 'Austria', code: '43' },
    { value: 'AZ', label: 'Azerbaijan', code: '994' },
    { value: 'BS', label: 'Bahamas', code: '1-242' },
    { value: 'BH', label: 'Bahrain', code: '973' },
    { value: 'BD', label: 'Bangladesh', code: '880' },
    { value: 'BB', label: 'Barbados', code: '1-246' },
    { value: 'BY', label: 'Belarus', code: '375' },
    { value: 'BE', label: 'Belgium', code: '32' },
    { value: 'BZ', label: 'Belize', code: '501' },
    { value: 'BJ', label: 'Benin', code: '229' },
    { value: 'BM', label: 'Bermuda', code: '1-441' },
    { value: 'BT', label: 'Bhutan', code: '975' },
    { value: 'BO', label: 'Bolivia, Plurinational State of', code: '591' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba', code: '599' },
    { value: 'BA', label: 'Bosnia and Herzegovina', code: '387' },
    { value: 'BW', label: 'Botswana', code: '267' },
    { value: 'BR', label: 'Brazil', code: '55' },
    { value: 'IO', label: 'British Indian Ocean Territory', code: '246' },
    { value: 'BN', label: 'Brunei Darussalam', code: '673' },
    { value: 'BG', label: 'Bulgaria', code: '359' },
    { value: 'BF', label: 'Burkina Faso', code: '226' },
    { value: 'BI', label: 'Burundi', code: '257' },
    { value: 'KH', label: 'Cambodia', code: '855' },
    { value: 'CM', label: 'Cameroon', code: '237' },
    { value: 'CA', label: 'Canada', code: '1' },
    { value: 'CV', label: 'Cape Verde', code: '238' },
    { value: 'KY', label: 'Cayman Islands', code: '1-345' },
    { value: 'CF', label: 'Central African Republic', code: '236' },
    { value: 'TD', label: 'Chad', code: '235' },
    { value: 'CL', label: 'Chile', code: '56' },
    { value: 'CN', label: 'China', code: '86' },
    { value: 'CX', label: 'Christmas Island', code: '61' },
    { value: 'CC', label: 'Cocos (Keeling) Islands', code: '61' },
    { value: 'CO', label: 'Colombia', code: '57' },
    { value: 'KM', label: 'Comoros', code: '269' },
    { value: 'CG', label: 'Congo', code: '242' },
    { value: 'CD', label: 'Congo, the Democratic Republic of the', code: '243' },
    { value: 'CK', label: 'Cook Islands', code: '682' },
    { value: 'CR', label: 'Costa Rica', code: '506' },
    { value: 'CI', label: "Côte d'Ivoire", code: '225' },
    { value: 'HR', label: 'Croatia', code: '385' },
    { value: 'CU', label: 'Cuba', code: '53' },
    { value: 'CW', label: 'Curaçao', code: '599' },
    { value: 'CY', label: 'Cyprus', code: '357' },
    { value: 'CZ', label: 'Czech Republic', code: '420' },
    { value: 'DK', label: 'Denmark', code: '45' },
    { value: 'DJ', label: 'Djibouti', code: '253' },
    { value: 'DM', label: 'Dominica', code: '1-767' },
    { value: 'DO', label: 'Dominican Republic', code: '1' },
    { value: 'EC', label: 'Ecuador', code: '593' },
    { value: 'EG', label: 'Egypt', code: '20' },
    { value: 'SV', label: 'El Salvador', code: '503' },
    { value: 'GQ', label: 'Equatorial Guinea', code: '240' },
    { value: 'ER', label: 'Eritrea', code: '291' },
    { value: 'EE', label: 'Estonia', code: '372' },
    { value: 'ET', label: 'Ethiopia', code: '251' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)', code: '500' },
    { value: 'FO', label: 'Faroe Islands', code: '298' },
    { value: 'FJ', label: 'Fiji', code: '679' },
    { value: 'FI', label: 'Finland', code: '358' },
    { value: 'FR', label: 'France', code: '33' },
    { value: 'GF', label: 'French Guiana', code: '594' },
    { value: 'PF', label: 'French Polynesia', code: '689' },
    { value: 'GA', label: 'Gabon', code: '241' },
    { value: 'GM', label: 'Gambia', code: '220' },
    { value: 'GE', label: 'Georgia', code: '995' },
    { value: 'DE', label: 'Germany', code: '49' },
    { value: 'GH', label: 'Ghana', code: '233' },
    { value: 'GI', label: 'Gibraltar', code: '350' },
    { value: 'GR', label: 'Greece', code: '30' },
    { value: 'GL', label: 'Greenland', code: '299' },
    { value: 'GD', label: 'Grenada', code: '1-473' },
    { value: 'GP', label: 'Guadeloupe', code: '590' },
    { value: 'GU', label: 'Guam', code: '1-671' },
    { value: 'GT', label: 'Guatemala', code: '502' },
    { value: 'GG', label: 'Guernsey', code: '44-1481' },
    { value: 'GN', label: 'Guinea', code: '224' },
    { value: 'GW', label: 'Guinea-Bissau', code: '245' },
    { value: 'GY', label: 'Guyana', code: '592' },
    { value: 'HT', label: 'Haiti', code: '509' },
    { value: 'VA', label: 'Holy See (Vatican City State)', code: '379' },
    { value: 'HN', label: 'Honduras', code: '504' },
    { value: 'HK', label: 'Hong Kong', code: '852' },
    { value: 'HU', label: 'Hungary', code: '36' },
    { value: 'IS', label: 'Iceland', code: '354' },
    { value: 'IN', label: 'India', code: '91' },
    { value: 'ID', label: 'Indonesia', code: '62' },
    { value: 'IR', label: 'Iran, Islamic Republic of', code: '98' },
    { value: 'IQ', label: 'Iraq', code: '964' },
    { value: 'IE', label: 'Ireland', code: '353' },
    { value: 'IM', label: 'Isle of Man', code: '44-1624' },
    { value: 'IL', label: 'Israel', code: '972' },
    { value: 'IT', label: 'Italy', code: '39' },
    { value: 'JM', label: 'Jamaica', code: '1-876' },
    { value: 'JP', label: 'Japan', code: '81' },
    { value: 'JE', label: 'Jersey', code: '44-1534' },
    { value: 'JO', label: 'Jordan', code: '962' },
    { value: 'KZ', label: 'Kazakhstan', code: '7' },
    { value: 'KE', label: 'Kenya', code: '254' },
    { value: 'KI', label: 'Kiribati', code: '686' },
    { value: 'KP', label: "Korea, Democratic People's Republic of", code: '850' },
    { value: 'KR', label: 'Korea, Republic of', code: '82' },
    { value: 'KW', label: 'Kuwait', code: '965' },
    { value: 'KG', label: 'Kyrgyzstan', code: '996' },
    { value: 'LA', label: "Lao People's Democratic Republic", code: '856' },
    { value: 'LV', label: 'Latvia', code: '371' },
    { value: 'LB', label: 'Lebanon', code: '961' },
    { value: 'LS', label: 'Lesotho', code: '266' },
    { value: 'LR', label: 'Liberia', code: '231' },
    { value: 'LY', label: 'Libya', code: '218' },
    { value: 'LI', label: 'Liechtenstein', code: '423' },
    { value: 'LT', label: 'Lithuania', code: '370' },
    { value: 'LU', label: 'Luxembourg', code: '352' },
    { value: 'MO', label: 'Macao', code: '853' },
    { value: 'MK', label: 'Macedonia, the former Yugoslav Republic of', code: '389' },
    { value: 'MG', label: 'Madagascar', code: '261' },
    { value: 'MW', label: 'Malawi', code: '265' },
    { value: 'MY', label: 'Malaysia', code: '60' },
    { value: 'MV', label: 'Maldives', code: '960' },
    { value: 'ML', label: 'Mali', code: '223' },
    { value: 'MT', label: 'Malta', code: '356' },
    { value: 'MH', label: 'Marshall Islands', code: '692' },
    { value: 'MQ', label: 'Martinique', code: '596' },
    { value: 'MR', label: 'Mauritania', code: '222' },
    { value: 'MU', label: 'Mauritius', code: '230' },
    { value: 'YT', label: 'Mayotte', code: '262' },
    { value: 'MX', label: 'Mexico', code: '52' },
    { value: 'FM', label: 'Micronesia, Federated States of', code: '691' },
    { value: 'MD', label: 'Moldova, Republic of', code: '373' },
    { value: 'MC', label: 'Monaco', code: '377' },
    { value: 'MN', label: 'Mongolia', code: '976' },
    { value: 'ME', label: 'Montenegro', code: '382' },
    { value: 'MS', label: 'Montserrat', code: '1-664' },
    { value: 'MA', label: 'Morocco', code: '212' },
    { value: 'MZ', label: 'Mozambique', code: '258' },
    { value: 'MM', label: 'Myanmar', code: '95' },
    { value: 'NA', label: 'Namibia', code: '264' },
    { value: 'NR', label: 'Nauru', code: '674' },
    { value: 'NP', label: 'Nepal', code: '977' },
    { value: 'NL', label: 'Netherlands', code: '31' },
    { value: 'NC', label: 'New Caledonia', code: '687' },
    { value: 'NZ', label: 'New Zealand', code: '64' },
    { value: 'NI', label: 'Nicaragua', code: '505' },
    { value: 'NE', label: 'Niger', code: '227' },
    { value: 'NG', label: 'Nigeria', code: '234' },
    { value: 'NU', label: 'Niue', code: '683' },
    { value: 'NF', label: 'Norfolk Island', code: '672' },
    { value: 'MP', label: 'Northern Mariana Islands', code: '1-670' },
    { value: 'NO', label: 'Norway', code: '47' },
    { value: 'OM', label: 'Oman', code: '968' },
    { value: 'PK', label: 'Pakistan', code: '92' },
    { value: 'PW', label: 'Palau', code: '680' },
    { value: 'PS', label: 'Palestinian Territory, Occupied', code: '970' },
    { value: 'PA', label: 'Panama', code: '507' },
    { value: 'PG', label: 'Papua New Guinea', code: '675' },
    { value: 'PY', label: 'Paraguay', code: '595' },
    { value: 'PE', label: 'Peru', code: '51' },
    { value: 'PH', label: 'Philippines', code: '63' },
    { value: 'PN', label: 'Pitcairn', code: '870' },
    { value: 'PL', label: 'Poland', code: '48' },
    { value: 'PT', label: 'Portugal', code: '351' },
    { value: 'PR', label: 'Puerto Rico', code: '1' },
    { value: 'QA', label: 'Qatar', code: '974' },
    { value: 'RE', label: 'Réunion', code: '262' },
    { value: 'RO', label: 'Romania', code: '40' },
    { value: 'RU', label: 'Russian Federation', code: '7' },
    { value: 'RW', label: 'Rwanda', code: '250' },
    { value: 'BL', label: 'Saint Barthélemy', code: '590' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', code: '290' },
    { value: 'KN', label: 'Saint Kitts and Nevis', code: '1-869' },
    { value: 'LC', label: 'Saint Lucia', code: '1-758' },
    { value: 'MF', label: 'Saint Martin (French part)', code: '590' },
    { value: 'PM', label: 'Saint Pierre and Miquelon', code: '508' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', code: '1-784' },
    { value: 'WS', label: 'Samoa', code: '685' },
    { value: 'SM', label: 'San Marino', code: '378' },
    { value: 'ST', label: 'Sao Tome and Principe', code: '239' },
    { value: 'SA', label: 'Saudi Arabia', code: '966' },
    { value: 'SN', label: 'Senegal', code: '221' },
    { value: 'RS', label: 'Serbia', code: '381' },
    { value: 'SC', label: 'Seychelles', code: '248' },
    { value: 'SL', label: 'Sierra Leone', code: '232' },
    { value: 'SG', label: 'Singapore', code: '65' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)', code: '599' },
    { value: 'SK', label: 'Slovakia', code: '421' },
    { value: 'SI', label: 'Slovenia', code: '386' },
    { value: 'SB', label: 'Solomon Islands', code: '677' },
    { value: 'SO', label: 'Somalia', code: '252' },
    { value: 'ZA', label: 'South Africa', code: '27' },
    { value: 'SS', label: 'South Sudan', code: '211' },
    { value: 'ES', label: 'Spain', code: '34' },
    { value: 'LK', label: 'Sri Lanka', code: '94' },
    { value: 'SD', label: 'Sudan', code: '249' },
    { value: 'SR', label: 'Suriname', code: '597' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', code: '47' },
    { value: 'SZ', label: 'Swaziland', code: '268' },
    { value: 'SE', label: 'Sweden', code: '46' },
    { value: 'CH', label: 'Switzerland', code: '41' },
    { value: 'SY', label: 'Syrian Arab Republic', code: '963' },
    { value: 'TW', label: 'Taiwan, Province of China', code: '886' },
    { value: 'TJ', label: 'Tajikistan', code: '992' },
    { value: 'TZ', label: 'Tanzania, United Republic of', code: '255' },
    { value: 'TH', label: 'Thailand', code: '66' },
    { value: 'TL', label: 'Timor-Leste', code: '670' },
    { value: 'TG', label: 'Togo', code: '228' },
    { value: 'TK', label: 'Tokelau', code: '690' },
    { value: 'TO', label: 'Tonga', code: '676' },
    { value: 'TT', label: 'Trinidad and Tobago', code: '1-868' },
    { value: 'TN', label: 'Tunisia', code: '216' },
    { value: 'TR', label: 'Turkey', code: '90' },
    { value: 'TM', label: 'Turkmenistan', code: '993' },
    { value: 'TC', label: 'Turks and Caicos Islands', code: '1-649' },
    { value: 'TV', label: 'Tuvalu', code: '688' },
    { value: 'UG', label: 'Uganda', code: '256' },
    { value: 'UA', label: 'Ukraine', code: '380' },
    { value: 'AE', label: 'United Arab Emirates', code: '971' },
    { value: 'GB', label: 'United Kingdom', code: '44' },
    { value: 'US', label: 'United States', code: '1' },
    { value: 'UM', label: 'United States Minor Outlying Islands', code: '1' },
    { value: 'UY', label: 'Uruguay', code: '598' },
    { value: 'UZ', label: 'Uzbekistan', code: '998' },
    { value: 'VU', label: 'Vanuatu', code: '678' },
    { value: 'VE', label: 'Venezuela, Bolivarian Republic of', code: '58' },
    { value: 'VN', label: 'Viet Nam', code: '84' },
    { value: 'VG', label: 'Virgin Islands, British', code: '1-284' },
    { value: 'VI', label: 'Virgin Islands, U.S.', code: '1-340' },
    { value: 'WF', label: 'Wallis and Futuna', code: '681' },
    { value: 'EH', label: 'Western Sahara', code: '212' },
    { value: 'YE', label: 'Yemen', code: '967' },
    { value: 'ZM', label: 'Zambia', code: '260' },
    { value: 'ZW', label: 'Zimbabwe', code: '263' },
];

export default AllCountries;
