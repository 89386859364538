/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface AdFormatResponseDto
 */
export interface AdFormatResponseDto {
    /**
     *
     * @type {string}
     * @memberof AdFormatResponseDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdFormatResponseDto
     */
    adFormatName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdFormatResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdFormatResponseDto
     */
    channelCode?: string | null;
}

/**
 * Check if a given object implements the AdFormatResponseDto interface.
 */
export function instanceOfAdFormatResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdFormatResponseDtoFromJSON(json: any): AdFormatResponseDto {
    return AdFormatResponseDtoFromJSONTyped(json, false);
}

export function AdFormatResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AdFormatResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        adFormatName: !exists(json, 'ad_format_name') ? undefined : json['ad_format_name'],
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
    };
}

export function AdFormatResponseDtoToJSON(value?: AdFormatResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_code: value.adFormatCode,
        ad_format_name: value.adFormatName,
        channel_name: value.channelName,
        channel_code: value.channelCode,
    };
}
