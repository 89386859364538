/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';

/**
 *
 * @export
 * @interface GlobalCountriesResponseDto
 */
export interface GlobalCountriesResponseDto {
    /**
     *
     * @type {CountryCodeIso2}
     * @memberof GlobalCountriesResponseDto
     */
    countryCode?: CountryCodeIso2;
    /**
     *
     * @type {string}
     * @memberof GlobalCountriesResponseDto
     */
    countryName?: string | null;
}

/**
 * Check if a given object implements the GlobalCountriesResponseDto interface.
 */
export function instanceOfGlobalCountriesResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GlobalCountriesResponseDtoFromJSON(json: any): GlobalCountriesResponseDto {
    return GlobalCountriesResponseDtoFromJSONTyped(json, false);
}

export function GlobalCountriesResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GlobalCountriesResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        countryCode: !exists(json, 'country_code')
            ? undefined
            : CountryCodeIso2FromJSON(json['country_code']),
        countryName: !exists(json, 'country_name') ? undefined : json['country_name'],
    };
}

export function GlobalCountriesResponseDtoToJSON(value?: GlobalCountriesResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        country_code: CountryCodeIso2ToJSON(value.countryCode),
        country_name: value.countryName,
    };
}
