import {
    CampaignApi,
    CampaignBasicInfoResponseDto,
    UpdateDraftCampaignRequest,
    UpdateDraftCampaignRequestDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface UpdateDraftCampaignHook {
    updateDraftCampaign: (
        campaignId: string,
        updateDraftCampaignRequestDto: UpdateDraftCampaignRequestDto,
    ) => Promise<CampaignBasicInfoResponseDto>;
}

type useUpdateDraftCampaignProps = () => UpdateDraftCampaignHook;

export const useUpdateDraftCampaign: useUpdateDraftCampaignProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateDraftCampaign(
        campaignId: string,
        updateDraftCampaignRequestDto: UpdateDraftCampaignRequestDto,
    ): Promise<CampaignBasicInfoResponseDto> {
        try {
            const config = await getMediaPlanConfig();
            const request: UpdateDraftCampaignRequest = {
                campaignId,
                updateDraftCampaignRequestDto,
            };

            const api = new CampaignApi(config);
            return await api.updateDraftCampaign(request);
        } catch (error: any) {
            throw new Error(await getDisplayedErrorMessage(error));
        }
    }

    return {
        updateDraftCampaign,
    };
};
