import { GetScenarioBasicInfoResponseDto, ScenarioApi } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetScenarioBasicInfoHook = () => {
    getScenarioBasicInfo: (
        campaignId: string,
        scenarioId: string,
    ) => Promise<GetScenarioBasicInfoResponseDto>;
};

export const useGetScenarioBasicInfo: useGetScenarioBasicInfoHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    const getScenarioBasicInfo = async (campaignId: string, scenarioId: string) => {
        try {
            const config = await getMediaPlanConfig();
            const api = new ScenarioApi(config);
            return await api.getScenarioBasicInfoById({ campaignId, scenarioId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getScenarioBasicInfo };
};
