import { AdClientResponseDto } from '@api-clients/media-plan';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    Button,
    Center,
    Container,
    Flex,
    SkeletonText,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { AsyncCard, Loading } from '@components/atoms';
import { AddNewClient, Column, DataTable, RemoveClient, Row } from '@components/molecules';
import { useGetAdClients } from '@hooks/adClients/useGetAdClients';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useMemo, useState } from 'react';

interface ClientRow {
    name: string;
    contactPerson: string;
    createdAt: string;
}

export const ManageClientPage: FC = () => {
    const {
        currentContextValues: { user },
    } = useAppContextHelper();

    const { getAdClients } = useGetAdClients();
    const [adClients, setAdClients] = useState<Array<AdClientResponseDto>>();

    const fetchAdClients = () => {
        getAdClients().then(setAdClients);
    };

    useEffect(() => {
        fetchAdClients();
    }, []);

    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();

    const columns: Array<Column<ClientRow>> = [
        {
            title: 'Client name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Primary contact',
            dataIndex: 'contactPerson',
            key: 'contactPerson',
            sorter: (a, b) => a.contactPerson.localeCompare(b.contactPerson),
        },
        {
            title: 'Date created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => b.createdAt.localeCompare(a.createdAt),
        },
        {
            title: 'Action',
            key: 'action',
            textAlign: 'center',
            onCell: () => ({ width: 0, p: 0 }),
            render: (row) => (
                <Center>
                    <RemoveClient
                        onSuccessfulRemove={fetchAdClients}
                        adClientId={row.key}
                        adClientName={row.name}
                    />
                </Center>
            ),
        },
    ];

    const dataSource: Array<Row<ClientRow>> = useMemo(
        () =>
            adClients
                ?.map((client) => {
                    return {
                        key: client.id!,
                        name: client.adClientName ?? '',
                        contactPerson: client.primaryContactName ?? '',
                        createdAt: new Date(client.createdAt!).toLocaleDateString(),
                    };
                })
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt)) ?? [],
        [adClients],
    );

    return (
        <>
            {user ? (
                <Container>
                    <VStack height="100%" align="left" spacing="1rem">
                        <Flex>
                            <ContainerTitle headingText="Manage clients" size="xl" />
                            <Spacer />
                            <AddNewClient
                                onSuccessfulCreate={fetchAdClients}
                                isOpen={isAddOpen}
                                onClose={onAddClose}
                            />
                            <Button size="sm" onClick={onAddOpen}>
                                Add new client
                            </Button>
                        </Flex>
                        <AsyncCard isLoading={!adClients}>
                            <DataTable
                                isPaginated
                                defaultPageSize={10}
                                dataSource={dataSource}
                                columns={columns}
                                noEntriesComponent={<Text>No clients found</Text>}
                            />
                        </AsyncCard>
                    </VStack>
                </Container>
            ) : (
                <Loading />
            )}
        </>
    );
};
