/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { SelectedAdFormatRequestDto } from './SelectedAdFormatRequestDto';
import {
    SelectedAdFormatRequestDtoFromJSON,
    SelectedAdFormatRequestDtoFromJSONTyped,
    SelectedAdFormatRequestDtoToJSON,
} from './SelectedAdFormatRequestDto';

/**
 *
 * @export
 * @interface CreateStrategyReportRequestDto
 */
export interface CreateStrategyReportRequestDto {
    /**
     *
     * @type {string}
     * @memberof CreateStrategyReportRequestDto
     */
    primaryObjective?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateStrategyReportRequestDto
     */
    secondaryObjective?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateStrategyReportRequestDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateStrategyReportRequestDto
     */
    campaignId?: string;
    /**
     *
     * @type {Array<Gender>}
     * @memberof CreateStrategyReportRequestDto
     */
    selectedGenders?: Array<Gender> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof CreateStrategyReportRequestDto
     */
    selectedAgeGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<SelectedAdFormatRequestDto>}
     * @memberof CreateStrategyReportRequestDto
     */
    selectedFormatCodes?: Array<SelectedAdFormatRequestDto> | null;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof CreateStrategyReportRequestDto
     */
    selectedCountryCodes?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {boolean}
     * @memberof CreateStrategyReportRequestDto
     */
    isUsingGlobalCountry?: boolean;
}

/**
 * Check if a given object implements the CreateStrategyReportRequestDto interface.
 */
export function instanceOfCreateStrategyReportRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateStrategyReportRequestDtoFromJSON(json: any): CreateStrategyReportRequestDto {
    return CreateStrategyReportRequestDtoFromJSONTyped(json, false);
}

export function CreateStrategyReportRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateStrategyReportRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        primaryObjective: !exists(json, 'primary_objective')
            ? undefined
            : json['primary_objective'],
        secondaryObjective: !exists(json, 'secondary_objective')
            ? undefined
            : json['secondary_objective'],
        name: !exists(json, 'name') ? undefined : json['name'],
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        selectedGenders: !exists(json, 'selected_genders')
            ? undefined
            : json['selected_genders'] === null
            ? null
            : (json['selected_genders'] as Array<any>).map(GenderFromJSON),
        selectedAgeGroups: !exists(json, 'selected_age_groups')
            ? undefined
            : json['selected_age_groups'] === null
            ? null
            : (json['selected_age_groups'] as Array<any>).map(AgeGroupFromJSON),
        selectedFormatCodes: !exists(json, 'selected_format_codes')
            ? undefined
            : json['selected_format_codes'] === null
            ? null
            : (json['selected_format_codes'] as Array<any>).map(SelectedAdFormatRequestDtoFromJSON),
        selectedCountryCodes: !exists(json, 'selected_country_codes')
            ? undefined
            : json['selected_country_codes'] === null
            ? null
            : (json['selected_country_codes'] as Array<any>).map(CountryCodeIso2FromJSON),
        isUsingGlobalCountry: !exists(json, 'is_using_global_country')
            ? undefined
            : json['is_using_global_country'],
    };
}

export function CreateStrategyReportRequestDtoToJSON(
    value?: CreateStrategyReportRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        primary_objective: value.primaryObjective,
        secondary_objective: value.secondaryObjective,
        name: value.name,
        campaign_id: value.campaignId,
        selected_genders:
            value.selectedGenders === undefined
                ? undefined
                : value.selectedGenders === null
                ? null
                : (value.selectedGenders as Array<any>).map(GenderToJSON),
        selected_age_groups:
            value.selectedAgeGroups === undefined
                ? undefined
                : value.selectedAgeGroups === null
                ? null
                : (value.selectedAgeGroups as Array<any>).map(AgeGroupToJSON),
        selected_format_codes:
            value.selectedFormatCodes === undefined
                ? undefined
                : value.selectedFormatCodes === null
                ? null
                : (value.selectedFormatCodes as Array<any>).map(SelectedAdFormatRequestDtoToJSON),
        selected_country_codes:
            value.selectedCountryCodes === undefined
                ? undefined
                : value.selectedCountryCodes === null
                ? null
                : (value.selectedCountryCodes as Array<any>).map(CountryCodeIso2ToJSON),
        is_using_global_country: value.isUsingGlobalCountry,
    };
}
