export const defaultColorScheme = 'green';

export const colors = {
    colors: {
        orange: {
            DEFAULT: '#E86A3F',
            '50': '#FEF7F4',
            '100': '#FCECE3',
            '200': '#F9D5C2',
            '300': '#F3AF90',
            '400': '#F3AF90',
            '500': '#E86A3F',
            '600': '#BD5633',
            '700': '#914328',
            '800': '#662F1C',
            '900': '#3F1608',
        },
        green: {
            DEFAULT: '#2F6E45',
            '50': '#F5F8F6',
            '100': '#E6EDE8',
            '200': '#C6D8CC',
            '300': '#92B49E',
            '400': '#5F9172',
            '500': '#2F6E45',
            '600': '#005B35',
            '700': '#004629',
            '800': '#00311C',
            '900': '#1C4532',
        },
        navyBlue: {
            DEFAULT: '#254655',
            'amplified': '#264756',
            '50': '#F7F7F8',
            '100': '#DCDDE0',
            '200': '#B6BAC1',
            '300': '#808995',
            '400': '#536371',
            '500': '#254655',
            '600': '#1E3844',
            '700': '#162A33',
            '800': '#0F1C22',
            '900': '#0B151A',
        },
        limeGreen: {
            DEFAULT: '#B2B22E',
            '50': '#F3F3D2',
            '100': '#EEEEBE',
            '200': '#E4E495',
            '300': '#D9D96D',
            '400': '#CFCF44',
            '500': '#B2B22E',
            '600': '#898924',
            '700': '#616119',
            '800': '#38380F',
            '900': '#101004',
        },
        fadedPurple: {
            DEFAULT: '#9D5866',
            '50': '#F2E8EA',
            '100': '#E9D8DB',
            '200': '#D7B7BE',
            '300': '#C497A0',
            '400': '#B27682',
            '500': '#9D5866',
            '600': '#7C4651',
            '700': '#5C333C',
            '800': '#3B2126',
            '900': '#1A0F11',
        },
        gray: {
            DEFAULT: '#718096',
            '50': '#F9F9FA',
            '100': '#EDF2F7',
            '200': '#E2E8F0',
            '300': '#CBD5E0',
            '400': '#A0AEC0',
            '500': '#718096',
            '600': '#4A5568',
            '700': '#2D3748',
            '800': '#1A202C',
            '900': '#171923',
            dark: '#817870',
            light: '#D6D2CC',
        },
    },
};

export type CurrentAmplifiedColorScheme = keyof typeof colors.colors;
