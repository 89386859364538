/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdjustmentToleranceLevel } from './AdjustmentToleranceLevel';
import {
    AdjustmentToleranceLevelFromJSON,
    AdjustmentToleranceLevelFromJSONTyped,
    AdjustmentToleranceLevelToJSON,
} from './AdjustmentToleranceLevel';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { BudgetConstraintType } from './BudgetConstraintType';
import {
    BudgetConstraintTypeFromJSON,
    BudgetConstraintTypeFromJSONTyped,
    BudgetConstraintTypeToJSON,
} from './BudgetConstraintType';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';
import type { ScenarioStatus } from './ScenarioStatus';
import {
    ScenarioStatusFromJSON,
    ScenarioStatusFromJSONTyped,
    ScenarioStatusToJSON,
} from './ScenarioStatus';

/**
 *
 * @export
 * @interface GetScenarioBasicInfoResponseDto
 */
export interface GetScenarioBasicInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    scenarioName?: string | null;
    /**
     *
     * @type {number}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    budget?: number;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {ScenarioStatus}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    scenarioStatus?: ScenarioStatus;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {BudgetConstraintType}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    budgetConstraintType?: BudgetConstraintType;
    /**
     *
     * @type {AdjustmentToleranceLevel}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    adjustmentToleranceLevel?: AdjustmentToleranceLevel;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    scenarioShareLink?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioBasicInfoResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the GetScenarioBasicInfoResponseDto interface.
 */
export function instanceOfGetScenarioBasicInfoResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetScenarioBasicInfoResponseDtoFromJSON(
    json: any,
): GetScenarioBasicInfoResponseDto {
    return GetScenarioBasicInfoResponseDtoFromJSONTyped(json, false);
}

export function GetScenarioBasicInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetScenarioBasicInfoResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        scenarioStatus: !exists(json, 'scenario_status')
            ? undefined
            : ScenarioStatusFromJSON(json['scenario_status']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        budgetConstraintType: !exists(json, 'budget_constraint_type')
            ? undefined
            : BudgetConstraintTypeFromJSON(json['budget_constraint_type']),
        adjustmentToleranceLevel: !exists(json, 'adjustment_tolerance_level')
            ? undefined
            : AdjustmentToleranceLevelFromJSON(json['adjustment_tolerance_level']),
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        scenarioShareLink: !exists(json, 'scenario_share_link')
            ? undefined
            : json['scenario_share_link'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function GetScenarioBasicInfoResponseDtoToJSON(
    value?: GetScenarioBasicInfoResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        scenario_name: value.scenarioName,
        budget: value.budget,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        scenario_status: ScenarioStatusToJSON(value.scenarioStatus),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        budget_constraint_type: BudgetConstraintTypeToJSON(value.budgetConstraintType),
        adjustment_tolerance_level: AdjustmentToleranceLevelToJSON(value.adjustmentToleranceLevel),
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        scenario_share_link: value.scenarioShareLink,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
