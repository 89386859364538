import {
    GetScenarioLevelResultComparisonResponseDto,
    OptimisationStrategy,
} from '@api-clients/media-plan';
import { useTranslation } from 'react-i18next';

type useGetPercentageChangeAndCodedCommentaryHook = () => {
    getPercentageChangeAndCodedCommentary: (
        scenario?: GetScenarioLevelResultComparisonResponseDto | undefined,
    ) => { percentageChange: number; codedCommentary: string };
};

export const useGetPercentageChangeAndCodedCommentary: useGetPercentageChangeAndCodedCommentaryHook =
    () => {
        const getPercentageChangeAndCodedCommentary = (
            scenario?: GetScenarioLevelResultComparisonResponseDto | undefined,
        ): { percentageChange: number; codedCommentary: string } => {
            let percentageChange = 0;
            let codedCommentary = '';

            const { t } = useTranslation('mediaPlans');

            let codedCommentaryState = t(
                'mediaPlanning.scenarioCreation.keyOptimisedMetrics.codedCommentary',
                {
                    returnObjects: true,
                },
            );

            if (!scenario) {
                return { percentageChange, codedCommentary };
            }

            switch (scenario.optimisationStrategy) {
                case OptimisationStrategy.AApI:
                    percentageChange =
                        scenario?.activeAttentionSecondsPerImpression?.percentageDifference ?? 0;
                    codedCommentary = codedCommentaryState.aasi;
                    break;
                case OptimisationStrategy.AApR:
                    percentageChange =
                        scenario?.activeAttentionSecondsPerReach?.percentageDifference ?? 0;
                    codedCommentary = codedCommentaryState.aasr;
                    break;
                case OptimisationStrategy.Stl:
                    percentageChange = scenario?.brandUplift?.valueDifference ?? 0;
                    codedCommentary = codedCommentaryState.stl;
                    break;
                case OptimisationStrategy.Base:
                default:
                    break;
            }
            return { percentageChange, codedCommentary };
        };
        return { getPercentageChangeAndCodedCommentary };
    };
