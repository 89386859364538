import { ShareScenarioApi } from '@api-clients/media-plan/apis/ShareScenarioApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useDeleteShareLinkHook = () => {
    deleteShareLink: (scenarioId: string) => Promise<void>;
};

export const useDeleteShareLink: useDeleteShareLinkHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();
    const deleteShareLink = async (scenarioId: string) => {
        try {
            const config = await getMediaPlanConfig();
            const api = new ShareScenarioApi(config);
            return await api.deleteScenarioShareLink({ scenarioId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { deleteShareLink };
};
