import { Card, CardBody, Container, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export const PageNotFoundPage: FC = () => (
    <Container paddingTop="10vh">
        <Card>
            <CardBody>
                <VStack>
                    <Heading>Page Not Found</Heading>
                    <Text>404 Error: The page you requested does not exist</Text>
                </VStack>
            </CardBody>
        </Card>
    </Container>
);
