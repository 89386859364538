/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { OrganisationStatus } from './OrganisationStatus';
import {
    OrganisationStatusFromJSON,
    OrganisationStatusFromJSONTyped,
    OrganisationStatusToJSON,
} from './OrganisationStatus';
import type { UserWithAccessLevelDto } from './UserWithAccessLevelDto';
import {
    UserWithAccessLevelDtoFromJSON,
    UserWithAccessLevelDtoFromJSONTyped,
    UserWithAccessLevelDtoToJSON,
} from './UserWithAccessLevelDto';

/**
 *
 * @export
 * @interface OrganisationResponseDto
 */
export interface OrganisationResponseDto {
    /**
     *
     * @type {string}
     * @memberof OrganisationResponseDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {CountryCodeIso2}
     * @memberof OrganisationResponseDto
     */
    region?: CountryCodeIso2;
    /**
     *
     * @type {OrganisationStatus}
     * @memberof OrganisationResponseDto
     */
    status?: OrganisationStatus;
    /**
     *
     * @type {CurrencyCode}
     * @memberof OrganisationResponseDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {string}
     * @memberof OrganisationResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof OrganisationResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof OrganisationResponseDto
     */
    updatedAt?: Date;
    /**
     *
     * @type {Array<UserWithAccessLevelDto>}
     * @memberof OrganisationResponseDto
     */
    users?: Array<UserWithAccessLevelDto> | null;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationResponseDto
     */
    hasApiAccess?: boolean;
}

/**
 * Check if a given object implements the OrganisationResponseDto interface.
 */
export function instanceOfOrganisationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrganisationResponseDtoFromJSON(json: any): OrganisationResponseDto {
    return OrganisationResponseDtoFromJSONTyped(json, false);
}

export function OrganisationResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        region: !exists(json, 'region') ? undefined : CountryCodeIso2FromJSON(json['region']),
        status: !exists(json, 'status') ? undefined : OrganisationStatusFromJSON(json['status']),
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        users: !exists(json, 'users')
            ? undefined
            : json['users'] === null
            ? null
            : (json['users'] as Array<any>).map(UserWithAccessLevelDtoFromJSON),
        hasApiAccess: !exists(json, 'has_api_access') ? undefined : json['has_api_access'],
    };
}

export function OrganisationResponseDtoToJSON(value?: OrganisationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_name: value.organisationName,
        region: CountryCodeIso2ToJSON(value.region),
        status: OrganisationStatusToJSON(value.status),
        currency: CurrencyCodeToJSON(value.currency),
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        users:
            value.users === undefined
                ? undefined
                : value.users === null
                ? null
                : (value.users as Array<any>).map(UserWithAccessLevelDtoToJSON),
        has_api_access: value.hasApiAccess,
    };
}
