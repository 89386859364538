import { useTranslation } from 'react-i18next';
import { useApiConfiguration } from '@hooks/configuration';
import { toCapitalised, useHelper } from '@shared/utils';
import {
    CreateSelfSignUpOrganisationRequest,
    OrganisationBasicInfoResponseDto,
    SelfSignUpOrganisationDto,
    SignUpApi,
} from '@api-clients/account-manager';

export const useCreateSelfSignUpOrganisation = () => {
    const { t } = useTranslation();
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function createSelfSignUpOrganisation(
        selfSignUpOrganisationDto: SelfSignUpOrganisationDto,
    ): Promise<OrganisationBasicInfoResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: CreateSelfSignUpOrganisationRequest = {
                selfSignUpOrganisationDto,
            };

            const api = new SignUpApi(configuration);
            return await api.createSelfSignUpOrganisation(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        createSelfSignUpOrganisation,
    };
};
