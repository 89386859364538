import {
    AttentionDataSaasApi,
    GetAttentionDataForSaasRequest,
    GetAttentionDataResponseDto,
} from '@api-clients/attention-data';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAttentionDataProps {
    getAttentionData: (
        request: GetAttentionDataForSaasRequest,
    ) => Promise<Array<GetAttentionDataResponseDto>>;
}

type useGetAttentionDataHook = () => useGetAttentionDataProps;

export const useGetAttentionData: useGetAttentionDataHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAttentionData(
        request: GetAttentionDataForSaasRequest,
    ): Promise<Array<GetAttentionDataResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new AttentionDataSaasApi(config);

            return await api.getAttentionDataForSaas(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAttentionData,
    };
};
