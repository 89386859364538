import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, HStack, Stack, Th, Tooltip, useDisclosure } from '@chakra-ui/react';
import { SortingIcon } from '@components/atoms/icon/SortingIcon';
import { SortDirection } from '@shared/cores';
import { FC } from 'react';
import { Column } from './DataTable';
import { HeaderCell } from './DataTableHeaderSection';

export interface HeaderCellProps<T> {
    column: HeaderCell<T>;
    onSort?: (column: Column<T>) => void;
    sortDirection?: SortDirection;
}

export const DataTableHeaderCell = <T,>({
    column,
    onSort,
    sortDirection = SortDirection.Unsorted,
}: HeaderCellProps<T>) => {
    const { title, sorter, isNumeric, colSpan, rowSpan } = column;
    const alignment = isNumeric ? 'right' : column.textAlign;

    const isSelected = sortDirection !== SortDirection.Unsorted;

    const getTooltipText = () => {
        switch (sortDirection) {
            case SortDirection.Unsorted:
                return 'Click to sort ascending';
            case SortDirection.Ascending:
                return 'Click to sort descending';
            case SortDirection.Descending:
            default:
                return 'Click to cancel sorting';
        }
    };

    const isSortable = !!(onSort && sorter);
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        // eslint-disable-next-line jsx-a11y/role-supports-aria-props
        <Th
            role="group"
            aria-selected={isSelected}
            colSpan={colSpan}
            rowSpan={rowSpan}
            isNumeric={isNumeric}
            data-is-sortable={isSortable}
            onClick={() => isSortable && onSort(column)}
            onMouseOver={onOpen}
            onMouseLeave={onClose}
        >
            <Stack
                align="center"
                justifyContent={alignment}
                direction={alignment === 'right' ? 'row-reverse' : 'row'}
                whiteSpace="break-spaces"
            >
                <HStack textAlign={alignment}>
                    <Box>{title}</Box>
                    {column.tooltip && (
                        <Box>
                            <Tooltip label={column.tooltip}>
                                <InfoOutlineIcon />
                            </Tooltip>
                        </Box>
                    )}
                </HStack>

                {isSortable && (
                    <Tooltip label={getTooltipText()}>
                        <Box>
                            <SortingIcon
                                transition="opacity 200ms linear"
                                opacity={sortDirection === SortDirection.Unsorted ? 0 : 1}
                                _groupHover={{ opacity: 1 }}
                                boxSize="1rem"
                                sortDirection={sortDirection}
                            />
                        </Box>
                    </Tooltip>
                )}
            </Stack>
        </Th>
    );
};
