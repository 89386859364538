/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';

/**
 *
 * @export
 * @interface AudienceByAgeGroupResponseDto
 */
export interface AudienceByAgeGroupResponseDto {
    /**
     *
     * @type {AgeGroup}
     * @memberof AudienceByAgeGroupResponseDto
     */
    ageGroup?: AgeGroup;
    /**
     *
     * @type {number}
     * @memberof AudienceByAgeGroupResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof AudienceByAgeGroupResponseDto
     */
    averagePassiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof AudienceByAgeGroupResponseDto
     */
    averageInactiveAttention?: number;
}

/**
 * Check if a given object implements the AudienceByAgeGroupResponseDto interface.
 */
export function instanceOfAudienceByAgeGroupResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AudienceByAgeGroupResponseDtoFromJSON(json: any): AudienceByAgeGroupResponseDto {
    return AudienceByAgeGroupResponseDtoFromJSONTyped(json, false);
}

export function AudienceByAgeGroupResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AudienceByAgeGroupResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ageGroup: !exists(json, 'age_group') ? undefined : AgeGroupFromJSON(json['age_group']),
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        averagePassiveAttention: !exists(json, 'average_passive_attention')
            ? undefined
            : json['average_passive_attention'],
        averageInactiveAttention: !exists(json, 'average_inactive_attention')
            ? undefined
            : json['average_inactive_attention'],
    };
}

export function AudienceByAgeGroupResponseDtoToJSON(
    value?: AudienceByAgeGroupResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        age_group: AgeGroupToJSON(value.ageGroup),
        average_active_attention: value.averageActiveAttention,
        average_passive_attention: value.averagePassiveAttention,
        average_inactive_attention: value.averageInactiveAttention,
    };
}
