import { BaseFormHookProps } from '@hooks/BaseFormHookProps';
import {
    ApiService,
    CreateOauthApplicationRequest,
    CreateOauthApplicationRequestDto,
    CreateOauthApplicationResponseDto,
    DeleteOauthApplicationRequest,
    GetAllOauthApplicationsRequest,
    GetOauthApplicationPermissionsRequest,
    GetOauthApplicationRequest,
    OauthApplicationResponseDto,
    OrganisationApi,
    OrganisationResponseDto,
    UpdateOauthApplicationPermissionsRequest,
    UpdateOauthApplicationPermissionsRequestDto,
    UpdateOauthApplicationRequest,
    UpdateOauthApplicationRequestDto,
} from '@api-clients/account-manager';
import { useTranslation } from 'react-i18next';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';
import * as yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';

interface InternalProps extends BaseFormHookProps {
    createOauthApplication: (
        organisationId: string,
        createOauthApplicationRequestDto: CreateOauthApplicationRequestDto,
    ) => Promise<CreateOauthApplicationResponseDto>;
    getOauthApplication: (
        organisationId: string,
        clientId: string,
    ) => Promise<OauthApplicationResponseDto>;
    updateOauthApplication: (
        organisationId: string,
        clientId: string,
        updateOauthApplicationRequestDto: UpdateOauthApplicationRequestDto,
    ) => Promise<OrganisationResponseDto>;
    deleteOauthApplication: (
        organisationId: string,
        clientId: string,
    ) => Promise<OrganisationResponseDto>;
    getAllOauthApplications: () => Promise<OrganisationResponseDto[]>;
    updateOauthApplicationPermissions: (
        clientId: string,
        updateOauthApplicationPermissionsRequestDto: UpdateOauthApplicationPermissionsRequestDto,
    ) => Promise<OrganisationResponseDto>;
    getOauthApplicationPermissions: (clientId: string) => Promise<ApiService[]>;
}

type useOauthApplicationHook = () => InternalProps;

export const useOauthApplications: useOauthApplicationHook = () => {
    const { t } = useTranslation();
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();
    const { user } = useAuth0();
    const { getUserProfile } = useHelper();

    const validationSchema = yup.object().shape({
        applicationName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.oauthApplications.applicationName.isRequired')),
        requestedApis: yup
            .array()
            .min(1)
            .required(t('messages.forms.validation.oauthApplications.applicationName.isRequired')),
    });

    async function createOauthApplication(
        organisationId: string,
        createOauthApplicationRequestDto: CreateOauthApplicationRequestDto,
    ): Promise<CreateOauthApplicationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: CreateOauthApplicationRequest = {
                organisationId: profile.orgId as string,
                createOauthApplicationRequestDto,
            };

            const api = new OrganisationApi(configuration);
            return await api.createOauthApplication(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function getOauthApplicationPermissions(clientId: string): Promise<ApiService[]> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: GetOauthApplicationPermissionsRequest = {
                organisationId: profile.orgId as string,
                clientId,
            };

            const api = new OrganisationApi(configuration);
            return await api.getOauthApplicationPermissions(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function getAllOauthApplications(): Promise<OauthApplicationResponseDto[]> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: GetAllOauthApplicationsRequest = {
                organisationId: profile.orgId as string,
            };

            const api = new OrganisationApi(configuration);
            return await api.getAllOauthApplications(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function getOauthApplication(
        organisationId: string,
        clientId: string,
    ): Promise<OauthApplicationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: GetOauthApplicationRequest = {
                organisationId: profile.orgId as string,
                clientId,
            };

            const api = new OrganisationApi(configuration);
            return await api.getOauthApplication(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function updateOauthApplication(
        organisationId: string,
        clientId: string,
        updateOauthApplicationRequestDto: UpdateOauthApplicationRequestDto,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: UpdateOauthApplicationRequest = {
                organisationId: profile.orgId as string,
                clientId,
                updateOauthApplicationRequestDto,
            };

            const api = new OrganisationApi(configuration);
            return await api.updateOauthApplication(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function updateOauthApplicationPermissions(
        clientId: string,
        updateOauthApplicationPermissionsRequestDto: UpdateOauthApplicationPermissionsRequestDto,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: UpdateOauthApplicationPermissionsRequest = {
                organisationId: profile.orgId as string,
                clientId,
                updateOauthApplicationPermissionsRequestDto,
            };

            const api = new OrganisationApi(configuration);
            return await api.updateOauthApplicationPermissions(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    async function deleteOauthApplication(
        organisationId: string,
        clientId: string,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const profile = getUserProfile(user!);
            const request: DeleteOauthApplicationRequest = {
                organisationId: profile.orgId as string,
                clientId,
            };

            const api = new OrganisationApi(configuration);
            return await api.deleteOauthApplication(request);
        } catch (e) {
            throw new Error(`${await getDisplayedErrorMessage(e)}`);
        }
    }

    return {
        validationSchema,
        createOauthApplication,
        getOauthApplication,
        updateOauthApplication,
        deleteOauthApplication,
        getAllOauthApplications,
        updateOauthApplicationPermissions,
        getOauthApplicationPermissions,
    };
};
