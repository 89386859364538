import { Heading, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { FC, ReactElement } from 'react';

export interface ContainerTitleProps {
    headingText: string | ReactElement;
    subtitleText?: string | ReactElement;
    size?: string;
}

export const ContainerTitle: FC<ContainerTitleProps> = ({
    headingText,
    subtitleText,
    size = 'lg'
}) => {
    return (
        <Stack align="left" spacing="0.5rem">
            <HStack>
                <Heading size={size}>
                    {headingText}
                </Heading>
            </HStack>
            {subtitleText && <Text color="gray.600">{subtitleText}</Text>}
        </Stack>
    );
};
