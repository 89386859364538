import {
    Badge,
    Box,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    ScaleFade,
    Skeleton,
    Spacer,
    Tag,
    Tooltip,
    useConst,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
    chakraComponents,
    ClearIndicatorProps,
    MenuListProps,
    MultiValueGenericProps,
    OptionProps,
    Select,
} from 'chakra-react-select';
import { FC, useCallback } from 'react';
import { MultiSelectOption } from './MultiSelectOption';

interface Option {
    value: string;
    label: string;
    tooltip?: string;
}

export interface MultiSelectProps {
    label: string;
    options: Array<Option>;
    setSelectedOptions: (values: Array<Option>) => void;
    selectedOptions: Array<Option>;
    tooltip?: string;
    isRequired?: boolean;
    isClearable?: boolean;
    isLoaded?: boolean;
    selectAll?: boolean; // whether to display the 'Select all' option
    allowSelectAll?: boolean; // whether the 'Select all' option is enabled
    openUpgradeModal?: () => void;
    isPaidPlan?: boolean;
    isDisabled?: boolean;
}

// slightly hacky way to achieve having multiple selected options show up as only one with the selection count visible
const CustomComponents = {
    MultiValueContainer: (props: MultiValueGenericProps<any>) => {
        const selectedValues = props.selectProps?.value;
        // determine if the "select all" option is present or not, since there is no easy way to pass `selectAll` prop
        // don't count the "Select all" option
        let options = props.selectProps.options.find((option) => option.value === 'all')
            ? props.selectProps.options.length - 1
            : props.selectProps.options.length;

        return (
            // Hide tag unless its the first one selected
            <Tag hidden={props.data.value !== selectedValues.at(0).value}>
                {/* replace tag text from the selected option to static text displaying amount of values selected */}
                {`${
                    selectedValues.length > options ? options : selectedValues.length
                }/${options} selected`}
            </Tag>
        );
    },
    MenuList: (props: MenuListProps<any>) => {
        return (
            <ScaleFade in>
                <chakraComponents.MenuList {...props} />
            </ScaleFade>
        );
    },
};

export const MultiSelect: FC<MultiSelectProps> = ({
    label,
    options,
    setSelectedOptions,
    selectedOptions,
    tooltip,
    isRequired,
    isClearable = true,
    isLoaded = true,
    selectAll = false,
    allowSelectAll,
    openUpgradeModal,
    isPaidPlan = true,
    isDisabled = false,
}) => {
    const optionsConst = useConst(
        selectAll
            ? [
                  {
                      label: 'Select all',
                      value: 'all',
                      isDisabled: !allowSelectAll,
                      showLockIcon: !allowSelectAll,
                      tooltip: isPaidPlan
                          ? ''
                          : 'Upgrade your plan to customise age group constraints',
                  },
                  ...options,
              ]
            : options,
    );
    // dont display clear indicator when using global
    const ClearIndicator = useCallback(
        (props: ClearIndicatorProps<any>) =>
            isClearable ? <chakraComponents.ClearIndicator {...props} /> : <></>,
        [isClearable],
    );
    return (
        <FormControl isRequired={isRequired}>
            <Flex>
                <FormLabel>{label}</FormLabel>
                {tooltip && (
                    <Tooltip label={tooltip}>
                        <Icon color="gray.400" as={InformationCircleIcon} />
                    </Tooltip>
                )}
                <Spacer />
                {!isPaidPlan && (
                    <Badge variant="solid" backgroundColor="gray.400" height="1.2rem">
                        Upgrade
                    </Badge>
                )}
            </Flex>
            <Skeleton isLoaded={isLoaded}>
                <Select
                    isMulti
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    colorScheme="green"
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    options={optionsConst}
                    value={
                        selectedOptions.length === options.length
                            ? [{ label: 'Select all', value: 'all' }, ...selectedOptions]
                            : selectedOptions
                    }
                    components={{ ClearIndicator, Option: MultiSelectOption, ...CustomComponents }}
                    onChange={(e: any) => {
                        if (isPaidPlan) {
                            if (
                                // user clicked on 'Select all'
                                selectAll &&
                                allowSelectAll &&
                                e.find((item: Option) => item.value === 'all') &&
                                selectedOptions.length !== options.length
                            ) {
                                // select all
                                setSelectedOptions(
                                    options.map((option) => ({
                                        value: option.value,
                                        label: option.label,
                                    })),
                                );
                                return;
                            }
                            setSelectedOptions(
                                e
                                    .filter((o: Option) => o.value !== 'all')
                                    .map((o: any) => ({ value: o.value, label: o.label })),
                            );
                        } else if (openUpgradeModal) {
                            openUpgradeModal();
                        }
                    }}
                    styles={{
                        menuPortal: (provided: any) => ({ ...provided, zIndex: 5000 }),
                        option: (provided: any) => {
                            return provided.index === 0 ? { ...provided } : { display: 'none' };
                        },
                    }}
                    chakraStyles={{
                        control: (provided: any) => ({
                            ...provided,
                            background: 'white',
                        }),
                        multiValue: (provided: any, state: any) => {
                            return state.index === 0 ? { ...provided } : { display: 'none' };
                        },
                    }}
                    useBasicStyles
                    menuPortalTarget={document.body}
                    isDisabled={isDisabled}
                />
            </Skeleton>
        </FormControl>
    );
};
