import * as yup from 'yup';
import { AgeGroup, ChannelBenchmarkResponseDto } from '@api-clients/attention-data';
import { MutableRefObject, useCallback } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { attentionPlanExcelLogo } from '@assets/images';
import { arrayBufferToBase64, buildPng, downloadBlob } from '@shared/utils';
import { createWorkbook, tableHeaderCellStyle, valueCellStyle } from 'src/components/Excel';
import { Chart as ChartJS, LegendItem } from 'chart.js';
import { colors } from '@assets/design-system/theming/colors';

export type SortOptionValue = 'active' | 'passive' | 'cpm';
export type SegmentOptionValue = 'first' | 'second' | 'third' | 'fourth' | 'all';
function byActiveAttention(
    lhs: ChannelBenchmarkResponseDto,
    rhs: ChannelBenchmarkResponseDto,
): number {
    const lhsActive = lhs.averageActiveAttention ?? 0;
    const rhsActive = rhs.averageActiveAttention ?? 0;
    if (lhsActive < rhsActive) {
        return 1;
    }
    if (lhsActive > rhsActive) {
        return -1;
    }
    return 0;
}

function byPassiveAttention(
    lhs: ChannelBenchmarkResponseDto,
    rhs: ChannelBenchmarkResponseDto,
): number {
    const lhsPassive = lhs.averagePassiveAttention ?? 0;
    const rhsPassive = rhs.averagePassiveAttention ?? 0;
    if (lhsPassive < rhsPassive) {
        return 1;
    }
    if (lhsPassive > rhsPassive) {
        return -1;
    }
    return 0;
}
function byBestValue(lhs: ChannelBenchmarkResponseDto, rhs: ChannelBenchmarkResponseDto): number {
    const lhsCpm = lhs.cpm ?? 0;
    const rhsCpm = rhs.cpm ?? 0;
    const lhsActive = lhs.averageActiveAttention ?? 0;
    const rhsActive = rhs.averageActiveAttention ?? 0;
    const lhsAvgActivePerCpm = lhsCpm === 0 ? 0 : lhsActive / lhsCpm;
    const rhsAvgActivePerCpm = rhsCpm === 0 ? 0 : rhsActive / rhsCpm;
    if (lhsAvgActivePerCpm < rhsAvgActivePerCpm) {
        return 1;
    }
    if (lhsAvgActivePerCpm > rhsAvgActivePerCpm) {
        return -1;
    }
    return 0;
}

export const useSortDisplayChannelData = () => {
    return useCallback(
        (
            data: ChannelBenchmarkResponseDto[],
            sortOption: SortOptionValue,
            showSelectedChannelOnly: boolean,
        ) => {
            const selectedCh = data.filter((ch) => ch.isSelected ?? false);
            const unselectedCh = data.filter((ch) => !(ch.isSelected ?? false));
            if (sortOption === 'passive') {
                selectedCh.sort(byPassiveAttention);
                unselectedCh.sort(byPassiveAttention);
            } else if (sortOption === 'cpm') {
                selectedCh.sort(byBestValue);
                unselectedCh.sort(byBestValue);
            } else {
                // sortOption === 'active'
                selectedCh.sort(byActiveAttention);
                unselectedCh.sort(byActiveAttention);
            }
            return showSelectedChannelOnly ? selectedCh : selectedCh.concat(unselectedCh);
        },
        [],
    );
};

type TabIndexRange = { min: number; max: number };
export const useParseTabIndex = (tabIndexRange: TabIndexRange) => {
    return useCallback((tabIndexString: string | null): number => {
        if (!tabIndexString) {
            return tabIndexRange.min;
        }
        const index = parseInt(tabIndexString, 10);
        if (Number.isNaN(index) || index < tabIndexRange.min || index > tabIndexRange.max) {
            return tabIndexRange.min;
        }
        return index;
    }, []);
};

export const SEGMENT_OPTIONS: {
    value: SegmentOptionValue;
    heading: string;
    description?: string;
    tooltip?: string;
}[] = [
    {
        value: 'all',
        heading: 'All',
        description: 'All segments',
    },
    {
        value: 'first',
        heading: 'Segment 1',
        description: 'High retention rate, high attentive reach',
    },
    {
        value: 'second',
        heading: 'Segment 2',
        description: 'High retention rate, low attentive reach',
    },
    {
        value: 'third',
        heading: 'Segment 3',
        description: 'Low retention rate, low attentive reach',
    },
    {
        value: 'fourth',
        heading: 'Segment 4',
        description: 'Low retention rate, high attentive reach',
    },
];

export const HELP_SECTION_CONTENT: {
    value: SegmentOptionValue;
    heading: string;
    content: {
        description: string[];
        campaignObjective: string[];
        attentionPattern: string[];
        brandSize: string[];
        buyingStrategy: string[];
        recommendedCreativeContent: string[];
    };
}[] = [
    {
        value: 'first',
        heading: 'Segment 1: High attentive reach, high retention rate',
        content: {
            description: [
                'These are the formats with high attention volume and the active attention level sustains well throughout the impression. Here is how they can support your campaign:',
            ],
            campaignObjective: [
                'High attention retention supports memory formation and message comprehension, therefore is essential in growth and brand building campaigns, especially for small brands.',
            ],
            attentionPattern: [
                'These formats tend to have more active attention, which is recommended for all brand sizes and campaigns, essential for brand growth in small brands.',
            ],
            brandSize: [
                'These formats are most recommended for small and challenger brands with <50% market share (when considering your brand and 3 other biggest competitors) to build the brand.',
                'These formats are also recommended for big brands with at least 50% market share (considering your brand and 3 other competitors) when promoting new assets, products, or messaging.',
            ],
            buyingStrategy: ['Less frequency is required.'],
            recommendedCreativeContent: [
                'Better for storytelling with emotional cues to form long-term memory.',
                'Branding can be later but should be presented clearly with distinctive assets earlier on to make the most out of the attention gained. Late or ineffective branding (no clear display of brand names or not using brand’s distinctive assets) might lead to media wastage or being mistaken for bigger competitors.',
            ],
        },
    },
    {
        value: 'second',
        heading: 'Segment 2: Low attentive reach, high retention rate',
        content: {
            description: [
                'These formats work similarly to Segment 1, however has a lower attention volume due to lower attentive reach. Here is how they can support your campaign:',
            ],
            campaignObjective: [
                'High attention retention supports memory formation and message comprehension, therefore is essential in growth and brand building campaigns.',
            ],
            attentionPattern: [
                'These formats tend to have more active attention, which is beneficial for all brand sizes and campaigns.',
            ],
            brandSize: [
                'These formats are most recommended for small and challenger brands with <50% market share (when considering your brand and 3 other biggest competitors) to build the brand.',
                'These formats are also recommended for big brands with at least 50% market share (considering your brand and 3 other competitors) when promoting new assets, products, or messaging.',
            ],
            buyingStrategy: ['More frequency is required due to low attention volume.'],
            recommendedCreativeContent: [
                'Better for storytelling with emotional cues to form long-term memory.',
                'Branding can be later but should be presented clearly with distinctive assets earlier on to make the most out of the attention gained. Late or ineffective branding (no clear display of brand names or not using brand’s distinctive assets) might lead to media wastage or being mistaken for bigger competitors.',
            ],
        },
    },
    {
        value: 'third',
        heading: 'Segment 3: Low attentive reach, low retention rate',
        content: {
            description: [
                'These are the formats with lower attention volume and the active attention level drops off quickly. These formats need more frequency to work for any campaigns:',
            ],
            campaignObjective: [
                'These formats have lower attention retention, therefore are more suitable for campaigns with short, sales-led messaging.',
            ],
            attentionPattern: ['These formats have lower active and passive attention.'],
            brandSize: [
                'These formats have lower than average active and passive attention. Formats with higher active attention are recommended for small to challenger brands, while formats with higher passive attention work harder for big brands with established assets.',
            ],
            buyingStrategy: ['Higher frequency is required to make an impact.'],
            recommendedCreativeContent: [
                'Best for short, sales-led, effective messaging.',
                'Branding needs to be early. Branding should be presented clearly with distinctive assets earlier on to make the most out of the attention gained. Late or ineffective branding (no clear display of brand names or not using brand’s distinctive assets) might lead to media wastage or being mistaken for bigger competitors.',
            ],
        },
    },
    {
        value: 'fourth',
        heading: 'Segment 4: High attentive reach, low retention rate',
        content: {
            description: [
                'These are the formats with high attention volume, but the active attention level drops off quickly. Here is how they can support your campaign:',
            ],
            campaignObjective: [
                'These formats tend to have a high passive attention volume, and therefore are good for brand maintenance campaigns for big brands. They also have a high attentive reach but lower retention, which is suitable for campaigns with short, sales-led messaging.',
            ],
            attentionPattern: [
                'These formats have lower active attention than Segment 1, but still relatively high. Active attention is essential for small and challenger brands to grow.',
                'These formats also tend to have high passive attention, which is beneficial for big brands.',
            ],
            brandSize: [
                'These formats are more suitable for big brands with at least 50% market share (considering your brand and 3 other competitors) considering the amount of passive attention is higher than active attention. However, small brands can still utilise these formats by following the creative content recommendations below.',
            ],
            buyingStrategy: ['Lower frequency is required.'],
            recommendedCreativeContent: [
                'Best for short, sales-led, effective messaging.',
                'Branding needs to be early. Branding should be presented clearly with distinctive assets earlier on to make the most out of the attention gained. Late or ineffective branding (no clear display of brand names or not using brand’s distinctive assets) might lead to media wastage or being mistaken for bigger competitors.',
            ],
        },
    },
];

export const locale = navigator.language || 'en-GB';

export const numberFormatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export const exportChannelSelectionSectionGraphToPng = async (
    chartRef: MutableRefObject<ChartJSOrUndefined<'bar', string[], object>>,
    strategyReportName: string | undefined | null,
) => {
    const chartImageDataUrl = chartRef.current?.toBase64Image();
    if (!chartImageDataUrl) throw new Error('Exporting to PNG failed in channel selection section');
    const blob = await buildPng(chartImageDataUrl);
    downloadBlob(blob, `attentionPLAN-Channel-Selection-${strategyReportName ?? ''}.png`);
};

export const exportChannelSelectionSectionGraphToXlsx = async (
    channelBenchmarkData: ChannelBenchmarkResponseDto[] | undefined | null,
    strategyReportName: string | undefined | null,
) => {
    if (!channelBenchmarkData) throw new Error('Channel benchmark data is null or undefined');
    if (channelBenchmarkData.length === 0) throw new Error('Channel benchmark data is empty');
    // export to xlsx begin
    const workbook = createWorkbook();
    const sheet = workbook.addWorksheet('data');
    sheet.properties.defaultColWidth = 32;

    const firstRow = sheet.getRow(1);
    firstRow.height = 60;
    const res = await fetch(attentionPlanExcelLogo);
    const imageBuffer = await res.arrayBuffer();
    const logoId = workbook.addImage({ buffer: imageBuffer, extension: 'png' });
    sheet.addImage(logoId, 'A1:A1');

    // timestamp
    const tsCell = sheet.getCell('A3');
    tsCell.value = `Exported at ${new Date().toLocaleString()}`;
    // fill in details from 'channelBenchmarkData'
    const COLS = 'ABCDE';
    sheet.getCell('A5').value = 'Channel';
    sheet.getCell('B5').value = 'Active attention';
    sheet.getCell('C5').value = 'Passive attention';
    sheet.getCell('D5').value = 'Active attention per impression cost';
    sheet.getCell('E5').value = 'CPM';
    for (let i = 0; i < COLS.length; i++) sheet.getCell(`${COLS[i]}5`).style = tableHeaderCellStyle;
    // fill in the remaining details
    const baseRowNumber = 6;
    for (let i = 0; i < channelBenchmarkData.length; i++) {
        const row = channelBenchmarkData[i];
        const currRowNumber = baseRowNumber + i;
        sheet.getCell(`A${currRowNumber}`).value = row.channelName ?? '';
        sheet.getCell(`B${currRowNumber}`).value = row.averageActiveAttention ?? 0;
        sheet.getCell(`C${currRowNumber}`).value = row.averagePassiveAttention ?? 0;
        sheet.getCell(`D${currRowNumber}`).value =
            row.cpm && row.averageActiveAttention ? row.averageActiveAttention / row.cpm : 0;
        sheet.getCell(`E${currRowNumber}`).value = row.cpm ?? 0;
        sheet.getCell(`A${currRowNumber}`).style = valueCellStyle;
        sheet.getCell(`B${currRowNumber}`).style = valueCellStyle;
        sheet.getCell(`C${currRowNumber}`).style = valueCellStyle;
        sheet.getCell(`D${currRowNumber}`).style = valueCellStyle;
        sheet.getCell(`E${currRowNumber}`).style = valueCellStyle;
    }

    const buf = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'",
    });
    downloadBlob(
        blob,
        `attentionPLAN-Channel-Selection-${strategyReportName ?? 'New Strategy Report'}.xlsx`,
    );
};

export const AddCreativeValidationSchema = yup.object().shape({
    name: yup.string().trim().min(1, 'Name is required').required('Name is required'),
});

export const UpdateCreativeValidationSchema = yup.object().shape({
    name: yup.string().trim().min(1, 'Name is required').required('Name is required'),
});
export const selectedCreativeQueryKey = 'selected_creative';

export type BrandedMomentType = {
    index: number;
    isChecked: boolean;
    key: string;
    start: number;
    end: number;
};

export const MIN_CREATIVE_DURATION = 0;
export const MAX_CREATIVE_DURATION = 90;
export const ChartColors = {
    active: colors.colors.green['500'],
    passive: colors.colors.green['300'],
    inactive: colors.colors.green['100'],
    avgCost: colors.colors.orange['500'],
};

export const getFillColorByLabel = (label: string) => {
    const text = label.toLowerCase();
    if (text.startsWith('non')) {
        return ChartColors.inactive;
    }
    if (text.startsWith('passive')) {
        return ChartColors.passive;
    }
    if (text.startsWith('active') && text.endsWith('cost')) {
        return ChartColors.avgCost;
    }
    if (text.startsWith('active')) {
        return ChartColors.active;
    }
    throw new Error('Unknown label');
};

export const generateChartLegends = (chart: ChartJS) => {
    const originalLabels: LegendItem[] = chart.data.datasets.map((dataset, i) => ({
        text: dataset.label ?? '',
        borderRadius: 0,
        fillStyle: getFillColorByLabel(dataset.label ?? ''),
        hidden: !chart.isDatasetVisible(i),
        lineCap: 'round',
        lineDash: [],
        lineDashOffset: 0,
        lineJoin: 'round',
        lineWidth: 0,
        strokeStyle: 'white',
        pointStyle: 'circle',
        datasetIndex: i,
        rotation: 0,
    }));
    const active = originalLabels.find(
        (item) =>
            item.text.toLowerCase().startsWith('active') &&
            !item.text.toLowerCase().endsWith('cost'),
    );
    const passive = originalLabels.find((item) => item.text.toLowerCase().startsWith('passive'));
    const inactive = originalLabels.find((item) => item.text.toLowerCase().startsWith('non'));
    const avgCost = originalLabels.find(
        (item) =>
            item.text.toLowerCase().startsWith('active') && item.text.toLowerCase().endsWith('cost'),
    );
    if (!active || !passive || !inactive || !avgCost) {
        throw new Error('Something wrong with label');
    }
    return [active, passive, inactive, avgCost];
};

export const ageGroupToLabel = (ageGroup: AgeGroup | null | undefined) => {
    if (!ageGroup) return '';
    if (ageGroup === AgeGroup.Group18to24) return '18-24';
    if (ageGroup === AgeGroup.Group25to34) return '25-34';
    if (ageGroup === AgeGroup.Group35to44) return '35-44';
    if (ageGroup === AgeGroup.Group45to54) return '45-54';
    return '55+';
};
