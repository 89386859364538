import { ArrowDownIcon, ArrowUpIcon, IconProps } from '@chakra-ui/icons';
import { SortDirection } from '@shared/cores';

import { FC } from 'react';

export interface SortingIconProps extends IconProps {
    sortDirection: SortDirection;
    selectedColor?: string;
    color?: string;
}

/**
 * Chevron up-down icon that colors top or bottom arrow depending on sort direction
 */
export const SortingIcon: FC<SortingIconProps> = ({
    color = 'gray.400',
    selectedColor = 'green.600',
    sortDirection,
    ...iconProps
}) => {
    if (sortDirection === SortDirection.Ascending) {
        return <ArrowUpIcon color={selectedColor} {...iconProps} />;
    }
    if (sortDirection === SortDirection.Descending) {
        return <ArrowDownIcon color={selectedColor} {...iconProps} />;
    }

    return <ArrowUpIcon color={color} {...iconProps} />;
};
