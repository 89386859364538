import { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';

const data: TableDataType[] = [
    {
        channelName: 'BVOD',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'TV',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'Facebook',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'Instagram',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'General Web',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'Snapchat',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'TikTok',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'Twitter',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
    {
        channelName: 'Youtube',
        budget: 0,
        cpm: 0,
        frequency: 0,
        totalAudience: 0,
        impressions: 0,
        targetAudience: 0,
        reachPercentage: 0,
        trp: 0,
        cpp: 0,
        isChecked: true,
    },
];
export default data;
