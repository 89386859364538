import { AgeGroup, Gender } from '@api-clients/media-plan';
import { CountryCodeIso2 } from '@api-clients/shared';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { CountrySelect, MultiSelect } from '@apps/attentionADJUST/components/molecules';
import {
    Box,
    Flex,
    FormLabel,
    Grid,
    GridItem,
    Icon,
    Switch,
    Tooltip,
    useConst,
    useDisclosure,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useSubscriptionHelper } from '@hooks/subscriptions';
import { useAppContextHelper } from '@hooks/_contexts';
import { findCountryName } from '@shared/cores/types/Country';
import { useHelper } from '@shared/utils';
import {
    CountryFeatureCode,
    GetCountryCodeFromCountryFeature,
    isAttentionDataCountryFeature,
    isMediaPlanCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
    UpgradeAgeGroupHeader,
    UpgradeCountryHeader,
    UpgradeGenderHeader,
} from '@apps/attentionADJUST/components/atoms';
import { DynamicUpgradeModal } from '@components/molecules';

interface AttentionBenchmarkFilterProps {
    selectedCountries: CountryCodeIso2[];
    selectedAgeGroups: AgeGroup[];
    selectedGenders: Gender[];
    isUsingGlobal: boolean;
    isMrcCompliantOnly: boolean;
    setSelectedCountries: (countries: CountryCodeIso2[]) => void;
    setSelectedAgeGroups: (ageGroups: AgeGroup[]) => void;
    setSelectedGenders: (genders: Gender[]) => void;
    setIsUsingGlobal: (value: boolean) => void;
    setIsMrcCompliantOnly: (value: boolean) => void;
}

export const AttentionBenchmarkFilter: FC<AttentionBenchmarkFilterProps> = ({
    selectedCountries,
    selectedAgeGroups,
    selectedGenders,
    isUsingGlobal,
    isMrcCompliantOnly,
    setSelectedCountries,
    setSelectedAgeGroups,
    setSelectedGenders,
    setIsUsingGlobal,
    setIsMrcCompliantOnly,
}) => {
    const { t } = useTranslation('benchmark');
    const benchmarksText = useConst(() => t('attentionBenchmarks', { returnObjects: true }));
    const { canAccessBenchmarks } = useSubscriptionHelper();

    const isPaidPlan = canAccessBenchmarks();
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const { formatOptionsForSelect, formatAgeGroup, formatStringToCapitalized } = useHelper();

    const { canUseGlobal, subscribedCountries } = useMemo(() => {
        const isGlobalFeatureBalance = featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );
        setIsUsingGlobal(!!isGlobalFeatureBalance);

        return {
            canUseGlobal: isGlobalFeatureBalance,
            subscribedCountries:
                featureBalances
                    ?.filter(
                        (f) =>
                            isAttentionDataCountryFeature(f.featureCode!) &&
                            f.limitType === LimitType.Unlimited,
                    )
                    .map(
                        (f) =>
                            GetCountryCodeFromCountryFeature(
                                f.featureCode as CountryFeatureCode,
                            ) as CountryCodeIso2,
                    ) ?? [],
        };
    }, [featureBalances]);

    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const [upgradeHeader, setUpgradeHeader] = useState<ReactJSXElement>(<></>);
    const handleOpenUpgradeModal = (header: ReactJSXElement) => {
        setUpgradeHeader(header);
        onUpgradeModalOpen();
    };

    return (
        <>
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_AttentionBenchmarkFilter"
                isOpen={isUpgradeModalOpen}
                onClose={onUpgradeModalClose}
                header={upgradeHeader}
            />
            <Grid templateColumns="repeat(5, 1fr)" width="100%" gap="1rem">
                <GridItem>
                    <CountrySelect
                        label="Country"
                        availableCountryOptions={subscribedCountries}
                        setSelectedOptions={(selectedOptions) =>
                            setSelectedCountries(
                                selectedOptions.map((o) => o.value as CountryCodeIso2),
                            )
                        }
                        selectedOptions={formatOptionsForSelect(selectedCountries, findCountryName)}
                        canSelectGlobal
                        isUsingGlobal={!!isUsingGlobal}
                        setIsUsingGlobal={() => setIsUsingGlobal(!isUsingGlobal)}
                        isDisabled={false}
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeCountryHeader />)}
                    />
                </GridItem>
                <GridItem>
                    <MultiSelect
                        label="Age group"
                        isClearable={false}
                        options={formatOptionsForSelect(
                            Object.values(AgeGroup),
                            formatAgeGroup,
                        ).map((o) => ({
                            isDisabled: false,
                            tooltip: !isPaidPlan
                                ? 'Upgrade your plan to customise age group constraints'
                                : '',
                            showLockIcon: false,
                            ...o,
                        }))}
                        setSelectedOptions={(selectedOptions) =>
                            setSelectedAgeGroups(selectedOptions.map((o) => o.value as AgeGroup))
                        }
                        selectedOptions={formatOptionsForSelect(selectedAgeGroups, formatAgeGroup)}
                        selectAll
                        allowSelectAll
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeAgeGroupHeader />)}
                        isPaidPlan={!!isPaidPlan}
                    />
                </GridItem>
                <GridItem>
                    <MultiSelect
                        label="Gender"
                        isClearable={false}
                        options={formatOptionsForSelect(
                            Object.values(Gender),
                            formatStringToCapitalized,
                        ).map((o) => ({
                            isDisabled: false,
                            tooltip: !isPaidPlan
                                ? 'Upgrade your plan customise gender constraints'
                                : '',
                            showLockIcon: false,
                            ...o,
                        }))}
                        setSelectedOptions={(selectedOptions) =>
                            setSelectedGenders(selectedOptions.map((o) => o.value as Gender))
                        }
                        selectedOptions={formatOptionsForSelect(
                            selectedGenders,
                            formatStringToCapitalized,
                        )}
                        selectAll
                        allowSelectAll
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeGenderHeader />)}
                        isPaidPlan={!!isPaidPlan}
                    />
                </GridItem>
                <GridItem>
                    <Flex>
                        <FormLabel>Mrc compliant only</FormLabel>
                        <Tooltip label={benchmarksText.benchMarks.filterToolip.mrc}>
                            <Icon color="gray.400" as={InformationCircleIcon} />
                        </Tooltip>
                    </Flex>
                    <Tooltip
                        label="Upgrade your plan to unlock MRC viewability filter"
                        isDisabled={isPaidPlan}
                    >
                        <Box>
                            <Switch
                                size="lg"
                                paddingTop="0.3rem"
                                isChecked={isMrcCompliantOnly}
                                onChange={() => {
                                    setIsMrcCompliantOnly(!isMrcCompliantOnly);
                                }}
                                isDisabled={!isPaidPlan}
                            />
                        </Box>
                    </Tooltip>
                </GridItem>
            </Grid>
        </>
    );
};
