import { ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import {
    useDisclosure,
    HStack,
    IconButton,
    Checkbox,
    Collapse,
    Stack,
    Spacer,
    Box,
    Tooltip,
} from '@chakra-ui/react';
import { ExpandingIcon, CurrencyInput } from '@components/atoms';
import { FC } from 'react';
import { useHelper } from '@shared/utils';
import { LockIcon } from '@chakra-ui/icons';

interface ChannelCpmCheckboxesProps {
    channelCode: string;
    onCheckChannel: (channelCode: string) => void;
    formats: Array<ScenarioAdFormatResponseDto>;
    onAdFormatUpdate: (adFormatCode: string, cpm?: number, isChecked?: boolean) => void;
    isFreeTier?: boolean;
    openUpgradeModal?: () => void;
}

export const ChannelCpmCheckboxes: FC<ChannelCpmCheckboxesProps> = ({
    channelCode,
    formats,
    onAdFormatUpdate,
    onCheckChannel,
    isFreeTier,
    openUpgradeModal,
}) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

    const allChecked = formats.every((f) => f.isChecked);
    const isIndeterminate = formats.some((f) => f.isChecked) && !allChecked;

    const { convertChannelCodeToChannelName } = useHelper();

    return (
        <>
            <HStack h="40px" borderTopWidth="1px">
                <IconButton
                    variant="ghost"
                    size="xs"
                    onClick={onToggle}
                    icon={
                        <ExpandingIcon
                            direction="right"
                            rotation="90"
                            fontSize="1.2rem"
                            isOpen={isOpen}
                        />
                    }
                    aria-label="expand"
                />
                <Tooltip label="Upgrade your plan to customise CPM values" isDisabled={!isFreeTier}>
                    <Box>
                        <Checkbox
                            onChange={() => {
                                if (!isFreeTier) onCheckChannel(channelCode);
                                else if (openUpgradeModal) openUpgradeModal();
                            }}
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                        >
                            {convertChannelCodeToChannelName(channelCode)}
                        </Checkbox>
                    </Box>
                </Tooltip>
            </HStack>

            <Collapse style={{ overflow: isOpen ? 'visible' : 'hidden' }} in={isOpen}>
                <Stack paddingBottom="1rem" pl="3.5rem">
                    {formats
                        .sort((a, b) => a.adFormatName!.localeCompare(b.adFormatName!))
                        .map((f) => (
                            <HStack key={f.adFormatCode}>
                                <Tooltip
                                    label="Upgrade your plan to customise CPM values"
                                    isDisabled={!isFreeTier}
                                >
                                    <Box>
                                        <Checkbox
                                            onChange={(e) => {
                                                if (!isFreeTier)
                                                    onAdFormatUpdate(
                                                        f.adFormatCode!,
                                                        undefined,
                                                        e.target.checked,
                                                    );
                                                else if (openUpgradeModal) openUpgradeModal();
                                            }}
                                            isChecked={f.isChecked ?? false}
                                        >
                                            {f.adFormatName}
                                        </Checkbox>
                                    </Box>
                                </Tooltip>
                                <Spacer />
                                <Box
                                    onClick={() => {
                                        if (isFreeTier && openUpgradeModal) openUpgradeModal();
                                    }}
                                >
                                    <CurrencyInput
                                        isDisabled={!f.isChecked}
                                        value={f.cpm!}
                                        onChange={(value) => {
                                            onAdFormatUpdate(f.adFormatCode!, value, undefined);
                                        }}
                                        isFreeTier={isFreeTier}
                                    />
                                </Box>
                            </HStack>
                        ))}
                </Stack>
            </Collapse>
        </>
    );
};
