import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";

export const proveLineItemsDemoData: Array<ProveLineItem> = [
    {
        "id": "6e378885-c228-41b0-9c60-4dc6a72aeaca",
        "name": "Demo 1",
        "adFormat": "dis.std.mobile-banner",
        "externalId": "demo-1-b6tgk",
        "status": "READY"
    },
    {
        "id": "5b231013-2635-451d-aeff-7a15381b8ecc",
        "name": "Demo 2",
        "adFormat": "dis.std.mobile-banner",
        "externalId": "demo-2-fv4fu",
        "status": "READY"
    },
    {
        "id": "80a13c88-04bd-4a73-9bfc-6d0eed91dd5a",
        "name": "Demo 3",
        "adFormat": "dis.std.mobile-banner",
        "externalId": "demo-3-bal2b",
        "status": "READY"
    },
    {
        "id": "c3b61a8d-720b-4388-b803-77e3d2713216",
        "name": "Demo 4",
        "adFormat": "vid.std.instream",
        "externalId": "demo-4-ecu8y",
        "status": "READY"
    },
    {
        "id": "4edf43d6-1872-42d3-8859-ebef494a0b13",
        "name": "Demo 5",
        "adFormat": "vid.std.instream",
        "externalId": "demo-5-ymxon",
        "status": "READY"
    },
    {
        "id": "99afbe08-0aa1-49ff-b780-1d3e725bad4d",
        "name": "Demo 6",
        "adFormat": "vid.vid.30s",
        "externalId": "demo-6-yvfiy",
        "status": "READY"
    }
];


