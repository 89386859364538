import {
    DecimalComparisonValue,
    FormatLevelResultComparisonResponseDto,
    GetChannelLevelResultComparisonResponseDto,
    GetFormatLevelResultComparisonResponseDto,
} from '@api-clients/media-plan';
import { StatisticCell } from '@components/atoms/statisticCell/StatisticCell';
import { Column, DataTable, Row } from '@components/molecules/tables/dataTable/DataTable';
import { FC } from 'react';

export interface FormatTableProps {
    channelLevelResultArray: Array<GetChannelLevelResultComparisonResponseDto>;
}

const getFormatTotal = (
    channelFormats: Array<GetFormatLevelResultComparisonResponseDto>,
): GetFormatLevelResultComparisonResponseDto => {
    const reduceOnCell = (
        previousValue?: DecimalComparisonValue,
        currentValue?: DecimalComparisonValue,
    ): DecimalComparisonValue => ({
        value: (previousValue?.value ?? 0) + (currentValue?.value ?? 0),
        comparedWithValue:
            (previousValue?.comparedWithValue ?? 0) + (currentValue?.comparedWithValue ?? 0),
    });

    return channelFormats.reduce(
        (previousRow, currentRow): GetFormatLevelResultComparisonResponseDto => ({
            formatName: 'Total',
            budget: reduceOnCell(previousRow.budget, currentRow.budget),
            mix: reduceOnCell(previousRow.mix, currentRow.mix),
            impressions: reduceOnCell(previousRow.impressions, currentRow.impressions),
            activeAttentionVolumeSeconds: reduceOnCell(
                previousRow.activeAttentionVolumeSeconds,
                currentRow.activeAttentionVolumeSeconds,
            ),
            passiveAttentionVolumeSeconds: reduceOnCell(
                previousRow.passiveAttentionVolumeSeconds,
                currentRow.passiveAttentionVolumeSeconds,
            ),
        }),
    );
};

interface DataRow {
    channel: string;
    format: string;
    budget: DecimalComparisonValue;
    mix: DecimalComparisonValue;
    impression: DecimalComparisonValue;
    activeAttentionVolumeSeconds: DecimalComparisonValue;
    passiveAttentionVolumeSeconds: DecimalComparisonValue;
}

export const FormatTable: FC<FormatTableProps> = ({ channelLevelResultArray }) => {
    const dataSource: Array<Row<DataRow>> = channelLevelResultArray
        .filter((ch) => !!ch.id)
        .flatMap((channel) => {
            const { channelName } = channel;
            const formats = channel.formatLevelResults
                ?.filter((f) => !!f.id)
                .sort((a, b) => a.formatName?.localeCompare(b.formatName ?? '') ?? 0);
            let formatsWithTotal =
                formats && formats.length > 1 ? formats.concat(getFormatTotal(formats)) : formats;

            if (formatsWithTotal) {
                const total = formatsWithTotal.filter((a) => a.formatName === 'Total');
                formatsWithTotal = formatsWithTotal
                    .filter((a) => a.formatName !== 'Total')
                    .sort((a, b) => {
                        if (a.formatName && b.formatName) {
                            return a.formatName.localeCompare(b.formatName);
                        }
                        return 0;
                    });
                formatsWithTotal.push(...total);
            }

            return (
                formatsWithTotal?.map(
                    (format): Row<DataRow> => ({
                        key: `${channelName}-${format.formatCode}`,
                        channel: channelName ?? '',
                        format: format.formatName ?? '',
                        budget: {
                            value: format.budget?.value ?? 0,
                            comparedWithValue: format.budget?.comparedWithValue ?? 0,
                        },
                        mix: {
                            value: format.mix?.value ?? 0,
                            comparedWithValue: format.mix?.comparedWithValue ?? 0,
                        },
                        impression: {
                            value: format.impressions?.value ?? 0,
                            comparedWithValue: format.impressions?.comparedWithValue ?? 0,
                        },
                        activeAttentionVolumeSeconds: {
                            value: format.activeAttentionVolumeSeconds?.value ?? 0,
                            comparedWithValue:
                                format.activeAttentionVolumeSeconds?.comparedWithValue ?? 0,
                        },
                        passiveAttentionVolumeSeconds: {
                            value: format.passiveAttentionVolumeSeconds?.value ?? 0,
                            comparedWithValue:
                                format.passiveAttentionVolumeSeconds?.comparedWithValue ?? 0,
                        },
                    }),
                ) ?? []
            );
        });

    const getStatisticColumn = (title: string, dataIndex: keyof DataRow): Column<DataRow> => ({
        title,
        dataIndex,
        key: dataIndex,
        isNumeric: true,
        sorter: (a, b) => {
            // only compare scenario values if a and b are same channel
            if (a.channel !== b.channel || a.format === 'Total' || b.format === 'Total') {
                return 0;
            }
            const { value: aScenario } = a[dataIndex] as DecimalComparisonValue;
            const { value: bScenario } = b[dataIndex] as DecimalComparisonValue;
            return bScenario! - aScenario!;
        },
        render: (row) => {
            const isPercentage = dataIndex === 'mix';
            const isCurrency = dataIndex === 'budget';

            const comparisonData = row[dataIndex] as DecimalComparisonValue;
            return (
                <StatisticCell
                    value={isPercentage ? comparisonData.value! * 100 : comparisonData.value!}
                    comparedWithValue={
                        isPercentage
                            ? comparisonData.comparedWithValue! * 100
                            : comparisonData.comparedWithValue!
                    }
                    isPercentage={isPercentage}
                    isCurrency={isCurrency}
                />
            );
        },
        onCell: (row) => ({
            bgColor: row.format === 'Total' ? 'gray.50' : undefined,
        }),
    });

    const columns: Array<Column<DataRow>> = [
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            onCell: (row, index) => {
                const channelFirstIndex = dataSource.findIndex(
                    (data) => data.channel === row.channel,
                );
                const channelSpan = dataSource.filter(
                    (data) => data.channel === row.channel,
                ).length;

                return {
                    fontWeight: 'semibold',
                    rowSpan: index === channelFirstIndex ? channelSpan : 0,
                };
            },
        },
        {
            title: 'Format',
            key: 'format',
            dataIndex: 'format',
            onCell: (row) => ({
                bgColor: row.format === 'Total' ? 'gray.50' : undefined, // Show Total row as gray
            }),
        },
        {
            ...getStatisticColumn('Budget', 'budget'),
        },
        {
            ...getStatisticColumn('Mix', 'mix'),
        },
        {
            ...getStatisticColumn('Impressions', 'impression'),
        },
        {
            ...getStatisticColumn('Active Attention Volume(s)', 'activeAttentionVolumeSeconds'),
        },
        {
            ...getStatisticColumn('Passive Attention Volume(s)', 'passiveAttentionVolumeSeconds'),
        },
    ];

    return <DataTable dataSource={dataSource} columns={columns} />;
};
