import {
    ChannelLevelCurvesResponseDto,
    GetScenarioBasicInfoResponseDto,
    UpdateScenarioRequestDto,
} from '@api-clients/media-plan';
import {
    Box,
    Center,
    Container,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    SkeletonText,
    Spacer,
    Stack,
    Tag,
    TagLabel,
    Text,
    Wrap,
} from '@chakra-ui/react';
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader,
} from '@components/atoms/expandableBanner/ExpandableBanner';
import { CampaignHeaderSection } from '@components/organisms';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { getOptimisationObjective } from '@shared/cores/types';
import { useHelper } from '@shared/utils';
import { FC } from 'react';
import { ManageScenarioMenu } from '../../molecules';

export interface ScenarioBannerProps {
    scenarioBasicInfo?: GetScenarioBasicInfoResponseDto;
    onDelete: () => void;
    onEdit: (updateScenarioRequestDto: UpdateScenarioRequestDto) => void;
    formatDetailsComponent?: ReactJSXElement;
    reachCurveData: Array<ChannelLevelCurvesResponseDto>;
    isFreePlan: boolean;
}

const SkeletonScenarioDetailText = () => (
    <Center height="1.5rem">
        <SkeletonText noOfLines={1} width="100%" />
    </Center>
);
export const ScenarioBanner: FC<ScenarioBannerProps> = ({
    scenarioBasicInfo,
    onDelete,
    onEdit,
    formatDetailsComponent = <></>,
    reachCurveData,
    isFreePlan,
}) => {
    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    const { formatAgeGroup, formatStringToCapitalized, sortAgeGroups } = useHelper();

    const isLoaded = !!scenarioBasicInfo;

    return (
        <ExpandableBanner>
            <ExpandableBannerHeader>
                <Container>
                    <HStack>
                        <CampaignHeaderSection
                            title={scenarioBasicInfo?.scenarioName ?? ''}
                            lastModified={scenarioBasicInfo?.updatedAt}
                            isLoading={!isLoaded}
                        />
                        <Spacer />

                        <ManageScenarioMenu
                            scenarioBasicInfo={scenarioBasicInfo}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            reachCurveData={reachCurveData}
                            isFreePlan={isFreePlan}
                        />
                    </HStack>
                </Container>
            </ExpandableBannerHeader>
            <ExpandableBannerBody autoOpen defaultIsOpen>
                <Container>
                    <Stack spacing="2rem" align="left">
                        <Grid templateColumns="repeat(4, 1fr)" gap="1rem">
                            <GridItem>
                                <FormLabel>Budget</FormLabel>
                                {isLoaded ? (
                                    <Text id="budget">{`$${scenarioBasicInfo?.budget?.toLocaleString()}`}</Text>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                            <GridItem>
                                <FormLabel>Start Date</FormLabel>
                                {isLoaded ? (
                                    <Text>
                                        {scenarioBasicInfo?.startDate?.toLocaleDateString()}
                                    </Text>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                            <GridItem>
                                <FormLabel>End Date</FormLabel>
                                {isLoaded ? (
                                    <Text>{scenarioBasicInfo?.endDate?.toLocaleDateString()}</Text>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                            <GridItem>
                                <FormLabel>Budget Constraints</FormLabel>
                                {isLoaded ? (
                                    <Text>
                                        {scenarioBasicInfo?.budgetConstraintType ===
                                        'adjustment_tolerance_level'
                                            ? `Plan Tolerance - ${formatStringToCapitalized(
                                                  scenarioBasicInfo.adjustmentToleranceLevel ?? '',
                                              )}`
                                            : 'Minimum Spend'}
                                    </Text>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                            <GridItem>
                                <FormLabel>Countries</FormLabel>
                                {isLoaded ? (
                                    <Wrap>
                                        {scenarioBasicInfo?.countries &&
                                            scenarioBasicInfo.countries.map((countryCode) => (
                                                <Tag key={countryCode}>
                                                    <TagLabel>
                                                        {regionNames.of(countryCode.toUpperCase())}
                                                    </TagLabel>
                                                </Tag>
                                            ))}
                                    </Wrap>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                            {/* Age group */}
                            <GridItem>
                                <FormLabel>Age groups</FormLabel>
                                {isLoaded ? (
                                    <Wrap>
                                        {sortAgeGroups(scenarioBasicInfo?.ageGroups).map(
                                            (ageGroup) => (
                                                <Tag key={ageGroup} colorScheme="green">
                                                    <TagLabel>
                                                        {formatAgeGroup(ageGroup, 'Group', true)}
                                                    </TagLabel>
                                                </Tag>
                                            ),
                                        )}
                                    </Wrap>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>

                            {/* Gender */}
                            <GridItem>
                                <FormLabel>Genders</FormLabel>
                                {isLoaded ? (
                                    <Wrap>
                                        {scenarioBasicInfo?.genders?.map((gender) => (
                                            <Tag key={gender} colorScheme="green">
                                                <TagLabel>
                                                    {formatStringToCapitalized(gender)}
                                                </TagLabel>
                                            </Tag>
                                        ))}
                                    </Wrap>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>

                            {/* Optimisation Objective */}
                            <GridItem>
                                <FormLabel>Optimisation Objective</FormLabel>
                                {isLoaded ? (
                                    <>
                                        {scenarioBasicInfo?.optimisationStrategy && (
                                            <Text>
                                                {getOptimisationObjective(
                                                    scenarioBasicInfo.optimisationStrategy,
                                                )}
                                            </Text>
                                        )}
                                    </>
                                ) : (
                                    <SkeletonScenarioDetailText />
                                )}
                            </GridItem>
                        </Grid>
                        <Box>
                            <>{formatDetailsComponent}</>
                        </Box>
                    </Stack>
                </Container>
            </ExpandableBannerBody>
        </ExpandableBanner>
    );
};
