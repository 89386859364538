/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const AccessLevelType = {
    Owner: 'owner',
    Administrator: 'administrator',
    Member: 'member',
} as const;
export type AccessLevelType = typeof AccessLevelType[keyof typeof AccessLevelType];

export const allAccessLevelTypes = Object.values(AccessLevelType);

export function AccessLevelTypeFromJSON(json: any): AccessLevelType {
    return AccessLevelTypeFromJSONTyped(json, false);
}

export function AccessLevelTypeFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AccessLevelType {
    return json as AccessLevelType;
}

export function AccessLevelTypeToJSON(value?: AccessLevelType | null): any {
    return value as any;
}
