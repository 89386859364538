import { fonts } from '@assets/design-system/theming/typography';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
    baseStyle: {
        fontFamily: fonts.body,
    },
    defaultProps: {
        color: 'gray.800',
    },
};
