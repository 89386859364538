import {Configuration} from "@api-clients/runtime";
import {ProveInsertionOrder} from "@api-clients/prove/schema/ProveInsertionOrder";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class CreateProveInsertionOrderApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign,
        insertionOrder: ProveInsertionOrder
    ): Promise<ProveInsertionOrder> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaign.id}/insertion-orders`, {
            method: 'POST',
            headers: configuration.headers,
            body: JSON.stringify(insertionOrder)
        });

        return response.json();
    }

}
