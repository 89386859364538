import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Center,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Select,
    Spacer,
    TableColumnHeaderProps,
    Text,
    useConst,
} from '@chakra-ui/react';
import { ExpandingIcon } from '@components/atoms';
import { FC, useMemo } from 'react';
import { PaginatorWithEllipsis } from './PaginatorWithEllipsis';

export interface TablePaginatorProps extends TableColumnHeaderProps {
    selectedPage: number;
    numberOfPages: number;
    paginationOptions: Array<number>;
    setSelectedPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (pagPerPage: number) => void;
    paginatorPosition?: 'center' | 'left' | 'right';
}

const MAX_TOTAL_PAGINATOR_BUTTONS = 7;

export const TablePaginator: FC<TablePaginatorProps> = (props) => {
    const {
        selectedPage,
        numberOfPages,
        paginationOptions,
        setSelectedPage,
        rowsPerPage,
        setRowsPerPage,
        paginatorPosition,
    } = props;

    return (
        <HStack justifyContent={paginatorPosition}>
            {numberOfPages <= MAX_TOTAL_PAGINATOR_BUTTONS ? (
                <ButtonGroup colorScheme="gray" variant="ghost" size="sm">
                    <IconButton
                        onClick={() => setSelectedPage(selectedPage - 1)}
                        isDisabled={selectedPage === 1}
                        aria-label="previous page"
                        icon={<ChevronLeftIcon />}
                    />
                    {[...Array(numberOfPages)].map((_, index) => {
                        const page = index + 1;
                        return (
                            <Button
                                key={page}
                                maxW="2rem"
                                onClick={() => setSelectedPage(page)}
                                aria-label={`${page}`}
                                isActive={selectedPage === page}
                            >
                                {page}
                            </Button>
                        );
                    })}
                    <IconButton
                        onClick={() => setSelectedPage(selectedPage + 1)}
                        isDisabled={selectedPage === numberOfPages}
                        aria-label="next page"
                        icon={<ChevronRightIcon />}
                    />
                </ButtonGroup>
            ) : (
                <PaginatorWithEllipsis {...props} />
            )}

            <Menu matchWidth>
                <MenuButton
                    fontWeight="normal"
                    rightIcon={<ExpandingIcon isOpen={false} />}
                    as={Button}
                    width="8rem"
                    colorScheme="gray"
                    variant="outline"
                    size="sm"
                >
                    page / {rowsPerPage.toString()}
                </MenuButton>
                <MenuList minWidth="0">
                    <MenuOptionGroup
                        onChange={(value) => setRowsPerPage(parseInt(value as string, 10))}
                        value={rowsPerPage.toString()}
                        type="radio"
                    >
                        {[10, 25, 50, 100].map((number) => (
                            <MenuItemOption key={number} fontSize="sm" value={number.toString()}>
                                page / {number.toString()}
                            </MenuItemOption>
                        ))}
                    </MenuOptionGroup>
                </MenuList>
            </Menu>
        </HStack>
    );
};
