/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface DecimalComparisonValue
 */
export interface DecimalComparisonValue {
    /**
     *
     * @type {number}
     * @memberof DecimalComparisonValue
     */
    value?: number | null;
    /**
     *
     * @type {number}
     * @memberof DecimalComparisonValue
     */
    comparedWithValue?: number | null;
    /**
     *
     * @type {number}
     * @memberof DecimalComparisonValue
     */
    percentageDifference?: number | null;
    /**
     *
     * @type {number}
     * @memberof DecimalComparisonValue
     */
    valueDifference?: number | null;
}

/**
 * Check if a given object implements the DecimalComparisonValue interface.
 */
export function instanceOfDecimalComparisonValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DecimalComparisonValueFromJSON(json: any): DecimalComparisonValue {
    return DecimalComparisonValueFromJSONTyped(json, false);
}

export function DecimalComparisonValueFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): DecimalComparisonValue {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        value: !exists(json, 'value') ? undefined : json['value'],
        comparedWithValue: !exists(json, 'compared_with_value')
            ? undefined
            : json['compared_with_value'],
        percentageDifference: !exists(json, 'percentage_difference')
            ? undefined
            : json['percentage_difference'],
        valueDifference: !exists(json, 'value_difference') ? undefined : json['value_difference'],
    };
}

export function DecimalComparisonValueToJSON(value?: DecimalComparisonValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        value: value.value,
        compared_with_value: value.comparedWithValue,
        percentage_difference: value.percentageDifference,
        value_difference: value.valueDifference,
    };
}
