/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdFormatType = {
    Video: 'video',
    Static: 'static'
} as const;
export type AdFormatType = typeof AdFormatType[keyof typeof AdFormatType];


export function AdFormatTypeFromJSON(json: any): AdFormatType {
    return AdFormatTypeFromJSONTyped(json, false);
}

export function AdFormatTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdFormatType {
    return json as AdFormatType;
}

export function AdFormatTypeToJSON(value?: AdFormatType | null): any {
    return value as any;
}

