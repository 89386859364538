import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { Loading } from '@components/atoms';
import { clearLocalStorage } from '@shared/utils';
import jwtDecode from 'jwt-decode';
import { FC, ReactNode, useEffect, useState } from 'react';
import { usePosthogEvent } from '../app/usePosthog';

export const AuthenticatedWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const [hasPermissionsClaim, setHasPermissionsClaim] = useState<boolean>(false);

    const handleNoPermissionsClaim = (maxTries: number) => {
        if (maxTries > 0) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            setTimeout(() => tryAuthenticatingPermissions(maxTries - 1, { cacheMode: 'off' }), 500);
        } else {
            clearLocalStorage();
            loginWithRedirect();
        }
    };

    const tryAuthenticatingPermissions = (maxTries: number, options: GetTokenSilentlyOptions) => {
        getAccessTokenSilently({
            ...options,
            detailedResponse: true,
        })
            .then((response) => {
                const decoded = jwtDecode(response.access_token) as any;
                const permissions = decoded.permissions as string[] | undefined;
                if (permissions && permissions.length > 0) {
                    setHasPermissionsClaim(true);
                    return;
                }
                handleNoPermissionsClaim(maxTries);
            })
            .catch(() => handleNoPermissionsClaim(maxTries));
    };

    useEffect(() => {
        if (isLoading === false && isAuthenticated) {
            tryAuthenticatingPermissions(5, { cacheMode: 'on' });
        }
    }, [isLoading, isAuthenticated]);

    return <>{!isLoading && isAuthenticated ? children : <Loading/>}</>;
};
