import {
    OrganisationResponseDto,
    UserResponseDto,
    UserWithAccessLevelDto,
} from '@api-clients/account-manager';
import {
    Button,
    Card,
    CardBody,
    Center,
    Flex,
    Icon,
    IconButton,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Tag,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { RemoveUser } from '@components/molecules/modals/removeUser/RemoveUser';
import { useManageMember } from '@hooks/organisations/useManageMember';
import { FC, useMemo, useState } from 'react';
import _ from 'lodash';
import { Column, DataTable, Row } from '@components/molecules';
import {
    AddNewMember,
    DynamicUpgradeModal,
    ManageMemberFormContextProvider,
    UpdateUserRoleModal,
} from '@components/molecules/modals';
import { useTranslation } from 'react-i18next';
import {
    ContainerTitle,
    NoFeatureBalancePopover,
    UpgradeUserLimitHeader,
} from '@apps/attentionADJUST/components/atoms';
import { useAppContextHelper } from '@hooks/_contexts';
import { FeatureCode } from '@api-clients/subscriptions';
import { urls } from '@configs';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/solid';

export interface ManageMemberSectionProps {
    organisation: OrganisationResponseDto;
    currentUser: UserResponseDto;
    getOrganisation?: () => void;
}

export const ManageMemberSection: FC<ManageMemberSectionProps> = ({
    organisation,
    currentUser,
    getOrganisation,
}) => {
    const { t } = useTranslation();
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();
    const {
        canBeDeleted,
        getCurrentUserAccessLevel,
        canPerformDeletion,
        hasAdministrativePermission,
    } = useManageMember();
    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const {
        isOpen: isUpdateUserRoleOpen,
        onOpen: onUpdateUserRoleOpen,
        onClose: onUpdateUserRoleClose,
    } = useDisclosure();

    const userAccessLevel = getCurrentUserAccessLevel(organisation, currentUser);

    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();

    const [selectedUser, setSelectedUser] = useState<UserWithAccessLevelDto>();

    const handleUpdateModalOpen = (user: UserWithAccessLevelDto) => {
        setSelectedUser(user);
        onUpdateUserRoleOpen();
    };

    const columns: Array<Column<UserWithAccessLevelDto>> = [
        {
            title: t('labels.tables.memberList.columns.fullName'),
            key: 'name',
            render: (row) => <>{`${row.firstName} ${row.lastName}`}</>,
        },
        {
            title: t('labels.tables.memberList.columns.email'),
            key: 'emailAddress',
            dataIndex: 'emailAddress',
            render: (row) => <>{row.emailAddress}</>,
        },
        {
            title: t('labels.tables.memberList.columns.accessLevel'),
            key: 'accessLevel',
            dataIndex: 'accessLevel',
            render: (row) => {
                const { accessLevel } = row;
                let colorScheme = 'gray';
                if (accessLevel === 'owner') {
                    colorScheme = 'orange';
                }
                if (accessLevel === 'administrator') {
                    colorScheme = 'green';
                }
                return (
                    <Tag colorScheme={colorScheme} variant="outline">
                        {_.capitalize(row.accessLevel)}
                    </Tag>
                );
            },
        },
        {
            title: '',
            key: 'action',
            textAlign: 'center',
            onCell: () => ({ width: 0, p: 0 }),
            render: (row) => {
                return (
                    <Center>
                        <Menu placement="left-start">
                            {({ isOpen }) => (
                                <>
                                    {canPerformDeletion(userAccessLevel) &&
                                        canBeDeleted(row, userAccessLevel) && (
                                            <MenuButton
                                                isActive={isOpen}
                                                as={IconButton}
                                                icon={<Icon as={EllipsisVerticalIcon} />}
                                                variant="ghost"
                                                size="1rem"
                                            />
                                        )}
                                    <MenuList>
                                        <MenuItem
                                            icon={<Icon as={PencilIcon} />}
                                            onClick={() => handleUpdateModalOpen(row)}
                                        >
                                            Update
                                        </MenuItem>
                                        {organisation &&
                                            canPerformDeletion(userAccessLevel) &&
                                            canBeDeleted(row, userAccessLevel) && (
                                                <RemoveUser
                                                    organisation={organisation}
                                                    user={row}
                                                />
                                            )}
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                    </Center>
                );
            },
        },
    ];

    const dataSource: Array<Row<UserWithAccessLevelDto>> = useMemo(
        () =>
            organisation.users?.map((user) => {
                return {
                    key: user.id!,
                    ...user,
                };
            }) ?? [],
        [organisation.users],
    );

    const canAddMember = useMemo(() => {
        const seatLimitFeature = featureBalances!.find(
            (f) => f.featureCode === FeatureCode.OrganisationSeatLimit,
        )!;
        return seatLimitFeature.limit! - seatLimitFeature.used! > 0;
    }, [featureBalances]);

    const shouldDisplayAddMemberButton = useMemo(
        () => hasAdministrativePermission(organisation, currentUser),
        [organisation, currentUser],
    );
    const emitClickOnAddNewMemberEvent = usePosthogEvent('Click on Add new member');

    return (
        <>
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_ManageMemberSection"
                isOpen={isUpgradeModalOpen}
                onClose={onUpgradeModalClose}
                header={<UpgradeUserLimitHeader />}
            />
            <UpdateUserRoleModal
                selectedUser={selectedUser ?? {}}
                currentUser={currentUser ?? {}}
                isOpen={isUpdateUserRoleOpen}
                onClose={onUpdateUserRoleClose}
                getOrganisation={getOrganisation}
            />
            <VStack align="left" spacing="1rem">
                <ManageMemberFormContextProvider>
                    <Flex>
                        <ContainerTitle headingText="Manage organisation members" size="md" />
                        <Spacer />
                        {shouldDisplayAddMemberButton && (
                            <NoFeatureBalancePopover
                                shouldTriggerPopover={!canAddMember}
                                triggerElement={
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            if (canAddMember) {
                                                onAddOpen();
                                                emitClickOnAddNewMemberEvent();
                                            } else {
                                                onUpgradeModalOpen();
                                            }
                                        }}
                                    >
                                        Add new member
                                    </Button>
                                }
                                header="You have reached the user limit"
                                body={
                                    <Text>
                                        <Link
                                            wordBreak="break-all"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.amplifiedintelligence.com.au/attentionplan-pricing/"
                                        >
                                            Explore other plans
                                        </Link>
                                        <span> or talk to us at </span>
                                        <Link
                                            wordBreak="break-all"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={urls.customerSupportEmailUrl}
                                        >
                                            {urls.customerSupportEmail}
                                        </Link>
                                        <span> to upgrade.</span>
                                    </Text>
                                }
                            />
                        )}
                    </Flex>
                    <Card>
                        <CardBody>
                            <DataTable dataSource={dataSource} columns={columns} />
                        </CardBody>
                    </Card>
                    <AddNewMember
                        organisation={organisation}
                        isOpen={isAddOpen}
                        onClose={onAddClose}
                    />
                </ManageMemberFormContextProvider>
            </VStack>
        </>
    );
};
