/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptimisationStrategy = {
    Base: 'base',
    Stl: 'stl',
    Ltl: 'ltl',
    AApR: 'a_ap_r',
    AApI: 'a_ap_i'
} as const;
export type OptimisationStrategy = typeof OptimisationStrategy[keyof typeof OptimisationStrategy];


export function OptimisationStrategyFromJSON(json: any): OptimisationStrategy {
    return OptimisationStrategyFromJSONTyped(json, false);
}

export function OptimisationStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptimisationStrategy {
    return json as OptimisationStrategy;
}

export function OptimisationStrategyToJSON(value?: OptimisationStrategy | null): any {
    return value as any;
}

