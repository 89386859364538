import { FC, useMemo } from 'react';
import { OrganisationResponseDto, UserResponseDto } from '@api-clients/account-manager';
import { findCountryName } from '@shared/cores/types/Country';
import { Box, Button, Center, Flex, Heading, Spacer, StackDivider, VStack } from '@chakra-ui/react';
import { DetailListItem, DetailSection, TextRender } from '@components/molecules';
import { getListRenderKey, isValidValueString } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { useOrganisationFormContext } from './OrganisationInfoSectionContext';
import { useManageMember } from '@hooks/organisations';

interface OrganisationInfoViewModeProps {
    organisation: OrganisationResponseDto;
    user: UserResponseDto;
}

export const getCountryName = (region: string | null | undefined) => {
    if (isValidValueString(region)) return findCountryName(region!);

    return 'Other';
};

export const OrganisationInformationViewMode: FC<OrganisationInfoViewModeProps> = ({
    organisation,
    user,
}) => {
    const { t } = useTranslation();
    const { setEditMode } = useOrganisationFormContext();
    const { organisationName, region } = organisation;
    const { hasAdministrativePermission } = useManageMember();

    const renderDetails: DetailListItem[] = useMemo(
        () => [
            {
                label: t('labels.forms.organisationDetails.organisationName.field'),
                renderItem: <TextRender text={organisationName} />,
            },
            {
                label: t('labels.forms.organisationDetails.region.field'),
                renderItem: <TextRender text={getCountryName(region)} />,
            },
        ],
        [organisation],
    );

    return (
        <>
            <Flex marginBottom="4">
                <Center>
                    <Heading size="md" color="gray.600">
                        {t('labels.sections.organisationDetails.heading')}
                    </Heading>
                </Center>
                <Spacer />
                {hasAdministrativePermission(organisation, user) && (
                    <Box>
                        <Button size="sm" onClick={() => setEditMode(true)}>
                            {t('labels.sections.organisationDetails.buttonName.viewMode')}
                        </Button>
                    </Box>
                )}
            </Flex>
            <Box bg="white" borderRadius="lg">
                <VStack
                    divider={<StackDivider />}
                    align="stretch"
                    borderWidth="thin"
                    borderRadius="lg"
                    spacing="0"
                >
                    {renderDetails.map((item, index) => (
                        <DetailSection
                            key={getListRenderKey(index, item.label)}
                            label={item.label}
                            renderItem={item.renderItem}
                        />
                    ))}
                </VStack>
            </Box>
        </>
    );
};
