/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ApiService } from './ApiService';
import { ApiServiceFromJSON, ApiServiceFromJSONTyped, ApiServiceToJSON } from './ApiService';

/**
 *
 * @export
 * @interface CreateOauthApplicationRequestDto
 */
export interface CreateOauthApplicationRequestDto {
    /**
     *
     * @type {Array<ApiService>}
     * @memberof CreateOauthApplicationRequestDto
     */
    requestedApis?: Array<ApiService> | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationRequestDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationRequestDto
     */
    organisationRegion?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationRequestDto
     */
    applicationName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationRequestDto
     */
    createdByUserId?: string;
}

/**
 * Check if a given object implements the CreateOauthApplicationRequestDto interface.
 */
export function instanceOfCreateOauthApplicationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOauthApplicationRequestDtoFromJSON(
    json: any,
): CreateOauthApplicationRequestDto {
    return CreateOauthApplicationRequestDtoFromJSONTyped(json, false);
}

export function CreateOauthApplicationRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateOauthApplicationRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        requestedApis: !exists(json, 'requested_apis')
            ? undefined
            : json['requested_apis'] === null
            ? null
            : (json['requested_apis'] as Array<any>).map(ApiServiceFromJSON),
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        organisationRegion: !exists(json, 'organisation_region')
            ? undefined
            : json['organisation_region'],
        applicationName: !exists(json, 'application_name') ? undefined : json['application_name'],
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
    };
}

export function CreateOauthApplicationRequestDtoToJSON(
    value?: CreateOauthApplicationRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        requested_apis:
            value.requestedApis === undefined
                ? undefined
                : value.requestedApis === null
                ? null
                : (value.requestedApis as Array<any>).map(ApiServiceToJSON),
        organisation_name: value.organisationName,
        organisation_region: value.organisationRegion,
        application_name: value.applicationName,
        created_by_user_id: value.createdByUserId,
    };
}
