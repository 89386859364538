import type { RawImportedDataRequestDto } from '@api-clients/media-plan';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import type { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import TableErrorSection from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/TableErrorSection';
import TableRow from './TableRow';
import TableHeader from './TableHeader';

export type TableHeaderType = {
    label: string;
    sortable: boolean;
    id: number;
    accessorKey: keyof RawImportedDataRequestDto;
    tooltip?: string;
    minWidth?: string;
};
const TABLE_HEADERS: Array<TableHeaderType> = [
    {
        id: 1,
        sortable: false,
        label: 'Channel',
        accessorKey: 'channelName',
        tooltip: 'The media platform',
    },
    {
        id: 2,
        sortable: true,
        label: 'Budget',
        accessorKey: 'budget',
        tooltip: 'Total spend per channel',
        minWidth: '150px',
    },
    {
        id: 3,
        sortable: true,
        label: 'CPM',
        accessorKey: 'cpm',
        tooltip: 'The CPM for the target audience on each channel',
        minWidth: '105px',
    },
    {
        id: 4,
        sortable: true,
        label: 'Avg freq',
        tooltip: 'The average frequency for each channel across the campaign',
        accessorKey: 'frequency',
    },
    {
        id: 5,
        sortable: true,
        label: 'Potential target audience size',
        accessorKey: 'totalAudience',
        minWidth: '120px',
        tooltip:
            'The total audience that could be reached on this channel with your targeting parameters',
    },
    {
        id: 6,
        sortable: true,
        label: 'Impressions',
        accessorKey: 'impressions',
        tooltip: 'The total number of impressions delivered on the channel',
    },
    {
        id: 7,
        sortable: true,
        label: 'Target audience reached',
        accessorKey: 'targetAudience',
        tooltip:
            'The count of potential target audience reached based on budget, CPM and frequency',
    },
    {
        id: 8,
        sortable: true,
        label: 'Reach (%)',
        accessorKey: 'reachPercentage',
        tooltip:
            'The percentage of the Potential target audience size that will be exposed to your advertising placement across the campaign',
    },
    {
        id: 9,
        sortable: true,
        label: 'TRP',
        accessorKey: 'trp',
        tooltip:
            'The gross rated points achieved by your campaign among the targeted audience within a larger population',
    },
    {
        id: 10,
        sortable: true,
        label: 'CPP',
        accessorKey: 'cpp',
        tooltip: 'How much it costs to buy one rating point',
    },
];

const tableHeaders = TABLE_HEADERS.sort((a, b) => a.id - b.id);

type CampaignCreationTableProps = {
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
    subTableHasError: Array<TableError>;
    setSubTableHasError: Dispatch<SetStateAction<Array<TableError>>>;
    hasSubmitted: boolean;
    removeInvalidTableError?: () => void;
};

export type TableError = {
    channelName: string;
    adFormatName?: string;
    accessorKey?: string;
    message?: string;
};

export const CampaignCreationTable: FC<CampaignCreationTableProps> = ({
    tableData,
    setTableData,
    subTableHasError,
    setSubTableHasError,
    hasSubmitted,
    removeInvalidTableError = () => {},
}) => {
    const channelName = 'Channels'; // Placeholder name for this error
    const addTooFewChannelsError = () => {
        setSubTableHasError((old) => [
            ...old,
            {
                channelName,
                message: 'At least 2 channels must be enabled',
            },
        ]);
    };
    const removeTooFewChannelsError = () => {
        setSubTableHasError((old) => old.filter((err) => err.channelName !== channelName));
    };
    useEffect(() => {
        removeTooFewChannelsError();
        if (tableData.filter((row) => row.isChecked).length <= 1) {
            addTooFewChannelsError();
        }
        return () => {
            removeTooFewChannelsError();
        };
    }, [tableData]);

    return (
        <Box padding="0rem" display="flex" flexDirection="column" gap="1rem">
            {/* Alert section for errors */}
            <TableErrorSection subTableHasError={subTableHasError} />
            <TableContainer whiteSpace="normal" borderRadius="0.375rem">
                <Table>
                    <Thead>
                        <TableHeader
                            tableHeaders={tableHeaders}
                            tableData={tableData}
                            setTableData={setTableData}
                        />
                    </Thead>
                    <Tbody>
                        {tableData.map((row, rowIndex) => {
                            return (
                                <TableRow
                                    hasSubmitted={hasSubmitted}
                                    subTableHasError={subTableHasError}
                                    setSubTableHasError={setSubTableHasError}
                                    key={row.channelName}
                                    row={row}
                                    rowIndex={rowIndex}
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    removeInvalidTableError={removeInvalidTableError}
                                />
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};
