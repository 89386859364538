import { Box, Flex, Icon, IconButton, Spacer } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

export interface MediaMixChartContainerProps {
    onCopy?: () => void;
    children: ReactJSXElement;
    height?: string | number;
}

export const MediaMixChartContainer: FC<MediaMixChartContainerProps> = ({
    onCopy,
    children,
    height = '32rem',
}) => {
    return (
        <Flex
            boxShadow="xs"
            padding="1.25rem"
            bgColor="white"
            borderRadius="lg"
            overflow="hidden"
            height={height}
            direction="column"
        >
            <Flex>
                <Spacer />
                {onCopy && (
                    <IconButton
                        isRound
                        variant="ghost"
                        color="gray"
                        aria-label="Copy to clipboard"
                        icon={<Icon boxSize="1.5rem" as={ClipboardDocumentIcon} />}
                        onClick={onCopy}
                    />
                )}
            </Flex>
            <Box flex={1}>{children}</Box>
        </Flex>
    );
};
