export const pages = {
    applicationManagement: {
        heading: 'Manage Applications',
        tooltips: {
            exhaustedIntegrationCreation: 'You have reached the number of applications limit.',
        },
        buttons: { create: 'Create application', viewAll: 'View all applications' },
        secrets: {
            alert: {
                title: 'Important!',
                description:
                    'Please save your client secret in a safe and secure location. The client secret is only shown once for security purposes.',
            },
            clientId: 'Client Id',
            clientSecret: 'Client Secret',
            buttons: {
                copy: 'Copy',
                download: 'Download to text file',
            },
        },
        table: {
            headers: {
                appName: 'Application name',
                clientId: 'Client Id',
                creationDate: 'Creation Date',
                owner: 'Owner',
                action: '',
            },
            noApplicationsFoundSection: {
                heading: 'You do not have any existing API applications',
                subtitle: 'Click on the Create application button to get started',
                createButton: 'Create application',
            },
        },
        dialogs: {
            create: {
                headerText: 'Create new application',
                confirmButton: 'Create',
                cancelButton: 'Cancel',
                form: {
                    fields: {
                        applicationName: 'Application name',
                    },
                    checkBoxes: {
                        label: 'API Service',
                        reachCurve: {
                            description: 'Attention-Adjusted Reach Curves',
                            value: 'media_plans:reach_curve:full_access',
                            isAvailable: true,
                        },
                        attentionData: {
                            description: 'Active Attention Seconds Data Stream',
                            value: 'attention_data:data:read',
                            isAvailable: true,
                        },
                        optimiser: {
                            description: 'Media Plan Recommendation Engine (Optimiser)',
                            value: 'optimiser',
                            isAvailable: false,
                        },
                    },
                },
                policy: {
                    title: 'Important!',
                    description:
                        "It's important to keep in mind that only one application is permitted at a time. In order to create a new one, you will need to delete any existing application first.",
                },
            },
            edit: {
                headerText: 'Edit application',
                confirmButton: 'Update',
                cancelButton: 'Cancel',
                form: {
                    fields: {
                        applicationName: 'Application name',
                    },
                },
            },
            delete: {
                headerText: 'Delete application',
                alertText: {
                    heading: 'Alert',
                    description:
                        "Are you sure you want to delete this API application <strong>{applicationName}</strong>? This action cannot be undone. If you wish to continue, please type 'delete' in the field below",
                },
                form: {
                    fields: {
                        typeDelete: {
                            label: "Type 'delete' to continue",
                            placeholder: 'delete',
                            validation: "Please type 'delete' to proceed.",
                        },
                    },
                },
                confirmButton: 'Delete',
                cancelButton: 'Cancel',
            },
        },
        toast: {
            create: {
                success: {
                    heading: 'Success',
                    subtitle: 'API application successfully created',
                },
                error: {
                    heading: 'Something went wrong',
                    subtitle: 'API application could not be created.',
                },
            },
            delete: {
                success: {
                    heading: 'Success',
                    subtitle: 'API application successfully deleted',
                },
                error: {
                    heading: 'Something went wrong',
                    subtitle: 'API application could not be deleted.',
                },
            },
            edit: {
                success: {
                    heading: 'Success',
                    subtitle: 'API application successfully updated',
                },
                error: {
                    heading: 'Something went wrong',
                    subtitle: 'API application could not be updated.',
                },
            },
        },
    },
    forbidden: {
        heading: 'Forbidden Access',
        subtitle: 'You are unauthorised to access this resource.',
    },
};
