import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    radioAnatomy.keys,
);

export const outline = definePartsStyle((props: StyleFunctionProps) => {
    const c = props.colorScheme;
    return {
        container: {
            paddingY: '.5rem',
            paddingX: '1rem',
            bgColor: 'white',
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderRadius: 'md',
            _checked: {
                borderColor: `${c}.200`,
                bgColor: `${c}.50`,
            },
        },
        label: {
            width: '100%',
        },
    };
});

export const Radio = defineMultiStyleConfig({
    variants: { outline },
});
