import { Box, Link, Text } from '@chakra-ui/react';
import { Banner, BannerProps } from '@components/organisms/banner/Banner';
import { initialisePostHog } from '@components/templates/appsLayout/NavBarLayout';
import { urls } from '@configs';
import { useGoogleAnalytics } from '@hooks/analytics/useGoogleAnalytics';
import { AppCookie, useAppContext } from '@hooks/_contexts';
import { CookiePreference, getPersistedCookieAcceptanceState } from '@states/cookieAcceptanceState';
import { persistCookieBannerCompletion } from '@states/cookieBannerCompletionState';
import React, { useState } from 'react';
import { hotjar } from 'react-hotjar';
import { Trans, useTranslation } from 'react-i18next';
import { PreferencesPage, PreferencesPageProps } from '../preferencesPage/preferencesPage';
import { initialiseMicrosoftClarity } from './initialiseMicrosoftClarity.js';

export const initialiseHotjar = (): void => {
    const hotJarId = parseInt(import.meta.env.VITE_APP_HOTJAR_ID as string, 10);
    const hotJarVersion = parseInt(import.meta.env.VITE_APP_HOTJAR_VERSION as string, 10);
    hotjar.initialize(hotJarId, hotJarVersion);
};

export const CookieBanner = (): JSX.Element => {
    const { t } = useTranslation('generic');

    // Manages the visibility of the Banner component and PreferencesPage component.
    const {
        appCookies: { strict, performance },
    } = useAppContext();

    const [isBannerVisible, toggleBannerVisibility] = useState(true);
    const [isPreferencesPageVisible, togglePreferencesPageVisibility] = useState(false);

    // Manages the toggles and cookie acceptance states.
    const [attentionPlanCookieToggled, setAttentionPlanCookieToggledToggled] = useState(true);
    const [performanceCookieToggled, setPerformanceCookieToggled] = useState(false);
    const [attentionPlanCookieAccepted, setAttentionPlanCookieAccepted] = useState(true);
    const [performanceCookieAccepted, setPerformanceCookieAccepted] = useState(false);

    // The cookie banner description text.
    const cookieBannerDescription: JSX.Element = (
        <Text>
            <Trans
                i18nKey="banner.description"
                components={{
                    privacyPolicyLink: (
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urls.AIPrivacyPolicy}
                            color="green.500"
                        />
                    ),
                }}
            />
        </Text>
    );

    const googleAnalytics = useGoogleAnalytics();

    const initialiseAnalytics = (): void => {
        googleAnalytics.initialise();
        initialiseHotjar();
        if (
            !window.location.host.includes('127.0.0.1') &&
            !window.location.host.includes('localhost')
        ) {
            initialiseMicrosoftClarity();
            initialisePostHog();
        }
    };

    // The handler invoked when a user clicks 'Accept Cookies'.
    const acceptCookiesButtonHandler = (cookies: {
        strictCookie: AppCookie;
        performanceCookie: AppCookie;
    }): void => {
        // Persist the acceptance flags for each cookie to local storage.
        // Ensure all cookies are accepted.
        strict.setCurrentValue(cookies.strictCookie);
        performance.setCurrentValue(cookies.performanceCookie);

        // Persist the cookie banner completion flag
        persistCookieBannerCompletion('bannerCompleted', { completed: true });

        // Initialise all analytic services
        initialiseAnalytics();

        // Hide Banner and PreferencesPage component.
        toggleBannerVisibility(false);
        togglePreferencesPageVisibility(false);
    };

    // The handler invoked when a user clicks 'Save'.
    const savePreferencesButtonHandler = (cookies: {
        strictCookie: AppCookie;
        performanceCookie: AppCookie;
    }): void => {
        // Persist the acceptance flags for each cookie to local storage.
        strict.setCurrentValue(cookies.strictCookie);
        performance.setCurrentValue(cookies.performanceCookie);

        // Persist the cookie banner completion flag
        persistCookieBannerCompletion('bannerCompleted', { completed: true });

        // Check if analytics should be loaded according to the user's preference for performance cookies.
        const analyticsIsAccepted =
            getPersistedCookieAcceptanceState('performanceCookies')?.accepted;

        if (analyticsIsAccepted === true) {
            // Initialise all analytic services
            initialiseAnalytics();
        } else {
            googleAnalytics.disable();
        }

        // Hide Banner and PreferencesPage component.
        toggleBannerVisibility(false);
        togglePreferencesPageVisibility(false);
    };

    // The handler invoked when a user clicks 'Exit'.
    const exitButtonHandler = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setAttentionPlanCookieAccepted: React.Dispatch<React.SetStateAction<boolean>>,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setPerformanceCookieAccepted: React.Dispatch<React.SetStateAction<boolean>>,
    ): void => {
        // Hide the PreferencesPage component and show the Banner component.
        togglePreferencesPageVisibility(false);
        toggleBannerVisibility(true);

        // Reset cookie acceptance flags.
        // setCookieAcceptanceFlags(attentionPlanCookieAcceptance);
        setAttentionPlanCookieAccepted(attentionPlanCookieAccepted);
        setPerformanceCookieAccepted(performanceCookieAccepted);
    };

    // The handler invoked when a user clicks 'Cookie Settings'.
    const cookieSettingsButtonHandler = (): void => {
        // Show the PreferencesPage component and hide the Banner component.
        togglePreferencesPageVisibility(true);
        toggleBannerVisibility(false);
    };

    // The handler invoked when a toggle is changed on the preferences page popup.
    const onAcceptanceFlagToggled = (
        cookiePreference: CookiePreference,
        isAccepted: boolean,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setAccepted: React.Dispatch<React.SetStateAction<boolean>>,
    ): void => {
        // eslint-disable-next-line default-case
        switch (cookiePreference) {
            case 'strictlyNecessaryCookies':
                setAccepted(!isAccepted);
                break;
            case 'performanceCookies':
                setAccepted(!isAccepted);
                break;
        }
    };

    // Specifies the properties for the PreferencesPage component that is used to choose the cookies to enable in the Planner.
    const preferencesPageProps: PreferencesPageProps = {
        preferencesProps: [
            {
                title: t('cookie.necessary.title'),
                description: t('cookie.necessary.description'),
                label: 'attentionPLAN™',
                isToggled: attentionPlanCookieToggled,
                onToggle: () => {
                    onAcceptanceFlagToggled(
                        'strictlyNecessaryCookies',
                        attentionPlanCookieAccepted,
                        setAttentionPlanCookieAccepted,
                    );
                    setAttentionPlanCookieToggledToggled(!attentionPlanCookieToggled);
                },
                toggleId: 'strictlyNecessaryCookies',
                disableToggle: true,
            },
            {
                title: t('cookie.performance.title'),
                description: t('cookie.performance.description'),
                label: t('cookie.performance.label'),
                isToggled: performanceCookieToggled,
                onToggle: () => {
                    onAcceptanceFlagToggled(
                        'performanceCookies',
                        performanceCookieAccepted,
                        setPerformanceCookieAccepted,
                    );
                    setPerformanceCookieToggled(!performanceCookieToggled);
                },
                toggleId: 'performanceCookies',
                disableToggle: false,
            },
        ],
        buttonProps: [
            {
                key: 'exit-button',
                children: t('button.exit'),
                onClick: () => {
                    exitButtonHandler(setAttentionPlanCookieAccepted, setPerformanceCookieAccepted);
                },
                variant: 'outline',
            },
            {
                key: 'save-button',
                children: t('button.save'),
                onClick: () => {
                    savePreferencesButtonHandler({
                        strictCookie: {
                            accepted: attentionPlanCookieAccepted,
                        },
                        performanceCookie: {
                            accepted: performanceCookieAccepted,
                        },
                    });
                },
            },
        ],
        isOpen: isPreferencesPageVisible,
    };

    const bannerProps: BannerProps = {
        title: t('banner.title'),
        description: cookieBannerDescription,
        buttonProps: [
            {
                key: 'button-accept',
                children: t('button.accept'),
                onClick: () => {
                    acceptCookiesButtonHandler({
                        strictCookie: {
                            accepted: true,
                        },
                        performanceCookie: {
                            accepted: true,
                        },
                    });
                },
            },
            {
                key: 'button-setting',
                children: t('button.setting'),
                onClick: () => {
                    cookieSettingsButtonHandler();
                },
                variant: 'outline',
            },
        ],
    };

    return (
        <Box>
            {isBannerVisible ? <Banner {...bannerProps} /> : <></>}
            {isPreferencesPageVisible ? <PreferencesPage {...preferencesPageProps} /> : <></>}
        </Box>
    );
};
