/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';

/**
 *
 * @export
 * @interface AudienceByGenderResponseDto
 */
export interface AudienceByGenderResponseDto {
    /**
     *
     * @type {Gender}
     * @memberof AudienceByGenderResponseDto
     */
    gender?: Gender;
    /**
     *
     * @type {number}
     * @memberof AudienceByGenderResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof AudienceByGenderResponseDto
     */
    averagePassiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof AudienceByGenderResponseDto
     */
    averageInactiveAttention?: number;
}

/**
 * Check if a given object implements the AudienceByGenderResponseDto interface.
 */
export function instanceOfAudienceByGenderResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AudienceByGenderResponseDtoFromJSON(json: any): AudienceByGenderResponseDto {
    return AudienceByGenderResponseDtoFromJSONTyped(json, false);
}

export function AudienceByGenderResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AudienceByGenderResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        gender: !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        averagePassiveAttention: !exists(json, 'average_passive_attention')
            ? undefined
            : json['average_passive_attention'],
        averageInactiveAttention: !exists(json, 'average_inactive_attention')
            ? undefined
            : json['average_inactive_attention'],
    };
}

export function AudienceByGenderResponseDtoToJSON(value?: AudienceByGenderResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        gender: GenderToJSON(value.gender),
        average_active_attention: value.averageActiveAttention,
        average_passive_attention: value.averagePassiveAttention,
        average_inactive_attention: value.averageInactiveAttention,
    };
}
