export const generic = {
    spinner: {
        default: 'Loading app...',
        after3s: 'Just a moment please, the app is getting ready for you.',
    },
    cookie: {
        necessary: {
            title: 'Strictly necessary cookies',
            description: `These cookies provide essential functionality and are used 
            when logging in and interacting with attentionPLAN™.`,
        },
        performance: {
            title: 'Performance cookies',
            description: `These cookies are used to analyse web traffic 
            and to understand how attentionPLAN™ is used.`,
            label: 'Performance cookies',
        },
    },
    banner: {
        title: 'This website uses cookies',
        description: `By clicking "Accept Cookies" or by enabling certain cookies through
        "Cookie settings", you consent to their use as outlined in our <privacyPolicyLink>Privacy policy</privacyPolicyLink>.`,
    },
    button: {
        accept: 'Accept cookies',
        setting: 'Cookie settings',
        save: 'Save',
        exit: 'Exit',
    },
    footer: {
        earlyAccess:
            'This is an extremely early build of attentionPLAN™. Every day, there will be new changes and fixes. We thank you for your patience.',
        support: {
            title: 'Technical support',
            body: 'Try the attentionPLAN™ <faqLink>FAQ</faqLink> or email us at <supportEmailLink>plan-support@amplifiedintelligence.com.au</supportEmailLink>.',
        },
    },
    feedback: 'Feedback',
    freeTier: {
        bannerText:
            'You are using free version with limited access. Upgrade to unlock full product potential.',
        getFullVersionTexts: [
            'Create versatile campaign scenarios for diverse objectives.',
            'Review attention performance predictions per scenario and channels.',
            'Tailor channel spends, formats, and attention-specific budgets.',
            'Buy your media with confidence!',
        ],
        stripeText:
            '<u>Stripe will securely</u> process your payment data with certified standards.',
        formatViewTooltip: 'Upgrade your plan to view the format breakdown.',
    },
    mobileScreen: {
        headerText: 'Optimal experience on desktop',
        contentText:
            "We've noticed you're accessing attentionPLAN from a mobile device. To enjoy the full experience of our app, we suggest switching to a desktop or laptop. If you just signed up, please check your email for a how to guide and a link to log into our product.",
    },
};
