import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {findProveAdFormatsAsync} from "@/redux/slices/prove/adFormat/thunks";
import {ProveAdFormat} from "@api-clients/prove/schema/ProveAdFormat";

export interface AdFormatSliceState {
    available: Array<ProveAdFormat> | undefined
    isPending: boolean
    isError: boolean
}

const initialState: AdFormatSliceState = {
    available: undefined,
    isPending: false,
    isError: false
}

export const proveAdFormatSlice = createSlice({
    name: 'prove/adFormat',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findProveAdFormatsAsync.pending, (state, action) => {
                state.isPending = true;
                state.available = undefined;
            })
            .addCase(findProveAdFormatsAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(findProveAdFormatsAsync.fulfilled, (state, action) => {
                state.available = action.payload;
                state.isPending = false;
            })
    },
})
