export const routing = {
    root: {
        url: '',
        forbidden: {
            url: 'forbidden',
        },
        error: {
            url: 'error',
        },
        signInError: {
            url: 'unexpected-error',
        },
    },
    campaign: {
        root: {
            path: 'campaigns',
        },
        create: {
            path: `create`,
        },
        edit: {
            path: `edit`,
        },
    },
    plan: {
        root: {
            path: 'plan',
        },
        create: {
            path: 'create',
        },
        scenario: {
            path: 'scenario',
        },
    },
    prove: {
        root: {
            path: 'prove',
        },
        manage: {
            path: 'manage',
        },
        dashboard: {
            path: 'dashboard',
            
        },
        demo: {
            path: 'demo/dashboard'
        }
    },
    legacy: {
        mediaPlanning: {
            url: 'manage-media-plans',
        },
    },
    benchmarks: {
        url: 'benchmarks',
    },
    strategyReports: {
        root: {
            path: 'strategy-reports',
        },
        create: {
            path: 'create',
        },
        view: {
            path: 'view',
            channelSelection: {
                path: 'channel-selection',
            },
            formatSelection: {
                path: 'format-selection',
            },
            audienceAnalysis: {
                path: 'audience-analysis',
            },
            creativeRecommendations: {
                path: 'creative-recommendations',
            },
        },
    },
    accountDetails: { url: 'account-details' },
    manageOrganisation: { url: 'manage-organisation' },
    manageClients: { url: 'manage-clients' },
    manageApplications: { url: 'manage-applications' },
    progressiveSignUp: { url: 'progressive-signup' },
    share: {
        url: 'share',
        scenario: {
            url: 'scenario',
        },
    },
    strategyReport: {
        url: `strategy-report`,
    },
} as const;
