import { FC, useMemo } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const OAuthApplicationCreationPolicy: FC = () => {
    const { t } = useTranslation();
    const policyText = useMemo(
        () => t('pages.applicationManagement.dialogs.create.policy', { returnObjects: true }),
        [],
    );

    return (
        <Alert status="info" bgColor="blue.50">
            <VStack align="left">
                <Flex>
                    <AlertIcon />
                    <AlertTitle>{policyText.title}</AlertTitle>
                </Flex>
                <AlertDescription>{policyText.description}</AlertDescription>
            </VStack>
        </Alert>
    );
};
