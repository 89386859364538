/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface BrandedMomentsResponseDto
 */
export interface BrandedMomentsResponseDto {
    /**
     *
     * @type {string}
     * @memberof BrandedMomentsResponseDto
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof BrandedMomentsResponseDto
     */
    start?: number;
    /**
     *
     * @type {number}
     * @memberof BrandedMomentsResponseDto
     */
    end?: number;
    /**
     *
     * @type {string}
     * @memberof BrandedMomentsResponseDto
     */
    creativeId?: string;
}

/**
 * Check if a given object implements the BrandedMomentsResponseDto interface.
 */
export function instanceOfBrandedMomentsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BrandedMomentsResponseDtoFromJSON(json: any): BrandedMomentsResponseDto {
    return BrandedMomentsResponseDtoFromJSONTyped(json, false);
}

export function BrandedMomentsResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): BrandedMomentsResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        start: !exists(json, 'start') ? undefined : json['start'],
        end: !exists(json, 'end') ? undefined : json['end'],
        creativeId: !exists(json, 'creative_id') ? undefined : json['creative_id'],
    };
}

export function BrandedMomentsResponseDtoToJSON(value?: BrandedMomentsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        start: value.start,
        end: value.end,
        creative_id: value.creativeId,
    };
}
