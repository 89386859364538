export const AllCurrencies = ['AUD', 'GBP', 'USD', 'EUR'] as const;

export type Currency = typeof AllCurrencies[number];

export function isCurrency(value: string): value is Currency {
    return (AllCurrencies as unknown as Array<string>).includes(value);
}

export const getCurrencySymbol = (currency: Currency): string => {
    switch (currency) {
        case 'AUD':
            return 'AU$';
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'USD':
            return 'US$';
        default:
            return '';
    }
};

export const getShortCurrencySymbol = (currency: Currency): string => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'AUD':
        case 'USD':
        default:
            return '$';
    }
};

export const CurrencyOptions: Array<string> = ['AU$', 'GB€', 'EU£', 'US$'];
