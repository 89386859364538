import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonGroup,
    Center,
    IconButton,
    TableColumnHeaderProps,
    Text,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';

export interface TablePaginatorProps extends TableColumnHeaderProps {
    selectedPage: number;
    numberOfPages: number;
    setSelectedPage: (page: number) => void;
}

export const PaginatorWithEllipsis: FC<TablePaginatorProps> = ({
    selectedPage,
    numberOfPages,
    setSelectedPage,
}) => {
    const shouldShowUpperEllipsis = selectedPage <= numberOfPages - 4;
    const shouldShowLowerEllipsis = selectedPage > 4;

    const selectablePages = useMemo(() => {
        const centerPage = Math.max(4, Math.min(selectedPage, numberOfPages - 3));

        return [centerPage - 1, centerPage, centerPage + 1].filter(
            (pageIndex) => pageIndex > 0 && pageIndex < numberOfPages,
        );
    }, [selectedPage, numberOfPages]);

    const firstPage = 1;
    const lastPage = numberOfPages;

    return (
        <ButtonGroup colorScheme="gray" variant="ghost" size="sm">
            <IconButton
                onClick={() => setSelectedPage(selectedPage - 1)}
                isDisabled={selectedPage <= 1}
                aria-label="previous page"
                icon={<ChevronLeftIcon />}
            />
            <Button
                maxW="2rem"
                onClick={() => setSelectedPage(firstPage)}
                aria-label={`page ${firstPage}`}
                isActive={selectedPage === firstPage}
            >
                1
            </Button>
            {shouldShowLowerEllipsis ? (
                <Center width="2rem">
                    <Text cursor="default">...</Text>
                </Center>
            ) : (
                <Button
                    maxW="2rem"
                    onClick={() => setSelectedPage(firstPage + 1)}
                    aria-label={`${2}`}
                    isActive={selectedPage === firstPage + 1}
                >
                    2
                </Button>
            )}
            {selectablePages.map((pageIndex) => (
                <Button
                    key={pageIndex}
                    maxW="2rem"
                    onClick={() => setSelectedPage(pageIndex)}
                    aria-label={`${pageIndex}`}
                    isActive={selectedPage === pageIndex}
                >
                    {pageIndex}
                </Button>
            ))}
            {shouldShowUpperEllipsis ? (
                <Center width="2rem">
                    <Text cursor="default">...</Text>
                </Center>
            ) : (
                <Button
                    maxW="2rem"
                    onClick={() => setSelectedPage(numberOfPages - 1)}
                    aria-label={`${numberOfPages - 1}`}
                    isActive={selectedPage === numberOfPages - 1}
                >
                    {numberOfPages - 1}
                </Button>
            )}
            {lastPage > firstPage && (
                <Button
                    maxW="2rem"
                    onClick={() => setSelectedPage(lastPage)}
                    aria-label={`page ${lastPage}`}
                    isActive={selectedPage === lastPage}
                >
                    {numberOfPages}
                </Button>
            )}
            <IconButton
                onClick={() => setSelectedPage(selectedPage + 1)}
                isDisabled={selectedPage >= numberOfPages}
                aria-label="next page"
                icon={<ChevronRightIcon />}
            />
        </ButtonGroup>
    );
};
