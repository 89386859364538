import {
    ChannelLevelCurvesResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    ScenarioWithComparisonResponseDto
} from '@api-clients/media-plan';
import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useExport } from '@hooks/exports';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { AmplifiedChartImage } from '@shared/cores';
import { downloadBlob } from '@shared/utils';
import { FC, useRef, useState } from 'react';
import { ReachCurveContainerForScreenshot } from '../../organisms';

export interface ExportToPdfButtonProps {
    scenario: ScenarioWithComparisonResponseDto;
    reachCurveData: Array<ChannelLevelCurvesResponseDto>;
    scenarioLevelResultsComparison: GetScenarioLevelResultComparisonResponseDto;
    isFreePlan: boolean;
}

export const ExportToPdfButton: FC<ExportToPdfButtonProps> = ({
    scenario,
    reachCurveData,
    scenarioLevelResultsComparison,
    isFreePlan,
}) => {
    const { exportToPdf, exportToExcel } = useExport(scenario);
    const adChannels = reachCurveData?.map((curve) => curve.channel?.adChannelName ?? '');
    const [selectedChannel, setSelectedChannel] = useState<string>('');

    const emitClickOnScenarioExportPdfEvent = usePosthogEvent('Clicked on export scenario to pdf');
    const emitClickOnScenarioExportXlsxEvent = usePosthogEvent(
        'Clicked on export scenario to xlsx',
    );

    const chartRef = useRef<AmplifiedChartImage>(null);

    const getChartImages = async (): Promise<Array<string>> => {
        const exportedImages = [];
        for (let i = 0; i < adChannels.length; i++) {
            const adChannel = adChannels[i];
            setSelectedChannel(adChannel);
            /* eslint-disable-next-line */
            await new Promise((resolve) => setTimeout(resolve, 100)); // need to give time for browser to render
            const imageDataURL = chartRef.current?.toBase64Image();
            if (imageDataURL) {
                exportedImages.push(imageDataURL);
            }
        }
        return exportedImages;
    };

    const downloadPDF = async () => {
        emitClickOnScenarioExportPdfEvent();
        const images = await getChartImages();
        const blob = await exportToPdf(images, scenarioLevelResultsComparison);
        downloadBlob(blob, `Scenario_${Date.now()}`);
    };

    const downloadExcel = async () => {
        emitClickOnScenarioExportXlsxEvent();
        const images = await getChartImages();
        const blob = await exportToExcel(images, isFreePlan);
        downloadBlob(blob, `ScenarioExport_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    leftIcon={<Icon as={ArrowUpTrayIcon} />}
                    variant="outline"
                    colorScheme="gray"
                >
                    Export
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={() => downloadPDF()}>Export to PDF</MenuItem>
                    <MenuItem onClick={() => downloadExcel()}>Export to XLSX</MenuItem>
                </MenuList>
            </Menu>
            <Box position="fixed" zIndex={-999} pointerEvents="none" opacity={0}>
                <ReachCurveContainerForScreenshot
                    selectedChannel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                    reachCurveData={reachCurveData}
                    ref={chartRef}
                />
            </Box>
        </Box>
    );
};
