import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export const ExportButton: FC<ButtonProps> = ({
    leftIcon = <Icon as={DocumentArrowDownIcon} />,
    variant = 'outline',
    colorScheme = 'gray',
    children = 'Export',
    ...props
}) => {
    return (
        <Button leftIcon={leftIcon} variant={variant} colorScheme={colorScheme} {...props}>
            {children}
        </Button>
    );
};
