import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Icon,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { AmplifiedStackedLogo } from '@components/atoms';
import { TechnicalSupportDropdown } from '@components/molecules/technicalSupportDropdown/TechnicalSupportDropdown';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

type ViewOnlyBadgeProps = { children: React.ReactNode };
export const ViewOnlyBadge: FC<ViewOnlyBadgeProps> = ({ children }) => {
    return (
        <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
                <Box
                    px="0.625rem"
                    py="0.5rem"
                    rounded="md"
                    lineHeight={1}
                    fontSize="0.875rem"
                    fontWeight={600}
                    border="1px solid"
                    borderColor="green.500"
                    color="green.500"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="0.25rem"
                >
                    View only
                    <Icon strokeWidth="2px" as={InformationCircleIcon} />
                </Box>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverBody
                    bg="white"
                    p="1.5rem"
                    color="gray.700"
                    fontSize="1rem"
                    rounded="md"
                    shadow="sm"
                >
                    {children}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

type HorizontalNavBarForSharingProps = {
    height: string | number;
    iconSize?: string | number;
    hasBorder?: boolean;
};
export const HorizontalNavBarForSharing: FC<HorizontalNavBarForSharingProps> = ({
    height,
    iconSize,
    hasBorder,
}) => {
    const navigate = useNavigate();
    return (
        <Box position="sticky" top={0} zIndex={2}>
            <Flex
                bgColor="white"
                boxShadow={hasBorder ? 'xs' : ''}
                paddingX="1rem"
                paddingY=".5rem"
                h={height}
            >
                <HStack spacing=".5rem">
                    <Center>
                        <Link as={ReactLink} to="/">
                            <AmplifiedStackedLogo height="2.5rem" />
                        </Link>
                    </Center>
                </HStack>
                <Spacer />
                <Flex gap=".5rem" alignItems="center">
                    <Link
                        href="/"
                        px="0.625rem"
                        py="0.5rem"
                        size="sm"
                        fontSize="0.875rem"
                        lineHeight={1}
                        bg="green.500"
                        color="white"
                        rounded="md"
                        _hover={{ textDecoration: 'none' }}
                    >
                        Sign up for free
                    </Link>
                    <ViewOnlyBadge>
                        <Text>
                            You are a viewer of this media plan. To ask for editor rights to make
                            changes, please{' '}
                            <Link as={ReactLink} to="/" textDecoration="underline">
                                log in
                            </Link>{' '}
                            or{' '}
                            <Link as={ReactLink} to="/" textDecoration="underline">
                                sign up
                            </Link>
                            .
                        </Text>
                    </ViewOnlyBadge>
                    <TechnicalSupportDropdown />
                </Flex>
            </Flex>
        </Box>
    );
};
