/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CampaignStatus } from './CampaignStatus';
import {
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
} from './CampaignStatus';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { UpdateConstraintsRequestDto } from './UpdateConstraintsRequestDto';
import {
    UpdateConstraintsRequestDtoFromJSON,
    UpdateConstraintsRequestDtoFromJSONTyped,
    UpdateConstraintsRequestDtoToJSON,
} from './UpdateConstraintsRequestDto';

/**
 *
 * @export
 * @interface UpdateCampaignRequestDto
 */
export interface UpdateCampaignRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    managedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    adClientId?: string | null;
    /**
     *
     * @type {CampaignStatus}
     * @memberof UpdateCampaignRequestDto
     */
    campaignStatus?: CampaignStatus;
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    campaignName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    referenceNumber?: string | null;
    /**
     *
     * @type {number}
     * @memberof UpdateCampaignRequestDto
     */
    totalBudget?: number | null;
    /**
     *
     * @type {CurrencyCode}
     * @memberof UpdateCampaignRequestDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {Date}
     * @memberof UpdateCampaignRequestDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateCampaignRequestDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateCampaignRequestDto
     */
    completedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    lastWorkingStep?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateCampaignRequestDto
     */
    campaignObjective?: string | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateCampaignRequestDto
     */
    updatedAt?: Date | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdateCampaignRequestDto
     */
    isAccessibleToEveryone?: boolean | null;
    /**
     *
     * @type {UpdateConstraintsRequestDto}
     * @memberof UpdateCampaignRequestDto
     */
    constraints?: UpdateConstraintsRequestDto;
}

/**
 * Check if a given object implements the UpdateCampaignRequestDto interface.
 */
export function instanceOfUpdateCampaignRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCampaignRequestDtoFromJSON(json: any): UpdateCampaignRequestDto {
    return UpdateCampaignRequestDtoFromJSONTyped(json, false);
}

export function UpdateCampaignRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateCampaignRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        managedByUserId: !exists(json, 'managed_by_user_id')
            ? undefined
            : json['managed_by_user_id'],
        adClientId: !exists(json, 'ad_client_id') ? undefined : json['ad_client_id'],
        campaignStatus: !exists(json, 'campaign_status')
            ? undefined
            : CampaignStatusFromJSON(json['campaign_status']),
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
        referenceNumber: !exists(json, 'reference_number') ? undefined : json['reference_number'],
        totalBudget: !exists(json, 'total_budget') ? undefined : json['total_budget'],
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        completedAt: !exists(json, 'completed_at')
            ? undefined
            : json['completed_at'] === null
            ? null
            : new Date(json['completed_at']),
        lastWorkingStep: !exists(json, 'last_working_step') ? undefined : json['last_working_step'],
        campaignObjective: !exists(json, 'campaign_objective')
            ? undefined
            : json['campaign_objective'],
        updatedAt: !exists(json, 'updated_at')
            ? undefined
            : json['updated_at'] === null
            ? null
            : new Date(json['updated_at']),
        isAccessibleToEveryone: !exists(json, 'is_accessible_to_everyone')
            ? undefined
            : json['is_accessible_to_everyone'],
        constraints: !exists(json, 'constraints')
            ? undefined
            : UpdateConstraintsRequestDtoFromJSON(json['constraints']),
    };
}

export function UpdateCampaignRequestDtoToJSON(value?: UpdateCampaignRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        managed_by_user_id: value.managedByUserId,
        ad_client_id: value.adClientId,
        campaign_status: CampaignStatusToJSON(value.campaignStatus),
        campaign_name: value.campaignName,
        reference_number: value.referenceNumber,
        total_budget: value.totalBudget,
        currency: CurrencyCodeToJSON(value.currency),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        completed_at:
            value.completedAt === undefined
                ? undefined
                : value.completedAt === null
                ? null
                : value.completedAt.toISOString(),
        last_working_step: value.lastWorkingStep,
        campaign_objective: value.campaignObjective,
        updated_at:
            value.updatedAt === undefined
                ? undefined
                : value.updatedAt === null
                ? null
                : value.updatedAt.toISOString(),
        is_accessible_to_everyone: value.isAccessibleToEveryone,
        constraints: UpdateConstraintsRequestDtoToJSON(value.constraints),
    };
}
