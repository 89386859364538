/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { BaseScenarioResponseDto } from './BaseScenarioResponseDto';
import {
    BaseScenarioResponseDtoFromJSON,
    BaseScenarioResponseDtoFromJSONTyped,
    BaseScenarioResponseDtoToJSON,
} from './BaseScenarioResponseDto';
import type { CampaignStatus } from './CampaignStatus';
import {
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
} from './CampaignStatus';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { ScenarioResponseDto } from './ScenarioResponseDto';
import {
    ScenarioResponseDtoFromJSON,
    ScenarioResponseDtoFromJSONTyped,
    ScenarioResponseDtoToJSON,
} from './ScenarioResponseDto';

/**
 *
 * @export
 * @interface CampaignResponseDto
 */
export interface CampaignResponseDto {
    /**
     *
     * @type {Array<ScenarioResponseDto>}
     * @memberof CampaignResponseDto
     */
    scenarios?: Array<ScenarioResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    organisationId?: string;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    createdByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    managedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    adClientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    campaignName?: string | null;
    /**
     *
     * @type {CampaignStatus}
     * @memberof CampaignResponseDto
     */
    campaignStatus?: CampaignStatus;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    referenceNumber?: string | null;
    /**
     *
     * @type {number}
     * @memberof CampaignResponseDto
     */
    totalBudget?: number | null;
    /**
     *
     * @type {CurrencyCode}
     * @memberof CampaignResponseDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {Date}
     * @memberof CampaignResponseDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof CampaignResponseDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    lastWorkingStep?: string | null;
    /**
     *
     * @type {Date}
     * @memberof CampaignResponseDto
     */
    completedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    campaignObjective?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof CampaignResponseDto
     */
    isAccessibleToEveryone?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof CampaignResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof CampaignResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof CampaignResponseDto
     */
    updatedAt?: Date;
    /**
     *
     * @type {BaseScenarioResponseDto}
     * @memberof CampaignResponseDto
     */
    constraints?: BaseScenarioResponseDto;
}

/**
 * Check if a given object implements the CampaignResponseDto interface.
 */
export function instanceOfCampaignResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CampaignResponseDtoFromJSON(json: any): CampaignResponseDto {
    return CampaignResponseDtoFromJSONTyped(json, false);
}

export function CampaignResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CampaignResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        scenarios: !exists(json, 'scenarios')
            ? undefined
            : json['scenarios'] === null
            ? null
            : (json['scenarios'] as Array<any>).map(ScenarioResponseDtoFromJSON),
        organisationId: !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
        managedByUserId: !exists(json, 'managed_by_user_id')
            ? undefined
            : json['managed_by_user_id'],
        adClientId: !exists(json, 'ad_client_id') ? undefined : json['ad_client_id'],
        campaignName: !exists(json, 'campaign_name') ? undefined : json['campaign_name'],
        campaignStatus: !exists(json, 'campaign_status')
            ? undefined
            : CampaignStatusFromJSON(json['campaign_status']),
        referenceNumber: !exists(json, 'reference_number') ? undefined : json['reference_number'],
        totalBudget: !exists(json, 'total_budget') ? undefined : json['total_budget'],
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        lastWorkingStep: !exists(json, 'last_working_step') ? undefined : json['last_working_step'],
        completedAt: !exists(json, 'completed_at')
            ? undefined
            : json['completed_at'] === null
            ? null
            : new Date(json['completed_at']),
        campaignObjective: !exists(json, 'campaign_objective')
            ? undefined
            : json['campaign_objective'],
        isAccessibleToEveryone: !exists(json, 'is_accessible_to_everyone')
            ? undefined
            : json['is_accessible_to_everyone'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        constraints: !exists(json, 'constraints')
            ? undefined
            : BaseScenarioResponseDtoFromJSON(json['constraints']),
    };
}

export function CampaignResponseDtoToJSON(value?: CampaignResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        scenarios:
            value.scenarios === undefined
                ? undefined
                : value.scenarios === null
                ? null
                : (value.scenarios as Array<any>).map(ScenarioResponseDtoToJSON),
        organisation_id: value.organisationId,
        campaign_id: value.campaignId,
        created_by_user_id: value.createdByUserId,
        managed_by_user_id: value.managedByUserId,
        ad_client_id: value.adClientId,
        campaign_name: value.campaignName,
        campaign_status: CampaignStatusToJSON(value.campaignStatus),
        reference_number: value.referenceNumber,
        total_budget: value.totalBudget,
        currency: CurrencyCodeToJSON(value.currency),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        last_working_step: value.lastWorkingStep,
        completed_at:
            value.completedAt === undefined
                ? undefined
                : value.completedAt === null
                ? null
                : value.completedAt.toISOString(),
        campaign_objective: value.campaignObjective,
        is_accessible_to_everyone: value.isAccessibleToEveryone,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        constraints: BaseScenarioResponseDtoToJSON(value.constraints),
    };
}
