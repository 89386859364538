import {createSlice} from '@reduxjs/toolkit'
import {
    createProveCampaignAsync,
    findAllProveCampaignsAsync,
    selectProveCampaignAsync,
} from "@/redux/slices/prove/campaign/thunks";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {globalCampaignSlice} from "@redux/slices/global/campaign";

export interface CampaignState {
    available: ProveCampaign[],
    selected: ProveCampaign | undefined,
    isPending: boolean
    isError: boolean
}

const initialState: CampaignState = {
    available: [],
    selected: undefined,
    isPending: false,
    isError: false
}

export const proveCampaignSlice = createSlice({
    name: 'prove/campaign',
    initialState,
    reducers: {
        resetSelectedProveCampaign: (state) => {
            state.selected = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findAllProveCampaignsAsync.pending, (state, action) => {
                state.isPending = true;
                state.selected = undefined;
                state.available = [];
            })
            .addCase(findAllProveCampaignsAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(findAllProveCampaignsAsync.fulfilled, (state, action) => {
                state.available = action.payload;
                state.isPending = false;
            })
            .addCase(selectProveCampaignAsync.pending, (state, action) => {
                state.isPending = true;
                state.selected = undefined;
            })
            .addCase(selectProveCampaignAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(selectProveCampaignAsync.fulfilled, (state, action) => {
                state.selected = action.payload;
                state.isPending = false;
            })
            .addCase(createProveCampaignAsync.pending, (state, action) => {
                state.isPending = true;
                state.selected = undefined;
            })
            .addCase(createProveCampaignAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(createProveCampaignAsync.fulfilled, (state, action) => {
                state.available.push(action.payload);
                state.selected = action.payload;
                state.isPending = false;
            })
    }
})

export const { resetSelectedProveCampaign } = proveCampaignSlice.actions;
