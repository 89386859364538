import {
    AccessLevelType,
    allAccessLevelTypes,
    InviteNewUserDto,
    OrganisationResponseDto,
} from '@api-clients/account-manager';
import { Input, Select, VStack } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInviteUser } from '@hooks/organisations/useInviteUser';
import { useInviteUserToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { snakeCaseToCapitalised } from '@shared/utils';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ErrorAlert, InputWrapper } from '@components/atoms';
import { useManageMemberFormContext } from '@components/molecules/modals/addNewMember/AddNewMemberContext';
import { useTranslation } from 'react-i18next';
import { useCurrentActiveSubscription } from '@hooks/subscriptions';
import { useGetFeatureBalances } from '@hooks/subscriptions/useGetFeatureBalances';

export interface AddNewMemberProps {
    organisation: OrganisationResponseDto;
    isOpen: boolean;
    onClose: () => void;
}

export const AddNewMember: FC<AddNewMemberProps> = ({ organisation, isOpen, onClose }) => {
    const { t } = useTranslation();
    const {
        setCurrentContextValues: { setOrganisation, setFeatureBalances },
    } = useAppContextHelper();
    const { getFeatureBalances } = useGetFeatureBalances();

    const { errorAddNewMember, setErrorAddNewMember, errorInviteMessage, setErrorInviteMessage } =
        useManageMemberFormContext();
    const { validationSchema, inviteNewUser } = useInviteUser();
    const { successInviteUserToast } = useInviteUserToast();
    // Manage form status
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid, touchedFields, isSubmitting },
    } = useForm<InviteNewUserDto>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const onFormSave = async (data: InviteNewUserDto) => {
        try {
            const response = await inviteNewUser(organisation.id!, data);
            const currentFeatureBalances = await getFeatureBalances();
            if (currentFeatureBalances) {
                setOrganisation(response);
                setFeatureBalances(currentFeatureBalances);
            }
            setErrorAddNewMember(false);
            successInviteUserToast(
                `${data.firstName} ${data.lastName}`,
                organisation.organisationName,
            );
            onClose();
        } catch (e: any) {
            setErrorAddNewMember(true);
            setErrorInviteMessage(e.message);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            reset();
            setErrorAddNewMember(false);
        }
    }, [isOpen]);

    return (
        <ConfirmDialog
            headerText={t('labels.dialogs.addNewMember.header')}
            confirmButtonText={t('labels.dialogs.addNewMember.confirmButtonText')}
            cancelButtonText={t('labels.dialogs.addNewMember.cancelButtonText')}
            isOpen={isOpen}
            onClose={(isConfirmation) => {
                if (isConfirmation) {
                    handleSubmit(onFormSave)();
                } else {
                    onClose();
                }
            }}
            isLoading={isSubmitting}
            isConfirmDisabled={!isValid}
        >
            <form onSubmit={handleSubmit(onFormSave)}>
                <VStack spacing="1rem" align="left">
                    {errorAddNewMember && <ErrorAlert errorMessage={errorInviteMessage} />}
                    <InputWrapper
                        isRequired
                        formLabel={t('labels.forms.addNewMember.firstName.field')}
                        formErrorText={errors.firstName?.message}
                        isInvalid={touchedFields.firstName && !!errors.firstName}
                    >
                        <Input {...register('firstName')} />
                    </InputWrapper>
                    <InputWrapper
                        isRequired
                        formLabel={t('labels.forms.addNewMember.lastName.field')}
                        formErrorText={errors.lastName?.message}
                        isInvalid={touchedFields.lastName && !!errors.lastName}
                    >
                        <Input {...register('lastName')} />
                    </InputWrapper>
                    <InputWrapper
                        isRequired
                        formLabel={t('labels.forms.addNewMember.email.field')}
                        formErrorText={errors.emailAddress?.message}
                        isInvalid={touchedFields.emailAddress && !!errors.emailAddress}
                    >
                        <Input type="email" {...register('emailAddress')} />
                    </InputWrapper>
                    <InputWrapper
                        formLabel={t('labels.forms.addNewMember.accessLevel.field')}
                        isRequired
                        isInvalid={!!errors.accessLevel}
                    >
                        <Select {...register('accessLevel')}>
                            {allAccessLevelTypes
                                .filter(
                                    (option: AccessLevelType) => option !== AccessLevelType.Owner,
                                )
                                .map((accessType) => (
                                    <option key={accessType} value={accessType}>
                                        {snakeCaseToCapitalised(accessType)}
                                    </option>
                                ))}
                        </Select>
                    </InputWrapper>
                </VStack>
            </form>
        </ConfirmDialog>
    );
};
