import {
    ChannelLevelCurvesResponseDto,
    OptimisationStrategy,
    ScenarioLevelCurvesResponseDto,
} from '@api-clients/media-plan';
import { Center, Divider, Flex, Spacer, Spinner } from '@chakra-ui/react';
import { RadioField } from '@components/atoms';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReachCurveGraph } from '../../molecules/chart/reachCurveGraph/ReachCurveGraph';

export interface ReachCurveContainerProps {
    reachCurveData?: Array<ChannelLevelCurvesResponseDto>;
    height?: string | number;
    canUserSeeScenarioDetails?: boolean;
}

/**
 * function which sorts baseline curves to the top then sorts the rest alphabetically
 */
const sortReachCurves = (a: ScenarioLevelCurvesResponseDto, b: ScenarioLevelCurvesResponseDto) => {
    if (
        b.optimisationStrategy === OptimisationStrategy.Base &&
        a.optimisationStrategy !== OptimisationStrategy.Base
    ) {
        return 1;
    }
    if (
        a.optimisationStrategy === OptimisationStrategy.Base &&
        b.optimisationStrategy !== OptimisationStrategy.Base
    ) {
        return -1;
    }
    return a.scenarioName?.localeCompare(b.scenarioName ?? '') ?? 0;
};

export const ReachCurveContainer: FC<ReachCurveContainerProps> = ({
    reachCurveData,
    height = '32rem',
    canUserSeeScenarioDetails,
}) => {
    const { t } = useTranslation('mediaPlans');

    const [selectedChannel, setSelectedChannel] = useState<string>();

    const adChannels = useMemo(
        () => reachCurveData?.map((curve) => curve.channel?.adChannelName ?? ''),
        [reachCurveData],
    );

    useEffect(() => {
        if (!selectedChannel || !adChannels?.includes(selectedChannel)) {
            setSelectedChannel(adChannels?.at(0));
        }
    }, [adChannels, selectedChannel]);

    const selectedChannelCurve = useMemo(
        () => reachCurveData?.find((curve) => curve.channel?.adChannelName === selectedChannel),
        [selectedChannel, reachCurveData],
    );

    return (
        <Flex
            height={height}
            padding="1.25rem"
            boxShadow="xs"
            bgColor="white"
            borderRadius="lg"
            direction="row"
        >
            {reachCurveData && adChannels ? (
                <>
                    <Flex
                        minWidth="12rem"
                        direction="column"
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                    >
                        <Center
                            borderRadius="inherit"
                            fontWeight="semibold"
                            bgColor="gray.50"
                            paddingY="1rem"
                        >
                            {t('mediaPlanning.reachCurveChart.channelSelector')}
                        </Center>
                        <Divider />
                        <Center padding="1.5rem">
                            <RadioField
                                spacing="1rem"
                                direction="column"
                                selectedOption={selectedChannel}
                                onChange={(value) => setSelectedChannel(value as string)}
                                options={adChannels.sort((a, b) => a.localeCompare(b))}
                            />
                        </Center>
                    </Flex>
                    {selectedChannelCurve && (
                        <Flex overflow="hidden" direction="column" flex={1}>
                            <Flex>
                                <Spacer />
                            </Flex>

                            <ReachCurveGraph
                                canUserSeeScenarioDetails={canUserSeeScenarioDetails}
                                channelCurves={{
                                    scenarios:
                                        selectedChannelCurve?.scenarios?.sort(sortReachCurves),
                                    ...selectedChannelCurve,
                                }}
                            />
                        </Flex>
                    )}
                </>
            ) : (
                <Center w="100%">
                    <Spinner />
                </Center>
            )}
        </Flex>
    );
};
