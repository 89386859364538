/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdChannelResponseDto } from './AdChannelResponseDto';
import {
    AdChannelResponseDtoFromJSON,
    AdChannelResponseDtoFromJSONTyped,
    AdChannelResponseDtoToJSON,
} from './AdChannelResponseDto';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';
import type { FormatLevelResultComparisonResponseDto } from './FormatLevelResultComparisonResponseDto';
import {
    FormatLevelResultComparisonResponseDtoFromJSON,
    FormatLevelResultComparisonResponseDtoFromJSONTyped,
    FormatLevelResultComparisonResponseDtoToJSON,
} from './FormatLevelResultComparisonResponseDto';

/**
 *
 * @export
 * @interface ChannelLevelResultComparisonResponseDto
 */
export interface ChannelLevelResultComparisonResponseDto {
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    mix?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    budget?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    cpm?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    reachPercentage?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    impression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    frequency?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    totalAudience?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    targetAudience?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    reach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    trp?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    cpp?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    activeAttentionPerImpression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    activeAttentionPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    passiveAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    passiveAttentionPerImpression?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    passiveAttentionPerReach?: DecimalComparisonValue;
    /**
     *
     * @type {Array<FormatLevelResultComparisonResponseDto>}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    formatLevelResults?: Array<FormatLevelResultComparisonResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    scenarioLevelResultId?: string;
    /**
     *
     * @type {AdChannelResponseDto}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    adChannel?: AdChannelResponseDto;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    iota?: number | null;
    /**
     *
     * @type {string}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ChannelLevelResultComparisonResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ChannelLevelResultComparisonResponseDto interface.
 */
export function instanceOfChannelLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelLevelResultComparisonResponseDtoFromJSON(
    json: any,
): ChannelLevelResultComparisonResponseDto {
    return ChannelLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function ChannelLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mix: !exists(json, 'mix') ? undefined : DecimalComparisonValueFromJSON(json['mix']),
        budget: !exists(json, 'budget')
            ? undefined
            : DecimalComparisonValueFromJSON(json['budget']),
        cpm: !exists(json, 'cpm') ? undefined : DecimalComparisonValueFromJSON(json['cpm']),
        reachPercentage: !exists(json, 'reach_percentage')
            ? undefined
            : DecimalComparisonValueFromJSON(json['reach_percentage']),
        impression: !exists(json, 'impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impression']),
        frequency: !exists(json, 'frequency')
            ? undefined
            : DecimalComparisonValueFromJSON(json['frequency']),
        totalAudience: !exists(json, 'total_audience')
            ? undefined
            : DecimalComparisonValueFromJSON(json['total_audience']),
        targetAudience: !exists(json, 'target_audience')
            ? undefined
            : DecimalComparisonValueFromJSON(json['target_audience']),
        reach: !exists(json, 'reach') ? undefined : DecimalComparisonValueFromJSON(json['reach']),
        trp: !exists(json, 'trp') ? undefined : DecimalComparisonValueFromJSON(json['trp']),
        cpp: !exists(json, 'cpp') ? undefined : DecimalComparisonValueFromJSON(json['cpp']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        activeAttentionPerImpression: !exists(json, 'active_attention_per_impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_per_impression']),
        activeAttentionPerReach: !exists(json, 'active_attention_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_per_reach']),
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_volume_seconds']),
        passiveAttentionPerImpression: !exists(json, 'passive_attention_per_impression')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_per_impression']),
        passiveAttentionPerReach: !exists(json, 'passive_attention_per_reach')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_per_reach']),
        formatLevelResults: !exists(json, 'format_level_results')
            ? undefined
            : json['format_level_results'] === null
            ? null
            : (json['format_level_results'] as Array<any>).map(
                  FormatLevelResultComparisonResponseDtoFromJSON,
              ),
        scenarioLevelResultId: !exists(json, 'scenario_level_result_id')
            ? undefined
            : json['scenario_level_result_id'],
        adChannel: !exists(json, 'ad_channel')
            ? undefined
            : AdChannelResponseDtoFromJSON(json['ad_channel']),
        iota: !exists(json, 'iota') ? undefined : json['iota'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ChannelLevelResultComparisonResponseDtoToJSON(
    value?: ChannelLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mix: DecimalComparisonValueToJSON(value.mix),
        budget: DecimalComparisonValueToJSON(value.budget),
        cpm: DecimalComparisonValueToJSON(value.cpm),
        reach_percentage: DecimalComparisonValueToJSON(value.reachPercentage),
        impression: DecimalComparisonValueToJSON(value.impression),
        frequency: DecimalComparisonValueToJSON(value.frequency),
        total_audience: DecimalComparisonValueToJSON(value.totalAudience),
        target_audience: DecimalComparisonValueToJSON(value.targetAudience),
        reach: DecimalComparisonValueToJSON(value.reach),
        trp: DecimalComparisonValueToJSON(value.trp),
        cpp: DecimalComparisonValueToJSON(value.cpp),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        active_attention_per_impression: DecimalComparisonValueToJSON(
            value.activeAttentionPerImpression,
        ),
        active_attention_per_reach: DecimalComparisonValueToJSON(value.activeAttentionPerReach),
        passive_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.passiveAttentionVolumeSeconds,
        ),
        passive_attention_per_impression: DecimalComparisonValueToJSON(
            value.passiveAttentionPerImpression,
        ),
        passive_attention_per_reach: DecimalComparisonValueToJSON(value.passiveAttentionPerReach),
        format_level_results:
            value.formatLevelResults === undefined
                ? undefined
                : value.formatLevelResults === null
                ? null
                : (value.formatLevelResults as Array<any>).map(
                      FormatLevelResultComparisonResponseDtoToJSON,
                  ),
        scenario_level_result_id: value.scenarioLevelResultId,
        ad_channel: AdChannelResponseDtoToJSON(value.adChannel),
        iota: value.iota,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
