/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { BrandedMomentsResponseDto } from './BrandedMomentsResponseDto';
import {
    BrandedMomentsResponseDtoFromJSON,
    BrandedMomentsResponseDtoFromJSONTyped,
    BrandedMomentsResponseDtoToJSON,
} from './BrandedMomentsResponseDto';

/**
 *
 * @export
 * @interface CreativeResponseDto
 */
export interface CreativeResponseDto {
    /**
     *
     * @type {Array<BrandedMomentsResponseDto>}
     * @memberof CreativeResponseDto
     */
    brandedMoments?: Array<BrandedMomentsResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof CreativeResponseDto
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof CreativeResponseDto
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CreativeResponseDto
     */
    duration?: number;
}

/**
 * Check if a given object implements the CreativeResponseDto interface.
 */
export function instanceOfCreativeResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreativeResponseDtoFromJSON(json: any): CreativeResponseDto {
    return CreativeResponseDtoFromJSONTyped(json, false);
}

export function CreativeResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreativeResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        brandedMoments: !exists(json, 'branded_moments')
            ? undefined
            : json['branded_moments'] === null
            ? null
            : (json['branded_moments'] as Array<any>).map(BrandedMomentsResponseDtoFromJSON),
        id: !exists(json, 'id') ? undefined : json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        duration: !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function CreativeResponseDtoToJSON(value?: CreativeResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        branded_moments:
            value.brandedMoments === undefined
                ? undefined
                : value.brandedMoments === null
                ? null
                : (value.brandedMoments as Array<any>).map(BrandedMomentsResponseDtoToJSON),
        id: value.id,
        name: value.name,
        duration: value.duration,
    };
}
