/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface AdClientResponseDto
 */
export interface AdClientResponseDto {
    /**
     *
     * @type {string}
     * @memberof AdClientResponseDto
     */
    adClientName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AdClientResponseDto
     */
    isArchived?: boolean;
    /**
     *
     * @type {string}
     * @memberof AdClientResponseDto
     */
    primaryContactName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdClientResponseDto
     */
    phone?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdClientResponseDto
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdClientResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof AdClientResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof AdClientResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the AdClientResponseDto interface.
 */
export function instanceOfAdClientResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdClientResponseDtoFromJSON(json: any): AdClientResponseDto {
    return AdClientResponseDtoFromJSONTyped(json, false);
}

export function AdClientResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AdClientResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adClientName: !exists(json, 'ad_client_name') ? undefined : json['ad_client_name'],
        isArchived: !exists(json, 'is_archived') ? undefined : json['is_archived'],
        primaryContactName: !exists(json, 'primary_contact_name')
            ? undefined
            : json['primary_contact_name'],
        phone: !exists(json, 'phone') ? undefined : json['phone'],
        email: !exists(json, 'email') ? undefined : json['email'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function AdClientResponseDtoToJSON(value?: AdClientResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_client_name: value.adClientName,
        is_archived: value.isArchived,
        primary_contact_name: value.primaryContactName,
        phone: value.phone,
        email: value.email,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
