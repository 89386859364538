import { fonts } from '@assets/design-system/theming/typography';
import { ComponentStyleConfig, StyleFunctionProps } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
    baseStyle: {
        fontFamily: fonts.heading,
        color: 'green.500',
        fontWeight: 'semibold',
    },
    sizes: {
        sm: {
            fontSize: '1.125rem',
        },
        md: {
            fontSize: '1.25rem',
        },
        lg: {
            fontSize: '1.375rem',
        },
        xl: {
            fontSize: '1.5rem',
        },
        '2xl': {
            fontSize: '1.625rem',
        },
        '3xl': {
            fontSize: '1.75rem',
        },
        '4xl': {
            fontSize: '1.825rem',
        },
        '5xl': {
            fontSize: '1.825rem',
        },
    },
};
