/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';

/**
 *
 * @export
 * @interface ComparisonMeta
 */
export interface ComparisonMeta {
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof ComparisonMeta
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {string}
     * @memberof ComparisonMeta
     */
    scenarioName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComparisonMeta
     */
    scenarioId?: string | null;
}

/**
 * Check if a given object implements the ComparisonMeta interface.
 */
export function instanceOfComparisonMeta(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComparisonMetaFromJSON(json: any): ComparisonMeta {
    return ComparisonMetaFromJSONTyped(json, false);
}

export function ComparisonMetaFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ComparisonMeta {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        scenarioId: !exists(json, 'scenario_id') ? undefined : json['scenario_id'],
    };
}

export function ComparisonMetaToJSON(value?: ComparisonMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        scenario_name: value.scenarioName,
        scenario_id: value.scenarioId,
    };
}
