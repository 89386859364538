import {
    CampaignApi,
    CampaignResponseDto,
    CreateANewCampaignRequest,
    CreateCampaignRequestDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useHelper } from '@shared/utils';

interface CreateCampaignHook {
    validationSchema: yup.ObjectSchema<any>;
    createCampaign: (
        createCampaignRequestDto: CreateCampaignRequestDto,
    ) => Promise<CampaignResponseDto>;
}

type useCreateCampaignProps = () => CreateCampaignHook;

export const useCreateCampaign: useCreateCampaignProps = () => {
    const { t } = useTranslation();

    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    const validationSchema = yup.object().shape({
        campaignName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.campaign.campaignName.isRequired')),
    });

    async function createCampaign(
        createCampaignRequestDto: CreateCampaignRequestDto,
    ): Promise<CampaignResponseDto> {
        try {
            const config = await getMediaPlanConfig();
            const request: CreateANewCampaignRequest = {
                createCampaignRequestDto,
            };

            const api = new CampaignApi(config);
            return await api.createANewCampaign(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        validationSchema,
        createCampaign,
    };
};
