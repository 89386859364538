import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface NavLinkStepperButtonProps {
    title: string;
    description: string;
    url: string;
    colorScheme?: string;
}

export const NavLinkStepperButton: FC<NavLinkStepperButtonProps> = ({
    title,
    description,
    url,
    colorScheme = 'orange',
}) => {
    return (
        <NavLink style={{ width: '100%' }} to={url}>
            {({ isActive }) => (
                <Box
                    paddingTop={0}
                    paddingX=".5rem"
                    paddingBottom=".5rem"
                    borderBottomWidth=".25rem"
                    borderBottomRadius={0}
                    borderBottomColor={isActive ? `${colorScheme}.500` : 'gray.200'}
                >
                    <Flex flexDirection="column">
                        <Text fontSize="sm" fontWeight="medium" textAlign="left" color={isActive ? `${colorScheme}.500` : 'gray.400'}>
                            {title}
                        </Text>
                        <Text textAlign="left" color={isActive ? 'gray.600' : 'gray.400'}>
                            {description}
                        </Text>
                    </Flex>
                </Box>
            )}
        </NavLink>
    );
};
