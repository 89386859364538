/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { RawImportedDataResponseDto } from './RawImportedDataResponseDto';
import {
    RawImportedDataResponseDtoFromJSON,
    RawImportedDataResponseDtoFromJSONTyped,
    RawImportedDataResponseDtoToJSON,
} from './RawImportedDataResponseDto';

/**
 *
 * @export
 * @interface ImportedFileResponseDto
 */
export interface ImportedFileResponseDto {
    /**
     *
     * @type {string}
     * @memberof ImportedFileResponseDto
     */
    campaignId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileResponseDto
     */
    uploadedByUserId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileResponseDto
     */
    templateName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ImportedFileResponseDto
     */
    fileName?: string | null;
    /**
     *
     * @type {Array<RawImportedDataResponseDto>}
     * @memberof ImportedFileResponseDto
     */
    rawImportedData?: Array<RawImportedDataResponseDto> | null;
}

/**
 * Check if a given object implements the ImportedFileResponseDto interface.
 */
export function instanceOfImportedFileResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportedFileResponseDtoFromJSON(json: any): ImportedFileResponseDto {
    return ImportedFileResponseDtoFromJSONTyped(json, false);
}

export function ImportedFileResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ImportedFileResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        uploadedByUserId: !exists(json, 'uploaded_by_user_id')
            ? undefined
            : json['uploaded_by_user_id'],
        templateName: !exists(json, 'template_name') ? undefined : json['template_name'],
        fileName: !exists(json, 'file_name') ? undefined : json['file_name'],
        rawImportedData: !exists(json, 'raw_imported_data')
            ? undefined
            : json['raw_imported_data'] === null
            ? null
            : (json['raw_imported_data'] as Array<any>).map(RawImportedDataResponseDtoFromJSON),
    };
}

export function ImportedFileResponseDtoToJSON(value?: ImportedFileResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        uploaded_by_user_id: value.uploadedByUserId,
        template_name: value.templateName,
        file_name: value.fileName,
        raw_imported_data:
            value.rawImportedData === undefined
                ? undefined
                : value.rawImportedData === null
                ? null
                : (value.rawImportedData as Array<any>).map(RawImportedDataResponseDtoToJSON),
    };
}
