import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveOverviewReportDemoData: ProveReportData = {
    "metrics": {
        "avgActiveAttention": 1.21,
        "sumTotalAttention": 5958368,
        "avgNonAttention": 1.25,
        "sumNonAttention": 2752985,
        "sumActiveAttention": 1021482,
        "impressions": 841975,
        "sumPassiveAttention": 4936888,
        "avgPassiveAttention": 5.86
    }
};