/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface SelfSignUpOrganisationDto
 */
export interface SelfSignUpOrganisationDto {
    /**
     *
     * @type {string}
     * @memberof SelfSignUpOrganisationDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {string}
     * @memberof SelfSignUpOrganisationDto
     */
    region?: string | null;
}

/**
 * Check if a given object implements the SelfSignUpOrganisationDto interface.
 */
export function instanceOfSelfSignUpOrganisationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SelfSignUpOrganisationDtoFromJSON(json: any): SelfSignUpOrganisationDto {
    return SelfSignUpOrganisationDtoFromJSONTyped(json, false);
}

export function SelfSignUpOrganisationDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SelfSignUpOrganisationDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        region: !exists(json, 'region') ? undefined : json['region'],
    };
}

export function SelfSignUpOrganisationDtoToJSON(value?: SelfSignUpOrganisationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organisation_name: value.organisationName,
        region: value.region,
    };
}
