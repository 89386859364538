/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ScenarioMinSpendPerChannelResponseDto
 */
export interface ScenarioMinSpendPerChannelResponseDto {
    /**
     *
     * @type {string}
     * @memberof ScenarioMinSpendPerChannelResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioMinSpendPerChannelResponseDto
     */
    adChannelName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioMinSpendPerChannelResponseDto
     */
    minSpendPercentage?: number;
}

/**
 * Check if a given object implements the ScenarioMinSpendPerChannelResponseDto interface.
 */
export function instanceOfScenarioMinSpendPerChannelResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioMinSpendPerChannelResponseDtoFromJSON(
    json: any,
): ScenarioMinSpendPerChannelResponseDto {
    return ScenarioMinSpendPerChannelResponseDtoFromJSONTyped(json, false);
}

export function ScenarioMinSpendPerChannelResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioMinSpendPerChannelResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
        minSpendPercentage: !exists(json, 'min_spend_percentage')
            ? undefined
            : json['min_spend_percentage'],
    };
}

export function ScenarioMinSpendPerChannelResponseDtoToJSON(
    value?: ScenarioMinSpendPerChannelResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_channel_code: value.adChannelCode,
        ad_channel_name: value.adChannelName,
        min_spend_percentage: value.minSpendPercentage,
    };
}
