import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FC } from 'react';

export interface HelpSectionProps {
    onClose: () => void;
    isOpen: boolean;
    title: string;
    content: {
        description: string[];
        campaignObjective: string[];
        attentionPattern: string[];
        brandSize: string[];
        buyingStrategy: string[];
        recommendedCreativeContent: string[];
    };
}

export const HelpSection: FC<HelpSectionProps> = ({ onClose, isOpen, title, content }) => {
    return (
        <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="sm">
            <DrawerContent>
                <DrawerHeader>
                    <DrawerCloseButton top="1rem" right="1rem" />
                </DrawerHeader>
                <DrawerBody>
                    <Stack spacing="1rem">
                        <VStack width="100%" align="flex-start">
                            <Text fontSize="1.25rem" fontWeight={600}>
                                {title}
                            </Text>
                            <Spacer />
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                {content.description.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                <Text fontSize="1rem" fontWeight={600}>
                                    Campaign objective
                                </Text>
                                {content.campaignObjective.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                <Text fontSize="1rem" fontWeight={600}>
                                    Attention pattern
                                </Text>
                                {content.attentionPattern.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                <Text fontSize="1rem" fontWeight={600}>
                                    Brand size
                                </Text>
                                {content.brandSize.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                <Text fontSize="1rem" fontWeight={600}>
                                    Buying strategy
                                </Text>
                                {content.buyingStrategy.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                            <VStack width="100%" align="flex-start" marginTop="1rem">
                                <Text fontSize="1rem" fontWeight={600}>
                                    Recommended creative content
                                </Text>
                                {content.recommendedCreativeContent.map((item, index) => (
                                    <Text key={index} fontSize="1rem">
                                        {item}
                                    </Text>
                                ))}
                            </VStack>
                        </VStack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
