import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const BaseLayout: FC = () => {
    return (
        <Box minH="100vh" minW="100vw" bg="gray.50">
            <Outlet />
        </Box>
    );
};
