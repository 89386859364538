/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FeatureCode } from './FeatureCode';
import { FeatureCodeFromJSON, FeatureCodeFromJSONTyped, FeatureCodeToJSON } from './FeatureCode';

/**
 *
 * @export
 * @interface GetFeatureBalanceRequestDto
 */
export interface GetFeatureBalanceRequestDto {
    /**
     *
     * @type {FeatureCode}
     * @memberof GetFeatureBalanceRequestDto
     */
    featureCode: FeatureCode;
}

/**
 * Check if a given object implements the GetFeatureBalanceRequestDto interface.
 */
export function instanceOfGetFeatureBalanceRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'featureCode' in value;

    return isInstance;
}

export function GetFeatureBalanceRequestDtoFromJSON(json: any): GetFeatureBalanceRequestDto {
    return GetFeatureBalanceRequestDtoFromJSONTyped(json, false);
}

export function GetFeatureBalanceRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetFeatureBalanceRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        featureCode: FeatureCodeFromJSON(json['feature_code']),
    };
}

export function GetFeatureBalanceRequestDtoToJSON(value?: GetFeatureBalanceRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        feature_code: FeatureCodeToJSON(value.featureCode),
    };
}
