import {Configuration} from "@api-clients/runtime";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class FindProveLineItemsApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign
    ): Promise<Array<ProveLineItem>> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaign.id}/insertion-orders/${campaign.insertionOrder!.id}/line-items`, {
            method: 'GET',
            headers: configuration.headers
        });

        return response.json();
    }

}
