/**
 * Extend Amplified Intelligence in this file to implement
 * the company's own Design System which includes ColorScheme,
 * Typography, Gradients, Borders, Break Points, etc.
 */

import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { componentStyles } from './styles/component-styles';
import { defaultColorScheme } from './theming/colors';
import { foundation } from './theming/foundation';

const AMPLIFIED_THEMES = extendTheme(
    {
        config: {
            cssVarPrefix: 'ai',
        },
        components: componentStyles,
        ...foundation,
    },
    withDefaultColorScheme({ colorScheme: defaultColorScheme }),
);

export default AMPLIFIED_THEMES;
