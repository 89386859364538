/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdFormatResponseDto } from './AdFormatResponseDto';
import {
    AdFormatResponseDtoFromJSON,
    AdFormatResponseDtoFromJSONTyped,
    AdFormatResponseDtoToJSON,
} from './AdFormatResponseDto';

/**
 *
 * @export
 * @interface FormatLevelResultResponseDto
 */
export interface FormatLevelResultResponseDto {
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    mix?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    budget?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    cpm?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    impression?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    reach?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    activeAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {number}
     * @memberof FormatLevelResultResponseDto
     */
    passiveAttentionVolumeSeconds?: number | null;
    /**
     *
     * @type {string}
     * @memberof FormatLevelResultResponseDto
     */
    channelLevelResultId?: string;
    /**
     *
     * @type {AdFormatResponseDto}
     * @memberof FormatLevelResultResponseDto
     */
    adFormat?: AdFormatResponseDto;
    /**
     *
     * @type {string}
     * @memberof FormatLevelResultResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof FormatLevelResultResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof FormatLevelResultResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the FormatLevelResultResponseDto interface.
 */
export function instanceOfFormatLevelResultResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatLevelResultResponseDtoFromJSON(json: any): FormatLevelResultResponseDto {
    return FormatLevelResultResponseDtoFromJSONTyped(json, false);
}

export function FormatLevelResultResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatLevelResultResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mix: !exists(json, 'mix') ? undefined : json['mix'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        impression: !exists(json, 'impression') ? undefined : json['impression'],
        reach: !exists(json, 'reach') ? undefined : json['reach'],
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : json['active_attention_volume_seconds'],
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : json['passive_attention_volume_seconds'],
        channelLevelResultId: !exists(json, 'channel_level_result_id')
            ? undefined
            : json['channel_level_result_id'],
        adFormat: !exists(json, 'ad_format')
            ? undefined
            : AdFormatResponseDtoFromJSON(json['ad_format']),
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function FormatLevelResultResponseDtoToJSON(
    value?: FormatLevelResultResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mix: value.mix,
        budget: value.budget,
        cpm: value.cpm,
        impression: value.impression,
        reach: value.reach,
        active_attention_volume_seconds: value.activeAttentionVolumeSeconds,
        passive_attention_volume_seconds: value.passiveAttentionVolumeSeconds,
        channel_level_result_id: value.channelLevelResultId,
        ad_format: AdFormatResponseDtoToJSON(value.adFormat),
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
