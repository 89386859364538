import { Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';

type CreativeModalHeaderProps = {
    title: string;
    description: string;
};
export const CreativeModalHeader: FC<CreativeModalHeaderProps> = ({ title, description }) => {
    return (
        <>
            <Heading fontSize="1.125rem" color="gray.700" fontWeight={700} lineHeight="1.75rem">
                {title}
            </Heading>
            <Text
                textAlign="left"
                fontWeight={400}
                fontSize="1rem"
                lineHeight="1.5rem"
                color="gray.700"
                pt="0.5rem"
                pb="1.5rem"
            >
                {description}
            </Text>
        </>
    );
};
