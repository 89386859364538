import { ComponentStyleConfig, defineStyle, StyleFunctionProps } from '@chakra-ui/react';

/**
 * Example style for tables.
 */
export type TableVariant = 'base' | 'attentionAdjust';

export const Table: ComponentStyleConfig = {
    parts: ['table', 'thead', 'tbody', 'tfoot', 'th', 'tr', 'td'],
    baseStyle: (props: StyleFunctionProps) => {
        const { colorScheme } = props;
        const numericStyles = defineStyle({
            '&[data-is-numeric=true]': {
                textAlign: 'end',
            },
        });
        const sortableStyles = defineStyle({
            '&[data-is-sortable=true]': {
                _selected: {
                    bgColor: 'gray.50',
                },
                _hover: {
                    _selected: {
                        bgColor: 'gray.50',
                    },
                    bgColor: 'gray.50',
                    cursor: 'pointer',
                },
            },
        });
        return {
            th: {
                transition: 'background-color 200ms linear',
                ...sortableStyles,
                ...numericStyles,
            },
            tr: {
                _selected: {
                    bgColor: `${colorScheme}.50`,
                    transition: 'background-color 100ms linear',
                },
            },
            td: {
                '&[aria-sort=ascending], &[aria-sort=descending]': {
                    bgColor: 'gray.50',
                },
                ...numericStyles,
            },
        };
    },
    sizes: {
        sm: {
            th: {
                paddingX: '12px',
                paddingY: '12px',
                padding: '12px',
            },

            td: {
                paddingX: '12px',
                paddingY: '12px',
                padding: '12px',
            },
        },
        md: {
            th: {
                paddingX: '16px',
                paddingY: '16px',
                padding: '16px',
            },

            td: {
                paddingX: '16px',
                paddingY: '16px',
                padding: '16px',
            },
        },
        lg: {
            th: {
                paddingX: '20px',
                paddingY: '20px',
                padding: '20px',
            },

            td: {
                paddingX: '20px',
                paddingY: '20px',
                padding: '20px',
            },
        },
    },
    variants: {
        dataTable: (props: StyleFunctionProps) => {
            const { colorScheme } = props;
            const borderColor = 'gray.200';
            const numericStyles = defineStyle({
                '&[data-is-numeric=true]': {
                    textAlign: 'end',
                },
            });
            const sortableStyles = defineStyle({
                '&[data-is-sortable=true]': {
                    _selected: {
                        bgColor: 'gray.100',
                    },
                    _hover: {
                        _selected: {
                            bgColor: 'gray.200',
                        },
                        bgColor: 'gray.100',
                        cursor: 'pointer',
                    },
                },
            });
            return {
                table: {
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                },
                thead: {
                    backgroundColor: 'gray.50',
                },
                th: {
                    transition: 'background-color 100ms linear',
                    borderColor,
                    borderRightWidth: '1px',
                    '&:last-of-type': { borderRightWidth: 0 },
                    borderBottomWidth: '1px',
                    ...sortableStyles,
                    ...numericStyles,
                },
                tr: {
                    transition: 'background-color 200ms linear',
                    _selected: {
                        bgColor: `${colorScheme}.50`,
                        transition: 'background-color 200ms linear',
                    },
                    '&:last-of-type': {
                        td: {
                            borderBottomWidth: 0,
                        },
                    },
                },
                td: {
                    transition: 'background-color 100ms linear',
                    borderColor,
                    borderRightWidth: '1px',
                    borderBottomWidth: '1px',
                    '&:last-of-type': { borderRightWidth: 0 },
                    ':not(&[aria-sort=none])': {
                        bgColor: 'gray.50',
                    },
                    ...numericStyles,
                },
            };
        },
    },
};
