export const sizes = {
    sizes: {
        atoms: {
            xs: '1.5rem',
            sm: '2.5rem',
            md: '3.5rem',
            lg: '4.5rem',
            xl: '5.5rem',
        },
        molecules: {
            xs: '2.5rem',
            sm: '3.5rem',
            md: '4.5rem',
            lg: '5.5rem',
            xl: '6.5rem',
        },
    },
};
