/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ScenarioStatus = {
    New: 'new',
    Optimising: 'optimising',
    Active: 'active',
    OptimisationFailed: 'optimisation_failed',
    Archived: 'archived',
    Constraint: 'constraint'
} as const;
export type ScenarioStatus = typeof ScenarioStatus[keyof typeof ScenarioStatus];


export function ScenarioStatusFromJSON(json: any): ScenarioStatus {
    return ScenarioStatusFromJSONTyped(json, false);
}

export function ScenarioStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioStatus {
    return json as ScenarioStatus;
}

export function ScenarioStatusToJSON(value?: ScenarioStatus | null): any {
    return value as any;
}

