import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    StackDivider,
    useBoolean,
    VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { DetailListItem, DetailSection } from '@components/molecules';
import { getListRenderKey } from '@shared/utils';
import { LockIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { AmplifiedColorScheme } from '@shared/cores';
import { useChangePassword } from '@hooks/auth';
import { useAuthToast } from '@hooks/toast/useAuthToast';
import { useAppContextHelper } from '@hooks/_contexts';

export const SecuritySettings: FC<{ colorScheme?: AmplifiedColorScheme }> = ({
    colorScheme = 'orange',
}) => {
    const { t } = useTranslation();
    const {
        currentContextValues: { user },
    } = useAppContextHelper();
    const { successChangePasswordToast, errorChangePasswordToast } = useAuthToast();
    const { changePassword } = useChangePassword();

    const [isLoading, setIsLoading] = useBoolean();

    const onChangePassword = async () => {
        try {
            setIsLoading.on();
            await changePassword({ auth0: { emailAddress: user?.emailAddress } });
            successChangePasswordToast();
        } catch {
            errorChangePasswordToast();
        } finally {
            setIsLoading.off();
        }
    };

    const securityListItems: DetailListItem[] = [
        {
            label: 'Password',
            renderItem: (
                <Center>
                    <Button
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        width="full"
                        leftIcon={<LockIcon />}
                        colorScheme={colorScheme}
                        onClick={() => onChangePassword()}
                    >
                        {t('labels.sections.auth.changePassword.buttonName')}
                    </Button>
                </Center>
            ),
        },
    ];
    return (
        <>
            <Flex marginBottom="4">
                <Box>
                    <Heading size="md" color="gray.600">
                        Security
                    </Heading>
                </Box>
            </Flex>
            <Box bg="white" borderRadius="lg">
                <VStack
                    divider={<StackDivider />}
                    align="stretch"
                    borderWidth="thin"
                    borderRadius="lg"
                    spacing="0"
                >
                    {securityListItems.map((item, index) => (
                        <DetailSection
                            key={getListRenderKey(index, item.label)}
                            label={item.label}
                            renderItem={item.renderItem}
                        />
                    ))}
                </VStack>
            </Box>
        </>
    );
};
