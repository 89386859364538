export const attentionBenchmarks = {
    benchMarks: {
        heading: 'Attention benchmarks',
        subtitle: 'Display attention data benchmarks',
        filterToolip: {
            mrc: 'Only include data that meets the MRC Viewability standard',
        },
    },
    metrics: {
        heading: 'Attention metrics',
        subtitle: 'Aggregate level attention metrics based on the input parameters',
        tableTooltip: {
            activeAttention:
                'Number of active attention seconds paid to ad (users looking directly at the ad)',
            passiveAttention:
                'Number of passive attention seconds paid to ad (eyes on screen but not on the ad)',
            totalAttention: 'Total number of active and passive attention seconds paid to ad',
            averageAdLength:
                'Proportion of active attention relative to the average ad length. Static ads have a length of 0',
            averageAdTimeOnScreen:
                'Proportion of active attention relative to the average time-in-view',
        },
    },
    lockTooltip: 'Upgrade to unlock attention benchmarks',
};
