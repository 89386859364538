/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { DecaySecondResponseDto } from './DecaySecondResponseDto';
import {
    DecaySecondResponseDtoFromJSON,
    DecaySecondResponseDtoFromJSONTyped,
    DecaySecondResponseDtoToJSON,
} from './DecaySecondResponseDto';

/**
 *
 * @export
 * @interface FormatDecayResponseDto
 */
export interface FormatDecayResponseDto {
    /**
     *
     * @type {Array<DecaySecondResponseDto>}
     * @memberof FormatDecayResponseDto
     */
    decaySeconds?: Array<DecaySecondResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof FormatDecayResponseDto
     */
    adFormatName?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatDecayResponseDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatDecayResponseDto
     */
    adChannelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatDecayResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FormatDecayResponseDto
     */
    isSelected?: boolean;
    /**
     *
     * @type {number}
     * @memberof FormatDecayResponseDto
     */
    minDuration?: number;
    /**
     *
     * @type {number}
     * @memberof FormatDecayResponseDto
     */
    maxDuration?: number;
    /**
     *
     * @type {number}
     * @memberof FormatDecayResponseDto
     */
    retentionRate?: number;
    /**
     *
     * @type {number}
     * @memberof FormatDecayResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof FormatDecayResponseDto
     */
    averagePassiveAttention?: number;
}

/**
 * Check if a given object implements the FormatDecayResponseDto interface.
 */
export function instanceOfFormatDecayResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatDecayResponseDtoFromJSON(json: any): FormatDecayResponseDto {
    return FormatDecayResponseDtoFromJSONTyped(json, false);
}

export function FormatDecayResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatDecayResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        decaySeconds: !exists(json, 'decay_seconds')
            ? undefined
            : json['decay_seconds'] === null
            ? null
            : (json['decay_seconds'] as Array<any>).map(DecaySecondResponseDtoFromJSON),
        adFormatName: !exists(json, 'ad_format_name') ? undefined : json['ad_format_name'],
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        isSelected: !exists(json, 'is_selected') ? undefined : json['is_selected'],
        minDuration: !exists(json, 'min_duration') ? undefined : json['min_duration'],
        maxDuration: !exists(json, 'max_duration') ? undefined : json['max_duration'],
        retentionRate: !exists(json, 'retention_rate') ? undefined : json['retention_rate'],
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        averagePassiveAttention: !exists(json, 'average_passive_attention')
            ? undefined
            : json['average_passive_attention'],
    };
}

export function FormatDecayResponseDtoToJSON(value?: FormatDecayResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        decay_seconds:
            value.decaySeconds === undefined
                ? undefined
                : value.decaySeconds === null
                ? null
                : (value.decaySeconds as Array<any>).map(DecaySecondResponseDtoToJSON),
        ad_format_name: value.adFormatName,
        ad_format_code: value.adFormatCode,
        ad_channel_name: value.adChannelName,
        ad_channel_code: value.adChannelCode,
        is_selected: value.isSelected,
        min_duration: value.minDuration,
        max_duration: value.maxDuration,
        retention_rate: value.retentionRate,
        average_active_attention: value.averageActiveAttention,
        average_passive_attention: value.averagePassiveAttention,
    };
}
