import { NavBarContainer } from '@components/organisms';
import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AttentionPlanAppProvider } from '@hooks/_contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { useGoogleAnalytics } from '@hooks/analytics/useGoogleAnalytics';
import { CookieBanner, initialiseHotjar } from '@components/pages/cookieBanner/CookieBanner';
import { initialiseMicrosoftClarity } from '@components/pages/cookieBanner/initialiseMicrosoftClarity';
import { removePerformanceCookies } from '@shared/utils/helper';
import {
    getPersistedCookieAcceptanceState,
    persistCookieAcceptance,
} from '@states/cookieAcceptanceState';
import { getPersistedCookieBannerCompletionState } from '@states/cookieBannerCompletionState';
import posthog from 'posthog-js';

export const initialisePostHog = () => {
    posthog.init(import.meta.env.VITE_APP_POSTHOG_ID, {
        api_host: import.meta.env.VITE_APP_POSTHOG_API_HOST,
    });
    const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get('__posthog');
    if (toolbarJSON) {
        posthog.loadToolbar(JSON.parse(toolbarJSON));
    }
};

export const NavBarLayout: FC = () => {
    const { user } = useAuth0();
    const location = useLocation();

    const googleAnalytics = useGoogleAnalytics();

    useEffect(() => {
        googleAnalytics.initialise();
        initialiseHotjar();
        if (
            !window.location.host.includes('127.0.0.1') &&
            !window.location.host.includes('localhost')
        ) {
            initialiseMicrosoftClarity();
            initialisePostHog();
        }
    }, []);

    return (
        <>
            {user && (
                <AttentionPlanAppProvider>
                    <NavBarContainer>
                        <Outlet />
                    </NavBarContainer>
                </AttentionPlanAppProvider>
            )}
        </>
    );
};
