import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from '@chakra-ui/react';
import { FC } from 'react';

export interface InputWrapperProps extends FormControlProps {
    formLabel?: string;
    formHelperText?: string;
    formErrorText?: string;
    children: JSX.Element;
}

/**
 * Wrapper which wraps an input with a form control and label, error and helper text
 */
export const InputWrapper: FC<InputWrapperProps> = ({
    formLabel,
    formHelperText,
    formErrorText,
    children,
    ...formControlProps
}) => {
    return (
        <FormControl {...formControlProps}>
            {formLabel && <FormLabel htmlFor={formControlProps.id}>{formLabel}</FormLabel>}
            {children}
            {formControlProps?.isInvalid
                ? formErrorText && <FormErrorMessage>{formErrorText}</FormErrorMessage>
                : formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
        </FormControl>
    );
};
