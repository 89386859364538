/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    CampaignBasicInfoResponseDto,
    CampaignResponseDto,
    CampaignUserAccessResponseDto,
    CreateCampaignRequestDto,
    CreateDraftCampaignRequestDto,
    GetCampaignResponseDto,
    ProblemDetails,
    SearchedCampaignResponseDto,
    ShareUserAccess,
    ShareUserAccessDto,
    UpdateCampaignRequestDto,
    UpdateDraftCampaignRequestDto,
} from '../models';
import {
    CampaignBasicInfoResponseDtoFromJSON,
    CampaignBasicInfoResponseDtoToJSON,
    CampaignResponseDtoFromJSON,
    CampaignResponseDtoToJSON,
    CampaignUserAccessResponseDtoFromJSON,
    CampaignUserAccessResponseDtoToJSON,
    CreateCampaignRequestDtoFromJSON,
    CreateCampaignRequestDtoToJSON,
    CreateDraftCampaignRequestDtoFromJSON,
    CreateDraftCampaignRequestDtoToJSON,
    GetCampaignResponseDtoFromJSON,
    GetCampaignResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SearchedCampaignResponseDtoFromJSON,
    SearchedCampaignResponseDtoToJSON,
    ShareUserAccessFromJSON,
    ShareUserAccessToJSON,
    ShareUserAccessDtoFromJSON,
    ShareUserAccessDtoToJSON,
    UpdateCampaignRequestDtoFromJSON,
    UpdateCampaignRequestDtoToJSON,
    UpdateDraftCampaignRequestDtoFromJSON,
    UpdateDraftCampaignRequestDtoToJSON,
} from '../models';

export interface CreateADraftCampaignRequest {
    createDraftCampaignRequestDto?: CreateDraftCampaignRequestDto;
}

export interface CreateANewCampaignRequest {
    createCampaignRequestDto?: CreateCampaignRequestDto;
}

export interface CreateUserCampaignAccessRequest {
    campaignId: string;
    userId: string;
}

export interface DeleteCampaignRequest {
    campaignId: string;
}

export interface DeleteDraftCampaignRequest {
    campaignId: string;
}

export interface DeleteUserCampaignAccessRequest {
    campaignId: string;
    userId: string;
}

export interface GetCampaignByIdRequest {
    mediaPlanId: string;
}

export interface GetCampaignWithConstraintByCampaignIdRequest {
    campaignId: string;
}

export interface GetDraftCampaignByIdRequest {
    campaignId: string;
}

export interface GetUserCampaignAccessByCampaignIdRequest {
    campaignId: string;
}

export interface SearchCampaignRequest {
    campaignName?: string;
}

export interface UpdateCampaignRequest {
    campaignId: string;
    updateCampaignRequestDto?: UpdateCampaignRequestDto;
}

export interface UpdateDraftCampaignRequest {
    campaignId: string;
    updateDraftCampaignRequestDto?: UpdateDraftCampaignRequestDto;
}

export interface UpdatesCampaignVisibilityRequest {
    campaignId: string;
    body?: boolean;
}

/**
 * CampaignApi - interface
 *
 * @export
 * @interface CampaignApiInterface
 */
export interface CampaignApiInterface {
    /**
     *
     * @param {CreateDraftCampaignRequestDto} [createDraftCampaignRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    createADraftCampaignRaw(
        requestParameters: CreateADraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignBasicInfoResponseDto>>;

    /**
     */
    createADraftCampaign(
        requestParameters: CreateADraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignBasicInfoResponseDto>;

    /**
     *
     * @param {CreateCampaignRequestDto} [createCampaignRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    createANewCampaignRaw(
        requestParameters: CreateANewCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>>;

    /**
     */
    createANewCampaign(
        requestParameters: CreateANewCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    createUserCampaignAccessRaw(
        requestParameters: CreateUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ShareUserAccess>>;

    /**
     */
    createUserCampaignAccess(
        requestParameters: CreateUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ShareUserAccess>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    deleteCampaignRaw(
        requestParameters: DeleteCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>>;

    /**
     */
    deleteCampaign(
        requestParameters: DeleteCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    deleteDraftCampaignRaw(
        requestParameters: DeleteDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteDraftCampaign(
        requestParameters: DeleteDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;

    /**
     *
     * @param {string} campaignId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    deleteUserCampaignAccessRaw(
        requestParameters: DeleteUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteUserCampaignAccess(
        requestParameters: DeleteUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    getCampaignByIdRaw(
        requestParameters: GetCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>>;

    /**
     */
    getCampaignById(
        requestParameters: GetCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    getCampaignWithConstraintByCampaignIdRaw(
        requestParameters: GetCampaignWithConstraintByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetCampaignResponseDto>>;

    /**
     */
    getCampaignWithConstraintByCampaignId(
        requestParameters: GetCampaignWithConstraintByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetCampaignResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    getDraftCampaignByIdRaw(
        requestParameters: GetDraftCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetCampaignResponseDto>>;

    /**
     */
    getDraftCampaignById(
        requestParameters: GetDraftCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetCampaignResponseDto>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    getUserCampaignAccessRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignUserAccessResponseDto>>;

    /**
     */
    getUserCampaignAccess(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignUserAccessResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    getUserCampaignAccessByCampaignIdRaw(
        requestParameters: GetUserCampaignAccessByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ShareUserAccessDto>>>;

    /**
     */
    getUserCampaignAccessByCampaignId(
        requestParameters: GetUserCampaignAccessByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ShareUserAccessDto>>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    listAllCampaignsForTheCurrentLoggedInUserRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CampaignBasicInfoResponseDto>>>;

    /**
     */
    listAllCampaignsForTheCurrentLoggedInUser(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CampaignBasicInfoResponseDto>>;

    /**
     *
     * @param {string} [campaignName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    searchCampaignRaw(
        requestParameters: SearchCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SearchedCampaignResponseDto>>>;

    /**
     */
    searchCampaign(
        requestParameters: SearchCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SearchedCampaignResponseDto>>;

    /**
     *
     * @param {string} campaignId
     * @param {UpdateCampaignRequestDto} [updateCampaignRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    updateCampaignRaw(
        requestParameters: UpdateCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>>;

    /**
     */
    updateCampaign(
        requestParameters: UpdateCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {UpdateDraftCampaignRequestDto} [updateDraftCampaignRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    updateDraftCampaignRaw(
        requestParameters: UpdateDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>>;

    /**
     */
    updateDraftCampaign(
        requestParameters: UpdateDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto>;

    /**
     *
     * @param {string} campaignId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApiInterface
     */
    updatesCampaignVisibilityRaw(
        requestParameters: UpdatesCampaignVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignBasicInfoResponseDto>>;

    /**
     */
    updatesCampaignVisibility(
        requestParameters: UpdatesCampaignVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignBasicInfoResponseDto>;
}

/**
 *
 */
export class CampaignApi extends runtime.BaseAPI implements CampaignApiInterface {
    /**
     */
    async createADraftCampaignRaw(
        requestParameters: CreateADraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignBasicInfoResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/draft`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateDraftCampaignRequestDtoToJSON(
                    requestParameters.createDraftCampaignRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createADraftCampaign(
        requestParameters: CreateADraftCampaignRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignBasicInfoResponseDto> {
        const response = await this.createADraftCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createANewCampaignRaw(
        requestParameters: CreateANewCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateCampaignRequestDtoToJSON(requestParameters.createCampaignRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createANewCampaign(
        requestParameters: CreateANewCampaignRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto> {
        const response = await this.createANewCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserCampaignAccessRaw(
        requestParameters: CreateUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ShareUserAccess>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling createUserCampaignAccess.',
            );
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling createUserCampaignAccess.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/access/{userId}`
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    )
                    .replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ShareUserAccessFromJSON(jsonValue),
        );
    }

    /**
     */
    async createUserCampaignAccess(
        requestParameters: CreateUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ShareUserAccess> {
        const response = await this.createUserCampaignAccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCampaignRaw(
        requestParameters: DeleteCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling deleteCampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<number>(response);
    }

    /**
     */
    async deleteCampaign(
        requestParameters: DeleteCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number> {
        const response = await this.deleteCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDraftCampaignRaw(
        requestParameters: DeleteDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling deleteDraftCampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/draft/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDraftCampaign(
        requestParameters: DeleteDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.deleteDraftCampaignRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserCampaignAccessRaw(
        requestParameters: DeleteUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling deleteUserCampaignAccess.',
            );
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter requestParameters.userId was null or undefined when calling deleteUserCampaignAccess.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/access/{userId}`
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    )
                    .replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserCampaignAccess(
        requestParameters: DeleteUserCampaignAccessRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.deleteUserCampaignAccessRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCampaignByIdRaw(
        requestParameters: GetCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>> {
        if (requestParameters.mediaPlanId === null || requestParameters.mediaPlanId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getCampaignById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.mediaPlanId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getCampaignById(
        requestParameters: GetCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto> {
        const response = await this.getCampaignByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCampaignWithConstraintByCampaignIdRaw(
        requestParameters: GetCampaignWithConstraintByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetCampaignResponseDto>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getCampaignWithConstraintByCampaignId.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/constraints`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetCampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getCampaignWithConstraintByCampaignId(
        requestParameters: GetCampaignWithConstraintByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetCampaignResponseDto> {
        const response = await this.getCampaignWithConstraintByCampaignIdRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getDraftCampaignByIdRaw(
        requestParameters: GetDraftCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GetCampaignResponseDto>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getDraftCampaignById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/draft/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GetCampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getDraftCampaignById(
        requestParameters: GetDraftCampaignByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GetCampaignResponseDto> {
        const response = await this.getDraftCampaignByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCampaignAccessRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignUserAccessResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/access`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignUserAccessResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getUserCampaignAccess(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignUserAccessResponseDto> {
        const response = await this.getUserCampaignAccessRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCampaignAccessByCampaignIdRaw(
        requestParameters: GetUserCampaignAccessByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ShareUserAccessDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling getUserCampaignAccessByCampaignId.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/access`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ShareUserAccessDtoFromJSON),
        );
    }

    /**
     */
    async getUserCampaignAccessByCampaignId(
        requestParameters: GetUserCampaignAccessByCampaignIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ShareUserAccessDto>> {
        const response = await this.getUserCampaignAccessByCampaignIdRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async listAllCampaignsForTheCurrentLoggedInUserRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CampaignBasicInfoResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(CampaignBasicInfoResponseDtoFromJSON),
        );
    }

    /**
     */
    async listAllCampaignsForTheCurrentLoggedInUser(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CampaignBasicInfoResponseDto>> {
        const response = await this.listAllCampaignsForTheCurrentLoggedInUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async searchCampaignRaw(
        requestParameters: SearchCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SearchedCampaignResponseDto>>> {
        const queryParameters: any = {};

        if (requestParameters.campaignName !== undefined) {
            queryParameters['campaignName'] = requestParameters.campaignName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/search`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(SearchedCampaignResponseDtoFromJSON),
        );
    }

    /**
     */
    async searchCampaign(
        requestParameters: SearchCampaignRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SearchedCampaignResponseDto>> {
        const response = await this.searchCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCampaignRaw(
        requestParameters: UpdateCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updateCampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateCampaignRequestDtoToJSON(requestParameters.updateCampaignRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateCampaign(
        requestParameters: UpdateCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto> {
        const response = await this.updateCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDraftCampaignRaw(
        requestParameters: UpdateDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignResponseDto>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updateDraftCampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/draft/{campaignId}`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateDraftCampaignRequestDtoToJSON(
                    requestParameters.updateDraftCampaignRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateDraftCampaign(
        requestParameters: UpdateDraftCampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignResponseDto> {
        const response = await this.updateDraftCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatesCampaignVisibilityRaw(
        requestParameters: UpdatesCampaignVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignBasicInfoResponseDto>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updatesCampaignVisibility.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/access/visibility`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body as any,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CampaignBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updatesCampaignVisibility(
        requestParameters: UpdatesCampaignVisibilityRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignBasicInfoResponseDto> {
        const response = await this.updatesCampaignVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
