/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ShareUserAccess
 */
export interface ShareUserAccess {
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    scenarioId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ShareUserAccess
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ShareUserAccess
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ShareUserAccess
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ShareUserAccess interface.
 */
export function instanceOfShareUserAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShareUserAccessFromJSON(json: any): ShareUserAccess {
    return ShareUserAccessFromJSONTyped(json, false);
}

export function ShareUserAccessFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ShareUserAccess {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        scenarioId: !exists(json, 'scenario_id') ? undefined : json['scenario_id'],
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ShareUserAccessToJSON(value?: ShareUserAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        scenario_id: value.scenarioId,
        user_id: value.userId,
        first_name: value.firstName,
        last_name: value.lastName,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
