export const mediaPlanning = {
    reachCurveChart: {
        channelSelector: 'Select channel',
        lineLabel: 'reach curve',
        axisLabels: {
            x: {trp: 'Target rating point', budget: 'Budget ($)'},
            y: {reach: 'Reach (%)', targetAudience: 'Reach point'},
        },
    },
    mediaMixChart: {
        axisLabels: {
            y: '% of media spend',
        },
    },
    campaignCreation: {
        errorMessages: {
            importFailed: {
                title: 'Campaign cannot be created',
                description: 'An error has occurred.',
            },
            maxFilesExceeded_one:
                'Too many files uploaded. Only {{count}} file can be uploaded at a time.',
            maxFilesExceeded_other:
                'Too many files uploaded. Only {{count}} files can be uploaded at a time.',
            invalidFileType: 'Invalid file format. Only {{fileFormats}} formats are accepted.',
            invalidFileValues: 'The uploaded file contains invalid values.',
            doesNotHaveAtLeastTwoNonZeroRows:
                'The uploaded file should contain valid values for at least 2 channels.',
            totalAudienceMustLargerThanTargetAudience:
                'Total target audience must be bigger than the target audience reached.',
            frequencyShouldBeMoreThanOrEqualToOne:
                "Frequency can't be smaller than 1 for any valid channel.",
            fileDropFailed: {
                title: 'Oops, something went wrong',
                description: 'Unable to parse file',
            },
        },
        successMessages: {
            fileDropSucceeded: {
                title: 'Media plan uploaded',
                description: '',
            },
        },
    },
    campaignManagement: {
        heading: 'attentionPLAN media plans',
        subtitle: 'Click on a media plan for more details',
        deleteDialog: {
            headerText: 'Delete campaign',
            bodyText: 'Are you sure you want to delete this campaign? This action cannot be undone.',
            confirmButton: 'Delete',
            cancelButton: 'Cancel',
        },
        campaignTable: {
            headers: {
                campaignName: 'Name',
                dateCreated: 'Date created',
                lastModified: 'Last modified',
                owner: 'Owner',
                action: 'Action',
            }
        },
        toast: {
            success: {
                heading: 'Success',
                subtitle: 'Campaign successfully deleted',
            },
            error: {
                heading: 'Something went wrong',
                subtitle: 'Campaign could not be deleted.',
            },
            renameSuccess: {
                heading: 'Success',
                subtitle: 'Campaign successfully renamed',
            },
            renameError: {
                heading: 'Something went wrong',
                subtitle: 'Campaign could not be renamed',
            },
        },
    },
    scenarioCreation: {
        dateLabel: 'Last modified at',
        generateScenarios: {
            toast: {
                success: {
                    heading: 'Success',
                    subtitle: 'Scenarios for {{optimisationObjective}} successfully generated',
                },
                error: {
                    heading: 'No optimisation for {{optimisationObjective}} found',
                    requestFailedError: 'Please try again with a different plan tolerance level.',
                    optimisationFailedError:
                        'Please try again with a different plan tolerance level.',
                },
            },
            formLabels: {
                budget: 'Budget',
                startDate: 'Start date',
                endDate: 'End date',
                location: 'Location (country)',
                budgetConstraints: 'Budget constraints',
                adFormats: 'Ad formats',
                optimisationObjectives: 'Optimisation objective',
                campaignObjective: 'Campaign objective',
            },
            buttons: {
                generateScenarios: {
                    label: 'Generate scenarios',
                    tooltip: 'Get access to unlimited scenario creation with our paid tier.',
                },
                configureFormats: 'Configure formats',
                budgetConstraints: {
                    planTolerance: {
                        label: 'Plan tolerance',
                        tooltip:
                            'The extent to which the budget for each channel can be reduced. Tolerance levels: low (10%), medium (30%), high (50%).',
                        priority: {
                            low: 'Low',
                            medium: 'Medium',
                            high: 'High',
                        },
                    },
                    channelMinSpend: {
                        label: 'Channel minimum spend',
                        button: 'Configure minimum spend',
                    },
                },
                demographic: {
                    tooltip: 'The global data set will be used if there is insufficient data.',
                },
            },
            optimisationObjectives: {
                base: {
                    label: 'Base',
                    tooltip: 'Baseline media plan',
                },
                aasi: {
                    label: 'Active attention seconds per impression (AApI)',
                    tooltip:
                        'Optimises towards the average number of attention seconds per impression. Recommended for driving engagement and conversion.',
                },
                aasr: {
                    label: 'Active attention seconds per reach (AApR)',
                    tooltip:
                        'Optimises towards the average number of attention seconds per reach. Recommended for increasing brand awareness and retention.',
                },
                stl: {
                    label: 'Brand uplift',
                    tooltip:
                        'Optimises towards the likelihood of your brand being considered at a purchase occasion. Recommended for strengthening market position.',
                },
                // ltl: {
                //     label: 'Long-term lift (LTL)',
                //     tooltip:
                //         'The likelihood of a customer picking your brand on any purchase occasion.',
                // },
            },
        },
        comparison: {
            reachCurve: {
                header: 'Reach curve comparison',
                subHeader: 'Understand the reach of each scenario within each channel',
                update: {
                    title: 'Unable to update reach curves',
                    description: 'Something went wrong',
                },
            },
            scenarioMediaMix: {
                header: 'Scenario media mix',
                subHeader: 'Compare the media mix by budget spend between each scenario',
            },
            scenarioComparison: {
                header: 'Scenario comparison',
                subHeader: "Evaluate and adjust the scenario's budget mix",
            },
            delete: {
                dialog: {
                    title: 'Delete scenario',
                    body: 'Are you sure you want to delete <strong>{{scenario}}</strong>? This action cannot be undone.',
                    confirm: 'Delete',
                    cancel: 'Cancel',
                },
                toast: {
                    success: {
                        heading: 'Success',
                        subtitle: '{{scenario}} deleted',
                    },
                    error: {
                        heading: 'Something went wrong',
                        subtitle: '{{scenario}} could not be deleted.',
                    },
                },
            },
            edit: {
                dialog: {
                    title: 'Edit scenario',
                    form: {
                        name: {
                            label: 'Scenario name',
                            errorMessage: 'A scenario name must be provided',
                        },
                    },
                    confirm: 'Save',
                    cancel: 'Cancel',
                },
                toast: {
                    success: {
                        heading: 'Success',
                        subtitle: 'Scenario changes saved',
                    },
                    error: {
                        heading: 'Something went wrong',
                        subtitle: 'Scenario changes could not be saved.',
                    },
                },
            },
        },
        keyOptimisedMetrics: {
            codedCommentary: {
                aasi: `The average number of attention seconds per impression.`,
                stl: `The likelihood of your brand being considered at a purchase occasion. Recommended for strengthening market position.`,
                ltl: `The likelihood of a customer picking your brand on any purchase occasion.`,
                aasr: `The average number of attention seconds per reach.`,
            },
        },
    },
    scenarioSharing: {
        button: {
            name: 'Share',
        },
        dialog: {
            header: 'Share',
            body: '',
            confirmButtonText: 'Share',
            cancelButtonText: 'Cancel',
        },
        toast: {
            scenario: {
                success: {
                    heading: 'Success',
                    subtitle: 'Scenario has been shared.',
                },
                error: {
                    heading: 'Something went wrong',
                    subtitle: 'Scenario cannot be shared.',
                },
            },
            campaign: {
                success: {
                    heading: 'Success',
                    subtitle: 'Campaign has been shared.',
                },
                error: {
                    heading: 'Something went wrong',
                    subtitle: 'Campaign cannot be shared.',
                },
            },
        },
        shareModal: {
            copyLinkButton: {
                text: 'Copy link',
            },
            description: 'Anyone with the link can view this scenario',
        },
    },
    tables: {
        keyOptimisedMetrics: {
            drivers: {
                aSecPerReach: 'Attention seconds per reach',
                aSecPerImpression: 'Attention seconds per impression',
                shortTermLift: 'Brand uplift',
                longTermLift: 'Long-term lift % (LTL)',
                impressions: 'Impressions',
                reach: 'Reach',
                totalActiveAttention: 'Active attention volume seconds',
            },
        },
        scenarioComparison: {
            columns: {
                scenario: 'Scenario',
                activeAttentionSeconds: 'Active attention seconds',
                passiveAttentionSeconds: 'Passive attention seconds',
                perReach: 'Per reach',
                perImpression: 'Per impression',
                volume: 'Volume',
                shortTermLift: 'Brand uplift',
            },
            tooltips: {
                volume: 'The total amount of gained attention (in seconds).',
                shortTermLift:
                    'The increase in the likelihood of a customer considering your brand at a purchase occasion as a result of this campaign.',
            },
        },
    },
};
