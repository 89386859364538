// sort active to the top and inactive to the bottom
export const sortIsSelected = (a?: boolean, b?: boolean): number => {
    if (a === b) {
        return 0;
    }
    if (b && !a) {
        return 1;
    }
    if (a && !b) {
        return -1;
    }
    return 0;
};
