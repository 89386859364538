/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    AdClientDto,
    ApiService,
    CreateAdClientRequestDto,
    CreateOauthApplicationRequestDto,
    CreateOauthApplicationResponseDto,
    InviteUserToOrganisationRequestDto,
    OauthApplicationResponseDto,
    OrganisationResponseDto,
    ProblemDetails,
    RemoveAdClientRequestDto,
    RemoveUserFromOrganisationRequestDto,
    UpdateAdClientRequestDto,
    UpdateOauthApplicationPermissionsRequestDto,
    UpdateOauthApplicationRequestDto,
    UpdateOrganisationRequestDto,
    UpdateUserAccessRequestDto,
    UserWithAccessLevelDto,
} from '../models';
import {
    AdClientDtoFromJSON,
    AdClientDtoToJSON,
    ApiServiceFromJSON,
    ApiServiceToJSON,
    CreateAdClientRequestDtoFromJSON,
    CreateAdClientRequestDtoToJSON,
    CreateOauthApplicationRequestDtoFromJSON,
    CreateOauthApplicationRequestDtoToJSON,
    CreateOauthApplicationResponseDtoFromJSON,
    CreateOauthApplicationResponseDtoToJSON,
    InviteUserToOrganisationRequestDtoFromJSON,
    InviteUserToOrganisationRequestDtoToJSON,
    OauthApplicationResponseDtoFromJSON,
    OauthApplicationResponseDtoToJSON,
    OrganisationResponseDtoFromJSON,
    OrganisationResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RemoveAdClientRequestDtoFromJSON,
    RemoveAdClientRequestDtoToJSON,
    RemoveUserFromOrganisationRequestDtoFromJSON,
    RemoveUserFromOrganisationRequestDtoToJSON,
    UpdateAdClientRequestDtoFromJSON,
    UpdateAdClientRequestDtoToJSON,
    UpdateOauthApplicationPermissionsRequestDtoFromJSON,
    UpdateOauthApplicationPermissionsRequestDtoToJSON,
    UpdateOauthApplicationRequestDtoFromJSON,
    UpdateOauthApplicationRequestDtoToJSON,
    UpdateOrganisationRequestDtoFromJSON,
    UpdateOrganisationRequestDtoToJSON,
    UpdateUserAccessRequestDtoFromJSON,
    UpdateUserAccessRequestDtoToJSON,
    UserWithAccessLevelDtoFromJSON,
    UserWithAccessLevelDtoToJSON,
} from '../models';

export interface CreateAdClientRequest {
    organisationId: string;
    createAdClientRequestDto?: CreateAdClientRequestDto;
}

export interface CreateOauthApplicationRequest {
    organisationId: string;
    createOauthApplicationRequestDto?: CreateOauthApplicationRequestDto;
}

export interface DeleteOauthApplicationRequest {
    organisationId: string;
    clientId: string;
}

export interface GetAllAdClientsRequest {
    organisationId: string;
}

export interface GetAllOauthApplicationsRequest {
    organisationId: string;
}

export interface GetAllUsersAssociatedWithOrganisationRequest {
    organisationId: string;
}

export interface GetOauthApplicationRequest {
    organisationId: string;
    clientId: string;
}

export interface GetOauthApplicationPermissionsRequest {
    organisationId: string;
    clientId: string;
}

export interface GetOrganisationByIdRequest {
    organisationId: string;
}

export interface InviteUserRequest {
    organisationId: string;
    inviteUserToOrganisationRequestDto?: InviteUserToOrganisationRequestDto;
}

export interface RemoveAdClientRequest {
    organisationId: string;
    removeAdClientRequestDto?: RemoveAdClientRequestDto;
}

export interface RemoveUserRequest {
    organisationId: string;
    removeUserFromOrganisationRequestDto?: RemoveUserFromOrganisationRequestDto;
}

export interface UpdateAdClientDetailsRequest {
    organisationId: string;
    updateAdClientRequestDto?: UpdateAdClientRequestDto;
}

export interface UpdateOauthApplicationRequest {
    organisationId: string;
    clientId: string;
    updateOauthApplicationRequestDto?: UpdateOauthApplicationRequestDto;
}

export interface UpdateOauthApplicationPermissionsRequest {
    organisationId: string;
    clientId: string;
    updateOauthApplicationPermissionsRequestDto?: UpdateOauthApplicationPermissionsRequestDto;
}

export interface UpdateOrganisationRequest {
    organisationId: string;
    updateOrganisationRequestDto?: UpdateOrganisationRequestDto;
}

export interface UpdateUserAccessStatusRequest {
    organisationId: string;
    updateUserAccessRequestDto?: UpdateUserAccessRequestDto;
}

/**
 * OrganisationApi - interface
 *
 * @export
 * @interface OrganisationApiInterface
 */
export interface OrganisationApiInterface {
    /**
     *
     * @param {string} organisationId
     * @param {CreateAdClientRequestDto} [createAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    createAdClientRaw(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    createAdClient(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {CreateOauthApplicationRequestDto} [createOauthApplicationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    createOauthApplicationRaw(
        requestParameters: CreateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreateOauthApplicationResponseDto>>;

    /**
     */
    createOauthApplication(
        requestParameters: CreateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreateOauthApplicationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    deleteOauthApplicationRaw(
        requestParameters: DeleteOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    deleteOauthApplication(
        requestParameters: DeleteOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getAllAdClientsRaw(
        requestParameters: GetAllAdClientsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdClientDto>>>;

    /**
     */
    getAllAdClients(
        requestParameters: GetAllAdClientsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdClientDto>>;

    /**
     *
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getAllOauthApplicationsRaw(
        requestParameters: GetAllOauthApplicationsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<OauthApplicationResponseDto>>>;

    /**
     */
    getAllOauthApplications(
        requestParameters: GetAllOauthApplicationsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<OauthApplicationResponseDto>>;

    /**
     *
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getAllUsersAssociatedWithOrganisationRaw(
        requestParameters: GetAllUsersAssociatedWithOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<UserWithAccessLevelDto>>>;

    /**
     */
    getAllUsersAssociatedWithOrganisation(
        requestParameters: GetAllUsersAssociatedWithOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<UserWithAccessLevelDto>>;

    /**
     *
     * @param {string} organisationId
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getOauthApplicationRaw(
        requestParameters: GetOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OauthApplicationResponseDto>>;

    /**
     */
    getOauthApplication(
        requestParameters: GetOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OauthApplicationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getOauthApplicationPermissionsRaw(
        requestParameters: GetOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ApiService>>>;

    /**
     */
    getOauthApplicationPermissions(
        requestParameters: GetOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ApiService>>;

    /**
     *
     * @param {string} organisationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    getOrganisationByIdRaw(
        requestParameters: GetOrganisationByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    getOrganisationById(
        requestParameters: GetOrganisationByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {InviteUserToOrganisationRequestDto} [inviteUserToOrganisationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    inviteUserRaw(
        requestParameters: InviteUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    inviteUser(
        requestParameters: InviteUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {RemoveAdClientRequestDto} [removeAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    removeAdClientRaw(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    removeAdClient(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {RemoveUserFromOrganisationRequestDto} [removeUserFromOrganisationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    removeUserRaw(
        requestParameters: RemoveUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    removeUser(
        requestParameters: RemoveUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {UpdateAdClientRequestDto} [updateAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    updateAdClientDetailsRaw(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    updateAdClientDetails(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {string} clientId
     * @param {UpdateOauthApplicationRequestDto} [updateOauthApplicationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    updateOauthApplicationRaw(
        requestParameters: UpdateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    updateOauthApplication(
        requestParameters: UpdateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {string} clientId
     * @param {UpdateOauthApplicationPermissionsRequestDto} [updateOauthApplicationPermissionsRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    updateOauthApplicationPermissionsRaw(
        requestParameters: UpdateOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    updateOauthApplicationPermissions(
        requestParameters: UpdateOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {UpdateOrganisationRequestDto} [updateOrganisationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    updateOrganisationRaw(
        requestParameters: UpdateOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    updateOrganisation(
        requestParameters: UpdateOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;

    /**
     *
     * @param {string} organisationId
     * @param {UpdateUserAccessRequestDto} [updateUserAccessRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApiInterface
     */
    updateUserAccessStatusRaw(
        requestParameters: UpdateUserAccessStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>>;

    /**
     */
    updateUserAccessStatus(
        requestParameters: UpdateUserAccessStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto>;
}

/**
 *
 */
export class OrganisationApi extends runtime.BaseAPI implements OrganisationApiInterface {
    /**
     */
    async createAdClientRaw(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling createAdClient.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/ad-clients`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateAdClientRequestDtoToJSON(requestParameters.createAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createAdClient(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.createAdClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOauthApplicationRaw(
        requestParameters: CreateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreateOauthApplicationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling createOauthApplication.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOauthApplicationRequestDtoToJSON(
                    requestParameters.createOauthApplicationRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreateOauthApplicationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createOauthApplication(
        requestParameters: CreateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreateOauthApplicationResponseDto> {
        const response = await this.createOauthApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOauthApplicationRaw(
        requestParameters: DeleteOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling deleteOauthApplication.',
            );
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter requestParameters.clientId was null or undefined when calling deleteOauthApplication.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps/{clientId}`
                    .replace(
                        `{${'organisationId'}}`,
                        encodeURIComponent(String(requestParameters.organisationId)),
                    )
                    .replace(
                        `{${'clientId'}}`,
                        encodeURIComponent(String(requestParameters.clientId)),
                    ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async deleteOauthApplication(
        requestParameters: DeleteOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.deleteOauthApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllAdClientsRaw(
        requestParameters: GetAllAdClientsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdClientDto>>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getAllAdClients.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/ad-clients`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(AdClientDtoFromJSON),
        );
    }

    /**
     */
    async getAllAdClients(
        requestParameters: GetAllAdClientsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdClientDto>> {
        const response = await this.getAllAdClientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllOauthApplicationsRaw(
        requestParameters: GetAllOauthApplicationsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<OauthApplicationResponseDto>>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getAllOauthApplications.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(OauthApplicationResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllOauthApplications(
        requestParameters: GetAllOauthApplicationsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<OauthApplicationResponseDto>> {
        const response = await this.getAllOauthApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllUsersAssociatedWithOrganisationRaw(
        requestParameters: GetAllUsersAssociatedWithOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<UserWithAccessLevelDto>>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getAllUsersAssociatedWithOrganisation.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/users`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(UserWithAccessLevelDtoFromJSON),
        );
    }

    /**
     */
    async getAllUsersAssociatedWithOrganisation(
        requestParameters: GetAllUsersAssociatedWithOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<UserWithAccessLevelDto>> {
        const response = await this.getAllUsersAssociatedWithOrganisationRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getOauthApplicationRaw(
        requestParameters: GetOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OauthApplicationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getOauthApplication.',
            );
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter requestParameters.clientId was null or undefined when calling getOauthApplication.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps/{clientId}`
                    .replace(
                        `{${'organisationId'}}`,
                        encodeURIComponent(String(requestParameters.organisationId)),
                    )
                    .replace(
                        `{${'clientId'}}`,
                        encodeURIComponent(String(requestParameters.clientId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OauthApplicationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getOauthApplication(
        requestParameters: GetOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OauthApplicationResponseDto> {
        const response = await this.getOauthApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOauthApplicationPermissionsRaw(
        requestParameters: GetOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ApiService>>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getOauthApplicationPermissions.',
            );
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter requestParameters.clientId was null or undefined when calling getOauthApplicationPermissions.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps/{clientId}/permissions`
                    .replace(
                        `{${'organisationId'}}`,
                        encodeURIComponent(String(requestParameters.organisationId)),
                    )
                    .replace(
                        `{${'clientId'}}`,
                        encodeURIComponent(String(requestParameters.clientId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ApiServiceFromJSON),
        );
    }

    /**
     */
    async getOauthApplicationPermissions(
        requestParameters: GetOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ApiService>> {
        const response = await this.getOauthApplicationPermissionsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getOrganisationByIdRaw(
        requestParameters: GetOrganisationByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationById.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getOrganisationById(
        requestParameters: GetOrganisationByIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.getOrganisationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inviteUserRaw(
        requestParameters: InviteUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling inviteUser.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/users/invite`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InviteUserToOrganisationRequestDtoToJSON(
                    requestParameters.inviteUserToOrganisationRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async inviteUser(
        requestParameters: InviteUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.inviteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeAdClientRaw(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling removeAdClient.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/ad-clients`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: RemoveAdClientRequestDtoToJSON(requestParameters.removeAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async removeAdClient(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.removeAdClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserRaw(
        requestParameters: RemoveUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling removeUser.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/users`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: RemoveUserFromOrganisationRequestDtoToJSON(
                    requestParameters.removeUserFromOrganisationRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async removeUser(
        requestParameters: RemoveUserRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.removeUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAdClientDetailsRaw(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling updateAdClientDetails.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/ad-clients`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateAdClientRequestDtoToJSON(requestParameters.updateAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateAdClientDetails(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.updateAdClientDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOauthApplicationRaw(
        requestParameters: UpdateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling updateOauthApplication.',
            );
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter requestParameters.clientId was null or undefined when calling updateOauthApplication.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps/{clientId}`
                    .replace(
                        `{${'organisationId'}}`,
                        encodeURIComponent(String(requestParameters.organisationId)),
                    )
                    .replace(
                        `{${'clientId'}}`,
                        encodeURIComponent(String(requestParameters.clientId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOauthApplicationRequestDtoToJSON(
                    requestParameters.updateOauthApplicationRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateOauthApplication(
        requestParameters: UpdateOauthApplicationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.updateOauthApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOauthApplicationPermissionsRaw(
        requestParameters: UpdateOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling updateOauthApplicationPermissions.',
            );
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter requestParameters.clientId was null or undefined when calling updateOauthApplicationPermissions.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/oauth-apps/{clientId}/permissions`
                    .replace(
                        `{${'organisationId'}}`,
                        encodeURIComponent(String(requestParameters.organisationId)),
                    )
                    .replace(
                        `{${'clientId'}}`,
                        encodeURIComponent(String(requestParameters.clientId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOauthApplicationPermissionsRequestDtoToJSON(
                    requestParameters.updateOauthApplicationPermissionsRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateOauthApplicationPermissions(
        requestParameters: UpdateOauthApplicationPermissionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.updateOauthApplicationPermissionsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updateOrganisationRaw(
        requestParameters: UpdateOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling updateOrganisation.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOrganisationRequestDtoToJSON(
                    requestParameters.updateOrganisationRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateOrganisation(
        requestParameters: UpdateOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.updateOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserAccessStatusRaw(
        requestParameters: UpdateUserAccessStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationResponseDto>> {
        if (
            requestParameters.organisationId === null ||
            requestParameters.organisationId === undefined
        ) {
            throw new runtime.RequiredError(
                'organisationId',
                'Required parameter requestParameters.organisationId was null or undefined when calling updateUserAccessStatus.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/organisation/{organisationId}/users`.replace(
                    `{${'organisationId'}}`,
                    encodeURIComponent(String(requestParameters.organisationId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateUserAccessRequestDtoToJSON(
                    requestParameters.updateUserAccessRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateUserAccessStatus(
        requestParameters: UpdateUserAccessStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationResponseDto> {
        const response = await this.updateUserAccessStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
