import { FC } from 'react';
import {
    Button,
    Card,
    CardBody,
    Center,
    Container,
    Heading,
    Icon,
    Text,
    useConst,
    VStack,
} from '@chakra-ui/react';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ForbiddenPage: FC = () => {
    const { t } = useTranslation();
    const forbiddenText = useConst(() => t('pages.forbidden', { returnObjects: true }));
    const navigate = useNavigate();
    return (
        <Container height="100vh">
            <Center height="70%">
                <Card width="45rem">
                    <CardBody>
                        <VStack>
                            <Icon boxSize="3.5em" color="red.500" as={NoSymbolIcon} />
                            <Center>
                                <Heading>{forbiddenText.heading}</Heading>
                            </Center>
                            <Center>
                                <Text>{forbiddenText.subtitle}</Text>
                            </Center>
                            <Button variant="link" onClick={() => navigate('/')}>
                                Go back
                            </Button>
                        </VStack>
                    </CardBody>
                </Card>
            </Center>
        </Container>
    );
};
