/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ReachCurvePointResponseDto
 */
export interface ReachCurvePointResponseDto {
    /**
     *
     * @type {number}
     * @memberof ReachCurvePointResponseDto
     */
    reachPercentage?: number;
    /**
     *
     * @type {number}
     * @memberof ReachCurvePointResponseDto
     */
    reach?: number;
    /**
     *
     * @type {number}
     * @memberof ReachCurvePointResponseDto
     */
    budget?: number;
    /**
     *
     * @type {number}
     * @memberof ReachCurvePointResponseDto
     */
    trp?: number;
}

/**
 * Check if a given object implements the ReachCurvePointResponseDto interface.
 */
export function instanceOfReachCurvePointResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReachCurvePointResponseDtoFromJSON(json: any): ReachCurvePointResponseDto {
    return ReachCurvePointResponseDtoFromJSONTyped(json, false);
}

export function ReachCurvePointResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ReachCurvePointResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        reachPercentage: !exists(json, 'reach_percentage') ? undefined : json['reach_percentage'],
        reach: !exists(json, 'reach') ? undefined : json['reach'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        trp: !exists(json, 'trp') ? undefined : json['trp'],
    };
}

export function ReachCurvePointResponseDtoToJSON(value?: ReachCurvePointResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        reach_percentage: value.reachPercentage,
        reach: value.reach,
        budget: value.budget,
        trp: value.trp,
    };
}
