import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveBreakdownHierarchyReportDemoData: ProveReportData = {
    "breakdown": [
        {
            "value": "Mobile",
            "nested": [
                {
                    "value": "www.publisher1.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.86,
                                "impressionsActive5s": 5051,
                                "impressionsActive4s": 8638,
                                "avgNonAttention": 0.53,
                                "impressions": 179152,
                                "avgPassiveAttention": 3.14,
                                "impressionsActive3s": 14533,
                                "impressionsActive2s": 29167,
                                "impressionsActive1s": 54015
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher3.com",
                    "nested": [
                        {
                            "value": "dis.std.mrec",
                            "metrics": {
                                "avgActiveAttention": 1.65,
                                "impressionsActive5s": 5969,
                                "impressionsActive4s": 9198,
                                "avgNonAttention": 1.61,
                                "impressions": 74599,
                                "avgPassiveAttention": 6.76,
                                "impressionsActive3s": 14143,
                                "impressionsActive2s": 24306,
                                "impressionsActive1s": 39674
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher4.com",
                    "nested": [
                        {
                            "value": "vid.std.instream",
                            "metrics": {
                                "avgActiveAttention": 2.39,
                                "impressionsActive5s": 163,
                                "impressionsActive4s": 942,
                                "avgNonAttention": 1.41,
                                "impressions": 58119,
                                "avgPassiveAttention": 7.39,
                                "impressionsActive3s": 5888,
                                "impressionsActive2s": 16911,
                                "impressionsActive1s": 33280
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher5.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.25,
                                "impressionsActive5s": 56,
                                "impressionsActive4s": 640,
                                "avgNonAttention": 1.29,
                                "impressions": 43264,
                                "avgPassiveAttention": 8.9,
                                "impressionsActive3s": 4351,
                                "impressionsActive2s": 11408,
                                "impressionsActive1s": 22195
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher7.com",
                    "nested": [
                        {
                            "value": "vid.std.instream",
                            "metrics": {
                                "avgActiveAttention": 1.98,
                                "impressionsActive5s": 4500,
                                "impressionsActive4s": 7532,
                                "avgNonAttention": 2.52,
                                "impressions": 32493,
                                "avgPassiveAttention": 9.96,
                                "impressionsActive3s": 10043,
                                "impressionsActive2s": 13366,
                                "impressionsActive1s": 16824
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher8.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.07,
                                "impressionsActive5s": 844,
                                "impressionsActive4s": 1564,
                                "avgNonAttention": 1.57,
                                "impressions": 27574,
                                "avgPassiveAttention": 5.51,
                                "impressionsActive3s": 2685,
                                "impressionsActive2s": 5253,
                                "impressionsActive1s": 10702
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher9.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.44,
                                "impressionsActive5s": 399,
                                "impressionsActive4s": 672,
                                "avgNonAttention": 0.84,
                                "impressions": 26077,
                                "avgPassiveAttention": 2.16,
                                "impressionsActive3s": 1049,
                                "impressionsActive2s": 1911,
                                "impressionsActive1s": 3785
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher10.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.85,
                                "impressionsActive5s": 456,
                                "impressionsActive4s": 773,
                                "avgNonAttention": 0.52,
                                "impressions": 25276,
                                "avgPassiveAttention": 2.85,
                                "impressionsActive3s": 1261,
                                "impressionsActive2s": 2786,
                                "impressionsActive1s": 7245
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher11.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.29,
                                "impressionsActive5s": 833,
                                "impressionsActive4s": 1443,
                                "avgNonAttention": 0.62,
                                "impressions": 19131,
                                "avgPassiveAttention": 4.49,
                                "impressionsActive3s": 2289,
                                "impressionsActive2s": 4328,
                                "impressionsActive1s": 7754
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher12.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.05,
                                "impressionsActive5s": 491,
                                "impressionsActive4s": 1049,
                                "avgNonAttention": 3.86,
                                "impressions": 15443,
                                "avgPassiveAttention": 8.32,
                                "impressionsActive3s": 2082,
                                "impressionsActive2s": 3366,
                                "impressionsActive1s": 4888
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher13.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.02,
                                "impressionsActive5s": 552,
                                "impressionsActive4s": 862,
                                "avgNonAttention": 2.62,
                                "impressions": 13905,
                                "avgPassiveAttention": 6.74,
                                "impressionsActive3s": 1150,
                                "impressionsActive2s": 2348,
                                "impressionsActive1s": 5380
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher14.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.81,
                                "impressionsActive5s": 280,
                                "impressionsActive4s": 423,
                                "avgNonAttention": 1.99,
                                "impressions": 11640,
                                "avgPassiveAttention": 4.99,
                                "impressionsActive3s": 614,
                                "impressionsActive2s": 1495,
                                "impressionsActive1s": 3745
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher16.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.59,
                                "impressionsActive5s": 34,
                                "impressionsActive4s": 308,
                                "avgNonAttention": 2.09,
                                "impressions": 8969,
                                "avgPassiveAttention": 12.69,
                                "impressionsActive3s": 1719,
                                "impressionsActive2s": 3520,
                                "impressionsActive1s": 5268
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher15.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 2.04,
                                "impressionsActive5s": 1117,
                                "impressionsActive4s": 1969,
                                "avgNonAttention": 2.15,
                                "impressions": 8767,
                                "avgPassiveAttention": 10.1,
                                "impressionsActive3s": 2735,
                                "impressionsActive2s": 3728,
                                "impressionsActive1s": 4786
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher17.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.33,
                                "impressionsActive5s": 5,
                                "impressionsActive4s": 84,
                                "avgNonAttention": 1.3,
                                "impressions": 7998,
                                "avgPassiveAttention": 9.37,
                                "impressionsActive3s": 656,
                                "impressionsActive2s": 2130,
                                "impressionsActive1s": 4542
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher18.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.91,
                                "impressionsActive5s": 197,
                                "impressionsActive4s": 313,
                                "avgNonAttention": 2.24,
                                "impressions": 7370,
                                "avgPassiveAttention": 5.83,
                                "impressionsActive3s": 453,
                                "impressionsActive2s": 1078,
                                "impressionsActive1s": 2564
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher19.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.85,
                                "impressionsActive5s": 729,
                                "impressionsActive4s": 1189,
                                "avgNonAttention": 1.99,
                                "impressions": 6681,
                                "avgPassiveAttention": 9.07,
                                "impressionsActive3s": 1678,
                                "impressionsActive2s": 2481,
                                "impressionsActive1s": 3574
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher20.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.52,
                                "impressionsActive5s": 395,
                                "impressionsActive4s": 697,
                                "avgNonAttention": 2.38,
                                "impressions": 5615,
                                "avgPassiveAttention": 6.96,
                                "impressionsActive3s": 967,
                                "impressionsActive2s": 1588,
                                "impressionsActive1s": 2712
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher21.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 1.62,
                                "impressionsActive5s": 20,
                                "impressionsActive4s": 94,
                                "avgNonAttention": 1.37,
                                "impressions": 5375,
                                "avgPassiveAttention": 11.17,
                                "impressionsActive3s": 622,
                                "impressionsActive2s": 1776,
                                "impressionsActive1s": 3708
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher22.com",
                    "nested": [
                        {
                            "value": "dis.std.mobile-banner",
                            "metrics": {
                                "avgActiveAttention": 0.88,
                                "impressionsActive5s": 129,
                                "impressionsActive4s": 212,
                                "avgNonAttention": 1.85,
                                "impressions": 5216,
                                "avgPassiveAttention": 5.14,
                                "impressionsActive3s": 314,
                                "impressionsActive2s": 708,
                                "impressionsActive1s": 1688
                            }
                        }
                    ]
                }
            ]
        },
        {
            "value": "Tablet",
            "nested": [
                {
                    "value": "www.publisher2.com",
                    "nested": [
                        {
                            "value": "dis.std.mrec",
                            "metrics": {
                                "avgActiveAttention": 1.17,
                                "impressionsActive5s": 4689,
                                "impressionsActive4s": 8011,
                                "avgNonAttention": 0.71,
                                "impressions": 152525,
                                "avgPassiveAttention": 4.17,
                                "impressionsActive3s": 12904,
                                "impressionsActive2s": 27265,
                                "impressionsActive1s": 61539
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher6.com",
                    "nested": [
                        {
                            "value": "dis.std.mrec",
                            "metrics": {
                                "avgActiveAttention": 2.08,
                                "impressionsActive5s": 4657,
                                "impressionsActive4s": 8158,
                                "avgNonAttention": 2.04,
                                "impressions": 36160,
                                "avgPassiveAttention": 9.8,
                                "impressionsActive3s": 11697,
                                "impressionsActive2s": 16337,
                                "impressionsActive1s": 20676
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher3.com",
                    "nested": [
                        {
                            "value": "vid.std.instream",
                            "metrics": {
                                "avgActiveAttention": 1.87,
                                "impressionsActive5s": 393,
                                "impressionsActive4s": 817,
                                "avgNonAttention": 0.79,
                                "impressions": 7739,
                                "avgPassiveAttention": 7.59,
                                "impressionsActive3s": 1896,
                                "impressionsActive2s": 3021,
                                "impressionsActive1s": 5035
                            }
                        }
                    ]
                },
                {
                    "value": "www.publisher1.com",
                    "nested": [
                        {
                            "value": "dis.std.mrec",
                            "metrics": {
                                "avgActiveAttention": 1.2,
                                "impressionsActive5s": 145,
                                "impressionsActive4s": 222,
                                "avgNonAttention": 0.79,
                                "impressions": 2078,
                                "avgPassiveAttention": 3.92,
                                "impressionsActive3s": 308,
                                "impressionsActive2s": 492,
                                "impressionsActive1s": 776
                            }
                        }
                    ]
                }
            ]
        }
    ]
};





