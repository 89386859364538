/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BrandedMomentsRequestDto } from './BrandedMomentsRequestDto';
import {
    BrandedMomentsRequestDtoFromJSON,
    BrandedMomentsRequestDtoFromJSONTyped,
    BrandedMomentsRequestDtoToJSON,
} from './BrandedMomentsRequestDto';

/**
 *
 * @export
 * @interface CreateCreativeRequestDto
 */
export interface CreateCreativeRequestDto {
    /**
     *
     * @type {string}
     * @memberof CreateCreativeRequestDto
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CreateCreativeRequestDto
     */
    duration?: number;
    /**
     *
     * @type {Array<BrandedMomentsRequestDto>}
     * @memberof CreateCreativeRequestDto
     */
    brandedMoments?: Array<BrandedMomentsRequestDto> | null;
}

/**
 * Check if a given object implements the CreateCreativeRequestDto interface.
 */
export function instanceOfCreateCreativeRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCreativeRequestDtoFromJSON(json: any): CreateCreativeRequestDto {
    return CreateCreativeRequestDtoFromJSONTyped(json, false);
}

export function CreateCreativeRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateCreativeRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        duration: !exists(json, 'duration') ? undefined : json['duration'],
        brandedMoments: !exists(json, 'branded_moments')
            ? undefined
            : json['branded_moments'] === null
            ? null
            : (json['branded_moments'] as Array<any>).map(BrandedMomentsRequestDtoFromJSON),
    };
}

export function CreateCreativeRequestDtoToJSON(value?: CreateCreativeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        duration: value.duration,
        branded_moments:
            value.brandedMoments === undefined
                ? undefined
                : value.brandedMoments === null
                ? null
                : (value.brandedMoments as Array<any>).map(BrandedMomentsRequestDtoToJSON),
    };
}
