/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AccessLevelType } from './AccessLevelType';
import {
    AccessLevelTypeFromJSON,
    AccessLevelTypeFromJSONTyped,
    AccessLevelTypeToJSON,
} from './AccessLevelType';
import type { OrganisationUserStatus } from './OrganisationUserStatus';
import {
    OrganisationUserStatusFromJSON,
    OrganisationUserStatusFromJSONTyped,
    OrganisationUserStatusToJSON,
} from './OrganisationUserStatus';

/**
 *
 * @export
 * @interface UpdateUserAccessRequestDto
 */
export interface UpdateUserAccessRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateUserAccessRequestDto
     */
    userId?: string;
    /**
     *
     * @type {AccessLevelType}
     * @memberof UpdateUserAccessRequestDto
     */
    accessLevel?: AccessLevelType;
    /**
     *
     * @type {OrganisationUserStatus}
     * @memberof UpdateUserAccessRequestDto
     */
    accessStatus?: OrganisationUserStatus;
}

/**
 * Check if a given object implements the UpdateUserAccessRequestDto interface.
 */
export function instanceOfUpdateUserAccessRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserAccessRequestDtoFromJSON(json: any): UpdateUserAccessRequestDto {
    return UpdateUserAccessRequestDtoFromJSONTyped(json, false);
}

export function UpdateUserAccessRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateUserAccessRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
        accessLevel: !exists(json, 'access_level')
            ? undefined
            : AccessLevelTypeFromJSON(json['access_level']),
        accessStatus: !exists(json, 'access_status')
            ? undefined
            : OrganisationUserStatusFromJSON(json['access_status']),
    };
}

export function UpdateUserAccessRequestDtoToJSON(value?: UpdateUserAccessRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_id: value.userId,
        access_level: AccessLevelTypeToJSON(value.accessLevel),
        access_status: OrganisationUserStatusToJSON(value.accessStatus),
    };
}
