/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PackageTier = {
    Free: 'free',
    Paid: 'paid'
} as const;
export type PackageTier = typeof PackageTier[keyof typeof PackageTier];


export function PackageTierFromJSON(json: any): PackageTier {
    return PackageTierFromJSONTyped(json, false);
}

export function PackageTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageTier {
    return json as PackageTier;
}

export function PackageTierToJSON(value?: PackageTier | null): any {
    return value as any;
}

