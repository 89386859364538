import { importTemplate } from '@configs/importTemplate';

const { channelHeader } = importTemplate.headers;
const { inputHeaders } = importTemplate.headers;

export const AllChannels = importTemplate.channels;
export type Channel = typeof AllChannels[number];
export const isChannel = (value: string): value is Channel => {
    return (AllChannels as unknown as Array<string>).includes(value);
};

export const AllColumns = [channelHeader, ...inputHeaders] as const;
export type Column = typeof AllColumns[number];
export const isColumn = (value: string): value is Column => {
    return (AllColumns as unknown as Array<string>).includes(value);
};

export interface Row
    extends Record<typeof channelHeader, Channel>,
        Record<typeof inputHeaders[number], number> {}

export const isRow = (object: any): object is Row => {
    return AllColumns.every((column) => column in object);
};

export const isTable = (objects: Array<any>): objects is Array<Row> => {
    return objects.every((row) => isRow(row));
};
