import {
    CampaignBasicInfoResponseDto,
    CampaignResponseDto,
    CampaignStatus,
} from '@api-clients/media-plan';
import {
    Badge,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Input,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack,
    useBoolean,
    useDisclosure,
} from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { Column, DataTable, Row } from '@components/molecules';
import { routing } from '@configs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
    ArrowTopRightOnSquareIcon,
    EllipsisVerticalIcon,
    PencilIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import { useDeleteCampaign, useGetCampaigns } from '@hooks/campaigns';
import { useUpdateCampaign } from '@hooks/campaigns/useUpdateCampaign';
import { useCustomToast } from '@hooks/toast';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContextHelper } from '@hooks/_contexts';
import { AsyncCard } from '@components/atoms';

export interface CampaignManagementSectionProps {
    legacyMediaPlans: CampaignBasicInfoResponseDto[]
}

interface DataRow {
    campaignName: string;
    createdAt: Date;
    updatedAt: Date;
    managedByUserId: string;
    isDraft: boolean;
}
const campaignIdQueryKey = 'campaignId';

export const CampaignManagementSection: FC<CampaignManagementSectionProps> = ({legacyMediaPlans}) => {
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();
    const users = organisation?.users;
    const { t } = useTranslation('mediaPlans');
    const campaignText = t('mediaPlanning.campaignManagement', {
        returnObjects: true,
    });
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { errorToast, successToast } = useCustomToast();
    const {
        isOpen: deleteCampaignDialogIsOpen,
        onOpen: deleteCampaignDialogOnOpen,
        onClose: deleteCampaignDialogOnClose,
    } = useDisclosure();

    const deleteCampaignIdInUrl = () => {
        setSearchParams((params) => {
            params.delete(campaignIdQueryKey);
            return params;
        });
    };
    const resetStateAndRefreshPage = () => {
        deleteCampaignIdInUrl();
        navigate(0);
    };
    const { deleteCampaign } = useDeleteCampaign();
    const [isDeletingCampaign, setIsDeletingCampaign] = useBoolean();
    const handleDeleteCampaign = async (isConfirmation: boolean) => {
        if (!isConfirmation) {
            deleteCampaignIdInUrl();
            deleteCampaignDialogOnClose();
            return;
        }
        setIsDeletingCampaign.on();
        const campaignId = searchParams.get(campaignIdQueryKey);
        try {
            if (!campaignId) {
                throw new Error(
                    'Something went wrong when deleting campaign, unable to get campaign id',
                );
            }
            await deleteCampaign(campaignId);
            successToast(campaignText.toast.success.heading, campaignText.toast.success.subtitle);
        } catch (error) {
            errorToast(campaignText.toast.error.heading, campaignText.toast.error.subtitle);
        } finally {
            setIsDeletingCampaign.off();
            deleteCampaignDialogOnClose();
            resetStateAndRefreshPage();
        }
    };
    const {
        isOpen: renameCampaignDialogIsOpen,
        onOpen: renameCampaignDialogOnOpen,
        onClose: renameCampaignDialogClose,
    } = useDisclosure();
    const [campaignName, setCampaignName] = useState('');
    const renameCampaignDialogOnClose = () => {
        setCampaignName('');
        renameCampaignDialogClose();
    };
    const [isRenamingCampaign, setIsRenamingCampaign] = useBoolean();
    const { updateCampaign } = useUpdateCampaign();
    const handleRenameCampaign = async (isConfirmation: boolean) => {
        if (!isConfirmation) {
            deleteCampaignIdInUrl();
            renameCampaignDialogOnClose();
            return;
        }
        try {
            setIsRenamingCampaign.on();
            const campaignId = searchParams.get(campaignIdQueryKey);
            if (!campaignId) {
                throw new Error(
                    'Something went wrong when renaming campaign, unable to get campaign id',
                );
            }
            await updateCampaign(campaignId, { campaignName });
            successToast(
                campaignText.toast.renameSuccess.heading,
                campaignText.toast.renameSuccess.subtitle,
            );
        } catch (error) {
            errorToast(
                campaignText.toast.renameError.heading,
                campaignText.toast.renameError.subtitle,
            );
        } finally {
            setIsRenamingCampaign.off();
            renameCampaignDialogOnClose();
            resetStateAndRefreshPage();
        }
    };
    const getUserNameFromId = (id?: string | null) => {
        const currentUser = users?.find((user) => user.id === id);
        return `${currentUser?.firstName} ${currentUser?.lastName}`;
    };

    const tableText = campaignText.campaignTable;

    const menuItemList = [
        [
            {
                id: 1,
                text: 'Rename',
                icon: PencilIcon,
                disableOnDraft: true,
                onClick: (
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    row: Row<DataRow>,
                ) => {
                    if (row.isDraft) {
                        return;
                    }
                    setSearchParams((params) => {
                        params.set(campaignIdQueryKey, row.key);
                        return params;
                    });
                    setCampaignName(row.campaignName);
                    renameCampaignDialogOnOpen();
                },
            },
            {
                id: 2,
                text: 'Open in new tab',
                icon: ArrowTopRightOnSquareIcon,
                disableOnDraft: true,
                onClick: (
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    row: Row<DataRow>,
                ) => {
                    if (row.isDraft) {
                        return;
                    }
                    window.open(
                        `${routing.plan.root.path}/${row.key}`,
                        '_blank',
                        'noopener',
                    );
                },
            },
        ],
        [
            {
                id: 3,
                text: 'Delete',
                disableOnDraft: false,
                icon: TrashIcon,
                onClick: (
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    row: Row<DataRow>,
                ) => {
                    setSearchParams((params) => {
                        params.set(campaignIdQueryKey, row.key);
                        return params;
                    });
                    deleteCampaignDialogOnOpen();
                },
            },
        ],
    ];
    const columns: Array<Column<DataRow>> = [
        {
            title: tableText.headers.campaignName,
            dataIndex: 'campaignName',
            key: 'campaignName',
            render: (row) => {
                return (
                    <Flex alignItems="center" gap="1rem">
                        <Text noOfLines={1}>
                            <Link
                                as={ReactLink}
                                to={`${routing.plan.root.path}/${row.key}`}
                                wordBreak="break-all"
                                whiteSpace="normal"
                                _groupHover={{ textDecoration: 'underline' }}
                            >
                                {row.campaignName}
                            </Link>
                        </Text>
                        {row.isDraft && (
                            <Badge colorScheme="yellow" textTransform="none">
                                In progress
                            </Badge>
                        )}
                    </Flex>
                );
            },
            sorter: (a, b) => a.campaignName.localeCompare(b.campaignName),
        },
        {
            title: tableText.headers.dateCreated,
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
            render: (row) => <>{row.createdAt.toLocaleDateString()}</>,
        },
        {
            title: tableText.headers.lastModified,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a, b) => a.updatedAt.getTime() - b.updatedAt.getTime(),
            render: (row) => <>{row.updatedAt.toLocaleDateString()}</>,
        },
        {
            title: tableText.headers.owner,
            dataIndex: 'managedByUserId',
            key: 'owner',
            sorter: (a, b) => a.campaignName.localeCompare(b.campaignName),
        },
        {
            title: '',
            key: 'action',
            render: (row) => (
                <Menu>
                    <MenuButton
                        color="gray.400"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        as={IconButton}
                        isRound
                        variant="ghost"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        icon={<EllipsisVerticalIcon />}
                        size="sm"
                    />
                    <MenuList py="0.6rem">
                        {menuItemList.map((section, index, menuItemListArray) => (
                            <React.Fragment
                                key={`${index}-${
                                    section.length > 0 ? section[0].id.toString() : ''
                                }`}
                            >
                                {section.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        px="1.2rem"
                                        py="0.4rem"
                                        display="flex"
                                        gap="0.5rem"
                                        alignItems="center"
                                        isDisabled={row.isDraft && item.disableOnDraft}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            item.onClick(e, row);
                                        }}
                                    >
                                        <Icon
                                            as={item.icon}
                                            boxSize="1.1em"
                                            strokeWidth="2px"
                                            color="gray.600"
                                            transform="translateY(1px)"
                                        />
                                        <Text color="gray.700">{item.text}</Text>
                                    </MenuItem>
                                ))}
                                {index < menuItemListArray.length - 1 && <Divider py="0.25rem" />}
                            </React.Fragment>
                        ))}
                    </MenuList>
                </Menu>
            ),
        },
    ];

    const dataSource: Array<Row<DataRow>> = (legacyMediaPlans ?? []).map((campaign) => {
        return {
            key: campaign.id!,
            campaignName: campaign.campaignName!,
            createdAt: campaign.createdAt!,
            updatedAt: campaign.updatedAt!,
            managedByUserId: getUserNameFromId(campaign.managedByUserId),
            isDraft: campaign.campaignStatus === CampaignStatus.Draft,
        };
    });

    return (
        <AsyncCard isLoading={!legacyMediaPlans}>
            <DataTable
                isPaginated
                defaultPageSize={10}
                dataSource={dataSource}
                columns={columns}
                onRow={(row) => ({
                    onClick: () => {
                        if (row.isDraft) {
                            navigate(
                                `${routing.plan.root.path}/${row.key}/${routing.plan.create.path}`,
                            );
                            return;
                        }
                        navigate(`${routing.plan.root.path}/${row.key}`);
                    },
                    _hover: {
                        cursor: 'pointer',
                        bgColor: 'gray.50',
                    },
                })}
            />
            <ConfirmDialog
                isOpen={deleteCampaignDialogIsOpen}
                onClose={(isConfirmed) => handleDeleteCampaign(isConfirmed)}
                headerText="Delete campaign"
                isDestructive
                isLoading={isDeletingCampaign}
                confirmButtonText="Delete"
                colorScheme="red"
                cancelButtonText="Cancel"
            >
                <Text color="gray.700">You cannot undo this action</Text>
            </ConfirmDialog>

            <ConfirmDialog
                isOpen={renameCampaignDialogIsOpen}
                onClose={(isConfirmed) => handleRenameCampaign(isConfirmed)}
                headerText="Rename media plan"
                isLoading={isRenamingCampaign}
                confirmButtonText="Rename"
                cancelButtonText="Cancel"
            >
                <FormControl>
                    <FormLabel>New name</FormLabel>
                    <Input value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                </FormControl>
            </ConfirmDialog>
        </AsyncCard>
    );
};
