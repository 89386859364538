import { FC } from 'react';
import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import { AttentionPlanLogo } from '@components/pdf/AttentionPlanLogo';
import { FormatDecayResponseDto } from '@api-clients/attention-data';
import { numberFormatter } from '@apps/attention360/pages';

const tableCellBorder = '1px solid #E2E8F0';
const styles = StyleSheet.create({
    logo: {
        left: 330,
        top: 30,
        width: 200,
        height: 66,
    },
    firstPage: {
        paddingVertical: 15,
        paddingHorizontal: 30,
        fontSize: 12,
        position: 'relative',
    },
    body: {
        marginTop: 50,
        fontSize: 8,
    },
    sectionTitle: {
        fontSize: 16,
        color: '#2F6E45',
    },
    table: {
        marginTop: 10,
        borderTop: tableCellBorder,
        borderLeft: tableCellBorder,
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        backgroundColor: '#F9F9FA',
    },
    tableBody: {
        fontSize: 8,
    },
    tableHeaderCell: {
        width: 130,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        padding: 12,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableCell: {
        width: 130,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        padding: 12,
    },
});

type CreativeRecommendationSectionPdfProps = {
    formatDecayResponses: FormatDecayResponseDto[];
};

export const CreativeRecommendationSectionPdf: FC<CreativeRecommendationSectionPdfProps> = ({
    formatDecayResponses,
}) => {
    return (
        <Document>
            <Page wrap orientation="portrait" style={styles.firstPage}>
                <AttentionPlanLogo style={styles.logo} />
                <View style={styles.body}>
                    <Text style={styles.sectionTitle}>Creative recommendation</Text>
                    <View style={styles.table}>
                        <Text style={styles.tableHeaderCell}>CHANNEL</Text>
                        <Text style={styles.tableHeaderCell}>FORMAT</Text>
                        <Text style={styles.tableHeaderCell}>MIN DURATION</Text>
                        <Text style={styles.tableHeaderCell}>MAX DURATION</Text>
                        <Text style={styles.tableHeaderCell}>RETENTION RATE</Text>
                    </View>
                    <View>
                        {formatDecayResponses.map((format, index) => (
                            <View
                                key={format.adFormatCode ?? index.toString()}
                                style={styles.tableRow}
                            >
                                <Text style={{ ...styles.tableCell, borderLeft: tableCellBorder }}>
                                    {format.adChannelName ?? ''}
                                </Text>
                                <Text style={styles.tableCell}>{format.adFormatName ?? ''}</Text>
                                <Text style={{ ...styles.tableCell, textAlign: 'right' }}>
                                    {format.minDuration ?? 0}
                                </Text>
                                <Text style={{ ...styles.tableCell, textAlign: 'right' }}>
                                    {format.maxDuration ?? 0}
                                </Text>
                                <Text style={{ ...styles.tableCell, textAlign: 'right' }}>
                                    {numberFormatter.format(format.retentionRate ?? 0)}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    );
};
