export const updateMessages = {
    user: {
        success: {
            title: 'Information updated.',
            description: "User's information has been successfully updated.",
        },
        error: {
            title: `User's information has not been updated.`,
            description: 'An error has occurred.',
        },
    },
    organisation: {
        success: {
            title: 'Information updated.',
            description: "Organisation's information has been successfully updated.",
        },
        error: {
            title: `Organisation's information has not been updated.`,
            description: 'An error has occurred.',
        },
    },
};
