/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface FromAuth0
 */
export interface FromAuth0 {
    /**
     *
     * @type {string}
     * @memberof FromAuth0
     */
    emailAddress?: string | null;
}

/**
 * Check if a given object implements the FromAuth0 interface.
 */
export function instanceOfFromAuth0(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FromAuth0FromJSON(json: any): FromAuth0 {
    return FromAuth0FromJSONTyped(json, false);
}

export function FromAuth0FromJSONTyped(json: any, ignoreDiscriminator: boolean): FromAuth0 {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
    };
}

export function FromAuth0ToJSON(value?: FromAuth0 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        email_address: value.emailAddress,
    };
}
