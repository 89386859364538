import { FeatureCode, LimitType, PackageTier } from '@api-clients/subscriptions';
import { Box, Flex } from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { HorizontalNavBar } from '@components/molecules';
import { DisplayedOnLoginModals } from '@components/molecules/modals';
import { layoutPadding, topbarHeight } from '@configs/layoutConfig';
import { useAppContextHelper } from '@hooks/_contexts';
import { useLayoutContainerContext } from '@hooks/_contexts/layoutContainer/LayoutContainerContextProvider';
import { AmplifiedColorScheme } from '@shared/cores';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';

export interface BaseNavBarsLayoutProps {
    colorScheme?: AmplifiedColorScheme;
    children?: ReactNode;
}

interface GuidelineCookiesModel {
    lastShown?: Date;
}

export const ScrollContext = createContext(null);

export const NavBarContainer: FC<BaseNavBarsLayoutProps> = ({ children, colorScheme }) => {
    const {
        currentContextValues: { user, organisation, featureBalances },
    } = useAppContextHelper();
    const location = useLocation();

    const username = `${user?.firstName} ${user?.lastName}`;

    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const isPaidPlan =
        featureBalances?.find(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;
    const packageTier = isPaidPlan ? PackageTier.Paid : PackageTier.Free;

    const scrollRef = useLayoutContainerContext();

    useEffect(() => {
        const properties = {
            organisation_name: organisation?.organisationName ?? 'N/A',
            organisation_id: organisation?.id ?? 'N/A',
            user_id: user?.id ?? 'N/A',
            user_email: user?.emailAddress ?? 'N/A',
            user_name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim(),
        };
        posthog.capture('$pageview', properties);
    }, [location]);

    return (
        <>
            {user && organisation && featureBalances && packageTier ? (
                <>
                    {!isLoggingOut ? (
                        <>
                            <DisplayedOnLoginModals />
                            <Flex height="100vh" width="100vw" direction="column">
                                <HorizontalNavBar
                                    height={topbarHeight}
                                    profileName={username}
                                    setIsLoggingOut={setIsLoggingOut}
                                />
                                <Box ref={scrollRef} flexGrow={1} overflow="auto">
                                    <Box padding={layoutPadding}>{children}</Box>
                                </Box>
                            </Flex>
                        </>
                    ) : (
                        <Loading loadingText="Logging Out" />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};
