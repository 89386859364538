export const formValidationMessages = {
    confirmationCode: {
        isRequired: 'Confirmation code is required.',
    },
    email: {
        isRequired: 'Email is required.',
        isInvalid: 'Email is invalid.',
    },
    password: {
        default: {
            isRequired: 'Password is required.',
        },
        current: {
            isRequired: 'Current password is required.',
        },
        new: {
            isRequired: 'New password is required.',
            minRequirements: 'Password does not meet character requirements',
        },
        confirmed: {
            isRequired: 'Confirm password is required.',
            requiresMatched: 'Confirm password must match with new password.',
        },
    },
    user: {
        firstName: {
            isRequired: 'First name is required.',
        },
        lastName: {
            isRequired: 'Last name is required.',
        },
        phoneNumber: {
            isInvalid: 'Phone number is invalid.',
        },
    },
    organisation: {
        name: {
            isRequired: 'Organisation name is required.',
        },
    },
    adClient: {
        clientName: {
            isRequired: 'Client name is required.',
        },
        primaryContact: {
            isRequired: 'Primary contact is required.',
        },
        phone: {
            isRequired: 'Primary contact phone is required.',
        },
        email: {
            isInvalid: 'Email is invalid.',
            isRequired: 'Primary contact email is required.',
        },
    },
    campaign: {
        campaignName: {
            isRequired: 'Campaign name is required.',
        },
        client: {
            isRequired: 'Client must be selected',
        },
    },
    oauthApplications: {
        applicationName: {
            isRequired: 'Application name is required',
        },
    },
    progressiveSignUp: {
        companyName: {
            isRequired: 'Company name is required',
        },
        firstName: {
            isRequired: 'First name is required',
        },
        lastName: {
            isRequired: 'Last name is required',
        },
        phoneNumber: {
            isValid: 'Please enter your phone number correctly',
        },
    },
};
