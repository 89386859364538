import { FeatureCode, LimitType, PackageCode } from '@api-clients/subscriptions';
import { useAppContextHelper } from '@hooks/_contexts';

export const useSubscriptionHelper = () => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    function isAttentionDataOnly() {
        const hasAttentionDataAccess = !!featureBalances?.find(
            (f) =>
                f.featureCode === FeatureCode.AttentionDataAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );

        const hasOptimiserAccess = !!featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
        );

        return (hasAttentionDataAccess && !hasOptimiserAccess) ?? false;
    }

    function canAccessBenchmarks() {
        return (
            featureBalances?.find(
                (item) =>
                    item.featureCode?.toLowerCase().includes('benchmark') &&
                    item.limitType === LimitType.Unlimited,
            ) !== undefined
        );
    }

    const isOptimiserPaidPlan = featureBalances?.find(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    return {
        isAttentionDataOnly,
        canAccessBenchmarks,
        isOptimiserPaidPlan,
    };
};
