import React, {FC, useEffect} from 'react'
import {
    Box,
    Container,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tooltip,
    Tr,
    useToken,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {Chart} from "react-chartjs-2";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {ProveDashboardSearchCriteriaProps} from "@components/organisms/prove/ProveDashboardHeader";
import {retrieveProveOverviewReportDataAsync} from "@redux/slices/prove/dashboard/thunks";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {useApiConfiguration} from "@hooks/configuration";
import {useDispatch, useSelector} from "@redux";
import {proveDashboardOverviewData, proveDashboardOverviewIsPending} from "@redux/slices/prove/dashboard/selectors";
import {AsyncCard} from "@components/atoms";
import {ProveDashboardNoData} from "@components/organisms/prove/ProveDashboardNoData";
import {ProveFilter} from "@api-clients/prove/schema/ProveFilter";

export interface ProveDashboardOverviewProps {
    proveCampaign: ProveCampaign;
    searchCriteria: ProveDashboardSearchCriteriaProps;
    filters: Array<ProveFilter>;
}

export const ProveDashboardOverview: FC<ProveDashboardOverviewProps> = ({
                                                                            proveCampaign,
                                                                            searchCriteria,
                                                                            filters
                                                                        }) => {

    const dispatch = useDispatch();
    const {getProveReportServiceConfig} = useApiConfiguration();

    const dashboardOverviewIsPendingSelector = useSelector(proveDashboardOverviewIsPending);
    const dashboardOverviewDataSelector = useSelector(proveDashboardOverviewData);

    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    useEffect(() => {
        if (!searchCriteria) {
            return;
        }

        const retrieveOverviewReportData = async () => {
            dispatch(retrieveProveOverviewReportDataAsync({
                configuration: await getProveReportServiceConfig(),
                campaign: proveCampaign!,
                lineItems: searchCriteria.selectedLineItems,
                start: searchCriteria.startDate,
                end: searchCriteria.endDate,
                filters
            }));
        }

        retrieveOverviewReportData().catch();
    }, [searchCriteria, filters]);

    const chartColors = useToken('colors', [
        'green.500',
        'orange.500'
    ]);

    const pieChartColors = useToken('colors', [
        'navyBlue.amplified',
        'gray.300'
    ]);

    const formatDuration = (valueInSeconds: number) => {
        const seconds = Math.floor(valueInSeconds % 60);
        const minutes = Math.floor((valueInSeconds / 60) % 60);
        const hours = Math.floor((valueInSeconds / 60 / 60) % 24);
        const days = Math.floor((valueInSeconds / 60 / 60 / 24) % 7);
        const weeks = Math.floor(valueInSeconds / 60 / 60 / 24 / 7);

        const data = [];
        if (weeks > 0) {
            const label = (weeks === 1) ? 'week' : 'weeks';
            data.push(`${weeks} ${label}`);
        }

        if (days > 0) {
            const label = (days === 1) ? 'day' : 'days';
            data.push(`${days} ${label}`);
        }

        if (hours > 0) {
            const label = (hours === 1) ? 'hour' : 'hours';
            data.push(`${hours} ${label}`);
        }

        if (minutes > 0) {
            const label = (minutes === 1) ? 'minute' : 'minutes';
            data.push(`${minutes} ${label}`);
        }

        if (seconds > 0) {
            const label = (seconds === 1) ? 'second' : 'seconds';
            data.push(`${seconds} ${label}`);
        }

        return data;
    }

    const formatCountMetric = (metric: number) => {
        if (metric < 1000) {
            return metric.toLocaleString();
        }

        if (metric < 1000000) {
            return `${Math.round(metric / 1000)}K`;
        }

        return `${(metric / 1000000).toFixed(3)}M`;
    }

    const buildAttentionPieChart = (config: {
        height: number,
        labels: Array<string>,
        data: Array<number>,
        tooltipFormatter(data: number): Array<string>
    }) => {
        return (<Chart height={config.height} type="pie"
                       data={{
                           labels: config.labels,
                           datasets: [{
                               data: config.data,
                               backgroundColor: pieChartColors,
                               borderWidth: 1
                           }]
                       }}
                       options={{
                           maintainAspectRatio: false,
                           plugins: {
                               legend: {
                                   display: true
                               },
                               tooltip: {
                                   callbacks: {
                                       title: (tooltipItems) => {
                                           const formattedValue = `${((tooltipItems.at(0)!.raw as number / (dashboardOverviewDataSelector!.sumTotalAttention! + dashboardOverviewDataSelector!.sumNonAttention!)) * 100).toFixed(2)}%`
                                           return `${tooltipItems.at(0)!.label}: ${formattedValue}`;
                                       },
                                       label: () => {
                                           return '';
                                       },
                                       afterBody: (tooltipItems) => {
                                           const data = tooltipItems.at(0)!.raw as number;

                                           return config.tooltipFormatter(data);
                                       },
                                   },
                               },
                           }
                       }}/>);
    }

    const buildAttentionBarChart = (config: {
        height: number,
        isHorizontal?: boolean,
        labels: Array<string>,
        data: Array<number>,
        tooltipFormatter(data: number): Array<string>
    }) => {
        return (<Chart height={config.height} type="bar"
                       data={{
                           labels: config.labels,
                           datasets: [{
                               data: config.data,
                               backgroundColor: chartColors,
                               borderWidth: 1,
                               borderRadius: 15
                           }]
                       }}
                       options={{
                           maintainAspectRatio: false,
                           indexAxis: config.isHorizontal ? 'y' : 'x',
                           plugins: {
                               legend: {
                                   display: false
                               },
                               tooltip: {
                                   callbacks: {
                                       title: (tooltipItems) => {
                                           return tooltipItems.at(0)!.label;
                                       },
                                       label: () => {
                                           return '';
                                       },
                                       afterBody: (tooltipItems) => {
                                           const data = tooltipItems.at(0)!.raw as number;

                                           return config.tooltipFormatter(data);
                                       },
                                   },
                               },
                           },
                           scales: {
                               y: {
                                   display: (config.isHorizontal),
                                   grid: {
                                       display: false,
                                   }
                               },
                               x: {
                                   display: (!config.isHorizontal),
                                   grid: {
                                       display: false,
                                   }
                               }
                           }
                       }}/>);
    }

    return (
        <AsyncCard
            isLoading={dashboardOverviewIsPendingSelector}>
            {dashboardOverviewDataSelector && dashboardOverviewDataSelector.impressions > 0 ? (
                <Container mt={4}>
                    <Grid templateColumns="repeat(4, 1fr)" gap="1rem">
                        <GridItem>
                            <VStack>
                                <HStack>
                                    <Heading size="xs">{content.overview.impressions.label}</Heading>
                                    <Tooltip
                                        label={content.overview.impressions.tooltip}
                                    >
                                        <Icon as={InformationCircleIcon} boxSize="1.2rem"/>
                                    </Tooltip>
                                </HStack>
                                <Heading
                                    fontSize="2rem"
                                    variant="metric">{formatCountMetric(dashboardOverviewDataSelector.impressions)}</Heading>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <VStack>
                                <HStack>
                                    <Heading size="xs">{content.overview.totalAttention.label}</Heading>
                                    <Tooltip
                                        label={content.overview.totalAttention.tooltip}
                                    >
                                        <Icon as={InformationCircleIcon} boxSize="1.2rem"/>
                                    </Tooltip>
                                </HStack>
                                <Box>{buildAttentionBarChart({
                                    height: 120,
                                    isHorizontal: true,
                                    labels: [content.overview.active, content.overview.passive],
                                    data: [dashboardOverviewDataSelector.sumActiveAttention!, dashboardOverviewDataSelector.sumPassiveAttention!],
                                    tooltipFormatter: (data) => {
                                        return formatDuration(data);
                                    }
                                })}</Box>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <VStack>
                                <HStack>
                                    <Heading size="xs">{content.overview.averageAttention.label}</Heading>
                                    <Tooltip
                                        label={content.overview.averageAttention.tooltip}
                                    >
                                        <Icon as={InformationCircleIcon} boxSize="1.2rem"/>
                                    </Tooltip>
                                </HStack>
                                <TableContainer>
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td>{content.overview.active}</Td>
                                                <Td>{dashboardOverviewDataSelector.avgActiveAttention}s</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>{content.overview.passive}</Td>
                                                <Td>{dashboardOverviewDataSelector.avgPassiveAttention}s</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <VStack>
                                <HStack>
                                    <Heading size="xs">{content.overview.attentionVsTiv.label}</Heading>
                                    <Tooltip
                                        label={content.overview.attentionVsTiv.tooltip}
                                    >
                                        <Icon as={InformationCircleIcon} boxSize="1.2rem"/>
                                    </Tooltip>
                                </HStack>
                                <Box>{buildAttentionPieChart({
                                    height: 120,
                                    labels: [content.overview.attentionVsTiv.totalAttention, content.overview.attentionVsTiv.noAttention],
                                    data: [dashboardOverviewDataSelector.sumTotalAttention!, dashboardOverviewDataSelector.sumNonAttention!],
                                    tooltipFormatter: (data) => {
                                        return formatDuration(data);
                                    }
                                })}</Box>
                            </VStack>
                        </GridItem>
                    </Grid>
                </Container>
            ) : (
                <ProveDashboardNoData/>
            )}
        </AsyncCard>
    )

}
