import {createAppAsyncThunk} from "@/redux/createAppAsyncThunk";
import {FindProveAdFormatsApi} from "@api-clients/prove/api/FindProveAdFormatsApi";
import {Configuration} from "@api-clients/runtime";

export const findProveAdFormatsAsync = createAppAsyncThunk(
    'prove/adFormat/findAll',
    async (
        payload: {
            configuration: Configuration,
            isDemoCampaign?: boolean
        },
        {getState}
    ) => {
        const state = getState();

        const availableAdFormats = state.proveAdFormat.available || [];

        if (availableAdFormats.length > 0) {
            return availableAdFormats;
        }

        return FindProveAdFormatsApi.execute(payload.configuration);
    }
)
