export const formLabels = {
    addNewClient: {
        clientName: { field: 'Client name' },
        primaryContactName: { field: 'Primary contact person' },
        phone: { field: 'Phone' },
        email: { field: 'Email' },
    },
    addNewMember: {
        firstName: { field: 'First name' },
        lastName: { field: 'Last name' },
        email: { field: 'Email' },
        accessLevel: { field: 'Access level' },
    },
    accountDetails: {
        firstName: { field: 'First name' },
        lastName: { field: 'Last name' },
        email: { field: 'Email' },
        roleTitle: { field: 'Role title' },
        phoneNumber: { field: 'Phone number' },
        password: {
            field: 'Password',
            placeholder: 'Change password',
        },
    },
    auth: {
        changePassword: {
            currentPassword: { field: 'Current password' },
            newPassword: { field: 'New password' },
            confirmPassword: { field: 'Confirm password' },
        },
        confirmForgotPassword: {
            confirmationCode: { field: 'Confirmation code' },
            newPassword: { field: 'New password' },
            confirmPassword: { field: 'Confirm password' },
        },
        login: {
            emailAddress: { field: 'Email address' },
            password: { field: 'Password' },
        },
        newPasswordRequired: {
            newPassword: { field: 'New password' },
        },
    },
    organisationDetails: {
        organisationName: { field: 'Organisation name' },
        region: { field: 'Region' },
    },
};
