import { FormatBenchmarkResponseDto } from '@api-clients/attention-data';
import {
    GetAllBenchmarkResultsForAllFormatsRequest, StrategyReportsApi
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAllFormatBenchmarksProps {
    getAllFormatBenchmarks: (
        request: GetAllBenchmarkResultsForAllFormatsRequest,
    ) => Promise<Array<FormatBenchmarkResponseDto>>;
}

type useGetAllFormatBenchmarksHook = () => useGetAllFormatBenchmarksProps;

export const useGetAllFormatBenchmarks: useGetAllFormatBenchmarksHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllFormatBenchmarks(
        request: GetAllBenchmarkResultsForAllFormatsRequest,
    ): Promise<Array<FormatBenchmarkResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getAllBenchmarkResultsForAllFormats(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAllFormatBenchmarks,
    };
};
