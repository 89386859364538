import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    ScaleFade,
    Skeleton,
    Tag,
    Tooltip,
    useConst,
} from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
    chakraComponents,
    MenuListProps,
    MultiValueGenericProps,
    OptionProps,
    Select,
} from 'chakra-react-select';
import { FC, useCallback } from 'react';

interface Option {
    value: string;
    label: string;
    tooltip?: string;
}

export interface SingleSelectProps {
    label: string;
    options: Array<Option>;
    setSelectedOption: (value?: Option) => void;
    selectedOption?: Option;
    tooltip?: string;
    isRequired?: boolean;
    isLoaded?: boolean;
    rightContent?: ReactJSXElement;
    leftContent?: ReactJSXElement;
    noOptionsMessage?: string;
    useSkeletonLoading?: boolean;
    isReadOnly?: boolean;
}

interface CustomOptionProps {
    data: { tooltip: string };
}

// slightly hacky way to achieve having multiple selected options show up as only one with the selection count visible
const CustomComponents = {
    MenuList: (props: MenuListProps<any>) => {
        return (
            <ScaleFade in>
                <chakraComponents.MenuList {...props} />
            </ScaleFade>
        );
    },
    Option: ({ children, data, ...props }: OptionProps<any> & CustomOptionProps & any) => (
        <Tooltip label={data.tooltip} placement="left">
            <Box>
                <chakraComponents.Option {...props}>{children}</chakraComponents.Option>
            </Box>
        </Tooltip>
    ),
};

export const SingleSelect: FC<SingleSelectProps> = ({
    label,
    options,
    setSelectedOption,
    selectedOption,
    tooltip,
    isRequired,
    isLoaded = true,
    rightContent,
    leftContent,
    noOptionsMessage,
    useSkeletonLoading = true,
    isReadOnly,
}) => {
    const noOptionsMessageComponent = useCallback(
        () => <>{noOptionsMessage}</>,
        [noOptionsMessage],
    );

    return (
        <FormControl isRequired={isRequired}>
            <Flex>
                <FormLabel>{label}</FormLabel>
                {tooltip && (
                    <Tooltip label={tooltip}>
                        <Icon color="gray.400" as={InformationCircleIcon} />
                    </Tooltip>
                )}
            </Flex>
            <Skeleton isLoaded={isLoaded || !useSkeletonLoading}>
                <HStack>
                    {leftContent}
                    <Box width="100%">
                        <Select
                            isReadOnly={isReadOnly}
                            isLoading={!isLoaded}
                            isSearchable={false}
                            colorScheme="green"
                            hideSelectedOptions={false}
                            selectedOptionStyle="check"
                            options={options}
                            value={selectedOption}
                            components={CustomComponents}
                            noOptionsMessage={
                                noOptionsMessage ? noOptionsMessageComponent : undefined
                            }
                            onChange={(e) => {
                                setSelectedOption(e as Option);
                            }}
                            styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 5000 }),
                                option: (provided) => {
                                    return provided.index === 0
                                        ? { ...provided }
                                        : { display: 'none' };
                                },
                            }}
                            chakraStyles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: 'white',
                                }),
                                multiValue: (provided, state) => {
                                    return state.index === 0
                                        ? { ...provided }
                                        : { display: 'none' };
                                },
                            }}
                            useBasicStyles
                            menuPortalTarget={document.body}
                        />
                    </Box>
                    {rightContent}
                </HStack>
            </Skeleton>
        </FormControl>
    );
};
