/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdjustmentToleranceLevel } from './AdjustmentToleranceLevel';
import {
    AdjustmentToleranceLevelFromJSON,
    AdjustmentToleranceLevelFromJSONTyped,
    AdjustmentToleranceLevelToJSON,
} from './AdjustmentToleranceLevel';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { BudgetConstraintType } from './BudgetConstraintType';
import {
    BudgetConstraintTypeFromJSON,
    BudgetConstraintTypeFromJSONTyped,
    BudgetConstraintTypeToJSON,
} from './BudgetConstraintType';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';
import type { ScenarioAdFormatResponseDto } from './ScenarioAdFormatResponseDto';
import {
    ScenarioAdFormatResponseDtoFromJSON,
    ScenarioAdFormatResponseDtoFromJSONTyped,
    ScenarioAdFormatResponseDtoToJSON,
} from './ScenarioAdFormatResponseDto';
import type { ScenarioLevelResultResponseDto } from './ScenarioLevelResultResponseDto';
import {
    ScenarioLevelResultResponseDtoFromJSON,
    ScenarioLevelResultResponseDtoFromJSONTyped,
    ScenarioLevelResultResponseDtoToJSON,
} from './ScenarioLevelResultResponseDto';
import type { ScenarioMinSpendPerChannelResponseDto } from './ScenarioMinSpendPerChannelResponseDto';
import {
    ScenarioMinSpendPerChannelResponseDtoFromJSON,
    ScenarioMinSpendPerChannelResponseDtoFromJSONTyped,
    ScenarioMinSpendPerChannelResponseDtoToJSON,
} from './ScenarioMinSpendPerChannelResponseDto';
import type { ScenarioStatus } from './ScenarioStatus';
import {
    ScenarioStatusFromJSON,
    ScenarioStatusFromJSONTyped,
    ScenarioStatusToJSON,
} from './ScenarioStatus';

/**
 *
 * @export
 * @interface ScenarioResponseDto
 */
export interface ScenarioResponseDto {
    /**
     *
     * @type {Array<ScenarioAdFormatResponseDto>}
     * @memberof ScenarioResponseDto
     */
    adFormats?: Array<ScenarioAdFormatResponseDto> | null;
    /**
     *
     * @type {Array<ScenarioMinSpendPerChannelResponseDto>}
     * @memberof ScenarioResponseDto
     */
    minSpendPerChannels?: Array<ScenarioMinSpendPerChannelResponseDto> | null;
    /**
     *
     * @type {Array<ScenarioLevelResultResponseDto>}
     * @memberof ScenarioResponseDto
     */
    scenarioLevelResults?: Array<ScenarioLevelResultResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof ScenarioResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {string}
     * @memberof ScenarioResponseDto
     */
    scenarioName?: string | null;
    /**
     *
     * @type {ScenarioStatus}
     * @memberof ScenarioResponseDto
     */
    scenarioStatus?: ScenarioStatus;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof ScenarioResponseDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioResponseDto
     */
    budget?: number | null;
    /**
     *
     * @type {Date}
     * @memberof ScenarioResponseDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof ScenarioResponseDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {BudgetConstraintType}
     * @memberof ScenarioResponseDto
     */
    budgetConstraintType?: BudgetConstraintType;
    /**
     *
     * @type {AdjustmentToleranceLevel}
     * @memberof ScenarioResponseDto
     */
    adjustmentToleranceLevel?: AdjustmentToleranceLevel;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof ScenarioResponseDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof ScenarioResponseDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {boolean}
     * @memberof ScenarioResponseDto
     */
    isUsingGlobalCountry?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof ScenarioResponseDto
     */
    isAccessibleToEveryone?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof ScenarioResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ScenarioResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ScenarioResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ScenarioResponseDto interface.
 */
export function instanceOfScenarioResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioResponseDtoFromJSON(json: any): ScenarioResponseDto {
    return ScenarioResponseDtoFromJSONTyped(json, false);
}

export function ScenarioResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormats: !exists(json, 'ad_formats')
            ? undefined
            : json['ad_formats'] === null
            ? null
            : (json['ad_formats'] as Array<any>).map(ScenarioAdFormatResponseDtoFromJSON),
        minSpendPerChannels: !exists(json, 'min_spend_per_channels')
            ? undefined
            : json['min_spend_per_channels'] === null
            ? null
            : (json['min_spend_per_channels'] as Array<any>).map(
                  ScenarioMinSpendPerChannelResponseDtoFromJSON,
              ),
        scenarioLevelResults: !exists(json, 'scenario_level_results')
            ? undefined
            : json['scenario_level_results'] === null
            ? null
            : (json['scenario_level_results'] as Array<any>).map(
                  ScenarioLevelResultResponseDtoFromJSON,
              ),
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        scenarioStatus: !exists(json, 'scenario_status')
            ? undefined
            : ScenarioStatusFromJSON(json['scenario_status']),
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        budgetConstraintType: !exists(json, 'budget_constraint_type')
            ? undefined
            : BudgetConstraintTypeFromJSON(json['budget_constraint_type']),
        adjustmentToleranceLevel: !exists(json, 'adjustment_tolerance_level')
            ? undefined
            : AdjustmentToleranceLevelFromJSON(json['adjustment_tolerance_level']),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        isUsingGlobalCountry: !exists(json, 'is_using_global_country')
            ? undefined
            : json['is_using_global_country'],
        isAccessibleToEveryone: !exists(json, 'is_accessible_to_everyone')
            ? undefined
            : json['is_accessible_to_everyone'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ScenarioResponseDtoToJSON(value?: ScenarioResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_formats:
            value.adFormats === undefined
                ? undefined
                : value.adFormats === null
                ? null
                : (value.adFormats as Array<any>).map(ScenarioAdFormatResponseDtoToJSON),
        min_spend_per_channels:
            value.minSpendPerChannels === undefined
                ? undefined
                : value.minSpendPerChannels === null
                ? null
                : (value.minSpendPerChannels as Array<any>).map(
                      ScenarioMinSpendPerChannelResponseDtoToJSON,
                  ),
        scenario_level_results:
            value.scenarioLevelResults === undefined
                ? undefined
                : value.scenarioLevelResults === null
                ? null
                : (value.scenarioLevelResults as Array<any>).map(
                      ScenarioLevelResultResponseDtoToJSON,
                  ),
        campaign_id: value.campaignId,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        scenario_name: value.scenarioName,
        scenario_status: ScenarioStatusToJSON(value.scenarioStatus),
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        budget: value.budget,
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        budget_constraint_type: BudgetConstraintTypeToJSON(value.budgetConstraintType),
        adjustment_tolerance_level: AdjustmentToleranceLevelToJSON(value.adjustmentToleranceLevel),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        is_using_global_country: value.isUsingGlobalCountry,
        is_accessible_to_everyone: value.isAccessibleToEveryone,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
