export const dialogsLabels = {
    addNewMember: {
        header: 'Invite member',
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
    },
    removeMember: {
        header: 'Remove member',
        body: 'Are you sure you want to remove <strong>{{memberName}}</strong> from <strong>{{companyName}}</strong>?',
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
    },
    addNewClient: {
        header: 'Add new client',
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
    },
    removeClient: {
        header: 'Remove client',
        body: 'Are you sure you want to remove <strong>{{clientName}}</strong> from <strong>{{companyName}}</strong>?',
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
    },
};
