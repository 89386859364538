import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { Box, Collapse, Flex, useBoolean } from '@chakra-ui/react';
import { ExpandingIcon } from '@components/atoms';
import React, { FC } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

export interface CollapsableComponentProps {
    headingText: string;
    subtitleText?: string | ReactElement;
    rightElement?: string | ReactElement;
    children: ReactElement;
}
export const CollapsibleComponent: FC<CollapsableComponentProps> = ({
    headingText,
    subtitleText,
    children,
    rightElement,
}) => {
    const [isOpen, setIsOpen] = useBoolean(true)

    return (
        <>
            <Flex>
                <Box flex={1} onClick={setIsOpen.toggle} cursor="pointer">
                    <ContainerTitle
                        headingText={
                            <>
                                {headingText}
                                <ExpandingIcon
                                    color="green.500"
                                    marginLeft=".5rem"
                                    boxSize="1.5rem"
                                    isOpen={isOpen}
                                />
                            </>
                        }
                        subtitleText={subtitleText}
                    />
                </Box>
                {rightElement}
            </Flex>
            <Collapse style={{ overflow: isOpen ? 'visible' : 'hidden' }} in={isOpen}>{children}</Collapse>
        </>
    );
};
