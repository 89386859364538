import { Chart } from 'chart.js';
import { colors } from '@assets/design-system/theming/colors';

const lineColor = colors.colors.gray['300'];
const lineWidth = 2;

export const SegmentDividerPluginFactory = (xTick: number, yTick: number) => (chartCtx: Chart) => {
    if (xTick < 0 || yTick < 0) {
        throw new Error('Invalid xTick or yTick params');
    }
    const ctx = chartCtx.canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Unable to get canvas 2d context');
    }
    const { scales, chartArea } = chartCtx;

    // the horizontal divider, y coordinate is fixed at @param {number} yTick
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(chartArea.left, scales.y.getPixelForValue(yTick));
    ctx.lineTo(chartArea.right, scales.y.getPixelForValue(yTick));
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = lineColor; // Change the color of the line
    ctx.stroke();
    ctx.restore();

    // the vertical divider, x coordinate is fixed at @param {number} xTick
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(scales.x.getPixelForValue(xTick), chartArea.top);
    ctx.lineTo(scales.x.getPixelForValue(xTick), chartArea.bottom);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = lineColor; // Change the color of the line
    ctx.stroke();
    ctx.restore();
};
