import {
    GetScenarioLevelComparisonOfScenariosRequest,
    GetScenarioLevelResultComparisonResponseDto,
    ScenarioApi,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetScenarioLevelResultsComparisonHook = () => {
    getScenarioLevelResultsComparison: (
        campaignId: string,
        scenarioId: string,
    ) => Promise<GetScenarioLevelResultComparisonResponseDto>;
};

export const useGetScenarioLevelResultsComparison: useGetScenarioLevelResultsComparisonHook =
    () => {
        const { getMediaPlanConfig } = useApiConfiguration();
        const { getDisplayedErrorMessage } = useHelper();

        const getScenarioLevelResultsComparison = async (
            campaignId: string,
            scenarioId: string,
        ) => {
            try {
                const config = await getMediaPlanConfig();
                const api = new ScenarioApi(config);
                return await api.getScenarioLevelComparisonOfScenarios({ campaignId, scenarioId });
            } catch (e: any) {
                throw new Error(await getDisplayedErrorMessage(e));
            }
        };
        return { getScenarioLevelResultsComparison };
    };
