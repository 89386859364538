export const initialiseMicrosoftClarity = () => {
    // eslint-disable-next-line func-names
    (function (c, l, a, r, i, t, y) {
        // eslint-disable-next-line func-names
        c[a] = c[a] || function () {
            // eslint-disable-next-line prefer-rest-params
            (c[a].q = c[a].q || []).push(arguments)
        };
        t = l.createElement(r);
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${  i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script",
        import.meta.env.VITE_APP_CLARITY_ID);
};
