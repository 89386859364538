import { ShareScenarioApi } from '@api-clients/media-plan/apis/ShareScenarioApi';
import {
    ScenarioShareLinkResponseDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetShareableLinkHook = () => {
    getShareableLink: (scenarioId: string) => Promise<ScenarioShareLinkResponseDto>;
};

export const useGetShareableLink: useGetShareableLinkHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration()
    const { getDisplayedErrorMessage } = useHelper();
    const getShareableLink = async (scenarioId: string) => {
        try {
            const config = await getMediaPlanConfig();
            const api = new ShareScenarioApi(config);
            return api.createNewScenarioSharedLink({ scenarioId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getShareableLink };
};
