import { StrategyReportCreationPage } from '@apps/attention360/pages/strategyReports/StrategyReportCreationPage';
import { routing } from '@configs';
import { Navigate, RouteObject } from 'react-router-dom';
import { StrategyReportPage } from '@apps/attention360/pages';
import { FormatSelectionSection } from '@apps/attention360/pages/strategyReports/FormatSelectionSection';
import { ChannelSelectionSection } from '@apps/attention360/pages/strategyReports/ChannelSelectionSection';
import { AudienceAnalysisSection } from '@apps/attention360/pages/strategyReports/AudienceAnalysisSection';
import { CreativeRecommendationsSection } from '@apps/attention360/pages/strategyReports/CreativeRecommendationsSection';

export const StrategyReportRoutes: Array<RouteObject> = [
    {
        path: `${routing.campaign.root.path}/:campaignId/${routing.strategyReports.root.path}`,
        children: [
            {
                path: `${routing.strategyReports.create.path}`,
                element: <StrategyReportCreationPage />,
            },
            {
                path: `${routing.strategyReports.view.path}/:strategyReportId`,
                element: <StrategyReportPage />,
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate to={routing.strategyReports.view.channelSelection.path} />
                        ),
                    },
                    {
                        path: routing.strategyReports.view.channelSelection.path,
                        element: <ChannelSelectionSection />,
                    },
                    {
                        path: routing.strategyReports.view.formatSelection.path,
                        element: <FormatSelectionSection />,
                    },
                    {
                        path: routing.strategyReports.view.audienceAnalysis.path,
                        element: <AudienceAnalysisSection />,
                    },
                    {
                        path: routing.strategyReports.view.creativeRecommendations.path,
                        element: <CreativeRecommendationsSection />,
                    },
                ],
            },
        ],
    },
];
