/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LimitType = {
    Unlimited: 'unlimited',
    Limited: 'limited'
} as const;
export type LimitType = typeof LimitType[keyof typeof LimitType];


export function LimitTypeFromJSON(json: any): LimitType {
    return LimitTypeFromJSONTyped(json, false);
}

export function LimitTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LimitType {
    return json as LimitType;
}

export function LimitTypeToJSON(value?: LimitType | null): any {
    return value as any;
}

