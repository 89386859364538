import {
    ChannelLevelCurvesResponseDto,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    OptimisationStrategy,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { Button, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentScenarioComparison, useGetSharedScenarioComparison } from '@hooks/scenarios';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ShareScenarioButton } from '@apps/attentionADJUST/components/molecules/manageScenarioMenu/ShareScenarioButton';
import { useGetScenarioLevelResultsComparison } from '@hooks/scenarios/useGetScenarioLevelResultsComparison';
import { ExportToPdfButtonForScenarioSharing } from '@apps/attentionADJUST/components/molecules/manageScenarioMenu/ExportToPdfButtonForScenarioSharing';
import { EveryoneCanViewToggle } from '@apps/attentionADJUST/components/molecules/manageScenarioMenu/EveryoneCanViewToggle';

export interface ManageShareScenarioMenuProps {
    scenarioBasicInfo?: GetScenarioBasicInfoResponseDto;
    reachCurveData: Array<ChannelLevelCurvesResponseDto>;
    scenarioLevelResultsComparison?: GetScenarioLevelResultComparisonResponseDto;
    isFreePlan: boolean;
}

export const ManageShareScenarioMenu: FC<ManageShareScenarioMenuProps> = ({
    scenarioBasicInfo,
    reachCurveData,
    scenarioLevelResultsComparison,
    isFreePlan,
}) => {
    const { getSharedScenarioComparison } = useGetSharedScenarioComparison();
    // for use by the pdf
    const [scenarioWithComparison, setScenarioWithComparison] =
        useState<ScenarioWithComparisonResponseDto>();
    const { scenarioShareLinkId } = useParams();
    useEffect(() => {
        if (!scenarioShareLinkId) {
            return;
        }
        getSharedScenarioComparison({ scenarioShareLinkId }).then((response) =>
            setScenarioWithComparison(response),
        );
    }, [scenarioShareLinkId]);

    return (
        <HStack gap="1rem">
            {scenarioWithComparison && scenarioLevelResultsComparison ? (
                <HStack spacing={3}>
                    <EveryoneCanViewToggle />
                    <ExportToPdfButtonForScenarioSharing
                        scenario={scenarioWithComparison}
                        reachCurveData={reachCurveData}
                        scenarioLevelResultsComparison={scenarioLevelResultsComparison}
                        isFreePlan={isFreePlan}
                    />
                </HStack>
            ) : (
                <Button variant="link" colorScheme="orange" isLoading />
            )}
        </HStack>
    );
};
