import { FC, ReactNode } from 'react';
import { LayoutContainerContextProvider } from '@hooks/_contexts/layoutContainer/LayoutContainerContextProvider';
import { AppContextProvider } from '@hooks/_contexts';

export const AttentionPlanAppProvider: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <AppContextProvider>
            <LayoutContainerContextProvider>{children}</LayoutContainerContextProvider>
        </AppContextProvider>
    );
};
