import { useDisclosure } from '@chakra-ui/react';
import { MobileScreenModal } from '@components/molecules/modals/mobileScreenModal/MobileScreenModal';
import { FC, useEffect } from 'react';

export const DisplayedOnLoginModals: FC = () => {
    const {
        isOpen: isMobileScreenModalOpen,
        onClose: onMobileScreenModalClose,
        onOpen: onMobileScreenModalOpen,
    } = useDisclosure();

    useEffect(() => {
        if (window.matchMedia('(max-width: 768px)').matches) {
            onMobileScreenModalOpen();
        }
    }, []);

    return (
        <>
            <MobileScreenModal
                isOpen={isMobileScreenModalOpen}
                onClose={onMobileScreenModalClose}
            />
        </>
    );
};
