import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveBreakdownLineItemReportDemoData: ProveReportData = {
    "breakdown": [
        {
            "value": "demo-1-b6tgk",
            "metrics": {
                "avgActiveAttention": 1.28,
                "impressionsActive5s": 14758,
                "impressionsActive4s": 25617,
                "avgNonAttention": 1.44,
                "impressions": 315107,
                "avgPassiveAttention": 6.39,
                "impressionsActive3s": 43500,
                "impressionsActive2s": 78346,
                "impressionsActive1s": 135677
            }
        },
        {
            "value": "demo-6-yvfiy",
            "metrics": {
                "avgActiveAttention": 1.1,
                "impressionsActive5s": 3304,
                "impressionsActive4s": 5826,
                "avgNonAttention": 0.98,
                "impressions": 117290,
                "avgPassiveAttention": 5.16,
                "impressionsActive3s": 10773,
                "impressionsActive2s": 23227,
                "impressionsActive1s": 46220
            }
        },
        {
            "value": "demo-5-ymxon",
            "metrics": {
                "avgActiveAttention": 1.04,
                "impressionsActive5s": 3096,
                "impressionsActive4s": 5536,
                "avgNonAttention": 0.86,
                "impressions": 107953,
                "avgPassiveAttention": 4.55,
                "impressionsActive3s": 9652,
                "impressionsActive2s": 19917,
                "impressionsActive1s": 39522
            }
        },
        {
            "value": "demo-2-fv4fu",
            "metrics": {
                "avgActiveAttention": 1.28,
                "impressionsActive5s": 4679,
                "impressionsActive4s": 8134,
                "avgNonAttention": 1.42,
                "impressions": 102876,
                "avgPassiveAttention": 6.41,
                "impressionsActive3s": 14096,
                "impressionsActive2s": 25402,
                "impressionsActive1s": 44580
            }
        },
        {
            "value": "demo-3-bal2b",
            "metrics": {
                "avgActiveAttention": 1.29,
                "impressionsActive5s": 4783,
                "impressionsActive4s": 8255,
                "avgNonAttention": 1.43,
                "impressions": 100744,
                "avgPassiveAttention": 6.39,
                "impressionsActive3s": 14056,
                "impressionsActive2s": 25366,
                "impressionsActive1s": 43737
            }
        },
        {
            "value": "demo-4-ecu8y",
            "metrics": {
                "avgActiveAttention": 1.16,
                "impressionsActive5s": 3218,
                "impressionsActive4s": 5568,
                "avgNonAttention": 1.03,
                "impressions": 98002,
                "avgPassiveAttention": 5.35,
                "impressionsActive3s": 10003,
                "impressionsActive2s": 20347,
                "impressionsActive1s": 39866
            }
        }
    ]
};