import {
    CreateOauthApplicationResponseDto,
    OauthApplicationResponseDto,
} from '@api-clients/account-manager';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    Button,
    Center,
    Container,
    Flex,
    Spacer,
    Tooltip,
    useConst,
    VStack,
} from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { OAuthApplicationListSection } from '@components/organisms';
import { CreateNewOAuthApplicationDialogButton } from '@components/organisms/integrationSection';
import { DisplayClientSecret } from '@components/organisms/integrationSection/secret/DisplayClientSecret';
import { routing } from '@configs';
import { useOauthApplications } from '@hooks/accountManager';
import { useManageMember } from '@hooks/organisations';
import { useAppContextHelper } from '@hooks/_contexts';
import { shouldDisplayApiApplications } from '@shared/utils/formats/countryFeatures';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

export const ManageApplicationPage: FC = () => {
    const { t } = useTranslation();
    const { hasAdministrativePermission } = useManageMember();

    const {
        currentContextValues: { organisation, user, featureBalances },
    } = useAppContextHelper();

    const integrationText = useConst(() =>
        t('pages.applicationManagement', {
            returnObjects: true,
        }),
    );
    const { getAllOauthApplications } = useOauthApplications();

    const [oAuthApplications, setOAuthApplications] = useState<OauthApplicationResponseDto[]>();

    const fetchOAuthApplications = () => {
        setOAuthApplications(undefined);
        getAllOauthApplications().then(setOAuthApplications);
    };

    useEffect(() => {
        fetchOAuthApplications();
    }, []);

    const [createOauthApplicationResponse, setCreateOauthApplicationResponse] =
        useState<CreateOauthApplicationResponseDto>();

    const isCreateButtonEnabled = oAuthApplications && oAuthApplications.length === 0;

    return (
        <Container>
            {organisation && user ? (
                <>
                    {shouldDisplayApiApplications(featureBalances!) &&
                    hasAdministrativePermission(organisation, user) ? (
                        <VStack align="stretch" spacing="1rem">
                            <Flex>
                                <Center>
                                    <ContainerTitle
                                        size="xl"
                                        headingText={integrationText.heading}
                                    />
                                </Center>
                                <Spacer />
                                {createOauthApplicationResponse ? (
                                    <Button
                                        onClick={() => {
                                            fetchOAuthApplications();
                                            setCreateOauthApplicationResponse(undefined);
                                        }}
                                    >
                                        {integrationText.buttons.viewAll}
                                    </Button>
                                ) : (
                                    <Tooltip
                                        isDisabled={isCreateButtonEnabled}
                                        label={
                                            integrationText.tooltips.exhaustedIntegrationCreation
                                        }
                                    >
                                        <CreateNewOAuthApplicationDialogButton
                                            onCreate={setCreateOauthApplicationResponse}
                                            isDisabled={!isCreateButtonEnabled}
                                        />
                                    </Tooltip>
                                )}
                            </Flex>
                            {createOauthApplicationResponse ? (
                                <DisplayClientSecret
                                    oauthApplication={createOauthApplicationResponse}
                                />
                            ) : (
                                <OAuthApplicationListSection
                                    oAuthApplications={oAuthApplications}
                                    organisation={organisation}
                                    onFetch={fetchOAuthApplications}
                                />
                            )}
                        </VStack>
                    ) : (
                        <Navigate to={`/${routing.root.forbidden.url}`} />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </Container>
    );
};
