import { ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { LockIcon } from '@chakra-ui/icons';
import {
    useDisclosure,
    HStack,
    IconButton,
    Checkbox,
    Collapse,
    Stack,
    Box,
    Tooltip,
} from '@chakra-ui/react';
import { ExpandingIcon } from '@components/atoms';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ChannelCheckboxesProps {
    channelCode: string;
    channelName: string;
    onCheckChannel: (channelCode: string) => void;
    formats: Array<ScenarioAdFormatResponseDto>;
    onAdFormatUpdate: (adFormatCode: string, cpm?: number, isChecked?: boolean) => void;
    isFreeTier?: boolean;
}

export const ChannelCheckboxes: FC<ChannelCheckboxesProps> = ({
    channelCode,
    channelName,
    formats,
    onAdFormatUpdate,
    onCheckChannel,
    isFreeTier,
}) => {
    const { t } = useTranslation('benchmark');
    const lockTooltip = t('attentionBenchmarks.lockTooltip', {
        returnObjects: true,
    });

    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

    const allChecked = formats.every((f) => f.isChecked);
    const isIndeterminate = formats.some((f) => f.isChecked) && !allChecked;

    return (
        <>
            <HStack h="40px" borderTopWidth="1px">
                <IconButton
                    variant="ghost"
                    size="xs"
                    onClick={onToggle}
                    icon={
                        <ExpandingIcon
                            direction="right"
                            rotation="90"
                            fontSize="1.2rem"
                            isOpen={isOpen}
                        />
                    }
                    aria-label="expand"
                />
                <Checkbox
                    onChange={() => onCheckChannel(channelCode)}
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    isDisabled={isFreeTier}
                >
                    {channelName}
                </Checkbox>
                {isFreeTier && (
                    <Tooltip label={lockTooltip}>
                        <LockIcon color="gray.400" />
                    </Tooltip>
                )}
            </HStack>

            <Collapse style={{ overflow: isOpen ? 'visible' : 'hidden' }} in={isOpen}>
                <Stack paddingBottom="1rem" pl="3.5rem">
                    {formats
                        .sort((a, b) => a.adFormatName!.localeCompare(b.adFormatName!))
                        .map((f) => (
                            <HStack key={f.adFormatCode}>
                                <Box>
                                    <Checkbox
                                        onChange={(e) =>
                                            onAdFormatUpdate(
                                                f.adFormatCode!,
                                                undefined,
                                                e.target.checked,
                                            )
                                        }
                                        isChecked={f.isChecked ?? false}
                                        isDisabled={isFreeTier}
                                    >
                                        {f.adFormatName}
                                    </Checkbox>
                                </Box>
                            </HStack>
                        ))}
                </Stack>
            </Collapse>
        </>
    );
};
