import {
    Box,
    Button,
    Collapse,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputLeftAddon,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useMultiStyleConfig,
    useTab,
} from '@chakra-ui/react';
import { GroupBase, Select, SelectInstance } from 'chakra-react-select';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState, forwardRef, useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppContext, useAppContextHelper } from '@hooks/_contexts';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/toast';
import {
    useCreateSelfSignUpOrganisation,
    useFinaliseUserDataFromSelfSignup,
} from '@hooks/accountManager';
import { useGetOrganisation } from '@hooks/organisations';
import { useGetUser } from '@hooks/users';
import { useNavigate } from 'react-router-dom';
import { roleOptions } from '@apps/attention360/pages/progressiveSignUp/RoleOptions';
import type { RoleOption } from '@apps/attention360/pages/progressiveSignUp/RoleOptions';
import {
    orgFormValidationSchema,
    userFormValidationschema,
} from '@apps/attention360/pages/progressiveSignUp/validationSchema';

import AllCountries from './AllCountriesData';
import AllCountriesPhoneData from './CountryPhoneCodeData';

interface UserFormData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    yourRole: string; // yourRole will only be populated if user select 'Other' as their 'role' and enter their role
}
interface OrgFormData {
    companyName: string;
}

type CustomTabProps = {};

const CustomTab = forwardRef<HTMLElement, CustomTabProps>((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);
    return (
        <Button
            pointerEvents="none"
            _hover={{ cursor: 'default' }}
            __css={styles.tab}
            {...tabProps}
            width="12.5rem"
            borderBottom="2px solid"
            borderColor={isSelected ? 'green.500' : 'gray.300'}
        />
    );
});

type Option = {
    value: string;
    label: string;
    code: string;
};
const orgTabIndex = 0;
const userTabIndex = 1;
export const ProgressiveSignUpPage: FC<any> = () => {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(orgTabIndex);
    const [isInputOpen, setIsInputOpen] = useState<{
        location: boolean;
        lastName: boolean;
        role: boolean;
        phoneNumber: boolean;
        yourRole: boolean;
    }>({ location: false, lastName: false, role: false, phoneNumber: false, yourRole: false });
    const {
        resetAppContext,
        currentContextValues: { user, organisation },
    } = useAppContextHelper();
    const { getOrganisation } = useGetOrganisation();
    const { getUser } = useGetUser();
    const { appUser, appOrganisation } = useAppContext();
    const { t } = useTranslation();

    useEffect(() => {
        if (!user) {
            return;
        }
        const hasOrg =
            (user.organisations && user.organisations.length !== 0) ||
            (organisation !== null && organisation !== undefined);
        const detailFilled =
            user.firstName &&
            user.firstName.length !== 0 &&
            user.lastName &&
            user.lastName.length !== 0;
        if (hasOrg && detailFilled) {
            resetAppContext();
            navigate('/');
            return;
        }
        if (hasOrg) {
            // if user already has org, redirect user to the second tab to set up user info
            setTabIndex(userTabIndex);
        }
    }, [user, organisation]);

    const userFirstName =
        user && user.firstName && user.firstName.length === 0 ? undefined : user!.firstName;
    const userLastName =
        user && user.lastName && user.lastName.length === 0 ? undefined : user!.lastName;

    useEffect(() => {
        if (userFirstName) {
            setIsInputOpen((old) => ({ ...old, lastName: true }));
        }
    }, [userFirstName]);
    useEffect(() => {
        if (userLastName) {
            setIsInputOpen((old) => ({ ...old, role: true }));
        }
    }, [userLastName]);

    const {
        handleSubmit: handleOrgFormSubmit,
        register: registerOrgForm,
        watch: watchOrgForm,
        formState: { errors: orgFormErrors, isSubmitting: isOrgFormSubmitting },
    } = useForm<OrgFormData>({
        resolver: yupResolver(orgFormValidationSchema),
        mode: 'onChange',
    });

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors, isSubmitting, isValid },
        setFocus: userFormSetFocus,
    } = useForm<UserFormData>({
        resolver: yupResolver(userFormValidationschema),
        mode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
        },
    });

    const [country, setCountry] = useState<Option | null>(null);
    const [phoneCountry, setPhoneCountry] = useState<Option | null>(null);
    const [role, setRole] = useState<RoleOption | null>(null);

    // refactor toast to a hook
    const { successToast, errorToast } = useCustomToast();
    const { createSelfSignUpOrganisation } = useCreateSelfSignUpOrganisation();
    const { finaliseUserDataFromSelfSignup } = useFinaliseUserDataFromSelfSignup();

    const onOrgFormSave: SubmitHandler<OrgFormData> = async (data) => {
        try {
            const response = await createSelfSignUpOrganisation({
                organisationName: data.companyName,
                region: country?.value,
            });
            const org = await getOrganisation();
            appOrganisation.setCurrentValue(org);
            appUser.setCurrentValue(await getUser());
            setTabIndex((old) => old + 1);
        } catch (e: any) {
            errorToast('Something went wrong', 'Unable to update user');
            throw e;
        }
    };

    const onUserFormSave: SubmitHandler<UserFormData> = async (data) => {
        if (!user || !user.id) {
            errorToast('Something went wrong', 'Unable to retrieve current user');
            throw new Error('Unable to update user');
        }
        try {
            const response = await finaliseUserDataFromSelfSignup(user.id, {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                countryPhoneCode: phoneCountry?.code,
                roleTitle: role?.value,
                otherRole: data.yourRole,
            });
            // re-fetch user and org info
            resetAppContext();
            navigate('/');
        } catch (e: any) {
            errorToast('Something went wrong', 'Unable to update user');
            throw e;
        }
    };

    // handle situation when user refreshes the browser while on the second tab
    useEffect(() => {
        if (organisation && organisation.region) {
            setPhoneCountry(
                AllCountriesPhoneData.find(
                    (c) => c.value.toLowerCase() === organisation.region?.toLowerCase(),
                ) ?? null,
            );
        }
    }, []);
    const companyName = watchOrgForm('companyName');
    const firstName = watch('firstName');
    const lastName = watch('lastName');
    const phoneNumber = watch('phoneNumber');
    const countryRef = useRef<SelectInstance<Option, false, GroupBase<Option>>>(null);
    const roleRef = useRef<SelectInstance<RoleOption, false, GroupBase<RoleOption>>>(null);
    return (
        <Container p="3rem">
            <Box>
                <Tabs isFitted index={tabIndex}>
                    <TabList
                        maxWidth="30rem"
                        mx="auto"
                        border="none"
                        display="flex"
                        justifyContent="center"
                        gap="2.5rem"
                    >
                        <CustomTab />
                        <CustomTab />
                    </TabList>
                    <Heading
                        mt="3rem"
                        fontWeight={700}
                        fontSize="1.5rem"
                        lineHeight="1.75rem"
                        textAlign="center"
                    >
                        Start optimising media spend with the Amplified Intelligence platform!
                    </Heading>
                    <TabPanels mt="4rem">
                        <TabPanel>
                            {/** First Tab panel */}
                            <form onSubmit={handleOrgFormSubmit(onOrgFormSave)}>
                                <Container
                                    maxWidth="48rem"
                                    display="flex"
                                    flexDir="column"
                                    gap="2rem"
                                >
                                    <FormControl
                                        isRequired
                                        isInvalid={orgFormErrors.companyName !== undefined}
                                    >
                                        <FormLabel>Company Name</FormLabel>
                                        <Input
                                            {...registerOrgForm('companyName')}
                                            placeholder="Enter company name..."
                                            bg="white"
                                            onBlur={() => {
                                                if (
                                                    orgFormErrors.companyName ||
                                                    companyName.length === 0
                                                ) {
                                                    return;
                                                }
                                                setIsInputOpen((old) => ({
                                                    ...old,
                                                    location: true,
                                                }));
                                            }}
                                        />
                                        <FormErrorMessage>
                                            {orgFormErrors.companyName &&
                                                (orgFormErrors.companyName.message as string)}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl isRequired>
                                        <FormLabel>Location</FormLabel>
                                        <Select
                                            ref={countryRef}
                                            value={country}
                                            onChange={(e) => {
                                                setCountry(e);
                                                setPhoneCountry(
                                                    AllCountriesPhoneData.find(
                                                        (c) => c.value === e?.value,
                                                    ) ?? null,
                                                );
                                            }}
                                            options={AllCountries}
                                            placeholder="Choose country..."
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 6000,
                                                }),
                                            }}
                                            chakraStyles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    background: 'white',
                                                }),
                                            }}
                                            useBasicStyles
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        w="100%"
                                        isDisabled={
                                            orgFormErrors.companyName !== undefined ||
                                            country === null ||
                                            isOrgFormSubmitting
                                        }
                                        isLoading={isOrgFormSubmitting}
                                    >
                                        Next
                                    </Button>
                                </Container>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <form onSubmit={handleSubmit(onUserFormSave)}>
                                {/** Second Tab Panel */}
                                <Container
                                    maxWidth="48rem"
                                    display="flex"
                                    flexDir="column"
                                    gap="2rem"
                                >
                                    <FormControl
                                        isRequired
                                        isInvalid={errors.firstName !== undefined}
                                    >
                                        <FormLabel>First name</FormLabel>
                                        <Input
                                            bg="white"
                                            placeholder="Enter your first name..."
                                            {...register('firstName')}
                                            onBlur={() => {
                                                if (errors.firstName || firstName.length === 0) {
                                                    return;
                                                }
                                                setIsInputOpen((old) => ({
                                                    ...old,
                                                    lastName: true,
                                                }));
                                            }}
                                        />
                                        <FormErrorMessage>
                                            {errors.firstName &&
                                                (errors.firstName.message as string)}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={errors.lastName !== undefined}
                                    >
                                        <FormLabel>Last name</FormLabel>
                                        <Input
                                            bg="white"
                                            placeholder="Enter your last name..."
                                            {...register('lastName')}
                                            onBlur={() => {
                                                if (errors.lastName || lastName.length === 0) {
                                                    return;
                                                }
                                                setIsInputOpen((old) => ({
                                                    ...old,
                                                    role: true,
                                                }));
                                            }}
                                        />
                                        <FormErrorMessage>
                                            {errors.lastName && (errors.lastName.message as string)}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Role</FormLabel>
                                        <Select
                                            ref={roleRef}
                                            useBasicStyles
                                            options={roleOptions}
                                            value={role}
                                            onChange={(newRole) => {
                                                setRole(newRole);
                                                if (!newRole) {
                                                    return;
                                                }
                                                if (newRole.value === 'other') {
                                                    setIsInputOpen((old) => ({
                                                        ...old,
                                                        yourRole: true,
                                                    }));
                                                    return;
                                                }
                                                setIsInputOpen((old) => ({
                                                    ...old,
                                                    yourRole: false,
                                                    phoneNumber: true,
                                                }));
                                            }}
                                            placeholder="Choose your role..."
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 6000,
                                                }),
                                            }}
                                            chakraStyles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    background: 'white',
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                    <Collapse
                                        style={{ overflow: 'visible' }}
                                        in={isInputOpen.yourRole}
                                        onAnimationComplete={() => {
                                            userFormSetFocus('yourRole');
                                        }}
                                    >
                                        <FormControl>
                                            <FormLabel>What&apos;s your role?</FormLabel>
                                            <Input
                                                bg="white"
                                                placeholder="Enter your role..."
                                                {...register('yourRole')}
                                                onBlur={() => {
                                                    if (errors.yourRole) {
                                                        return;
                                                    }
                                                    setIsInputOpen((old) => ({
                                                        ...old,
                                                        phoneNumber: true,
                                                    }));
                                                }}
                                            />
                                            <FormErrorMessage>
                                                {errors.yourRole &&
                                                    (errors.yourRole.message as string)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Collapse>
                                    {/** the regexp below /^\s*$|^\d{3,}$/ means either 0 or more whitespaces or 3 or more digits */}
                                    <FormControl
                                        isInvalid={
                                            phoneNumber != null &&
                                            !/^\s*$|^\d{0,}$/.test(phoneNumber)
                                        }
                                    >
                                        <FormLabel>Phone number</FormLabel>
                                        <Flex alignItems="center" gap="0.5rem">
                                            <Box flexGrow={0} minWidth="15rem">
                                                <Select
                                                    value={phoneCountry}
                                                    onChange={(e) => {
                                                        setPhoneCountry(e);
                                                    }}
                                                    options={AllCountriesPhoneData}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (provided) => ({
                                                            ...provided,
                                                            zIndex: 6000,
                                                        }),
                                                    }}
                                                    chakraStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            background: 'white',
                                                        }),
                                                        // extra styling here
                                                    }}
                                                    useBasicStyles
                                                />
                                            </Box>
                                            <Input
                                                bg="white"
                                                placeholder="Enter your phone number..."
                                                {...register('phoneNumber')}
                                                isInvalid={
                                                    phoneNumber != null &&
                                                    !/^\s*$|^\d{0,}$/.test(phoneNumber)
                                                }
                                            />
                                        </Flex>
                                        <FormErrorMessage>
                                            {t(
                                                'messages.forms.validation.progressiveSignUp.phoneNumber.isValid',
                                            )}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        w="100%"
                                        rightIcon={<Icon as={ArrowRightIcon} />}
                                        isDisabled={
                                            !isValid ||                                           
                                            isSubmitting
                                        }
                                        isLoading={isSubmitting}
                                    >
                                        Get started
                                    </Button>
                                </Container>
                            </form>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Container>
    );
};
