import { Chart } from 'chart.js';

export const WhiteBackgroundPlugin = (chartCtx: Chart) => {
    const ctx = chartCtx.canvas.getContext('2d');
    if (!ctx) {
        return;
    }
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
    ctx.restore();
};
