import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import moment from "moment";
import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";
import {ProveFilter} from "@api-clients/prove/schema/ProveFilter";

export class RetrieveProveBreakdownReportDataApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign,
        lineItems: Array<ProveLineItem>,
        start: Date,
        end: Date,
        filters: Array<ProveFilter>,
        breakdown: Array<string>
    ): Promise<ProveReportData> {
        const lineItemsExternalIds = [] as Array<string>;
        for (let i = 0; i < lineItems.length; i++) {
            lineItemsExternalIds.push(lineItems[i].externalId!);
        }

        const formattedFilters = [] as Array<string>;
        for (let i = 0; i < filters.length; i++) {
            formattedFilters.push(`${filters[i].type}:${encodeURIComponent(filters[i].value)}`);
        }

        const filtersQueryString = (formattedFilters.length > 0) ?
            `&filter=${formattedFilters.join(',')}` : "";

        const formattedStart = encodeURIComponent(moment(start).startOf('day').local(true).toISOString(true));
        const formattedEnd = encodeURIComponent(moment(end).endOf('day').local(true).toISOString(true));

        const response = await fetch(`${configuration.basePath}/prove/v1/report?campaign=${campaign.externalId}&insertionOrder=${campaign.insertionOrder!.externalId}&lineItems=${lineItemsExternalIds.join(',')}${filtersQueryString}&start=${formattedStart}&end=${formattedEnd}&breakdown=${breakdown.join(',')}&metrics=${["impressions", "avgActiveAttention", "avgPassiveAttention", "avgNonAttention", "impressionsActive1s", "impressionsActive2s", "impressionsActive3s", "impressionsActive4s", "impressionsActive5s"].join(",")}&missingValue=-`, {
            method: 'GET',
            headers: configuration.headers
        });

        return response.json();
    }

}
