import { StrategyReportResponseDto } from '@api-clients/attention-data';
import {
    Center,
    Container,
    Flex,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SkeletonText,
    Spacer,
    Stack,
    Tag,
    TagLabel,
    Text,
    useBoolean,
    useDisclosure, Wrap
} from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader
} from '@components/atoms/expandableBanner/ExpandableBanner';
import { routing } from '@configs';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useGetStrategyReport } from '@hooks/strategyReport';
import { useDeleteStrategyReport } from '@hooks/strategyReport/useDeleteStrategyReport';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { selectedGlobalCampaign } from '@redux/slices/global/campaign/selectors';
import { useHelper } from '@shared/utils';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigureAdFormatsButton } from './ConfigureAdFormatsButton';
import { StrategyReportHeaderSection } from './StrategyReportHeaderSection';

export interface StrategyReportBannerProps {
    campaignId: string;
}

const SkeletonDetailText = () => (
    <Center height="1.5rem">
        <SkeletonText noOfLines={1} width="100%" />
    </Center>
);

export const StrategyReportBanner: FC<StrategyReportBannerProps> = ({ campaignId }) => {
    const campaign = useSelector(selectedGlobalCampaign);
    const [isFetching, setIsFetching] = useBoolean(false);
    const [isDeletingReport, setIsDeletingReport] = useBoolean();

    const { strategyReportId } = useParams();
    const { getStrategyReport } = useGetStrategyReport();
    const [strategyReport, setStrategyReport] = useState<StrategyReportResponseDto>();
    const { errorToast, successToast } = useCustomToast();

    const { deleteStrategyReport } = useDeleteStrategyReport();

    const [isDeletingStratgeyReport, setIsDeletingStrategyReport] = useBoolean();
    const navigate = useNavigate();

    const {
        isOpen: deleteStrategyReportIsOpen,
        onOpen: deleteStrategyReportOnOpen,
        onClose: deleteStrategyReportOnClose,
    } = useDisclosure();

    const handleDeleteStrategyReport = async (isConfirmation: boolean) => {
        if (!isConfirmation) {
            deleteStrategyReportOnClose();
            return;
        }

        setIsDeletingStrategyReport.on();
        try {
            await deleteStrategyReport(strategyReportId!);
            successToast('Success', 'Stratgey report successfully deleted');
        } catch (e) {
            errorToast('Something went wrong', 'Stratgey report could not be deleted');
        } finally {
            setIsDeletingStrategyReport.off();
            deleteStrategyReportOnClose();

            navigate(`/${routing.campaign.root.path}`);
        }
    };

    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    const { formatAgeGroup, formatStringToCapitalized, sortAgeGroups } = useHelper();
    const isLoaded = !!strategyReport;

    const {
        helper: { selectGlobalCampaign },
    } = useAppContextHelper();

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
    }, [campaignId]);

    useEffect(() => {
        let ignore = false;
        if (strategyReportId && strategyReportId.length > 0) {
            setStrategyReport(undefined);
            getStrategyReport({ strategyReportId })
                .then((s) => {
                    if (!ignore) setStrategyReport(s);
                })
                .catch(() => errorToast('Something went wrong', 'Please try again later'));
        }

        return () => {
            ignore = true;
        };
    }, [strategyReportId]);

    return (
        <>
            <ConfirmDialog
                isOpen={deleteStrategyReportIsOpen}
                onClose={(isConfirmed) => handleDeleteStrategyReport(isConfirmed)}
                headerText="Delete strategy report"
                isDestructive
                isLoading={isDeletingReport}
                confirmButtonText="Delete"
                colorScheme="red"
                cancelButtonText="Cancel"
            >
                <Text color="gray.700">You cannot undo this action</Text>
            </ConfirmDialog>
            <ExpandableBanner>
                <ExpandableBannerHeader>
                    <Container>
                        <HStack justifyContent="space-between">
                            {/* TODO: get campaign info */}
                            <StrategyReportHeaderSection
                                strategyReportName={strategyReport?.name ?? ''}
                                isLoading={!strategyReport}
                                campaignStartDate={campaign?.startDate}
                                campaignEndDate={campaign?.endDate}
                            />
                            <Spacer />
                            <Menu>
                                <MenuButton
                                    color="gray.400"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    as={IconButton}
                                    isRound
                                    variant="ghost"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    icon={<EllipsisVerticalIcon />}
                                    size="sm"
                                />
                                <MenuList minH="48px" fontSize="md">
                                    <MenuItem
                                        onClick={() => {
                                            deleteStrategyReportOnOpen();
                                        }}
                                        icon={
                                            <Center>
                                                <Icon as={TrashIcon} boxSize="1.5rem" />
                                            </Center>
                                        }
                                    >
                                        Delete strategy report
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Container>
                </ExpandableBannerHeader>
                <ExpandableBannerBody autoOpen={false} defaultIsOpen={false}>
                    <Container>
                        <Stack spacing="2rem" align="left">
                            <Grid templateColumns="repeat(4, 1fr)" gap="1rem">
                                <GridItem>
                                    <FormLabel color="gray.700" fontSize="md">
                                        Countries
                                    </FormLabel>
                                    {isLoaded ? (
                                        <Wrap>
                                            {strategyReport?.selectedCountryCodes &&
                                                strategyReport.selectedCountryCodes.map(
                                                    (countryCode) => (
                                                        <Tag key={countryCode}>
                                                            <TagLabel>
                                                                {regionNames.of(
                                                                    countryCode.toUpperCase(),
                                                                )}
                                                            </TagLabel>
                                                        </Tag>
                                                    ),
                                                )}
                                        </Wrap>
                                    ) : (
                                        <SkeletonDetailText />
                                    )}
                                </GridItem>

                                <GridItem>
                                    <FormLabel color="#323233" fontSize="md">
                                        Age groups
                                    </FormLabel>
                                    {isLoaded ? (
                                        <Wrap>
                                            {sortAgeGroups(strategyReport?.selectedAgeGroups).map(
                                                (ageGroup) => (
                                                    <Tag key={ageGroup} colorScheme="green">
                                                        <TagLabel>
                                                            {formatAgeGroup(
                                                                ageGroup,
                                                                'Group',
                                                                true,
                                                            )}
                                                        </TagLabel>
                                                    </Tag>
                                                ),
                                            )}
                                        </Wrap>
                                    ) : (
                                        <SkeletonDetailText />
                                    )}
                                </GridItem>

                                <GridItem>
                                    <FormLabel color="#323233" fontSize="md">
                                        Genders
                                    </FormLabel>
                                    {isLoaded ? (
                                        <Wrap>
                                            {strategyReport?.selectedGenders?.map((gender) => (
                                                <Tag key={gender} colorScheme="green">
                                                    <TagLabel>
                                                        {formatStringToCapitalized(gender)}
                                                    </TagLabel>
                                                </Tag>
                                            ))}
                                        </Wrap>
                                    ) : (
                                        <SkeletonDetailText />
                                    )}
                                </GridItem>

                                <GridItem>
                                    <FormLabel color="#323233" fontSize="md">
                                        Ad formats
                                    </FormLabel>
                                    {isLoaded ? (
                                        <Flex alignItems="center">
                                            <ConfigureAdFormatsButton />
                                        </Flex>
                                    ) : (
                                        <SkeletonDetailText />
                                    )}
                                </GridItem>
                            </Grid>
                        </Stack>
                    </Container>
                </ExpandableBannerBody>
            </ExpandableBanner>
        </>
    );
};
