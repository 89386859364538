import { GetScenarioResponseDto, OptimisationStrategy } from '@api-clients/media-plan';
import { useToken } from '@chakra-ui/react';
import { getShortenedNumber, toAppropriateDecimalPlace } from '@shared/utils';
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export interface MediaMixChannelViewChartProps {
    scenarios: Array<GetScenarioResponseDto>;
    isStacked?: boolean;
    canUserSeeScenarioDetails?: boolean;
}

const getBudget = (scenario?: GetScenarioResponseDto) => {
    return (
        scenario?.channelMix
            ?.map((ch) => ch.budget ?? 0)
            .reduce((prev, current) => prev + current, 0) ?? 0
    );
};

export const MediaMixChannelViewChart: FC<MediaMixChannelViewChartProps> = ({
    scenarios,
    isStacked = true,
    canUserSeeScenarioDetails,
}) => {
    const { t } = useTranslation('mediaPlans');

    const chartColors = useToken('colors', [
        'green.500',
        'orange.500',
        'blue.500',
        'red.500',
        'purple.500',
        'limeGreen.500',
        'pink.500',
        'yellow.500',
        'teal.500',
        'cyan.500',
    ]);

    const [upgradeColor] = useToken('colors', ['gray.300']);

    const upgradeLabel = 'Upgrade to view budget allocation';

    const adChannels = [
        ...new Set(
            scenarios
                .flatMap((scenario) => scenario.channelMix?.map((channel) => channel.channelName))
                .filter((element): element is string => typeof element === 'string'),
        ),
    ].sort((a, b) => b.localeCompare(a));

    const chartData = adChannels
        .map((adChannel, index) => {
            const color = chartColors[index % chartColors.length];
            return {
                label: adChannel,
                backgroundColor: color,
                data:
                    scenarios
                        .filter(
                            (s) =>
                                canUserSeeScenarioDetails ||
                                s.optimisationStrategy === OptimisationStrategy.Base,
                        )
                        .flatMap((scenario) => {
                            return (
                                scenario.channelMix
                                    ?.filter((channel) => channel.channelName === adChannel)
                                    .map((channel) => ({
                                        x: scenario.scenarioName ?? 'bliud',
                                        y: (channel.mix ?? 0) * 100,
                                    })) ?? []
                            );
                        }) ?? [],
            };
        })
        .concat(
            canUserSeeScenarioDetails
                ? []
                : [
                      {
                          label: upgradeLabel,
                          backgroundColor: upgradeColor,
                          data: scenarios
                              .filter((s) => s.optimisationStrategy !== OptimisationStrategy.Base)
                              .map((s) => ({ x: s.scenarioName ?? '', y: 100 })),
                      },
                  ],
        );

    return (
        <Bar
            data={{
                labels: scenarios
                    .sort((a, b) => {
                        if (a.optimisationStrategy === OptimisationStrategy.Base || !b.scenarioName)
                            return -1;
                        if (b.optimisationStrategy === OptimisationStrategy.Base || !a.scenarioName)
                            return 1;
                        return a.scenarioName.localeCompare(b.scenarioName);
                    })
                    .map((scenario) => scenario.scenarioName),
                datasets: chartData,
            }}
            options={{
                plugins: {
                    legend: {
                        labels: {
                            filter: (item, data) => {
                                return item.text !== upgradeLabel;
                            },
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) =>
                                tooltipItems.map((tooltipItem) => tooltipItem.dataset.label ?? ''),
                            label: (tooltipItem) => {
                                const scenario = scenarios.find(
                                    (s) => s.scenarioName === tooltipItem.label,
                                );
                                const percentage = tooltipItem.parsed.y;
                                return tooltipItem.dataset.label !== upgradeLabel
                                    ? [
                                          `Mix: ${toAppropriateDecimalPlace(
                                              tooltipItem.parsed.y,
                                          )}%`,
                                          `Budget: $${getShortenedNumber(
                                              getBudget(scenario) * (percentage / 100),
                                          )}`,
                                      ]
                                    : [];
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        title: {
                            text: `${t('mediaPlanning.mediaMixChart.axisLabels.y')}`,
                            display: true,
                        },
                        ticks: {
                            callback: (value) => {
                                return `${value}%`;
                            },
                        },
                        max: 100,
                        stacked: isStacked,
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                        stacked: isStacked,
                    },
                },
            }}
        />
    );
};
