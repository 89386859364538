import { AppRoutes } from '@routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './configs/chartjsDefaults';
import './configs/i18n/i18n';

export const App = (): JSX.Element => {
    const router = createBrowserRouter(AppRoutes);
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
};
