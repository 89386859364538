import {
    GetAttentionDataForSaasRequest,
    GetAttentionDataResponseDto,
    GetAttentionDataSaasRequestDto,
} from '@api-clients/attention-data';
import { AgeGroup, Gender, ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { CountryCodeIso2 } from '@api-clients/shared';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { Box, Button, Container, Flex, useBoolean, useConst, VStack } from '@chakra-ui/react';
import { AttentionBenchmarkFilter, AttentionMetricSection } from '@components/organisms';
import { useGetAttentionData } from '@hooks/attentionData';
import { useSubscriptionHelper } from '@hooks/subscriptions';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AttentionBenchmarksPage: FC = () => {
    const { getAttentionData } = useGetAttentionData();
    const { t } = useTranslation('benchmark');

    const { canAccessBenchmarks } = useSubscriptionHelper();

    const isPaidPlan = canAccessBenchmarks();

    const [isUsingGlobal, setIsUsingGlobal] = useState<boolean>(true);
    const [adFormats, setAdFormats] = useState<ScenarioAdFormatResponseDto[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<CountryCodeIso2[]>([]);
    const [selectedAgeGroups, setSelectedAgeGroups] = useState<AgeGroup[]>(Object.values(AgeGroup));
    const [selectedGenders, setSelectedGenders] = useState<Gender[]>(Object.values(Gender));
    const [isMrcCompliantOnly, setIsMrcCompliantOnly] = useState<boolean>(false);

    const [requestedFilters, setRequestedFilters] = useState<GetAttentionDataSaasRequestDto>();

    const benchmarksText = useConst(() => t('attentionBenchmarks', { returnObjects: true }));

    const [attentionData, setAttentionData] = useState<GetAttentionDataResponseDto[]>();
    const [attentionDataMirror, setAttentionDataMirror] = useState<GetAttentionDataResponseDto[]>();

    const [isLoading, setIsLoading] = useBoolean();

    const handleFilterFormat = (channelData: ScenarioAdFormatResponseDto[]) => {
        setAdFormats(channelData);
        const data = attentionDataMirror?.filter((ad) => {
            const adFormatCode = ad.adFormat?.code;
            return channelData.some((cd) => cd.adFormatCode === adFormatCode && cd.isChecked);
        });
        setAttentionData(data);
    };

    const handleFetch = () => {
        const body: GetAttentionDataSaasRequestDto = {
            countries: isUsingGlobal ? [] : selectedCountries,
            ageGroups: selectedAgeGroups,
            genders: selectedGenders,
            isOnlyMrcCompliant: isMrcCompliantOnly,
        };

        setRequestedFilters(body);

        setIsLoading.on();
        const request: GetAttentionDataForSaasRequest = { getAttentionDataSaasRequestDto: body };
        getAttentionData(request)
            .then((data) => {
                setAttentionDataMirror(data);
                setAttentionData(data);
            })
            .finally(() => {
                setIsLoading.off();
                if (adFormats.length > 0) handleFilterFormat(adFormats);
            });
    };

    useEffect(() => {
        if (attentionDataMirror) {
            const mappedAdFormats: ScenarioAdFormatResponseDto[] | undefined =
                attentionDataMirror?.map((item) => {
                    const mappedItem: ScenarioAdFormatResponseDto = {
                        adFormatCode: item.adFormat?.code || null,
                        adFormatName: item.adFormat?.name || null,
                        isChecked: true,
                    };
                    return mappedItem;
                });
            if (mappedAdFormats !== adFormats && mappedAdFormats) {
                setAdFormats(mappedAdFormats);
                handleFilterFormat(mappedAdFormats);
            }
        }
    }, [attentionDataMirror]);

    useEffect(() => {
        handleFetch();
    }, []);

    const isApplyButtonDisabled = useMemo(() => {
        return selectedAgeGroups.length <= 0 || selectedGenders.length <= 0;
    }, [selectedAgeGroups, selectedGenders]);

    return (
        <VStack gap="1rem" align="right">
            {/* hacky way to add green background from edge to edge */}
            <Box
                backgroundColor="green.50"
                padding="2rem"
                marginTop="-2rem"
                marginX="-2rem"
                width="99.5vw"
            >
                <Container>
                    <VStack spacing="1rem" align="right">
                        <Box>
                            <Flex direction="column" gap="1rem">
                                <ContainerTitle
                                    headingText={benchmarksText.benchMarks.heading}
                                    size="xl"
                                />
                                <AttentionBenchmarkFilter
                                    selectedCountries={selectedCountries}
                                    selectedAgeGroups={selectedAgeGroups}
                                    selectedGenders={selectedGenders}
                                    isUsingGlobal={isUsingGlobal}
                                    isMrcCompliantOnly={isMrcCompliantOnly}
                                    setSelectedCountries={setSelectedCountries}
                                    setSelectedAgeGroups={setSelectedAgeGroups}
                                    setSelectedGenders={setSelectedGenders}
                                    setIsUsingGlobal={setIsUsingGlobal}
                                    setIsMrcCompliantOnly={setIsMrcCompliantOnly}
                                />
                            </Flex>
                        </Box>
                        <Box>
                            <Button
                                colorScheme="orange"
                                isLoading={isLoading}
                                loadingText="Fetching data"
                                onClick={handleFetch}
                                isDisabled={isApplyButtonDisabled}
                            >
                                Apply
                            </Button>
                        </Box>
                    </VStack>
                </Container>
            </Box>
            <Box>
                <AttentionMetricSection
                    attentionData={attentionData}
                    channels={attentionDataMirror?.map((a) => a.adChannel!) ?? []}
                    handleFormatConfirm={handleFilterFormat}
                    adFormats={adFormats}
                    isFetching={isLoading}
                    isPaidPlan={isPaidPlan}
                    selectedCountries={requestedFilters?.countries ?? []}
                    selectedAgeGroups={requestedFilters?.ageGroups ?? []}
                    selectedGenders={requestedFilters?.genders ?? []}
                    isUsingGlobal={requestedFilters?.countries?.length === 0}
                    isMrcCompliantOnly={isMrcCompliantOnly}
                />
            </Box>
        </VStack>
    );
};
