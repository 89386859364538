/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BudgetConstraintType = {
    AdjustmentToleranceLevel: 'adjustment_tolerance_level',
    MinSpendPerChannel: 'min_spend_per_channel'
} as const;
export type BudgetConstraintType = typeof BudgetConstraintType[keyof typeof BudgetConstraintType];


export function BudgetConstraintTypeFromJSON(json: any): BudgetConstraintType {
    return BudgetConstraintTypeFromJSONTyped(json, false);
}

export function BudgetConstraintTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetConstraintType {
    return json as BudgetConstraintType;
}

export function BudgetConstraintTypeToJSON(value?: BudgetConstraintType | null): any {
    return value as any;
}

