/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface FormatResponseDto
 */
export interface FormatResponseDto {
    /**
     *
     * @type {string}
     * @memberof FormatResponseDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatResponseDto
     */
    code?: string | null;
}

/**
 * Check if a given object implements the FormatResponseDto interface.
 */
export function instanceOfFormatResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatResponseDtoFromJSON(json: any): FormatResponseDto {
    return FormatResponseDtoFromJSONTyped(json, false);
}

export function FormatResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        code: !exists(json, 'code') ? undefined : json['code'],
    };
}

export function FormatResponseDtoToJSON(value?: FormatResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        code: value.code,
    };
}
