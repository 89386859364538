import {
    InviteExistingUserDto,
    InviteNewUserDto,
    InviteUserRequest,
    OrganisationApi,
    OrganisationResponseDto,
} from '@api-clients/account-manager';
import * as yup from 'yup';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';
import { toCapitalised, useHelper } from '@shared/utils';

interface InviteUserHook {
    validationSchema: yup.ObjectSchema<any>;
    inviteNewUser: (
        organisationId: string,
        inviteUserToOrganisationRequestDto: InviteNewUserDto,
    ) => Promise<OrganisationResponseDto>;
    inviteExistingUser: (
        organisationId: string,
        inviteUserToOrganisationRequestDto: InviteExistingUserDto,
    ) => Promise<OrganisationResponseDto>;
}

type useInviteUserProps = () => InviteUserHook;

export const useInviteUser: useInviteUserProps = () => {
    const { t } = useTranslation();
    const { getAccountManagerConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.user.firstName.isRequired')),
        lastName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.user.lastName.isRequired')),
        emailAddress: yup
            .string()
            .email(t('messages.forms.validation.email.isInvalid'))
            .required(t('messages.forms.validation.email.isRequired')),
    });

    async function inviteNewUser(
        organisationId: string,
        inviteUserDto: InviteNewUserDto,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: InviteUserRequest = {
                organisationId,
                inviteUserToOrganisationRequestDto: {
                    newUser: toCapitalised(inviteUserDto, ['firstName', 'lastName']),
                },
            };
            const api = new OrganisationApi(configuration);
            return await api.inviteUser(request);
        } catch (e: any) {
            throw new Error(`${await mapDisplayedErrorMessage(e)}`);
        }
    }

    async function inviteExistingUser(
        organisationId: string,
        inviteUserDto: InviteExistingUserDto,
    ): Promise<OrganisationResponseDto> {
        const configuration = await getAccountManagerConfig();
        const request: InviteUserRequest = {
            organisationId,
            inviteUserToOrganisationRequestDto: {
                existingUser: inviteUserDto,
            },
        };

        try {
            const api = new OrganisationApi(configuration);
            return await api.inviteUser(request);
        } catch (e: any) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        validationSchema,
        inviteNewUser,
        inviteExistingUser,
    };
};
