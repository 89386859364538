import {
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ExpandingIconProps extends IconProps {
    isOpen: boolean;
    direction?: 'up' | 'down' | 'left' | 'right';
    rotation?: '-180' | '90';
}

// arrow icon that animates a 180 degree rotation when isOpen is true
export const ExpandingIcon: FC<ExpandingIconProps> = ({
    isOpen,
    color,
    direction = 'down',
    rotation = '180',
    ...iconProps
}) => {
    let icon;

    switch (direction) {
        case 'left':
            icon = ChevronLeftIcon;
            break;
        case 'right':
            icon = ChevronRightIcon;
            break;
        case 'up':
            icon = ChevronUpIcon;
            break;
        case 'down':
        default:
            icon = ChevronDownIcon;
            break;
    }

    return (
        <Icon
            __css={{
                transform: isOpen ? `rotate(${rotation}deg)` : undefined,
                transition: 'transform 0.2s',
                transformOrigin: 'center',
            }}
            color={color}
            as={icon}
            {...iconProps}
        />
    );
};
