/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface RemoveUserFromOrganisationRequestDto
 */
export interface RemoveUserFromOrganisationRequestDto {
    /**
     *
     * @type {string}
     * @memberof RemoveUserFromOrganisationRequestDto
     */
    userId?: string;
}

/**
 * Check if a given object implements the RemoveUserFromOrganisationRequestDto interface.
 */
export function instanceOfRemoveUserFromOrganisationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveUserFromOrganisationRequestDtoFromJSON(
    json: any,
): RemoveUserFromOrganisationRequestDto {
    return RemoveUserFromOrganisationRequestDtoFromJSONTyped(json, false);
}

export function RemoveUserFromOrganisationRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RemoveUserFromOrganisationRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function RemoveUserFromOrganisationRequestDtoToJSON(
    value?: RemoveUserFromOrganisationRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_id: value.userId,
    };
}
