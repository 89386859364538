/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdjustmentToleranceLevel } from './AdjustmentToleranceLevel';
import {
    AdjustmentToleranceLevelFromJSON,
    AdjustmentToleranceLevelFromJSONTyped,
    AdjustmentToleranceLevelToJSON,
} from './AdjustmentToleranceLevel';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { BudgetConstraintType } from './BudgetConstraintType';
import {
    BudgetConstraintTypeFromJSON,
    BudgetConstraintTypeFromJSONTyped,
    BudgetConstraintTypeToJSON,
} from './BudgetConstraintType';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';
import type { ScenarioAdFormatRequestDto } from './ScenarioAdFormatRequestDto';
import {
    ScenarioAdFormatRequestDtoFromJSON,
    ScenarioAdFormatRequestDtoFromJSONTyped,
    ScenarioAdFormatRequestDtoToJSON,
} from './ScenarioAdFormatRequestDto';
import type { ScenarioMinSpendPerChannelRequestDto } from './ScenarioMinSpendPerChannelRequestDto';
import {
    ScenarioMinSpendPerChannelRequestDtoFromJSON,
    ScenarioMinSpendPerChannelRequestDtoFromJSONTyped,
    ScenarioMinSpendPerChannelRequestDtoToJSON,
} from './ScenarioMinSpendPerChannelRequestDto';

/**
 *
 * @export
 * @interface UpdateConstraintsRequestDto
 */
export interface UpdateConstraintsRequestDto {
    /**
     *
     * @type {Array<OptimisationStrategy>}
     * @memberof UpdateConstraintsRequestDto
     */
    optimisationStrategies?: Array<OptimisationStrategy> | null;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof UpdateConstraintsRequestDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {number}
     * @memberof UpdateConstraintsRequestDto
     */
    budget?: number | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateConstraintsRequestDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateConstraintsRequestDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {AdjustmentToleranceLevel}
     * @memberof UpdateConstraintsRequestDto
     */
    adjustmentToleranceLevel?: AdjustmentToleranceLevel;
    /**
     *
     * @type {BudgetConstraintType}
     * @memberof UpdateConstraintsRequestDto
     */
    budgetConstraintType?: BudgetConstraintType;
    /**
     *
     * @type {Array<ScenarioAdFormatRequestDto>}
     * @memberof UpdateConstraintsRequestDto
     */
    adFormats?: Array<ScenarioAdFormatRequestDto> | null;
    /**
     *
     * @type {Array<ScenarioMinSpendPerChannelRequestDto>}
     * @memberof UpdateConstraintsRequestDto
     */
    minSpendPerChannels?: Array<ScenarioMinSpendPerChannelRequestDto> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof UpdateConstraintsRequestDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof UpdateConstraintsRequestDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdateConstraintsRequestDto
     */
    isUsingGlobalCountry?: boolean | null;
}

/**
 * Check if a given object implements the UpdateConstraintsRequestDto interface.
 */
export function instanceOfUpdateConstraintsRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateConstraintsRequestDtoFromJSON(json: any): UpdateConstraintsRequestDto {
    return UpdateConstraintsRequestDtoFromJSONTyped(json, false);
}

export function UpdateConstraintsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateConstraintsRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        optimisationStrategies: !exists(json, 'optimisation_strategies')
            ? undefined
            : json['optimisation_strategies'] === null
            ? null
            : (json['optimisation_strategies'] as Array<any>).map(OptimisationStrategyFromJSON),
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        adjustmentToleranceLevel: !exists(json, 'adjustment_tolerance_level')
            ? undefined
            : AdjustmentToleranceLevelFromJSON(json['adjustment_tolerance_level']),
        budgetConstraintType: !exists(json, 'budget_constraint_type')
            ? undefined
            : BudgetConstraintTypeFromJSON(json['budget_constraint_type']),
        adFormats: !exists(json, 'ad_formats')
            ? undefined
            : json['ad_formats'] === null
            ? null
            : (json['ad_formats'] as Array<any>).map(ScenarioAdFormatRequestDtoFromJSON),
        minSpendPerChannels: !exists(json, 'min_spend_per_channels')
            ? undefined
            : json['min_spend_per_channels'] === null
            ? null
            : (json['min_spend_per_channels'] as Array<any>).map(
                  ScenarioMinSpendPerChannelRequestDtoFromJSON,
              ),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        isUsingGlobalCountry: !exists(json, 'is_using_global_country')
            ? undefined
            : json['is_using_global_country'],
    };
}

export function UpdateConstraintsRequestDtoToJSON(value?: UpdateConstraintsRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        optimisation_strategies:
            value.optimisationStrategies === undefined
                ? undefined
                : value.optimisationStrategies === null
                ? null
                : (value.optimisationStrategies as Array<any>).map(OptimisationStrategyToJSON),
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        budget: value.budget,
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        adjustment_tolerance_level: AdjustmentToleranceLevelToJSON(value.adjustmentToleranceLevel),
        budget_constraint_type: BudgetConstraintTypeToJSON(value.budgetConstraintType),
        ad_formats:
            value.adFormats === undefined
                ? undefined
                : value.adFormats === null
                ? null
                : (value.adFormats as Array<any>).map(ScenarioAdFormatRequestDtoToJSON),
        min_spend_per_channels:
            value.minSpendPerChannels === undefined
                ? undefined
                : value.minSpendPerChannels === null
                ? null
                : (value.minSpendPerChannels as Array<any>).map(
                      ScenarioMinSpendPerChannelRequestDtoToJSON,
                  ),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        is_using_global_country: value.isUsingGlobalCountry,
    };
}
