/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    OrganisationBasicInfoResponseDto,
    ProblemDetails,
    SelfSignUpOrganisationDto,
    UpdateUserRequestDto,
    UserBasicInfoResponseDto,
} from '../models';
import {
    OrganisationBasicInfoResponseDtoFromJSON,
    OrganisationBasicInfoResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SelfSignUpOrganisationDtoFromJSON,
    SelfSignUpOrganisationDtoToJSON,
    UpdateUserRequestDtoFromJSON,
    UpdateUserRequestDtoToJSON,
    UserBasicInfoResponseDtoFromJSON,
    UserBasicInfoResponseDtoToJSON,
} from '../models';

export interface CreateSelfSignUpOrganisationRequest {
    selfSignUpOrganisationDto?: SelfSignUpOrganisationDto;
}

export interface FinaliseUserDataFromSelfSignUpRequest {
    userId?: string;
    updateUserRequestDto?: UpdateUserRequestDto;
}

/**
 * SignUpApi - interface
 *
 * @export
 * @interface SignUpApiInterface
 */
export interface SignUpApiInterface {
    /**
     *
     * @param {SelfSignUpOrganisationDto} [selfSignUpOrganisationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignUpApiInterface
     */
    createSelfSignUpOrganisationRaw(
        requestParameters: CreateSelfSignUpOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationBasicInfoResponseDto>>;

    /**
     */
    createSelfSignUpOrganisation(
        requestParameters: CreateSelfSignUpOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationBasicInfoResponseDto>;

    /**
     *
     * @param {string} [userId]
     * @param {UpdateUserRequestDto} [updateUserRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignUpApiInterface
     */
    finaliseUserDataFromSelfSignUpRaw(
        requestParameters: FinaliseUserDataFromSelfSignUpRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserBasicInfoResponseDto>>;

    /**
     */
    finaliseUserDataFromSelfSignUp(
        requestParameters: FinaliseUserDataFromSelfSignUpRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserBasicInfoResponseDto>;
}

/**
 *
 */
export class SignUpApi extends runtime.BaseAPI implements SignUpApiInterface {
    /**
     */
    async createSelfSignUpOrganisationRaw(
        requestParameters: CreateSelfSignUpOrganisationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OrganisationBasicInfoResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/sign-up`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SelfSignUpOrganisationDtoToJSON(requestParameters.selfSignUpOrganisationDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createSelfSignUpOrganisation(
        requestParameters: CreateSelfSignUpOrganisationRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OrganisationBasicInfoResponseDto> {
        const response = await this.createSelfSignUpOrganisationRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async finaliseUserDataFromSelfSignUpRaw(
        requestParameters: FinaliseUserDataFromSelfSignUpRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserBasicInfoResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/sign-up`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateUserRequestDtoToJSON(requestParameters.updateUserRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserBasicInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async finaliseUserDataFromSelfSignUp(
        requestParameters: FinaliseUserDataFromSelfSignUpRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserBasicInfoResponseDto> {
        const response = await this.finaliseUserDataFromSelfSignUpRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
