/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface UserBasicInfoResponseDto
 */
export interface UserBasicInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof UserBasicInfoResponseDto
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserBasicInfoResponseDto
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserBasicInfoResponseDto
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserBasicInfoResponseDto
     */
    emailAddress?: string | null;
}

/**
 * Check if a given object implements the UserBasicInfoResponseDto interface.
 */
export function instanceOfUserBasicInfoResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserBasicInfoResponseDtoFromJSON(json: any): UserBasicInfoResponseDto {
    return UserBasicInfoResponseDtoFromJSONTyped(json, false);
}

export function UserBasicInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UserBasicInfoResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
    };
}

export function UserBasicInfoResponseDtoToJSON(value?: UserBasicInfoResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        first_name: value.firstName,
        last_name: value.lastName,
        email_address: value.emailAddress,
    };
}
