import { useTranslation } from 'react-i18next';
import { useCustomToast } from './useCustomToast';

export const useRemoveClientToast = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successRemoveClientToast(organisationName?: string | null) {
        const title = t('messages.toast.remove.client.success.title');
        const description = `${t('messages.toast.remove.client.success.description')} ${
            organisationName ?? ''
        }`;

        successToast(title, description);
    }

    function errorRemoveClientToast() {
        const title = t('messages.toast.remove.client.error.title');
        const description = t('messages.toast.remove.client.error.description');

        errorToast(title, description);
    }

    return {
        successRemoveClientToast,
        errorRemoveClientToast,
    };
};
