import { FC } from 'react';
import { AmplifiedColorScheme } from '@shared/cores';
import {
    Flex,
    Heading,
    Link as ChakraLink,
    VStack,
    Text,
    Box,
    UnorderedList,
    ListItem,
    Button,
    Icon,
} from '@chakra-ui/react';
import { routing } from '@configs';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { externalUrls } from '@configs/externalUrls';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

type TableEditingSectionBannerProps = {
    colorScheme?: AmplifiedColorScheme;
    onClick?: () => void;
};
const instructions = [
    {
        id: 0,
        text: 'Choose the channels and formats included in your media plan. For a channel with the budget > 0, if a format has the initial budget of 0 and is ticked, that format will still be taken into optimisation.',
    },
    {
        id: 1,
        text: 'Your current budget, CPM, frequency and targeted audience will be taken into consideration during optimisation.',
    },
];
export const TableEditingSectionBanner: FC<TableEditingSectionBannerProps> = ({
    colorScheme = 'green',
    onClick = () => {},
}) => {
    const navigate = useNavigate();
    return (
        <Box paddingX="2.5rem" paddingY="1.5rem" bgColor={`${colorScheme}.50`}>
            <VStack align="left" display="flex" gap="1rem">
                <Heading color="gray.800" size="sm">
                    Build your media plan mix by editing the table below
                </Heading>

                <UnorderedList>
                    {instructions.map((ins) => (
                        <ListItem
                            key={ins.id}
                            color="gray.600"
                            fontSize="0.875rem"
                            lineHeight="1.25rem"
                        >
                            {/** TODO: Could've used text-wrap: balance here, but Chakra has no support at the moment */}
                            {ins.text}
                        </ListItem>
                    ))}
                </UnorderedList>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text
                        color="gray.600"
                        fontSize="0.875rem"
                        fontWeight={400}
                        lineHeight="1.25rem"
                    >
                        <Text as="span" color="gray.700" fontWeight={700}>
                            💡 Tip
                        </Text>
                        : Take a look at{' '}
                        <ChakraLink
                            to={`/${routing.benchmarks.url}`}
                            as={ReactRouterLink}
                            color="green.500"
                            isExternal
                        >
                            attention benchmarks <ExternalLinkIcon />
                        </ChakraLink>{' '}
                        to gain insights into the attention performance of ad formats before
                        planning.
                    </Text>
                    <Button
                        variant="outline"
                        onClick={onClick}
                        display="flex"
                        alignItems="center"
                        gap="0.5rem"
                    >
                        <Icon as={ArrowDownTrayIcon} boxSize="1.2em" strokeWidth="2px" />
                        Import from file
                    </Button>
                </Flex>
            </VStack>
        </Box>
    );
};
