/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { OrganisationStatus } from './OrganisationStatus';
import {
    OrganisationStatusFromJSON,
    OrganisationStatusFromJSONTyped,
    OrganisationStatusToJSON,
} from './OrganisationStatus';
import type { UserBasicInfoResponseDto } from './UserBasicInfoResponseDto';
import {
    UserBasicInfoResponseDtoFromJSON,
    UserBasicInfoResponseDtoFromJSONTyped,
    UserBasicInfoResponseDtoToJSON,
} from './UserBasicInfoResponseDto';

/**
 *
 * @export
 * @interface OrganisationBasicInfoResponseDto
 */
export interface OrganisationBasicInfoResponseDto {
    /**
     *
     * @type {UserBasicInfoResponseDto}
     * @memberof OrganisationBasicInfoResponseDto
     */
    owner?: UserBasicInfoResponseDto;
    /**
     *
     * @type {Date}
     * @memberof OrganisationBasicInfoResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof OrganisationBasicInfoResponseDto
     */
    updatedAt?: Date;
    /**
     *
     * @type {string}
     * @memberof OrganisationBasicInfoResponseDto
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OrganisationBasicInfoResponseDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {CountryCodeIso2}
     * @memberof OrganisationBasicInfoResponseDto
     */
    region?: CountryCodeIso2;
    /**
     *
     * @type {CurrencyCode}
     * @memberof OrganisationBasicInfoResponseDto
     */
    currency?: CurrencyCode;
    /**
     *
     * @type {OrganisationStatus}
     * @memberof OrganisationBasicInfoResponseDto
     */
    status?: OrganisationStatus;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationBasicInfoResponseDto
     */
    hasApiAccess?: boolean;
}

/**
 * Check if a given object implements the OrganisationBasicInfoResponseDto interface.
 */
export function instanceOfOrganisationBasicInfoResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrganisationBasicInfoResponseDtoFromJSON(
    json: any,
): OrganisationBasicInfoResponseDto {
    return OrganisationBasicInfoResponseDtoFromJSONTyped(json, false);
}

export function OrganisationBasicInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationBasicInfoResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        owner: !exists(json, 'owner') ? undefined : UserBasicInfoResponseDtoFromJSON(json['owner']),
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        id: !exists(json, 'id') ? undefined : json['id'],
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        region: !exists(json, 'region') ? undefined : CountryCodeIso2FromJSON(json['region']),
        currency: !exists(json, 'currency') ? undefined : CurrencyCodeFromJSON(json['currency']),
        status: !exists(json, 'status') ? undefined : OrganisationStatusFromJSON(json['status']),
        hasApiAccess: !exists(json, 'has_api_access') ? undefined : json['has_api_access'],
    };
}

export function OrganisationBasicInfoResponseDtoToJSON(
    value?: OrganisationBasicInfoResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        owner: UserBasicInfoResponseDtoToJSON(value.owner),
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        id: value.id,
        organisation_name: value.organisationName,
        region: CountryCodeIso2ToJSON(value.region),
        currency: CurrencyCodeToJSON(value.currency),
        status: OrganisationStatusToJSON(value.status),
        has_api_access: value.hasApiAccess,
    };
}
