import {
    Badge,
    Button,
    Center,
    Flex,
    Heading,
    Icon,
    Indicator,
    Link,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { importTemplate } from '@configs';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { AmplifiedColorScheme } from '@shared/cores';
import { FC } from 'react';
import { useAppContextHelper } from '@hooks/_contexts';

export interface MediaPlanDetailsBannerProps {
    colorScheme?: AmplifiedColorScheme;
    firstName: string;
}

export const MediaPlanDetailsBanner: FC<MediaPlanDetailsBannerProps> = ({
    colorScheme = 'green',
    firstName,
}) => {
    const { fileUrl } = importTemplate;
    const {
        currentContextValues: { organisation, user },
    } = useAppContextHelper();

    return (
        <Flex paddingX="2.5rem" paddingY="1.5rem" bgColor={`${colorScheme}.50`}>
            <VStack align="left">
                <Heading color="gray.800" size="sm">
                    Hi {firstName}! 👋 Create a base media plan to get started!
                </Heading>
                <Text color="gray.600" fontSize="0.875rem" lineHeight="1.25rem">
                    Please enter your media plan details below.
                </Text>
            </VStack>
            <Spacer />
        </Flex>
    );
};
