import {BackButton} from '@apps/attention360/atoms';
import {Heading, Link, Stack, Text, VStack} from '@chakra-ui/react';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import moment from "moment";
import {Link as ReactLink, useNavigate} from "react-router-dom";
import {routing} from "@configs";

export interface GlobalCampaignHeaderSectionProps {
    campaign: GlobalCampaign;
    allowEdits: boolean;
}

export const GlobalCampaignHeaderSection: FC<GlobalCampaignHeaderSectionProps> = ({
                                                                                      campaign,
                                                                                      allowEdits
                                                                                  }) => {

    const navigate = useNavigate();
    const {t} = useTranslation('campaign');

    return (
        <Stack direction="row">
            <BackButton/>
            <VStack
                minW="8rem"
                overflowX="hidden"
                align="left"
                spacing={0}
                marginRight="1rem"
                minH="3.125rem"
            >
                <Heading textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                    {campaign.name}
                </Heading>
                <Text
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    color="gray.500"
                    fontSize="sm"
                >
                    {`${campaign.startDate} • `}
                    {`${campaign.endDate} • `}
                    {t('campaign.details.lastModifiedAt')}{' '}
                    {(campaign.updatedAt) ? moment(campaign.updatedAt).toDate().toLocaleString() : moment(campaign.createdAt).toDate().toLocaleString()}
                    {allowEdits && (
                        <Link ml={4} as={ReactLink}
                              to={`/${routing.campaign.root.path}/${campaign.id}/${routing.campaign.edit.path}`}
                              textDecoration="underline">
                            Edit
                        </Link>
                    )}
                </Text>
            </VStack>
        </Stack>
    );
};
