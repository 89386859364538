import { OrganisationResponseDto, UserResponseDto } from '@api-clients/account-manager';
import { FC } from 'react';
import { UserInformationViewMode } from './UserInformationViewMode';
import { UserInformationEditMode } from './UserInformationEditMode';
import { useUserFormContext } from './UserInfoFormContext';

export interface UserInfoProps {
    user: UserResponseDto;
    organisation: OrganisationResponseDto;
}

export const UserInformationSection: FC<UserInfoProps> = (props: UserInfoProps) => {
    const { isEditMode } = useUserFormContext();
    const { user, organisation } = props;

    return (
        <>
            {isEditMode ? (
                <UserInformationEditMode user={user} organisation={organisation} />
            ) : (
                <UserInformationViewMode user={user} />
            )}
        </>
    );
};
