import { FC, ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';
import { DetailSection } from '@components/molecules';

export interface ViewOnlyFormProps<TValuesObject = Record<string, any>> {
    label?: string;
    defaultValue?: string | null;
    data?: TValuesObject;
    inputOptions?: ReactNode;
    renderItem?: ReactNode;
}

export const ViewOnlyFormInput =
    <TValuesObject extends FieldValues>(): FC<ViewOnlyFormProps<TValuesObject>> =>
    ({ label, renderItem }) => {
        return <DetailSection label={label} renderItem={renderItem} />;
    };
