import {
    GetScenarioLevelResultComparisonResponseDto,
    OptimisationStrategy,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { StatisticCell } from '@components/atoms/statisticCell/StatisticCell';
import { Row, Column, DataTable } from '@components/molecules';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@chakra-ui/react';
import { KeyOptimisedMetricCell } from '@components/atoms/keyOptimisedMetrics/KeyOptimisedMetricCell';
import { getOptimisationObjective } from '@shared/cores/types';

export interface KeyOptimisedMetricsTableProps {
    scenario: GetScenarioLevelResultComparisonResponseDto;
}

interface DataRow {
    driver: string;
    statComparedWith: number;
    stat: number;
    colSpan?: number;
    toDecimalPlace?: number;
    isPercentage?: boolean;
    tooltip?: string;
}

export const KeyOptimisedMetricsTable: FC<KeyOptimisedMetricsTableProps> = ({ scenario }) => {
    const { t } = useTranslation('mediaPlans');
    const drivers = t('mediaPlanning.tables.keyOptimisedMetrics.drivers', { returnObjects: true });

    const getIsPercentage = (driver: string) => {
        return driver === drivers.shortTermLift || driver === drivers.longTermLift;
    };
    const isBaseScenario = scenario.optimisationStrategy === OptimisationStrategy.Base;

    const dataSource: Array<Row<DataRow>> = [
        {
            key: drivers.totalActiveAttention,
            driver: drivers.totalActiveAttention,
            statComparedWith: scenario?.activeAttentionVolumeSeconds?.comparedWithValue ?? 0,
            stat: scenario?.activeAttentionVolumeSeconds?.value ?? 0,
            colSpan: 2,
            toDecimalPlace: 0,
            tooltip: 'The total amount of active attention throughout the entire campaign',
        },
        {
            key: drivers.reach,
            driver: drivers.reach,
            statComparedWith:
                scenario?.channelLevelResults?.reduce(
                    (prev, channel) => (channel.targetAudience?.comparedWithValue ?? 0) + prev,
                    0,
                ) ?? 0,
            stat:
                scenario?.channelLevelResults?.reduce(
                    (prev, channel) => (channel.targetAudience?.value ?? 0) + prev,
                    0,
                ) ?? 0,
            toDecimalPlace: 0,
        },
        {
            key: drivers.impressions,
            driver: drivers.impressions,
            statComparedWith: scenario?.impressions?.comparedWithValue ?? 0,
            stat: scenario?.impressions?.value ?? 0,
            toDecimalPlace: 0,
        },
        {
            key: drivers.aSecPerReach,
            driver: drivers.aSecPerReach,
            statComparedWith: scenario?.activeAttentionSecondsPerReach?.comparedWithValue ?? 0,
            stat: scenario?.activeAttentionSecondsPerReach?.value ?? 0,
        },
        {
            key: drivers.aSecPerImpression,
            driver: drivers.aSecPerImpression,
            statComparedWith: scenario?.activeAttentionSecondsPerImpression?.comparedWithValue ?? 0,
            stat: scenario?.activeAttentionSecondsPerImpression?.value ?? 0,
        },
        {
            key: drivers.shortTermLift,
            driver: drivers.shortTermLift,
            statComparedWith: scenario?.brandUplift?.comparedWithValue ?? 0,
            stat: scenario?.brandUplift?.value ?? 0,
            colSpan: 2,
            isPercentage: true,
            tooltip:
                'The increase in the likelihood of a customer considering your brand at a purchase occasion as a result of this campaign',
        },
        // Commented out as LTL is broken
        // {
        //     key: drivers.longTermLift,
        //     driver: drivers.longTermLift,
        //     statComparedWith: scenario?.longTermLift?.comparedWithValue ?? 0,
        //     stat: scenario?.longTermLift?.value ?? 0,

        // },
    ];

    let columns: Array<Column<DataRow>> = [
        {
            title: 'Drivers',
            dataIndex: 'driver',
            key: 'driver',
            onCell: () => ({
                fontWeight: 'semibold',
                whiteSpace: 'break-spaces',
            }),
        },
        {
            title: scenario.scenarioName ?? '',
            key: 'stat',
            dataIndex: 'stat',
            isNumeric: true,
            render: (row) => {
                const isPercentage = getIsPercentage(row.driver);
                return (
                    <StatisticCell
                        isPercentage={isPercentage}
                        value={isPercentage ? row.stat * 100 : row.stat}
                        comparedWithValue={
                            isPercentage ? row.statComparedWith * 100 : row.statComparedWith
                        }
                    />
                );
            },
        },
    ];

    if ((scenario.optimisationStrategy as OptimisationStrategy) !== OptimisationStrategy.Base) {
        columns = columns.concat({
            title: scenario.comparedWithScenarioName ?? '',
            key: 'statComparedWith',
            dataIndex: 'statComparedWith',
            isNumeric: true,
            render: (row) => {
                const isPercentage = getIsPercentage(row.driver);
                return (
                    <StatisticCell
                        isPercentage={isPercentage}
                        value={isPercentage ? row.statComparedWith * 100 : row.statComparedWith}
                    />
                );
            },
        });
    }

    // return <DataTable dataSource={dataSource} columns={columns} />;
    return (
        <Grid templateColumns="repeat(2, 1fr)" gap="1rem">
            {dataSource.map((data) => (
                <KeyOptimisedMetricCell
                    key={data.key}
                    value={data.isPercentage ? 100 * data.stat : data.stat}
                    comparedWithValue={
                        data.isPercentage ? 100 * data.statComparedWith : data.statComparedWith
                    }
                    label={scenario.scenarioName ?? ''}
                    title={data.driver}
                    colSpan={data.colSpan ?? 1}
                    toDecimalPlace={data.toDecimalPlace ?? 2}
                    isPercentage={data.isPercentage}
                    isBaseScenario={isBaseScenario}
                    tooltip={data.tooltip}
                />
            ))}
        </Grid>
    );
};
