/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type { ChannelLevelCurvesResponseDto, ProblemDetails } from '../models';
import {
    ChannelLevelCurvesResponseDtoFromJSON,
    ChannelLevelCurvesResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest {
    scenarioShareLinkId: string;
}

/**
 * PublicReachCurveApi - interface
 *
 * @export
 * @interface PublicReachCurveApiInterface
 */
export interface PublicReachCurveApiInterface {
    /**
     *
     * @param {string} scenarioShareLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicReachCurveApiInterface
     */
    listAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRaw(
        requestParameters: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>>;

    /**
     */
    listAllReachCurvesRelatingToASharedScenarioBySharedLinkId(
        requestParameters: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>>;
}

/**
 *
 */
export class PublicReachCurveApi extends runtime.BaseAPI implements PublicReachCurveApiInterface {
    /**
     */
    async listAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRaw(
        requestParameters: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>> {
        if (
            requestParameters.scenarioShareLinkId === null ||
            requestParameters.scenarioShareLinkId === undefined
        ) {
            throw new runtime.RequiredError(
                'scenarioShareLinkId',
                'Required parameter requestParameters.scenarioShareLinkId was null or undefined when calling listAllReachCurvesRelatingToASharedScenarioBySharedLinkId.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/share/reach-curves/{scenarioShareLinkId}`.replace(
                    `{${'scenarioShareLinkId'}}`,
                    encodeURIComponent(String(requestParameters.scenarioShareLinkId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelLevelCurvesResponseDtoFromJSON),
        );
    }

    /**
     */
    async listAllReachCurvesRelatingToASharedScenarioBySharedLinkId(
        requestParameters: ListAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>> {
        const response = await this.listAllReachCurvesRelatingToASharedScenarioBySharedLinkIdRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
