import {
    ScenarioApi,
    DeleteScenarioUserAccessRequest,
    ShareUserAccessDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';

import { useHelper } from '@shared/utils';

interface DeleteUserScenarioAccessHook {
    deleteUserScenarioAccess: (
        userId: string,
        campaignId: string,
        scenarioId: string,
    ) => Promise<void>;
}

type useDeleteUserScenarioAccessProps = () => DeleteUserScenarioAccessHook;

export const useDeleteUserScenarioAccess: useDeleteUserScenarioAccessProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteUserScenarioAccess(
        scenarioId: string,
        userId: string,
        campaignId: string,
    ) {
        try {
            const config = await getMediaPlanConfig();
            const request: DeleteScenarioUserAccessRequest = {
                scenarioId,
                userId,
                campaignId,
            };
            const api = new ScenarioApi(config);
            await api.deleteScenarioUserAccess(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        deleteUserScenarioAccess,
    };
};
