import { CreateAdClientRequestDto } from '@api-clients/media-plan';
import { Input, VStack } from '@chakra-ui/react';
import { ErrorAlert, InputWrapper } from '@components/atoms';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateAdClient } from '@hooks/adClients/useCreateAdClient';
import { useAddNewClientToast } from '@hooks/toast/useAddNewClientToast';
import { useAppContextHelper } from '@hooks/_contexts';
import { useHelper } from '@shared/utils';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface AddNewClientProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccessfulCreate: () => void;
}

export const AddNewClient: FC<AddNewClientProps> = ({ isOpen, onClose, onSuccessfulCreate }) => {
    const { t } = useTranslation();
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();

    const { createAdClient } = useCreateAdClient();

    const { displayUniqueNameInMessage, mapOrganisationTranslation, getDisplayedErrorMessage } =
        useHelper();

    const { successAddNewClientToast } = useAddNewClientToast();

    // Manage form status
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid, isSubmitting, touchedFields },
    } = useForm<CreateAdClientRequestDto>({
        resolver: yupResolver(
            yup.object().shape({
                adClientName: yup
                    .string()
                    .trim()
                    .required(t('messages.forms.validation.adClient.clientName.isRequired')),
                primaryContactName: yup
                    .string()
                    .trim()
                    .required(t('messages.forms.validation.adClient.primaryContact.isRequired')),
            }),
        ),
        mode: 'onChange',
    });

    const [errorMessage, setErrorMessage] = useState<string>();

    const handleClose = () => {
        onClose();
        reset();
        setErrorMessage(undefined);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onFormSave = async (data: CreateAdClientRequestDto) => {
        try {
            setIsLoading(true);
            await createAdClient(data);

            onSuccessfulCreate();
            handleClose();
            successAddNewClientToast(data.adClientName ?? '', organisation?.organisationName ?? '');
        } catch (e: any) {
            const msg = await getDisplayedErrorMessage(e);
            setErrorMessage(msg);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ConfirmDialog
            headerText={t('labels.dialogs.addNewClient.header')}
            confirmButtonText={t('labels.dialogs.addNewClient.confirmButtonText')}
            cancelButtonText={t('labels.dialogs.addNewClient.cancelButtonText')}
            isOpen={isOpen}
            onClose={(isConfirmation) => {
                if (isConfirmation) {
                    handleSubmit(onFormSave)();
                } else {
                    handleClose();
                }
            }}
            isLoading={isLoading}
            isConfirmDisabled={!isValid}
        >
            <form onSubmit={handleSubmit(onFormSave)}>
                <VStack align="left">
                    {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
                    <InputWrapper
                        isRequired
                        formLabel={t('labels.forms.addNewClient.clientName.field')}
                        formErrorText={errors.adClientName?.message}
                        isInvalid={touchedFields.adClientName && errors.adClientName !== undefined}
                    >
                        <Input {...register('adClientName')} />
                    </InputWrapper>
                    <InputWrapper
                        isRequired
                        formLabel={t('labels.forms.addNewClient.primaryContactName.field')}
                        formErrorText={errors.primaryContactName?.message}
                        isInvalid={
                            touchedFields.primaryContactName &&
                            errors.primaryContactName !== undefined
                        }
                    >
                        <Input {...register('primaryContactName')} />
                    </InputWrapper>
                </VStack>
            </form>
        </ConfirmDialog>
    );
};
