import {
    AdjustmentToleranceLevel,
    AgeGroup,
    BudgetConstraintType,
    CreateOptimisedScenariosRequestDto,
    Gender,
    GetCampaignByIdRequest,
    GetCampaignResponseDto,
    OptimisationStrategy,
    ScenarioAdFormatResponseDto,
    ScenarioMinSpendPerChannelRequestDto,
    UpdateCampaignRequestDto,
    UpdateConstraintsRequestDto,
} from '@api-clients/media-plan';
import { CountryCodeIso2 } from '@api-clients/shared';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import {
    Badge,
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Icon,
    Input,
    InputGroup,
    InputRightAddon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Skeleton,
    Spacer,
    Stack,
    Tag,
    TagLeftIcon,
    useBoolean,
    useDisclosure,
} from '@chakra-ui/react';
import { DatePicker, ToleranceLevelSlider } from '@components/atoms';
import { AdFormatsConstraintModal, DynamicUpgradeModal } from '@components/molecules';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { AdjustmentsHorizontalIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useUpdateCampaign } from '@hooks/campaigns/useUpdateCampaign';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { findCountryName } from '@shared/cores/types/Country';
import { useHelper } from '@shared/utils';
import {
    CountryFeatureCode,
    GetCountryCodeFromCountryFeature,
    isMediaPlanCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { CountrySelect, MultiSelect } from '..';
import { UpgradeAgeGroupHeader, UpgradeCountryHeader, UpgradeGenderHeader } from '../../atoms';
import { SingleSelect } from '../formSelect/SingleSelect';

export interface ConstraintsSectionProps {
    campaign?: GetCampaignResponseDto;
    onCreateScenarios: (scenario: CreateOptimisedScenariosRequestDto) => Promise<void>;
}

export const ConstraintsSection: FC<ConstraintsSectionProps> = ({
    campaign,
    onCreateScenarios,
}) => {
    const requestParams = useLoaderData() as GetCampaignByIdRequest;
    const { mediaPlanId } = requestParams;

    const { t } = useTranslation('mediaPlans');
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const optimisationObjectivesText = t(
        'mediaPlanning.scenarioCreation.generateScenarios.optimisationObjectives',
        {
            returnObjects: true,
        },
    );
    const formLabels = t('mediaPlanning.scenarioCreation.generateScenarios.formLabels', {
        returnObjects: true,
    });
    const buttons = t('mediaPlanning.scenarioCreation.generateScenarios.buttons', {
        returnObjects: true,
    });

    const demographic = t('mediaPlanning.scenarioCreation.generateScenarios.buttons.demographic', {
        returnObjects: true,
    });

    const { formatOptionsForSelect, formatAgeGroup, formatStringToCapitalized } = useHelper();

    const { updateCampaign } = useUpdateCampaign();

    const [isGenerating, setIsGenerating] = useBoolean();

    const optimisationOptions = [
        {
            value: OptimisationStrategy.AApR,
            label: optimisationObjectivesText.aasr.label,
            tooltip: optimisationObjectivesText.aasr.tooltip,
        },
        {
            value: OptimisationStrategy.Stl,
            label: optimisationObjectivesText.stl.label,
            tooltip: optimisationObjectivesText.stl.tooltip,
        },
        // commented out for the time being as this option is currently not generating meaningful results for customers
        // {
        //     value: OptimisationStrategy.AApI,
        //     label: optimisationObjectivesText.aasi.label,
        //     tooltip: optimisationObjectivesText.aasi.tooltip,
        // },
        // {
        //     value: OptimisationStrategy.Ltl,
        //     label: optimisationObjectivesText.ltl.label,
        //     tooltip: optimisationObjectivesText.ltl.tooltip,
        // },
    ];

    const adjustmentToleranceLevels = [
        {
            value: AdjustmentToleranceLevel.Low,
            label: 'Low (10%)',
        },
        {
            value: AdjustmentToleranceLevel.Medium,
            label: 'Medium (30%)',
        },
        {
            value: AdjustmentToleranceLevel.High,
            label: 'High (50%)',
        },
    ];
    const toleranceLevelLimit = {
        max: 60,
        default: 30,
        min: 5,
    } as const;

    const {
        isOpen: isAdFormatsModalOpen,
        onOpen: onAdFormatsModalOpen,
        onClose: onAdFormatsModalClose,
    } = useDisclosure();

    const [budget, setBudget] = useState<number>();
    const [channelFormatData, setChannelFormatData] =
        useState<Array<ScenarioAdFormatResponseDto>>();
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [isUsingGlobal, setIsUsingGlobal] = useState<boolean>();
    const [selectedCountries, setSelectedCountries] = useState<CountryCodeIso2[]>([]);
    const [selectedAgeGroups, setSelectedAgeGroups] = useState<AgeGroup[]>();
    const [selectedGenders, setSelectedGenders] = useState<Gender[]>();
    const [selectedToleranceOption, setSelectedToleranceOption] =
        useState<AdjustmentToleranceLevel>();
    const [toleranceLevel, setToleranceLevel] = useState<number>(toleranceLevelLimit.default);
    const [minSpendData, setMinSpendData] = useState<Array<ScenarioMinSpendPerChannelRequestDto>>();
    const constraints = campaign?.constraints;

    useEffect(() => {
        setBudget(constraints?.budget ?? undefined);
        setChannelFormatData(constraints?.adFormats ?? []);
        setStartDate(constraints?.startDate ?? undefined);
        setEndDate(constraints?.endDate ?? undefined);
        setIsUsingGlobal(constraints?.isUsingGlobalCountry);
        setMinSpendData(
            constraints?.minSpendPerChannels?.map((channel) => ({
                channelCode: channel.adChannelCode,
                minSpendPercentage: channel.minSpendPercentage,
            })) ?? [],
        );
        setSelectedToleranceOption(
            constraints?.adjustmentToleranceLevel ?? AdjustmentToleranceLevel.Medium,
        );
        setSelectedGenders(constraints?.genders ?? Object.values(Gender));
        setSelectedAgeGroups(constraints?.ageGroups ?? Object.values(AgeGroup));
    }, [constraints]);

    const [selectedOptimisationObjectives, setSelectedOptimisationObjectives] = useState<
        Array<OptimisationStrategy>
    >([]);

    const { canUseGlobal, subscribedCountries } = useMemo(() => {
        const hasGlobalFeatureBalance = featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );
        const countries =
            featureBalances
                ?.filter(
                    (f) =>
                        isMediaPlanCountryFeature(f.featureCode!) &&
                        f.limitType === LimitType.Unlimited,
                )
                .map(
                    (f) =>
                        GetCountryCodeFromCountryFeature(
                            f.featureCode as CountryFeatureCode,
                        ) as CountryCodeIso2,
                ) ?? [];

        return {
            canUseGlobal: hasGlobalFeatureBalance,
            subscribedCountries: countries,
        };
    }, [featureBalances]);

    useEffect(() => {
        setSelectedCountries(
            constraints?.countries?.filter((c) => subscribedCountries.includes(c)) ?? [],
        );
        if (canUseGlobal) {
            setIsUsingGlobal(constraints?.isUsingGlobalCountry ?? undefined);
        } else {
            setIsUsingGlobal(false);
        }
    }, [canUseGlobal, subscribedCountries, campaign]);

    const isGenerateDisabled = useMemo(() => {
        const isBudgetInvalid = !budget || budget <= 0;
        const isDatesInvalid = !startDate || !endDate || startDate > endDate;
        const isOptimisationObjectivesInvalid = selectedOptimisationObjectives.length === 0;
        const isAgeGroupInvalid = !selectedAgeGroups || selectedAgeGroups.length === 0;
        const isGenderInvalid = !selectedGenders || selectedGenders.length === 0;
        const isCountriesInvalid = selectedCountries.length === 0 && !isUsingGlobal;
        return (
            isBudgetInvalid ||
            isDatesInvalid ||
            isOptimisationObjectivesInvalid ||
            isCountriesInvalid ||
            isGenderInvalid ||
            isAgeGroupInvalid ||
            isCountriesInvalid
        );
    }, [
        selectedOptimisationObjectives,
        budget,
        startDate,
        endDate,
        selectedAgeGroups,
        selectedGenders,
        selectedCountries,
        isUsingGlobal,
    ]);

    const emitGenerateScenarioEvent = usePosthogEvent('Generate scenarios (5/5)');
    const handleGenerate = async () => {
        emitGenerateScenarioEvent();
        setIsGenerating.on();
        await onCreateScenarios({
            optimisationStrategies: selectedOptimisationObjectives,
            budget,
            startDate,
            endDate,
            adjustmentToleranceLevel: selectedToleranceOption,
            toleranceLevelPercentage: toleranceLevel / 100,
            countries: selectedCountries,
            ageGroups: selectedAgeGroups,
            genders: selectedGenders,
            // budgetConstraintType: selectedBudgetConstraint,
            budgetConstraintType: BudgetConstraintType.AdjustmentToleranceLevel,
            adFormats: channelFormatData,
            minSpendPerChannels: minSpendData,
            toGenerateResults: true,
            isUsingGlobalCountry: isUsingGlobal,
        }).finally(() => setIsGenerating.off());
    };

    const budgetConstraintsText = t(
        'mediaPlanning.scenarioCreation.generateScenarios.buttons.budgetConstraints',
        {
            returnObjects: true,
        },
    );

    const canUseAgeGroups = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseAgeGroups,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const canUseGenders = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseGenders,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const canUseAdFormat = useMemo(() => {
        const featureBalance = featureBalances?.find(
            (f) => f.featureCode === FeatureCode.MediaPlansCustomiseFormatCpms,
        );

        return featureBalance && featureBalance.limitType === LimitType.Unlimited;
    }, [featureBalances]);

    const updateConstraint = (data: UpdateConstraintsRequestDto) => {
        const campaignRequestDto: UpdateCampaignRequestDto = {
            constraints: data,
        };
        updateCampaign(mediaPlanId, campaignRequestDto);
    };

    const {
        isOpen: isUpgradeModalOpen,
        onOpen: onUpgradeModalOpen,
        onClose: onUpgradeModalClose,
    } = useDisclosure();

    const [upgradeHeader, setUpgradeHeader] = useState<ReactJSXElement>(<></>);
    const handleOpenUpgradeModal = (header: ReactJSXElement) => {
        setUpgradeHeader(header);
        onUpgradeModalOpen();
    };

    const toleranceInputRef = useRef<HTMLInputElement>(null);
    return (
        <Stack gap="1rem">
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_ConstraintSection"
                isOpen={isUpgradeModalOpen}
                onClose={onUpgradeModalClose}
                header={upgradeHeader}
            />
            <Grid templateColumns="repeat(5, 1fr)" width="100%" gap="1rem">
                <GridItem>
                    <FormControl>
                        <FormLabel>{formLabels.budget}</FormLabel>
                        <Center justifyContent="left">
                            {/* commented out until budget is reworked to effect scenario result */}
                            {/* <EditableCell
                                alignment="left"
                                width="100%"
                                value={budget}
                                onChange={handleBudgetChange}
                                unit="$"
                            /> */}
                            <Skeleton isLoaded={!!campaign} width="100%">
                                <Input
                                    isReadOnly
                                    value={`$${campaign?.constraints?.budget?.toLocaleString(
                                        undefined,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        },
                                    )}`}
                                />
                            </Skeleton>
                        </Center>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl isRequired>
                        <FormLabel>{formLabels.startDate}</FormLabel>
                        <Skeleton isLoaded={!!campaign} width="100%">
                            <DatePicker
                                type="startDate"
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date);
                                    updateConstraint({ startDate: date });
                                }}
                                maxDate={endDate}
                            />
                        </Skeleton>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl isRequired>
                        <FormLabel>{formLabels.endDate}</FormLabel>
                        <Skeleton isLoaded={!!campaign} width="100%">
                            <DatePicker
                                type="endDate"
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date);
                                    updateConstraint({ endDate: date });
                                }}
                                minDate={startDate}
                            />
                        </Skeleton>
                    </FormControl>
                </GridItem>
                {/* Campaign objective */}
                <GridItem>
                    <FormControl>
                        <FormLabel>{formLabels.campaignObjective}</FormLabel>
                        <Skeleton isLoaded={!!campaign} width="100%">
                            <Input
                                value={campaign?.campaignObjective ?? ''}
                                textOverflow="ellipsis"
                                readOnly
                                placeholder="N/A"
                            />
                        </Skeleton>
                    </FormControl>
                </GridItem>
                {/* Ad formats */}
                <GridItem>
                    <Flex>
                        <FormLabel>{formLabels.adFormats}</FormLabel>
                        <Spacer />
                        {!canUseAdFormat && (
                            <Badge variant="solid" backgroundColor="gray.400" height="1.2rem">
                                Upgrade
                            </Badge>
                        )}
                    </Flex>
                    <Button
                        isLoading={!campaign}
                        variant="outline"
                        onClick={onAdFormatsModalOpen}
                        rightIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                        colorScheme="orange"
                        width="100%"
                    >
                        {buttons.configureFormats}
                    </Button>
                    {channelFormatData && (
                        <AdFormatsConstraintModal
                            scenarioAdFormats={channelFormatData}
                            onConfirm={(adFormats) => {
                                setChannelFormatData(adFormats);
                                updateConstraint({ adFormats });
                            }}
                            onClose={onAdFormatsModalClose}
                            isOpen={isAdFormatsModalOpen}
                        />
                    )}
                </GridItem>
                {/* Country */}
                <GridItem>
                    <CountrySelect
                        label="Countries"
                        isLoaded={!!campaign}
                        availableCountryOptions={subscribedCountries}
                        setSelectedOptions={(selectedOptions) => {
                            const countries = selectedOptions.map(
                                (o) => o.value as CountryCodeIso2,
                            );
                            setSelectedCountries(countries);
                            updateConstraint({
                                countries,
                            });
                        }}
                        selectedOptions={formatOptionsForSelect(
                            selectedCountries.filter((f) => subscribedCountries.includes(f)),
                            findCountryName,
                        )}
                        tooltip={demographic.tooltip}
                        isRequired
                        canSelectGlobal={!!canUseGlobal}
                        isUsingGlobal={!!isUsingGlobal}
                        setIsUsingGlobal={(isUsingGlobalCountry) => {
                            setIsUsingGlobal(isUsingGlobalCountry);
                            updateConstraint({ isUsingGlobalCountry });
                        }}
                        isDisabled={!featureBalances}
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeCountryHeader />)}
                    />
                </GridItem>
                {/* Age group */}
                <GridItem>
                    <MultiSelect
                        label="Age group"
                        isClearable={!!canUseAgeGroups}
                        options={formatOptionsForSelect(
                            Object.values(AgeGroup),
                            formatAgeGroup,
                        ).map((o) => ({
                            isDisabled: false,
                            tooltip: !canUseAgeGroups
                                ? 'Upgrade your plan to customise age group constraints'
                                : '',
                            showLockIcon: false,
                            ...o,
                        }))}
                        setSelectedOptions={(selectedOptions) => {
                            const ageGroups = selectedOptions.map((o) => o.value as AgeGroup);
                            setSelectedAgeGroups(ageGroups);
                            updateConstraint({ ageGroups });
                        }}
                        selectedOptions={formatOptionsForSelect(
                            selectedAgeGroups ?? [],
                            formatAgeGroup,
                        )}
                        tooltip={demographic.tooltip}
                        isRequired
                        isLoaded={!!campaign}
                        selectAll
                        allowSelectAll
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeAgeGroupHeader />)}
                        isPaidPlan={!!canUseAgeGroups}
                    />
                </GridItem>
                {/* Gender */}
                <GridItem>
                    <MultiSelect
                        label="Gender"
                        isClearable={!!canUseGenders}
                        options={formatOptionsForSelect(
                            Object.values(Gender),
                            formatStringToCapitalized,
                        ).map((o) => ({
                            isDisabled: false,
                            tooltip: !canUseGenders
                                ? 'Upgrade your plan customise gender constraints'
                                : '',
                            showLockIcon: false,
                            ...o,
                        }))}
                        setSelectedOptions={(selectedOptions) => {
                            const genders = selectedOptions.map((o) => o.value as Gender);
                            setSelectedGenders(genders);
                            updateConstraint({ genders });
                        }}
                        selectedOptions={formatOptionsForSelect(
                            selectedGenders ?? [],
                            formatStringToCapitalized,
                        )}
                        tooltip={demographic.tooltip}
                        isRequired
                        isLoaded={!!campaign}
                        selectAll
                        allowSelectAll
                        openUpgradeModal={() => handleOpenUpgradeModal(<UpgradeGenderHeader />)}
                        isPaidPlan={!!canUseGenders}
                    />
                </GridItem>
                {/* Tolerance level */}
                <GridItem>
                    <SingleSelect
                        label="Tolerance level"
                        tooltip={budgetConstraintsText.planTolerance.tooltip}
                        options={adjustmentToleranceLevels}
                        setSelectedOption={(selectedOption) => {
                            const adjustmentToleranceLevel =
                                selectedOption?.value as AdjustmentToleranceLevel;
                            setSelectedToleranceOption(adjustmentToleranceLevel);
                            updateConstraint({ adjustmentToleranceLevel });
                        }}
                        selectedOption={adjustmentToleranceLevels.find(
                            (level) => level.value === selectedToleranceOption,
                        )}
                        isRequired
                        isLoaded={!!campaign}
                    />

                    {/* <FormControl isRequired>
                        <FormLabel>Tolerance level</FormLabel>
                        <Flex alignItems="center" gap="0.75rem" justifyContent="space-between">
                            <ToleranceLevelSlider
                                sliderValue={toleranceLevel}
                                setSliderValue={setToleranceLevel}
                                min={toleranceLevelLimit.min}
                                max={toleranceLevelLimit.max}
                                sliderMarks={[10, 30, 50]}
                            />
                            <NumberInput
                                position="relative"
                                allowMouseWheel
                                maxW="80px"
                                max={toleranceLevelLimit.max}
                                min={toleranceLevelLimit.min}
                                step={1}
                                value={toleranceLevel}
                                onChange={(str, val) => {
                                    if (str.length === 0) {
                                        setToleranceLevel(toleranceLevelLimit.min);
                                        return;
                                    }
                                    setToleranceLevel(val);
                                }}
                                bg="white"
                            >
                                <Box
                                    display="inline-flex"
                                    position="absolute"
                                    justifyContent="center"
                                    alignItems="center"
                                    top="50%"
                                    right="0.5rem"
                                    transform="translateY(-50%)"
                                    boxSize={3}
                                    fontWeight={500}
                                    borderRadius="9999px"
                                    bg="green.100"
                                    fontSize="14px"
                                    p={3}
                                    zIndex={999}
                                    pointerEvents="none"
                                >
                                    %
                                </Box>
                                <NumberInputField px="1rem" />
                            </NumberInput>
                        </Flex>
                    </FormControl> */}
                </GridItem>
                {/* Optimisation objective */}
                <GridItem>
                    <MultiSelect
                        label={formLabels.optimisationObjectives}
                        options={optimisationOptions}
                        setSelectedOptions={(selectedOptions) => {
                            setSelectedOptimisationObjectives(
                                selectedOptions.map((o) => o.value as OptimisationStrategy),
                            );
                        }}
                        selectedOptions={optimisationOptions.filter((o) =>
                            selectedOptimisationObjectives.includes(o.value),
                        )}
                        isRequired
                    />
                </GridItem>
            </Grid>
            <Box>
                <Button
                    colorScheme="orange"
                    leftIcon={<Icon as={ArrowPathIcon} />}
                    isDisabled={isGenerateDisabled}
                    onClick={handleGenerate}
                    isLoading={isGenerating}
                    loadingText="Generate Scenarios"
                >
                    {buttons.generateScenarios.label}
                </Button>
            </Box>
        </Stack>
    );
};
