import { OptimisationStrategy } from '@api-clients/media-plan';

enum OptimisationObjective {
    ShortTermLift = 'Brand uplift',
    LongTermLift = 'Long term lift',
    ActiveAttentionSecondsPerImpression = 'Active Attention Seconds Per Impression',
    ActiveAttentionSecondsPerReach = 'Active Attention Seconds Per Reach',
    Baseline = 'Baseline',
}

const getOptimisationObjectiveBase = (
    optimisationStrategy: OptimisationStrategy,
): OptimisationObjective => {
    switch (optimisationStrategy) {
        case 'ltl':
            return OptimisationObjective.LongTermLift;
        case 'stl':
            return OptimisationObjective.ShortTermLift;
        case 'a_ap_i':
            return OptimisationObjective.ActiveAttentionSecondsPerImpression;
        case 'a_ap_r':
            return OptimisationObjective.ActiveAttentionSecondsPerReach;
        case 'base':
        default:
            return OptimisationObjective.Baseline;
    }
};

const getOptimisationObjectiveAcronym = (optimisationStrategy: OptimisationStrategy): string => {
    switch (optimisationStrategy) {
        case 'ltl':
            return 'LTL';
        case 'stl':
            return 'BU';
        case 'a_ap_i':
            return 'AApI';
        case 'a_ap_r':
            return 'AApR';
        case 'base':
        default:
            return 'base';
    }
};

export const getOptimisationObjective = (
    optimisationStrategy: OptimisationStrategy,
    type: 'base' | 'verbose' | 'acronym' = 'base',
): string => {
    switch (type) {
        case 'verbose':
            return `${getOptimisationObjectiveBase(
                optimisationStrategy,
            )} (${getOptimisationObjectiveAcronym(optimisationStrategy)})`;
        case 'acronym':
            return getOptimisationObjectiveAcronym(optimisationStrategy);
        case 'base':
        default:
            return getOptimisationObjectiveBase(optimisationStrategy);
    }
};
