import {globalCampaignSlice} from "@/redux/slices/global/campaign";
import {proveAdFormatSlice} from "@/redux/slices/prove/adFormat";
import {proveCampaignSlice} from "@/redux/slices/prove/campaign";
import {lineItemSlice} from "@/redux/slices/prove/lineItem";
import {dashboardSlice} from "@/redux/slices/prove/dashboard";

export const reducer = {
    globalCampaign: globalCampaignSlice.reducer,
    proveAdFormat: proveAdFormatSlice.reducer,
    proveCampaign: proveCampaignSlice.reducer,
    proveLineItem: lineItemSlice.reducer,
    proveDashboard: dashboardSlice.reducer
}
