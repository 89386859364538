import React, {FC, useEffect, useState} from 'react'
import {Box, useToken} from "@chakra-ui/react";
import {Chart} from 'react-chartjs-2';
import {
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    ChartType,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
import {ProveDashboardBreakdownConfig} from "@components/organisms/prove/ProveDashboardBody";
import {ProveFlattenedReportBreakdownEntry} from "@api-clients/prove/schema/ProveDashboard";
import {proveDashboardOverviewData} from "@redux/slices/prove/dashboard/selectors";
import {useSelector} from "@redux";

import annotationPlugin from "chartjs-plugin-annotation";
import {useTranslation} from "react-i18next";
import {AnnotationOptions} from "chartjs-plugin-annotation/types/options";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";

interface ProveDashboardChartProps {
    breakdownConfig: ProveDashboardBreakdownConfig;

    valueFormatter(type: string, value: string): string;

    chartRef: React.MutableRefObject<ChartJSOrUndefined>;

    breakdownData: Array<ProveFlattenedReportBreakdownEntry>;

    showAnnotations: boolean;
}

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    BubbleController,
    LineController,
    BarController,
    annotationPlugin
);

export const ProveDashboardChart: FC<ProveDashboardChartProps> = ({
                                                                      breakdownConfig,
                                                                      valueFormatter,
                                                                      chartRef,
                                                                      breakdownData,
                                                                      showAnnotations
                                                                  }) => {

    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    const dashboardOverviewDataSelector = useSelector(proveDashboardOverviewData);

    const [chartData, setChartData] = useState({} as ChartData);
    const [chartOptions, setChartOptions] = useState({} as ChartOptions);

    const chartColors = useToken('colors', [
        'green.500',
        'orange.500',
        'gray.300',
        'red.500',
        'purple.500',
        'limeGreen.500',
        'pink.500',
        'yellow.500',
        'teal.500',
        'cyan.500',
    ]);

    const formatCountMetric = (metric: number) => {
        if (metric < 1000) {
            return metric.toLocaleString();
        }

        if (metric < 1000000) {
            return `${Math.round(metric / 1000)}K`;
        }

        return `${(metric / 1000000).toFixed(3)}M`;
    }

    const formatBreakdownEntryValue = (breakdownEntry: ProveFlattenedReportBreakdownEntry) => {
        const formattedValues = [];
        for (let i = 0; i < breakdownConfig.fields.length; i++) {
            const field = breakdownConfig.fields[i];

            formattedValues.push(valueFormatter(field.id, breakdownEntry.value[i]));
        }

        return formattedValues.join(" :: ");
    }

    useEffect(() => {
        if (!breakdownData || !dashboardOverviewDataSelector) {
            setChartOptions({} as ChartOptions);
            setChartData({} as ChartData);

            return;
        }

        const filteredBreakdownData = breakdownData.filter((entry, index) => {
            return (!breakdownConfig.chartLimit || index < breakdownConfig.chartLimit)
        });

        if (breakdownConfig.chartType === 'bar') {
            setChartOptions({
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) =>
                                tooltipItems.map(
                                    (tooltipItem) => tooltipItem.dataset.label ?? '',
                                ),
                            label: () => {
                                return '';
                            },
                            afterBody: (tooltipItems) => {
                                const label = tooltipItems.at(0)!.dataset.label as string;
                                const data = tooltipItems.at(0)!.raw as number;
                                const formattedValue = (label === content.breakdown.labels.impressions) ? formatCountMetric(data) : `${data}s`;

                                return [formattedValue];
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        title: {
                            text: content.breakdown.labels.averageAttention,
                            display: true,
                        },
                        ticks: {
                            callback: (tickValue) => `${tickValue}s`,
                        },
                        grid: {
                            display: false,
                        },
                        position: 'left'
                    },
                    y1: {
                        title: {
                            text: content.breakdown.labels.impressions,
                            display: true,
                        },
                        grid: {
                            display: false,
                        },
                        position: 'right'
                    },
                    x: {
                        grid: {
                            display: false,
                        }
                    },
                }
            });
            setChartData({
                labels: filteredBreakdownData.map((entry, index) => {
                    return formatBreakdownEntryValue(entry);
                }),
                datasets: [
                    {
                        type: 'line' as const,
                        label: content.breakdown.labels.impressions,
                        borderColor: chartColors[3],
                        borderWidth: 2,
                        fill: false,
                        yAxisID: 'y1',
                        data: filteredBreakdownData.map((entry) => {
                            return entry.metrics.impressions
                        }),
                    },
                    {
                        label: content.breakdown.labels.averageActiveAttention,
                        data: filteredBreakdownData.map((entry) => {
                            return entry.metrics.avgActiveAttention
                        }),
                        backgroundColor: chartColors[0],
                        yAxisID: 'y',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 17,
                        borderColor: "white",
                        borderWidth: 2
                    },
                    {
                        label: content.breakdown.labels.averagePassiveAttention,
                        data: filteredBreakdownData.map((entry) => {
                            return entry.metrics.avgPassiveAttention
                        }),
                        backgroundColor: chartColors[1],
                        yAxisID: 'y',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 17,
                        borderColor: "white",
                        borderWidth: 2
                    },
                    {
                        label: content.breakdown.labels.averageNonAttention,
                        data: filteredBreakdownData.map((entry) => {
                            return entry.metrics.avgNonAttention
                        }),
                        backgroundColor: chartColors[2],
                        yAxisID: 'y',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 17,
                        borderColor: "white",
                        borderWidth: 2
                    }
                ]
            });
        } else if (breakdownConfig.chartType === 'bubble') {
            const annotations = (showAnnotations) ? {
                avgActiveAttention: {
                    type: 'line',
                    yMin: dashboardOverviewDataSelector.avgActiveAttention,
                    yMax: dashboardOverviewDataSelector.avgActiveAttention,
                    borderColor: "#ccc",
                    borderWidth: 1
                },
                avgPassiveAttention: {
                    type: 'line',
                    xMin: dashboardOverviewDataSelector.avgPassiveAttention,
                    xMax: dashboardOverviewDataSelector.avgPassiveAttention,
                    borderColor: "#ccc",
                    borderWidth: 1,
                }
            } as Record<string, AnnotationOptions> : undefined;

            setChartOptions({
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) =>
                                tooltipItems.map(
                                    (tooltipItem) => tooltipItem.dataset.label ?? '',
                                ),
                            label: () => {
                                return '';
                            },
                            // have to put data in the afterBody section as label is rendered per
                            // tooltip item, creating lots of redundant displayed data
                            afterBody: (tooltipItems) => {
                                const data = tooltipItems.at(0)!.raw as { impressions: number, x: number, y: number };

                                return [
                                    `${content.breakdown.labels.impressions}: ${formatCountMetric(data.impressions)}`,
                                    `${content.breakdown.labels.averageActiveAttention}: ${data.y}s`,
                                    `${content.breakdown.labels.averagePassiveAttention}: ${data.x}s`
                                ];
                            },
                        },
                    },
                    annotation: {
                        annotations
                    }
                },
                scales: {
                    y: {
                        title: {
                            text: content.breakdown.labels.averageActiveAttention,
                            display: true,
                        },
                        grid: {
                            display: false,
                        },
                        position: 'left'
                    },
                    x: {
                        title: {
                            text: content.breakdown.labels.averagePassiveAttention,
                            display: true,
                        },
                        grid: {
                            display: false,
                        }
                    },
                }
            });
            setChartData({
                datasets: filteredBreakdownData.map((entry) => {
                    return {
                        label: formatBreakdownEntryValue(entry),
                        data: [{
                            x: entry.metrics.avgPassiveAttention,
                            y: entry.metrics.avgActiveAttention,
                            r: Math.min((entry.metrics.impressions / dashboardOverviewDataSelector.impressions) * 300, 20),
                            impressions: entry.metrics.impressions
                        }],
                        backgroundColor: chartColors[0]
                    }
                })
            });
        }
    }, [breakdownData]);

    return (
        <>
            {chartData.datasets && (
                <Box p={4} height={600}>
                    <Chart type={breakdownConfig.chartType as ChartType}
                           plugins={[{
                               id: 'custom_canvas_background_color',
                               beforeDraw: (chart) => {
                                   const ctx = chart.canvas.getContext('2d');

                                   if (!ctx) {
                                       return;
                                   }

                                   ctx.save();
                                   ctx.globalCompositeOperation = 'destination-over';
                                   ctx.fillStyle = 'white';
                                   ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
                                   ctx.restore();
                               }
                           }]}
                           data={chartData}
                           options={chartOptions}
                           ref={chartRef}
                    />
                </Box>
            )}
        </>
    )
}
