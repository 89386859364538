/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface SelectedAdFormatResponseDto
 */
export interface SelectedAdFormatResponseDto {
    /**
     *
     * @type {string}
     * @memberof SelectedAdFormatResponseDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof SelectedAdFormatResponseDto
     */
    adFormatName?: string | null;
    /**
     *
     * @type {string}
     * @memberof SelectedAdFormatResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof SelectedAdFormatResponseDto
     */
    adChannelName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SelectedAdFormatResponseDto
     */
    isChecked?: boolean;
    /**
     *
     * @type {number}
     * @memberof SelectedAdFormatResponseDto
     */
    cpm?: number;
}

/**
 * Check if a given object implements the SelectedAdFormatResponseDto interface.
 */
export function instanceOfSelectedAdFormatResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SelectedAdFormatResponseDtoFromJSON(json: any): SelectedAdFormatResponseDto {
    return SelectedAdFormatResponseDtoFromJSONTyped(json, false);
}

export function SelectedAdFormatResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SelectedAdFormatResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        adFormatName: !exists(json, 'ad_format_name') ? undefined : json['ad_format_name'],
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
        isChecked: !exists(json, 'is_checked') ? undefined : json['is_checked'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
    };
}

export function SelectedAdFormatResponseDtoToJSON(value?: SelectedAdFormatResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_code: value.adFormatCode,
        ad_format_name: value.adFormatName,
        ad_channel_code: value.adChannelCode,
        ad_channel_name: value.adChannelName,
        is_checked: value.isChecked,
        cpm: value.cpm,
    };
}
