import { colors } from '@assets/design-system/theming/colors';
import { FC } from 'react';
import { LogoProps } from './AmplifiedStackedLogo';

// Attention Plan Logo
export const AmplifiedMinimisedLogo: FC<LogoProps> = ({ height, width }) => {
    const green = colors.colors.green[500];
    return (
        <svg name="MinimisedLogoIcon" height={height} width={width} viewBox="0 0 210 180">
            <path
                fill={green}
                d="M98.6,49.69h-19.88v-15.3c0-8-6.51-14.52-14.52-14.52s-14.52,6.51-14.52,14.52v24.45h9.16v-24.45c0-2.95,2.4-5.36,5.36-5.36s5.36,2.4,5.36,5.36v35.17h-19.88v29.81h-15.3c-8,0-14.52,6.51-14.52,14.52s6.51,14.52,14.52,14.52h4.58v-9.16h-4.58c-2.95,0-5.36-2.4-5.36-5.36s2.4-5.36,5.36-5.36h15.3v35.17c0,8,6.51,14.52,14.52,14.52s14.52-6.51,14.52-14.52v-44.26h-9.16v44.26c0,2.95-2.4,5.36-5.36,5.36s-5.36-2.4-5.36-5.36V78.72h10.72v9.94h9.16v-29.81h10.72v69.56h39.75v15.3c0,8,6.51,14.52,14.52,14.52s14.52-6.51,14.52-14.52v-24.45h-9.16v24.45c0,2.95-2.4,5.36-5.36,5.36s-5.36-2.4-5.36-5.36v-35.17h19.88v-29.81h15.3c8,0,14.52-6.51,14.52-14.52s-6.51-14.52-14.52-14.52h-4.58v9.16h4.58c2.95,0,5.36,2.4,5.36,5.36s-2.4,5.36-5.36,5.36h-15.3V34.39c0-8-6.51-14.52-14.52-14.52s-14.52,6.51-14.52,14.52v44.26h9.16V34.39c0-2.95,2.4-5.36,5.36-5.36s5.36,2.4,5.36,5.36V99.38h-10.72v-9.94h-9.16v29.81h-10.72V49.69h-9.16V119.25h-10.72V49.69Z"
            />
            <path
                fill={green}
                d="M207.91,64.2c0-17.5-13.14-32-30.08-34.12C175.71,13.14,161.21,0,143.71,0c-18.96,0-34.39,15.43-34.39,34.39v4.58h9.16v-4.58c0-13.91,11.32-25.23,25.23-25.23s25.23,11.32,25.23,25.23v4.58h4.58c13.91,0,25.23,11.32,25.23,25.23,0,8.47-4.22,16.33-11.29,21.03l-5.74,3.81,5.74,3.81c7.07,4.7,11.29,12.56,11.29,21.03,0,12.35-8.92,22.65-20.65,24.81v-11.03c5.77-1.92,9.94-7.37,9.94-13.78,0-8-6.51-14.52-14.52-14.52h-4.58v9.16h4.58c2.95,0,5.36,2.4,5.36,5.36s-2.4,5.36-5.36,5.36h-4.58v24.45c0,13.91-11.32,25.23-25.23,25.23s-25.23-11.32-25.23-25.23v-4.58h-9.16v4.58c0,18.96,15.43,34.39,34.39,34.39,17.41,0,31.85-13.01,34.08-29.81,0-.07,.04-.27,.04-.27,0,0,.21-.03,.27-.04,16.79-2.39,29.81-16.67,29.81-34.08,0-9.49-3.88-18.41-10.61-24.84,6.73-6.43,10.61-15.36,10.61-24.84Z"
            />
            <path
                fill={green}
                d="M0,113.89c0,17.5,13.14,32,30.08,34.12,2.13,16.94,16.62,30.08,34.12,30.08,18.96,0,34.39-15.43,34.39-34.39v-4.58s-9.16,0-9.16,0v4.58c0,13.91-11.32,25.23-25.23,25.23s-25.23-11.32-25.23-25.23v-4.58s-4.58,0-4.58,0c-13.91,0-25.23-11.32-25.23-25.23,0-8.47,4.22-16.33,11.29-21.03l5.74-3.81-5.74-3.81c-7.07-4.7-11.29-12.56-11.29-21.03,0-12.35,8.92-22.65,20.65-24.81v11.03c-5.77,1.92-9.94,7.37-9.94,13.78,0,8,6.51,14.52,14.52,14.52h4.58s0-9.16,0-9.16h-4.58c-2.95,0-5.36-2.4-5.36-5.36,0-2.95,2.4-5.36,5.36-5.36h4.58s0-19.88,0-19.88v-4.58c0-13.91,11.32-25.23,25.23-25.23s25.23,11.32,25.23,25.23v4.58s9.16,0,9.16,0v-4.58C98.6,15.43,83.17,0,64.2,0,46.79,0,32.36,13.01,30.12,29.81c0,.07-.04,.27-.04,.27,0,0-.21,.03-.27,.04C13.03,32.51,0,46.79,0,64.2c0,9.49,3.88,18.41,10.61,24.84C3.88,95.48,0,104.41,0,113.89Z"
            />
        </svg>
    );
};
