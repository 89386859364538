import React from 'react';
import { SortOptionValue } from '@apps/attention360/pages';
import {
    ActiveAttentionTooltip,
    PassiveAttentionTooltip,
} from '@apps/attention360/pages/strategyReportTooltip';

export const CHANNEL_SELECTION_SORT_OPTIONS: {
    value: SortOptionValue;
    heading: string;
    description?: string;
    tooltip?: React.ReactNode;
}[] = [
    {
        value: 'active',
        heading: 'Highest active attention',
        description: 'For all campaigns and brands',
        tooltip: <ActiveAttentionTooltip />,
    },
    {
        value: 'passive',
        heading: 'Highest passive attention',
        description: 'Good for big brands with distinctive assets',
        tooltip: <PassiveAttentionTooltip />,
    },
    {
        value: 'cpm',
        heading: 'Best value',
        description: 'Highest active attention per impression cost',
    },
];
