import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveBreakdownAdFormatReportDemoData: ProveReportData = {
    "breakdown": [
        {
            "value": "dis.std.mobile-banner",
            "metrics": {
                "avgActiveAttention": 0.21,
                "impressionsActive5s": 33838,
                "impressionsActive4s": 58936,
                "avgNonAttention": 3.21,
                "impressions": 518727,
                "avgPassiveAttention": 3.86,
                "impressionsActive3s": 102080,
                "impressionsActive2s": 192605,
                "impressionsActive1s": 349602
            }
        },
        {
            "value": "dis.std.mrec",
            "metrics": {
                "avgActiveAttention": 3.21,
                "impressionsActive5s": 33838,
                "impressionsActive4s": 58936,
                "avgNonAttention": 1.26,
                "impressions": 205955,
                "avgPassiveAttention": 5.86,
                "impressionsActive3s": 89080,
                "impressionsActive2s": 111605,
                "impressionsActive1s": 142602
            }
        },
        {
            "value": "vid.std.instream",
            "metrics": {
                "avgActiveAttention": 2.21,
                "impressionsActive5s": 33838,
                "impressionsActive4s": 58936,
                "avgNonAttention": 3.81,
                "impressions": 107953,
                "avgPassiveAttention": 6.86,
                "impressionsActive3s": 69080,
                "impressionsActive2s": 82605,
                "impressionsActive1s": 97602
            }
        }
    ]
};
