/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface DecaySecondResponseDto
 */
export interface DecaySecondResponseDto {
    /**
     *
     * @type {number}
     * @memberof DecaySecondResponseDto
     */
    activeAttentionPercentage?: number;
    /**
     *
     * @type {number}
     * @memberof DecaySecondResponseDto
     */
    passiveAttentionPercentage?: number;
    /**
     *
     * @type {number}
     * @memberof DecaySecondResponseDto
     */
    second?: number;
}

/**
 * Check if a given object implements the DecaySecondResponseDto interface.
 */
export function instanceOfDecaySecondResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DecaySecondResponseDtoFromJSON(json: any): DecaySecondResponseDto {
    return DecaySecondResponseDtoFromJSONTyped(json, false);
}

export function DecaySecondResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): DecaySecondResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activeAttentionPercentage: !exists(json, 'active_attention_percentage')
            ? undefined
            : json['active_attention_percentage'],
        passiveAttentionPercentage: !exists(json, 'passive_attention_percentage')
            ? undefined
            : json['passive_attention_percentage'],
        second: !exists(json, 'second') ? undefined : json['second'],
    };
}

export function DecaySecondResponseDtoToJSON(value?: DecaySecondResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        active_attention_percentage: value.activeAttentionPercentage,
        passive_attention_percentage: value.passiveAttentionPercentage,
        second: value.second,
    };
}
