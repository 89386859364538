import { forwardRef } from 'react';
import { ChannelBenchmarkResponseDto } from '@api-clients/attention-data';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    BarController,
    Plugin,
    ChartOptions,
    LegendItem,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { WhiteBackgroundPlugin } from 'src/components/Chart';
import {
    generateChartLegends,
    ChartColors,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { colors } from '@assets/design-system/theming/colors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    BarController,
);

const channelSelectionGraphNumberFormatter = new Intl.NumberFormat([navigator.language, 'en-US'], {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});
type ChannelSelectionGraphProps = {
    channelBenchmark: ChannelBenchmarkResponseDto[];
};
const chartColors = {
    ...ChartColors,
};

export const ChannelSelectionGraph = forwardRef<
    ChartJSOrUndefined<'bar', string[], object>,
    ChannelSelectionGraphProps
>(({ channelBenchmark }, ref) => {
    const labels = channelBenchmark.map((c) => c.channelName ?? '');
    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Active attention per impression cost',
                fill: false,
                borderColor: chartColors.avgCost,
                backgroundColor: chartColors.avgCost,
                data: channelBenchmark.map((c) => {
                    const cpm = c.cpm ?? 0;
                    if (cpm === 0) return 0;
                    return (c.averageActiveAttention ?? 0) / cpm;
                }),
                yAxisID: 'y1',
                order: 1,
            },
            {
                label: 'Active attention',
                data: channelBenchmark.map((c) => c.averageActiveAttention ?? 0),
                backgroundColor: chartColors.active,
                yAxisID: 'y',
                order: 2,
            },
            {
                label: 'Passive attention',
                data: channelBenchmark.map((c) => c.averagePassiveAttention ?? 0),
                backgroundColor: chartColors.passive,
                yAxisID: 'y',
                order: 3,
            },
            {
                label: 'Non attention',
                data: channelBenchmark.map((c) => c.averageInactiveAttention ?? 0),
                backgroundColor: chartColors.inactive,
                yAxisID: 'y',
                order: 4,
            },
        ],
    };
    const setBgWhitePlugin: Plugin<'bar'> = {
        id: 'setBgWhitePlugin',
        beforeDraw: WhiteBackgroundPlugin,
    };

    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem) => {
                        const datasetObject = data.datasets[tooltipItem.datasetIndex];
                        const { label } = datasetObject;
                        const value = datasetObject.data[tooltipItem.dataIndex];
                        return `${label}: ${channelSelectionGraphNumberFormatter.format(value)}`;
                    },
                    title: (tooltipItems): string => {
                        const item = tooltipItems.at(0);
                        if (!item) return '';
                        return labels[item.dataIndex];
                    },
                },
            },
            legend: {
                reverse: false,
                labels: {
                    generateLabels: (chart: ChartJS): LegendItem[] => generateChartLegends(chart),
                },
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Seconds',
                    color: colors.colors.gray['700'],
                    font: {
                        size: 14,
                    },
                },
            },
            y1: {
                type: 'linear',
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'Active attention per impression cost (s)',
                    color: colors.colors.gray['700'],
                    font: {
                        size: 14,
                    },
                },
            },
        },
    };

    return (
        <Chart
            // tsc not happy but this code works
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data={data}
            options={chartOptions}
            type="bar"
            ref={ref}
            plugins={[setBgWhitePlugin]}
        />
    );
});
