import {
    OrganisationApi,
    OrganisationResponseDto,
    RemoveUserFromOrganisationRequestDto,
    RemoveUserRequest,
} from '@api-clients/account-manager';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface RemoveUserHook {
    removeUser: (
        organisationId: string,
        removeUserToOrganisationRequestDto: RemoveUserFromOrganisationRequestDto,
    ) => Promise<OrganisationResponseDto>;
}

type useRemoveUserProps = () => RemoveUserHook;

export const useRemoveUser: useRemoveUserProps = () => {
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function removeUser(
        organisationId: string,
        removeUserDto: RemoveUserFromOrganisationRequestDto,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: RemoveUserRequest = {
                organisationId,
                removeUserFromOrganisationRequestDto: removeUserDto,
            };

            const api = new OrganisationApi(configuration);
            return await api.removeUser(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        removeUser,
    };
};
