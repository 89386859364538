import { Box } from '@chakra-ui/react';
import { Row } from '@components/molecules';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LegendItem,
    LinearScale,
    LineController,
    LineElement,
    Plugin,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { forwardRef } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { WhiteBackgroundPlugin } from 'src/components/Chart';
import {
    generateChartLegends,
    ChartColors,
    numberFormatter,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { DataRow } from './FormatSelectionSection';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    BarController,
);

type FormatSelectionGraphProps = {
    benchmarks: Row<DataRow>[];
};

const barThickness = 25;
const chartColors = {
    ...ChartColors,
    selectedDot: '#00AC59',
};

export const FormatSelectionGraph = forwardRef<
    ChartJSOrUndefined<'bar', string[], object>,
    FormatSelectionGraphProps
>(({ benchmarks }, ref) => {
    // Flatten formatBenchmark data from all channels

    // Extract and sort formats based on selection
    const allFormats = benchmarks.flatMap((benchmark) => {
        // Extract the properties matching the DataRow interface
        return {
            channelName: benchmark.channelName ?? '',
            formatName: benchmark.formatName ?? '',
            averageActiveAttention: benchmark.averageActiveAttention ?? 0,
            averagePassiveAttention: benchmark.averagePassiveAttention ?? 0,
            averageInactiveAttention: benchmark.averageInactiveAttention ?? 0,
            isSelected: benchmark.isSelected ?? false,
            activeAttentionPerImpressionCost: benchmark.cpm
                ? (benchmark.averageActiveAttention ?? 0) / benchmark.cpm
                : 0,
        };
    });

    // Sort formats: selected formats first, then unselected
    // eslint-disable-next-line no-nested-ternary
    allFormats.sort((a, b) => (b.isSelected === a.isSelected ? 0 : b.isSelected ? 1 : -1));

    const labels = allFormats.map((format) => [format.channelName, format.formatName]);
    const activeAttentionData = allFormats.map((format) => format.averageActiveAttention);
    const passiveAttentionData = allFormats.map((format) => format.averagePassiveAttention);
    const inactiveAttentionData = allFormats.map((format) => format.averageInactiveAttention);
    const avgCostData = allFormats.map((format) => format.activeAttentionPerImpressionCost);

    // Determine if any format is selected
    const isAnySelected = allFormats.some((format) => format.isSelected);

    const chartHeight = Math.max(benchmarks.length * 60, 300);

    const data: ChartData<'bar' | 'line'> = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Active attention per impression cost',
                fill: false,
                borderColor: chartColors.avgCost,
                backgroundColor: chartColors.avgCost,
                yAxisID: 'y',
                order: 1,
                data: avgCostData,
            },
            {
                label: 'Active attention',
                data: activeAttentionData,
                backgroundColor: chartColors.active,
                yAxisID: 'y',
                order: 2,
                barThickness,
            },
            {
                label: 'Passive attention',
                data: passiveAttentionData,
                backgroundColor: chartColors.passive,
                yAxisID: 'y',
                order: 3,
                barThickness,
            },
            {
                label: 'Non attention',
                data: inactiveAttentionData,
                backgroundColor: chartColors.inactive,
                yAxisID: 'y',
                order: 4,
                barThickness,
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                position: 'top',
                reverse: false,
                labels: {
                    generateLabels: (chart: ChartJS): LegendItem[] => generateChartLegends(chart),
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label(tooltipItem): string | string[] {
                        const datasetObject = data.datasets[tooltipItem.datasetIndex];
                        const { label } = datasetObject;
                        const value: any = datasetObject.data[tooltipItem.dataIndex];
                        return `${label}: ${numberFormatter.format(value)}`;
                    },
                    title(tooltipItems): string | string[] {
                        const item = tooltipItems.at(0);
                        if (!item) return '';
                        const [ch, fm] = labels[item.dataIndex];
                        return `${ch} - ${fm}`;
                    },
                },
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
        },
        indexAxis: 'y',
        scales: {
            x: {
                stacked: true,
                ticks: {
                    display: false,
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        weight: isAnySelected ? 'bold' : 'normal',
                    },
                },
            },
        },
    };

    const setBgWhitePlugin: Plugin<'bar'> = {
        id: 'setBgWhitePlugin',
        beforeDraw: WhiteBackgroundPlugin,
    };

    return (
        <Box height={chartHeight}>
            <Chart
                // tsc not happy but this code works
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data={data}
                options={chartOptions}
                type="bar"
                ref={ref}
                plugins={[setBgWhitePlugin]}
            />
        </Box>
    );
});
