const BASE_ONBOARDING_CLASSES = 'onboarding onboarding-base-width';
const HIGHLIGHT_CLASS = 'highlighted';

const BACK_BUTTON_CLASS = 'back-button';
const NEXT_BUTTON_CLASS = 'next-button';

export const digitalOnboarding = {
    modals: {
        scenarioGeneration: {
            id: 'scenario-creation-tour',
            title: 'Scenario Creation',
            text: ['This is where we will create scenarios to optimise your media plan.'],
            classes: BASE_ONBOARDING_CLASSES,
            buttons: {
                cancel: {
                    text: 'Exit',
                },
                confirm: {
                    text: 'Next',
                },
            },
        },
        scenarioDetails: {
            id: 'scenario-details-tour',
            title: 'Scenario detailed page',
            text: [
                'This is a summary page of the suggested budget allocation as well as predicted attention performance and attention-adjusted reach curves for a scenario.',
            ],
            classes: BASE_ONBOARDING_CLASSES,
            buttons: {
                cancel: {
                    text: 'Exit',
                },
                confirm: {
                    text: 'Next',
                },
            },
        },
    },
    mediaPlans: {
        baseScenario: {
            id: 'base-scenario',
            title: 'Base scenario',
            text: [
                '<p>A scenario is a simulation of your media plan. This is the base scenario that has been created with the media plan that you initially provided to us at the very start.</p>',
                '<p>Click into it to understand its attention performance!</p>',
            ],
            attachTo: '#scenario-comparison-base-scenario-tour-id',
            classes: `${BASE_ONBOARDING_CLASSES} base-scenario`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: `${BACK_BUTTON_CLASS} display-none`,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioCreation: {
            id: 'scenario-creation',
            title: 'Scenario Creation',
            text: [
                '<p>You can now experiment and create different scenarios by adjusting these parameters, which will influence the scenario optimisation.</p>',
                '<p>When your parameters are all set, click on the Generate Scenarios button to begin the calculation of your scenario.</p>',
            ],
            targetClass: 'scenario-creation-ctn',
            attachTo: '.scenario-creation-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-creation`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioComparison: {
            id: 'scenario-comparison',
            title: 'Scenario Comparison',
            text: [
                '<p>This panel is a collection of all of your scenarios to compare and contrast their different performances and trade-offs.</p>',
            ],
            targetClass: 'scenario-comparison-ctn',
            attachTo: '.scenario-comparison-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-comparison`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        reachCurveComparison: {
            id: 'reach-curve-comparison',
            title: 'Reach curve comparison',
            text: [
                '<p>This is where you can see and compare the reach curve for each channel and generated scenario.</p>',
            ],
            targetClass: 'reach-curve-comparison-ctn',
            attachTo: '.reach-curve-comparison-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} reach-curve-comparison`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioMediaMixComparison: {
            id: 'scenario-media-mix-comparison',
            title: 'Scenario media mix',
            text: ['<p>This is a visual representation of the budget mix of all scenarios.</p>'],
            targetClass: 'scenario-media-mix-comparison-ctn',
            attachTo: '.scenario-media-mix-comparison-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-media-mix-comparison`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        completeScenarioGeneration: {
            id: 'complete-scenario-generation',
            title: 'Congratulations',
            text: ['<p>You’re all set. Enjoy planning your media spend with attentionPLAN!</p>'],
            targetClass: '',
            attachTo: '',
            classes: `${BASE_ONBOARDING_CLASSES} complete-scenario-generation`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Done',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioParameters: {
            id: 'scenario-parameters',
            title: 'Scenario parameters',
            text: [
                '<p>This is a summary of the parameters that were used to generate this scenario.</p>',
            ],
            targetClass: 'scenario-parameters-ctn',
            attachTo: '.scenario-parameters-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-parameters`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioMixDetailedView: {
            id: 'scenario-mix-detailed-view',
            title: 'Scenario mix detailed view',
            text: [
                '<p>The results of the optimisation are displayed here, with the accompanying attention metrics. A format view is also available if you are interested in a further breakdown of the scenario.</p>',
            ],
            targetClass: 'scenario-mix-detailed-view-ctn',
            attachTo: '.scenario-mix-detailed-view-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-mix-detailed-view`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        keyOptimisedMetrics: {
            id: 'key-optimised-metrics',
            title: 'Key optimised metrics',
            text: [
                '<p>These are the key performance metrics of the scenario. If you selected a generated scenario, there will also be a comparison table against the base scenario for these metrics.</p>',
            ],
            targetClass: 'key-optimised-metrics-ctn',
            attachTo: '.key-optimised-metrics-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} key-optimised-metrics`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        attentionAdjustReachCurves: {
            id: 'attention-adjusted-reach-curves',
            title: 'Attention-adjusted reach curves',
            text: [
                '<p>Reach curves are based on your input media plan, and further adjusted using attention. They filter out non-attentive impressions.</p>',
            ],
            targetClass: 'attention-adjusted-reach-curves-ctn',
            attachTo: '.attention-adjusted-reach-curves-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} attention-adjusted-reach-curves`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Next',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
        scenarioMediaMix: {
            id: 'scenario-media-mix',
            title: 'Scenario media mix',
            text: ['<p>This is a visual representation of the budget mix of the scenario.</p>'],
            targetClass: 'scenario-media-mix-ctn',
            attachTo: '.scenario-media-mix-ctn',
            classes: `${BASE_ONBOARDING_CLASSES} scenario-media-mix`,
            highlightClass: HIGHLIGHT_CLASS,
            buttons: {
                cancel: {
                    text: 'Back',
                    classes: BACK_BUTTON_CLASS,
                },
                next: {
                    text: 'Done',
                    classes: NEXT_BUTTON_CLASS,
                },
            },
        },
    },
};
