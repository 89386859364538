import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface UserFormContextProps {
    isEditMode: boolean;
    setEditMode: (mode: boolean) => void;
    errorUpdateUser: boolean;
    setErrorUpdateUser: (isError: boolean) => void;
    updateErrorMessage: string | null;
    setUpdateErrorMessage: (message: string) => void;
}

const UserFormContext = createContext<UserFormContextProps>({
    isEditMode: false,
    setEditMode: (mode: boolean) => {},
    errorUpdateUser: false,
    setErrorUpdateUser: (isError: boolean) => {},
    updateErrorMessage: null,
    setUpdateErrorMessage: (message: string) => {},
});

export const UserFormContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [mode, setMode] = useState(false);
    const [error, setError] = useState(false);
    const [failedMessage, setFailedMessage] = useState<string | null>(null);

    const isEditMode = useMemo(() => mode, [mode]);
    const setEditMode = useCallback((value: boolean) => setMode(value), [setMode]);

    const errorUpdateUser = useMemo(() => error, [error]);
    const setErrorUpdateUser = useCallback((value: boolean) => setError(value), [setError]);

    const updateErrorMessage = useMemo(() => failedMessage, [failedMessage]);
    const setUpdateErrorMessage = useCallback(
        (value: string) => setFailedMessage(value),
        [setFailedMessage],
    );

    const value = useMemo(
        (): UserFormContextProps => ({
            isEditMode,
            setEditMode,
            errorUpdateUser,
            setErrorUpdateUser,
            updateErrorMessage,
            setUpdateErrorMessage,
        }),
        [
            isEditMode,
            setEditMode,
            errorUpdateUser,
            setErrorUpdateUser,
            updateErrorMessage,
            setUpdateErrorMessage,
        ],
    );

    return <UserFormContext.Provider value={value}>{children}</UserFormContext.Provider>;
};

export const useUserFormContext = (): UserFormContextProps => useContext(UserFormContext);
