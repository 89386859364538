import { UserResponseDto } from '@api-clients/account-manager';
import { Avatar, Badge, Heading, HStack } from '@chakra-ui/react';
import { FC } from 'react';

export interface UserBadgeProps {
    user: UserResponseDto;
    organisationId: string;
}

export const UserBadgeSection: FC<UserBadgeProps> = ({ user, organisationId }: UserBadgeProps) => {
    const { firstName, lastName, accountType } = user;
    const username = `${firstName} ${lastName}`;
    const accessLevel = user.organisations?.filter((o) => o.id === organisationId)[0]?.accessLevel;

    return (
        <>
            <HStack spacing={4}>
                <Avatar name={username} />
                <Heading>{username}</Heading>
                <HStack spacing={2}>
                    {accessLevel && (
                        <Badge variant="outline" colorScheme="gray">
                            {accessLevel}
                        </Badge>
                    )}
                </HStack>
            </HStack>
        </>
    );
};
