import { createAppAsyncThunk } from '@/redux/createAppAsyncThunk';
import { ProveCampaign } from '@api-clients/prove/schema/ProveCampaign';
import { ProveLineItem, ProveLineItemVast } from '@api-clients/prove/schema/ProveLineItem';
import { GenerateProveJsTagApi } from '@api-clients/prove/api/GenerateProveJsTagApi';
import { SaveProveLineItemTagApi } from '@api-clients/prove/api/SaveProveLineItemTagApi';
import { GenerateProveVastLinearWrapperTagApi } from '@api-clients/prove/api/GenerateProveVastLinearWrapperTagApi';
import { Configuration } from '@api-clients/runtime';
import { SaveProveLineItemVastApi } from '@api-clients/prove/api/SaveProveLineItemVastApi';
import { CreateProveLineItemApi } from '@api-clients/prove/api/CreateProveLineItemApi';
import { FindProveLineItemsApi } from '@api-clients/prove/api/FindProveLineItemsApi';
import { proveLineItemsDemoData } from '@redux/slices/prove/lineItem/demo/proveLineItems';
import { fileToRowArray } from '@components/atoms/fileUploadContainer/ValidateImportTemplate';

export const findProveLineItemsAsync = createAppAsyncThunk(
    'prove/lineItem/findAll',
    async (
        payload: {
            configuration: Configuration,
            campaign: ProveCampaign
        },
    ) => {
        if (payload.campaign.id === 'demo') {
            return proveLineItemsDemoData;
        }

        return FindProveLineItemsApi.execute(
            payload.configuration,
            payload.campaign,
        );
    },
);

export const createAllProveLineItemsAsync = createAppAsyncThunk(
    'prove/lineItem/createAll',
    async (
        payload: {
            managementConfiguration: Configuration,
            tagConfiguration: Configuration,
            campaign: ProveCampaign,
            lineItems: Array<ProveLineItem>
        },
    ) => {
        const createSingleLineItem = async (lineItem: ProveLineItem): Promise<ProveLineItem> => {
            const createdLineItem = await CreateProveLineItemApi.execute(
                payload.managementConfiguration,
                payload.campaign,
                lineItem,
            );

            if (createdLineItem.status !== 'TAG_REQUIRED') {
                return createdLineItem;
            }

            // we assume JS tags because VAST tags require additional information and therefore status will never be TAG_REQUIRED.
            const generatedTagResponse = await GenerateProveJsTagApi.execute(
                payload.tagConfiguration,
                payload.campaign,
                createdLineItem,
            );

            return SaveProveLineItemTagApi.execute(
                payload.managementConfiguration,
                payload.campaign,
                createdLineItem,
                generatedTagResponse.js!,
            );
        }

        const promises = [] as Array<Promise<ProveLineItem>>;
        for (let i = 0; i < payload.lineItems.length; i++) {
            promises.push(createSingleLineItem(payload.lineItems[i]));
        }

        return Promise.all(promises);
    },
);

export const saveProveLineItemVastAsync = createAppAsyncThunk(
    'prove/lineItem/saveVast',
    async (
        payload: {
            managementConfiguration: Configuration,
            tagConfiguration: Configuration,
            campaign: ProveCampaign,
            lineItem: ProveLineItem,
            lineItemVast: ProveLineItemVast
        },
    ) => {
        const savedLineItem = await SaveProveLineItemVastApi.execute(
            payload.managementConfiguration,
            payload.campaign,
            payload.lineItem,
            payload.lineItemVast,
        );

        if (savedLineItem.status !== 'TAG_REQUIRED') {
            return savedLineItem;
        }

        const generatedTagResponse = await GenerateProveVastLinearWrapperTagApi.execute(
            payload.tagConfiguration,
            payload.campaign,
            savedLineItem,
        );

        return SaveProveLineItemTagApi.execute(
            payload.managementConfiguration,
            payload.campaign,
            savedLineItem,
            generatedTagResponse.vastLinearWrapper!,
        );
    },
);

export const generateProveLineItemJsTagAsync = createAppAsyncThunk(
    'prove/lineItem/generateJsTag',
    async (
        payload: {
            tagConfiguration: Configuration,
            managementConfiguration: Configuration,
            campaign: ProveCampaign,
            lineItem: ProveLineItem
        },
    ) => {
        const generatedTagResponse = await GenerateProveJsTagApi.execute(
            payload.tagConfiguration,
            payload.campaign,
            payload.lineItem,
        );

        return SaveProveLineItemTagApi.execute(
            payload.managementConfiguration,
            payload.campaign,
            payload.lineItem,
            generatedTagResponse.js!,
        );
    },
);

export const generateProveLineItemVastLinearWrapperTagAsync = createAppAsyncThunk(
    'prove/lineItem/generateVastLinearWrapperTag',
    async (
        payload: {
            tagConfiguration: Configuration,
            managementConfiguration: Configuration,
            campaign: ProveCampaign,
            lineItem: ProveLineItem
        },
    ) => {
        const generatedTagResponse = await GenerateProveVastLinearWrapperTagApi.execute(
            payload.tagConfiguration,
            payload.campaign,
            payload.lineItem,
        );

        return SaveProveLineItemTagApi.execute(
            payload.managementConfiguration,
            payload.campaign,
            payload.lineItem,
            generatedTagResponse.vastLinearWrapper!,
        );
    },
);
