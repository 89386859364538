/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdFormatType } from './AdFormatType';
import {
    AdFormatTypeFromJSON,
    AdFormatTypeFromJSONTyped,
    AdFormatTypeToJSON,
} from './AdFormatType';

/**
 *
 * @export
 * @interface FormatResponseDto
 */
export interface FormatResponseDto {
    /**
     *
     * @type {string}
     * @memberof FormatResponseDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatResponseDto
     */
    adFormatName?: string | null;
    /**
     *
     * @type {AdFormatType}
     * @memberof FormatResponseDto
     */
    adFormatType?: AdFormatType;
}

/**
 * Check if a given object implements the FormatResponseDto interface.
 */
export function instanceOfFormatResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatResponseDtoFromJSON(json: any): FormatResponseDto {
    return FormatResponseDtoFromJSONTyped(json, false);
}

export function FormatResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        adFormatName: !exists(json, 'ad_format_name') ? undefined : json['ad_format_name'],
        adFormatType: !exists(json, 'ad_format_type')
            ? undefined
            : AdFormatTypeFromJSON(json['ad_format_type']),
    };
}

export function FormatResponseDtoToJSON(value?: FormatResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_code: value.adFormatCode,
        ad_format_name: value.adFormatName,
        ad_format_type: AdFormatTypeToJSON(value.adFormatType),
    };
}
