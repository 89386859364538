import { AdFormatsApi } from '@api-clients/attention-data/apis/AdFormatsApi';
import { AdFormatResponseDto } from '@api-clients/attention-data/models/AdFormatResponseDto';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useGetAllAdFormatsHook = () => {
    getAllAdFormats: () => Promise<AdFormatResponseDto[]>;
};

export const useGetAllAdFormats: useGetAllAdFormatsHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllAdFormats(): Promise<AdFormatResponseDto[]> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new AdFormatsApi(config);
            return await api.getAllAdFormats();
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    };

    return { getAllAdFormats };
};