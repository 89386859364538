/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdjustmentToleranceLevel } from './AdjustmentToleranceLevel';
import {
    AdjustmentToleranceLevelFromJSON,
    AdjustmentToleranceLevelFromJSONTyped,
    AdjustmentToleranceLevelToJSON,
} from './AdjustmentToleranceLevel';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { BudgetConstraintType } from './BudgetConstraintType';
import {
    BudgetConstraintTypeFromJSON,
    BudgetConstraintTypeFromJSONTyped,
    BudgetConstraintTypeToJSON,
} from './BudgetConstraintType';
import type { ChannelMixResponseDto } from './ChannelMixResponseDto';
import {
    ChannelMixResponseDtoFromJSON,
    ChannelMixResponseDtoFromJSONTyped,
    ChannelMixResponseDtoToJSON,
} from './ChannelMixResponseDto';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { OptimisationStrategy } from './OptimisationStrategy';
import {
    OptimisationStrategyFromJSON,
    OptimisationStrategyFromJSONTyped,
    OptimisationStrategyToJSON,
} from './OptimisationStrategy';
import type { ScenarioStatus } from './ScenarioStatus';
import {
    ScenarioStatusFromJSON,
    ScenarioStatusFromJSONTyped,
    ScenarioStatusToJSON,
} from './ScenarioStatus';

/**
 *
 * @export
 * @interface GetScenarioResponseDto
 */
export interface GetScenarioResponseDto {
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    activeAttentionPerReach?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    activeAttentionPerImpression?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    activeAttentionVolumeSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    passiveAttentionPerReach?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    passiveAttentionPerImpression?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    passiveAttentionVolumeSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    shortTermLift?: number;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    longTermLift?: number;
    /**
     *
     * @type {Array<ChannelMixResponseDto>}
     * @memberof GetScenarioResponseDto
     */
    channelMix?: Array<ChannelMixResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioResponseDto
     */
    scenarioName?: string | null;
    /**
     *
     * @type {number}
     * @memberof GetScenarioResponseDto
     */
    budget?: number;
    /**
     *
     * @type {OptimisationStrategy}
     * @memberof GetScenarioResponseDto
     */
    optimisationStrategy?: OptimisationStrategy;
    /**
     *
     * @type {ScenarioStatus}
     * @memberof GetScenarioResponseDto
     */
    scenarioStatus?: ScenarioStatus;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioResponseDto
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioResponseDto
     */
    endDate?: Date | null;
    /**
     *
     * @type {BudgetConstraintType}
     * @memberof GetScenarioResponseDto
     */
    budgetConstraintType?: BudgetConstraintType;
    /**
     *
     * @type {AdjustmentToleranceLevel}
     * @memberof GetScenarioResponseDto
     */
    adjustmentToleranceLevel?: AdjustmentToleranceLevel;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof GetScenarioResponseDto
     */
    countries?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof GetScenarioResponseDto
     */
    ageGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof GetScenarioResponseDto
     */
    genders?: Array<Gender> | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioResponseDto
     */
    scenarioShareLink?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetScenarioResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof GetScenarioResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the GetScenarioResponseDto interface.
 */
export function instanceOfGetScenarioResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetScenarioResponseDtoFromJSON(json: any): GetScenarioResponseDto {
    return GetScenarioResponseDtoFromJSONTyped(json, false);
}

export function GetScenarioResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetScenarioResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activeAttentionPerReach: !exists(json, 'active_attention_per_reach')
            ? undefined
            : json['active_attention_per_reach'],
        activeAttentionPerImpression: !exists(json, 'active_attention_per_impression')
            ? undefined
            : json['active_attention_per_impression'],
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : json['active_attention_volume_seconds'],
        passiveAttentionPerReach: !exists(json, 'passive_attention_per_reach')
            ? undefined
            : json['passive_attention_per_reach'],
        passiveAttentionPerImpression: !exists(json, 'passive_attention_per_impression')
            ? undefined
            : json['passive_attention_per_impression'],
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : json['passive_attention_volume_seconds'],
        shortTermLift: !exists(json, 'short_term_lift') ? undefined : json['short_term_lift'],
        longTermLift: !exists(json, 'long_term_lift') ? undefined : json['long_term_lift'],
        channelMix: !exists(json, 'channel_mix')
            ? undefined
            : json['channel_mix'] === null
            ? null
            : (json['channel_mix'] as Array<any>).map(ChannelMixResponseDtoFromJSON),
        scenarioName: !exists(json, 'scenario_name') ? undefined : json['scenario_name'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        optimisationStrategy: !exists(json, 'optimisation_strategy')
            ? undefined
            : OptimisationStrategyFromJSON(json['optimisation_strategy']),
        scenarioStatus: !exists(json, 'scenario_status')
            ? undefined
            : ScenarioStatusFromJSON(json['scenario_status']),
        startDate: !exists(json, 'start_date')
            ? undefined
            : json['start_date'] === null
            ? null
            : new Date(json['start_date']),
        endDate: !exists(json, 'end_date')
            ? undefined
            : json['end_date'] === null
            ? null
            : new Date(json['end_date']),
        budgetConstraintType: !exists(json, 'budget_constraint_type')
            ? undefined
            : BudgetConstraintTypeFromJSON(json['budget_constraint_type']),
        adjustmentToleranceLevel: !exists(json, 'adjustment_tolerance_level')
            ? undefined
            : AdjustmentToleranceLevelFromJSON(json['adjustment_tolerance_level']),
        countries: !exists(json, 'countries')
            ? undefined
            : json['countries'] === null
            ? null
            : (json['countries'] as Array<any>).map(CountryCodeIso2FromJSON),
        ageGroups: !exists(json, 'age_groups')
            ? undefined
            : json['age_groups'] === null
            ? null
            : (json['age_groups'] as Array<any>).map(AgeGroupFromJSON),
        genders: !exists(json, 'genders')
            ? undefined
            : json['genders'] === null
            ? null
            : (json['genders'] as Array<any>).map(GenderFromJSON),
        scenarioShareLink: !exists(json, 'scenario_share_link')
            ? undefined
            : json['scenario_share_link'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function GetScenarioResponseDtoToJSON(value?: GetScenarioResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        active_attention_per_reach: value.activeAttentionPerReach,
        active_attention_per_impression: value.activeAttentionPerImpression,
        active_attention_volume_seconds: value.activeAttentionVolumeSeconds,
        passive_attention_per_reach: value.passiveAttentionPerReach,
        passive_attention_per_impression: value.passiveAttentionPerImpression,
        passive_attention_volume_seconds: value.passiveAttentionVolumeSeconds,
        short_term_lift: value.shortTermLift,
        long_term_lift: value.longTermLift,
        channel_mix:
            value.channelMix === undefined
                ? undefined
                : value.channelMix === null
                ? null
                : (value.channelMix as Array<any>).map(ChannelMixResponseDtoToJSON),
        scenario_name: value.scenarioName,
        budget: value.budget,
        optimisation_strategy: OptimisationStrategyToJSON(value.optimisationStrategy),
        scenario_status: ScenarioStatusToJSON(value.scenarioStatus),
        start_date:
            value.startDate === undefined
                ? undefined
                : value.startDate === null
                ? null
                : value.startDate.toISOString(),
        end_date:
            value.endDate === undefined
                ? undefined
                : value.endDate === null
                ? null
                : value.endDate.toISOString(),
        budget_constraint_type: BudgetConstraintTypeToJSON(value.budgetConstraintType),
        adjustment_tolerance_level: AdjustmentToleranceLevelToJSON(value.adjustmentToleranceLevel),
        countries:
            value.countries === undefined
                ? undefined
                : value.countries === null
                ? null
                : (value.countries as Array<any>).map(CountryCodeIso2ToJSON),
        age_groups:
            value.ageGroups === undefined
                ? undefined
                : value.ageGroups === null
                ? null
                : (value.ageGroups as Array<any>).map(AgeGroupToJSON),
        genders:
            value.genders === undefined
                ? undefined
                : value.genders === null
                ? null
                : (value.genders as Array<any>).map(GenderToJSON),
        scenario_share_link: value.scenarioShareLink,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
