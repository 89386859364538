/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface CreateOauthApplicationResponseDto
 */
export interface CreateOauthApplicationResponseDto {
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationResponseDto
     */
    applicationName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationResponseDto
     */
    clientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateOauthApplicationResponseDto
     */
    clientSecret?: string | null;
}

/**
 * Check if a given object implements the CreateOauthApplicationResponseDto interface.
 */
export function instanceOfCreateOauthApplicationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOauthApplicationResponseDtoFromJSON(
    json: any,
): CreateOauthApplicationResponseDto {
    return CreateOauthApplicationResponseDtoFromJSONTyped(json, false);
}

export function CreateOauthApplicationResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateOauthApplicationResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        applicationName: !exists(json, 'application_name') ? undefined : json['application_name'],
        clientId: !exists(json, 'client_id') ? undefined : json['client_id'],
        clientSecret: !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}

export function CreateOauthApplicationResponseDtoToJSON(
    value?: CreateOauthApplicationResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        application_name: value.applicationName,
        client_id: value.clientId,
        client_secret: value.clientSecret,
    };
}
