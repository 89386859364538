import {
    GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ScenarioApi,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetScenarioComparisonHook = () => {
    getScenarioComparison: (
        requestParams: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ) => Promise<ScenarioWithComparisonResponseDto>;
};

export const useGetScenarioComparison: useGetScenarioComparisonHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    const getScenarioComparison = async (
        requestParameters: GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ) => {
        try {
            const config = await getMediaPlanConfig();
            const api = new ScenarioApi(config);
            return await api.getAScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
                requestParameters,
            );
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getScenarioComparison };
};
