/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const RoleTitle = {
    MediaPlanner: 'media_planner',
    MediaBuyer: 'media_buyer',
    MediaStrategist: 'media_strategist',
    Manager: 'manager',
    Director: 'director',
    Other: 'other',
} as const;
export type RoleTitle = typeof RoleTitle[keyof typeof RoleTitle];

export const allRoleTitles = Object.values(RoleTitle);

export function RoleTitleFromJSON(json: any): RoleTitle {
    return RoleTitleFromJSONTyped(json, false);
}

export function RoleTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleTitle {
    return json as RoleTitle;
}

export function RoleTitleToJSON(value?: RoleTitle | null): any {
    return value as any;
}
