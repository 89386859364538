/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface RawImportedDataResponseDto
 */
export interface RawImportedDataResponseDto {
    /**
     *
     * @type {string}
     * @memberof RawImportedDataResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    budget?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    cpm?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    reachPercentage?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    totalAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    impressions?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    targetAudience?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    frequency?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    trp?: number | null;
    /**
     *
     * @type {number}
     * @memberof RawImportedDataResponseDto
     */
    cpp?: number | null;
}

/**
 * Check if a given object implements the RawImportedDataResponseDto interface.
 */
export function instanceOfRawImportedDataResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RawImportedDataResponseDtoFromJSON(json: any): RawImportedDataResponseDto {
    return RawImportedDataResponseDtoFromJSONTyped(json, false);
}

export function RawImportedDataResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RawImportedDataResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        reachPercentage: !exists(json, 'reach_percentage') ? undefined : json['reach_percentage'],
        totalAudience: !exists(json, 'total_audience') ? undefined : json['total_audience'],
        impressions: !exists(json, 'impressions') ? undefined : json['impressions'],
        targetAudience: !exists(json, 'target_audience') ? undefined : json['target_audience'],
        frequency: !exists(json, 'frequency') ? undefined : json['frequency'],
        trp: !exists(json, 'trp') ? undefined : json['trp'],
        cpp: !exists(json, 'cpp') ? undefined : json['cpp'],
    };
}

export function RawImportedDataResponseDtoToJSON(value?: RawImportedDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        channel_name: value.channelName,
        budget: value.budget,
        cpm: value.cpm,
        reach_percentage: value.reachPercentage,
        total_audience: value.totalAudience,
        impressions: value.impressions,
        target_audience: value.targetAudience,
        frequency: value.frequency,
        trp: value.trp,
        cpp: value.cpp,
    };
}
