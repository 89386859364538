export const toAppropriateDecimalPlace = (valueInUnit: number): string => {
    if (valueInUnit < 10) {
        return valueInUnit.toFixed(2);
    }
    if (valueInUnit < 100) {
        return valueInUnit.toFixed(1);
    }
    return valueInUnit.toFixed(0);
};

/**
 * converts any number to 3 digits appended by its unit.
 * Units are K for thousands, M for millions, B for billions
 * will always display 3 digits, either in the format d.dd, dd.d, or ddd depending on the size of the number relative to its unit
 * @param value Number to be converted.
 */
export const getShortenedNumber = (value: number): string => {
    const numberFormat = new Intl.NumberFormat('en', {
        notation: 'compact',
        minimumSignificantDigits: 3,
        maximumSignificantDigits: 3,
    });
    return numberFormat.format(value);
};

/**
 *
 * @param n number to be modified
 * @param fractionDigits Number of digits after the decimal point. defaults to 2
 * @returns
 */
export const toLocaleFixed = (n: number, fractionDigits = 2): string => {
    return n.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    });
};
