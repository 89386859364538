import { CampaignApi, DeleteCampaignRequest } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface DeleteCampaignHook {
    deleteCampaign: (campaignId: string) => Promise<number>;
}

type useDeleteCampaignProps = () => DeleteCampaignHook;

export const useDeleteCampaign: useDeleteCampaignProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteCampaign(campaignId: string): Promise<number> {
        try {
            const config = await getMediaPlanConfig();
            const request: DeleteCampaignRequest = {
                campaignId,
            };

            const api = new CampaignApi(config);
            return await api.deleteCampaign(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        deleteCampaign,
    };
};
