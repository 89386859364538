import { FC, useMemo } from 'react';
import { UserResponseDto } from '@api-clients/account-manager';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Icon,
    Spacer,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import { getListRenderKey, snakeCaseToCapitalised, isValidValueString } from '@shared/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { DetailSection, DetailListItem, TextRender } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { useUserFormContext } from './UserInfoFormContext';

interface UserInfoViewModeProps {
    user: UserResponseDto;
}

const EmailStyleOption = () => {
    return (
        <Center>
            <Icon
                as={CheckCircleIcon}
                marginLeft="1rem"
                w="1.25rem"
                h="1.25rem"
                color="green.600"
                flex="1"
            />
        </Center>
    );
};

export const UserInformationViewMode: FC<UserInfoViewModeProps> = ({ user }) => {
    const { t } = useTranslation();
    const { setEditMode } = useUserFormContext();
    const { firstName, lastName, roleTitle, emailAddress, countryPhoneCode, phoneNumber } = user;

    const phoneNumberWithCountryCode =
        isValidValueString(countryPhoneCode) && isValidValueString(phoneNumber)
            ? `(+${countryPhoneCode}) ${phoneNumber}`
            : '';

    const renderDetails: DetailListItem[] = useMemo(
        () => [
            {
                label: t('labels.forms.accountDetails.firstName.field'),
                renderItem: <TextRender text={firstName} />,
            },
            {
                label: t('labels.forms.accountDetails.lastName.field'),
                renderItem: <TextRender text={lastName} />,
            },
            {
                label: t('labels.forms.accountDetails.roleTitle.field'),
                renderItem: <TextRender text={snakeCaseToCapitalised(roleTitle!)} />,
            },
            {
                label: t('labels.forms.accountDetails.email.field'),
                renderItem: <TextRender text={emailAddress} option={<EmailStyleOption />} />,
            },
            {
                label: t('labels.forms.accountDetails.phoneNumber.field'),
                renderItem: <TextRender text={phoneNumberWithCountryCode} />,
            },
        ],
        [user],
    );

    return (
        <>
            <Flex marginBottom="4">
                <Center>
                    <Heading size="md" color="gray.600">
                        {t('labels.sections.accountDetails.heading')}
                    </Heading>
                </Center>
                <Spacer />
                <Box>
                    <Button size="sm" onClick={() => setEditMode(true)}>
                        {t('labels.sections.accountDetails.buttonName.viewMode')}
                    </Button>
                </Box>
            </Flex>
            <Box bg="white" borderRadius="lg">
                <VStack
                    divider={<StackDivider />}
                    align="stretch"
                    borderWidth="thin"
                    borderRadius="lg"
                    spacing="0"
                >
                    {renderDetails.map((item, index) => (
                        <DetailSection
                            key={getListRenderKey(index, item.label)}
                            label={item.label}
                            renderItem={item.renderItem}
                        />
                    ))}
                </VStack>
            </Box>
        </>
    );
};
