import codes from 'country-calling-code';

export interface CountryInfo {
    country: string;
    countryCode: string;
    phoneCode: string;
}

function getAllCountryInfo(): CountryInfo[] {
    const countryOptions: CountryInfo[] = [];

    codes.map((item) => {
        return item.countryCodes.map((code) => {
            return countryOptions.push({
                country: item.country,
                countryCode: item.isoCode2,
                phoneCode: code,
            });
        });
    });

    return countryOptions;
}

export const allCountriesInfo = getAllCountryInfo();

export const allCountryCodes = codes.map((code) => code.isoCode2);

export type CountryCode = typeof allCountryCodes[number];

export const allCountryPhoneCodes = allCountriesInfo.map((code) => code.phoneCode);

export type CountryPhoneCode = typeof allCountryPhoneCodes[number];

export function isCountryCode(code: string): code is CountryCode {
    return (allCountryCodes as unknown as Array<string>).includes(code);
}

export function findCountryPhoneCode(countryCode: CountryCode): string {
    const countryInfos: CountryInfo[] = allCountriesInfo.filter(
        (countryInfo) => countryInfo.countryCode === countryCode,
    );
    if (countryInfos !== undefined) {
        return countryInfos[0].phoneCode;
    }
    return '';
}

export function findCountryName(countryCode: CountryCode): string {
    const countryInfos: CountryInfo[] = allCountriesInfo.filter(
        (countryInfo) => countryInfo.countryCode === countryCode.toUpperCase(),
    );
    if (countryInfos !== undefined) {
        return countryInfos[0].country;
    }
    return '';
}
