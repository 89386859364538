/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { OauthApplicationStatus } from './OauthApplicationStatus';
import {
    OauthApplicationStatusFromJSON,
    OauthApplicationStatusFromJSONTyped,
    OauthApplicationStatusToJSON,
} from './OauthApplicationStatus';

/**
 *
 * @export
 * @interface UpdateOauthApplicationRequestDto
 */
export interface UpdateOauthApplicationRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateOauthApplicationRequestDto
     */
    applicationName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateOauthApplicationRequestDto
     */
    organisationName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdateOauthApplicationRequestDto
     */
    secretRotated?: boolean;
    /**
     *
     * @type {OauthApplicationStatus}
     * @memberof UpdateOauthApplicationRequestDto
     */
    status?: OauthApplicationStatus;
}

/**
 * Check if a given object implements the UpdateOauthApplicationRequestDto interface.
 */
export function instanceOfUpdateOauthApplicationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOauthApplicationRequestDtoFromJSON(
    json: any,
): UpdateOauthApplicationRequestDto {
    return UpdateOauthApplicationRequestDtoFromJSONTyped(json, false);
}

export function UpdateOauthApplicationRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateOauthApplicationRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        applicationName: !exists(json, 'application_name') ? undefined : json['application_name'],
        organisationName: !exists(json, 'organisation_name')
            ? undefined
            : json['organisation_name'],
        secretRotated: !exists(json, 'secret_rotated') ? undefined : json['secret_rotated'],
        status: !exists(json, 'status')
            ? undefined
            : OauthApplicationStatusFromJSON(json['status']),
    };
}

export function UpdateOauthApplicationRequestDtoToJSON(
    value?: UpdateOauthApplicationRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        application_name: value.applicationName,
        organisation_name: value.organisationName,
        secret_rotated: value.secretRotated,
        status: OauthApplicationStatusToJSON(value.status),
    };
}
