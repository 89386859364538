/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface AdClientDto
 */
export interface AdClientDto {
    /**
     * 
     * @type {string}
     * @memberof AdClientDto
     */
    adClientName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdClientDto
     */
    primaryContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdClientDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdClientDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdClientDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the AdClientDto interface.
 */
export function instanceOfAdClientDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdClientDtoFromJSON(json: any): AdClientDto {
    return AdClientDtoFromJSONTyped(json, false);
}

export function AdClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adClientName': !exists(json, 'ad_client_name') ? undefined : json['ad_client_name'],
        'primaryContactName': !exists(json, 'primary_contact_name') ? undefined : json['primary_contact_name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function AdClientDtoToJSON(value?: AdClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ad_client_name': value.adClientName,
        'primary_contact_name': value.primaryContactName,
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

