import { FC, useState } from 'react';
import {
    CreativeResponseDto,
    DecaySecondResponseDto,
    FormatDecayResponseDto,
} from '@api-clients/attention-data';
import { Flex, Text } from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { Column, DataTable, Row } from '@components/molecules';
import { toLocaleFixed } from '@shared/utils';
import { CreativeRecommendationSortOptionValue } from '@apps/attention360/pages/strategyReports/CreativeRecommendationsSection';
import { RetentionRateTooltip } from '@apps/attention360/pages/strategyReportTooltip';
import { InlineChart } from './InlineChart';
import { numberFormatter } from './StrategyReportUtil';

interface DataRow {
    adChannelCode: string;
    adChannelName: string;
    adFormatCode: string;
    adFormatName: string;
    minDuration: number;
    maxDuration: number;
    retentionRate: number;
    aaForBrandedMoments?: number;
    paForBrandedMoments?: number;
    decayCurve: Array<DecaySecondResponseDto>;
    isSelected: boolean;
}

export interface AllFormatsViewProps {
    selectedCreative?: CreativeResponseDto;
    showOnlySelectedFormats?: boolean;
    formatDecayResponses?: FormatDecayResponseDto[];
    selectedSortOption: CreativeRecommendationSortOptionValue;
    selectedCreativeSortOption: CreativeRecommendationSortOptionValue;
    dataSource: Row<DataRow>[];
}

export const AllFormatsView: FC<AllFormatsViewProps> = ({
    selectedCreative,
    showOnlySelectedFormats = false,
    formatDecayResponses,
    selectedCreativeSortOption,
    selectedSortOption,
    dataSource,
}) => {
    const [hoveredRow, setHoveredRow] = useState<DataRow | null>(null);

    const brandedMomentsColumns: Array<Column<DataRow>> = [
        {
            title: 'Active attention on branded moments (s)',
            dataIndex: 'aaForBrandedMoments',
            isNumeric: true,
            key: 'aaForBrandedMoments',
            render: (row) => (
                <>
                    {row.aaForBrandedMoments !== undefined
                        ? toLocaleFixed(row.aaForBrandedMoments)
                        : 'N/A'}
                </>
            ),
        },
        {
            title: 'Passive attention on branded moments (s)',
            dataIndex: 'paForBrandedMoments',
            isNumeric: true,
            key: 'paForBrandedMoments',
            render: (row) => (
                <>
                    {row.paForBrandedMoments !== undefined
                        ? toLocaleFixed(row.paForBrandedMoments)
                        : 'N/A'}
                </>
            ),
        },
    ];

    const columns: Array<Column<DataRow>> = [
        {
            title: 'Channel',
            dataIndex: 'adChannelName',
            isNumeric: false,
            key: 'channelName',
            render: (row) => {
                const selectedTextStyle = {
                    color: '#191919',
                    fontWeight: 600,
                };

                const unselectedTextStyle = {
                    color: '#4A4B4D',
                    fontWeight: 500,
                };

                return (
                    <Flex alignItems="center">
                        <Text
                            as="span"
                            style={row.isSelected ? selectedTextStyle : unselectedTextStyle}
                        >
                            {row.adChannelName}
                        </Text>
                    </Flex>
                );
            },
        },
        {
            title: 'Format',
            dataIndex: 'adFormatName',
            isNumeric: false,
            key: 'adFormatName',
            render: (row) => {
                const baseStyle = {
                    display: 'inline-block',
                    marginRight: '8px',
                };

                const selectedDotStyle = {
                    ...baseStyle,
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#00AC59',
                    borderRadius: '50%',
                };

                return (
                    <Flex alignItems="center">
                        {row.isSelected && <Text as="span" style={selectedDotStyle} />}
                        <Text as="span">{row.adFormatName}</Text>
                    </Flex>
                );
            },
        },
        {
            title: 'Min duration (s)',
            dataIndex: 'minDuration',
            isNumeric: true,
            key: 'minDuration',
        },
        {
            title: 'Max duration (s)',
            dataIndex: 'maxDuration',
            isNumeric: true,
            key: 'maxDuration',
        },
        ...(selectedCreative ? brandedMomentsColumns : []),
        {
            title: 'Retention rate',
            dataIndex: 'retentionRate',
            isNumeric: true,
            key: 'retentionRate',
            render: (row) => {
                return <>{row.retentionRate ? numberFormatter.format(row.retentionRate) : 'N/A'}</>;
            },
            tooltip: <RetentionRateTooltip />,
        },
        {
            title: 'Decay curve',
            dataIndex: 'decayCurve',
            isNumeric: false,
            key: 'decayCurve',
            render: (row) =>
                row.decayCurve.length === 0 ? (
                    <span>N/A</span>
                ) : (
                    <InlineChart entries={row.decayCurve} />
                ),
            tooltip: 'A graph of how viewers pay attention over the viewing session',
        },
    ];

    return (
        <>
            <AsyncCard isLoading={!formatDecayResponses} mt="1rem">
                {formatDecayResponses && (
                    <DataTable
                        dataSource={dataSource}
                        columns={columns}
                        onRow={(row) => ({
                            onMouseEnter: () => setHoveredRow(row),
                            onMouseLeave: () => setHoveredRow(null),
                            sx: {
                                cursor: 'pointer',
                                bg: hoveredRow === row ? 'green.50' : 'white',
                            },
                        })}
                    />
                )}
            </AsyncCard>
        </>
    );
};
