import { ComponentStyleConfig } from '@chakra-ui/react';

export const Menu: ComponentStyleConfig = {
    parts: ['menu', 'button'],
    baseStyle: {
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0.5,
            _hover: {
                bg: 'blue.300',
            },
        },
    },
};
