export const inviteMessages = {
    user: {
        success: {
            title: 'An invitation has been sent.',
            description: 'has been invited to',
        },
        error: {
            title: 'An invitation has not been sent.',
            description: 'An error has occurred.',
        },
    },
    client: {
        success: {
            title: 'New client has been created.',
            description: 'has been added to',
        },
        error: {
            title: 'New client has not created.',
            description: 'An error has occurred.',
        },
    },
};
