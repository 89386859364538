import { Checkbox, Th, Tr } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction } from 'react';
import type { RawImportedDataRequestDto } from '@api-clients/media-plan';
import type { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import type { TableHeaderType } from './CampaignCreationTable';
import TableHeaderCell from './TableHeaderCell';

type TableHeaderProp = {
    tableHeaders: TableHeaderType[];
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
};
const TableHeader: FC<TableHeaderProp> = ({ tableHeaders, tableData, setTableData }) => {
    const isAllRowsSelected = tableData.every((row) => row.isChecked);
    const isIndeterminate = tableData.some((row) => row.isChecked) && !isAllRowsSelected;
    const toggleSelectAllRows = () => {
        if (isAllRowsSelected) {
            // uncheck all, include all the adformats
            setTableData((old) =>
                old.map((row) => ({
                    ...row,
                    isChecked: false,
                    adFormats: row.adFormats?.map((f) => ({ ...f, isChecked: false })),
                })),
            );
            return;
        }
        // otherwise check all rows and all formats
        setTableData((old) =>
            old.map((row) => ({
                ...row,
                isChecked: true,
                adFormats: row.adFormats?.map((f) => ({ ...f, isChecked: true })),
            })),
        );
    };
    return (
        <Tr backgroundColor="gray.50">
            <Th>
                <Checkbox
                    isChecked={isAllRowsSelected}
                    onChange={(_) => toggleSelectAllRows()}
                    isIndeterminate={isIndeterminate}
                />
            </Th>
            {tableHeaders.map((header) => (
                <TableHeaderCell
                    key={header.id}
                    header={header}
                    setTableData={setTableData}
                    tableData={tableData}
                />
            ))}
        </Tr>
    );
};
export default TableHeader;
