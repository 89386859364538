import { RawImportedDataRequestDto } from '@api-clients/media-plan';
import { TableDataType } from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';

export const recalculateRow = (row: RawImportedDataRequestDto) => {
    if (row.cpm && row.cpm > 0) {
        row.impressions = ((row.budget ?? 0) / row.cpm) * 1000;
    }
    if (row.frequency && row.frequency >= 1) {
        row.targetAudience = (row.impressions ?? 0) / row.frequency;
    } else {
        row.targetAudience = 0;
    }
    if (row.totalAudience && row.totalAudience >= 1) {
        row.reachPercentage = row.targetAudience / row.totalAudience;
    } else {
        row.reachPercentage = 0;
    }
    row.trp = row.reachPercentage * (row.frequency ?? 0) * 100;
    if (row.trp && row.trp > 0) {
        row.cpp = (row.budget ?? 0) / row.trp;
    } else {
        row.cpp = 0;
    }
};

// formula for calculate avg cpm when format level mix is enabled
// (format A CPM * format A impressions + format B CPM * format B impressions) / (format A impressions + format B impressions) = channel average CPM
export const recalculateCpm = (row: TableDataType) => {
    let totalFormatLevelImpressions = 0;
    let totalFormatLevelCpmTimesImpressions = 0;
    for (let i = 0; i < (row.adFormats?.length ?? 0); i++) {
        const format = row.adFormats?.at(i);
        if (!format) {
            // eslint-disable-next-line
            continue;
        }
        const budget = format.budget ?? 0;
        const formatImpression = format.cpm && format.cpm > 0 ? (budget / format.cpm) * 1000 : 0;
        totalFormatLevelImpressions += formatImpression;
        totalFormatLevelCpmTimesImpressions +=
            format.cpm && format.cpm > 0 ? format.cpm * formatImpression : 0;
    }
    if (totalFormatLevelImpressions === 0) {
        return;
    }
    row.cpm = totalFormatLevelCpmTimesImpressions / totalFormatLevelImpressions;
};
