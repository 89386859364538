export const navigation = {
    sidebar: {
        dashboard: 'Dashboard',
        mediaPlanning: 'Media plans',
        analyseCampaign: 'Analyse campaign',
        manageCampaigns: 'Manage campaigns',
        organisation: 'Organisation',
    },
    topbar: {
        settings: {
            manageOrganisation: 'Manage organisation',
            manageClients: 'Manage clients',
            manageIntegrations: 'Manage integrations',
        },
        profile: { accountDetails: 'Account details', logout: 'Log out' },
    },
};
