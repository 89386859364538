/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ScenarioAdFormatRequestDto
 */
export interface ScenarioAdFormatRequestDto {
    /**
     *
     * @type {string}
     * @memberof ScenarioAdFormatRequestDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioAdFormatRequestDto
     */
    cpm?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof ScenarioAdFormatRequestDto
     */
    isChecked?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioAdFormatRequestDto
     */
    budget?: number | null;
}

/**
 * Check if a given object implements the ScenarioAdFormatRequestDto interface.
 */
export function instanceOfScenarioAdFormatRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioAdFormatRequestDtoFromJSON(json: any): ScenarioAdFormatRequestDto {
    return ScenarioAdFormatRequestDtoFromJSONTyped(json, false);
}

export function ScenarioAdFormatRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioAdFormatRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        isChecked: !exists(json, 'is_checked') ? undefined : json['is_checked'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
    };
}

export function ScenarioAdFormatRequestDtoToJSON(value?: ScenarioAdFormatRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_code: value.adFormatCode,
        cpm: value.cpm,
        is_checked: value.isChecked,
        budget: value.budget,
    };
}
