import { BackButton } from '@apps/attention360/atoms';
import { Center, Heading, SkeletonText, Stack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface CampaignHeaderSectionForSharingProps {
    title: string;
    subtitle?: string;
    lastModified?: Date;
    isLoading?: boolean;
}

export const CampaignHeaderSectionForSharing: FC<CampaignHeaderSectionForSharingProps> = ({
    title,
    subtitle,
    lastModified,
    isLoading,
}) => {
    const { t } = useTranslation('mediaPlans');

    return (
        <Stack direction="row">
            <VStack
                minW="8rem"
                overflowX="hidden"
                align="left"
                spacing={0}
                marginRight="1rem"
                minH="3.125rem"
            >
                {isLoading ? (
                    <Center marginTop="0.5rem">
                        <SkeletonText width="15rem" noOfLines={2} />
                    </Center>
                ) : (
                    <>
                        <Heading textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {title}
                        </Heading>
                        <Text
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            color="gray.500"
                            fontSize="sm"
                        >
                            {subtitle && `${subtitle} • `}
                            {t('mediaPlanning.scenarioCreation.dateLabel')}{' '}
                            {lastModified?.toLocaleString()}
                        </Text>
                    </>
                )}
            </VStack>
        </Stack>
    );
};
