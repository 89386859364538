/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type { AuthResponseDto, ChangePasswordRequestDto } from '../models';
import {
    AuthResponseDtoFromJSON,
    AuthResponseDtoToJSON,
    ChangePasswordRequestDtoFromJSON,
    ChangePasswordRequestDtoToJSON,
} from '../models';

export interface ChangePasswordRequest {
    changePasswordRequestDto?: ChangePasswordRequestDto;
}

/**
 * AuthApi - interface
 *
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     *
     * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    changePasswordRaw(
        requestParameters: ChangePasswordRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AuthResponseDto>>;

    /**
     */
    changePassword(
        requestParameters: ChangePasswordRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AuthResponseDto>;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {
    /**
     */
    async changePasswordRaw(
        requestParameters: ChangePasswordRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AuthResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request(
            {
                path: `/api/v1/auth/change-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ChangePasswordRequestDtoToJSON(requestParameters.changePasswordRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            AuthResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async changePassword(
        requestParameters: ChangePasswordRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AuthResponseDto> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
