import { Center, Icon, IconButton, Tooltip, useBoolean } from '@chakra-ui/react';
import { Row } from '@components/molecules';
import { TrashIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export interface ScenarioDeleteCellProps {
    onScenarioDelete?: (scenarioId: string) => Promise<void>;
    row: Row<{ scenarioName: string }>;
}

export const ScenarioDeleteCell: FC<ScenarioDeleteCellProps> = ({ onScenarioDelete, row }) => {
    const [isDeleting, setIsDeleting] = useBoolean();

    return (
        <Center>
            <Tooltip label="Delete scenario">
                {onScenarioDelete && (
                    <IconButton
                        aria-label={`delete-${row.scenarioName}`}
                        isLoading={isDeleting}
                        variant="ghost"
                        colorScheme="red"
                        onClick={async (e) => {
                            e.stopPropagation();
                            setIsDeleting.on();
                            await onScenarioDelete(row.key);
                            setIsDeleting.off();
                        }}
                        size="sm"
                        icon={<Icon boxSize="1rem" as={TrashIcon} />}
                    />
                )}
            </Tooltip>
        </Center>
    );
};
