import {
    DecimalComparisonValue,
    GetChannelLevelResultComparisonResponseDto,
} from '@api-clients/media-plan';
import { StatisticCell } from '@components/atoms/statisticCell/StatisticCell';
import { Column, DataTable, Row } from '@components/molecules';
import { FC } from 'react';

export interface ChannelViewTableProps {
    channelLevelResultArray: Array<GetChannelLevelResultComparisonResponseDto>;
}

interface NumberColumns {
    budget: DecimalComparisonValue;
    mix: DecimalComparisonValue;
    impression: DecimalComparisonValue;
    reachPercentage: DecimalComparisonValue;
    targetAudience: DecimalComparisonValue;
    activeAttentionVolumeSeconds: DecimalComparisonValue;
    passiveAttentionVolumeSeconds: DecimalComparisonValue;
}

interface DataRow extends NumberColumns {
    adChannelName: string;
}

export const ChannelViewTable: FC<ChannelViewTableProps> = ({ channelLevelResultArray }) => {
    const results = channelLevelResultArray.filter((ch) => !!ch.id);

    const maxValuesPerColumn = {
        key: '',
        budget: Math.max(...results.map((value) => value.budget?.value ?? 0)),
        mix: Math.max(...results.map((value) => value.mix?.value ?? 0)),
        impression: Math.max(...results.map((value) => value.impressions?.value ?? 0)),
        reachPercentage: Math.max(...results.map((value) => value.reachPercentage?.value ?? 0)),
        targetAudience: Math.max(...results.map((value) => value.targetAudience?.value ?? 0)),
        activeAttentionVolumeSeconds: Math.max(
            ...results.map((value) => value.activeAttentionVolumeSeconds?.value ?? 0),
        ),
        passiveAttentionVolumeSeconds: Math.max(
            ...results.map((value) => value.passiveAttentionVolumeSeconds?.value ?? 0),
        ),
    };

    const getStatisticColumn = (dataIndex: keyof NumberColumns) => ({
        dataIndex,
        key: dataIndex,
        isNumeric: true,
        sorter: (a: Row<NumberColumns>, b: Row<NumberColumns>) =>
            b[dataIndex].value! - a[dataIndex].value!,
        render: (row: Row<NumberColumns>) => {
            const isPercentage = dataIndex === 'mix' || dataIndex === 'reachPercentage';
            return (
                <StatisticCell
                    isPercentage={isPercentage}
                    isCurrency={dataIndex === 'budget'}
                    value={isPercentage ? row[dataIndex].value! * 100 : row[dataIndex].value!}
                    comparedWithValue={
                        isPercentage
                            ? row[dataIndex].comparedWithValue! * 100
                            : row[dataIndex].comparedWithValue!
                    }
                    isHighlighted={row[dataIndex].value === maxValuesPerColumn[dataIndex]}
                />
            );
        },
        onCell: (row: Row<DataRow>) => ({
            bgColor:
                row[dataIndex].value === maxValuesPerColumn[dataIndex] ? 'green.50' : undefined, // Show Total row as gray
        }),
    });

    const columns: Array<Column<DataRow>> = [
        {
            title: 'Channel',
            dataIndex: 'adChannelName',
            key: 'AdChannel',
            onCell: () => ({ fontWeight: 'semibold' }),
        },
        {
            title: 'Budget',
            ...getStatisticColumn('budget'),
        },
        {
            title: 'Mix',
            ...getStatisticColumn('mix'),
        },
        {
            title: 'Impressions',
            ...getStatisticColumn('impression'),
        },
        {
            title: 'Reach',
            ...getStatisticColumn('reachPercentage'),
        },
        {
            title: 'Target Audience',
            ...getStatisticColumn('targetAudience'),
        },
        {
            title: 'Active attention volume(s)',
            ...getStatisticColumn('activeAttentionVolumeSeconds'),
        },
        {
            title: 'Passive attention volume(s)',
            ...getStatisticColumn('passiveAttentionVolumeSeconds'),
        },
    ];

    const dataSource: Array<Row<DataRow>> = results.map((channelLevelResult) => ({
        key: channelLevelResult.channelCode!,
        adChannelName: channelLevelResult.channelName!,
        budget: channelLevelResult.budget!,
        mix: channelLevelResult.mix!,
        impression: channelLevelResult.impressions!,
        reachPercentage: channelLevelResult.reachPercentage!,
        targetAudience: channelLevelResult.targetAudience!,
        activeAttentionVolumeSeconds: channelLevelResult.activeAttentionVolumeSeconds!,
        passiveAttentionVolumeSeconds: channelLevelResult.passiveAttentionVolumeSeconds!,
    }));

    return <DataTable dataSource={dataSource} columns={columns} />;
};
