import {createSlice} from '@reduxjs/toolkit'
import {
    createGlobalCampaignAsync,
    findGlobalCampaignsAsync,
    selectGlobalCampaignAsync,
    updateGlobalCampaignAsync
} from "@/redux/slices/global/campaign/thunks";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";

export interface GlobalCampaignState {
    availableCampaigns: Array<GlobalCampaign> | undefined,
    selectedCampaign: GlobalCampaign | undefined,
    isPending: boolean
    isError: boolean
}

const initialState: GlobalCampaignState = {
    availableCampaigns: undefined,
    selectedCampaign: undefined,
    isPending: false,
    isError: false
}

const mergeCampaign = (target: GlobalCampaign, available: Array<GlobalCampaign>) => {
    const availableCampaigns = [] as Array<GlobalCampaign>;

    for (let i = 0; i < available.length; i++) {
        const campaign = available[i];

        availableCampaigns.push((campaign.id === target.id) ? target : campaign);
    }

    return availableCampaigns;
}

export const globalCampaignSlice = createSlice({
    name: 'global/campaign',
    initialState,
    reducers: {
        resetSelectedGlobalCampaign: (state) => {
            state.selectedCampaign = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findGlobalCampaignsAsync.pending, (state, action) => {
                state.isPending = true;
                state.availableCampaigns = undefined;
                state.selectedCampaign = undefined;
            })
            .addCase(findGlobalCampaignsAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(findGlobalCampaignsAsync.fulfilled, (state, action) => {
                state.availableCampaigns = action.payload || [];
                state.isPending = false;
            })
            .addCase(selectGlobalCampaignAsync.pending, (state, action) => {
                state.isPending = true;
                state.selectedCampaign = undefined;
            })
            .addCase(selectGlobalCampaignAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(selectGlobalCampaignAsync.fulfilled, (state, action) => {
                state.selectedCampaign = action.payload;
                state.isPending = false;
            })
            .addCase(createGlobalCampaignAsync.pending, (state) => {
                state.isPending = true;
                state.selectedCampaign = undefined
            })
            .addCase(createGlobalCampaignAsync.rejected, (state, action) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(createGlobalCampaignAsync.fulfilled, (state, action) => {
                state.selectedCampaign = action.payload;
                state.availableCampaigns!.push(action.payload);
                state.isPending = false;
            })
            .addCase(updateGlobalCampaignAsync.pending, (state) => {
                state.isPending = true;
            })
            .addCase(updateGlobalCampaignAsync.rejected, (state) => {
                state.isError = true;
                state.isPending = false;
            })
            .addCase(updateGlobalCampaignAsync.fulfilled, (state, action) => {
                state.selectedCampaign = action.payload;
                state.availableCampaigns = mergeCampaign(action.payload, state.availableCampaigns!);
                state.isPending = false;
            })
    }
})

export const { resetSelectedGlobalCampaign } = globalCampaignSlice.actions

