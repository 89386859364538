import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class FindProveCampaignApi {

    static async execute(
        configuration: Configuration,
        campaignId: string
    ): Promise<ProveCampaign> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaignId}`, {
            method: 'GET',
            headers: configuration.headers
        });

        return response.json();
    }

}
