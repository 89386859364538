import {
    ChannelLevelCurvesResponseDto,
    ListAllReachCurvesRelatingToASpecificScenarioRequest,
    ReachCurveApi,
    UpdateAllReachCurvesRelatingToAScenarioRequest,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useUpdateScenarioReachCurvesHook = () => {
    updateScenarioReachCurves: (
        request: UpdateAllReachCurvesRelatingToAScenarioRequest,
    ) => Promise<Array<ChannelLevelCurvesResponseDto>>;
};

export const useUpdateScenarioReachCurves: useUpdateScenarioReachCurvesHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateScenarioReachCurves(
        request: UpdateAllReachCurvesRelatingToAScenarioRequest,
    ) {
        try {
            const config = await getMediaPlanConfig();
            const api = new ReachCurveApi(config);
            return await api.updateAllReachCurvesRelatingToAScenario(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { updateScenarioReachCurves };
};