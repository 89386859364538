import { FC, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useBoolean,
} from '@chakra-ui/react';
import { CreativeResponseDto } from '@api-clients/attention-data';
import { useDeleteCreative } from '@hooks/creatives';
import { useCustomToast } from '@hooks/toast';
import { useParams } from 'react-router-dom';

type DeleteCreativeModalProps = {
    onClose: () => void;
    isOpen: boolean;
    creative: CreativeResponseDto;
    onSuccess: () => void;
};
export const DeleteCreativeModal: FC<DeleteCreativeModalProps> = ({
    onClose,
    isOpen,
    creative,
    onSuccess,
}) => {
    const { deleteCreative } = useDeleteCreative();
    const { successToast, errorToast } = useCustomToast();
    const { strategyReportId } = useParams();
    const [isDeleting, setIsDeleting] = useBoolean(false);
    const onDeleteCreative = async () => {
        try {
            setIsDeleting.on();
            await deleteCreative({
                strategyReportId: strategyReportId ?? '',
                creativeId: creative.id ?? '',
            });
            onSuccess();
            onClose();
            successToast('Creative has been removed', '');
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong');
        } finally {
            setIsDeleting.off();
        }
    };
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove creative</ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to remove this creative?</ModalBody>
                <ModalFooter gap="1rem">
                    <Button onClick={onClose} type="button" variant="outline" colorScheme="gray">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onDeleteCreative()}
                        isLoading={isDeleting}
                        type="button"
                        colorScheme="red"
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
