/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    ChannelLevelCurvesResponseDto,
    ProblemDetails,
    UpdateReachCurveRequestDto,
} from '../models';
import {
    ChannelLevelCurvesResponseDtoFromJSON,
    ChannelLevelCurvesResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateReachCurveRequestDtoFromJSON,
    UpdateReachCurveRequestDtoToJSON,
} from '../models';

export interface ListAllReachCurvesOfACampaignRequest {
    campaignId: string;
}

export interface ListAllReachCurvesRelatingToASpecificScenarioRequest {
    campaignId: string;
    scenarioId: string;
}

export interface UpdateAllReachCurvesRelatingToAScenarioRequest {
    campaignId: string;
    scenarioId: string;
    updateReachCurveRequestDto?: UpdateReachCurveRequestDto;
}

/**
 * ReachCurveApi - interface
 *
 * @export
 * @interface ReachCurveApiInterface
 */
export interface ReachCurveApiInterface {
    /**
     *
     * @param {string} campaignId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachCurveApiInterface
     */
    listAllReachCurvesOfACampaignRaw(
        requestParameters: ListAllReachCurvesOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>>;

    /**
     */
    listAllReachCurvesOfACampaign(
        requestParameters: ListAllReachCurvesOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>>;

    /**
     *
     * @param {string} campaignId
     * @param {string} scenarioId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachCurveApiInterface
     */
    listAllReachCurvesRelatingToASpecificScenarioRaw(
        requestParameters: ListAllReachCurvesRelatingToASpecificScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>>;

    /**
     */
    listAllReachCurvesRelatingToASpecificScenario(
        requestParameters: ListAllReachCurvesRelatingToASpecificScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>>;

    /**
     *
     * @param {string} campaignId
     * @param {string} scenarioId
     * @param {UpdateReachCurveRequestDto} [updateReachCurveRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachCurveApiInterface
     */
    updateAllReachCurvesRelatingToAScenarioRaw(
        requestParameters: UpdateAllReachCurvesRelatingToAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>>;

    /**
     */
    updateAllReachCurvesRelatingToAScenario(
        requestParameters: UpdateAllReachCurvesRelatingToAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>>;
}

/**
 *
 */
export class ReachCurveApi extends runtime.BaseAPI implements ReachCurveApiInterface {
    /**
     */
    async listAllReachCurvesOfACampaignRaw(
        requestParameters: ListAllReachCurvesOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling listAllReachCurvesOfACampaign.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/reach-curves`.replace(
                    `{${'campaignId'}}`,
                    encodeURIComponent(String(requestParameters.campaignId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelLevelCurvesResponseDtoFromJSON),
        );
    }

    /**
     */
    async listAllReachCurvesOfACampaign(
        requestParameters: ListAllReachCurvesOfACampaignRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>> {
        const response = await this.listAllReachCurvesOfACampaignRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async listAllReachCurvesRelatingToASpecificScenarioRaw(
        requestParameters: ListAllReachCurvesRelatingToASpecificScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling listAllReachCurvesRelatingToASpecificScenario.',
            );
        }

        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling listAllReachCurvesRelatingToASpecificScenario.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/reach-curves/{scenarioId}`
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    )
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelLevelCurvesResponseDtoFromJSON),
        );
    }

    /**
     */
    async listAllReachCurvesRelatingToASpecificScenario(
        requestParameters: ListAllReachCurvesRelatingToASpecificScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>> {
        const response = await this.listAllReachCurvesRelatingToASpecificScenarioRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updateAllReachCurvesRelatingToAScenarioRaw(
        requestParameters: UpdateAllReachCurvesRelatingToAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelLevelCurvesResponseDto>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter requestParameters.campaignId was null or undefined when calling updateAllReachCurvesRelatingToAScenario.',
            );
        }

        if (requestParameters.scenarioId === null || requestParameters.scenarioId === undefined) {
            throw new runtime.RequiredError(
                'scenarioId',
                'Required parameter requestParameters.scenarioId was null or undefined when calling updateAllReachCurvesRelatingToAScenario.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/campaigns/{campaignId}/reach-curves/{scenarioId}`
                    .replace(
                        `{${'campaignId'}}`,
                        encodeURIComponent(String(requestParameters.campaignId)),
                    )
                    .replace(
                        `{${'scenarioId'}}`,
                        encodeURIComponent(String(requestParameters.scenarioId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateReachCurveRequestDtoToJSON(
                    requestParameters.updateReachCurveRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelLevelCurvesResponseDtoFromJSON),
        );
    }

    /**
     */
    async updateAllReachCurvesRelatingToAScenario(
        requestParameters: UpdateAllReachCurvesRelatingToAScenarioRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelLevelCurvesResponseDto>> {
        const response = await this.updateAllReachCurvesRelatingToAScenarioRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
