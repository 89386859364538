import * as XLSX from 'xlsx';
import { ImportedProveLineItem, ProveLineItem } from '@api-clients/prove/schema/ProveLineItem';

export default class AttentionProveImportHelper {

    static hasNoLineItems(items: Array<ProveLineItem>) {
        return items.length === 0;
    }

    public static async processFile(file: File): Promise<Array<ImportedProveLineItem>> {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets['Line items'];
        worksheet['!ref'] = `A5:C999`;

        const rawData: any[] = XLSX.utils.sheet_to_json(worksheet, { defval: 0 });

        return rawData
            .filter((entry) => {
                return entry['Line item name'];
            })
            .map((entry) => {
            return {
                name: entry['Line item name'],
                mediaType: entry['Media type'],
                adFormatName: entry['Ad format'],
            } as ImportedProveLineItem;
        });
    };
}

