import { Badge, Button, Card, Flex, HStack, Spacer, StackDivider, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContextHelper } from '@hooks/_contexts';
import { urls } from '@configs';
import {
    CountryFeatureCode,
    GetCountryNameFromCountryFeature,
    isAarcCountryFeature,
    isAttentionDataCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { DetailListItem, DetailSection } from '@components/molecules';
import { getListRenderKey } from '@shared/utils';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { GlobalBadge } from '@components/atoms';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';

const ATTENTION_DATA = 'attention_data';
const AARC = 'aarc';

export const ManageApiServiceSection: FC = () => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const hasAttentionDataGlobalAccess = useMemo(
        () =>
            featureBalances?.some(
                (f) => f.featureCode === FeatureCode.AttentionDataAccessCountryGlobal,
            ),
        [featureBalances],
    );

    const has_AARC_GlobalAccess = useMemo(
        () => featureBalances?.some((f) => f.featureCode === FeatureCode.AarcAccessCountryGlobal),
        [featureBalances],
    );

    const subscribedCountries = useMemo(() => {
        const attentionDataSubscribedCountries =
            featureBalances
                ?.filter(
                    (f) =>
                        isAttentionDataCountryFeature(f.featureCode!) &&
                        f.limitType === LimitType.Unlimited,
                )
                ?.map((c) =>
                    GetCountryNameFromCountryFeature(c.featureCode as CountryFeatureCode),
                ) ?? [];
        const aarcSubscribedCountries =
            featureBalances
                ?.filter(
                    (f) =>
                        isAarcCountryFeature(f.featureCode!) && f.limitType === LimitType.Unlimited,
                )
                ?.map((c) =>
                    GetCountryNameFromCountryFeature(c.featureCode as CountryFeatureCode),
                ) ?? [];
        return [
            { key: ATTENTION_DATA, items: attentionDataSubscribedCountries },
            { key: AARC, items: aarcSubscribedCountries },
        ];
    }, [featureBalances]);

    const { t } = useTranslation();
    const apiServiceTexts = t('labels.sections.mediaPlanApiService', {
        returnObjects: true,
    });
    const apiPlanServiceTable = t('labels.tables.mediaPlanApiService', {
        returnObjects: true,
    });

    const handlePurchaseRedirect = () => {
        window.open(urls.customerSupportEmailUrl);
    };

    const renderDetails: DetailListItem[] = useMemo(
        () => [
            {
                label: apiPlanServiceTable.columns.attentionDataCountryDataAccess,
                renderItem: (
                    <HStack spacing={3} flexWrap="wrap">
                        {hasAttentionDataGlobalAccess && <GlobalBadge />}
                        {subscribedCountries
                            .find((item) => item.key === ATTENTION_DATA)!
                            .items.map((s) => (
                                <Badge key={`${ATTENTION_DATA}_${s}`}>{s}</Badge>
                            ))}
                    </HStack>
                ),
            },
            {
                label: apiPlanServiceTable.columns.aarcCountryDataAccess,
                renderItem: (
                    <HStack spacing={3} flexWrap="wrap">
                        {has_AARC_GlobalAccess && <GlobalBadge />}
                        {subscribedCountries
                            .find((item) => item.key === AARC)!
                            .items.map((s) => (
                                <Badge key={`${AARC}_${s}`}>{s}</Badge>
                            ))}
                    </HStack>
                ),
            },
        ],
        [subscribedCountries],
    );

    const emitClickOnPurchaseMoreEvent = usePosthogEvent(
        'Click on Purchase more in Manage API service',
    );
    return (
        <VStack align="left" spacing="1rem">
            <Flex>
                <ContainerTitle headingText={apiServiceTexts.heading} size="md" />
                <Spacer />
                <Button
                    size="sm"
                    onClick={() => {
                        handlePurchaseRedirect();
                        emitClickOnPurchaseMoreEvent();
                    }}
                >
                    {apiServiceTexts.button.purchaseMore}
                </Button>
            </Flex>
            <Card>
                <VStack divider={<StackDivider />} align="stretch" spacing="0">
                    {renderDetails.map((item, index) => (
                        <DetailSection
                            key={getListRenderKey(index, item.label)}
                            label={item.label}
                            renderItem={item.renderItem}
                        />
                    ))}
                </VStack>
            </Card>
        </VStack>
    );
};
