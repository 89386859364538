import {
    ChannelLevelCurvesResponseDto,
    OptimisationStrategy,
    ScenarioLevelCurvesResponseDto,
} from '@api-clients/media-plan';
import { Center, Divider, Flex, Spacer, Spinner } from '@chakra-ui/react';
import { RadioField } from '@components/atoms';
import { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { ReachCurveGraphForScreenshot } from '../../molecules/chart/reachCurveGraph/ReachCurveGraphForScreenshot';

export interface ReachCurveContainerForScreenshotProps {
    reachCurveData?: Array<ChannelLevelCurvesResponseDto>;
    height?: string | number;
    canUserSeeScenarioDetails?: boolean;
    selectedChannel: string;
    setSelectedChannel: (newChannel: string) => void;
}

/**
 * function which sorts baseline curves to the top then sorts the rest alphabetically
 */
const sortReachCurves = (a: ScenarioLevelCurvesResponseDto, b: ScenarioLevelCurvesResponseDto) => {
    if (
        b.optimisationStrategy === OptimisationStrategy.Base &&
        a.optimisationStrategy !== OptimisationStrategy.Base
    ) {
        return 1;
    }
    if (
        a.optimisationStrategy === OptimisationStrategy.Base &&
        b.optimisationStrategy !== OptimisationStrategy.Base
    ) {
        return -1;
    }
    return a.scenarioName?.localeCompare(b.scenarioName ?? '') ?? 0;
};

export const ReachCurveContainerForScreenshot = forwardRef<
    ChartJSOrUndefined<
        'line',
        | {
              x: number | undefined;
              y: number;
          }[]
        | undefined,
        unknown
    >,
    ReachCurveContainerForScreenshotProps
>(
    (
        {
            reachCurveData,
            height = '32rem',
            canUserSeeScenarioDetails,
            selectedChannel,
            setSelectedChannel,
        },
        ref,
    ) => {
        const { t } = useTranslation('mediaPlans');

        const adChannels = useMemo(
            () => reachCurveData?.map((curve) => curve.channel?.adChannelName ?? ''),
            [reachCurveData],
        );

        useEffect(() => {
            if (!selectedChannel || !adChannels?.includes(selectedChannel)) {
                setSelectedChannel(adChannels?.at(0) ?? '');
            }
        }, [adChannels, selectedChannel]);

        const selectedChannelCurve = reachCurveData?.find(
            (curve) => curve.channel?.adChannelName === selectedChannel,
        );

        return (
            <Flex
                height={height}
                padding="1.25rem"
                boxShadow="xs"
                bgColor="white"
                borderRadius="lg"
                direction="row"
            >
                {reachCurveData && adChannels ? (
                    <>
                        <Flex
                            minWidth="12rem"
                            direction="column"
                            border="1px"
                            borderColor="gray.200"
                            borderRadius="md"
                        >
                            <Center
                                borderRadius="inherit"
                                fontWeight="semibold"
                                bgColor="gray.50"
                                paddingY="1rem"
                            >
                                {t('mediaPlanning.reachCurveChart.channelSelector')}
                            </Center>
                            <Divider />
                            <Center padding="1.5rem">
                                <RadioField
                                    spacing="1rem"
                                    direction="column"
                                    selectedOption={selectedChannel}
                                    onChange={(value) => {
                                        setSelectedChannel(value as string);
                                    }}
                                    options={adChannels}
                                />
                            </Center>
                        </Flex>
                        {selectedChannelCurve && (
                            <Flex overflow="hidden" direction="column" flex={1}>
                                <Flex>
                                    <Spacer />
                                </Flex>

                                <ReachCurveGraphForScreenshot
                                    canUserSeeScenarioDetails={canUserSeeScenarioDetails}
                                    channelCurves={{
                                        scenarios:
                                            selectedChannelCurve?.scenarios?.sort(sortReachCurves),
                                        ...selectedChannelCurve,
                                    }}
                                    ref={ref}
                                />
                            </Flex>
                        )}
                    </>
                ) : (
                    <Center w="100%">
                        <Spinner />
                    </Center>
                )}
            </Flex>
        );
    },
);
