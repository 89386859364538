import { removePerformanceCookies } from '@shared/utils/helper';
import ReactGA from 'react-ga';
import { history } from './utils/history';

type ArbitraryData = { [i: string]: any };

export type EventCategory = 'user' | 'attention' | 'cpms' | 'timeOfDay';

export interface googleAnalyticsService {
    initialise(): void;

    set(data: ArbitraryData): void;

    pageView(pathName: string): void;

    modalView(modalName: string): void;

    disable(): void;

    event(category: EventCategory, action: string, label?: string): void;

    outBoundLink(label: string, callback: () => void): void;
}

export class GoogleAnalytics implements googleAnalyticsService {
    private trackingId: string;

    private disableAnalyticsKey: string;

    constructor() {
        this.trackingId = import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID as string;
        this.disableAnalyticsKey = `ga-disable${this.trackingId}`;
    }

    initialise(): void {
        // Allow google analytics to send cookies.
        (window as any)[this.disableAnalyticsKey] = false;

        ReactGA.initialize(this.trackingId);

        history.listen((update) => {
            this.set({ page: update.location.pathname });
            this.pageView(update.location.pathname);
        });
    }

    set(data: ArbitraryData): void {
        // Allowed only if Google Analytics has been enabled.
        if ((window as any)[this.disableAnalyticsKey] === false) {
            ReactGA.set(data);
        }
    }

    pageView(pathName: string): void {
        // Allowed only if Google Analytics has been enabled.
        if ((window as any)[this.disableAnalyticsKey] === false) {
            ReactGA.pageview(pathName);
        }
    }

    modalView(modalName: string): void {
        // Allowed only if Google Analytics has been enabled.
        if ((window as any)[this.disableAnalyticsKey] === false) {
            ReactGA.modalview(modalName);
        }
    }

    event(category: EventCategory, action: string, label?: string): void {
        // Allowed only if Google Analytics has been enabled.
        if ((window as any)[this.disableAnalyticsKey] === false) {
            ReactGA.event({
                category,
                action,
                label,
            });
        }
    }

    disable(): void {
        // Notify google analytics that cookies should no longer be sent.
        (window as any)[this.disableAnalyticsKey] = true;

        // Delete any analytics cookies.
        removePerformanceCookies();
    }

    outBoundLink(label: string, callBack: () => void): void {
        // Provide analytics for outbound links only if Google Analytics has been enabled.
        if ((window as any)[this.disableAnalyticsKey] === false) {
            ReactGA.outboundLink({ label }, callBack);
        }

        // Allow the outbound link to open, but do not use analytics to track this.
        else {
            callBack();
        }
    }
}
