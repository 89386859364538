/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AgeGroup = {
    Group18to24: 'group18to24',
    Group25to34: 'group25to34',
    Group35to44: 'group35to44',
    Group45to54: 'group45to54',
    Group55plus: 'group55plus'
} as const;
export type AgeGroup = typeof AgeGroup[keyof typeof AgeGroup];


export function AgeGroupFromJSON(json: any): AgeGroup {
    return AgeGroupFromJSONTyped(json, false);
}

export function AgeGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeGroup {
    return json as AgeGroup;
}

export function AgeGroupToJSON(value?: AgeGroup | null): any {
    return value as any;
}

