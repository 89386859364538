/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FormatResponseDto } from './FormatResponseDto';
import {
    FormatResponseDtoFromJSON,
    FormatResponseDtoFromJSONTyped,
    FormatResponseDtoToJSON,
} from './FormatResponseDto';

/**
 *
 * @export
 * @interface GetAttentionDataResponseDto
 */
export interface GetAttentionDataResponseDto {
    /**
     *
     * @type {FormatResponseDto}
     * @memberof GetAttentionDataResponseDto
     */
    adChannel?: FormatResponseDto;
    /**
     *
     * @type {FormatResponseDto}
     * @memberof GetAttentionDataResponseDto
     */
    adFormat?: FormatResponseDto;
    /**
     *
     * @type {number}
     * @memberof GetAttentionDataResponseDto
     */
    averageActiveAttentionSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetAttentionDataResponseDto
     */
    averagePassiveAttentionSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetAttentionDataResponseDto
     */
    averageInactiveAttentionSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetAttentionDataResponseDto
     */
    averageAdLengthSeconds?: number;
    /**
     *
     * @type {number}
     * @memberof GetAttentionDataResponseDto
     */
    averageAdTimeOnScreenSeconds?: number;
}

/**
 * Check if a given object implements the GetAttentionDataResponseDto interface.
 */
export function instanceOfGetAttentionDataResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAttentionDataResponseDtoFromJSON(json: any): GetAttentionDataResponseDto {
    return GetAttentionDataResponseDtoFromJSONTyped(json, false);
}

export function GetAttentionDataResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetAttentionDataResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adChannel: !exists(json, 'ad_channel')
            ? undefined
            : FormatResponseDtoFromJSON(json['ad_channel']),
        adFormat: !exists(json, 'ad_format')
            ? undefined
            : FormatResponseDtoFromJSON(json['ad_format']),
        averageActiveAttentionSeconds: !exists(json, 'average_active_attention_seconds')
            ? undefined
            : json['average_active_attention_seconds'],
        averagePassiveAttentionSeconds: !exists(json, 'average_passive_attention_seconds')
            ? undefined
            : json['average_passive_attention_seconds'],
        averageInactiveAttentionSeconds: !exists(json, 'average_inactive_attention_seconds')
            ? undefined
            : json['average_inactive_attention_seconds'],
        averageAdLengthSeconds: !exists(json, 'average_ad_length_seconds')
            ? undefined
            : json['average_ad_length_seconds'],
        averageAdTimeOnScreenSeconds: !exists(json, 'average_ad_time_on_screen_seconds')
            ? undefined
            : json['average_ad_time_on_screen_seconds'],
    };
}

export function GetAttentionDataResponseDtoToJSON(value?: GetAttentionDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_channel: FormatResponseDtoToJSON(value.adChannel),
        ad_format: FormatResponseDtoToJSON(value.adFormat),
        average_active_attention_seconds: value.averageActiveAttentionSeconds,
        average_passive_attention_seconds: value.averagePassiveAttentionSeconds,
        average_inactive_attention_seconds: value.averageInactiveAttentionSeconds,
        average_ad_length_seconds: value.averageAdLengthSeconds,
        average_ad_time_on_screen_seconds: value.averageAdTimeOnScreenSeconds,
    };
}
