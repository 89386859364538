import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';
import {
    CreateCreativeRequest,
    CreativeResponseDto,
    StrategyReportsApi,
} from '@api-clients/attention-data';

type useCreateCreativeHook = () => {
    createCreative: (request: CreateCreativeRequest) => Promise<CreativeResponseDto>;
};

export const useCreateCreative: useCreateCreativeHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function createCreative(request: CreateCreativeRequest) {
        try {
            const config = await getAttentionDataApiConfig();

            const api = new StrategyReportsApi(config);
            return await api.createCreative(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { createCreative };
};
