import { ToastPosition, ToastProps, useToast } from '@chakra-ui/react';

export const useCustomToast = () => {
    const toast = useToast();

    function successToast(
        title: string,
        description: string,
        isClosable = true,
        position: ToastPosition = 'bottom',
        duration: number | null = 3000,
    ) {
        toast({
            title,
            description,
            status: 'success',
            duration,
            isClosable,
            position,
        });
    }

    function errorToast(
        title: string,
        description: string,
        isClosable = true,
        position: ToastPosition = 'bottom',
        duration: number | null = 3000,
    ) {
        toast({
            title,
            description,
            status: 'error',
            duration,
            isClosable,
            position,
        });
    }

    return {
        successToast,
        errorToast,
    };
};
