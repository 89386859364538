import {
    OrganisationResponseDto,
    RemoveUserFromOrganisationRequestDto,
    UserWithAccessLevelDto,
} from '@api-clients/account-manager';
import { Button, Icon, IconButton, MenuItem, useDisclosure } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useRemoveUser } from '@hooks/organisations/useRemoveUser';
import { useRemovedToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentActiveSubscription } from '@hooks/subscriptions';
import { useGetFeatureBalances } from '@hooks/subscriptions/useGetFeatureBalances';

export interface RemoveUserProps {
    organisation: OrganisationResponseDto;
    user: UserWithAccessLevelDto;
}

export const RemoveUser: FC<RemoveUserProps> = ({ organisation, user }) => {
    const {
        setCurrentContextValues: { setOrganisation, setFeatureBalances },
    } = useAppContextHelper();
    const { t } = useTranslation();
    const { removeUser } = useRemoveUser();
    const { getFeatureBalances } = useGetFeatureBalances();
    const { successRemoveUserToast, errorRemoveUserToast, errorRemoveUserToastWithMessage } =
        useRemovedToast();

    const onRemove = async () => {
        const payload: RemoveUserFromOrganisationRequestDto = {
            userId: user.id,
        };
        try {
            const response = await removeUser(organisation.id!, payload);
            const currentFeatureBalances = await getFeatureBalances();
            setOrganisation(response);
            setFeatureBalances(currentFeatureBalances!);
            successRemoveUserToast(organisation.organisationName);
        } catch (err) {
            if (err instanceof Error && err.message) {
                errorRemoveUserToastWithMessage(err.message);
                return;
            }
            errorRemoveUserToast();
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <MenuItem onClick={onOpen} icon={<Icon as={TrashIcon} />}>
                Remove
            </MenuItem>

            <ConfirmDialog
                headerText={t('labels.dialogs.removeMember.header')}
                confirmButtonText={t('labels.dialogs.removeMember.confirmButtonText')}
                cancelButtonText={t('labels.dialogs.removeMember.cancelButtonText')}
                isOpen={isOpen}
                onClose={async (isConfirmation) => {
                    if (isConfirmation) await onRemove();
                    onClose();
                }}
                isDestructive
            >
                <Trans
                    i18nKey="labels.dialogs.removeMember.body"
                    values={{
                        memberName: `${user?.firstName} ${user?.lastName}` ?? 'this member',
                        companyName: organisation.organisationName,
                    }}
                />
            </ConfirmDialog>
        </>
    );
};
