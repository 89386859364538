/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ShareUserAccessCampaignDetails
 */
export interface ShareUserAccessCampaignDetails {
    /**
     *
     * @type {string}
     * @memberof ShareUserAccessCampaignDetails
     */
    campaignId?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ShareUserAccessCampaignDetails
     */
    scenarioIds?: Array<string> | null;
}

/**
 * Check if a given object implements the ShareUserAccessCampaignDetails interface.
 */
export function instanceOfShareUserAccessCampaignDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShareUserAccessCampaignDetailsFromJSON(json: any): ShareUserAccessCampaignDetails {
    return ShareUserAccessCampaignDetailsFromJSONTyped(json, false);
}

export function ShareUserAccessCampaignDetailsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ShareUserAccessCampaignDetails {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        scenarioIds: !exists(json, 'scenario_ids') ? undefined : json['scenario_ids'],
    };
}

export function ShareUserAccessCampaignDetailsToJSON(
    value?: ShareUserAccessCampaignDetails | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        campaign_id: value.campaignId,
        scenario_ids: value.scenarioIds,
    };
}
