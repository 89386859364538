import {
    Badge,
    Button,
    Card,
    Flex,
    HStack,
    Spacer,
    StackDivider,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import {
    DetailListItem,
    DetailSection,
    GetFullVersionModal,
    TextRender,
} from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { useAppContextHelper } from '@hooks/_contexts';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { getListRenderKey } from '@shared/utils';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    CountryFeatureCode,
    GetCountryNameFromCountryFeature,
    isMediaPlanCountryFeature, isStrategyReportCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { GlobalBadge } from '@components/atoms';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';

interface campaignModel {
    campaignUsed?: number;
    campaignTotal?: number;
    limitType?: LimitType;
}
const MEDIA_PLAN = 'media_plan';

export const ManageMediaPlanServiceSection: FC = () => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const feature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
    );
    const balance = feature && feature.limit! - feature.used!;
    const strategyReportFeature = featureBalances?.find(f => f.featureCode === FeatureCode.StrategyReportCreateReport)
    const strategyReportFeatureBalance = strategyReportFeature && strategyReportFeature.limit! - strategyReportFeature.used!

    const {
        isOpen: isFullVersionModalOpen,
        onClose: onFullVersionModalClose,
        onOpen: onFullVersionModalOpen,
    } = useDisclosure();

    const { t } = useTranslation('accountManager');
    const mediaPlanServiceTexts = t('labels.sections.mediaPlanService', {
        returnObjects: true,
    });
    const mediaPlanServiceTable = t('labels.tables.mediaPlanService', {
        returnObjects: true,
    });

    const hasMediaPlanGlobalAccess = useMemo(
        () =>
            featureBalances?.some(
                (f) => f.featureCode === FeatureCode.MediaPlansAccessCountryGlobal,
            ),
        [featureBalances],
    );
    const subscribedCountries = useMemo(() => {
        const mediaPlanServiceSubscribedCountries =
            featureBalances
                ?.filter(
                    (f) =>
                        (isMediaPlanCountryFeature(f.featureCode!) || isStrategyReportCountryFeature(f.featureCode!)) &&
                        f.limitType === LimitType.Unlimited,
                )
                ?.map((c) =>
                    GetCountryNameFromCountryFeature(c.featureCode as CountryFeatureCode),
                ) ?? [];
        // deduplicate
        const subscribedCountriesSet = new Set(
            mediaPlanServiceSubscribedCountries
                .map(c => c.toString())
                .filter(s => s.length !== 0)
        )
        return [{ key: MEDIA_PLAN, items: Array.from(subscribedCountriesSet)}];
    }, [featureBalances]);

    const renderDetails: DetailListItem[] = useMemo(
        () => [
            {
                label: mediaPlanServiceTable.columns.strategyReportsLeft,
                renderItem: <TextRender text={`${strategyReportFeatureBalance ?? 0} / ${strategyReportFeature?.limit ?? 0}`}/>
            },
            {
                label: mediaPlanServiceTable.columns.campaignsLeft,
                renderItem: <TextRender text={`${balance} / ${feature?.limit}`} />,
            },
            {
                label: mediaPlanServiceTable.columns.countryAccess,
                renderItem: (
                    <HStack spacing={3} flexWrap="wrap">
                        {hasMediaPlanGlobalAccess && <GlobalBadge />}
                        {subscribedCountries
                            .find((item) => item.key === MEDIA_PLAN)!
                            .items.map((s) => (
                                <Badge key={`${MEDIA_PLAN}_${s}`}>{s}</Badge>
                            ))}
                    </HStack>
                ),
            },
        ],
        [feature, balance],
    );

    const emitClickOnPurchaseMoreEvent = usePosthogEvent(
        'Click on Purchase more in Manage media plans service',
    );
    return (
        <>
            <GetFullVersionModal
                key="freetier_ManageMediaPlan"
                isOpen={isFullVersionModalOpen}
                onClose={onFullVersionModalClose}
            />
            <VStack align="left" spacing="1rem">
                <Flex>
                    <ContainerTitle headingText={mediaPlanServiceTexts.heading} size="md" />
                    <Spacer />
                    <Button
                        size="sm"
                        onClick={() => {
                            onFullVersionModalOpen();
                            emitClickOnPurchaseMoreEvent();
                        }}
                    >
                        {mediaPlanServiceTexts.button.purchaseMore}
                    </Button>
                </Flex>
                <Card>
                    <VStack divider={<StackDivider />} align="stretch" spacing="0">
                        {renderDetails.map((item, index) => (
                            <DetailSection
                                key={getListRenderKey(index, item.label)}
                                label={item.label}
                                renderItem={item.renderItem}
                            />
                        ))}
                    </VStack>
                </Card>
            </VStack>
        </>
    );
};
