import {
    ApiService,
    OauthApplicationResponseDto,
    UpdateOauthApplicationPermissionsRequestDto,
} from '@api-clients/account-manager';
import { FeatureCode } from '@api-clients/subscriptions';
import {
    Center,
    Checkbox,
    CheckboxGroup,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Spinner,
    Stack,
    useBoolean,
    useConst,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { NavBarMenuListItem } from '@components/atoms/navBarItem';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOauthApplications } from '@hooks/accountManager';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { OAuthApplicationFormData } from './CreateNewOAuthApplicationDialogButton';

interface InternalProps {
    application: OauthApplicationResponseDto;
    onUpdate: () => void;
}

export const UpdateOAuthApplicationPermissionsDialogButton: FC<InternalProps> = ({
    application,
    onUpdate,
}) => {
    const { t } = useTranslation();
    const { errorToast, successToast } = useCustomToast();
    const { updateOauthApplicationPermissions, getOauthApplicationPermissions } =
        useOauthApplications();
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const hasAarcAccess = featureBalances?.some((f) => f.featureCode === FeatureCode.AarcApiAccess);
    const hasProveAccess = featureBalances?.some(
        (f) => f.featureCode === FeatureCode.ProveApiAccess,
    );
    const hasProveSessionAccess = featureBalances?.some(
        (f) => f.featureCode === FeatureCode.ProveApiSessionAccess,
    );
    const hasAttentionDataAccess = featureBalances?.some(
        (f) => f.featureCode === FeatureCode.AttentionDataApiAccess,
    );

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedPermissions, setSelectedPermissions] = useState<Array<ApiService>>([]);

    const {
        handleSubmit,
        register,
        reset,
        watch,
        formState: { errors, isSubmitting, isValid },
    } = useForm<OAuthApplicationFormData>({
        resolver: yupResolver(
            yup.object().shape({
                requestedApis: yup
                    .array()
                    .min(1)
                    .required(
                        t('messages.forms.validation.oauthApplications.applicationName.isRequired'),
                    ),
            }),
        ),
        mode: 'onChange',
        defaultValues: { requestedApis: selectedPermissions },
    });

    const translation = useConst(() =>
        t('pages.applicationManagement', {
            returnObjects: true,
        }),
    );

    const {
        createDialog: { confirmButton, cancelButton },
        toastText: {
            edit: { success, error },
        },
    } = useConst(() => ({
        createDialog: translation.dialogs.create,
        toastText: translation.toast,
    }));

    const onFormSubmit = async (data: OAuthApplicationFormData) => {
        try {
            const request: UpdateOauthApplicationPermissionsRequestDto = {
                requestedApis: data.requestedApis,
            };

            const response = await updateOauthApplicationPermissions(
                application.clientId!,
                request,
            );
            onUpdate();
            successToast(success.heading, success.subtitle);
        } catch (e) {
            errorToast(error.heading, error.subtitle);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const permissionOptions = [
        {
            value: ApiService.AttentionData,
            label: 'Attention benchmarks',
            isDisabled: !hasAttentionDataAccess,
        },
        {
            value: ApiService.AttentionProve,
            label: 'Attention prove',
            isDisabled: !hasProveAccess,
        },
        {
            value: ApiService.AttentionProveSession,
            label: 'Attention prove session creation',
            isDisabled: !hasProveSessionAccess,
        },
        {
            value: ApiService.ReachCurve,
            label: 'Attention-adjusted® reach curves',
            isDisabled: !hasAarcAccess,
        },
    ];

    const [isLoading, setIsLoading] = useBoolean();

    const handleOpen = () => {
        setIsLoading.on();
        onOpen();
        getOauthApplicationPermissions(application.clientId!)
            .then((p) => setSelectedPermissions(p))
            .finally(() => setIsLoading.off());
    };

    return (
        <>
            <NavBarMenuListItem
                children="Edit Permissions"
                icon={Cog6ToothIcon}
                onClick={handleOpen}
            />
            <ConfirmDialog
                size="xl"
                headerText="Edit Permissions"
                confirmButtonText="Save"
                cancelButtonText={cancelButton}
                isOpen={isOpen}
                isLoading={isSubmitting}
                isConfirmDisabled={!isValid && !isLoading}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        handleSubmit(onFormSubmit)();
                    } else {
                        onClose();
                    }
                }}
            >
                <VStack spacing="1rem" align="left">
                    {isLoading ? (
                        <Center padding="1rem">
                            <Spinner />
                        </Center>
                    ) : (
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                            <VStack align="left">
                                <FormControl isInvalid={!!errors.requestedApis} isRequired>
                                    <FormLabel>Permissions</FormLabel>
                                    <CheckboxGroup defaultValue={selectedPermissions}>
                                        <Stack>
                                            {permissionOptions.map((p) => (
                                                <Checkbox
                                                    {...register('requestedApis')}
                                                    value={p.value}
                                                    isDisabled={p.isDisabled}
                                                >
                                                    {p.label}
                                                </Checkbox>
                                            ))}
                                            <FormErrorMessage>
                                                Must select at least one permission.
                                            </FormErrorMessage>
                                        </Stack>
                                    </CheckboxGroup>
                                </FormControl>
                            </VStack>
                        </form>
                    )}
                </VStack>
            </ConfirmDialog>
        </>
    );
};
