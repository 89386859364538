/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { DecimalComparisonValue } from './DecimalComparisonValue';
import {
    DecimalComparisonValueFromJSON,
    DecimalComparisonValueFromJSONTyped,
    DecimalComparisonValueToJSON,
} from './DecimalComparisonValue';
import type { GetFormatLevelResultComparisonResponseDto } from './GetFormatLevelResultComparisonResponseDto';
import {
    GetFormatLevelResultComparisonResponseDtoFromJSON,
    GetFormatLevelResultComparisonResponseDtoFromJSONTyped,
    GetFormatLevelResultComparisonResponseDtoToJSON,
} from './GetFormatLevelResultComparisonResponseDto';

/**
 *
 * @export
 * @interface GetChannelLevelResultComparisonResponseDto
 */
export interface GetChannelLevelResultComparisonResponseDto {
    /**
     *
     * @type {Array<GetFormatLevelResultComparisonResponseDto>}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    formatLevelResults?: Array<GetFormatLevelResultComparisonResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    comparedWithId?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    channelCode?: string | null;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    budget?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    mix?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    impressions?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    reachPercentage?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    targetAudience?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    activeAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {DecimalComparisonValue}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    passiveAttentionVolumeSeconds?: DecimalComparisonValue;
    /**
     *
     * @type {Date}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    createdAt?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof GetChannelLevelResultComparisonResponseDto
     */
    updatedAt?: Date | null;
}

/**
 * Check if a given object implements the GetChannelLevelResultComparisonResponseDto interface.
 */
export function instanceOfGetChannelLevelResultComparisonResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetChannelLevelResultComparisonResponseDtoFromJSON(
    json: any,
): GetChannelLevelResultComparisonResponseDto {
    return GetChannelLevelResultComparisonResponseDtoFromJSONTyped(json, false);
}

export function GetChannelLevelResultComparisonResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetChannelLevelResultComparisonResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        formatLevelResults: !exists(json, 'format_level_results')
            ? undefined
            : json['format_level_results'] === null
            ? null
            : (json['format_level_results'] as Array<any>).map(
                  GetFormatLevelResultComparisonResponseDtoFromJSON,
              ),
        id: !exists(json, 'id') ? undefined : json['id'],
        comparedWithId: !exists(json, 'compared_with_id') ? undefined : json['compared_with_id'],
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
        budget: !exists(json, 'budget')
            ? undefined
            : DecimalComparisonValueFromJSON(json['budget']),
        mix: !exists(json, 'mix') ? undefined : DecimalComparisonValueFromJSON(json['mix']),
        impressions: !exists(json, 'impressions')
            ? undefined
            : DecimalComparisonValueFromJSON(json['impressions']),
        reachPercentage: !exists(json, 'reach_percentage')
            ? undefined
            : DecimalComparisonValueFromJSON(json['reach_percentage']),
        targetAudience: !exists(json, 'target_audience')
            ? undefined
            : DecimalComparisonValueFromJSON(json['target_audience']),
        activeAttentionVolumeSeconds: !exists(json, 'active_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['active_attention_volume_seconds']),
        passiveAttentionVolumeSeconds: !exists(json, 'passive_attention_volume_seconds')
            ? undefined
            : DecimalComparisonValueFromJSON(json['passive_attention_volume_seconds']),
        createdAt: !exists(json, 'created_at')
            ? undefined
            : json['created_at'] === null
            ? null
            : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at')
            ? undefined
            : json['updated_at'] === null
            ? null
            : new Date(json['updated_at']),
    };
}

export function GetChannelLevelResultComparisonResponseDtoToJSON(
    value?: GetChannelLevelResultComparisonResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        format_level_results:
            value.formatLevelResults === undefined
                ? undefined
                : value.formatLevelResults === null
                ? null
                : (value.formatLevelResults as Array<any>).map(
                      GetFormatLevelResultComparisonResponseDtoToJSON,
                  ),
        id: value.id,
        compared_with_id: value.comparedWithId,
        channel_name: value.channelName,
        channel_code: value.channelCode,
        budget: DecimalComparisonValueToJSON(value.budget),
        mix: DecimalComparisonValueToJSON(value.mix),
        impressions: DecimalComparisonValueToJSON(value.impressions),
        reach_percentage: DecimalComparisonValueToJSON(value.reachPercentage),
        target_audience: DecimalComparisonValueToJSON(value.targetAudience),
        active_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.activeAttentionVolumeSeconds,
        ),
        passive_attention_volume_seconds: DecimalComparisonValueToJSON(
            value.passiveAttentionVolumeSeconds,
        ),
        created_at:
            value.createdAt === undefined
                ? undefined
                : value.createdAt === null
                ? null
                : value.createdAt.toISOString(),
        updated_at:
            value.updatedAt === undefined
                ? undefined
                : value.updatedAt === null
                ? null
                : value.updatedAt.toISOString(),
    };
}
