import { LockIcon } from '@chakra-ui/icons';
import { Box, Spacer, Tooltip } from '@chakra-ui/react';
import { chakraComponents, OptionProps } from 'chakra-react-select';
import { FC } from 'react';

interface CustomOptionProps {
    data: { tooltip: string; showLockIcon: boolean };
}

export const MultiSelectOption: FC<OptionProps<any> & CustomOptionProps> = (props) => {
    return (
        <Tooltip
            isDisabled={!props.data.tooltip}
            label={props.data?.tooltip}
            hasArrow
            placement="left"
        >
            <Box fontWeight={props.data.value === 'all' ? '700' : '400'}>
                <chakraComponents.Option {...props}>
                    {props.children}
                    {props.data.showLockIcon && (
                        <>
                            <Spacer />
                            <LockIcon />
                        </>
                    )}
                </chakraComponents.Option>
            </Box>
        </Tooltip>
    );
};
