import { ComponentMultiStyleConfig, StyleFunctionProps } from '@chakra-ui/react';

export const Tabs: ComponentMultiStyleConfig = {
    parts: ['tab', 'tablist'],
    variants: {
        stepper: (props: StyleFunctionProps) => {
            const c = props.colorScheme;
            return {
                tab: {
                    textTransform: 'Capitalize',
                    paddingX: '24px',
                    borderBottom: '4px',
                    borderColor: `${c}.200`,
                    color: 'gray.400',
                    _selected: {
                        borderColor: `${c}.500`,
                    },
                    _disabled: {
                        borderColor: 'gray.200',
                        color: 'gray.400',
                        opacity: 1,
                    },
                    borderRadius: '0',
                    _focusVisible: {
                        boxShadow: 'none',
                    },
                },
            };
        },
    },
};
