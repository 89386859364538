/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FeatureCode } from './FeatureCode';
import { FeatureCodeFromJSON, FeatureCodeFromJSONTyped, FeatureCodeToJSON } from './FeatureCode';
import type { LimitType } from './LimitType';
import { LimitTypeFromJSON, LimitTypeFromJSONTyped, LimitTypeToJSON } from './LimitType';

/**
 *
 * @export
 * @interface FeatureBalanceResponseDto
 */
export interface FeatureBalanceResponseDto {
    /**
     *
     * @type {string}
     * @memberof FeatureBalanceResponseDto
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureBalanceResponseDto
     */
    subscriptionId?: string;
    /**
     *
     * @type {FeatureCode}
     * @memberof FeatureBalanceResponseDto
     */
    featureCode?: FeatureCode;
    /**
     *
     * @type {LimitType}
     * @memberof FeatureBalanceResponseDto
     */
    limitType?: LimitType;
    /**
     *
     * @type {number}
     * @memberof FeatureBalanceResponseDto
     */
    limit?: number;
    /**
     *
     * @type {number}
     * @memberof FeatureBalanceResponseDto
     */
    used?: number;
    /**
     *
     * @type {Date}
     * @memberof FeatureBalanceResponseDto
     */
    updatedAt?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof FeatureBalanceResponseDto
     */
    lastUsedAt?: Date | null;
}

/**
 * Check if a given object implements the FeatureBalanceResponseDto interface.
 */
export function instanceOfFeatureBalanceResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeatureBalanceResponseDtoFromJSON(json: any): FeatureBalanceResponseDto {
    return FeatureBalanceResponseDtoFromJSONTyped(json, false);
}

export function FeatureBalanceResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FeatureBalanceResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        subscriptionId: !exists(json, 'subscription_id') ? undefined : json['subscription_id'],
        featureCode: !exists(json, 'feature_code')
            ? undefined
            : FeatureCodeFromJSON(json['feature_code']),
        limitType: !exists(json, 'limit_type') ? undefined : LimitTypeFromJSON(json['limit_type']),
        limit: !exists(json, 'limit') ? undefined : json['limit'],
        used: !exists(json, 'used') ? undefined : json['used'],
        updatedAt: !exists(json, 'updated_at')
            ? undefined
            : json['updated_at'] === null
            ? null
            : new Date(json['updated_at']),
        lastUsedAt: !exists(json, 'last_used_at')
            ? undefined
            : json['last_used_at'] === null
            ? null
            : new Date(json['last_used_at']),
    };
}

export function FeatureBalanceResponseDtoToJSON(value?: FeatureBalanceResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        subscription_id: value.subscriptionId,
        feature_code: FeatureCodeToJSON(value.featureCode),
        limit_type: LimitTypeToJSON(value.limitType),
        limit: value.limit,
        used: value.used,
        updated_at:
            value.updatedAt === undefined
                ? undefined
                : value.updatedAt === null
                ? null
                : value.updatedAt.toISOString(),
        last_used_at:
            value.lastUsedAt === undefined
                ? undefined
                : value.lastUsedAt === null
                ? null
                : value.lastUsedAt.toISOString(),
    };
}
