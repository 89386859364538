import {
    AccessLevelType,
    OrganisationResponseDto,
    UserResponseDto,
    UserWithAccessLevelDto,
} from '@api-clients/account-manager';

interface useManageMemberProps {
    canBeDeleted: (
        member: UserWithAccessLevelDto,
        currentUserAccessLevel: AccessLevelType,
    ) => boolean;
    getCurrentUserAccessLevel: (
        organisation: OrganisationResponseDto,
        currentUser: UserResponseDto,
    ) => AccessLevelType;
    canPerformDeletion: (accessLevel: AccessLevelType) => boolean;
    hasAdministrativePermission: (
        organisation: OrganisationResponseDto,
        currentUser: UserResponseDto,
    ) => boolean;
}

export const useManageMember = (): useManageMemberProps => {
    const canBeDeleted = (
        member: UserWithAccessLevelDto,
        currentUserAccessLevel: AccessLevelType,
    ): boolean => {
        if (currentUserAccessLevel === 'owner') return member.accessLevel !== 'owner';

        if (currentUserAccessLevel === 'administrator') return member.accessLevel === 'member';

        return false;
    };

    const getCurrentUserAccessLevel = (
        organisation: OrganisationResponseDto,
        currentUser: UserResponseDto,
    ): AccessLevelType => {
        return organisation.users!.find((u) => u.id === currentUser.id)!.accessLevel!;
    };

    const canPerformDeletion = (accessLevel: AccessLevelType): boolean => {
        return accessLevel === 'owner' || accessLevel === 'administrator';
    };

    const hasAdministrativePermission = (
        organisation: OrganisationResponseDto,
        currentUser: UserResponseDto,
    ): boolean => {
        const accessLevel = getCurrentUserAccessLevel(organisation, currentUser);
        return accessLevel === 'owner' || accessLevel === 'administrator';
    };

    return {
        canBeDeleted,
        getCurrentUserAccessLevel,
        canPerformDeletion,
        hasAdministrativePermission,
    };
};
