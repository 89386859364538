import { RoleTitle } from '@api-clients/account-manager';

export type RoleOption = {
    id: number;
    label: string;
    value: RoleTitle;
};
export const roleOptions: RoleOption[] = [
    {
        id: 1,
        value: 'media_planner',
        label: 'Media planner',
    },
    {
        id: 2,
        value: 'media_buyer',
        label: 'Media buyer',
    },
    {
        id: 3,
        value: 'media_strategist',
        label: 'Media strategist',
    },
    {
        id: 4,
        value: 'manager',
        label: 'Manager',
    },
    {
        id: 5,
        value: 'director',
        label: 'Director',
    },
    {
        id: 6,
        value: 'other',
        label: 'Other',
    },
];
