import {
    CreativeResponseDto,
    GetAllCreativesForAStrategyReportRequest,
    StrategyReportsApi,
} from '@api-clients/attention-data';
import { useHelper } from '@shared/utils';
import { useApiConfiguration } from '@hooks/configuration';

type useGetAllCreativesHook = () => {
    getAllCreatives: (
        request: GetAllCreativesForAStrategyReportRequest,
    ) => Promise<Array<CreativeResponseDto>>;
};

export const useGetAllCreatives: useGetAllCreativesHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getAllCreatives(
        request: GetAllCreativesForAStrategyReportRequest,
    ): Promise<Array<CreativeResponseDto>> {
        try {
            const config = await getAttentionDataApiConfig();
            const api = new StrategyReportsApi(config);

            return await api.getAllCreativesForAStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getAllCreatives,
    };
};
