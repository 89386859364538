import {Configuration} from "@api-clients/runtime";
import {ProveAdFormat} from "@api-clients/prove/schema/ProveAdFormat";

export class FindProveAdFormatsApi {

    static async execute(
        configuration: Configuration
    ): Promise<Array<ProveAdFormat>> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/ad-formats`, {
            method: 'GET',
            headers: configuration.headers
        });

        return response.json();
    }

}
