import {
    AspectRatio,
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
} from '@chakra-ui/react';
import {CheckCircleIcon} from '@heroicons/react/24/solid';
import {FC} from 'react';
import {externalUrls} from "@configs/externalUrls";
import {routing} from "@configs";

export interface EnableProveModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const EnableProveModal: FC<EnableProveModalProps> = ({isOpen, onClose}) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalHeader margin="1rem">Measure and optimise live campaigns with attentionPROVE</ModalHeader>
                    <ModalBody>
                        <Box marginRight="1rem" marginLeft="1rem">
                            <Flex gap="3rem">
                                <Flex flexDirection="column" gap="0.5rem" width={"50%"}>
                                    <Flex gap="0.5rem" alignItems="top">
                                        <Icon as={CheckCircleIcon} fontSize="1.5rem" color="green.600"/>
                                        <Text>Powered by the world's most <strong>robust human attention
                                            dataset</strong> from over <strong>100,000</strong> unique
                                            impressions</Text>
                                    </Flex>
                                    <Flex gap="0.5rem" alignItems="top">
                                        <Icon as={CheckCircleIcon} fontSize="1.5rem" color="green.600"/>
                                        <Text>Gain <strong>real-time</strong> insight into <strong>campaign
                                            performance</strong></Text>
                                    </Flex>
                                    <Flex gap="0.5rem" alignItems="top">
                                        <Icon as={CheckCircleIcon} fontSize="1.5rem" color="green.600"/>
                                        <Text>Instant <strong>prediction of attention</strong> per ad creative, format,
                                            platform, and more</Text>
                                    </Flex>
                                    <Flex gap="0.5rem" alignItems="top">
                                        <Icon as={CheckCircleIcon} fontSize="1.5rem" color="green.600"/>
                                        <Text>Identify <strong>optimisation opportunity</strong> to drive smarter media
                                            buying and stronger ROI</Text>
                                    </Flex>
                                </Flex>
                                <Box width={"50%"}>
                                    <AspectRatio ratio={1.8}>
                                        <iframe
                                            id="youtube_demo_video"
                                            title="PROVE Demo video"
                                            allowFullScreen
                                            src={externalUrls.youTubeProveVideo}
                                        />
                                    </AspectRatio>
                                </Box>
                            </Flex>
                            <HStack mt={8} spacing={4}>
                                <Spacer/>
                                <Button
                                    as={Link}
                                    href={`/${routing.prove.root.path}/demo/${routing.prove.dashboard.path}`}
                                    isExternal
                                    colorScheme="gray">View product demo</Button>
                                <Button
                                    as={Link}
                                    href="https://share.hsforms.com/1sEM35U5qT_23iSAn2rYqCAcs4ay"
                                    isExternal>Enquire for pricing</Button>
                            </HStack>
                        </Box>
                    </ModalBody>
                    <ModalFooter/>
                </ModalContent>
            </Modal>
        </>
    );
};
