import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    Flex,
    FormLabel,
    Icon,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useBoolean,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
    CampaignResponseDto,
    ChannelLevelCurvesResponseDto,
    GetCampaignByIdRequest,
    GetCampaignResponseDto,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import {
    useCreateUserScenarioAccess,
    useShareMediaPlan,
    useGetUserScenarioAccess,
    useDeleteUserScenarioAccess,
    useSetScenarioVisibility,
} from '@hooks/scenarios';
import { useExport } from '@hooks/exports';
import { useAppContext, useAppContextHelper } from '@hooks/_contexts';
import { useCustomToast } from '@hooks/toast';
import { ShareMediaPlanRequest } from '@api-clients/account-manager';
import { useForm } from 'react-hook-form';
import { ReachCurveContainerForScreenshot } from '@apps/attentionADJUST/components/organisms';
import { AmplifiedChartImage } from '@shared/cores';
import { LinkIcon, ShareIcon } from '@heroicons/react/24/outline';
import { RecipientSelect } from '@apps/attentionADJUST/components/molecules/manageScenarioMenu/RecipientSelect';
import { useGetShareableLink } from '@hooks/scenarios/useGetSharebleLink';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { useDeleteShareLink } from '@hooks/scenarios/useDeleteShareLink';
import { Option } from '@shared/cores/types';

interface InternalProps {
    scenario: ScenarioWithComparisonResponseDto;
    reachCurveData: ChannelLevelCurvesResponseDto[];
    scenarioLevelResultsComparison: GetScenarioLevelResultComparisonResponseDto;
    campaign: CampaignResponseDto;
    scenarioBasicInfo?: GetScenarioBasicInfoResponseDto;
}

export const ShareScenarioButton: FC<InternalProps> = ({
    scenario,
    reachCurveData,
    scenarioLevelResultsComparison,
    campaign,
    scenarioBasicInfo,
}) => {
    const [hasShareLink, setHasShareLink] = useState(false);
    const [isLoadingShareLink, setIsLoadingShareLink] = useBoolean();
    const { appOrganisation } = useAppContext();

    const { getUserScenarioAcess } = useGetUserScenarioAccess();

    const { t } = useTranslation('mediaPlans');
    const { shareMediaPlan } = useShareMediaPlan();
    const { exportToPdf, exportToExcel } = useExport(scenario);
    const {
        currentContextValues: { user, organisation },
    } = useAppContextHelper();
    const { successToast, errorToast } = useCustomToast();
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const isFreePlan = !featureBalances?.find(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    const [isLoading, setIsLoading] = useBoolean();
    const [isFetchingLink, setIsFetchingLink] = useBoolean();
    const adChannels = reachCurveData?.map((curve) => curve.channel?.adChannelName ?? '');
    const [selectedChannel, setSelectedChannel] = useState<string>('');
    const [isAccessRequestLoading, setIsAccessRequestLoading] = useBoolean(false);
    const [isNotifyViaEmailChecked, setIsNotifyViaEmailChecked] = useBoolean(false);

    const scenarioSharingText = useMemo(
        () => t('mediaPlanning.scenarioSharing', { returnObjects: true }),
        [],
    );
    const internalUsers = useMemo(
        () => organisation?.users?.map((u) => u.emailAddress!),
        [organisation],
    );

    const getUserDetailByEmailAddress = (emailAddress: string) => {
        return organisation?.users?.filter((u) => u.emailAddress === emailAddress).at(0);
    };
    const getUserDetailById = (id: string) => {
        return organisation?.users?.filter((u) => u.id === id).at(0);
    };

    const [selectedRecipients, setSelectedRecipients] = useState<
        { name: string; emailAddress: string }[]
    >([]);
    const [selectedRecipientsMirror, setSelectedRecipientsMirror] = useState<
        { name: string; emailAddress: string }[]
    >([]);

    useEffect(() => {
        if (
            scenarioBasicInfo &&
            scenarioBasicInfo.scenarioShareLink &&
            scenarioBasicInfo.scenarioShareLink.length !== 0
        ) {
            setHasShareLink(true);
        }
    }, []);

    const chartRef = useRef<AmplifiedChartImage>(null);

    const getChartImages = async (): Promise<Array<string>> => {
        const exportedImages = [];
        for (let i = 0; i < adChannels.length; i++) {
            const adChannel = adChannels[i];
            setSelectedChannel(adChannel);
            /* eslint-disable-next-line */
            await new Promise((resolve) => setTimeout(resolve, 100)); // need to give time for browser to render
            const imageDataURL = chartRef.current?.toBase64Image();
            if (imageDataURL) {
                exportedImages.push(imageDataURL);
            }
        }
        return exportedImages;
    };

    const { handleSubmit } = useForm();
    const pdfFileName = useMemo(
        () => `${scenario.campaignBasicInfo?.campaignName}-${scenario.scenarioName}.pdf`,
        [scenario],
    );
    const excelFileName = useMemo(
        () => `${scenario.campaignBasicInfo?.campaignName}-${scenario.scenarioName}.xlsx`,
        [scenario],
    );

    const recipients = useMemo(
        () =>
            selectedRecipients.map((entity) => {
                if (internalUsers!.includes(entity.emailAddress)) {
                    return {
                        isInternal: true,
                        email: entity.emailAddress,
                    };
                }

                return {
                    isInternal: false,
                    email: entity.emailAddress,
                };
            }),
        [selectedRecipients, internalUsers],
    );

    const { getShareableLink } = useGetShareableLink();
    const { deleteShareLink } = useDeleteShareLink();
    const toggleOnShareLink = async () => {
        if (!scenario.id) {
            throw new Error('scenario id is null');
        }
        try {
            const res = await getShareableLink(scenario.id);
            const url = `${window.location.origin}/share/scenario/${res.id}`;
            await navigator.clipboard.writeText(url);
            successToast('Share link enabled', '', true, 'top', 2000);
        } catch (error) {
            errorToast('Unable to get link', '', true, 'top', 3000);
        }
    };
    const getLinkForSharing = async () => {
        if (!scenario.id) {
            throw new Error('scenario id is null');
        }
        try {
            setIsFetchingLink.on();
            const res = await getShareableLink(scenario.id);
            const url = `${window.location.origin}/share/scenario/${res.id}`;
            await navigator.clipboard.writeText(url);
            successToast('Copied', '', true, 'top', 2000);
        } catch (error) {
            errorToast('Unable to get link', '', true, 'top', 3000);
        } finally {
            setIsFetchingLink.off();
        }
    };
    const deleteLinkForSharing = async () => {
        if (!scenario.id) {
            throw new Error('scneario id is null');
        }
        try {
            await deleteShareLink(scenario.id);
            successToast('Share link disabled', '', true, 'top', 3000);
        } catch (error) {
            errorToast('Unable to delete share link', '', true, 'top', 3000);
        }
    };
    const handleToggleShareLink = async (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        try {
            setIsLoadingShareLink.on();
            if (newValue) {
                await toggleOnShareLink();
            } else {
                await deleteLinkForSharing();
            }
            setHasShareLink(newValue);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingShareLink.off();
        }
    };

    const handleGetUserScenarioAccess = () => {
        getUserScenarioAcess(scenario?.campaignId ?? '', scenario?.id ?? '')
            .then((response) => {
                const users =
                    response.map((r) => {
                        const u = organisation?.users?.filter((ou) => ou.id === r.userId).at(0);
                        return {
                            name: `${u?.firstName} ${u?.lastName}`,
                            emailAddress: u?.emailAddress ?? '',
                        };
                    }) ?? [];
                setSelectedRecipients(users);
                setSelectedRecipientsMirror(users);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const { isOpen: isReceipientBoxOpen, onToggle: onReceipientBoxToggle } = useDisclosure({
        defaultIsOpen: !scenario.isAccessibleToEveryone,
    });
    const createScenario = useCreateUserScenarioAccess();
    const handleCreateAccess = async (userf?: {
        name: string;
        emailAddress: string;
    }): Promise<void> => {
        const userInfo = organisation?.users
            ?.filter((u) => u.emailAddress === userf?.emailAddress)
            .at(0);
        await createScenario
            .createUserScenarioAccess(
                scenario.id ?? '',
                userInfo?.id ?? '',
                scenario.campaignId ?? '',
            )
            .catch((e) => errorToast('Something went wrong', e.message));
    };

    const { deleteUserScenarioAccess } = useDeleteUserScenarioAccess();
    const handleRemoveAccess = (u: { name: string; emailAddress: string }) => {
        const userDetail = getUserDetailByEmailAddress(u.emailAddress);
        setIsAccessRequestLoading.on();
        deleteUserScenarioAccess(
            scenario?.id ?? '',
            userDetail?.id ?? '',
            scenario?.campaignId ?? '',
        )
            .then((res) =>
                setSelectedRecipients(
                    selectedRecipients.filter((r) => r.emailAddress !== u.emailAddress),
                ),
            )
            .catch((e) => errorToast('Something went wrong', e.message))
            .finally(() => {
                setIsAccessRequestLoading.off();
            });
    };

    const handleReceipientChange = (selectedOption: Option) => {
        const userDetail = getUserDetailByEmailAddress(selectedOption.value);
        const receipients = [
            ...selectedRecipients,
            {
                name: `${userDetail?.firstName} ${userDetail?.lastName}`,
                emailAddress: userDetail?.emailAddress ?? '',
            },
        ];
        setSelectedRecipients(receipients);
    };

    const { setScenarioVisibility } = useSetScenarioVisibility();
    const handleSetScenarioVisibility = (value: boolean): Promise<void> => {
        return setScenarioVisibility(scenario.id ?? '', scenario.campaignId ?? '', value);
    };

    const [isVisibilityLoading, setIsVisibilityLoading] = useBoolean(false);
    const handleAccessToggle = () => {
        setIsVisibilityLoading.on();
        handleSetScenarioVisibility(isReceipientBoxOpen)
            .then(() => onReceipientBoxToggle())
            .catch((e) => errorToast('Something went wrong', e.message))
            .finally(() => setIsVisibilityLoading.off());
    };

    const onFormSubmit = async () => {
        try {
            setIsLoading.on();

            const toRequestUsers = selectedRecipients.filter(
                (item) => !selectedRecipientsMirror.includes(item),
            );

            await Promise.all(toRequestUsers.map((req) => handleCreateAccess(req)));

            if (isNotifyViaEmailChecked) {
                const images = await getChartImages();
                const pdfBlob = await exportToPdf(images, scenarioLevelResultsComparison);
                const excelBlob = await exportToExcel(images);
                const res = await getShareableLink(scenario.id ?? '');
                const url = `${window.location.origin}/share/scenario/${res.id}`;

                const request: ShareMediaPlanRequest = {
                    sender: `${user?.firstName} ${user?.lastName}`,
                    recipients,
                    campaignName: scenario.campaignBasicInfo!.campaignName!,
                    scenarioName: scenario.scenarioName!,
                    scenarioLink: url,
                    attachments: [
                        new File([pdfBlob], pdfFileName),
                        new File([excelBlob], excelFileName),
                    ],
                };
                await shareMediaPlan(request);
            }
            successToast(
                scenarioSharingText.toast.scenario.success.heading,
                scenarioSharingText.toast.scenario.success.subtitle,
            );
        } catch {
            errorToast(
                scenarioSharingText.toast.scenario.error.heading,
                scenarioSharingText.toast.scenario.error.subtitle,
            );
        } finally {
            setSelectedRecipientsMirror(selectedRecipients);
            setIsLoading.off();
        }
    };

    useEffect(() => {
        handleGetUserScenarioAccess();
    }, []);

    return (
        <>
            {campaign && (
                <>
                    <Popover placement="bottom-end">
                        <PopoverTrigger>
                            <Button
                                leftIcon={<ShareIcon width={15} height={15} />}
                                variant="outline"
                                colorScheme="gray"
                            >
                                {scenarioSharingText.button.name}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent width="500px" padding={3}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <form
                                onSubmit={handleSubmit(onFormSubmit)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') event.preventDefault();
                                }}
                            >
                                <PopoverCloseButton />
                                <PopoverHeader
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 'large',
                                        border: 0,
                                    }}
                                >
                                    Share
                                </PopoverHeader>
                                <PopoverBody>
                                    <Flex
                                        alignItems="center"
                                        justifyContent="space-between"
                                        minHeight="1.875rem"
                                    >
                                        <Text>Anyone with the link can view this scenario</Text>
                                        <Box>
                                            {isLoadingShareLink ? (
                                                <Box pr="0.625rem" pt="0.125rem">
                                                    <Spinner size="sm" />
                                                </Box>
                                            ) : (
                                                <Switch
                                                    isChecked={hasShareLink}
                                                    onChange={(e) => {
                                                        handleToggleShareLink(e);
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Flex>

                                    {/*   
                                    <Box py="0.75rem">
                                        <Divider />
                                    </Box>
                                    <Flex
                                        alignItems="center"
                                        justifyContent="space-between"
                                        minHeight="1.875rem"
                                    >
                                        <Text>
                                            Everyone at{' '}
                                            <strong>
                                                {appOrganisation.currentValue?.organisationName ??
                                                    ''}
                                            </strong>{' '}
                                            can edit this scenario
                                        </Text>
                                        <Box>
                                            {isVisibilityLoading ? (
                                                <Box pr="0.625rem" pt="0.125rem">
                                                    <Spinner size="sm" />
                                                </Box>
                                            ) : (
                                                <Switch
                                                    isChecked={!isReceipientBoxOpen}
                                                    onChange={handleAccessToggle}
                                                />
                                            )}
                                        </Box>
                                    </Flex> */}

                                    {/* <Collapse in={isReceipientBoxOpen}>
                                        <VStack alignItems="start" gap="1rem">
                                            // <MultiSelectInputTag /> 
                                            <RecipientSelect
                                                self={user}
                                                availableRecipientOptions={organisation!.users!}
                                                selectedOptions={selectedRecipients.map((r) => ({
                                                    value: r.emailAddress,
                                                    label: r.name,
                                                }))}
                                                setSelectedOption={(selectedOption) =>
                                                    handleReceipientChange(selectedOption)
                                                }
                                                isLoading={isAccessRequestLoading}
                                            />
                                            <Flex gap="0.5rem" wrap="wrap">
                                                {selectedRecipients.map((o) => (
                                                    <Badge
                                                        marginRight="0.5rem"
                                                        fontWeight="normal"
                                                        fontSize="16px"
                                                        display="flex"
                                                        gap="0.5rem"
                                                        rounded="0.3rem"
                                                        key={`recipients_scenario_${o.emailAddress}`}
                                                    >
                                                        <Text
                                                            fontWeight="none"
                                                            textTransform="none"
                                                        >
                                                            {o.name}
                                                        </Text>
                                                        <Text
                                                            textTransform="none"
                                                            cursor="pointer"
                                                            onClick={() => handleRemoveAccess(o)}
                                                        >
                                                            ✖
                                                        </Text>
                                                    </Badge>
                                                ))}
                                            </Flex>
                                            <Checkbox
                                                isChecked={isNotifyViaEmailChecked}
                                                onChange={setIsNotifyViaEmailChecked.toggle}
                                            >
                                                Notify recipients via email
                                            </Checkbox>
                                        </VStack>
                                    </Collapse> */}
                                </PopoverBody>
                                <PopoverFooter border={0}>
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Tooltip
                                            label={isFreePlan ? 'Upgrade to enable sharing' : ''}
                                        >
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                onClick={() => {
                                                    getLinkForSharing();
                                                }}
                                                gap="0.25rem"
                                                isDisabled={!hasShareLink}
                                                isLoading={isFetchingLink}
                                                leftIcon={
                                                    <Icon
                                                        as={LinkIcon}
                                                        boxSize="1rem"
                                                        strokeWidth="2px"
                                                    />
                                                }
                                            >
                                                Copy link
                                            </Button>
                                        </Tooltip>
                                        {/* <Button
                                            type="submit"
                                            isLoading={isLoading}
                                            isDisabled={
                                                selectedRecipients.length <= 0 ||
                                                !isReceipientBoxOpen
                                            }
                                        >
                                            Share
                                        </Button> */}
                                    </Flex>
                                </PopoverFooter>
                            </form>
                        </PopoverContent>
                    </Popover>
                    <Box position="fixed" zIndex={-999} pointerEvents="none" opacity={0}>
                        <ReachCurveContainerForScreenshot
                            selectedChannel={selectedChannel}
                            setSelectedChannel={setSelectedChannel}
                            reachCurveData={reachCurveData}
                            ref={chartRef}
                        />
                    </Box>
                </>
            )}
        </>
    );
};
