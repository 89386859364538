import { FC } from 'react';
import { Alert, AlertIcon, AlertProps } from '@chakra-ui/react';

interface InternalProps extends AlertProps {
    errorMessage: string | null;
}

export const ErrorAlert: FC<InternalProps> = ({
    errorMessage,
    status = 'error',
    variant = 'left-accent',
    ...alertProps
}) => {
    return (
        <>
            <Alert status={status} variant={variant} {...alertProps}>
                <AlertIcon />
                {errorMessage ?? 'There was an error processing your request'}
            </Alert>
        </>
    );
};
