import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface ManageClientContextProps {
    errorAddNewClient: boolean;
    setErrorAddNewClient: (isError: boolean) => void;
    errorAddNewClientMessage: string | null;
    setErrorAddNewClientMessage: (message: string) => void;
}

const ManageClientFormContext = createContext<ManageClientContextProps>({
    errorAddNewClient: false,
    setErrorAddNewClient: (isError: boolean) => {},
    errorAddNewClientMessage: null,
    setErrorAddNewClientMessage: (message: string) => {},
});

export const ManageClientFormContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [errorAdd, setErrorAdd] = useState(false);
    const [failedAddMessage, setFailedAddMessage] = useState<string | null>(null);

    const errorAddNewClient = useMemo(() => errorAdd, [errorAdd]);
    const setErrorAddNewClient = useCallback((value: boolean) => setErrorAdd(value), [setErrorAdd]);

    const errorAddNewClientMessage = useMemo(() => failedAddMessage, [failedAddMessage]);
    const setErrorAddNewClientMessage = useCallback(
        (value: string) => setFailedAddMessage(value),
        [setFailedAddMessage],
    );

    const value = useMemo(
        (): ManageClientContextProps => ({
            errorAddNewClient,
            setErrorAddNewClient,
            errorAddNewClientMessage,
            setErrorAddNewClientMessage,
        }),
        [
            errorAddNewClient,
            setErrorAddNewClient,
            errorAddNewClientMessage,
            setErrorAddNewClientMessage,
        ],
    );

    return (
        <ManageClientFormContext.Provider value={value}>
            {children}
        </ManageClientFormContext.Provider>
    );
};

export const useManageClientFormContext = (): ManageClientContextProps =>
    useContext(ManageClientFormContext);
