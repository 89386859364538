import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export const proveCampaignDemoData: ProveCampaign = {
    "id": "demo",
    "externalId": "cmp-demo",
    "insertionOrder": {
        "id": "demo",
        "name": "default",
        "externalId": "io-demo"
    }
};