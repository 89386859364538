import {
    OrganisationApi,
    OrganisationResponseDto,
    UpdateOrganisationRequest,
    UpdateOrganisationRequestDto,
} from '@api-clients/account-manager';
import * as yup from 'yup';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';

import { useHelper } from '@shared/utils';

interface UpdateOrganisationHook {
    validationSchema: yup.ObjectSchema<any>;
    updateOrganisation: (
        organisationId: string,
        updateOrganisationRequestDto: UpdateOrganisationRequestDto,
    ) => Promise<OrganisationResponseDto>;
}

type useUpdateOrganisationProps = () => UpdateOrganisationHook;

export const useUpdateOrganisation: useUpdateOrganisationProps = () => {
    const { t } = useTranslation();
    const { getDisplayedErrorMessage } = useHelper();
    const { getAccountManagerConfig } = useApiConfiguration();

    const validationSchema = yup.object().shape({
        organisationName: yup
            .string()
            .trim()
            .required(t('messages.forms.validation.organisation.name.isRequired')),
    });

    async function updateOrganisation(
        organisationId: string,
        updateOrganisationRequestDto: UpdateOrganisationRequestDto,
    ): Promise<OrganisationResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: UpdateOrganisationRequest = {
                organisationId,
                updateOrganisationRequestDto,
            };

            const api = new OrganisationApi(configuration);
            return await api.updateOrganisation(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        validationSchema,
        updateOrganisation,
    };
};
