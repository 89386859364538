import { AdClientApi, CreateAdClientRequestDto } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

interface useCreateAdClientProps {
    createAdClient: (createAdClientRequestDto: CreateAdClientRequestDto) => Promise<void>;
}

type useCreateAdClientHook = () => useCreateAdClientProps;

export const useCreateAdClient: useCreateAdClientHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();

    async function createAdClient(
        createAdClientRequestDto: CreateAdClientRequestDto,
    ): Promise<void> {
        const config = await getMediaPlanConfig();
        const api = new AdClientApi(config);

        await api.createAdClient({ createAdClientRequestDto });
    }

    return {
        createAdClient,
    };
};
