import React, { FC } from 'react';
import { Document, Image, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import {
    DecimalComparisonValue,
    OptimisationStrategy,
    ScenarioWithComparisonResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
} from '@api-clients/media-plan';
import { getOptimisationObjective } from '@shared/cores/types';
import { AdClientDto } from '@api-clients/account-manager';
import { useGetPercentageChangeAndCodedCommentary } from '@hooks/scenarios/useGetPercentageChangeAndCodedCommentary';
import { useHelper } from '@shared/utils';

const tableCellBorder = '1 solid #E2E8F0';

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 4,
    },
    page: {
        padding: 40,
        fontSize: '10px',
    },
    body: {},
    sectionTitle: {
        fontSize: 16,
        color: '#2F6E45',
        marginTop: 24,
        marginBottom: 10,
    },
    constraints: {
        flexDirection: 'column',
        marginTop: 10,
        constraint: {
            minWidth: 150,
            marginRight: 10,
        },
        label: {
            marginRight: 10,
            color: '#4A5568',
            fontSize: 8,
            marginBottom: 4,
        },
    },
    constraintsRow: {
        marginTop: 5,
        height: '40px',
        flexDirection: 'row',
    },
    detailedTable: {
        borderTop: tableCellBorder,
        borderLeft: tableCellBorder,
        textAlign: 'right',
        justifyContent: 'center',
    },
    detailedTableHeader: {
        flexDirection: 'row',
        fontSize: '9px',
        textAlign: 'left',
        backgroundColor: '#F9F9FA',
    },
    detailedTableRow: {
        flexDirection: 'row',
    },
    detailedTableHeaderCell: {
        width: 107,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedTableHeaderCellLong: {
        width: 118,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedTableCellLong: {
        width: 118,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedTableCell: {
        width: 107,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedTableCellLeft: {
        width: 107,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        textAlign: 'left',
        justifyContent: 'center',
        minHeight: 28,
        padding: 12,
    },
    detailedTableFormatRow: {
        flexDirection: 'row',
    },
    detailedTableFormatRowStatistic: {
        flexDirection: 'column',
    },
    detailedFormatTableHeaderCell: {
        width: 100,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedFormatTableHeaderCellLong: {
        width: 120,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedFormatTableCell: {
        width: 100,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedFormatTableCellLong: {
        width: 120,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        minHeight: 28,
        justifyContent: 'center',
        padding: 12,
    },
    detailedFormatTableCellLeft: {
        width: 100,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        textAlign: 'left',
        justifyContent: 'center',
        minHeight: 28,
        padding: 12,
    },
    detailedFormatTableCellLongLeft: {
        width: 120,
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        textAlign: 'left',
        justifyContent: 'center',
        minHeight: 28,
        padding: 12,
    },
    keyOptimisedMetrics: {},
    keyOptimisedMetricsBody: {
        flexDirection: 'row',
    },
    keyOptimisedMetricsTable: {
        flexDirection: 'column',
        width: '50%',
        borderTop: tableCellBorder,
        borderLeft: tableCellBorder,
    },
    keyOptimisedMetricsTableHeader: {
        flexDirection: 'row',
        fontWeight: 'extrabold',
        minHeight: 28,
        justifyContent: 'center',
        backgroundColor: '#F9F9FA',
    },
    keyOptimisedMetricsTableRow: {
        flexDirection: 'row',
        minHeight: 28,
    },
    keyOptimisedMetricsTableField: {
        width: '100%',
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        justifyContent: 'center',
        padding: '12px',
    },
    keyOptimisedMetricsTableHeaderField: {
        width: '100%',
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        justifyContent: 'center',
        padding: '12px',
    },
    keyOptimisedMetricsTableValue: {
        width: '50%',
        textAlign: 'right',
        borderBottom: tableCellBorder,
        borderRight: tableCellBorder,
        justifyContent: 'center',
        padding: '12px',
    },
    keyOptimisedMetricsChart: {
        flexDirection: 'column',
        marginRight: 5,
        marginLeft: 5,
        border: tableCellBorder,
        width: '50%',
    },
    keyOptimisedMetricsChartOutcome: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '310',
    },
    keyOptimisedMetricsChartOutcomeName: {
        fontSize: 16,
    },
    keyOptimisedMetricsChartOutcomeNumber: {
        fontSize: 24,
        fontWeight: 'extrabold',
    },
    keyOptimisedMetricsChartDescription: {
        backgroundColor: '#FEF7F4',
        textAlign: 'left',
        padding: 10,
        margin: 5,
        justifyContent: 'flex-end',
    },
    keyOptimisedMetricsChartDescriptionTitle: {
        fontWeight: 'ultrabold',
    },
    lastModifiedAt: {
        color: '#718096',
        fontSize: '12px',
        marginTop: 4,
    },
    logo: {
        position: 'absolute',
        left: 600,
        top: 30,
        width: 200,
        height: 66,
    },
    footer: {
        position: 'absolute',
        left: 780,
        top: 560,
        textAlign: 'right',
    },
    numberValue: {
        textAlign: 'right',
        marginBottom: 2,
    },
    comparisonValue: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    image: {
        width: '80%',
        marginHorizontal: 'auto',
    },
    secondImage: {
        width: '80%',
        marginTop: '24px',
        marginHorizontal: 'auto',
    },
});

interface ComparisonCellProps {
    comparisonValue?: DecimalComparisonValue | null | number;
    isPercentage?: boolean;
    currencySymbol?: string;
    isBaseScenario?: boolean;
}
const ArrowRightUp = () => (
    <Svg width="24" height="24" viewBox="0 0 24 24" style={{ width: 48, height: 48 }}>
        <Path d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" stroke="#2F6E45" strokeWidth={1.5} />
    </Svg>
);

const StatArrowUp = () => (
    <Svg width="24" height="24" viewBox="0 0 24 24" style={{ width: 8, height: 8 }}>
        <Path
            d="M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"
            fill="#5F9172"
        />
    </Svg>
);
const StatArrowDown = () => (
    <Svg width="24" height="24" viewBox="0 0 24 24" style={{ width: 8, height: 8 }}>
        <Path
            d="M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z"
            fill="#F56565"
        />
    </Svg>
);

const ComparisonCell: FC<ComparisonCellProps> = ({
    comparisonValue,
    isPercentage = false,
    currencySymbol,
    isBaseScenario,
}) => {
    const { formatNumber } = useHelper();
    return typeof comparisonValue === 'number' ? (
        <Text>{formatNumber(comparisonValue, isPercentage)}</Text>
    ) : (
        <>
            <Text style={styles.numberValue}>
                {formatNumber(comparisonValue?.value, isPercentage)}
            </Text>
            {!isBaseScenario && (
                <View style={styles.comparisonValue}>
                    {comparisonValue?.valueDifference && comparisonValue?.valueDifference > 0 ? (
                        <StatArrowUp />
                    ) : (
                        <StatArrowDown />
                    )}
                    <Text style={{ fontSize: 8 }}>
                        {formatNumber(
                            Math.abs(comparisonValue?.percentageDifference ?? 0),
                            true,
                            1,
                        )}
                    </Text>
                </View>
            )}
        </>
    );
};

const AttentionPlanLogo = () => (
    <Svg width="545.46" height="178.1" viewBox="0 0 545.46 178.1" style={styles.logo}>
        <Path
            fill="#E86A3F"
            d="M247.27,149.85v-55.2h19.68c4.29,0,7.85,.78,10.67,2.35,2.82,1.56,4.93,3.7,6.33,6.4,1.4,2.7,2.1,5.75,2.1,9.12s-.7,6.45-2.11,9.15c-1.41,2.7-3.53,4.84-6.36,6.42-2.83,1.57-6.38,2.36-10.66,2.36h-13.53v-7.06h12.78c2.71,0,4.91-.47,6.6-1.42,1.69-.94,2.93-2.23,3.72-3.87,.79-1.64,1.19-3.49,1.19-5.58s-.4-3.93-1.19-5.55c-.79-1.62-2.03-2.88-3.73-3.8-1.7-.92-3.93-1.38-6.7-1.38h-10.46v48.06h-8.33Z"
        />
        <Path fill="#E86A3F" d="M297.31,149.85v-55.2h8.33v48.03h25.01v7.17h-33.34Z" />
        <Path
            fill="#E86A3F"
            d="M349.35,149.85h-8.84l19.87-55.2h9.62l19.87,55.2h-8.84l-15.61-45.17h-.43l-15.63,45.17Zm1.48-21.62h28.68v7.01h-28.68v-7.01Z"
        />
        <Path
            fill="#E86A3F"
            d="M443.89,94.65v55.2h-7.66l-28.06-40.49h-.51v40.49h-8.33v-55.2h7.71l28.09,40.54h.51v-40.54h8.25Z"
        />
        <Path
            fill="#E86A3F"
            d="M467.87,124.86c-2.09,0-4.05-.39-5.87-1.17-1.83-.78-3.43-1.86-4.82-3.25s-2.47-2.99-3.25-4.81c-.78-1.83-1.17-3.78-1.17-5.87s.39-4.05,1.17-5.87c.78-1.82,1.86-3.43,3.25-4.81,1.38-1.38,2.99-2.47,4.82-3.25,1.82-.78,3.78-1.17,5.87-1.17s4.05,.39,5.87,1.17c1.82,.78,3.43,1.86,4.81,3.25,1.38,1.39,2.47,2.99,3.25,4.81,.78,1.83,1.17,3.78,1.17,5.87s-.39,4.05-1.17,5.87c-.78,1.82-1.86,3.43-3.25,4.81-1.39,1.38-2.99,2.47-4.81,3.25-1.83,.78-3.78,1.17-5.87,1.17Zm0-3.74c2.09,0,3.99-.51,5.71-1.54,1.72-1.03,3.09-2.4,4.11-4.12,1.02-1.72,1.53-3.62,1.53-5.71s-.51-4.01-1.53-5.73c-1.02-1.72-2.39-3.09-4.11-4.11-1.72-1.02-3.62-1.53-5.71-1.53s-4,.51-5.72,1.53-3.1,2.39-4.12,4.11c-1.02,1.72-1.53,3.63-1.53,5.73s.51,3.99,1.53,5.71c1.02,1.72,2.39,3.09,4.12,4.12s3.63,1.54,5.72,1.54Zm-5.34-4.57v-13.93h6.97c.68,0,1.36,.17,2.05,.5,.69,.33,1.26,.84,1.72,1.51,.46,.67,.69,1.51,.69,2.5s-.24,1.86-.72,2.57c-.48,.71-1.08,1.24-1.79,1.61-.72,.37-1.43,.55-2.15,.55h-4.95v-2.4h4.08c.45,0,.9-.2,1.35-.59,.45-.39,.67-.97,.67-1.74,0-.81-.22-1.36-.67-1.66s-.88-.45-1.29-.45h-2.7v11.54h-3.25Zm8.57-6.53l3.38,6.53h-3.57l-3.25-6.53h3.44Z"
        />
        <Path
            fill="#2F6E45"
            d="M278.51,65.67c0,2.5,1.05,3.39,2.74,3.39,.89,0,1.61-.24,2.34-.56v7.83c-1.53,.97-3.55,1.37-6.14,1.37-4.76,0-7.67-1.86-8.88-5.9-1.7,4.6-5.73,6.14-10.82,6.14-6.62,0-10.98-3.87-10.98-10.25,0-8.32,6.7-12.84,21.08-12.03v-3.55c0-3.23-1.37-5.17-4.36-5.17s-4.52,2.02-4.68,5.49l-10.74-2.02c.56-7.91,7.27-11.63,15.83-11.63,9.29,0,14.62,4.04,14.62,12.36v14.54Zm-10.66-4.2c-7.59-.24-10.26,1.78-10.26,5.09,0,2.18,1.53,3.71,4.12,3.71,2.83,0,6.14-1.37,6.14-5.01v-3.8Z"
        />
        <Path
            fill="#2F6E45"
            d="M313.31,75.52c-3.15,1.61-7.02,2.42-11.47,2.42-7.43,0-12.27-3.47-12.27-12.03v-17.93h-5.41v-8.32h5.49v-7.91l10.82-1.29v9.21h11.06v8.32h-11.14v15.5c0,3.96,1.53,5.25,4.52,5.25,2.34,0,4.36-.81,6.54-2.34l1.86,9.13Z"
        />
        <Path
            fill="#2F6E45"
            d="M339.63,75.52c-3.15,1.61-7.02,2.42-11.47,2.42-7.43,0-12.27-3.47-12.27-12.03v-17.93h-5.41v-8.32h5.49v-7.91l10.82-1.29v9.21h11.06v8.32h-11.14v15.5c0,3.96,1.53,5.25,4.52,5.25,2.34,0,4.36-.81,6.54-2.34l1.86,9.13Z"
        />
        <Path
            fill="#2F6E45"
            d="M366.28,64.54l9.21,3.15c-1.94,7.59-8.64,10.25-15.99,10.25-10.66,0-18.01-7.02-18.01-19.06,0-12.84,6.94-20.11,18.17-20.11,10.58,0,15.75,6.94,15.75,16.55,0,1.61-.16,3.31-.48,4.84h-23.01c.24,5.73,2.91,9.45,7.83,9.45,2.91,0,5.33-1.13,6.54-5.09Zm-1.21-10.34c.08-.49,.08-.81,.08-1.29,0-3.47-2.02-6.14-5.9-6.14s-6.62,3.07-7.02,7.43h12.84Z"
        />
        <Path
            fill="#2F6E45"
            d="M392.2,46.85c2.02-5.57,6.22-8.07,11.95-8.07s10.98,3.39,10.98,11.79v26.41h-10.82v-22.37c0-4.12-1.13-6.78-5.01-6.78-3.47,0-6.78,1.94-6.78,8.64v20.51h-10.82V39.66h9.21l1.29,7.19Z"
        />
        <Path
            fill="#2F6E45"
            d="M448.16,75.52c-3.15,1.61-7.02,2.42-11.47,2.42-7.43,0-12.27-3.47-12.27-12.03v-17.93h-5.41v-8.32h5.49v-7.91l10.82-1.29v9.21h11.06v8.32h-11.14v15.5c0,3.96,1.53,5.25,4.52,5.25,2.34,0,4.36-.81,6.54-2.34l1.86,9.13Z"
        />
        <Path
            fill="#2F6E45"
            d="M458.25,21.98c3.8,0,6.7,2.18,6.7,6.3s-2.91,6.14-6.7,6.14-6.54-2.18-6.54-6.14,2.91-6.3,6.54-6.3Zm5.41,17.68v37.31h-10.82V39.66h10.82Z"
        />
        <Path
            fill="#2F6E45"
            d="M469.79,58.24c0-12.68,7.19-19.46,18.09-19.46s18.01,6.78,18.01,19.46-7.19,19.7-18.01,19.7-18.09-7.1-18.09-19.7Zm10.66,0c0,7.75,2.34,11.3,7.43,11.3s7.35-3.55,7.35-11.3c0-7.35-2.42-10.82-7.35-10.82s-7.43,3.47-7.43,10.82Z"
        />
        <Path
            fill="#2F6E45"
            d="M522.52,46.85c2.02-5.57,6.22-8.07,11.95-8.07s10.98,3.39,10.98,11.79v26.41h-10.82v-22.37c0-4.12-1.13-6.78-5.01-6.78-3.47,0-6.78,1.94-6.78,8.64v20.51h-10.82V39.66h9.21l1.29,7.19Z"
        />
        <Path
            fill="#2F6E45"
            d="M98.6,49.69h-19.88v-15.3c0-8-6.51-14.52-14.52-14.52s-14.52,6.51-14.52,14.52v24.45h9.16v-24.45c0-2.95,2.4-5.36,5.36-5.36s5.36,2.4,5.36,5.36v35.17h-19.88v29.81h-15.3c-8,0-14.52,6.51-14.52,14.52s6.51,14.52,14.52,14.52h4.58v-9.16h-4.58c-2.95,0-5.36-2.4-5.36-5.36s2.4-5.36,5.36-5.36h15.3v35.17c0,8,6.51,14.52,14.52,14.52s14.52-6.51,14.52-14.52v-44.26h-9.16v44.26c0,2.95-2.4,5.36-5.36,5.36s-5.36-2.4-5.36-5.36V78.72h10.72v9.94h9.16v-29.81h10.72v69.56h39.75v15.3c0,8,6.51,14.52,14.52,14.52s14.52-6.51,14.52-14.52v-24.45h-9.16v24.45c0,2.95-2.4,5.36-5.36,5.36s-5.36-2.4-5.36-5.36v-35.17h19.88v-29.81h15.3c8,0,14.52-6.51,14.52-14.52s-6.51-14.52-14.52-14.52h-4.58v9.16h4.58c2.95,0,5.36,2.4,5.36,5.36s-2.4,5.36-5.36,5.36h-15.3V34.39c0-8-6.51-14.52-14.52-14.52s-14.52,6.51-14.52,14.52v44.26h9.16V34.39c0-2.95,2.4-5.36,5.36-5.36s5.36,2.4,5.36,5.36V99.38h-10.72v-9.94h-9.16v29.81h-10.72V49.69h-9.16V119.25h-10.72V49.69Z"
        />
        <Path
            fill="#2F6E45"
            d="M207.91,64.2c0-17.5-13.14-32-30.08-34.12C175.71,13.14,161.21,0,143.71,0c-18.96,0-34.39,15.43-34.39,34.39v4.58h9.16v-4.58c0-13.91,11.32-25.23,25.23-25.23s25.23,11.32,25.23,25.23v4.58h4.58c13.91,0,25.23,11.32,25.23,25.23,0,8.47-4.22,16.33-11.29,21.03l-5.74,3.81,5.74,3.81c7.07,4.7,11.29,12.56,11.29,21.03,0,12.35-8.92,22.65-20.65,24.81v-11.03c5.77-1.92,9.94-7.37,9.94-13.78,0-8-6.51-14.52-14.52-14.52h-4.58v9.16h4.58c2.95,0,5.36,2.4,5.36,5.36s-2.4,5.36-5.36,5.36h-4.58v24.45c0,13.91-11.32,25.23-25.23,25.23s-25.23-11.32-25.23-25.23v-4.58h-9.16v4.58c0,18.96,15.43,34.39,34.39,34.39,17.41,0,31.85-13.01,34.08-29.81,0-.07,.04-.27,.04-.27,0,0,.21-.03,.27-.04,16.79-2.39,29.81-16.67,29.81-34.08,0-9.49-3.88-18.41-10.61-24.84,6.73-6.43,10.61-15.36,10.61-24.84Z"
        />
        <Path
            fill="#2F6E45"
            d="M0,113.89c0,17.5,13.14,32,30.08,34.12,2.13,16.94,16.62,30.08,34.12,30.08,18.96,0,34.39-15.43,34.39-34.39v-4.58s-9.16,0-9.16,0v4.58c0,13.91-11.32,25.23-25.23,25.23s-25.23-11.32-25.23-25.23v-4.58s-4.58,0-4.58,0c-13.91,0-25.23-11.32-25.23-25.23,0-8.47,4.22-16.33,11.29-21.03l5.74-3.81-5.74-3.81c-7.07-4.7-11.29-12.56-11.29-21.03,0-12.35,8.92-22.65,20.65-24.81v11.03c-5.77,1.92-9.94,7.37-9.94,13.78,0,8,6.51,14.52,14.52,14.52h4.58s0-9.16,0-9.16h-4.58c-2.95,0-5.36-2.4-5.36-5.36,0-2.95,2.4-5.36,5.36-5.36h4.58s0-19.88,0-19.88v-4.58c0-13.91,11.32-25.23,25.23-25.23s25.23,11.32,25.23,25.23v4.58s9.16,0,9.16,0v-4.58C98.6,15.43,83.17,0,64.2,0,46.79,0,32.36,13.01,30.12,29.81c0,.07-.04,.27-.04,.27,0,0-.21,.03-.27,.04C13.03,32.51,0,46.79,0,64.2c0,9.49,3.88,18.41,10.61,24.84C3.88,95.48,0,104.41,0,113.89Z"
        />
    </Svg>
);

export interface ScenarioDetailPdfProps {
    scenario: ScenarioWithComparisonResponseDto;
    scenarioLevelResultsComparison: GetScenarioLevelResultComparisonResponseDto;
    images?: Array<string>;
    adClient?: AdClientDto;
}

export const ScenarioDetailPdf: FC<ScenarioDetailPdfProps> = ({
    scenario,
    scenarioLevelResultsComparison,
    adClient,
    images,
}) => {
    const { formatNumber, getReachValue } = useHelper();
    let { getPercentageChangeAndCodedCommentary } = useGetPercentageChangeAndCodedCommentary();

    let { percentageChange, codedCommentary } = getPercentageChangeAndCodedCommentary(
        scenarioLevelResultsComparison,
    );

    const isBaseScenario = (): boolean => {
        return (
            (scenario.optimisationStrategy as OptimisationStrategy) === OptimisationStrategy.Base
        );
    };

    return (
        <Document>
            <Page wrap style={styles.page} orientation="landscape">
                <AttentionPlanLogo />
                <View style={styles.title}>
                    <Text>{scenario.campaignBasicInfo?.campaignName}</Text>
                    {adClient?.adClientName && (
                        <Text style={styles.lastModifiedAt}>
                            Brand - {adClient?.adClientName}, Last modified at{' '}
                            {scenario.campaignBasicInfo?.updatedAt?.toLocaleString()}{' '}
                        </Text>
                    )}
                </View>
                <View style={styles.constraints}>
                    <Text style={styles.sectionTitle}>Scenario Parameters</Text>
                    <View style={styles.constraintsRow}>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>NAME</Text>
                            <Text>{scenario.scenarioName}</Text>
                        </View>
                    </View>
                    <View style={styles.constraintsRow}>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>LAST MODIFIED AT</Text>
                            <Text>{scenario.updatedAt?.toLocaleString()}</Text>
                        </View>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>START DATE</Text>
                            <Text>{scenario.startDate?.toLocaleDateString()}</Text>
                        </View>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>END DATE</Text>
                            <Text>{scenario.endDate?.toLocaleDateString()}</Text>
                        </View>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>LOCATION (COUNTRY)</Text>
                            {scenario.countries && scenario.countries?.length > 0 ? (
                                <Text>{scenario.countries?.join(' ')}</Text>
                            ) : (
                                <Text>Global</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.constraintsRow}>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>BUDGET</Text>
                            <Text>
                                {formatNumber(
                                    (scenario.budget as DecimalComparisonValue).value,
                                    false,
                                    2,
                                    '$',
                                )}
                            </Text>
                        </View>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>BUDGET CONSTRAINTS</Text>
                            <Text>
                                {scenario.budgetConstraintType === 'adjustment_tolerance_level'
                                    ? 'Plan Tolerance'
                                    : 'Minimum Spend'}
                            </Text>
                        </View>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>FORMATS</Text>
                            <View style={{ flexDirection: 'row' }}>
                                {scenario.scenarioLevelResults
                                    ?.at(0)
                                    ?.channelLevelResults?.map(
                                        (channel, index, channelLevelResults) => (
                                            <View key={channel.id} style={{ marginRight: 3 }}>
                                                <Text>
                                                    {`${channel.adChannel?.adChannelName} (${
                                                        channel.formatLevelResults?.length ?? 0
                                                    })`}
                                                    {index < channelLevelResults.length - 1 && ', '}
                                                </Text>
                                            </View>
                                        ),
                                    )}
                            </View>
                        </View>
                    </View>
                    <View style={styles.constraintsRow}>
                        <View style={styles.constraints.constraint}>
                            <Text style={styles.constraints.label}>OPTIMISATION OBJECTIVE</Text>
                            <Text>
                                {scenario.optimisationStrategy &&
                                    getOptimisationObjective(scenario.optimisationStrategy)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.body}>
                    <Text style={styles.sectionTitle}>Scenario mix detailed channel view</Text>
                    <View style={styles.detailedTable}>
                        <View style={styles.detailedTableHeader}>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>CHANNEL</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>BUDGET ($)</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>MIX(%)</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>IMPRESSIONS</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>REACH %</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCell}>
                                <Text>TARGET</Text>
                                <Text>AUDIENCE</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCellLong}>
                                <Text>ACTIVE ATTENTION</Text>
                                <Text>VOLUME (SECONDS)</Text>
                            </View>
                            <View style={styles.detailedTableHeaderCellLong}>
                                <Text>PASSIVE ATTENTION</Text>
                                <Text>VOLUME (SECONDS)</Text>
                            </View>
                        </View>
                        <View key="">
                            {scenario?.scenarioLevelResults
                                ?.at(0)
                                ?.channelLevelResults?.map((cr) => (
                                    <View style={styles.detailedTableRow} key={cr.id}>
                                        <View style={styles.detailedTableCellLeft}>
                                            <Text>{cr.adChannel?.adChannelName}</Text>
                                        </View>
                                        <View style={styles.detailedTableCell}>
                                            <ComparisonCell
                                                comparisonValue={cr.budget}
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCell}>
                                            <ComparisonCell
                                                comparisonValue={cr.mix}
                                                isPercentage
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCell}>
                                            <ComparisonCell
                                                comparisonValue={cr.impression}
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCell}>
                                            <ComparisonCell
                                                comparisonValue={cr.reachPercentage}
                                                isPercentage
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCell}>
                                            <ComparisonCell
                                                comparisonValue={cr.targetAudience}
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCellLong}>
                                            <ComparisonCell
                                                comparisonValue={cr.activeAttentionVolumeSeconds}
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                        <View style={styles.detailedTableCellLong}>
                                            <ComparisonCell
                                                comparisonValue={cr.passiveAttentionVolumeSeconds}
                                                isBaseScenario={isBaseScenario()}
                                            />
                                        </View>
                                    </View>
                                ))}
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={styles.sectionTitle}>Scenario mix detailed format view</Text>
                    <View style={styles.detailedTable}>
                        <View style={styles.detailedTableHeader}>
                            <View style={styles.detailedFormatTableHeaderCell}>
                                <Text>CHANNEL</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCellLong}>
                                <Text>FORMAT</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCell}>
                                <Text>BUDGET ($)</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCell}>
                                <Text>MIX(%)</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCell}>
                                <Text>IMPRESSIONS</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCellLong}>
                                <Text>ACTIVE ATTENTION VOLUME(S)</Text>
                            </View>
                            <View style={styles.detailedFormatTableHeaderCellLong}>
                                <Text>PASSIVE ATTENTION VOLUME(S)</Text>
                            </View>
                        </View>
                        <View>
                            {scenario?.scenarioLevelResults
                                ?.at(0)
                                ?.channelLevelResults?.map((cr) => (
                                    <View style={styles.detailedTableFormatRow} key={cr.id}>
                                        <View style={styles.detailedFormatTableCellLeft}>
                                            <Text>{cr.adChannel?.adChannelName}</Text>
                                        </View>
                                        <View style={styles.detailedTableFormatRowStatistic}>
                                            {cr.formatLevelResults?.map((fr) => (
                                                <View style={styles.detailedTableRow} key={fr.id}>
                                                    <View
                                                        style={
                                                            styles.detailedFormatTableCellLongLeft
                                                        }
                                                    >
                                                        <Text>{fr.adFormat?.adFormatName}</Text>
                                                    </View>
                                                    <View style={styles.detailedFormatTableCell}>
                                                        <ComparisonCell
                                                            comparisonValue={fr.budget}
                                                            isBaseScenario={isBaseScenario()}
                                                        />
                                                    </View>
                                                    <View style={styles.detailedFormatTableCell}>
                                                        <ComparisonCell
                                                            comparisonValue={fr.mix}
                                                            isPercentage
                                                            isBaseScenario={isBaseScenario()}
                                                        />
                                                    </View>
                                                    <View style={styles.detailedFormatTableCell}>
                                                        <ComparisonCell
                                                            comparisonValue={fr.impression}
                                                            isBaseScenario={isBaseScenario()}
                                                        />
                                                    </View>
                                                    <View
                                                        style={styles.detailedFormatTableCellLong}
                                                    >
                                                        <ComparisonCell
                                                            comparisonValue={
                                                                fr.activeAttentionVolumeSeconds
                                                            }
                                                            isBaseScenario={isBaseScenario()}
                                                        />
                                                    </View>
                                                    <View
                                                        style={styles.detailedFormatTableCellLong}
                                                    >
                                                        <ComparisonCell
                                                            comparisonValue={
                                                                fr.passiveAttentionVolumeSeconds
                                                            }
                                                            isBaseScenario={isBaseScenario()}
                                                        />
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                ))}
                        </View>
                    </View>
                </View>

                <View style={styles.keyOptimisedMetrics} break>
                    <Text style={styles.sectionTitle}>
                        Understand how the optimised scenario performs comparing to the original
                        media plan.
                    </Text>
                    <View style={styles.keyOptimisedMetricsBody}>
                        <View style={styles.keyOptimisedMetricsTable}>
                            <View style={styles.keyOptimisedMetricsTableHeader}>
                                <View style={styles.keyOptimisedMetricsTableHeaderField}>
                                    <Text>Drivers</Text>
                                </View>

                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <Text>{scenario.scenarioName}</Text>
                                    </View>
                                )}

                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <Text>BASE</Text>
                                </View>
                            </View>
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Attention seconds per reach</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionSecPerReach
                                            }
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionSecPerReach as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Attention seconds per impression</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionSecPerImpression
                                            }
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionSecPerImpression as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Brand uplift</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)?.shortTermLift
                                            }
                                            isPercentage
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.shortTermLift as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isPercentage
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                            {/* commented out as LTL is broken */}
                            {/* <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Long-term lift % (LTL)</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)?.longTermLift
                                            }
                                            isPercentage
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.longTermLift as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isPercentage
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View> */}
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Impressions</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)?.impression
                                            }
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.impression as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Reach</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={getReachValue(
                                                scenario.scenarioLevelResults?.at(0),
                                            )}
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                getReachValue(
                                                    scenario.scenarioLevelResults?.at(0),
                                                ) as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                            <View style={styles.keyOptimisedMetricsTableRow}>
                                <View style={styles.keyOptimisedMetricsTableField}>
                                    <Text>Active attention volume seconds</Text>
                                </View>
                                {!isBaseScenario() && (
                                    <View style={styles.keyOptimisedMetricsTableValue}>
                                        <ComparisonCell
                                            comparisonValue={
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionVolumeSeconds
                                            }
                                            isBaseScenario={isBaseScenario()}
                                        />
                                    </View>
                                )}
                                <View style={styles.keyOptimisedMetricsTableValue}>
                                    <ComparisonCell
                                        comparisonValue={
                                            (
                                                scenario.scenarioLevelResults?.at(0)
                                                    ?.activeAttentionVolumeSeconds as DecimalComparisonValue
                                            ).comparedWithValue
                                        }
                                        isBaseScenario={isBaseScenario()}
                                    />
                                </View>
                            </View>
                        </View>
                        {!isBaseScenario() && (
                            <View style={styles.keyOptimisedMetricsChart}>
                                <View style={styles.keyOptimisedMetricsChartOutcome}>
                                    <ArrowRightUp />
                                    <Text style={styles.keyOptimisedMetricsChartOutcomeName}>
                                        {`${getOptimisationObjective(
                                            scenario.optimisationStrategy!,
                                            'acronym',
                                        )} change`}
                                    </Text>
                                    <Text style={styles.keyOptimisedMetricsChartOutcomeNumber}>
                                        {formatNumber(percentageChange, true)}
                                    </Text>
                                </View>
                                <View style={styles.keyOptimisedMetricsChartDescription}>
                                    <Text style={styles.keyOptimisedMetricsChartDescriptionTitle}>
                                        {getOptimisationObjective(
                                            scenario.optimisationStrategy!,
                                            'verbose',
                                        )}
                                    </Text>
                                    <Text>{codedCommentary}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <Text
                    style={styles.footer}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
                {images &&
                    images.length > 0 &&
                    images.map((image, index, array) => {
                        if (index % 2 === 0) {
                            return (
                                <View break key={index}>
                                    <Image style={styles.image} src={image} />
                                    {index + 1 < array.length && (
                                        <Image style={styles.secondImage} src={array[index + 1]} />
                                    )}
                                </View>
                            );
                        }
                        return <></>;
                    })}
            </Page>
        </Document>
    );
};
