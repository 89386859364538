import { GetScenarioBasicInfoResponseDto, ScenarioApi } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';
import { ShareScenarioApi } from '@api-clients/media-plan/apis/ShareScenarioApi';

type useGetShareScenarioBasicInfoHook = () => {
    getShareScenarioBasicInfo: (
        scenarioShareLinkId: string,
    ) => Promise<GetScenarioBasicInfoResponseDto>;
};

export const useGetShareScenarioBasicInfo: useGetShareScenarioBasicInfoHook = () => {
    const { getMediaPlanConfigWithoutCredentials } = useApiConfiguration()
    const { getDisplayedErrorMessage } = useHelper();
    const getShareScenarioBasicInfo = async (scenarioShareLinkId: string) => {
        try {
            const config = await getMediaPlanConfigWithoutCredentials();
            const api = new ShareScenarioApi(config);
            return await api.getSharedScenarioBasicInfoByShareLinkId({ scenarioShareLinkId });
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getShareScenarioBasicInfo };
};
