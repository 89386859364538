import { ArrowBackIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Heading,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Portal,
    Stack,
    Text,
} from '@chakra-ui/react';
import { routing, urls } from '@configs';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NoCampaignsLeftCard = () => {
    const { t } = useTranslation('mediaPlans');
    const navigate = useNavigate();
    return (
        <Card>
            <CardHeader>
                <Heading size="md">No campaigns left</Heading>
            </CardHeader>
            <CardBody>
                <Stack>
                    <Text>
                        Please contact our support team at{' '}
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urls.customerSupportEmailUrl}
                        >
                            {urls.customerSupportEmail}
                        </Link>{' '}
                        to purchase more campaigns
                    </Text>
                </Stack>
            </CardBody>
            <CardFooter>
                <Box>
                    <Button
                        onClick={() => navigate(`/${routing.campaign.root.path}`)}
                        leftIcon={<ArrowBackIcon />}
                        variant="link"
                    >
                        Go back to campaigns
                    </Button>
                </Box>
            </CardFooter>
        </Card>
    );
};
