/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ScenarioMinSpendPerChannelRequestDto
 */
export interface ScenarioMinSpendPerChannelRequestDto {
    /**
     *
     * @type {string}
     * @memberof ScenarioMinSpendPerChannelRequestDto
     */
    channelCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof ScenarioMinSpendPerChannelRequestDto
     */
    minSpendPercentage?: number;
}

/**
 * Check if a given object implements the ScenarioMinSpendPerChannelRequestDto interface.
 */
export function instanceOfScenarioMinSpendPerChannelRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioMinSpendPerChannelRequestDtoFromJSON(
    json: any,
): ScenarioMinSpendPerChannelRequestDto {
    return ScenarioMinSpendPerChannelRequestDtoFromJSONTyped(json, false);
}

export function ScenarioMinSpendPerChannelRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioMinSpendPerChannelRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
        minSpendPercentage: !exists(json, 'min_spend_percentage')
            ? undefined
            : json['min_spend_percentage'],
    };
}

export function ScenarioMinSpendPerChannelRequestDtoToJSON(
    value?: ScenarioMinSpendPerChannelRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        channel_code: value.channelCode,
        min_spend_percentage: value.minSpendPercentage,
    };
}
