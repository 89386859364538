import {
    AuthApi,
    AuthResponseDto,
    ChangePasswordRequest,
    ChangePasswordRequestDto,
} from '@api-clients/account-manager';
import { useApiConfiguration } from '@hooks/configuration';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { passwordRequirementsRegex, useHelper } from '@shared/utils';
import { BaseFormHookProps } from '@hooks/BaseFormHookProps';

interface useChangePasswordHookProps extends BaseFormHookProps {
    changePassword: (request: ChangePasswordRequestDto) => Promise<AuthResponseDto>;
}

type useChangePasswordHook = () => useChangePasswordHookProps;

export const useChangePassword: useChangePasswordHook = () => {
    const { t } = useTranslation();
    const { getDisplayedErrorMessage } = useHelper();
    const { getAccountManagerConfig } = useApiConfiguration();

    const validationSchema = yup.object().shape({
        currentPassword: yup
            .string()
            .required(t('messages.forms.validation.password.current.isRequired')),
        newPassword: yup
            .string()
            .required(t('messages.forms.validation.password.new.isRequired'))
            .matches(
                passwordRequirementsRegex,
                t('messages.forms.validation.password.new.minRequirements'),
            ),
        confirmPassword: yup
            .string()
            .required(t('messages.forms.validation.password.confirmed.isRequired'))
            .oneOf(
                [yup.ref('newPassword')],
                t('messages.forms.validation.password.confirmed.requiresMatched'),
            ),
    });

    async function changePassword(
        changePasswordDto: ChangePasswordRequestDto,
    ): Promise<AuthResponseDto> {
        let configuration = await getAccountManagerConfig();
        let request: ChangePasswordRequest = {
            changePasswordRequestDto: changePasswordDto,
        };

        try {
            const api = new AuthApi(configuration);
            return await api.changePassword(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        changePassword,
        validationSchema,
    };
};
