import React, {FC} from 'react'
import {Box, Button, Icon, Wrap} from "@chakra-ui/react";
import {ProveDashboardSearchCriteriaProps} from "@components/organisms/prove/ProveDashboardHeader";
import {ProveFilter} from "@api-clients/prove/schema/ProveFilter";
import {CloseIcon} from "@chakra-ui/icons";
import {useSelector} from "@redux";
import {proveAvailableAdFormats} from "@redux/slices/prove/adFormat/selectors";

export interface ProveDashboardSelectedFiltersProps {
    searchCriteria: ProveDashboardSearchCriteriaProps;
    filters: Array<ProveFilter>;

    setFilters(filters: Array<ProveFilter>): void;
}

export const ProveDashboardSelectedFilters: FC<ProveDashboardSelectedFiltersProps> = ({
                                                                                          searchCriteria,
                                                                                          filters,
                                                                                          setFilters
                                                                                      }) => {

    const availableAdFormatsSelector = useSelector(proveAvailableAdFormats);

    const resolveAdFormatName = (value: string) => {
        for (let i = 0; i < availableAdFormatsSelector!.length; i++) {
            const adFormat = availableAdFormatsSelector![i];

            if (adFormat.code === value) {
                return adFormat.name;
            }
        }

        return value;
    }

    const resolveLineItemName = (value: string) => {
        for (let i = 0; i < searchCriteria.selectedLineItems.length; i++) {
            const lineItem = searchCriteria.selectedLineItems[i];

            if (lineItem.externalId === value) {
                return lineItem.name;
            }
        }

        return value;
    }

    const valueFormatter = (filter: ProveFilter) => {
        switch (filter.type) {
            case 'lineItem':
                return resolveLineItemName(filter.value);
            case 'adFormat':
                return resolveAdFormatName(filter.value);
            default:
                return filter.value;
        }
    }

    return (
        <>
            {(availableAdFormatsSelector && filters.length > 0 &&
                <Box mb={4}>
                    <Wrap>
                        {filters.map((entry) => {
                            return (
                                <Button
                                    key={entry.type}
                                    size={"sm"}
                                    rightIcon={
                                        <Icon as={CloseIcon} boxSize="0.5rem"/>
                                    }
                                    type="button"
                                    colorScheme="gray"
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => {
                                        const updatedFilters = [];

                                        for (let i = 0; i < filters.length; i++) {
                                            if (filters[i].type !== entry.type) {
                                                updatedFilters.push(filters[i]);
                                            }
                                        }

                                        setFilters(updatedFilters);
                                    }}
                                >
                                    {valueFormatter(entry)}
                                </Button>);
                        })
                        }
                    </Wrap>
                </Box>
            )}
        </>
    )

}
