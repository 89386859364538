import { useState } from 'react';

const isDevelopment = import.meta.env.DEV;

export const useLocalStorage = (keyName: string, defaultValue: any = null): any => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value !== null) {
                return JSON.parse(value);
            }

            window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        } catch (e) {
            // ignoring error and returning default value
        }

        return defaultValue;
    });

    const setValue = (newValue: any): void => {
        try {
            const serializedValue = JSON.stringify(newValue);
            if (serializedValue === undefined) {
                throw new Error(`Trying to set ${keyName} to undefined`);
            }
            window.localStorage.setItem(keyName, serializedValue);
        } catch (e) {
            if (isDevelopment) {
                // help to catch bugs on dev mode
                // eslint-disable-next-line
                console.error(`Unable to set key ${keyName} to ${newValue}`, (e as Error).stack);
            }
        } finally {
            setStoredValue(newValue);
        }
    };

    return [storedValue, setValue];
};
