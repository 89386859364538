import { RawImportedDataRequestDto } from '@api-clients/media-plan';
import { importTemplate } from '@configs';
import { AllChannels, isChannel, isTable, Row } from '@shared/cores/types';
import * as XLSX from 'xlsx';

const { sheetName } = importTemplate;

export interface TemplateDTO {
    channel: string;
    budget: number;
    cpm: number;
    reach: number;
    totalAudience: number;
}

const isPositiveNumber = (value: any): boolean => typeof value === 'number' && value >= 0;
const isArrayValuesUnique = (array: Array<any>): boolean => new Set(array).size === array.length;

export const isAtLeastTwoNonZeroRows = (table: Array<Row>): boolean => {
    return (
        table.filter((row) => Object.entries(row).every(([key, value]) => value !== 0)).length >= 2
    );
};

export const totalAudienceCheck = (table: Array<Row>): boolean => {
    return table.every((row) => {
        // only do this validation if the row is otherwise a valid row (all values greater than zero)
        if (
            row.Budget > 0 &&
            row.CPM > 0 &&
            row['Average frequency'] > 0 &&
            row['Potential target audience size'] > 0
        ) {
            return (
                Math.round((row.Budget / row.CPM) * 1000) / row['Average frequency'] <
                row['Potential target audience size']
            );
        }
        return true;
    });
};

export const frequencyValueCheck = (table: Array<Row>): boolean => {
    return table.every((row) => {
        if (row.Budget > 0 && row.CPM > 0) {
            return row['Average frequency'] >= 1;
        }
        return true;
    });
};

const isCellValuesOfRowValid = (row: Row): boolean =>
    isChannel(row.Channel) &&
    isPositiveNumber(row.Budget) &&
    isPositiveNumber(row.CPM) &&
    isPositiveNumber(row['Average frequency']) &&
    isPositiveNumber(row['Potential target audience size']);

const transformData = (table: Array<Row>): Array<Row> => {
    table.forEach((row: Row) => {
        if (
            row.Budget === undefined ||
            row.CPM === undefined ||
            row['Average frequency'] === undefined ||
            row['Potential target audience size'] === undefined ||
            row.Budget === 0 ||
            row.CPM === 0 ||
            row['Potential target audience size'] === 0
        ) {
            row.Budget = 0;
            row.CPM = 0;
            row['Reach (%)'] = 0;
            row['Potential target audience size'] = 0;
            row['Impressions (M)'] = 0;
            row['Target audience reached'] = 0;
            row.TRP = 0;
            row.CPP = 0;
        }
    });

    return table;
};

export const fileToRowArray = async (file: File): Promise<Array<Row>> => {
    const rowNo = AllChannels.length + 1;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[sheetName];
    worksheet['!ref'] = `A2:J${rowNo}`;
    const worksheetData: Array<Row> = XLSX.utils.sheet_to_json(worksheet, { defval: 0 });

    return transformData(worksheetData);
};

export const isTemplateFileValid = (worksheetData: Array<Row>): boolean => {
    return (
        isTable(worksheetData) &&
        worksheetData.every(isCellValuesOfRowValid) &&
        isArrayValuesUnique(worksheetData.map((row) => row.Channel))
    );
};

export const convertTemplateToDTO = async (
    file: File,
): Promise<Array<RawImportedDataRequestDto> | undefined> => {
    let worksheetData: Array<Row> = await fileToRowArray(file);

    if (isTable(worksheetData)) {
        return worksheetData.map((row) => ({
            channelName: row.Channel,
            budget: row.Budget,
            cpm: row.CPM,
            frequency: row['Average frequency'],
            totalAudience: row['Potential target audience size'],
            impressions: row['Impressions (M)'],
            targetAudience: row['Target audience reached'],
            reachPercentage: row['Reach (%)'],
            trp: row['TRP'],
            cpp: row['CPP'],
        }));
    }
    return undefined;
};
