import { ScenarioWithComparisonResponseDto } from '@api-clients/media-plan';
import { useHelper } from '@shared/utils';
import {
    GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ShareScenarioApi,
} from '@api-clients/media-plan/apis/ShareScenarioApi';
import { useApiConfiguration } from '@hooks/configuration';

type useGetSharedScenarioComparisonHook = () => {
    getSharedScenarioComparison: (
        requestParams: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ) => Promise<ScenarioWithComparisonResponseDto>;
};

export const useGetSharedScenarioComparison: useGetSharedScenarioComparisonHook = () => {
    const { getDisplayedErrorMessage } = useHelper();
    const { getMediaPlanConfigWithoutCredentials } = useApiConfiguration()

    const getSharedScenarioComparison = async (
        requestParameters: GetASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    ) => {
        try {
            const config = await getMediaPlanConfigWithoutCredentials()
            const api = new ShareScenarioApi(config);
            return await api.getASharedScenarioComparedWithAGivenScenarioOrTheBaselineScenario(
                requestParameters,
            );
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    };
    return { getSharedScenarioComparison };
};
