import { getRandomColor } from '@shared/utils';

const BubbleColors: { hsl: string }[] = Array.from({ length: 256 }, (_) => {
    return { hsl: getRandomColor() };
});

export const getBubbleColor = (index: number): string => {
    if (index < 0 || index >= BubbleColors.length) {
        return 'hsl(0, 0%, 0%)'; // black
    }
    return BubbleColors[index].hsl;
};

export const flameColors = {
    gray: 'rgba(199, 200, 202, 1)',
    xl: 'rgba(233, 62, 58, 1)',
    lg: 'rgba(237, 104, 60, 1)',
    md: 'rgba(243, 144, 63, 1)',
    sm: 'rgba(253, 199, 12, 1)',
    xs: 'rgba(255, 243, 59, 1)',
};
export type FlameColorCategory = keyof typeof flameColors;
