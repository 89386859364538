import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Input,
    InputGroup,
    Select,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ViewOnlyFormProps } from '@components/molecules/formInput';
import { ComponentType } from '@shared/cores/types';
import { getListRenderKey } from '@shared/utils';
import { FC, HTMLInputTypeAttribute } from 'react';
import {
    FieldErrors,
    FieldPath,
    FieldValues,
    RegisterOptions,
    UseFormRegisterReturn,
} from 'react-hook-form';

interface EditableFormProps<TFormDataType extends FieldValues> {
    formRegister: (
        name: FieldPath<TFormDataType>,
        options?: RegisterOptions<TFormDataType>,
    ) => UseFormRegisterReturn<FieldPath<TFormDataType>>;
    formErrors: FieldErrors<TFormDataType>;
    inputName: FieldPath<TFormDataType>;
}

export interface SelectInputOptions {
    variant?: string;
    optionList: any[];
    optionKey?: string;
    optionLabelFormat: (...option: any) => string;
    filter?: (option: any) => boolean;
}

export interface GroupInputOptions {
    leftAddon?: JSX.Element;
    input: JSX.Element;
    rightAddon?: JSX.Element;
}

export interface BaseFormProps<TFormData extends FieldValues, TValuesObject = Record<string, any>>
    extends EditableFormProps<TFormData>,
        ViewOnlyFormProps<TValuesObject> {
    inputType?: HTMLInputTypeAttribute;
    componentType?: ComponentType;
    selectOptions?: SelectInputOptions;
    groupOptions?: GroupInputOptions;
    formDisplay?: 'block' | 'grid';
    isRequired?: boolean;
}

const EditTextInput =
    <TFormData extends FieldValues, TValuesObject = Record<string, any>>(): FC<
        BaseFormProps<TFormData, TValuesObject>
    > =>
    ({ inputType = 'text', inputName, defaultValue, formErrors, formRegister }) => {
        return (
            <Box width="100%">
                <Input
                    type={inputType}
                    fontWeight="normal"
                    defaultValue={defaultValue ?? ''}
                    {...formRegister!(inputName)}
                />
                <FormErrorMessage>
                    {formErrors![inputName] !== undefined &&
                        (formErrors![inputName]!.message as string)}
                </FormErrorMessage>
            </Box>
        );
    };

const EditSelectInput =
    <TFormData extends FieldValues, TValuesObject = Record<string, any>>(): FC<
        BaseFormProps<TFormData, TValuesObject>
    > =>
    ({ inputName, defaultValue, formErrors, formRegister, selectOptions }) => {
        return (
            <>
                <Select
                    variant={selectOptions?.variant}
                    defaultValue={defaultValue ?? ''}
                    {...formRegister(inputName)}
                >
                    {selectOptions && (
                        <>
                            {(selectOptions.filter !== undefined
                                ? selectOptions.optionList.filter((option) =>
                                      selectOptions.filter!(option),
                                  )
                                : selectOptions.optionList
                            ).map((option: any, index) => (
                                <option
                                    key={getListRenderKey(index, option)}
                                    value={option}
                                    label={selectOptions?.optionLabelFormat(option)}
                                >
                                    {option}
                                </option>
                            ))}
                        </>
                    )}
                </Select>
            </>
        );
    };

const EditGroupInput =
    <TFormData extends FieldValues, TValuesObject = Record<string, any>>(): FC<
        BaseFormProps<TFormData, TValuesObject>
    > =>
    ({ inputName, data, formErrors, formRegister, groupOptions }) => {
        return (
            <Box width="100%">
                <InputGroup>
                    {groupOptions && (
                        <>
                            {groupOptions.leftAddon}
                            {groupOptions.input}
                            {groupOptions.rightAddon}
                        </>
                    )}
                </InputGroup>
                <FormErrorMessage>
                    {formErrors![inputName] !== undefined &&
                        (formErrors![inputName]!.message as string)}
                </FormErrorMessage>
            </Box>
        );
    };

export const EditableFormInput =
    <TFormData extends FieldValues, TValuesObject = Record<string, any>>(): FC<
        BaseFormProps<TFormData, TValuesObject>
    > =>
    ({
        label,
        inputName,
        defaultValue,
        data,
        formErrors,
        formRegister,
        componentType = 'text',
        selectOptions,
        groupOptions,
        formDisplay = 'grid',
        isRequired,
        inputType = 'text',
    }) => {
        const EditInput = EditTextInput<TFormData, TValuesObject>();
        const SelectInput = EditSelectInput<TFormData, TValuesObject>();
        const GroupInput = EditGroupInput<TFormData, TValuesObject>();

        const InputLabel = (
            <Flex height="100%" alignItems="center">
                <Text margin={0}>{label}</Text>
            </Flex>
        );

        const TextInputRender = (
            <EditInput
                inputType={inputType}
                formRegister={formRegister}
                formErrors={formErrors}
                inputName={inputName}
                label={label}
                data={data}
                defaultValue={defaultValue}
            />
        );

        const SelectInputRender = (
            <SelectInput
                formRegister={formRegister}
                formErrors={formErrors}
                inputName={inputName}
                data={data}
                selectOptions={selectOptions}
                defaultValue={defaultValue}
            />
        );

        const GroupInputRender = (
            <GroupInput
                formRegister={formRegister}
                formErrors={formErrors}
                inputName={inputName}
                data={data}
                groupOptions={groupOptions}
                defaultValue={defaultValue}
            />
        );

        return (
            <FormControl isRequired={isRequired} isInvalid={formErrors![inputName] !== undefined}>
                {formDisplay === 'grid' ? (
                    <Grid
                        height="3rem"
                        marginX="2rem"
                        marginY="1rem"
                        templateColumns="repeat(3, 1fr)"
                        gap={3}
                    >
                        <GridItem colSpan={1}>{InputLabel}</GridItem>
                        <GridItem colSpan={2}>
                            <Flex height="100%" alignItems="center">
                                {componentType === 'text' && <>{TextInputRender}</>}
                                {componentType === 'select' && selectOptions && (
                                    <>{SelectInputRender}</>
                                )}
                                {componentType === 'group' && groupOptions && (
                                    <>{GroupInputRender}</>
                                )}
                            </Flex>
                        </GridItem>
                    </Grid>
                ) : (
                    <VStack align="flex-start" spacing={1}>
                        {InputLabel}
                        {componentType === 'text' && <>{TextInputRender}</>}
                        {componentType === 'select' && selectOptions && <>{SelectInputRender}</>}
                        {componentType === 'group' && groupOptions && <>{GroupInputRender}</>}
                    </VStack>
                )}
            </FormControl>
        );
    };
