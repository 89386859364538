/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface OauthApplicationResponseDto
 */
export interface OauthApplicationResponseDto {
    /**
     *
     * @type {Date}
     * @memberof OauthApplicationResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {string}
     * @memberof OauthApplicationResponseDto
     */
    createdByUserId?: string;
    /**
     *
     * @type {string}
     * @memberof OauthApplicationResponseDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof OauthApplicationResponseDto
     */
    clientId?: string | null;
}

/**
 * Check if a given object implements the OauthApplicationResponseDto interface.
 */
export function instanceOfOauthApplicationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OauthApplicationResponseDtoFromJSON(json: any): OauthApplicationResponseDto {
    return OauthApplicationResponseDtoFromJSONTyped(json, false);
}

export function OauthApplicationResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OauthApplicationResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        clientId: !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function OauthApplicationResponseDtoToJSON(value?: OauthApplicationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        created_by_user_id: value.createdByUserId,
        name: value.name,
        client_id: value.clientId,
    };
}
