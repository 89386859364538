import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Spinner: ComponentStyleConfig = {
    baseStyle: {
        color: 'gray.500',
    },
    defaultProps: {
        size: 'xl',
    },
    sizes: {
        '2xl': {
            boxSize: '5rem',
        },
        '3xl': {
            boxSize: '6rem',
        },
        '4xl': {
            boxSize: '7rem',
        },
    },
};
