import {
    CreateOptimisedScenariosRequestDto,
    GetCampaignByIdRequest,
    GetScenarioResponseDto,
    OptimisationStrategy,
    ScenarioStatus,
} from '@api-clients/media-plan';
import {CampaignBanner, ComparisonSection} from '@apps/attentionADJUST/components/organisms';
import {Stack} from '@chakra-ui/react';
import {useCreateScenarios, useDeleteScenario, useGetScenarios} from '@hooks/scenarios';
import {useCustomToast} from '@hooks/toast';
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLoaderData, useParams} from 'react-router-dom';

const formatStrategyName = (strategy?: OptimisationStrategy): string => {
    switch (strategy) {
        case OptimisationStrategy.AApI:
            return 'AApI';
        case OptimisationStrategy.AApR:
            return 'AApR';
        case OptimisationStrategy.Stl:
            return 'BU';
        case OptimisationStrategy.Ltl:
            return 'LTL';
        case OptimisationStrategy.Base:
        default:
            return '';
    }
};

export const ScenarioCreationPage: FC = () => {
    const {mediaPlanId} = useParams();

    const {t} = useTranslation('mediaPlans');

    const {successToast, errorToast} = useCustomToast();
    const {createScenarios} = useCreateScenarios();
    const {getScenarios} = useGetScenarios();
    const {deleteScenario} = useDeleteScenario();
    const [scenarios, setScenarios] = useState<Array<GetScenarioResponseDto>>();

    const [scenarioPlaceholders, setScenarioPlaceholders] = useState<Array<GetScenarioResponseDto>>(
        [],
    );

    const handleCreateScenario = async (
        createScenarioRequestDto: CreateOptimisedScenariosRequestDto,
    ): Promise<void> => {
        const toastText = t('mediaPlanning.scenarioCreation.generateScenarios.toast', {
            returnObjects: true,
        });

        setScenarioPlaceholders(
            createScenarioRequestDto.optimisationStrategies?.map(
                (optimisationStrategy): GetScenarioResponseDto => ({
                    id: optimisationStrategy,
                    scenarioName: `Generating...`,
                    optimisationStrategy,
                    scenarioStatus: ScenarioStatus.Optimising,
                }),
            ) ?? [],
        );

        let succeededOptimisations: OptimisationStrategy[] = [];
        let failedOptimisations: OptimisationStrategy[] = [];
        let errorSubtitleMessage = t(
            'mediaPlanning.scenarioCreation.generateScenarios.toast.error.optimisationFailedError',
        );

        try {
            const response = await createScenarios(
                mediaPlanId!,
                createScenarioRequestDto,
            );

            succeededOptimisations =
                createScenarioRequestDto.optimisationStrategies?.filter((o) =>
                    response.some((s) => s.optimisationStrategy === o),
                ) ?? [];

            failedOptimisations =
                createScenarioRequestDto.optimisationStrategies?.filter(
                    (x) => !succeededOptimisations.includes(x),
                ) ?? [];
        } catch {
            // set failed optimisations to all optimisations attempted
            failedOptimisations = createScenarioRequestDto.optimisationStrategies ?? [];
            // change error message to be more generic if request fails entirely
            errorSubtitleMessage = 'Something went wrong';
        }

        setScenarios(await getScenarios(mediaPlanId!));

        if (succeededOptimisations.length > 0) {
            successToast(
                toastText.success.heading,
                t('mediaPlanning.scenarioCreation.generateScenarios.toast.success.subtitle', {
                    optimisationObjective: succeededOptimisations
                        .map((r) => formatStrategyName(r))
                        .join(', '),
                }),
            );
        }
        if (failedOptimisations.length > 0) {
            errorToast(
                t('mediaPlanning.scenarioCreation.generateScenarios.toast.error.heading', {
                    optimisationObjective: failedOptimisations
                        .map((r) => formatStrategyName(r))
                        .join(', '),
                }),
                errorSubtitleMessage,
                true,
                'bottom',
                null,
            );
        }
        setScenarioPlaceholders([]);
    };

    useEffect(() => {
        getScenarios(mediaPlanId!).then((r) => setScenarios(r));
    }, [mediaPlanId]);

    const handleDeleteScenario = async (scenarioId: string): Promise<void> => {
        const toastText = t('mediaPlanning.scenarioCreation.comparison.delete.toast', {
            returnObjects: true,
            scenario: scenarios?.find((s) => s.id === scenarioId)?.scenarioName,
        });
        let isError = false;

        try {
            await deleteScenario(mediaPlanId!, scenarioId);
        } catch {
            isError = true;
        } finally {
            setScenarios(await getScenarios(mediaPlanId!));
            if (!isError) {
                successToast(toastText.success.heading, toastText.success.subtitle);
            } else {
                errorToast(toastText.error.heading, toastText.error.subtitle);
            }
        }
    };

    return (
        <>
            <Stack spacing="1.25rem">
                <CampaignBanner
                    campaignId={mediaPlanId!}
                    onCreateScenarios={handleCreateScenario}
                />
                <ComparisonSection
                    scenarioPlaceholders={scenarioPlaceholders}
                    onScenarioDelete={handleDeleteScenario}
                    scenarios={scenarios}
                    setScenarios={setScenarios}
                />
            </Stack>
        </>
    );
};
