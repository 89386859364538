import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface OrganisationFormContextProps {
    isEditMode: boolean;
    setEditMode: (mode: boolean) => void;
    errorUpdateOrganisation: boolean;
    setErrorUpdateOrganisation: (isError: boolean) => void;
    updateErrorMessage: string | null;
    setUpdateErrorMessage: (message: string) => void;
}

const OrganisationFormContext = createContext<OrganisationFormContextProps>({
    isEditMode: false,
    setEditMode: (mode: boolean) => {},
    errorUpdateOrganisation: false,
    setErrorUpdateOrganisation: (isError: boolean) => {},
    updateErrorMessage: null,
    setUpdateErrorMessage: (message: string) => {},
});

export const OrganisationFormContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [mode, setMode] = useState(false);
    const [error, setError] = useState(false);
    const [failedMessage, setFailedMessage] = useState<string | null>(null);

    const isEditMode = useMemo(() => mode, [mode]);
    const setEditMode = useCallback((value: boolean) => setMode(value), [setMode]);

    const errorUpdateOrganisation = useMemo(() => error, [error]);
    const setErrorUpdateOrganisation = useCallback((value: boolean) => setError(value), [setError]);

    const updateErrorMessage = useMemo(() => failedMessage, [failedMessage]);
    const setUpdateErrorMessage = useCallback(
        (value: string) => setFailedMessage(value),
        [setFailedMessage],
    );

    const value = useMemo(
        (): OrganisationFormContextProps => ({
            isEditMode,
            setEditMode,
            errorUpdateOrganisation,
            setErrorUpdateOrganisation,
            updateErrorMessage,
            setUpdateErrorMessage,
        }),
        [
            isEditMode,
            setEditMode,
            errorUpdateOrganisation,
            setErrorUpdateOrganisation,
            updateErrorMessage,
            setUpdateErrorMessage,
        ],
    );

    return (
        <OrganisationFormContext.Provider value={value}>
            {children}
        </OrganisationFormContext.Provider>
    );
};

export const useOrganisationFormContext = (): OrganisationFormContextProps =>
    useContext(OrganisationFormContext);
