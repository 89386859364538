import { ArrowBackIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@configs';
import { FC } from 'react';

export interface BackButtonProps {
    route?: string;
}

export const BackButton: FC<BackButtonProps> = ({ route = `/${routing.campaign.root.path}` }) => {
    const navigate = useNavigate();
    return (
        <IconButton
            isRound
            icon={<ArrowBackIcon boxSize="1rem" />}
            variant="ghost"
            colorScheme="gray"
            size="sm"
            aria-label="back"
            onClick={() => navigate(route)}
        />
    );
};
