import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export const ActiveAttentionTooltip: React.FC = () => {
    return (
        <Box p=".5rem">
            <Text>Active attention</Text>
            <Text>Mobile: Looking directly at the ad</Text>
            <Text>Desktop: Looking directly at the ad</Text>
            <Text>TV: Looking directly at the ad</Text>
        </Box>
    );
};

export const PassiveAttentionTooltip: React.FC = () => {
    return (
        <Box p=".5rem">
            <Text>Passive attention</Text>
            <Text>Mobile: Eyes on screen, not on ad</Text>
            <Text>Desktop: Eyes on screen, not on ad</Text>
            <Text>TV: In the room but not looking at the TV</Text>
        </Box>
    );
};

export const RetentionRateTooltip: React.FC = () => {
    return (
        <Box p=".5rem">
            <Text>How well attention retains over the viewing session.</Text>
            <Text>
                High retention rate means slower attention decay (viewers attention remains stable).
                Low retention rate means faster attention decay (viewer attention quickly declines).
            </Text>
        </Box>
    );
};
