/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AccessLevelType } from './AccessLevelType';
import {
    AccessLevelTypeFromJSON,
    AccessLevelTypeFromJSONTyped,
    AccessLevelTypeToJSON,
} from './AccessLevelType';
import type { OrganisationUserStatus } from './OrganisationUserStatus';
import {
    OrganisationUserStatusFromJSON,
    OrganisationUserStatusFromJSONTyped,
    OrganisationUserStatusToJSON,
} from './OrganisationUserStatus';

/**
 *
 * @export
 * @interface UserWithAccessLevelDto
 */
export interface UserWithAccessLevelDto {
    /**
     *
     * @type {string}
     * @memberof UserWithAccessLevelDto
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserWithAccessLevelDto
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserWithAccessLevelDto
     */
    emailAddress?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserWithAccessLevelDto
     */
    id?: string;
    /**
     *
     * @type {AccessLevelType}
     * @memberof UserWithAccessLevelDto
     */
    accessLevel?: AccessLevelType;
    /**
     *
     * @type {OrganisationUserStatus}
     * @memberof UserWithAccessLevelDto
     */
    accessStatus?: OrganisationUserStatus;
}

/**
 * Check if a given object implements the UserWithAccessLevelDto interface.
 */
export function instanceOfUserWithAccessLevelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserWithAccessLevelDtoFromJSON(json: any): UserWithAccessLevelDto {
    return UserWithAccessLevelDtoFromJSONTyped(json, false);
}

export function UserWithAccessLevelDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UserWithAccessLevelDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
        id: !exists(json, 'id') ? undefined : json['id'],
        accessLevel: !exists(json, 'access_level')
            ? undefined
            : AccessLevelTypeFromJSON(json['access_level']),
        accessStatus: !exists(json, 'access_status')
            ? undefined
            : OrganisationUserStatusFromJSON(json['access_status']),
    };
}

export function UserWithAccessLevelDtoToJSON(value?: UserWithAccessLevelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        first_name: value.firstName,
        last_name: value.lastName,
        email_address: value.emailAddress,
        id: value.id,
        access_level: AccessLevelTypeToJSON(value.accessLevel),
        access_status: OrganisationUserStatusToJSON(value.accessStatus),
    };
}
