import { colors } from '@assets/design-system/theming/colors';
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    LineController,
    BarElement,
    BarController,
    PointElement,
    Title,
    Tooltip,
    DoughnutController,
    ArcElement,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
} from 'chart.js';

Chart.register(
    CategoryScale,
    Legend,
    LinearScale,
    LineElement,
    LineController,
    BarElement,
    BarController,
    PointElement,
    Title,
    Tooltip,
    DoughnutController,
    ArcElement,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
);

// scale defaults
const { linear, category } = Chart.defaults.scales;
linear.title = {
    ...linear.title,
    font: {
        size: 14,
    },
    padding: 10,
    color: colors.colors.gray[500],
};
linear.ticks = {
    ...linear.ticks,
    color: colors.colors.gray[500],
};
category.ticks = {
    ...category.ticks,
    font: {
        size: 14,
        weight: 'bold',
    },
    color: colors.colors.gray[500],
};

// changes bar chart
Chart.defaults.datasets.bar.borderRadius = 7;
Chart.defaults.datasets.bar.barPercentage = 0.6;

const { legend, tooltip } = Chart.defaults.plugins;

// changes default legend look to circular dots
legend.position = 'bottom';
legend.onHover = (e, legendItem, legendSettings) => {
    legendSettings.chart.canvas.style.cursor = 'pointer';
};
legend.onLeave = (e, legendItem, legendSettings) => {
    legendSettings.chart.canvas.style.cursor = 'default';
};
legend.labels.boxHeight = 6;
legend.labels.pointStyle = 'circle';
legend.labels.usePointStyle = true;
legend.labels.font = { size: 12 };
legend.reverse = true;

// reduce default tooltip animation speed to minimise feeling of 'laginess'
tooltip.animation = {
    duration: 50,
    delay: 0,
};
tooltip.displayColors = false;

// so chart stretches to container size regardless of aspect ratio
Chart.defaults.maintainAspectRatio = false;
