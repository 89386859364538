import { useTranslation } from 'react-i18next';
import { useCustomToast } from './useCustomToast';

export const useAddNewClientToast = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successAddNewClientToast(clientName: string, organisationName: string) {
        const title = t('messages.toast.invite.client.success.title');
        const description = `${clientName} ${t(
            'messages.toast.invite.client.success.description',
        )} ${organisationName}`;

        successToast(title, description);
    }

    function errorAddNewClientToast() {
        const title = t('messages.toast.invite.client.error.title');
        const description = t('messages.toast.invite.client.error.description');

        errorToast(title, description);
    }

    return {
        successAddNewClientToast,
        errorAddNewClientToast,
    };
};
