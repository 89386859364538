/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface FormatSegmentResponseDto
 */
export interface FormatSegmentResponseDto {
    /**
     *
     * @type {string}
     * @memberof FormatSegmentResponseDto
     */
    adFormatId?: string;
    /**
     *
     * @type {string}
     * @memberof FormatSegmentResponseDto
     */
    adFormatCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatSegmentResponseDto
     */
    adFormatName?: string | null;
    /**
     *
     * @type {number}
     * @memberof FormatSegmentResponseDto
     */
    attentiveReachPercent?: number;
    /**
     *
     * @type {number}
     * @memberof FormatSegmentResponseDto
     */
    retentionRate?: number;
    /**
     *
     * @type {boolean}
     * @memberof FormatSegmentResponseDto
     */
    isSelected?: boolean;
    /**
     *
     * @type {number}
     * @memberof FormatSegmentResponseDto
     */
    cpm?: number;
}

/**
 * Check if a given object implements the FormatSegmentResponseDto interface.
 */
export function instanceOfFormatSegmentResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatSegmentResponseDtoFromJSON(json: any): FormatSegmentResponseDto {
    return FormatSegmentResponseDtoFromJSONTyped(json, false);
}

export function FormatSegmentResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatSegmentResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adFormatId: !exists(json, 'ad_format_id') ? undefined : json['ad_format_id'],
        adFormatCode: !exists(json, 'ad_format_code') ? undefined : json['ad_format_code'],
        adFormatName: !exists(json, 'ad_format_name') ? undefined : json['ad_format_name'],
        attentiveReachPercent: !exists(json, 'attentive_reach_percent')
            ? undefined
            : json['attentive_reach_percent'],
        retentionRate: !exists(json, 'retention_rate') ? undefined : json['retention_rate'],
        isSelected: !exists(json, 'is_selected') ? undefined : json['is_selected'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
    };
}

export function FormatSegmentResponseDtoToJSON(value?: FormatSegmentResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_format_id: value.adFormatId,
        ad_format_code: value.adFormatCode,
        ad_format_name: value.adFormatName,
        attentive_reach_percent: value.attentiveReachPercent,
        retention_rate: value.retentionRate,
        is_selected: value.isSelected,
        cpm: value.cpm,
    };
}
