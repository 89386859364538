import { CampaignApi, CampaignResponseDto, GetCampaignByIdRequest } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

type useGetCampaignHook = () => {
    getCampaign: (request: GetCampaignByIdRequest) => Promise<CampaignResponseDto>;
};

export const useGetCampaign: useGetCampaignHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getCampaign(request: GetCampaignByIdRequest) {
        try {
            const config = await getMediaPlanConfig();
            const api = new CampaignApi(config);
            return await api.getCampaignById(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getCampaign };
};
