import {
    Accordion,
    Button,
    Card,
    Checkbox,
    Container,
    FormLabel,
    Heading,
    Menu,
    Radio,
    Table,
    Text,
    Tooltip,
    Tabs,
    Spinner,
    Link,
} from '.';

export const componentStyles = {
    Container,
    Card,
    Menu,
    FormLabel,
    Table,
    Accordion,
    Text,
    Heading,
    Button,
    Tabs,
    Checkbox,
    Radio,
    Tooltip,
    Spinner,
    Link,
};
