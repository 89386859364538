import { AdClientApi, RemoveAdClientRequestDto } from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

interface useRemoveAdClientProps {
    removeAdClient: (adClientId: string) => Promise<void>;
}

type useRemoveAdClientHook = () => useRemoveAdClientProps;

export const useRemoveAdClient: useRemoveAdClientHook = () => {
    const { getMediaPlanConfig } = useApiConfiguration();

    async function removeAdClient(adClientId: string): Promise<void> {
        try {
            const config = await getMediaPlanConfig();
            const api = new AdClientApi(config);
            const removeAdClientRequestDto: RemoveAdClientRequestDto = {
                adClientId,
            };
            await api.removeAdClient({ removeAdClientRequestDto });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    return {
        removeAdClient,
    };
};
