import { BackButton } from '@apps/attention360/atoms';
import { Center, Flex, Heading, SkeletonText, Stack, Text, VStack } from '@chakra-ui/react';
import { routing } from '@configs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export interface StrategyReportHeaderSectionProps {
    strategyReportName: string;
    campaignStartDate?: string;
    campaignEndDate?: string;
    isLoading?: boolean;
}

export const StrategyReportHeaderSection: FC<StrategyReportHeaderSectionProps> = ({
    strategyReportName,
    campaignStartDate,
    campaignEndDate,
    isLoading,
}) => {
    const { t } = useTranslation('mediaPlans');

    return (
        <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="center">
                <BackButton route={`/${routing.campaign.root.path}`} />
                <Text fontSize="12px" fontWeight="400" color="#373D51">
                    Campaigns
                </Text>
            </Stack>
            <VStack align="left" spacing={2}>
                {isLoading ? (
                    <Center marginTop="0.5rem">
                        <SkeletonText width="15rem" noOfLines={2} />
                    </Center>
                ) : (
                    <>
                        <Heading textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {strategyReportName}
                        </Heading>
                        <Text
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            color="gray.500"
                            fontSize="sm"
                        >
                            {`Start date: ${moment(campaignStartDate).format(
                                'DD/MM/YYYY',
                            )} • End date: ${moment(campaignEndDate).format('DD/MM/YYYY')}`}
                        </Text>
                    </>
                )}
            </VStack>
        </Stack>
    );
};
