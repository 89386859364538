import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import {
    Box,
    Center,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { externalUrls } from '@configs/externalUrls';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import {
    ChatBubbleLeftRightIcon,
    DocumentTextIcon,
    EnvelopeIcon,
    FilmIcon,
    ListBulletIcon,
    PhoneIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { useAppContextHelper } from '@hooks/_contexts';
import React, { FC } from 'react';
import { DemoVideoModal, FeedbackModal } from '../modals';

export const TechnicalSupportDropdown: FC = () => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();
    const isFreePlan = !featureBalances?.find(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    const disclosure = useDisclosure();

    const {
        isOpen: isTooltipOpen,
        onOpen: onTooltipOpen,
        onClose: onTooltipClose,
    } = useDisclosure();

    const {
        isOpen: isPlanDemoVideoModalOpen,
        onOpen: onPlanDemoVideoModalOpen,
        onClose: onPlanDemoVideoModalClose,
    } = useDisclosure();

    const {
        isOpen: isProveDemoVideoModalOpen,
        onOpen: onProveDemoVideoModalOpen,
        onClose: onProveDemoVideoModalClose,
    } = useDisclosure();

    const handleOpen = () => {
        disclosure.onOpen();
    };
    const emitClickOnDemoVideoEvent = usePosthogEvent('View demo video from Help');
    const emitClickOnHowToGuideEvent = usePosthogEvent('View how to guide from help');
    const emitClickOnFaqEvent = usePosthogEvent('View FAQ from help');
    const emitClickOnEmailUsEvent = usePosthogEvent('Click on email us from help');
    const emitClickOnFeedbackEvent = usePosthogEvent('Click on Feedback from Help');

    return (
        <Box>
            <DemoVideoModal
                title="Attention Plan Demo Video"
                videoUrl={externalUrls.youtubePlanDemoVideo}
                isOpen={isPlanDemoVideoModalOpen}
                onClose={onPlanDemoVideoModalClose}
            />
            <DemoVideoModal
                title="Attention Prove Demo Video"
                videoUrl={externalUrls.youtubeProveDemoVideo}
                isOpen={isProveDemoVideoModalOpen}
                onClose={onProveDemoVideoModalClose}
            />
            {/* Wrapping Menu component with Box component to prevent Popper warning in browser console */}
            <Menu>
                <Tooltip isOpen={isTooltipOpen} label="Support">
                    <MenuButton
                        isRound
                        as={IconButton}
                        variant="ghost"
                        onMouseEnter={onTooltipOpen}
                        onMouseLeave={onTooltipClose}
                        onClick={onTooltipClose}
                        icon={<Icon boxSize="1.5rem" as={QuestionMarkCircleIcon} />}
                    />
                </Tooltip>

                <MenuList>
                    {isFreePlan && (
                        <>
                            <a href={externalUrls.bookACall} target="_blank" rel="noreferrer">
                                <MenuItem
                                    icon={
                                        <Center>
                                            <Icon as={PhoneIcon} boxSize="1.5rem" />
                                        </Center>
                                    }
                                >
                                    Book a call
                                </MenuItem>
                            </a>
                            <MenuDivider />
                        </>
                    )}
                    <MenuGroup title="attentionPLAN">
                        <MenuItem
                            icon={
                                <Center>
                                    <Icon as={FilmIcon} boxSize="1.5rem" />
                                </Center>
                            }
                            onClick={() => {
                                onPlanDemoVideoModalOpen();
                                emitClickOnDemoVideoEvent();
                            }}
                        >
                            Demo video
                        </MenuItem>

                        <MenuItem
                            icon={
                                <Center>
                                    <Icon as={DocumentTextIcon} boxSize="1.5rem" />
                                </Center>
                            }
                            onClick={(e) => {
                                window.open(externalUrls.howToGuidePlan, '_blank', 'noreferrer');
                                emitClickOnHowToGuideEvent();
                            }}
                        >
                            How to guide
                        </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="attentionPROVE">
                        <MenuItem
                            icon={
                                <Center>
                                    <Icon as={FilmIcon} boxSize="1.5rem" />
                                </Center>
                            }
                            onClick={() => {
                                onProveDemoVideoModalOpen();
                                emitClickOnDemoVideoEvent();
                            }}
                        >
                            Demo video
                        </MenuItem>

                        <MenuItem
                            icon={
                                <Center>
                                    <Icon as={DocumentTextIcon} boxSize="1.5rem" />
                                </Center>
                            }
                            onClick={(e) => {
                                window.open(externalUrls.howToGuideProve, '_blank', 'noreferrer');
                                emitClickOnHowToGuideEvent();
                            }}
                        >
                            How to guide
                        </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuItem
                        icon={
                            <Center>
                                <Icon as={ListBulletIcon} boxSize="1.5rem" />
                            </Center>
                        }
                        onClick={(e) => {
                            window.open(externalUrls.attentionPLANFaq, '_blank', 'noreferrer');
                            emitClickOnFaqEvent();
                        }}
                    >
                        FAQ
                    </MenuItem>
                    <MenuItem
                        icon={
                            <Center>
                                <Icon as={EnvelopeIcon} boxSize="1.5rem" />
                            </Center>
                        }
                        onClick={(e) => {
                            window.open(
                                externalUrls.customerSupportEmailUrl,
                                '_blank',
                                'noreferrer',
                            );

                            emitClickOnEmailUsEvent();
                        }}
                    >
                        Email Us
                    </MenuItem>
                    <MenuItem
                        icon={
                            <Center>
                                <Icon as={ChatBubbleLeftRightIcon} boxSize="1.5rem" />
                            </Center>
                        }
                        onClick={(e) => {
                            handleOpen();
                            emitClickOnFeedbackEvent();
                        }}
                    >
                        Feedback
                    </MenuItem>
                    <FeedbackModal
                        onClose={disclosure.onClose}
                        defaultIsOpen={false}
                        isOpen={disclosure.isOpen}
                        onOpen={disclosure.onOpen}
                    />
                </MenuList>
            </Menu>
        </Box>
    );
};
