import { GetChannelLevelResultComparisonResponseDto } from '@api-clients/media-plan';
import { useToken } from '@chakra-ui/react';
import { getShortenedNumber } from '@shared/utils';
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export interface MediaMixFormatViewChartProps {
    channels: Array<GetChannelLevelResultComparisonResponseDto>;
    isStacked?: boolean;
}

export const MediaMixFormatViewChart: FC<MediaMixFormatViewChartProps> = ({
    channels,
    isStacked = true,
}) => {
    const { t } = useTranslation('mediaPlans');

    const filteredChannels = channels
        .filter((ch) => !!ch.id)
        .map(
            (ch): GetChannelLevelResultComparisonResponseDto => ({
                formatLevelResults: ch.formatLevelResults?.filter((f) => !!f.id),
                ...ch,
            }),
        );

    const chartColors = useToken('colors', [
        'green.500',
        'orange.500',
        'blue.500',
        'red.500',
        'purple.500',
        'limeGreen.500',
        'pink.500',
        'yellow.500',
        'teal.500',
        'cyan.500',
    ]);

    const formats = [
        ...new Set(
            filteredChannels
                ?.flatMap((channel) =>
                    channel.formatLevelResults?.map((format) => format.formatName),
                )

                .filter((element): element is string => typeof element === 'string'),
        ),
    ].sort((a, b) => {
        if (!a) return -1;
        if (!b) return 1;
        return b.localeCompare(a);
    });

    return (
        <Bar
            data={{
                labels: filteredChannels?.map((channel) => channel.channelName),
                datasets: formats.map((adFormat, index) => {
                    const color = chartColors[index % chartColors.length];
                    return {
                        label: adFormat,
                        backgroundColor: color,
                        data:
                            filteredChannels?.flatMap((channel) => {
                                return channel.formatLevelResults
                                    ?.filter((format) => format.formatName === adFormat)
                                    .map((format) => ({
                                        x: channel.channelName,
                                        y:
                                            channel.budget?.value && format.budget?.value
                                                ? (format.budget.value / channel.budget.value) * 100
                                                : 0,
                                    }));
                            }) ?? [],
                    };
                }),
            }}
            options={{
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) =>
                                tooltipItems.map((tooltipItem) => tooltipItem.dataset.label ?? ''),
                            label: (tooltipItem) => {
                                const channelName = tooltipItem.label;
                                const percentage = tooltipItem.parsed.y;
                                return [
                                    `Mix: ${tooltipItem.parsed.y.toLocaleString()}%`,
                                    `Budget: $${getShortenedNumber(
                                        (channels.find((ch) => channelName === ch.channelName)
                                            ?.budget?.value ?? 0) *
                                            (percentage / 100),
                                    )}`,
                                ];
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        title: {
                            text: `${t('mediaPlanning.mediaMixChart.axisLabels.y')}`,
                            display: true,
                        },
                        ticks: {
                            callback: (value) => {
                                return `${value}%`;
                            },
                        },
                        max: 100,
                        stacked: isStacked,
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                        stacked: isStacked,
                    },
                },
            }}
        />
    );
};
