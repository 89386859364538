import {
    CreateNewOptimisedScenariosRequest,
    CreateOptimisedScenariosRequestDto,
    ScenarioApi,
    ScenarioResponseDto,
} from '@api-clients/media-plan';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface CreateScenariosHook {
    createScenarios: (
        campaignId: string,
        createScenarioRequestDto: CreateOptimisedScenariosRequestDto,
    ) => Promise<ScenarioResponseDto[]>;
}

type useCreateScenariosProps = () => CreateScenariosHook;

export const useCreateScenarios: useCreateScenariosProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function createScenarios(
        campaignId: string,
        createOptimisedScenariosRequestDto: CreateOptimisedScenariosRequestDto,
    ): Promise<ScenarioResponseDto[]> {
        try {
            const config = await getMediaPlanConfig();
            const request: CreateNewOptimisedScenariosRequest = {
                campaignId,
                createOptimisedScenariosRequestDto,
            };

            const api = new ScenarioApi(config);
            return await api.createNewOptimisedScenarios(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        createScenarios,
    };
};
