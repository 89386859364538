/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { RoleTitle } from './RoleTitle';
import { RoleTitleFromJSON, RoleTitleFromJSONTyped, RoleTitleToJSON } from './RoleTitle';

/**
 *
 * @export
 * @interface UpdateUserRequestDto
 */
export interface UpdateUserRequestDto {
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    countryPhoneCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {RoleTitle}
     * @memberof UpdateUserRequestDto
     */
    roleTitle?: RoleTitle;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    otherRole?: string | null;
    /**
     *
     * @type {Date}
     * @memberof UpdateUserRequestDto
     */
    termsAndConditionsAcceptedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    termsAndConditionsVersionAccepted?: string | null;
}

/**
 * Check if a given object implements the UpdateUserRequestDto interface.
 */
export function instanceOfUpdateUserRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserRequestDtoFromJSON(json: any): UpdateUserRequestDto {
    return UpdateUserRequestDtoFromJSONTyped(json, false);
}

export function UpdateUserRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateUserRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        countryPhoneCode: !exists(json, 'country_phone_code')
            ? undefined
            : json['country_phone_code'],
        phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
        roleTitle: !exists(json, 'role_title') ? undefined : RoleTitleFromJSON(json['role_title']),
        otherRole: !exists(json, 'other_role') ? undefined : json['other_role'],
        termsAndConditionsAcceptedAt: !exists(json, 'terms_and_conditions_accepted_at')
            ? undefined
            : json['terms_and_conditions_accepted_at'] === null
            ? null
            : new Date(json['terms_and_conditions_accepted_at']),
        termsAndConditionsVersionAccepted: !exists(json, 'terms_and_conditions_version_accepted')
            ? undefined
            : json['terms_and_conditions_version_accepted'],
    };
}

export function UpdateUserRequestDtoToJSON(value?: UpdateUserRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        first_name: value.firstName,
        last_name: value.lastName,
        country_phone_code: value.countryPhoneCode,
        phone_number: value.phoneNumber,
        role_title: RoleTitleToJSON(value.roleTitle),
        other_role: value.otherRole,
        terms_and_conditions_accepted_at:
            value.termsAndConditionsAcceptedAt === undefined
                ? undefined
                : value.termsAndConditionsAcceptedAt === null
                ? null
                : value.termsAndConditionsAcceptedAt.toISOString(),
        terms_and_conditions_version_accepted: value.termsAndConditionsVersionAccepted,
    };
}
