export const resources_FR = {
    accountManager: {
        welcome: 'Bonjour',
        errors: {
            changePassword: {
                notMatching: '',
                missingField: '',
            },
        },
        toast: {
            listAction: {
                memberAdded: '',
                memberDeleted: '',
            },
        },
    },
    mediaPlans: {
        clientList: {
            client: '',
        },
    },
};
