import {ProveReportData} from "@api-clients/prove/schema/ProveDashboard";

export const proveBreakdownDeviceReportDemoData: ProveReportData = {
    "breakdown": [
        {
            "value": "Mobile",
            "metrics": {
                "avgActiveAttention": 0.61,
                "impressionsActive5s": 33838,
                "impressionsActive4s": 58936,
                "avgNonAttention": 3.25,
                "impressions": 518727,
                "avgPassiveAttention": 2.86,
                "impressionsActive3s": 102080,
                "impressionsActive2s": 192605,
                "impressionsActive1s": 349602
            }
        },
        {
            "value": "Tablet",
            "metrics": {
                "avgActiveAttention": 2.21,
                "impressionsActive5s": 33838,
                "impressionsActive4s": 58936,
                "avgNonAttention": 8.25,
                "impressions": 313908,
                "avgPassiveAttention": 6.53,
                "impressionsActive3s": 89080,
                "impressionsActive2s": 111605,
                "impressionsActive1s": 142602
            }
        }
    ]
};





