import { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {routing} from "@configs";

type BackButtonOnCampaignCreationPageProps = {
    tabIndex: number;
    setTabIndex: Dispatch<SetStateAction<number>>;
    handleUnload?: () => void;
};
export const BackButtonOnCampaignCreationPage: FC<BackButtonOnCampaignCreationPageProps> = ({
    tabIndex,
    setTabIndex,
    handleUnload = () => {},
}) => {
    const navigate = useNavigate();
    return (
        <IconButton
            isRound
            icon={<ArrowBackIcon boxSize="1rem" />}
            variant="ghost"
            colorScheme="gray"
            size="sm"
            aria-label="back"
            onClick={() => {
                if (tabIndex !== 0) {
                    setTabIndex((old) => old - 1);
                    return;
                }
                handleUnload();
                navigate(`/${routing.campaign.root.path}`);
            }}
        />
    );
};
