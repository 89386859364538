import {
    GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest,
    GetCampaignByIdRequest,
} from '@api-clients/media-plan';
import {MediaPlanCreationPage} from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import {ScenarioCreationPage} from '@apps/attentionADJUST/pages/campaign/ScenarioCreationPage';
import {ScenarioEditPage} from '@apps/attentionADJUST/pages/campaign/ScenarioEditPage';
import {routing} from '@configs';
import {RouteObject} from 'react-router-dom';

export const MediaPlanningRoutes: Array<RouteObject> = [
    {
        path: `${routing.plan.root.path}/:mediaPlanId`,
        children: [
            {
                index: true,
                element: <ScenarioCreationPage/>,
                loader: ({params}): GetCampaignByIdRequest => ({
                    mediaPlanId: params.mediaPlanId ?? '',
                }),
            },
            {
                path: `${routing.plan.create.path}`,
                element: <MediaPlanCreationPage/>,
            },
            {
                path: `${routing.plan.scenario.path}/:scenarioId`,
                element: <ScenarioEditPage/>,
                loader: ({
                             params,
                         }): GetAScenarioComparedWithAGivenScenarioOrTheBaselineScenarioRequest => ({
                    campaignId: params.mediaPlanId ?? '',
                    scenarioId: params.scenarioId ?? '',
                }),
            }
        ]
    },

];
