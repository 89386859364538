import { Box, Container, Skeleton, Stack, useBoolean } from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { ErrorCard } from '@components/molecules/errorCard/ErrorCard';
import { ManageMemberSection } from '@components/organisms/manageMembersSection/ManageMembersSection';
import { OrganisationInformationSection } from '@components/organisms/organisationInformation/OrganisationInformationSection';
import { OrganisationFormContextProvider } from '@components/organisms/organisationInformation/OrganisationInfoSectionContext';
import { FC, useState } from 'react';
import { useAppContextHelper } from '@hooks/_contexts';
import { ManageMediaPlanServiceSection } from '@components/organisms/manageMediaPlanServiceSection/ManageMediaPlanServiceSection';
import { ManageApiServiceSection } from '@components/organisms/manageApiServiceSection/ManageApiServiceSection';
import { shouldDisplayApiApplications } from '@shared/utils/formats/countryFeatures';
import { OrganisationResponseDto } from '@api-clients/account-manager';
import { useGetOrganisation } from '@hooks/organisations';

export const OrganisationDetailsPage: FC<any> = () => {
    const {
        currentContextValues: { user, organisation, featureBalances, error },
    } = useAppContextHelper();

    const [organisationMirror, setOrganisationMirror] = useState<OrganisationResponseDto>(
        organisation ?? {},
    );
    const [isFetching, setIsFetching] = useBoolean(false);

    const { getOrganisation } = useGetOrganisation();
    const getOrganisationContext = () => {
        setIsFetching.on();
        getOrganisation()
            .then((response) => {
                setOrganisationMirror(response);
            })
            .finally(() => setIsFetching.off());
    };

    return (
        <OrganisationFormContextProvider>
            {!error?.isError ? (
                <>
                    {user && organisationMirror && featureBalances ? (
                        <Container>
                            <Stack spacing="3rem">
                                <Box>
                                    <OrganisationInformationSection
                                        organisation={organisationMirror}
                                        user={user}
                                    />
                                </Box>
                                {shouldDisplayApiApplications(featureBalances!) && (
                                    <Box>
                                        <ManageApiServiceSection />
                                    </Box>
                                )}
                                <Box>
                                    <ManageMediaPlanServiceSection />
                                </Box>
                                <Box>
                                    {!isFetching ? (
                                        <ManageMemberSection
                                            organisation={organisationMirror}
                                            currentUser={user}
                                            getOrganisation={getOrganisationContext}
                                        />
                                    ) : (
                                        <Skeleton />
                                    )}
                                </Box>
                            </Stack>
                        </Container>
                    ) : (
                        <Loading />
                    )}
                </>
            ) : (
                <ErrorCard />
            )}
        </OrganisationFormContextProvider>
    );
};
