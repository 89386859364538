import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';
import {
    AccessLevelType,
    UpdateUserAccessLevelRequest,
    UserApi,
    UserResponseDto,
} from '@api-clients/account-manager';

export const useUpdateUserRole = () => {
    const { getAccountManagerConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateUserRole(
        userId: string,
        accessType: AccessLevelType,
    ): Promise<UserResponseDto> {
        try {
            const configuration = await getAccountManagerConfig();
            const request: UpdateUserAccessLevelRequest = {
                userId,
                body: accessType,
            };
            const api = new UserApi(configuration);
            return await api.updateUserAccessLevel(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return {
        updateUserRole,
    };
};
