/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ChannelMixResponseDto
 */
export interface ChannelMixResponseDto {
    /**
     *
     * @type {string}
     * @memberof ChannelMixResponseDto
     */
    channelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof ChannelMixResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ChannelMixResponseDto
     */
    mix?: number;
    /**
     *
     * @type {number}
     * @memberof ChannelMixResponseDto
     */
    budget?: number;
}

/**
 * Check if a given object implements the ChannelMixResponseDto interface.
 */
export function instanceOfChannelMixResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelMixResponseDtoFromJSON(json: any): ChannelMixResponseDto {
    return ChannelMixResponseDtoFromJSONTyped(json, false);
}

export function ChannelMixResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelMixResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        mix: !exists(json, 'mix') ? undefined : json['mix'],
        budget: !exists(json, 'budget') ? undefined : json['budget'],
    };
}

export function ChannelMixResponseDtoToJSON(value?: ChannelMixResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        channel_code: value.channelCode,
        channel_name: value.channelName,
        mix: value.mix,
        budget: value.budget,
    };
}
