/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SelectedAdFormatRequestDto } from './SelectedAdFormatRequestDto';
import {
    SelectedAdFormatRequestDtoFromJSON,
    SelectedAdFormatRequestDtoFromJSONTyped,
    SelectedAdFormatRequestDtoToJSON,
} from './SelectedAdFormatRequestDto';

/**
 *
 * @export
 * @interface UpdateAdFormatsRequestDto
 */
export interface UpdateAdFormatsRequestDto {
    /**
     *
     * @type {Array<SelectedAdFormatRequestDto>}
     * @memberof UpdateAdFormatsRequestDto
     */
    selectedFormatCodes?: Array<SelectedAdFormatRequestDto> | null;
}

/**
 * Check if a given object implements the UpdateAdFormatsRequestDto interface.
 */
export function instanceOfUpdateAdFormatsRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAdFormatsRequestDtoFromJSON(json: any): UpdateAdFormatsRequestDto {
    return UpdateAdFormatsRequestDtoFromJSONTyped(json, false);
}

export function UpdateAdFormatsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateAdFormatsRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        selectedFormatCodes: !exists(json, 'selected_format_codes')
            ? undefined
            : json['selected_format_codes'] === null
            ? null
            : (json['selected_format_codes'] as Array<any>).map(SelectedAdFormatRequestDtoFromJSON),
    };
}

export function UpdateAdFormatsRequestDtoToJSON(value?: UpdateAdFormatsRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        selected_format_codes:
            value.selectedFormatCodes === undefined
                ? undefined
                : value.selectedFormatCodes === null
                ? null
                : (value.selectedFormatCodes as Array<any>).map(SelectedAdFormatRequestDtoToJSON),
    };
}
