import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/toast/useCustomToast';

export const useInviteUserToast = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successInviteUserToast(fullName: string, organisationName?: string | null) {
        const title = t('messages.toast.invite.user.success.title');
        const description = `${fullName} ${t('messages.toast.invite.user.success.description')} ${
            organisationName ?? ''
        }`;

        successToast(title, description);
    }

    function errorInviteUserToast() {
        const title = t('messages.toast.remove.user.error.title');
        const description = t('messages.toast.invite.user.error.description');

        errorToast(title, description);
    }

    return {
        successInviteUserToast,
        errorInviteUserToast,
    };
};
