import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetSharedScenario,
    useGetSharedScenarioLevelResultsComparison,
    useGetShareScenarioBasicInfo,
} from '@hooks/scenarios';
import {
    ChannelLevelCurvesResponseDto,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    OptimisationStrategy,
    ScenarioResponseDto,
} from '@api-clients/media-plan';
import {
    Box,
    Center,
    Container,
    Flex,
    Icon,
    Link,
    SimpleGrid,
    Spacer,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import {
    MediaMixChartContainer,
    ReachCurveContainer,
    SharedScenarioBanner,
} from '@apps/attentionADJUST/components/organisms';
import { useGetSharedScenarioReachCurves } from '@hooks/reachCurves';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { AsyncCard } from '@components/atoms';
import {
    ChannelViewTable,
    FormatTable,
    KeyOptimisedMetricsTable,
    MediaMixChannelViewChart,
    MediaMixFormatViewChart,
} from '@apps/attentionADJUST/components/molecules';
import { CodedCommentaryCard } from '@components/molecules';
import { mapScenarioToGetScenarioResponseDto } from '@apps/attentionADJUST/components/organisms/scenarioEditSection/ScenarioViewSection';
import { AdFormatDetailModal } from '@apps/attentionADJUST/components/molecules/modal';

type ShareScenarioPageProps = {};
export const ShareScenarioPage: FC<ShareScenarioPageProps> = () => {
    const { scenarioShareLinkId } = useParams();
    const { getShareScenarioBasicInfo } = useGetShareScenarioBasicInfo();
    const { getSharedScenarioReachCurves } = useGetSharedScenarioReachCurves();
    const { getSharedScenarioLevelResultsComparison } =
        useGetSharedScenarioLevelResultsComparison();

    const [scenarioBasicInfo, setScenarioBasicInfo] = useState<GetScenarioBasicInfoResponseDto>();

    const [reachCurveData, setReachCurveData] = useState<Array<ChannelLevelCurvesResponseDto>>([]);

    const [scenarioLevelResultsComparison, setScenarioLevelResultsComparison] =
        useState<GetScenarioLevelResultComparisonResponseDto>();

    const loadScenarioInfo = () => {
        if (!scenarioShareLinkId) {
            throw new Error('Missing campaign id or scenario id');
        }
        getShareScenarioBasicInfo(scenarioShareLinkId).then((response) =>
            setScenarioBasicInfo(response),
        );
    };
    const loadReachCurve = () => {
        if (!scenarioShareLinkId) {
            throw new Error('Missing campaign id or scenario id');
        }
        getSharedScenarioReachCurves({ scenarioShareLinkId }).then((response) =>
            setReachCurveData(response),
        );
    };
    const loadScenarioLevelResultComparison = () => {
        if (!scenarioShareLinkId) {
            throw new Error('Missing campaign id or scenario id');
        }
        getSharedScenarioLevelResultsComparison(scenarioShareLinkId).then((r) => {
            r.channelLevelResults?.sort(
                (a, b) => a.channelName?.localeCompare(b.channelName ?? '') ?? 0,
            );
            setScenarioLevelResultsComparison(r);
        });
    };
    const isFreePlan = !!scenarioLevelResultsComparison?.channelLevelResults?.every(
        (r) => r.formatLevelResults?.length === 0,
    );
    useEffect(() => {
        loadScenarioInfo();
        loadReachCurve();
        loadScenarioLevelResultComparison();
    }, [scenarioShareLinkId]);

    const isBaseScenario = scenarioBasicInfo?.optimisationStrategy === OptimisationStrategy.Base;

    return (
        <Stack spacing="1.25rem">
            <SharedScenarioBanner
                isFreePlan={isFreePlan}
                reachCurveData={reachCurveData}
                scenarioBasicInfo={scenarioBasicInfo}
                scenarioLevelResultsComparison={scenarioLevelResultsComparison}
                formatDetailsComponent={
                    <AdFormatDetailModal
                        channels={scenarioLevelResultsComparison?.channelLevelResults?.map(
                            (ch) => ({
                                channelCode: ch.channelCode!,
                                channelName: ch.channelName!,
                                formats:
                                    ch.formatLevelResults?.map((f) => ({
                                        formatCode: f.formatCode!,
                                        formatName: f.formatName!,
                                    })) ?? [],
                            }),
                        )}
                    />
                }
            />
            {scenarioBasicInfo ? (
                <Container>
                    <Stack spacing="2.25rem">
                        <Tabs>
                            <Stack direction="row">
                                <ContainerTitle
                                    headingText="Scenario mix detailed view"
                                    subtitleText="Evaluate the scenario's budget mix and performance metrics by channel and format"
                                />
                                <Spacer />
                                <VStack>
                                    <TabList>
                                        <Tab>Channel View</Tab>
                                        <Tab isDisabled={isFreePlan}>
                                            {isFreePlan && (
                                                <Icon
                                                    as={LockClosedIcon}
                                                    boxSize="1rem"
                                                    mr="0.2rem"
                                                />
                                            )}
                                            Format View
                                        </Tab>
                                    </TabList>
                                </VStack>
                            </Stack>

                            <TabPanels marginTop="1rem">
                                <TabPanel padding={0}>
                                    <AsyncCard
                                        isLoading={
                                            !scenarioLevelResultsComparison?.channelLevelResults
                                        }
                                    >
                                        {scenarioLevelResultsComparison?.channelLevelResults && (
                                            <ChannelViewTable
                                                channelLevelResultArray={
                                                    scenarioLevelResultsComparison?.channelLevelResults
                                                }
                                            />
                                        )}
                                    </AsyncCard>
                                </TabPanel>
                                <TabPanel padding={0}>
                                    <AsyncCard
                                        isLoading={
                                            !scenarioLevelResultsComparison?.channelLevelResults
                                        }
                                    >
                                        {scenarioLevelResultsComparison?.channelLevelResults && (
                                            <FormatTable
                                                channelLevelResultArray={
                                                    scenarioLevelResultsComparison?.channelLevelResults
                                                }
                                            />
                                        )}
                                    </AsyncCard>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Box display="flex" flexDirection="column" gap="1rem">
                            <ContainerTitle
                                headingText="Key optimised metrics"
                                subtitleText="Understand how the optimised scenario performs comparing to the original media plan"
                            />
                            <SimpleGrid
                                width="100%"
                                gap="1rem"
                                columns={{ sm: 1, lg: isBaseScenario ? 1 : 2 }}
                            >
                                <Box flex={0.5}>
                                    <AsyncCard isLoading={!scenarioLevelResultsComparison}>
                                        {scenarioLevelResultsComparison && (
                                            <KeyOptimisedMetricsTable
                                                scenario={scenarioLevelResultsComparison}
                                            />
                                        )}
                                    </AsyncCard>
                                </Box>
                                {!isBaseScenario && (
                                    <Box flex={0.5}>
                                        {scenarioLevelResultsComparison ? (
                                            <CodedCommentaryCard
                                                scenario={scenarioLevelResultsComparison}
                                            />
                                        ) : (
                                            <AsyncCard isLoading height="100%" />
                                        )}
                                    </Box>
                                )}
                            </SimpleGrid>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="1rem">
                            <ContainerTitle
                                headingText="Attention-adjusted® reach curves"
                                subtitleText={
                                    <>
                                        The{' '}
                                        <Link
                                            isExternal
                                            textDecoration="underline"
                                            href="https://help.amplifiedintelligence.com.au/what-are-attention-adjusted-reach-curves"
                                        >
                                            Attention-adjusted® reach curve (AARC)
                                        </Link>{' '}
                                        transforms reach-based planning by filtering out
                                        non-attentive impressions, giving a view of the attentive,
                                        high value audience
                                    </>
                                }
                            />
                            <ReachCurveContainer reachCurveData={reachCurveData} />
                        </Box>
                        <Tabs>
                            <Flex direction="row">
                                <ContainerTitle
                                    headingText="Scenario media mix"
                                    subtitleText="Compare the media mix by budget spend between each scenario"
                                />
                                <Spacer />
                                <VStack>
                                    <TabList>
                                        <Tab>Channel view</Tab>
                                        <Tab isDisabled={isFreePlan}>
                                            {isFreePlan && (
                                                <Icon
                                                    boxSize="1rem"
                                                    as={LockClosedIcon}
                                                    mr="0.2rem"
                                                />
                                            )}
                                            Format View
                                        </Tab>
                                    </TabList>
                                </VStack>
                            </Flex>
                            <TabPanels marginTop="1rem">
                                <TabPanel padding={0}>
                                    <MediaMixChartContainer>
                                        {scenarioLevelResultsComparison ? (
                                            <MediaMixChannelViewChart
                                                scenarios={mapScenarioToGetScenarioResponseDto(
                                                    scenarioLevelResultsComparison,
                                                )}
                                                canUserSeeScenarioDetails
                                            />
                                        ) : (
                                            <Center height="100%">
                                                <Spinner />
                                            </Center>
                                        )}
                                    </MediaMixChartContainer>
                                </TabPanel>
                                <TabPanel padding={0}>
                                    <MediaMixChartContainer>
                                        {scenarioLevelResultsComparison?.channelLevelResults ? (
                                            <MediaMixFormatViewChart
                                                channels={
                                                    scenarioLevelResultsComparison.channelLevelResults
                                                }
                                            />
                                        ) : (
                                            <Center height="100%">
                                                <Spinner />
                                            </Center>
                                        )}
                                    </MediaMixChartContainer>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Stack>
                </Container>
            ) : (
                <Center marginTop="5rem">
                    <Spinner />
                </Center>
            )}
        </Stack>
    );
};
