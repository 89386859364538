import { SelectedAdFormatResponseDto } from '@api-clients/attention-data/models/SelectedAdFormatResponseDto';
import {
    Box,
    Checkbox,
    Collapse,
    HStack,
    IconButton,
    Spacer,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { CurrencyInput, ExpandingIcon } from '@components/atoms';
import { FC } from 'react';

interface ChannelCpmCheckboxesProps {
    channelName: string;
    onCheckChannel: (channelCode: string) => void;
    formats: Array<SelectedAdFormatResponseDto>;
    onAdFormatUpdate: (adFormatCode: string, cpm?: number, isChecked?: boolean) => void;
}

export const ChannelCpmReportsCheckboxes: FC<ChannelCpmCheckboxesProps> = ({
    channelName,
    formats,
    onAdFormatUpdate,
    onCheckChannel,
}) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

    const allChecked = formats.every((f) => f.isChecked);
    const isIndeterminate = formats.some((f) => f.isChecked) && !allChecked;

    const checkedFormats = formats.filter((f) => f.isChecked);
    const formatsToUseForCpm = checkedFormats.length > 0 ? checkedFormats : formats;

    return (
        <>
            <HStack h="40px" borderTopWidth="1px" paddingY="2rem">
                <IconButton
                    variant="ghost"
                    size="xs"
                    onClick={onToggle}
                    icon={
                        <ExpandingIcon
                            direction="right"
                            rotation="90"
                            fontSize="1.2rem"
                            isOpen={isOpen}
                        />
                    }
                    aria-label="expand"
                />

                <Box>
                    <Checkbox
                        onChange={() => onCheckChannel(channelName)}
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                    >
                        {channelName}
                    </Checkbox>
                </Box>
                <Spacer />
                <Box>
                    <CurrencyInput
                        type="text"
                        value={
                            formats.length === 0
                                ? 0
                                : formatsToUseForCpm.reduce((a, b) => a + (b.cpm ?? 0), 0) /
                                  formatsToUseForCpm.length
                        }
                        onChange={(value) =>
                            formats.forEach((f) =>
                                onAdFormatUpdate(f.adFormatCode!, value, f.isChecked),
                            )
                        }
                    />
                </Box>
            </HStack>

            <Collapse style={{ overflow: isOpen ? 'visible' : 'hidden' }} in={isOpen}>
                <Stack paddingBottom="1rem" pl="3.5rem">
                    {formats
                        .sort((a, b) => a.adFormatName!.localeCompare(b.adFormatName!))
                        .map((f) => (
                            <HStack key={f.adFormatCode}>
                                <Box>
                                    <Checkbox
                                        onChange={(e) => {
                                            onAdFormatUpdate(
                                                f.adFormatCode!,
                                                f.cpm,
                                                e.target.checked,
                                            );
                                        }}
                                        isChecked={f.isChecked ?? false}
                                    >
                                        {f.adFormatName}
                                    </Checkbox>
                                </Box>
                                <Spacer />
                                <Box>
                                    <CurrencyInput
                                        type="text"
                                        value={f.cpm!}
                                        onChange={(value) => {
                                            onAdFormatUpdate(f.adFormatCode!, value, f.isChecked);
                                        }}
                                    />
                                </Box>
                            </HStack>
                        ))}
                </Stack>
            </Collapse>
        </>
    );
};
