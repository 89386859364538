import {
    Button,
    forwardRef,
    TabProps,
    Text,
    useMultiStyleConfig,
    useTab,
    VStack,
} from '@chakra-ui/react';
import { AmplifiedColorScheme } from '@shared/cores';
import { FC } from 'react';

export interface StepperTabProps extends TabProps {
    stepNumber: number;
    colorScheme?: AmplifiedColorScheme;
}

export const StepperTab: FC<StepperTabProps> = forwardRef(({ stepNumber, ...props }, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];

    const styles = useMultiStyleConfig('Tabs', {
        isFitted: true,
        variant: 'stepper',
        ...tabProps,
    });

    return (
        <Button __css={styles.tab} {...tabProps}>
            <VStack align="left" textAlign="left" spacing={0}>
                <Text color={isSelected ? `green.500` : ''} fontWeight="600" fontSize="12px">
                    {`STEP ${stepNumber}`}
                </Text>
                <Text
                    textAlign="left"
                    fontWeight="500"
                    color={isSelected ? 'gray.700' : ''}
                    fontSize="14px"
                >
                    {props.children}
                </Text>
            </VStack>
        </Button>
    );
});
