import {
    BudgetConstraintType,
    GetScenarioResponseDto,
    UpdateScenarioRequestDto,
} from '@api-clients/media-plan';
import {
    Box,
    Button,
    Center,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Input,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useHelper } from '@shared/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { useGetScenarios, useUpdateScenario } from '@hooks/scenarios';
import { useCustomToast } from '@hooks/toast';
import { useLoaderData } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputWrapper } from '@components/atoms';
import { CountryDetailModal } from '../../molecules/modal';

export interface ScenarioParameterViewProps {
    scenario: GetScenarioResponseDto;
    setScenarios: (countries: Array<GetScenarioResponseDto>) => void;
}

export const ScenarioParameterView: FC<ScenarioParameterViewProps> = ({
    scenario,
    setScenarios,
}) => {
    const { t } = useTranslation('mediaPlans');
    const {
        getOptimisationObjectiveText,
        getBudgetConstraintLabel,
        formatAgeGroup,
        sortAgeGroups,
        formatStringToCapitalized,
    } = useHelper();

    const optimisationObjectivesText = t(
        'mediaPlanning.scenarioCreation.generateScenarios.optimisationObjectives',
        {
            returnObjects: true,
        },
    );
    const budgetConstraintsText = t(
        'mediaPlanning.scenarioCreation.generateScenarios.buttons.budgetConstraints',
        {
            returnObjects: true,
        },
    );
    const editText = t('mediaPlanning.scenarioCreation.comparison.edit', {
        returnObjects: true,
    });

    const {
        isOpen: isLocationOpen,
        onOpen: onLocationOpen,
        onClose: onLocationClose,
    } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { updateScenario } = useUpdateScenario();
    const { successToast, errorToast } = useCustomToast();
    const { campaignId } = useLoaderData() as {
        campaignId: string;
    };
    const { getScenarios } = useGetScenarios();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid },
    } = useForm<{ name: string }>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                name: yup.string().required(editText.dialog.form.name.errorMessage),
            }),
        ),
    });

    const handleUpdateScenarioName = async (updateScenarioRequestDto: UpdateScenarioRequestDto) => {
        const toastText = t('mediaPlanning.scenarioCreation.comparison.edit.toast', {
            returnObjects: true,
        });
        try {
            await updateScenario(campaignId, scenario.id!, updateScenarioRequestDto);
            setScenarios(await getScenarios(campaignId));
            successToast(toastText.success.heading, toastText.success.subtitle);
        } catch (e) {
            errorToast(toastText.error.heading, toastText.error.subtitle);
        }
    };

    const isBaseScenario = (): boolean => {
        return scenario?.optimisationStrategy === 'base';
    };

    return (
        <VStack padding="1rem" gap="0.2rem" align="left">
            <CountryDetailModal
                countryCodes={scenario.countries ?? []}
                isOpen={isLocationOpen}
                onClose={onLocationClose}
            />
            <ConfirmDialog
                headerText="Edit Scenario"
                onClose={(isConfirmation: boolean) => {
                    if (isConfirmation) {
                        handleSubmit((form) =>
                            handleUpdateScenarioName({ scenarioName: form.name }),
                        )();
                    }
                    onEditClose();
                }}
                confirmButtonText={editText.dialog.confirm}
                cancelButtonText={editText.dialog.cancel}
                isOpen={isEditOpen}
                isConfirmDisabled={!isValid}
            >
                <form
                    onSubmit={handleSubmit((form) =>
                        handleUpdateScenarioName({ scenarioName: form.name }),
                    )}
                >
                    <InputWrapper
                        isRequired
                        formLabel={editText.dialog.form.name.label}
                        formErrorText={errors.name?.message}
                        isInvalid={!!errors.name}
                    >
                        <Input
                            {...register('name', {
                                value: scenario?.scenarioName ?? '',
                            })}
                        />
                    </InputWrapper>
                </form>
            </ConfirmDialog>
            <Flex justifyContent="flex-end">
                <HStack cursor="pointer" onClick={onEditOpen}>
                    <Icon as={PencilIcon} boxSize="1.2rem" />
                    <Text textDecoration="underline" color="gray.600">
                        Rename
                    </Text>
                </HStack>
            </Flex>
            <Grid templateColumns="repeat(1, 1fr)">
                <HStack padding="0.3rem">
                    <Box>
                        <Center gap="0.2rem">
                            Optimisation objective:
                            <Text fontWeight="semibold">
                                {getOptimisationObjectiveText(
                                    scenario?.optimisationStrategy ?? '',
                                    'label',
                                    optimisationObjectivesText,
                                )}
                            </Text>
                            <Tooltip
                                label={getOptimisationObjectiveText(
                                    scenario?.optimisationStrategy ?? '',
                                    'tooltip',
                                    optimisationObjectivesText,
                                )}
                            >
                                <Icon as={InformationCircleIcon} boxSize="1.2rem" />
                            </Tooltip>
                        </Center>
                    </Box>
                </HStack>
                <GridItem>
                    {!isBaseScenario() && (
                        <HStack padding="0.3rem">
                            <Box>
                                <Center gap="0.2rem">
                                    Budget constraint:
                                    <Text textTransform="capitalize" fontWeight="semibold">
                                        {getBudgetConstraintLabel(
                                            scenario.budgetConstraintType ?? '',
                                        )}

                                        {scenario.budgetConstraintType ===
                                            BudgetConstraintType.AdjustmentToleranceLevel &&
                                            ` - ${scenario.adjustmentToleranceLevel}`}
                                    </Text>
                                    <Tooltip label={budgetConstraintsText.planTolerance.tooltip}>
                                        <Icon as={InformationCircleIcon} boxSize="1.2rem" />
                                    </Tooltip>
                                </Center>
                            </Box>
                        </HStack>
                    )}
                </GridItem>
                <GridItem>
                    <HStack padding="0.3rem">
                        <Box>
                            <Center gap="0.2rem">
                                Country:
                                <Tooltip label="View details">
                                    <Button variant="link" onClick={onLocationOpen}>
                                        {scenario.countries?.length}
                                        {scenario.countries?.length !== undefined &&
                                        scenario.countries?.length > 1
                                            ? ' countries'
                                            : ' country'}
                                    </Button>
                                </Tooltip>
                            </Center>
                        </Box>
                    </HStack>
                    <HStack padding="0.3rem">
                        <Box>
                            <Center gap="0.2rem">
                                Age group:
                                <Text fontWeight="semibold">
                                    {sortAgeGroups(scenario.ageGroups)
                                        ?.map((c) => formatAgeGroup(c, 'Group', true))
                                        .join(', ')}
                                </Text>
                            </Center>
                        </Box>
                    </HStack>
                    <HStack padding="0.3rem">
                        <Box>
                            <Center gap="0.2rem">
                                Gender:
                                <Text fontWeight="semibold">
                                    {scenario.genders
                                        ?.map((c) => formatStringToCapitalized(c))
                                        .join(', ')}
                                </Text>
                            </Center>
                        </Box>
                    </HStack>
                </GridItem>
            </Grid>
        </VStack>
    );
};
