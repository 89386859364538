/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CampaignStatus = {
    Completed: 'completed',
    Draft: 'draft'
} as const;
export type CampaignStatus = typeof CampaignStatus[keyof typeof CampaignStatus];


export function CampaignStatusFromJSON(json: any): CampaignStatus {
    return CampaignStatusFromJSONTyped(json, false);
}

export function CampaignStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStatus {
    return json as CampaignStatus;
}

export function CampaignStatusToJSON(value?: CampaignStatus | null): any {
    return value as any;
}

