/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganisationUserStatus = {
    Active: 'active',
    Inactive: 'inactive'
} as const;
export type OrganisationUserStatus = typeof OrganisationUserStatus[keyof typeof OrganisationUserStatus];


export function OrganisationUserStatusFromJSON(json: any): OrganisationUserStatus {
    return OrganisationUserStatusFromJSONTyped(json, false);
}

export function OrganisationUserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationUserStatus {
    return json as OrganisationUserStatus;
}

export function OrganisationUserStatusToJSON(value?: OrganisationUserStatus | null): any {
    return value as any;
}

