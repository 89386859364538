/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const AttentionCriteria = {
    ActiveAttention: 'active_attention',
    PassiveAttention: 'passive_attention',
    TotalAttention: 'total_attention',
} as const;
export type AttentionCriteria = typeof AttentionCriteria[keyof typeof AttentionCriteria];

export function AttentionCriteriaFromJSON(json: any): AttentionCriteria {
    return AttentionCriteriaFromJSONTyped(json, false);
}

export function AttentionCriteriaFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AttentionCriteria {
    return json as AttentionCriteria;
}

export function AttentionCriteriaToJSON(value?: AttentionCriteria | null): any {
    return value as any;
}
