import { CreateStrategyReportRequestDto, StrategyReportResponseDto } from '@api-clients/attention-data';
import {
    CreateStrategyReportRequest,
    StrategyReportsApi,
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useCreateStrategyReportProps {
    createStrategyReport: (
        createStrategyReportRequestDto: CreateStrategyReportRequestDto,
    ) => Promise<StrategyReportResponseDto>;
}

type useCreateStrategyReportHook = () => useCreateStrategyReportProps;

export const useCreateStrategyReport: useCreateStrategyReportHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function createStrategyReport(
        createStrategyReportRequestDto: CreateStrategyReportRequestDto,
    ): Promise<StrategyReportResponseDto> {
        try {
            const config = await getAttentionDataApiConfig();
            const request: CreateStrategyReportRequest = {
                createStrategyReportRequestDto,
            };

            const api = new StrategyReportsApi(config);

            return await api.createStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        createStrategyReport,
    };
};