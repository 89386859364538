/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    AdClientResponseDto,
    CreateAdClientRequestDto,
    ProblemDetails,
    RemoveAdClientRequestDto,
    UpdateAdClientRequestDto,
} from '../models';
import {
    AdClientResponseDtoFromJSON,
    AdClientResponseDtoToJSON,
    CreateAdClientRequestDtoFromJSON,
    CreateAdClientRequestDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RemoveAdClientRequestDtoFromJSON,
    RemoveAdClientRequestDtoToJSON,
    UpdateAdClientRequestDtoFromJSON,
    UpdateAdClientRequestDtoToJSON,
} from '../models';

export interface CreateAdClientRequest {
    createAdClientRequestDto?: CreateAdClientRequestDto;
}

export interface RemoveAdClientRequest {
    removeAdClientRequestDto?: RemoveAdClientRequestDto;
}

export interface UpdateAdClientDetailsRequest {
    updateAdClientRequestDto?: UpdateAdClientRequestDto;
}

/**
 * AdClientApi - interface
 *
 * @export
 * @interface AdClientApiInterface
 */
export interface AdClientApiInterface {
    /**
     *
     * @param {CreateAdClientRequestDto} [createAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdClientApiInterface
     */
    createAdClientRaw(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>>;

    /**
     */
    createAdClient(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdClientApiInterface
     */
    getAllAdClientsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdClientResponseDto>>>;

    /**
     */
    getAllAdClients(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdClientResponseDto>>;

    /**
     *
     * @param {RemoveAdClientRequestDto} [removeAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdClientApiInterface
     */
    removeAdClientRaw(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>>;

    /**
     */
    removeAdClient(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto>;

    /**
     *
     * @param {UpdateAdClientRequestDto} [updateAdClientRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdClientApiInterface
     */
    updateAdClientDetailsRaw(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>>;

    /**
     */
    updateAdClientDetails(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto>;
}

/**
 *
 */
export class AdClientApi extends runtime.BaseAPI implements AdClientApiInterface {
    /**
     */
    async createAdClientRaw(
        requestParameters: CreateAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/ad-clients`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateAdClientRequestDtoToJSON(requestParameters.createAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            AdClientResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createAdClient(
        requestParameters: CreateAdClientRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto> {
        const response = await this.createAdClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllAdClientsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AdClientResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/ad-clients`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(AdClientResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllAdClients(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AdClientResponseDto>> {
        const response = await this.getAllAdClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeAdClientRaw(
        requestParameters: RemoveAdClientRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/ad-clients`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: RemoveAdClientRequestDtoToJSON(requestParameters.removeAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            AdClientResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async removeAdClient(
        requestParameters: RemoveAdClientRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto> {
        const response = await this.removeAdClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAdClientDetailsRaw(
        requestParameters: UpdateAdClientDetailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AdClientResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/ad-clients`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateAdClientRequestDtoToJSON(requestParameters.updateAdClientRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            AdClientResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateAdClientDetails(
        requestParameters: UpdateAdClientDetailsRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AdClientResponseDto> {
        const response = await this.updateAdClientDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
