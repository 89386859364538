import { StrategyReportResponseDto } from '@api-clients/attention-data';
import {
    NavLinkStepperButton,
    NavLinkStepperButtonProps,
} from '@apps/attention360/atoms/navLinkStepperButton/NavLinkStepperButton';
import { Card, CardBody, Container, Flex, Stack } from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { routing } from '@configs';
import { useGetStrategyReport } from '@hooks/strategyReport';
import { useCustomToast } from '@hooks/toast';
import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { StrategyReportBanner } from './StrategyReportBanner';
import { StrategyReportContextProvider } from './StrategyReportContextProvider';

type StrategyReportPageProps = {};
export const StrategyReportPage: FC<StrategyReportPageProps> = () => {
    const { strategyReportId } = useParams();
    const { getStrategyReport } = useGetStrategyReport();

    const { errorToast } = useCustomToast();

    const [strategyReport, setStrategyReport] = useState<StrategyReportResponseDto>();
    const [showOnlySelectedChannelsOrFormats, setShowOnlySelectedChannelsOrFormats] =
        useState(false);

    useEffect(() => {
        let ignore = false;
        if (strategyReportId && strategyReportId.length > 0) {
            setStrategyReport(undefined);
            getStrategyReport({ strategyReportId })
                .then((s) => {
                    if (!ignore) setStrategyReport(s);
                })
                .catch(() => errorToast('Something went wrong', 'Please try again later'));
        }

        return () => {
            ignore = true;
        };
    }, [strategyReportId]);

    const tabButtons: NavLinkStepperButtonProps[] = [
        {
            url: routing.strategyReports.view.channelSelection.path,
            title: 'Step 1',
            description: 'Channel selection',
        },
        {
            url: routing.strategyReports.view.formatSelection.path,
            title: 'Step 2',
            description: 'Format selection',
        },
        {
            url: routing.strategyReports.view.audienceAnalysis.path,
            title: 'Step 3',
            description: 'Audience analysis',
        },
        {
            url: routing.strategyReports.view.creativeRecommendations.path,
            title: 'Step 4',
            description: 'Creative recommendations',
        },
    ];

    return strategyReport ? (
        <StrategyReportContextProvider
            strategyReport={strategyReport}
            setStrategyReport={setStrategyReport}
            showOnlySelectedChannelsOrFormats={showOnlySelectedChannelsOrFormats}
            setShowOnlySelectedChannelsOrFormats={setShowOnlySelectedChannelsOrFormats}
        >
            <Stack spacing="1.25rem">
                <StrategyReportBanner campaignId={strategyReport.campaignId ?? ''} />
                <Container>
                    <Stack gap="1rem">
                        <Card>
                            <CardBody>
                                <Flex gap="1rem" flexGrow={1}>
                                    {tabButtons.map((props) => (
                                        <NavLinkStepperButton key={props.url} {...props} />
                                    ))}
                                </Flex>
                            </CardBody>
                        </Card>
                        <Outlet />
                    </Stack>
                </Container>
            </Stack>
        </StrategyReportContextProvider>
    ) : (
        <Loading loadingText="Loading strategy report" />
    );
};
