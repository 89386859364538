import { DeleteCreativeRequest, StrategyReportsApi } from '@api-clients/attention-data';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useDeleteCreativeHook = () => {
    deleteCreative: (request: DeleteCreativeRequest) => Promise<void>;
};

export const useDeleteCreative: useDeleteCreativeHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteCreative(request: DeleteCreativeRequest) {
        try {
            const config = await getAttentionDataApiConfig();

            const api = new StrategyReportsApi(config);
            return await api.deleteCreative(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { deleteCreative };
};
