import { FC, useMemo, useRef, useState } from 'react';
import {
    Button,
    Flex,
    FormControl,
    Heading,
    Icon,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Switch,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ShareIcon, LinkIcon } from '@heroicons/react/24/outline';
import { copyCurrentUrl } from '@shared/utils';
import { useCustomToast } from '@hooks/toast';

type EveryoneCanViewToggleProps = {};

export const EveryoneCanViewToggle: FC<EveryoneCanViewToggleProps> = () => {
    const { t } = useTranslation('mediaPlans');

    const scenarioSharingText = t('mediaPlanning.scenarioSharing', { returnObjects: true });
    const description = t('mediaPlanning.scenarioSharing.shareModal.description');
    const copyLinkButtonText = t('mediaPlanning.scenarioSharing.shareModal.copyLinkButton.text');
    const { successToast, errorToast } = useCustomToast();
    const handleCopy = () => {
        try {
            copyCurrentUrl();
            successToast('Copied to clipboard', '', true, 'top');
        } catch (error) {
            errorToast('Something went wrong when copy to clipboard', '', true, 'top');
        }
    };

    return (
        <>
            <Popover placement="bottom-end">
                <PopoverTrigger>
                    <Button
                        leftIcon={<ShareIcon width={15} height={15} />}
                        variant="outline"
                        colorScheme="gray"
                    >
                        {scenarioSharingText.button.name}
                    </Button>
                </PopoverTrigger>
                <PopoverContent width="500px" padding={3} boxShadow="lg">
                    <PopoverCloseButton />
                    <PopoverHeader>
                        <Heading fontWeight={600} color="black" fontSize="1.25rem">
                            Share
                        </Heading>
                    </PopoverHeader>
                    <PopoverBody>
                        <Flex gap="0.75rem" flexDir="column" alignItems="flex-start">
                            <FormControl
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text fontSize="1rem" color="gray.600">
                                    {description}
                                </Text>
                                <Switch isChecked isReadOnly isDisabled />
                            </FormControl>

                            <Button
                                leftIcon={<Icon as={LinkIcon} boxSize={4} />}
                                type="button"
                                onClick={() => handleCopy()}
                            >
                                {copyLinkButtonText}
                            </Button>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};
