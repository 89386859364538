import { Input, InputGroup, InputLeftElement, InputProps, useBoolean } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Currency } from '@shared/cores/types';
import { getShortCurrencySymbol } from '@shared/cores/types/Currency';

export interface CurrencyInputProps extends Omit<InputProps, 'onChange' | 'value'> {
    currency?: Currency;
    onChange?: (value: number) => void;
    value?: number;
    isFreeTier?: boolean;
}

export const CurrencyInput: FC<CurrencyInputProps> = ({
    currency = 'USD',
    type = 'number',
    value,
    onChange,
    isFreeTier = false,
    ...inputProps
}) => {
    const [displayedInput, setDisplayedInput] = useState<string>("");
    const [isFocused, setIsFocused] = useBoolean(false);

    useEffect(() => {
        if (isFocused) {
            if (onChange) {
                const valueAsNumber = displayedInput
                    ? parseFloat(parseFloat(displayedInput).toFixed(2))
                    : NaN;
                onChange(valueAsNumber >= 0 ? valueAsNumber : 0);
            }
        }
    }, [displayedInput]);

    useEffect(() => {
        if (!isFocused) {
            if (!value || Number.isNaN(value) || value < 0) {
                setDisplayedInput((0).toFixed(2));
            } else {
                setDisplayedInput(value.toFixed(2));
            }
        }
    }, [value, isFocused]);

    return (
        <InputGroup>
            <InputLeftElement
                pointerEvents="none"
                color="gray.400"
                children={getShortCurrencySymbol(currency)}
            />
            <Input
                textAlign="right"
                type={type}
                value={displayedInput}
                onFocus={(e) => {
                    e.target.select();
                    setIsFocused.on();
                }}
                onChange={(e) => {
                    setDisplayedInput(e.target.value);
                }}
                onBlur={(e) => {
                    setIsFocused.off();
                }}
                isReadOnly={isFreeTier}
                {...inputProps}
            />
        </InputGroup>
    );
};
