import {
    ScenarioApi,
    ScenarioResponseDto,
    UpdateAScenarioRequest,
    UpdateScenarioRequestDto,
} from '@api-clients/media-plan';

import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface UpdateScenarioHook {
    updateScenario: (
        campaignId: string,
        scenarioId: string,
        updateScenarioRequestDto: UpdateScenarioRequestDto,
    ) => Promise<ScenarioResponseDto>;
}

type useUpdateScenarioProps = () => UpdateScenarioHook;

export const useUpdateScenario: useUpdateScenarioProps = () => {
    const { getMediaPlanConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateScenario(
        campaignId: string,
        scenarioId: string,
        updateScenarioRequestDto: UpdateScenarioRequestDto,
    ): Promise<ScenarioResponseDto> {
        try {
            const config = await getMediaPlanConfig();
            const request: UpdateAScenarioRequest = {
                campaignId,
                scenarioId,
                updateScenarioRequestDto,
            };

            const api = new ScenarioApi(config);
            return await api.updateAScenario(request);
        } catch (error: any) {
            throw new Error(await getDisplayedErrorMessage(error));
        }
    }

    return {
        updateScenario,
    };
};
