import { Box, Icon, Input, InputGroup, InputRightElement, Portal } from '@chakra-ui/react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { FC, forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'customInput'> {
    type?: 'startDate' | 'endDate';
    width?: string | number;
}

const Date = forwardRef(({ ...props }: any, ref: any) => (
    <InputGroup>
        <Input backgroundColor="white" ref={ref} {...props} />
        <InputRightElement pointerEvents="none" color="gray.500">
            <Icon boxSize="1.5rem" as={CalendarIcon} />
        </InputRightElement>
    </InputGroup>
));

export const DatePicker: FC<DatePickerProps> = ({
    selected,
    onChange,
    value,
    width,
    dateFormat = 'dd/MM/yyyy',
    placeholderText = 'dd/mm/yyyy',
    type,
    showPopperArrow = false,
    autoComplete = 'off',
    ...props
}): JSX.Element => {
    return (
        <Box width={width}>
            <ReactDatePicker
                portalId="root-portal"
                placeholderText={placeholderText}
                autoComplete={autoComplete}
                selected={selected}
                onChange={onChange}
                value={value}
                customInput={<Date />}
                dateFormat={dateFormat}
                showPopperArrow={showPopperArrow}
                {...props}
            />
        </Box>
    );
};
