/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type { GlobalCountriesResponseDto, ProblemDetails } from '../models';
import {
    GlobalCountriesResponseDtoFromJSON,
    GlobalCountriesResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

/**
 * GlobalCountriesApi - interface
 *
 * @export
 * @interface GlobalCountriesApiInterface
 */
export interface GlobalCountriesApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCountriesApiInterface
     */
    getGlobalCountriesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GlobalCountriesResponseDto>>>;

    /**
     */
    getGlobalCountries(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GlobalCountriesResponseDto>>;
}

/**
 *
 */
export class GlobalCountriesApi extends runtime.BaseAPI implements GlobalCountriesApiInterface {
    /**
     */
    async getGlobalCountriesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GlobalCountriesResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/global-countries`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(GlobalCountriesResponseDtoFromJSON),
        );
    }

    /**
     */
    async getGlobalCountries(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GlobalCountriesResponseDto>> {
        const response = await this.getGlobalCountriesRaw(initOverrides);
        return await response.value();
    }
}
