import {Configuration} from "@api-clients/runtime";
import {ProveInsertionOrder} from "@api-clients/prove/schema/ProveInsertionOrder";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class FindProveInsertionOrdersApi {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign
    ): Promise<Array<ProveInsertionOrder>> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaign.id}/insertion-orders`, {
            method: 'GET',
            headers: configuration.headers
        });

        return response.json();
    }

}
