import {
    Center,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    UseModalProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const MobileScreenModal: FC<UseModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation('generic');

    const getMobileScreenText = t('mobileScreen', {
        returnObjects: true,
    });

    return (
        <>
            <Modal size="xs" isOpen={isOpen ?? false} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <HStack>
                            <Text>{getMobileScreenText.headerText}</Text>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Text>{getMobileScreenText.contentText}</Text>
                        </Center>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>
    );
};
