/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ScenarioShareLinkResponseDto
 */
export interface ScenarioShareLinkResponseDto {
    /**
     *
     * @type {string}
     * @memberof ScenarioShareLinkResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof ScenarioShareLinkResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof ScenarioShareLinkResponseDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ScenarioShareLinkResponseDto interface.
 */
export function instanceOfScenarioShareLinkResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScenarioShareLinkResponseDtoFromJSON(json: any): ScenarioShareLinkResponseDto {
    return ScenarioShareLinkResponseDtoFromJSONTyped(json, false);
}

export function ScenarioShareLinkResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ScenarioShareLinkResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function ScenarioShareLinkResponseDtoToJSON(
    value?: ScenarioShareLinkResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}
