/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AccountType } from './AccountType';
import { AccountTypeFromJSON, AccountTypeFromJSONTyped, AccountTypeToJSON } from './AccountType';
import type { OrganisationWithAccessLevelDto } from './OrganisationWithAccessLevelDto';
import {
    OrganisationWithAccessLevelDtoFromJSON,
    OrganisationWithAccessLevelDtoFromJSONTyped,
    OrganisationWithAccessLevelDtoToJSON,
} from './OrganisationWithAccessLevelDto';
import type { RoleTitle } from './RoleTitle';
import { RoleTitleFromJSON, RoleTitleFromJSONTyped, RoleTitleToJSON } from './RoleTitle';
import type { UserStatus } from './UserStatus';
import { UserStatusFromJSON, UserStatusFromJSONTyped, UserStatusToJSON } from './UserStatus';

/**
 *
 * @export
 * @interface UserResponseDto
 */
export interface UserResponseDto {
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    emailAddress?: string | null;
    /**
     *
     * @type {UserStatus}
     * @memberof UserResponseDto
     */
    status?: UserStatus;
    /**
     *
     * @type {RoleTitle}
     * @memberof UserResponseDto
     */
    roleTitle?: RoleTitle;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    otherRole?: string | null;
    /**
     *
     * @type {AccountType}
     * @memberof UserResponseDto
     */
    accountType?: AccountType;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    countryPhoneCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    defaultOrganisation?: string | null;
    /**
     *
     * @type {Date}
     * @memberof UserResponseDto
     */
    termsAndConditionsAcceptedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    termsAndConditionsVersionAccepted?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserResponseDto
     */
    id?: string;
    /**
     *
     * @type {Date}
     * @memberof UserResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof UserResponseDto
     */
    updatedAt?: Date;
    /**
     *
     * @type {Array<OrganisationWithAccessLevelDto>}
     * @memberof UserResponseDto
     */
    organisations?: Array<OrganisationWithAccessLevelDto> | null;
}

/**
 * Check if a given object implements the UserResponseDto interface.
 */
export function instanceOfUserResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserResponseDtoFromJSON(json: any): UserResponseDto {
    return UserResponseDtoFromJSONTyped(json, false);
}

export function UserResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UserResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
        status: !exists(json, 'status') ? undefined : UserStatusFromJSON(json['status']),
        roleTitle: !exists(json, 'role_title') ? undefined : RoleTitleFromJSON(json['role_title']),
        otherRole: !exists(json, 'other_role') ? undefined : json['other_role'],
        accountType: !exists(json, 'account_type')
            ? undefined
            : AccountTypeFromJSON(json['account_type']),
        countryPhoneCode: !exists(json, 'country_phone_code')
            ? undefined
            : json['country_phone_code'],
        phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
        defaultOrganisation: !exists(json, 'default_organisation')
            ? undefined
            : json['default_organisation'],
        termsAndConditionsAcceptedAt: !exists(json, 'terms_and_conditions_accepted_at')
            ? undefined
            : json['terms_and_conditions_accepted_at'] === null
            ? null
            : new Date(json['terms_and_conditions_accepted_at']),
        termsAndConditionsVersionAccepted: !exists(json, 'terms_and_conditions_version_accepted')
            ? undefined
            : json['terms_and_conditions_version_accepted'],
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        organisations: !exists(json, 'organisations')
            ? undefined
            : json['organisations'] === null
            ? null
            : (json['organisations'] as Array<any>).map(OrganisationWithAccessLevelDtoFromJSON),
    };
}

export function UserResponseDtoToJSON(value?: UserResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        first_name: value.firstName,
        last_name: value.lastName,
        email_address: value.emailAddress,
        status: UserStatusToJSON(value.status),
        role_title: RoleTitleToJSON(value.roleTitle),
        other_role: value.otherRole,
        account_type: AccountTypeToJSON(value.accountType),
        country_phone_code: value.countryPhoneCode,
        phone_number: value.phoneNumber,
        default_organisation: value.defaultOrganisation,
        terms_and_conditions_accepted_at:
            value.termsAndConditionsAcceptedAt === undefined
                ? undefined
                : value.termsAndConditionsAcceptedAt === null
                ? null
                : value.termsAndConditionsAcceptedAt.toISOString(),
        terms_and_conditions_version_accepted: value.termsAndConditionsVersionAccepted,
        id: value.id,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        organisations:
            value.organisations === undefined
                ? undefined
                : value.organisations === null
                ? null
                : (value.organisations as Array<any>).map(OrganisationWithAccessLevelDtoToJSON),
    };
}
