import {
    Flex,
    Text,
    Popover,
    PopoverTrigger,
    Link,
    PopoverContent,
    PopoverArrow,
    OrderedList,
    PopoverBody,
    Button,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';
import { AvailableCountries } from '@configs/availableCountries';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { findCountryName } from '@shared/cores/types/Country';
import { FC } from 'react';

const countries = AvailableCountries.map((country) => findCountryName(country)).sort();

export const UpgradeCountryHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">Want to tailor media plans to your targeted market?</Text>
            <Flex gap="0.3rem" fontSize="0.875rem">
                <Text fontWeight="normal" fontSize="1rem">
                    Upgrade to access specific local market data from
                </Text>
                <Popover trigger="hover">
                    <PopoverTrigger>
                        <Text
                            fontSize="1rem"
                            color="green.500"
                            cursor="pointer"
                            _hover={{ textDecoration: 'underline' }}
                        >
                            {countries.length} countries.
                        </Text>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <UnorderedList fontSize="1rem" marginLeft="1.5rem" fontWeight="normal">
                                {countries.map((country) => (
                                    <ListItem key={country}>{country}</ListItem>
                                ))}
                            </UnorderedList>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Flex>
        </Flex>
    );
};

export const UpgradeAgeGroupHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">Want to tailor media plans to your targeted age groups?</Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to optimise your plan towards demographic target, including age and gender.
            </Text>
        </Flex>
    );
};

export const UpgradeGenderHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">Want to tailor media plans to your targeted genders?</Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to optimise your plan towards demographic target, including age and gender.
            </Text>
        </Flex>
    );
};

export const UpgradeAdFormatHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">Want to customise your format mix and CPMs?</Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to experiment with different channel or format mixes and CPM values.
            </Text>
        </Flex>
    );
};

export const UpgradeMediaPlanLimitHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">You&apos;ve reached your media plan limit</Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to experiment with different channel or format mixes and CPM values.
            </Text>
        </Flex>
    );
};

export const UpgradeUserLimitHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">You&apos;ve reached your user limit</Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to add more team members to your organisation.
            </Text>
        </Flex>
    );
};

export const UpgradeMediaSpendingHeader: FC = () => {
    return (
        <Flex flexDirection="column">
            <Text fontSize="1.25rem">
                Want to optimise your media spending with attention data?
            </Text>
            <Text fontWeight="normal" fontSize="0.875rem">
                Upgrade to view optimised media spending mix and campaign performance metrics.
            </Text>
        </Flex>
    );
};
