type ErrorType =
    | 'INCORRECT_USER_NAME_PASSWORD'
    | 'EMAIL_ADDRESS_IS_NOT_FOUND'
    | 'INVALID_CONFIRM_FORGOT_PASSWORD_CODE'
    | 'ATTEMPT_EXCEED_LIMIT'
    | 'EXISTED_AD_CLIENT'
    | 'EXISTED_MEMBER'
    | 'TEMPORARY_PASSWORD_EXPIRED'
    | 'SESSION_EXPIRED'
    | 'EXHAUSTED_CAMPAIGN_GENERATION'
    | 'AUTH0_WRONG_EMAIL_PASSWORD'
    | 'USER_DOES_NOT_EXIST';

export const ErrorDictionary: Record<ErrorType, string> = {
    INCORRECT_USER_NAME_PASSWORD: 'Incorrect username or password.',
    EMAIL_ADDRESS_IS_NOT_FOUND: 'Email address is not found.',
    INVALID_CONFIRM_FORGOT_PASSWORD_CODE: 'Invalid verification code provided, please try again.',
    ATTEMPT_EXCEED_LIMIT: 'Attempt limit exceeded, please try after some time.',
    EXISTED_AD_CLIENT: 'Ad client already exists.',
    EXISTED_MEMBER: 'The invited user has already joined the organisation.',
    TEMPORARY_PASSWORD_EXPIRED:
        'Temporary password has expired and must be reset by an administrator.',
    SESSION_EXPIRED: 'Invalid session for the user, session is expired.',
    EXHAUSTED_CAMPAIGN_GENERATION:
        'You have exhausted your usage. Please reach out to a customer success representative or plan-support@amplifiedintelligence.com.au for assistance.',
    AUTH0_WRONG_EMAIL_PASSWORD: 'Wrong email or password.',
    USER_DOES_NOT_EXIST: 'User does not exist in the organisation.',
};
