import { FC, ReactNode } from 'react';
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { StringType } from '@shared/cores/types';

interface BaseDetailSection {
    label?: string;
    renderItem: ReactNode;
}

export type DetailListItem = BaseDetailSection;

type DetailSectionProps = BaseDetailSection;

interface DetailTextRender {
    text?: StringType;
    option?: ReactNode;
}

export const TextRender: FC<DetailTextRender> = ({ text, option }) => {
    return (
        <>
            <Text fontWeight="bold">{text}</Text>
            {option !== undefined && option}
        </>
    );
};

export const DetailSection: FC<DetailSectionProps> = ({ label, renderItem }) => {
    return (
        <Grid
            minHeight="3rem"
            height="auto"
            marginX="2rem"
            marginY="1rem"
            templateColumns="repeat(3, 1fr)"
            gap={3}
        >
            <GridItem colSpan={1}>
                <Flex height="100%" alignItems="center">
                    <Text fontWeight="normal">{label}</Text>
                </Flex>
            </GridItem>
            <GridItem colSpan={2}>
                <Flex height="100%" alignItems="center">
                    {renderItem}
                </Flex>
            </GridItem>
        </Grid>
    );
};
