import { Box, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { DatePicker } from '@components/atoms';
import { FC, useEffect } from 'react';

export interface DateRangeFormParam {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    isValid: boolean;
}

export interface DateRangeProps {
    dateRangeForm: DateRangeFormParam;
    onChange: (formParam: DateRangeFormParam) => void;
    width?: string | number;
    startDateRequired?: boolean;
    endDateRequired?: boolean;
    disabled?: boolean;
}

export const DateRange: FC<DateRangeProps> = ({
    dateRangeForm,
    onChange,
    width = '100%',
    startDateRequired = false,
    endDateRequired = false,
    disabled = false,
}) => {
    const { startDate, endDate } = dateRangeForm;

    useEffect(() => {
        onChange({ ...dateRangeForm, isValid: !!startDate && !!endDate && startDate <= endDate });
    }, [startDate, endDate]);

    return (
        <Flex gap="1rem" width={width}>
            <Box flex="1">
                <FormControl isRequired={startDateRequired}>
                    <FormLabel htmlFor="startDate">Start Date</FormLabel>
                </FormControl>
                <DatePicker
                    id="startDate"
                    type="startDate"
                    selected={startDate}
                    onChange={(date) => onChange({ ...dateRangeForm, startDate: date })}
                    maxDate={endDate}
                    required={startDateRequired}
                    disabled={disabled}
                />
            </Box>
            <Box flex="1">
                <FormControl isRequired={endDateRequired}>
                    <FormLabel htmlFor="endDate">End Date</FormLabel>
                </FormControl>
                <DatePicker
                    id="endDate"
                    type="endDate"
                    selected={endDate}
                    onChange={(date) => onChange({ ...dateRangeForm, endDate: date })}
                    minDate={startDate}
                    required={endDateRequired}
                    disabled={disabled}
                />
            </Box>
        </Flex>
    );
};
