import { DecaySecondResponseDto } from '@api-clients/attention-data';
import { Box } from '@chakra-ui/react';
import { toLocaleFixed } from '@shared/utils';
import { FC } from 'react';
import { Chart } from 'react-chartjs-2';

interface InlineChartProps {
    entries: Array<DecaySecondResponseDto>;
}

export const InlineChart: FC<InlineChartProps> = ({entries}) => {
    const height = 50;
    const width = 140;
    return entries.length > 0 ? (
        <Chart
            height={height}
            width={width}
            type="line"
            data={{
                labels: entries.map((entry) => {
                    return `Second ${entry.second}`;
                }),
                datasets: [
                    {
                        data: entries.map((entry) => {
                            return (entry.activeAttentionPercentage ?? 0) * 100;
                        }),
                        fill: false,
                        spanGaps: true,
                        tension: 0.2,
                        backgroundColor: 'green.500',
                    },
                ],
            }}
            options={{
                maintainAspectRatio: false,
                responsive: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (item) =>
                                `${toLocaleFixed(item.raw as number, 0)}% active attention`,
                        },
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                        max: 100,
                    },
                },
            }}
        />
    ) : (
        <Box height={`${height}px`} width={`${width}px`} />
    );
};
