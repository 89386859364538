/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AdChannelResponseDto } from './AdChannelResponseDto';
import {
    AdChannelResponseDtoFromJSON,
    AdChannelResponseDtoFromJSONTyped,
    AdChannelResponseDtoToJSON,
} from './AdChannelResponseDto';
import type { ReachCurvePointResponseDto } from './ReachCurvePointResponseDto';
import {
    ReachCurvePointResponseDtoFromJSON,
    ReachCurvePointResponseDtoFromJSONTyped,
    ReachCurvePointResponseDtoToJSON,
} from './ReachCurvePointResponseDto';
import type { ScenarioLevelCurvesResponseDto } from './ScenarioLevelCurvesResponseDto';
import {
    ScenarioLevelCurvesResponseDtoFromJSON,
    ScenarioLevelCurvesResponseDtoFromJSONTyped,
    ScenarioLevelCurvesResponseDtoToJSON,
} from './ScenarioLevelCurvesResponseDto';

/**
 *
 * @export
 * @interface ChannelLevelCurvesResponseDto
 */
export interface ChannelLevelCurvesResponseDto {
    /**
     *
     * @type {AdChannelResponseDto}
     * @memberof ChannelLevelCurvesResponseDto
     */
    channel?: AdChannelResponseDto;
    /**
     *
     * @type {ReachCurvePointResponseDto}
     * @memberof ChannelLevelCurvesResponseDto
     */
    axisMin?: ReachCurvePointResponseDto;
    /**
     *
     * @type {ReachCurvePointResponseDto}
     * @memberof ChannelLevelCurvesResponseDto
     */
    axisMax?: ReachCurvePointResponseDto;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelCurvesResponseDto
     */
    totalAudience?: number;
    /**
     *
     * @type {number}
     * @memberof ChannelLevelCurvesResponseDto
     */
    cpp?: number;
    /**
     *
     * @type {Array<ScenarioLevelCurvesResponseDto>}
     * @memberof ChannelLevelCurvesResponseDto
     */
    scenarios?: Array<ScenarioLevelCurvesResponseDto> | null;
}

/**
 * Check if a given object implements the ChannelLevelCurvesResponseDto interface.
 */
export function instanceOfChannelLevelCurvesResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelLevelCurvesResponseDtoFromJSON(json: any): ChannelLevelCurvesResponseDto {
    return ChannelLevelCurvesResponseDtoFromJSONTyped(json, false);
}

export function ChannelLevelCurvesResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelLevelCurvesResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        channel: !exists(json, 'channel')
            ? undefined
            : AdChannelResponseDtoFromJSON(json['channel']),
        axisMin: !exists(json, 'axis_min')
            ? undefined
            : ReachCurvePointResponseDtoFromJSON(json['axis_min']),
        axisMax: !exists(json, 'axis_max')
            ? undefined
            : ReachCurvePointResponseDtoFromJSON(json['axis_max']),
        totalAudience: !exists(json, 'total_audience') ? undefined : json['total_audience'],
        cpp: !exists(json, 'cpp') ? undefined : json['cpp'],
        scenarios: !exists(json, 'scenarios')
            ? undefined
            : json['scenarios'] === null
            ? null
            : (json['scenarios'] as Array<any>).map(ScenarioLevelCurvesResponseDtoFromJSON),
    };
}

export function ChannelLevelCurvesResponseDtoToJSON(
    value?: ChannelLevelCurvesResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        channel: AdChannelResponseDtoToJSON(value.channel),
        axis_min: ReachCurvePointResponseDtoToJSON(value.axisMin),
        axis_max: ReachCurvePointResponseDtoToJSON(value.axisMax),
        total_audience: value.totalAudience,
        cpp: value.cpp,
        scenarios:
            value.scenarios === undefined
                ? undefined
                : value.scenarios === null
                ? null
                : (value.scenarios as Array<any>).map(ScenarioLevelCurvesResponseDtoToJSON),
    };
}
