import {
    AudienceAnalysisChannelBenchmarkResponseDto,
    GetAllBenchmarkResultsForAudienceAnalysisRequest,
} from '@api-clients/attention-data';
import { StrategyReportsApi } from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetAllBenchmarkResultsForAudienceAnalysisProps {
    getAllBenchmarkResultsForAudienceAnalysis: (
        request: GetAllBenchmarkResultsForAudienceAnalysisRequest,
    ) => Promise<Array<AudienceAnalysisChannelBenchmarkResponseDto>>;
}

type useGetAllBenchmarkResultsForAudienceAnalysisHook =
    () => useGetAllBenchmarkResultsForAudienceAnalysisProps;

export const useGetAllBenchmarkResultsForAudienceAnalysis: useGetAllBenchmarkResultsForAudienceAnalysisHook =
    () => {
        const { getAttentionDataApiConfig } = useApiConfiguration();
        const { mapDisplayedErrorMessage } = useHelper();

        async function getAllBenchmarkResultsForAudienceAnalysis(
            request: GetAllBenchmarkResultsForAudienceAnalysisRequest,
        ): Promise<Array<AudienceAnalysisChannelBenchmarkResponseDto>> {
            try {
                const config = await getAttentionDataApiConfig();
                const api = new StrategyReportsApi(config);

                return await api.getAllBenchmarkResultsForAudienceAnalysis(request);
            } catch (e) {
                throw new Error(await mapDisplayedErrorMessage(e));
            }
        }

        return {
            getAllBenchmarkResultsForAudienceAnalysis,
        };
    };
