import { ChannelLevelCurvesResponseDto, ReachCurveType } from '@api-clients/media-plan';
import {
    Box,
    Center,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Tooltip,
    useToken,
} from '@chakra-ui/react';
import { getShortenedNumber } from '@shared/utils';
import { FC, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export interface ReachCurveScenario {
    scenarioName: string;
    frequency: number;
    reachPercentage: number;
    iota: number;
    totalAudience: number;
    budget: number;
}

interface Range {
    min: number;
    max: number;
}

const getAAText = (reachCurveType: ReachCurveType) => {
    return reachCurveType === ReachCurveType.AttentionAdjusted ? '-Attention Adjusted' : '';
};

export interface ReachCurveGraphProps {
    channelCurves: ChannelLevelCurvesResponseDto;
    canUserSeeScenarioDetails?: boolean;
}

export const ReachCurveGraph: FC<ReachCurveGraphProps> = ({
    channelCurves,
    canUserSeeScenarioDetails = true,
}) => {
    const { t } = useTranslation('mediaPlans');

    const axisLabels = t('mediaPlanning.reachCurveChart.axisLabels', {
        returnObjects: true,
    });

    // getting colors for chartjs is overtly complex as we need to translate from chakras
    // colorscheme syntax to hex colors. Solution is store the hex colors as an array and iterate
    // through it with .400 values for lines (even) and .500 values for points (odd)
    const colorSchemes = [
        'green',
        'orange',
        'blue',
        'red',
        'purple',
        'limeGreen',
        'pink',
        'yellow',
        'teal',
        'cyan',
    ];

    const aaColorSchemes = ['limeGreen', 'yellow'];

    const colors = useToken(
        'colors',
        colorSchemes.flatMap((colorScheme) => [`${colorScheme}.400`, `${colorScheme}.500`]),
    );

    const aaColors = useToken(
        'colors',
        aaColorSchemes.flatMap((colorScheme) => [`${colorScheme}.400`, `${colorScheme}.500`]),
    );

    // range in budget, max is coordinate furthest along the x axisthen normalized with the niceNum algorithm
    const budgetRange: Range = {
        min: channelCurves.axisMin?.budget ?? 0,
        max: channelCurves.axisMax?.budget ?? 0,
    };

    // range in Reach Percent
    const reachPercentageRange: Range = { min: 0, max: 100 };

    const [displayedCurves, setDisplayedCurves] = useState<Array<string | number>>(
        channelCurves.scenarios?.flatMap(
            (scenario) =>
                scenario.reachCurves?.map(
                    (reachCurve) => `${reachCurve.reachCurveType}.${scenario.scenarioId}`,
                ) ?? '',
        ) ?? [],
    );

    useEffect(() => {
        setDisplayedCurves(
            channelCurves.scenarios?.flatMap(
                (scenario) =>
                    scenario.reachCurves?.map(
                        (reachCurve) => `${reachCurve.reachCurveType}.${scenario.scenarioId}`,
                    ) ?? '',
            ) ?? [],
        );
    }, [channelCurves]);

    const initialDataset =
        channelCurves.scenarios?.flatMap(
            (scenario, i) =>
                scenario.reachCurves?.flatMap((curve) => {
                    // iterates through color array and loops back around when
                    // length is reached
                    // even values for lines
                    // odd values for points
                    const lineColor =
                        curve.reachCurveType === ReachCurveType.Base
                            ? colors[(i * 2) % colors.length]
                            : aaColors[(i * 2) % aaColors.length];
                    const pointColor =
                        curve.reachCurveType === ReachCurveType.Base
                            ? colors[(i * 2 + 1) % colors.length]
                            : aaColors[(i * 2 + 1) % aaColors.length];

                    const line = {
                        scenarioId: scenario.scenarioId,
                        reachCurveType: curve.reachCurveType,
                        pointRadius: 0,
                        pointHitRadius: 25,
                        label: `${scenario.scenarioName}${getAAText(
                            curve.reachCurveType ?? ReachCurveType.Base,
                        )} Reach Curve`,
                        backgroundColor: lineColor,
                        borderColor: lineColor,
                        tension: 0.1,
                        order: 2,
                        data: curve.curvePoints?.map((point) => ({
                            x: point.budget,
                            y: (point.reachPercentage ?? 0) * 100,
                        })),
                        xAxisID: 'budget',
                        yAxisID: 'reachPercentage',
                    };

                    const point = {
                        scenarioId: scenario.scenarioId,
                        reachCurveType: curve.reachCurveType,
                        pointHitRadius: 50,
                        pointRadius: 5,
                        label: `${scenario.scenarioName}${getAAText(
                            curve.reachCurveType ?? ReachCurveType.Base,
                        )} Scenario`,
                        backgroundColor: pointColor,
                        borderColor: pointColor,
                        order: 1,
                        data: [
                            {
                                x: curve.scenarioPoint?.budget ?? 0,
                                y: (curve.scenarioPoint?.reachPercentage ?? 0) * 100,
                            },
                        ],
                        showLine: false,
                        xAxisID: 'budget',
                        yAxisID: 'reachPercentage',
                    };

                    return [line, point];
                }) ?? [],
        ) ?? [];

    const displayedDataset = useMemo(
        () =>
            initialDataset.filter(
                (scenario) =>
                    scenario.scenarioId &&
                    displayedCurves.includes(`${scenario.reachCurveType}.${scenario.scenarioId}`),
            ),
        [displayedCurves, initialDataset],
    );

    const convertReachPctToReach = (reachPct: number) =>
        (reachPct / 100) * channelCurves.totalAudience!;

    const convertBudgetToTrp = (budget: number) => {
        return budget / (channelCurves.cpp ?? 1);
    };

    const getOpposingAxisTick = (
        range: Range,
        percentageTick: number, // decimal number from 0 to 1
        converterFunction: (input: number) => number,
    ) => {
        return getShortenedNumber(
            converterFunction((range.max - range.min) * percentageTick + range.min),
        );
    };

    return (
        <Flex h="100%" direction="column">
            <Box flex={1}>
                <Line
                    data={{
                        datasets: displayedDataset,
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    title: (tooltipItems) =>
                                        tooltipItems.map(
                                            (tooltipItem) => tooltipItem.dataset.label ?? '',
                                        ),
                                    label: () => {
                                        return '';
                                    },
                                    // have to put data in the afterBody section as label is rendered per
                                    // tooltip item, creating lots of redundant displayed data
                                    afterBody: (tooltipItems) => {
                                        const { x, y } = tooltipItems.at(0)!.parsed;

                                        return [
                                            `${axisLabels.x.budget}: $${getShortenedNumber(x)}`,
                                            `${axisLabels.y.reach}: ${y.toFixed(2)}%`,
                                            `${axisLabels.x.trp}: ${getShortenedNumber(
                                                convertBudgetToTrp(x),
                                            )}`,
                                            `${axisLabels.y.targetAudience}: ${getShortenedNumber(
                                                convertReachPctToReach(y),
                                            )}`,
                                        ];
                                    },
                                },
                            },
                        },
                        scales: {
                            budget: {
                                title: {
                                    text: axisLabels.x.budget,
                                    display: true,
                                },
                                type: 'linear',
                                ticks: {
                                    callback: (tickValue) =>
                                        getShortenedNumber(parseFloat(tickValue.toString())),
                                },
                                position: 'bottom',
                                min: budgetRange.min,
                                max: budgetRange.max,
                            },
                            trp: {
                                title: {
                                    text: axisLabels.x.trp,
                                    display: true,
                                },
                                type: 'linear',
                                ticks: {
                                    // this axis is not associated with a dataset so defaults to a range of 0 to 1
                                    // therefore we can treat it as a percentage of budget axis range then convert to trp from there
                                    callback: (tickValue, i) =>
                                        getOpposingAxisTick(
                                            budgetRange,
                                            tickValue as number,
                                            convertBudgetToTrp,
                                        ),
                                },
                                position: 'top',
                            },
                            reachPercentage: {
                                title: {
                                    text: axisLabels.y.reach,
                                    display: true,
                                },
                                type: 'linear',
                                min: reachPercentageRange.min,
                                max: reachPercentageRange.max,
                                position: 'left',
                                ticks: {
                                    callback: (tickValue) => `${tickValue}%`,
                                },
                            },
                            targetAudience: {
                                title: {
                                    text: axisLabels.y.targetAudience,
                                    display: true,
                                },
                                type: 'linear',
                                ticks: {
                                    // this axis is not associated with a dataset so defaults to a range of 0 to 1
                                    // therefore we can treat it as a percentage of reach pct axis range then convert to reach from there
                                    callback: (tickValue) =>
                                        getOpposingAxisTick(
                                            reachPercentageRange,
                                            tickValue as number,
                                            convertReachPctToReach,
                                        ),
                                },
                                position: 'right',
                            },
                        },
                    }}
                />
            </Box>
            <Center>
                <CheckboxGroup size="sm" value={displayedCurves} onChange={setDisplayedCurves}>
                    <HStack>
                        {channelCurves.scenarios?.flatMap((scenario, i) => {
                            if (scenario.reachCurves && scenario.reachCurves?.length > 0) {
                                return scenario.reachCurves?.map((curve) => {
                                    const value = `${curve.reachCurveType}.${scenario.scenarioId}`;
                                    const colorScheme =
                                        curve.reachCurveType === ReachCurveType.Base
                                            ? colorSchemes[i % colorSchemes.length]
                                            : aaColorSchemes[i % aaColorSchemes.length];
                                    return (
                                        <Checkbox
                                            key={value}
                                            value={value}
                                            colorScheme={colorScheme}
                                        >
                                            {scenario.scenarioName}
                                            {getAAText(curve.reachCurveType ?? ReachCurveType.Base)}
                                        </Checkbox>
                                    );
                                });
                            }

                            if (!canUserSeeScenarioDetails) {
                                return (
                                    <Tooltip
                                        key={scenario.scenarioName}
                                        label="Upgrade your plan to unlock optimised plan's reach"
                                    >
                                        <Box>
                                            <Checkbox
                                                isDisabled
                                                colorScheme={colorSchemes[i % colorSchemes.length]}
                                            >
                                                {scenario.scenarioName}
                                            </Checkbox>
                                        </Box>
                                    </Tooltip>
                                );
                            }

                            return <></>;
                        })}
                    </HStack>
                </CheckboxGroup>
            </Center>
        </Flex>
    );
};
