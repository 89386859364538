import {
    FeatureBalanceApi,
    FeatureBalanceResponseDto
} from '@api-clients/subscriptions';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useGetFeatureBalancesProps {
    getFeatureBalances: () => Promise<Array<FeatureBalanceResponseDto>>;
}

type useGetFeatureBalancesHook = () => useGetFeatureBalancesProps;

export const useGetFeatureBalances: useGetFeatureBalancesHook = () => {
    const { getSubscriptionApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function getFeatureBalances(): Promise<Array<FeatureBalanceResponseDto>> {
        try {
            const config = await getSubscriptionApiConfig();
            const api = new FeatureBalanceApi(config);

            return await api.getAllFeatureBalancesOfLoggedInUser();
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        getFeatureBalances,
    };
};
