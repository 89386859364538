import {
    OrganisationResponseDto,
    UpdateOrganisationRequestDto,
} from '@api-clients/account-manager';
import { Button, Input, useDisclosure, VStack } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdatedToast } from '@hooks/toast';
import { useAppContext } from '@hooks/_contexts';
import { useHelper } from '@shared/utils';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ErrorAlert, InputWrapper } from '@components/atoms';
import { useUpdateOrganisation } from '@hooks/organisations';
import { useOrganisationFormContext } from './OrganisationInfoSectionContext';

export interface AddNewMemberProps {
    organisation: OrganisationResponseDto;
}

export const EditOrganisationDialog: FC<AddNewMemberProps> = ({ organisation }) => {
    const { appOrganisation } = useAppContext();
    const {
        errorUpdateOrganisation,
        setErrorUpdateOrganisation,
        updateErrorMessage,
        setUpdateErrorMessage,
    } = useOrganisationFormContext();
    const { validationSchema, updateOrganisation } = useUpdateOrganisation();
    const { successUpdateOrganisationToast } = useUpdatedToast();
    const { displayUniqueNameInMessage, mapOrganisationTranslation } = useHelper();

    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid, touchedFields, isSubmitting },
    } = useForm<UpdateOrganisationRequestDto>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur',
    });

    const onFormSave = async (data: UpdateOrganisationRequestDto) => {
        try {
            setErrorUpdateOrganisation(false);
            const response = await updateOrganisation(organisation.id!, data);
            appOrganisation.setCurrentValue(response);
            successUpdateOrganisationToast();
            onClose();
        } catch (e: any) {
            setErrorUpdateOrganisation(true);
            const errorMessage = displayUniqueNameInMessage(
                mapOrganisationTranslation(e.message),
                data.organisationName,
            );
            setUpdateErrorMessage(errorMessage);
        }
    };

    return (
        <>
            <Button
                size="sm"
                onClick={() => {
                    reset();
                    onOpen();
                }}
            >
                Edit organisation details
            </Button>

            <ConfirmDialog
                headerText="Edit organisation"
                confirmButtonText="Save"
                isOpen={isOpen}
                onClose={(isConfirmation) => {
                    if (isConfirmation) {
                        handleSubmit(onFormSave)();
                    } else {
                        onClose();
                    }
                }}
                isLoading={isSubmitting}
                isConfirmDisabled={!isValid}
            >
                <form onSubmit={handleSubmit(onFormSave)}>
                    <VStack spacing="1rem" align="left">
                        {errorUpdateOrganisation && (
                            <ErrorAlert errorMessage={updateErrorMessage} />
                        )}
                        <InputWrapper
                            isRequired
                            formLabel="Organisation name"
                            formErrorText={errors.organisationName?.message}
                            isInvalid={touchedFields.organisationName && !!errors.organisationName}
                        >
                            <Input
                                defaultValue={organisation.organisationName ?? ''}
                                {...register('organisationName')}
                            />
                        </InputWrapper>
                    </VStack>
                </form>
            </ConfirmDialog>
        </>
    );
};
