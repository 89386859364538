import {
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    AspectRatio,
    Spinner,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { externalUrls } from '@configs/externalUrls';

interface DemoVideoModalProps {
    isOpen: boolean;
    title: string;
    videoUrl: string;
    onClose: () => void;
}

export const DemoVideoModal: FC<DemoVideoModalProps> = ({ isOpen, title, videoUrl, onClose }) => {
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);

    const handleClose = () => {
        if (onClose) {
            setIsFrameLoaded(false);
            onClose();
        }
    };

    return (
        <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>{title}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!isFrameLoaded && (
                        <Center
                            position="absolute"
                            left={0}
                            width="100%"
                            top={0}
                            height="100%"
                            zIndex="modal"
                        >
                            <Spinner size="2xl" />
                        </Center>
                    )}
                    <AspectRatio ratio={1.8}>
                        <iframe
                            id="youtube_demo_video"
                            title="Demo video"
                            allowFullScreen
                            src={videoUrl}
                            onLoad={() => setIsFrameLoaded(true)}
                        />
                    </AspectRatio>
                </ModalBody>
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};
