import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    GridItem,
    Center,
    Spinner,
    Tabs,
    Spacer,
    VStack,
    TabList,
    Tab,
    Tooltip,
    Icon,
    TabPanels,
    TabPanel,
    Link,
    Skeleton,
    Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Text,
    Select,
    Button,
    useBoolean,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import {
    AttentionCriteria,
    ChannelLevelCurvesResponseDto,
    ChannelMixResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    GetScenarioResponseDto,
    OptimisationStrategy,
    ReachCurveType,
} from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { useAppContextHelper } from '@hooks/_contexts';
import { AsyncCard } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useGetScenarioReachCurves, useUpdateScenarioReachCurves } from '@hooks/reachCurves';
import { useCustomToast } from '@hooks/toast';
import { CollapsibleComponent } from '@components/molecules';
import { ContainerTitle } from '../../atoms';
import { MediaMixChartContainer } from '../mediaMixChartContainer/MediaMixChartContainer';
import {
    ChannelViewTable,
    FormatTable,
    KeyOptimisedMetricsTable,
    MediaMixChannelViewChart,
    MediaMixFormatViewChart,
} from '../../molecules';
import { ReachCurveContainer } from '../reachCurveContainer/ReachCurveContainer';

const AarcSelectOptions: { label: string; value: string }[] = [
    {
        label: 'Active attention',
        value: AttentionCriteria.ActiveAttention.toString(),
    },
    {
        label: 'Passive attention',
        value: AttentionCriteria.PassiveAttention.toString(),
    },
    {
        label: 'Total attention',
        value: AttentionCriteria.TotalAttention.toString(),
    },
];
const mapAarcSelectOptionsToAttentionCriteria = (value: string): AttentionCriteria => {
    switch (value) {
        case AttentionCriteria.ActiveAttention.toString():
            return AttentionCriteria.ActiveAttention;
        case AttentionCriteria.PassiveAttention.toString():
            return AttentionCriteria.PassiveAttention;
        case AttentionCriteria.TotalAttention.toString():
            return AttentionCriteria.TotalAttention;
        default:
            throw new Error('Unable to parse AARC select options value');
    }
};

//  map scenario comparison dto to two seperate scenario dtos
export const mapScenarioToGetScenarioResponseDto = (
    s: GetScenarioLevelResultComparisonResponseDto,
): Array<GetScenarioResponseDto> => {
    const scenario: GetScenarioResponseDto = {
        scenarioName: s.scenarioName,
        budget: s.budget?.value ?? 0,
        optimisationStrategy: s.optimisationStrategy,
        channelMix:
            s.channelLevelResults?.map(
                (ch): ChannelMixResponseDto => ({
                    channelCode: ch.channelCode,
                    channelName: ch.channelName,
                    mix: ch.mix?.value ?? 0,
                    budget: ch.budget?.value ?? 0,
                }),
            ) ?? [],
    };

    let scenarios = [scenario];

    if (s.comparedWithOptimisationStrategy !== s.optimisationStrategy) {
        scenarios.push({
            scenarioName: s.comparedWithScenarioName,
            budget: s.budget?.comparedWithValue ?? 0,
            optimisationStrategy: s.comparedWithOptimisationStrategy,
            channelMix:
                s.channelLevelResults?.map(
                    (ch): ChannelMixResponseDto => ({
                        channelCode: ch.channelCode,
                        channelName: ch.channelName,
                        mix: ch.mix?.comparedWithValue ?? 0,
                        budget: ch.budget?.comparedWithValue ?? 0,
                    }),
                ) ?? [],
        });
    }

    return scenarios;
};

const AarcThreshold = {
    max: 60,
    min: 0,
    step: 0.1,
    precision: 1,
} as const;

export interface ScenarioViewSectionProps {
    scenarioLevelResultsComparison: GetScenarioLevelResultComparisonResponseDto;
    reachCurveData: Array<ChannelLevelCurvesResponseDto>;
    setReachCurveData: (data: ChannelLevelCurvesResponseDto[]) => void;
    campaignId: string;
    scenarioId: string;
}

export const ScenarioViewSection: FC<ScenarioViewSectionProps> = ({
    scenarioLevelResultsComparison,
    reachCurveData,
    setReachCurveData,
    campaignId,
    scenarioId,
}) => {
    const { t } = useTranslation('generic');
    const { t: mediaPlansT } = useTranslation('mediaPlans');
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const isFreePlan = !featureBalances?.find(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );
    const canSeeScenarioDetails =
        featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    const getFormatViewTooltip = t('freeTier.formatViewTooltip', {
        returnObjects: true,
    });

    const [attentionSecondThreshold, setAttentionSecondThreshold] = useState(1);
    const [selectedAttentionType, setSelectedAttentionType] = useState(AarcSelectOptions[0].value);

    const { updateScenarioReachCurves } = useUpdateScenarioReachCurves();
    const [isUpdatingReachCurves, setIsUpdatingReachCurves] = useBoolean(false);

    const { getScenarioReachCurves } = useGetScenarioReachCurves();
    const { successToast, errorToast } = useCustomToast();

    const handleReachCurve = async () => {
        const data = await getScenarioReachCurves({
            campaignId,
            scenarioId,
        });
        const scenarios = data.length > 0 ? data[0].scenarios : [];
        // non-base scenario is preferred
        let scenario = scenarios?.find((s) => s.optimisationStrategy !== OptimisationStrategy.Base);
        if (!scenario) {
            scenario = scenarios?.find((s) => s.optimisationStrategy === OptimisationStrategy.Base);
        }
        const aaReachCurve = scenario?.reachCurves?.find(
            (rc) => rc.reachCurveType === ReachCurveType.AttentionAdjusted,
        );
        const storedAttentionThresholdSec = aaReachCurve ? aaReachCurve.attentionThreshold ?? 1 : 1;
        const storedAttentionType = aaReachCurve
            ? aaReachCurve.attentionCriteria ?? AttentionCriteria.ActiveAttention
            : AttentionCriteria.ActiveAttention;
        setSelectedAttentionType(storedAttentionType);
        setAttentionSecondThreshold(storedAttentionThresholdSec);
        setReachCurveData(data);
    };

    const handleUpdateScenarioReachCurves = async () => {
        const toastText = mediaPlansT(
            'mediaPlanning.scenarioCreation.comparison.reachCurve.update',
            { returnObjects: true },
        );
        try {
            setIsUpdatingReachCurves.on();
            await updateScenarioReachCurves({
                campaignId,
                scenarioId,
                updateReachCurveRequestDto: {
                    attentionThreshold: attentionSecondThreshold,
                    attentionCriteria:
                        mapAarcSelectOptionsToAttentionCriteria(selectedAttentionType),
                },
            });
            await handleReachCurve();
        } catch (error) {
            errorToast(toastText.title, toastText.description);
        } finally {
            setIsUpdatingReachCurves.off();
        }
    };

    useEffect(() => {
        handleReachCurve();
    }, [campaignId, scenarioId]);

    return (
        <Container>
            <Stack spacing="2.25rem">
                <Box display="flex" flexDirection="column" gap="1rem">
                    <CollapsibleComponent
                        headingText="Key optimised metrics"
                        subtitleText="Understand how the optimised scenario performs comparing to the original media plan"
                    >
                        <SimpleGrid width="100%" gap="2rem" columns={{ sm: 1, lg: 2 }}>
                            <GridItem>
                                {/** chart */}
                                <MediaMixChartContainer height="100%">
                                    {scenarioLevelResultsComparison ? (
                                        <MediaMixChannelViewChart
                                            scenarios={mapScenarioToGetScenarioResponseDto(
                                                scenarioLevelResultsComparison,
                                            )}
                                            canUserSeeScenarioDetails={canSeeScenarioDetails}
                                        />
                                    ) : (
                                        <Center height="100%">
                                            <Spinner />
                                        </Center>
                                    )}
                                </MediaMixChartContainer>
                            </GridItem>
                            <GridItem>
                                {scenarioLevelResultsComparison ? (
                                    <KeyOptimisedMetricsTable
                                        scenario={scenarioLevelResultsComparison}
                                    />
                                ) : (
                                    <Center
                                        height="100%"
                                        bg="white"
                                        border="1px solid"
                                        borderColor="gray.100"
                                    >
                                        <Spinner />
                                    </Center>
                                )}
                            </GridItem>
                        </SimpleGrid>
                    </CollapsibleComponent>
                </Box>
                <Tabs>
                    <CollapsibleComponent
                        headingText="Scenario mix detailed view"
                        subtitleText="Evaluate the scenario's budget mix and performance metrics by channel and format"
                        rightElement={
                            <VStack>
                                <TabList>
                                    <Tab>Channel View</Tab>
                                    <Tooltip isDisabled={!isFreePlan} label={getFormatViewTooltip}>
                                        <Tab isDisabled={isFreePlan}>
                                            {isFreePlan && (
                                                <Icon
                                                    boxSize="1rem"
                                                    as={LockClosedIcon}
                                                    marginRight="0.2rem"
                                                />
                                            )}
                                            Format View
                                        </Tab>
                                    </Tooltip>
                                </TabList>
                            </VStack>
                        }
                    >
                        <>
                            <TabPanels marginTop="1rem">
                                <TabPanel padding={0}>
                                    <AsyncCard
                                        isLoading={
                                            !scenarioLevelResultsComparison?.channelLevelResults
                                        }
                                    >
                                        {scenarioLevelResultsComparison?.channelLevelResults && (
                                            <ChannelViewTable
                                                channelLevelResultArray={
                                                    scenarioLevelResultsComparison?.channelLevelResults
                                                }
                                            />
                                        )}
                                    </AsyncCard>
                                </TabPanel>
                                <TabPanel padding={0}>
                                    <AsyncCard
                                        isLoading={
                                            !scenarioLevelResultsComparison?.channelLevelResults
                                        }
                                    >
                                        {scenarioLevelResultsComparison?.channelLevelResults && (
                                            <FormatTable
                                                channelLevelResultArray={
                                                    scenarioLevelResultsComparison?.channelLevelResults
                                                }
                                            />
                                        )}
                                    </AsyncCard>
                                </TabPanel>
                            </TabPanels>
                        </>
                    </CollapsibleComponent>
                </Tabs>
                <CollapsibleComponent
                    headingText="Attention-adjusted® reach curves"
                    subtitleText={
                        <>
                            The{' '}
                            <Link
                                isExternal
                                textDecoration="underline"
                                href="https://help.amplifiedintelligence.com.au/what-are-attention-adjusted-reach-curves"
                            >
                                Attention-adjusted® reach curve (AARC)
                            </Link>{' '}
                            transforms reach-based planning by filtering out non-attentive
                            impressions, giving a view of the attentive, high value audience
                        </>
                    }
                >
                    <Box display="flex" flexDirection="column" gap="1rem">
                        {reachCurveData.length === 0 ? (
                            <Flex flexDir="column" gap="1rem">
                                <Skeleton h="2.5rem" />
                                <Skeleton h="32rem" />
                            </Flex>
                        ) : (
                            <>
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Flex alignItems="center" gap="1rem">
                                        <Text whiteSpace="nowrap">
                                            Attention second threshold:{' '}
                                        </Text>
                                        <NumberInput
                                            step={AarcThreshold.step}
                                            min={AarcThreshold.min}
                                            max={AarcThreshold.max}
                                            value={attentionSecondThreshold}
                                            allowMouseWheel
                                            precision={AarcThreshold.precision}
                                            onChange={(str, num) => {
                                                if (str.length === 0) {
                                                    setAttentionSecondThreshold(0);
                                                    return;
                                                }
                                                setAttentionSecondThreshold(num);
                                            }}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <Text>second(s)</Text>
                                        <Tooltip label="The minimum number of attention seconds that an impression gets to be qualified as an attentive impression">
                                            <Icon as={InfoOutlineIcon} />
                                        </Tooltip>
                                    </Flex>
                                    <Flex alignItems="center" gap="1rem">
                                        <Text whiteSpace="nowrap">Attention type: </Text>
                                        <Select
                                            value={selectedAttentionType}
                                            isRequired
                                            onChange={(e) => {
                                                setSelectedAttentionType(e.target.value);
                                            }}
                                        >
                                            {AarcSelectOptions.map((option) => (
                                                <option value={option.value} key={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                        <Tooltip label="The types of attention data that is applied to the attention-adjusted reach curves">
                                            <Icon as={InfoOutlineIcon} />
                                        </Tooltip>
                                    </Flex>
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => {
                                            handleUpdateScenarioReachCurves();
                                        }}
                                        isLoading={isUpdatingReachCurves}
                                    >
                                        Update reach curves
                                    </Button>
                                </Flex>
                                <ReachCurveContainer reachCurveData={reachCurveData} />
                            </>
                        )}
                    </Box>
                </CollapsibleComponent>
                <CollapsibleComponent
                    headingText="Scenario media mix"
                    subtitleText="Compare the media mix by budget spend between each scenario"
                >
                    <MediaMixChartContainer>
                        {scenarioLevelResultsComparison?.channelLevelResults ? (
                            <MediaMixFormatViewChart
                                channels={scenarioLevelResultsComparison.channelLevelResults}
                            />
                        ) : (
                            <Center height="100%">
                                <Spinner />
                            </Center>
                        )}
                    </MediaMixChartContainer>
                </CollapsibleComponent>
            </Stack>
        </Container>
    );
};
