import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/toast/useCustomToast';

export const useRemovedToast = () => {
    const { t } = useTranslation();
    const { successToast, errorToast } = useCustomToast();

    function successRemoveUserToast(organisationName?: string | null) {
        const title = t('messages.toast.remove.user.success.title');
        const description = `${t('messages.toast.remove.user.success.description')} ${
            organisationName ?? ''
        }`;

        successToast(title, description);
    }

    function errorRemoveUserToast() {
        const title = t('messages.toast.remove.user.error.title');
        const description = t('messages.toast.remove.user.error.description');

        errorToast(title, description);
    }

    function errorRemoveUserToastWithMessage(message: string) {
        const title = message;
        errorToast(title, "");
    }

    return {
        successRemoveUserToast,
        errorRemoveUserToast,
        errorRemoveUserToastWithMessage,
    };
};
