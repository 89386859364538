/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Media Plans API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FormatResponseDto } from './FormatResponseDto';
import {
    FormatResponseDtoFromJSON,
    FormatResponseDtoFromJSONTyped,
    FormatResponseDtoToJSON,
} from './FormatResponseDto';

/**
 *
 * @export
 * @interface ChannelResponseDto
 */
export interface ChannelResponseDto {
    /**
     *
     * @type {string}
     * @memberof ChannelResponseDto
     */
    adChannelCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof ChannelResponseDto
     */
    adChannelName?: string | null;
    /**
     *
     * @type {Array<FormatResponseDto>}
     * @memberof ChannelResponseDto
     */
    adFormats?: Array<FormatResponseDto> | null;
}

/**
 * Check if a given object implements the ChannelResponseDto interface.
 */
export function instanceOfChannelResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelResponseDtoFromJSON(json: any): ChannelResponseDto {
    return ChannelResponseDtoFromJSONTyped(json, false);
}

export function ChannelResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        adChannelCode: !exists(json, 'ad_channel_code') ? undefined : json['ad_channel_code'],
        adChannelName: !exists(json, 'ad_channel_name') ? undefined : json['ad_channel_name'],
        adFormats: !exists(json, 'ad_formats')
            ? undefined
            : json['ad_formats'] === null
            ? null
            : (json['ad_formats'] as Array<any>).map(FormatResponseDtoFromJSON),
    };
}

export function ChannelResponseDtoToJSON(value?: ChannelResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ad_channel_code: value.adChannelCode,
        ad_channel_name: value.adChannelName,
        ad_formats:
            value.adFormats === undefined
                ? undefined
                : value.adFormats === null
                ? null
                : (value.adFormats as Array<any>).map(FormatResponseDtoToJSON),
    };
}
