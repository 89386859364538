import { DeleteStrategyReportRequest, StrategyReportsApi } from '@api-clients/attention-data';
import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';

interface DeleteStrategyReportHook {
    deleteStrategyReport: (strategyReportId: string) => Promise<number>;
}

type useDeleteStrategyReportProps = () => DeleteStrategyReportHook;

export const useDeleteStrategyReport: useDeleteStrategyReportProps = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function deleteStrategyReport(strategyReportId: string): Promise<number> {
        try {
            const config = await getAttentionDataApiConfig();
            const request: DeleteStrategyReportRequest = {
                strategyReportId,
            };

            const api = new StrategyReportsApi(config);
            return await api.deleteStrategyReport(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }

    return {
        deleteStrategyReport,
    };
};
