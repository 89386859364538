import {
    AttentionCriteria,
    ChannelLevelCurvesResponseDto,
    ChannelMixResponseDto,
    GetScenarioBasicInfoResponseDto,
    GetScenarioLevelResultComparisonResponseDto,
    GetScenarioResponseDto,
    OptimisationStrategy,
    ReachCurveType,
    UpdateScenarioRequestDto,
} from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { Box, Button, Center, Spinner, Stack } from '@chakra-ui/react';
import { ForbiddenPage } from '@components/pages';
import { useDeleteScenario, useUpdateScenario } from '@hooks/scenarios';
import { useGetScenarioBasicInfo } from '@hooks/scenarios/useGetScenarioBasicInfo';
import { useGetScenarioLevelResultsComparison } from '@hooks/scenarios/useGetScenarioLevelResultsComparison';

import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { AdFormatDetailModal } from '../../molecules/modal';
import { ScenarioBanner } from '..';
import { ScenarioViewSection } from './ScenarioViewSection';

export const ScenarioEditSection: FC = () => {
    const { t } = useTranslation('generic');

    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const isFreePlan = !featureBalances?.find(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    const { campaignId, scenarioId } = useLoaderData() as {
        campaignId: string;
        scenarioId: string;
    };

    const [reachCurveData, setReachCurveData] = useState<Array<ChannelLevelCurvesResponseDto>>([]);

    const [scenarioBasicInfo, setScenarioBasicInfo] = useState<GetScenarioBasicInfoResponseDto>();

    const { getScenarioBasicInfo } = useGetScenarioBasicInfo();

    const loadScenarioBasicInfo = () => {
        getScenarioBasicInfo(campaignId, scenarioId).then((response) =>
            setScenarioBasicInfo(response),
        );
    };

    useEffect(() => {
        loadScenarioBasicInfo();
    }, [campaignId, scenarioId]);

    const canSeeScenarioDetails =
        featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    const { t: mediaPlansT } = useTranslation('mediaPlans');
    const { deleteScenario } = useDeleteScenario();
    const { updateScenario } = useUpdateScenario();
    const { successToast, errorToast } = useCustomToast();

    const handleUpdateScenarioName = async (updateScenarioRequestDto: UpdateScenarioRequestDto) => {
        const toastText = mediaPlansT('mediaPlanning.scenarioCreation.comparison.edit.toast', {
            returnObjects: true,
        });
        try {
            setScenarioBasicInfo(undefined);
            await updateScenario(campaignId, scenarioId, updateScenarioRequestDto);
            loadScenarioBasicInfo();
            successToast(toastText.success.heading, toastText.success.subtitle);
        } catch {
            errorToast(toastText.error.heading, toastText.error.subtitle);
        }
    };

    const { getScenarioLevelResultsComparison } = useGetScenarioLevelResultsComparison();

    const [scenarioLevelResultsComparison, setScenarioLevelResultsComparison] =
        useState<GetScenarioLevelResultComparisonResponseDto>();

    useEffect(() => {
        getScenarioLevelResultsComparison(campaignId, scenarioId).then((r) => {
            r.channelLevelResults?.sort(
                (a, b) => a.channelName?.localeCompare(b.channelName ?? '') ?? 0,
            );
            setScenarioLevelResultsComparison(r);
        });
    }, [campaignId, scenarioId]);

    const navigate = useNavigate();
    const handleDeleteScenario = async () => {
        const toastText = mediaPlansT('mediaPlanning.scenarioCreation.comparison.delete.toast', {
            returnObjects: true,
            scenario: scenarioBasicInfo?.scenarioName,
        });
        try {
            await deleteScenario(campaignId, scenarioId);
            successToast(toastText.success.heading, toastText.success.subtitle);
            navigate(`..`);
        } catch {
            errorToast(toastText.error.heading, toastText.error.subtitle);
        }
    };

    if (
        scenarioBasicInfo &&
        !canSeeScenarioDetails &&
        scenarioBasicInfo.optimisationStrategy !== OptimisationStrategy.Base
    ) {
        return <ForbiddenPage />;
    }

    const isBaseScenario = scenarioBasicInfo?.optimisationStrategy === OptimisationStrategy.Base;

    return (
        <>
            <Stack spacing="1.25rem">
                <ScenarioBanner
                    isFreePlan={isFreePlan}
                    onEdit={handleUpdateScenarioName}
                    onDelete={handleDeleteScenario}
                    scenarioBasicInfo={scenarioBasicInfo}
                    formatDetailsComponent={
                        <AdFormatDetailModal
                            channels={scenarioLevelResultsComparison?.channelLevelResults
                                ?.filter(
                                    (ch) =>
                                        ch.formatLevelResults && ch.formatLevelResults.length > 0,
                                )
                                ?.map((ch) => ({
                                    channelCode: ch.channelCode!,
                                    channelName: ch.channelName!,
                                    formats:
                                        ch.formatLevelResults
                                            ?.filter((f) => f.id != null)
                                            ?.map((f) => ({
                                                formatCode: f.formatCode!,
                                                formatName: f.formatName!,
                                            })) ?? [],
                                }))}
                        />
                    }
                    reachCurveData={reachCurveData}
                />

                {scenarioBasicInfo && scenarioLevelResultsComparison ? (
                    <ScenarioViewSection
                        scenarioLevelResultsComparison={scenarioLevelResultsComparison}
                        reachCurveData={reachCurveData}
                        setReachCurveData={setReachCurveData}
                        campaignId={campaignId}
                        scenarioId={scenarioId}
                    />
                ) : (
                    <Center marginTop="5rem">
                        <Spinner />
                    </Center>
                )}
                {/** For posthog surveys, do not show survey when the user is on the base scenario */}
                {!isBaseScenario && <Box className="posthog-survey" />}
            </Stack>
        </>
    );
};
