import React, { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { routing } from '@configs';
import { useSubscriptionHelper } from '@hooks/subscriptions';
import { useAppContextHelper } from '@hooks/_contexts';

export const SubscriptionValidationRedirect: FC = () => {
    const { isAttentionDataOnly } = useSubscriptionHelper();
    return (
        <>
            {isAttentionDataOnly() ? (
                <Navigate to={routing.manageApplications.url} />
            ) : (
                <Navigate to={routing.campaign.root.path} />
            )}
        </>
    );
};
