import { SelectedAdFormatResponseDto } from '@api-clients/attention-data/models/SelectedAdFormatResponseDto';
import { Box, Stack, Text, useToast } from '@chakra-ui/react';
import { ConfirmDialog } from '@components/atoms/confirmDialog/ConfirmDialog';
import { FC, useEffect, useMemo, useState } from 'react';
import { ChannelCpmReportsCheckboxes } from './ChannelCpmReportsCheckboxes';

export interface AdFormatsReportsModalProps {
    selectedAdFormats: Array<SelectedAdFormatResponseDto>;
    onConfirm: (channelData: Array<SelectedAdFormatResponseDto>) => void;
    isOpen: boolean;
    onCancel: () => void;
    isLoading?: boolean;
}

export const AdFormatsReportsModal: FC<AdFormatsReportsModalProps> = ({
    selectedAdFormats,
    onConfirm,
    isOpen,
    onCancel,
    isLoading,
}) => {
    const channelNames = [...new Set(selectedAdFormats.map((f) => f.adChannelName ?? ''))];

    const getInitialAdFormats = () => {
        return new Map<string, SelectedAdFormatResponseDto>(
            selectedAdFormats.map((f) => [
                f.adFormatCode!,
                {
                    adFormatName: f.adFormatName,
                    adFormatCode: f.adFormatCode,
                    adChannelCode: f.adChannelCode,
                    adChannelName: f.adChannelName,
                    isChecked: f.isChecked,
                    cpm: f.cpm!,
                },
            ]),
        );
    };

    const [adFormats, setAdFormats] = useState<Map<string, SelectedAdFormatResponseDto>>(
        getInitialAdFormats(),
    );

    useEffect(() => {
        setAdFormats(getInitialAdFormats());
    }, [selectedAdFormats]);

    const onAdFormatUpdate = (adFormatCode: string, cpm?: number, isChecked?: boolean) => {
        const adFormat = adFormats.get(adFormatCode);
        setAdFormats((map) => {
            return new Map(
                map.set(adFormatCode, {
                    ...adFormat,
                    cpm: cpm ?? adFormat?.cpm,
                    isChecked: isChecked ?? adFormat?.isChecked,
                }),
            );
        });
    };

    const handleChannelToggle = (channelName: string) => {
        const channelAdFormats = [...adFormats.values()].filter(
            (f) => f.adChannelName === channelName,
        );
        const uncheckedChannelAdFormats = channelAdFormats.filter((f) => !f.isChecked);

        setAdFormats((map) => {
            // check all if some amount of unchecked boxes in channel
            // otherwise uncheck all
            if (uncheckedChannelAdFormats.length > 0) {
                uncheckedChannelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: true });
                });
            } else {
                channelAdFormats.forEach((f) => {
                    map.set(f.adFormatCode!, { ...f, isChecked: false });
                });
            }
            return new Map<string, SelectedAdFormatResponseDto>(map);
        });
    };

    const isConfirmDisabled = useMemo(
        () => ![...adFormats.values()].some((f) => f.isChecked),
        [adFormats],
    );

    return (
        <ConfirmDialog
            headerText="Configure ad formats"
            confirmButtonText="Confirm"
            onClose={(isConfirmation) => {
                if (isConfirmation) {
                    onConfirm([...adFormats.values()]);
                } else {
                    setAdFormats(getInitialAdFormats());
                    onCancel();
                }
            }}
            isOpen={isOpen}
            size="3xl"
            isLoading={isLoading}
            isConfirmDisabled={isConfirmDisabled}
            modalCaption={isConfirmDisabled ? 'At least one format must be selected' : ''}
        >
            <Stack>
                <Text color="gray.600">
                    Please fill in CPMs for all formats to effectively compare performance.
                    Substitution data will be provided when format data is not available for the
                    selected demographic
                </Text>
                <Box>
                    {channelNames
                        .sort((a, b) => a.localeCompare(b))
                        .map((channelName) => {
                            const channelFormats = [...adFormats.values()].filter(
                                (f) => f.adChannelName === channelName,
                            );
                            return (
                                <ChannelCpmReportsCheckboxes
                                    key={channelName}
                                    channelName={channelName}
                                    formats={channelFormats}
                                    onCheckChannel={handleChannelToggle}
                                    onAdFormatUpdate={onAdFormatUpdate}
                                />
                            );
                        })}
                </Box>
            </Stack>
        </ConfirmDialog>
    );
};
