/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Account Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ApiService } from './ApiService';
import { ApiServiceFromJSON, ApiServiceFromJSONTyped, ApiServiceToJSON } from './ApiService';

/**
 *
 * @export
 * @interface UpdateOauthApplicationPermissionsRequestDto
 */
export interface UpdateOauthApplicationPermissionsRequestDto {
    /**
     *
     * @type {Array<ApiService>}
     * @memberof UpdateOauthApplicationPermissionsRequestDto
     */
    requestedApis?: Array<ApiService> | null;
}

/**
 * Check if a given object implements the UpdateOauthApplicationPermissionsRequestDto interface.
 */
export function instanceOfUpdateOauthApplicationPermissionsRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOauthApplicationPermissionsRequestDtoFromJSON(
    json: any,
): UpdateOauthApplicationPermissionsRequestDto {
    return UpdateOauthApplicationPermissionsRequestDtoFromJSONTyped(json, false);
}

export function UpdateOauthApplicationPermissionsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UpdateOauthApplicationPermissionsRequestDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        requestedApis: !exists(json, 'requested_apis')
            ? undefined
            : json['requested_apis'] === null
            ? null
            : (json['requested_apis'] as Array<any>).map(ApiServiceFromJSON),
    };
}

export function UpdateOauthApplicationPermissionsRequestDtoToJSON(
    value?: UpdateOauthApplicationPermissionsRequestDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        requested_apis:
            value.requestedApis === undefined
                ? undefined
                : value.requestedApis === null
                ? null
                : (value.requestedApis as Array<any>).map(ApiServiceToJSON),
    };
}
