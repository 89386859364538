import {createSlice} from '@reduxjs/toolkit'
import {retrieveProveBreakdownReportDataAsync, retrieveProveOverviewReportDataAsync} from "@/redux/slices/prove/dashboard/thunks";
import {
    ProveFlattenedReportBreakdownEntry,
    ProveReportBreakdownEntry,
    ProveReportMetrics
} from "@api-clients/prove/schema/ProveDashboard";

export interface DashboardState {
    overview?: ProveReportMetrics
    overviewIsPending: boolean
    overviewIsError: boolean
    breakdown?: Array<ProveFlattenedReportBreakdownEntry>
    breakdownIsPending: boolean
    breakdownIsError: boolean
}

const initialState: DashboardState = {
    overview: undefined,
    overviewIsPending: false,
    overviewIsError: false,
    breakdown: undefined,
    breakdownIsPending: false,
    breakdownIsError: false
}

const flattenBreakdownEntries = (breakdownEntries: Array<ProveReportBreakdownEntry>, currentEntryValues: Array<string>) => {
    if (!breakdownEntries) {
        return [];
    }

    let entries = [] as Array<ProveFlattenedReportBreakdownEntry>;

    for (let i = 0; i < breakdownEntries.length; i++) {
        const breakdownEntry = breakdownEntries[i];
        const entryValues = [...currentEntryValues];

        entryValues.push(breakdownEntry.value);

        if (breakdownEntry.metrics) {
            entries.push({value: entryValues, metrics: breakdownEntry.metrics});
        } else if (breakdownEntry.nested) {
            entries = entries.concat(flattenBreakdownEntries(breakdownEntry.nested, entryValues));
        }
    }

    entries.sort((a, b) => b.metrics.impressions - a.metrics.impressions);

    return entries;
}

// TODO: think about ways to cache data to limit HTTP requests when navigating between tabs etc.
export const dashboardSlice = createSlice({
    name: 'prove/dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProveOverviewReportDataAsync.pending, (state, action) => {
                state.overviewIsPending = true;
                state.overview = undefined;
            })
            .addCase(retrieveProveOverviewReportDataAsync.rejected, (state, action) => {
                state.overviewIsError = true;
                state.overviewIsPending = false;
            })
            .addCase(retrieveProveOverviewReportDataAsync.fulfilled, (state, action) => {
                state.overview = action.payload.metrics;
                state.overviewIsPending = false;
            })
            .addCase(retrieveProveBreakdownReportDataAsync.pending, (state, action) => {
                state.breakdownIsPending = true;
                state.breakdown = undefined;
            })
            .addCase(retrieveProveBreakdownReportDataAsync.rejected, (state, action) => {
                state.breakdownIsError = true;
                state.breakdownIsPending = false;
            })
            .addCase(retrieveProveBreakdownReportDataAsync.fulfilled, (state, action) => {
                state.breakdown = flattenBreakdownEntries(action.payload.breakdown!, []);
                state.breakdownIsPending = false;
            })
    },
})
