import { CountryCodeIso2 } from '@api-clients/shared';
import {
    Badge,
    Box,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Skeleton,
    Spacer,
    Tag,
    Tooltip,
    useConst,
} from '@chakra-ui/react';
import { AvailableCountries } from '@configs/availableCountries';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { findCountryName } from '@shared/cores/types/Country';
import {
    chakraComponents,
    ClearIndicatorProps,
    MenuListProps,
    MultiValueGenericProps,
    OptionProps,
    Select,
} from 'chakra-react-select';
import { FC, useCallback } from 'react';
import type { Option } from '@shared/cores/types/MultiSelectTypes';
import { useAppContextHelper } from '@hooks/_contexts';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { CountrySelectMenuList } from './CountrySelectMenuList';
import { CountrySelectMultiValueContainer } from './CountrySelectMultiValueContainer';
import { CountrySelectOption } from './CountrySelectOption';

export interface CountrySelectProps {
    label: string;
    setSelectedOptions: (values: Array<Option>) => void;
    selectedOptions: Array<Option>;
    availableCountryOptions: CountryCodeIso2[];
    tooltip?: string;
    isRequired?: boolean;
    canSelectGlobal: boolean;
    isUsingGlobal: boolean;
    setIsUsingGlobal: (isUsingGlobal: boolean) => void;
    isDisabled?: boolean;
    isLoaded?: boolean;
    openUpgradeModal?: () => void;
}

export const CountrySelect: FC<CountrySelectProps> = ({
    label,
    setSelectedOptions,
    selectedOptions,
    availableCountryOptions,
    tooltip,
    isRequired,
    canSelectGlobal,
    isUsingGlobal,
    setIsUsingGlobal,
    isDisabled,
    isLoaded = true,
    openUpgradeModal,
}) => {
    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const isPaidTier = featureBalances?.some(
        (f) =>
            f.featureCode === FeatureCode.MediaPlansViewScenario &&
            f.limitType === LimitType.Unlimited,
    );

    const canSelectCountries = useConst(availableCountryOptions.length > 0);

    const countryOptionsConst = useConst(
        [...AvailableCountries]
            .map((c) => ({
                value: c,
                label: findCountryName(c),
                isDisabled: !availableCountryOptions.includes(c),
            }))
            .sort((a, b) => {
                if (a.isDisabled && !b.isDisabled) {
                    return 1;
                }
                if (b.isDisabled && !a.isDisabled) {
                    return -1;
                }
                return a.label.localeCompare(b.label);
            }),
    );

    const MultiValueContainer = useCallback(
        (props: MultiValueGenericProps<any>) => (
            <CountrySelectMultiValueContainer isUsingGlobal={isUsingGlobal} {...props} />
        ),
        [isUsingGlobal],
    );

    const MenuList = useCallback(
        (props: MenuListProps<any>) => (
            <CountrySelectMenuList
                canSelectCountries={canSelectCountries}
                canSelectGlobal={canSelectGlobal}
                isUsingGlobal={isUsingGlobal}
                setIsUsingGlobal={setIsUsingGlobal}
                countryOptions={countryOptionsConst}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                openUpgradeModal={openUpgradeModal}
                {...props}
            />
        ),
        [
            canSelectCountries,
            canSelectGlobal,
            isUsingGlobal,
            setIsUsingGlobal,
            selectedOptions,
            countryOptionsConst,
            setSelectedOptions,
        ],
    );

    const Option = useCallback((props: OptionProps<any>) => <CountrySelectOption {...props} />, []);

    // dont display clear indicator when using global
    const ClearIndicator = useCallback(
        (props: ClearIndicatorProps<any>) =>
            !isUsingGlobal ? <chakraComponents.ClearIndicator {...props} /> : <></>,
        [isUsingGlobal],
    );

    return (
        <Box>
            <FormControl isRequired={isRequired}>
                <Flex>
                    <FormLabel>{label}</FormLabel>

                    {tooltip && (
                        <Tooltip label={tooltip}>
                            <Icon color="gray.400" as={InformationCircleIcon} />
                        </Tooltip>
                    )}
                    <Spacer />
                    {!canSelectCountries && !isPaidTier && (
                        <Badge variant="solid" backgroundColor="gray.400" height="1.2rem">
                            Upgrade
                        </Badge>
                    )}
                </Flex>
            </FormControl>
            <Skeleton isLoaded={isLoaded}>
                <Select
                    isDisabled={isDisabled}
                    isMulti
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    colorScheme="green"
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    options={countryOptionsConst}
                    placeholder={isUsingGlobal ? <Tag>Global</Tag> : 'Select...'}
                    value={selectedOptions}
                    components={{
                        MultiValueContainer,
                        MenuList,
                        Option,
                        ClearIndicator,
                    }}
                    onChange={(e: any) => {
                        setSelectedOptions(
                            e.map((ev: any) => ({ value: ev.value, label: ev.label })),
                        );
                    }}
                    styles={{
                        menuPortal: (provided: any) => ({ ...provided, zIndex: 5000 }),
                        option: (provided: any) => {
                            return provided.index === 0 ? { ...provided } : { display: 'none' };
                        },
                    }}
                    chakraStyles={{
                        control: (provided: any) => ({
                            ...provided,
                            background: 'white',
                        }),
                        multiValue: (provided: any, state: any) => {
                            return state.index === 0 ? { ...provided } : { display: 'none' };
                        },
                    }}
                    useBasicStyles
                    menuPortalTarget={document.body}
                />
            </Skeleton>
        </Box>
    );
};
