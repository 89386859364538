import {
    Box,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { CountryCodeIso2 } from '@api-clients/shared';

interface CountryDetailModalProps {
    countryCodes: Array<CountryCodeIso2>;
    isOpen: boolean;
    onClose: () => void;
}

export const CountryDetailModal: FC<CountryDetailModalProps> = ({
    countryCodes,
    isOpen,
    onClose,
}) => {
    const [modalContent, setModalContent] = useState<JSX.Element>();

    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    const mapModalContent = () => {
        return (
            <Box>
                <HStack>
                    <Text fontWeight="bold">
                        {countryCodes.map((c) => regionNames.of(c.toUpperCase())).join(', ')}
                    </Text>
                </HStack>
            </Box>
        );
    };

    useEffect(() => {
        setModalContent(mapModalContent());
    }, []);

    return (
        <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Country</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>{modalContent}</ModalBody>
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};
