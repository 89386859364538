import {type Action, configureStore, type ThunkAction} from '@reduxjs/toolkit'
import {type TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector,} from 'react-redux'
import {devMiddleware} from "@redux/middleware";
import {reducer} from './rootReducer'

export const reduxStore = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
        return (process.env.NODE_ENV === 'development') ? getDefaultMiddleware().concat(devMiddleware) : getDefaultMiddleware();
    }
})
export const useDispatch = () => useReduxDispatch<ReduxDispatch>()
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector

/* Types */
export type ReduxStore = typeof reduxStore
export type ReduxState = ReturnType<typeof reduxStore.getState>
export type ReduxDispatch = typeof reduxStore.dispatch
export type ReduxThunkAction<ReturnType = void> = ThunkAction<
    ReturnType,
    ReduxState,
    unknown,
    Action
>
