import * as yup from 'yup';
import i18next from '@/configs/i18n/i18n';

export const orgFormValidationSchema = yup.object().shape({
    companyName: yup.string().trim().required(),
});

export const userFormValidationschema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(i18next.t('messages.forms.validation.progressiveSignUp.firstName.isRequired')),
    lastName: yup
        .string()
        .trim()
        .required(i18next.t('messages.forms.validation.progressiveSignUp.lastName.isRequired')),
});
