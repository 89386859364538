import type {
    SubTableDataType,
    TableDataType,
} from '@apps/attentionADJUST/components/pages/campaignCreationPage/MediaPlanCreationPage';
import { Checkbox, Input, Tbody, Td, Tr } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction } from 'react';
import { TableError } from '@apps/attentionADJUST/components/molecules/table/campaignCreationTable/CampaignCreationTable';
import { SubTableEditableCell } from './SubTableEditableCell';

type SubTableRowProps = {
    rowIndex: number;
    tableData: TableDataType[];
    setTableData: Dispatch<SetStateAction<TableDataType[]>>;
    adFormat: SubTableDataType;
    isDefaultFormatMix: boolean;
    setSubTableHasError: Dispatch<SetStateAction<Array<TableError>>>;
    removeInvalidTableError?: () => void;
};
const SubTableRow: FC<SubTableRowProps> = ({
    rowIndex,
    tableData,
    setTableData,
    adFormat,
    isDefaultFormatMix,
    setSubTableHasError,
    removeInvalidTableError = () => {},
}) => {
    const handleChangeOnCheckBox = (adFormatCode: string | undefined | null) => {
        if (adFormatCode === undefined || adFormatCode === null) {
            return;
        }
        const newTableData = tableData.map((tableRow, index) => {
            if (index !== rowIndex) {
                return tableRow;
            }
            const newAdformats = tableRow.adFormats?.map((format) => {
                if (format.adFormatCode !== adFormatCode) {
                    return format;
                }
                const newAdformat: SubTableDataType = { ...format, isChecked: !format.isChecked };
                return newAdformat;
            });
            const newTableRow: TableDataType = {
                ...tableRow,
                adFormats: newAdformats,
                isChecked: newAdformats?.some((format) => format.isChecked) ?? false,
            };
            // Depending on whether default format mix is checked,
            // update the budget in each ad formats or the budget in channel
            if (isDefaultFormatMix) {
                // update format budget
                const totalBudget = newTableRow.budget ?? 0;
                const numberOfActiveAdFormats =
                    newTableRow.adFormats?.filter((f) => f.isChecked).length ?? 0;
                if (numberOfActiveAdFormats !== 0) {
                    newTableRow.adFormats = newTableRow.adFormats?.map((f) => {
                        if (!f.isChecked) {
                            return { ...f, budget: 0 };
                        }
                        return { ...f, budget: totalBudget / numberOfActiveAdFormats };
                    });
                }
            } else {
                // update channel budget
                newTableRow.adFormats = newTableRow.adFormats?.map((f) => {
                    if (!f.isChecked) {
                        return { ...f, budget: 0 };
                    }
                    return { ...f };
                });
                newTableRow.budget =
                    newTableRow.adFormats?.reduce((partialSum, format) => {
                        if (format.isChecked) {
                            return partialSum + format.budget;
                        }
                        return partialSum;
                    }, 0) ?? 0;
            }
            return newTableRow;
        });
        setTableData(newTableData);
    };

    return (
        <Tr opacity={adFormat.isChecked ? '1' : '0.6'}>
            <Td>
                <Checkbox
                    isChecked={adFormat.isChecked ?? false}
                    onChange={() => {
                        handleChangeOnCheckBox(adFormat.adFormatCode);
                    }}
                />
            </Td>
            <Td lineHeight="1.25rem" fontSize="0.875rem" color="gray.800">
                {adFormat.adFormatName}
            </Td>
            <SubTableEditableCell
                adFormat={adFormat}
                value={adFormat.budget ?? 0}
                accessorKey="budget"
                setTableData={setTableData}
                tableData={tableData}
                rowIndex={rowIndex}
                validate={(val: number) => val >= 0}
                disabled={isDefaultFormatMix}
                setSubTableHasError={setSubTableHasError}
                removeInvalidTableError={removeInvalidTableError}
            />
            <SubTableEditableCell
                adFormat={adFormat}
                value={adFormat.cpm ?? 0}
                accessorKey="cpm"
                setTableData={setTableData}
                tableData={tableData}
                rowIndex={rowIndex}
                validate={(val: number) => val >= 1}
                disabled={isDefaultFormatMix}
                setSubTableHasError={setSubTableHasError}
                removeInvalidTableError={removeInvalidTableError}
            />
        </Tr>
    );
};
export default SubTableRow;
