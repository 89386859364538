import {
    Box,
    Flex,
    Heading,
    HStack,
    Icon,
    Radio,
    Spacer,
    Stack,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export interface SortOptionProps<T> {
    value: T;
    heading: string;
    description?: string;
    selectedSortOption: T;
    setSelectedSortOption: (value: T) => void;
    tooltip?: React.ReactNode;
    colorScheme?: string;
}

export const SortOption = <T,>({
    value,
    selectedSortOption,
    setSelectedSortOption,
    heading,
    description,
    tooltip,
    colorScheme = 'orange',
}: SortOptionProps<T>) => {
    return (
        <Box as="label">
            <Box
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                _checked={{
                    bg: `${colorScheme}.50`,
                    borderColor: `${colorScheme}.200`,
                }}
                backgroundColor="gray.50"
                p="1rem"
                borderColor={selectedSortOption === value ? `${colorScheme}.200` : 'gray.200'}
                bg={selectedSortOption === value ? `${colorScheme}.50` : 'gray.50'}
                onClick={(e) => {
                    setSelectedSortOption(value);
                    e.stopPropagation();
                }}
            >
                <Box>
                    <Flex gap=".5rem">
                        <Radio
                            colorScheme={colorScheme}
                            name="sortBy"
                            isChecked={selectedSortOption === value}
                            onChange={() => setSelectedSortOption(value)}
                        />
                        <Flex direction="column">
                            <Flex alignItems="center" gap="1rem">
                                <Text fontWeight="medium" fontSize="sm">
                                    {heading}
                                </Text>
                                {tooltip && (
                                    <Tooltip label={tooltip}>
                                        <Icon as={InfoOutlineIcon} boxSize=".75rem" />
                                    </Tooltip>
                                )}
                            </Flex>
                            <Text color="gray.600" fontSize="sm" userSelect="none">
                                {description}
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};
