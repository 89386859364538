import {
    GetScenarioLevelResultComparisonResponseDto,
    ScenarioLevelResultComparisonResponseDto,
    ScenarioWithComparisonResponseDto,
} from '@api-clients/media-plan';
import { Box, Center, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { ArrowDownRightIcon, ArrowRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useGetPercentageChangeAndCodedCommentary } from '@hooks/scenarios/useGetPercentageChangeAndCodedCommentary';
import { getOptimisationObjective } from '@shared/cores/types';
import { FC } from 'react';

export interface CodedCommentaryCardProps {
    scenario: GetScenarioLevelResultComparisonResponseDto;
}

export const CodedCommentaryCard: FC<CodedCommentaryCardProps> = ({ scenario }) => {
    let { getPercentageChangeAndCodedCommentary } = useGetPercentageChangeAndCodedCommentary();

    let { percentageChange, codedCommentary } = getPercentageChangeAndCodedCommentary(
        scenario as GetScenarioLevelResultComparisonResponseDto,
    );

    const getColor = () => {
        if (percentageChange > 0) {
            return 'green.500';
        }
        if (percentageChange < 0) {
            return 'red.500';
        }
        return 'gray.100';
    };

    return (
        <Flex
            backgroundColor="white"
            padding={4}
            direction="column"
            boxShadow="xs"
            borderBottomRadius="lg"
            borderTopWidth="0.25rem"
            borderTopColor={getColor()}
            height="100%"
        >
            <Center flex={1}>
                <VStack>
                    {percentageChange > 0 && (
                        <Icon boxSize="5rem" color={getColor()} as={ArrowUpRightIcon} />
                    )}
                    {percentageChange < 0 && (
                        <Icon boxSize="5rem" color={getColor()} as={ArrowDownRightIcon} />
                    )}
                    {percentageChange === 0 && (
                        <Icon boxSize="5rem" color="gray.400" as={ArrowRightIcon} />
                    )}
                    <Text>
                        {`${getOptimisationObjective(
                            scenario.optimisationStrategy!,
                            'acronym',
                        )} change`}
                    </Text>
                    <Text fontSize="5xl" fontWeight="bold">
                        {(percentageChange * 100).toFixed(2)}%
                    </Text>
                </VStack>
            </Center>
            <Box borderRadius="lg" padding={4} backgroundColor="orange.50">
                <Text fontSize="sm" fontWeight="bold">
                    {getOptimisationObjective(scenario.optimisationStrategy!, 'verbose')}
                </Text>
                <Text fontSize="sm">{codedCommentary}</Text>
            </Box>
        </Flex>
    );
};
