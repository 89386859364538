import { useAppContextHelper } from '@hooks/_contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { clearLocalStorage } from '@shared/utils';

type LogoutOptions = {
    force: boolean;
};
export const useLogout = () => {
    const { resetAppContext } = useAppContextHelper();
    const { logout: auth0Logout } = useAuth0();
    function logout(logoutOptions: LogoutOptions = { force: false }) {
        const { force } = logoutOptions;
        if (force) {
            window.localStorage.clear();
        } else {
            // keep user preferences and cookie consent
            clearLocalStorage();
        }
        resetAppContext();
        auth0Logout();
    }
    return { logout };
};
