import { Center, HStack, Icon, Radio, RadioGroup, Text, Tooltip, Wrap } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ColorScheme, Size } from '@shared/cores';
import { FC } from 'react';

// a more complex data structure that can be used to specify
// particular option behaviour (tooltip display, or disabling specific options)
export interface MultiSelectOption {
    value: string;
    label?: string;
    tooltip?: string;
    isDisabled?: boolean; // is this specific option disabled
}

export interface MultiSelectFieldProps {
    options: Array<string | MultiSelectOption>;
    colorScheme?: ColorScheme;
    size?: Size;
    direction?: 'row' | 'column';
    spacing?: number | string;
    variant?: 'normal' | 'outline';
    isDisabled?: boolean; // is the whole field disabled
}

export interface RadioFieldProps extends MultiSelectFieldProps {
    onChange: (selectedOption?: string) => void;
    selectedOption?: string;
}

export const RadioField: FC<RadioFieldProps> = ({
    options,
    colorScheme,
    size,
    direction = 'row',
    variant,
    spacing,
    onChange,
    selectedOption,
    isDisabled,
}) => {
    return (
        <RadioGroup
            value={selectedOption}
            colorScheme={colorScheme}
            size={size}
            onChange={(value) => onChange(value !== '' ? value : undefined)}
            isDisabled={isDisabled}
            variant={variant}
        >
            <Wrap direction={direction} spacing={spacing}>
                {options.map((option) => {
                    let multiSelectOption = typeof option === 'string' ? { value: option } : option;
                    multiSelectOption.label ??= multiSelectOption.value;
                    return (
                        <Radio
                            key={multiSelectOption.value}
                            value={multiSelectOption.value}
                            isDisabled={multiSelectOption.isDisabled}
                        >
                            <HStack>
                                <Text>{multiSelectOption.label}</Text>
                                {multiSelectOption.tooltip && (
                                    <Center>
                                        <Tooltip label={multiSelectOption.tooltip}>
                                            <Icon color="gray.400" as={InformationCircleIcon} />
                                        </Tooltip>
                                    </Center>
                                )}
                            </HStack>
                        </Radio>
                    );
                })}
            </Wrap>
        </RadioGroup>
    );
};
