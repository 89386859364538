import {createAppAsyncThunk} from "@/redux/createAppAsyncThunk";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import {CreateGlobalCampaignApi} from "@api-clients/global/campaign/api/CreateGlobalCampaignApi";
import {UpdateGlobalCampaignApi} from "@api-clients/global/campaign/api/UpdateGlobalCampaignApi";
import {FindGlobalCampaignApi} from "@api-clients/global/campaign/api/FindGlobalCampaignApi";
import {FindGlobalCampaignsApi} from "@api-clients/global/campaign/api/FindGlobalCampaignsApi";
import {Configuration} from "@api-clients/runtime";
import {globalCampaignDemoData} from "@redux/slices/global/campaign/demo/globalCampaign";

export const findGlobalCampaignsAsync = createAppAsyncThunk(
    'global/campaign/findAll',
    async (
        payload: {
            configuration: Configuration
        }
    ) => {
        return FindGlobalCampaignsApi.execute(payload.configuration);
    }
)

export const selectGlobalCampaignAsync = createAppAsyncThunk(
    'global/campaign/select',
    async (
        payload: {
            configuration: Configuration,
            campaignId: string
        },
        {getState}
    ) => {
        if (payload.campaignId === 'demo') {
            return globalCampaignDemoData;
        }

        const state = getState();
        if (state.globalCampaign.availableCampaigns) {
            for (let i = 0; i < state.globalCampaign.availableCampaigns.length; i++) {
                if (state.globalCampaign.availableCampaigns[i].id === payload.campaignId) {
                    return {...state.globalCampaign.availableCampaigns[i]};
                }
            }
        }

        return FindGlobalCampaignApi.execute(payload.configuration, payload.campaignId);
    }
)

export const createGlobalCampaignAsync = createAppAsyncThunk(
    'global/campaign/create',
    async (
        payload: {
            configuration: Configuration,
            campaign: GlobalCampaign
        }
    ) => {
        return CreateGlobalCampaignApi.execute(payload.configuration, payload.campaign);
    }
)

export const updateGlobalCampaignAsync = createAppAsyncThunk(
    'global/campaign/update',
    async (
        payload: {
            configuration: Configuration,
            campaign: GlobalCampaign
        }
    ) => {
        return UpdateGlobalCampaignApi.execute(payload.configuration, payload.campaign);
    }
)
